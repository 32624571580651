import React from "react";
import {
  Box,
  CircularProgress,
  DialogTitle,
  Grid,
  IconButton,
  Skeleton,
  Tooltip,
  Typography,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { MarketListingViewModel } from "james/views/marketListingView";
import { IconViewUpload } from "components/Ledger/Token/IconViewUpload";

export interface HeaderProps {
  closeDialog: () => void;
  marketListingViewModel?: MarketListingViewModel;
  disableClose?: boolean;
  showLoading?: boolean;
}

export function Header(props: HeaderProps) {
  if (!props.marketListingViewModel) {
    return (
      <DialogTitle
        sx={(theme) => ({
          backgroundColor: theme.palette.custom.midnight,
          width: { sm: 420 },
          padding: {
            sm: theme.spacing(1, 1, 1, 3),
            xs: theme.spacing(1, 1, 1, 2),
          },
        })}
      >
        <Grid container direction={"row"} spacing={2} alignItems={"center"}>
          <Grid item>
            <Skeleton variant={"circular"} width={64} height={64} />
          </Grid>
          <Grid item>
            <Skeleton width={200} height={30} />
            <Skeleton width={150} height={15} />
          </Grid>
        </Grid>
        <Box sx={{ alignSelf: "start" }}>
          <IconButton
            id={"placeDirectOrderDialog-close-iconButton"}
            size={"small"}
            onClick={props.closeDialog}
            disabled={props.disableClose}
          >
            <CloseIcon />
          </IconButton>
        </Box>
      </DialogTitle>
    );
  }

  return (
    <DialogTitle
      sx={(theme) => ({
        backgroundColor: theme.palette.custom.midnight,
        width: { sm: 420 },
        padding: {
          sm: theme.spacing(1, 1, 1, 3),
          xs: theme.spacing(1, 1, 1, 2),
        },
      })}
    >
      <Grid container direction={"row"} spacing={2} alignItems={"center"}>
        <Grid item>
          <IconViewUpload
            size={64}
            token={props.marketListingViewModel.token}
            tokenIconDownloadURL={props.marketListingViewModel.tokenIconURL}
          />
        </Grid>
        <Grid item sx={{ width: "60%" }}>
          <Tooltip
            title={props.marketListingViewModel.assetName}
            placement="top"
          >
            <Typography
              id={"placeDirectOrderDialog-headerInstrumentName-text"}
              variant={"h5"}
              sx={(theme) => ({
                fontWeight: { sm: theme.typography.fontWeightBold },
              })}
              children={props.marketListingViewModel.assetName}
            />
          </Tooltip>
          <Typography
            id={"placeDirectOrderDialog-headerInstrumentShortName-text"}
            variant={"caption"}
            sx={(theme) => ({ color: theme.palette.text.secondary })}
            children={props.marketListingViewModel.assetShortName}
          />
        </Grid>
        {props.showLoading && (
          <Grid item alignSelf={"start"}>
            <CircularProgress size={20} />
          </Grid>
        )}
      </Grid>
      <Box sx={{ alignSelf: "start" }}>
        <IconButton
          id={"placeDirectOrderDialog-close-iconButton"}
          size={"small"}
          onClick={props.closeDialog}
          disabled={props.disableClose}
        >
          <CloseIcon />
        </IconButton>
      </Box>
    </DialogTitle>
  );
}

import { useEffect } from "react";
import { useExchangeDashboardStore } from "../store";
import { useExchangeStore } from "../../../store";
import { Reader } from "james/views/marketListingView";
import { TextExactCriterion } from "james/search/criterion";
import { useLocation, useNavigate } from "react-router-dom";
import { LedgerAccountCategory, Token } from "james/ledger";
import { useAccountContext } from "context/Account/Account";
import { Decimal } from "@mesh/common-js/dist/num/decimal_pb";
import { FutureAmount } from "@mesh/common-js/dist/ledger/futureAmount_pb";
import { useApplicationContext } from "context/Application/Application";
import { Balance } from "@mesh/common-js/dist/views/stellarAccountView/model_pb";
import { MechanismType } from "james/market";
import { newStellarAccountViewModel } from "@mesh/common-js/dist/views/stellarAccountView";

export const useInitialDataFetch = () => {
  const store = useExchangeDashboardStore();
  const { stellarAccountContext } = useAccountContext();
  const exchangeStore = useExchangeStore();
  const { authContext } = useApplicationContext();
  const { pathname } = useLocation();
  const navigate = useNavigate();

  // Fetch market listing view model
  // only if not already set
  // this happens on refreshing the browser or navigating directly to the link
  useEffect(() => {
    let timeout: NodeJS.Timeout;
    const list = pathname.split("/");
    const baseCode = list[list.length - 2];
    const counterCode = list[list.length - 1];
    if (
      exchangeStore.marketListingPair &&
      !(
        baseCode != exchangeStore.marketListingPair.base.code ||
        counterCode != exchangeStore.marketListingPair.counter.code
      )
    ) {
      const pair = exchangeStore.marketListingPair;
      store.updateTradeCardState.setTradeCardForm(
        store.tradeCardState.price
          .setToken(pair.counter.toFutureToken())
          .setValue(new Decimal().setValue("0")),
        new FutureAmount()
          .setToken(pair.base.toFutureToken())
          .setValue(new Decimal().setValue("0")),
        store.tradeCardState.estimatedTotal
          .setToken(pair.counter.toFutureToken())
          .setValue(new Decimal().setValue("0")),
      );
      store.updateTradeCardState.setEditedFields(new Set());
      store.updateTradeCardState.setQuoteParemeter(
        exchangeStore.selectedMarketListing?.listingMarketMechanisms
          .find((v) => v.type === MechanismType.LimitOrder)
          ?.quoteParameters.find((qp) => qp.quoteToken.isEqualTo(pair.counter)),
      );
    } else {
      timeout = setTimeout(async () => {
        try {
          const response = await Reader.ReadOne({
            context: authContext,
            criteria: {
              "token.code": TextExactCriterion(baseCode),
            },
          });

          let counterToken = new Token();
          response.model.listingMarketMechanisms.forEach((mech) =>
            mech.quoteParameters.forEach((qp) => {
              if (qp.quoteToken.code === counterCode) {
                counterToken = qp.quoteToken;
              }
            }),
          );

          exchangeStore.setMarketListingPair({
            base: response.model.token,
            counter: counterToken,
          });

          store.updateTradeCardState.setTradeCardForm(
            store.tradeCardState.price
              .setToken(counterToken.toFutureToken())
              .setValue(new Decimal().setValue("0")),
            new FutureAmount()
              .setToken(response.model.token.toFutureToken())
              .setValue(new Decimal().setValue("0")),
            store.tradeCardState.estimatedTotal
              .setToken(counterToken.toFutureToken())
              .setValue(new Decimal().setValue("0")),
          );
          exchangeStore.setSelectedMarketListing(response.model);
          store.updateTradeCardState.setQuoteParemeter(
            response.model.listingMarketMechanisms
              .find((m) => m.type === MechanismType.LimitOrder)
              ?.quoteParameters.find((qp) =>
                qp.quoteToken.isEqualTo(counterToken),
              ),
          );
        } catch (e) {
          console.error(e);
          navigate("");
        }
      });
    }

    return () => clearTimeout(timeout);
  }, [pathname, exchangeStore.marketListingPair]);

  // Check balances and get source accounts
  useEffect(() => {
    if (!exchangeStore.marketListingPair || stellarAccountContext.loading)
      return;
    const tradingAccount = stellarAccountContext.accounts.find((v) => {
      if (v.getLabel() === LedgerAccountCategory.Trading) return true;
    });

    const potentialSourceAccounts = stellarAccountContext.accounts.filter(
      (val) =>
        !(
          val.getLabel() === LedgerAccountCategory.Issuance ||
          val.getLabel() === LedgerAccountCategory.Clearance
        ),
    );

    const balance = tradingAccount
      ?.getBalancesList()
      .find(
        (v) =>
          v.getTokenviewmodel()?.getToken()?.getCode() ===
          exchangeStore.marketListingPair?.counter.code,
      );

    const baseBalance = tradingAccount
      ?.getBalancesList()
      .find(
        (v) =>
          v.getTokenviewmodel()?.getToken()?.getCode() ===
          exchangeStore.marketListingPair?.base.code,
      );

    store.updateTradeCardState.setPotentialSourceAccounts(
      potentialSourceAccounts,
    );
    if (balance && baseBalance) {
      store.updateTradeCardState.setBalance({
        0: balance ?? new Balance(),
        1: baseBalance ?? new Balance(),
      });
    }
    store.updateTradeCardState.setSourceAccount(
      newStellarAccountViewModel(tradingAccount ?? potentialSourceAccounts[0]),
    );

    if (stellarAccountContext.accounts.length != 0) {
      store.setLoading(false);
    }
  }, [stellarAccountContext.loading, exchangeStore.marketListingPair]);

  useEffect(() => {
    if (!store.tradeCardState.sourceAccount.getLedgerid()) return;
    const tradingAccount = store.tradeCardState.sourceAccount;

    if (!tradingAccount?.getBalancesList()) {
      return;
    }

    const balance = tradingAccount
      ?.getBalancesList()
      .find(
        (v) =>
          v.getTokenviewmodel()?.getToken()?.getCode() ===
          exchangeStore.marketListingPair?.counter.code,
      );

    const baseBalance = tradingAccount
      ?.getBalancesList()
      .find(
        (v) =>
          v.getTokenviewmodel()?.getToken()?.getCode() ===
          exchangeStore.marketListingPair?.base.code,
      );

    if (balance && baseBalance) {
      store.updateTradeCardState.setBalance({
        0: balance ?? new Balance(),
        1: baseBalance ?? new Balance(),
      });
    }
  }, [store.tradeCardState.sourceAccount]);
};

import {
  Box,
  Button,
  Card,
  CardContent,
  CircularProgress,
  IconButton,
  Tooltip,
  Typography,
  alpha,
} from "@mui/material";
import React, { useMemo } from "react";
import { useBuilderContext } from "../../../../Context";
import { DataTable, RowType } from "@mesh/common-js-react/dist/Tables";
import { Assetflow } from "@mesh/common-js/dist/financial/assetflow_pb";
import { protobufTimestampToDayjs } from "@mesh/common-js/dist/googleProtobufConverters";
import { Timestamp } from "google-protobuf/google/protobuf/timestamp_pb";
import { decimalToBigNumber, formatTextNum } from "@mesh/common-js/dist/num";
import { assetflowCategoryToString } from "@mesh/common-js/dist/financial/assetFlowCategory";
import { DateTimeFormatWithOffset } from "const/dateformats";
import {
  FaceOutlined as FaceIcon,
  QueryStats,
  ErrorOutline as WarningIcon,
} from "@mui/icons-material";
import { timezoneToString } from "@mesh/common-js/dist/i8n";
import { Popover } from "components/PopOver/Popover";
import { Amount } from "components/Ledger/Amount";
import { Amount as LedgerAmount } from "james/ledger";

export type AssetflowsProps = {
  height: number;
};

export const Assetflows = (props: AssetflowsProps) => {
  const { simulationMode } = useBuilderContext();

  return simulationMode ? <Simulated {...props} /> : <Actual {...props} />;
};

const Simulated = (props: AssetflowsProps) => {
  const { formData, simulatedAssetflows } = useBuilderContext();

  const assetflowColumns: RowType<
    Assetflow,
    Omit<
      Assetflow.AsObject,
      | "id"
      | "number"
      | "ownerid"
      | "auditentry"
      | "state"
      | "legid"
      | "smartinstrumentid"
    >
  > = useMemo(
    () => ({
      sequencenumber: {
        title: "Seq No.",
        renderCell: (rowData: Assetflow) => {
          return rowData.getSequencenumber();
        },
      },
      fixed: {
        title: "Fixed",
        renderCell: (rowData: Assetflow) => {
          return rowData.getFixed() ? "True" : "False";
        },
      },
      paymentdate: {
        title: "Payment Date",
        renderCell: (rowData: Assetflow) => {
          return protobufTimestampToDayjs(
            rowData.getPaymentdate() ?? new Timestamp(),
          )
            .tz(timezoneToString(formData.smartInstrument.getTimezone()))
            .format(DateTimeFormatWithOffset);
        },
      },
      recorddate: {
        title: "Record Date",
        renderCell: (rowData: Assetflow) => {
          return protobufTimestampToDayjs(
            rowData.getRecorddate() ?? new Timestamp(),
          )
            .tz(timezoneToString(formData.smartInstrument.getTimezone()))
            .format(DateTimeFormatWithOffset);
        },
      },
      category: {
        title: "Category",
        renderCell: (rowData: Assetflow) => {
          return assetflowCategoryToString(rowData.getCategory());
        },
      },
      amount: {
        title: "Amount",
        renderCell: (rowData: Assetflow) => {
          return `${rowData.getAmount()?.getToken()?.getCode()} ${formatTextNum(
            decimalToBigNumber(rowData.getAmount()?.getValue()),
            { noDecimalPlaces: 7 },
          )}`;
        },
      },
      data: {
        title: "Data",
        renderCell: (rowData: Assetflow) => {
          return <AssetflowDataColumn Assetflow={rowData} />;
        },
      },
    }),
    [formData.smartInstrument.getTimezone()],
  );

  if (!simulatedAssetflows.length) {
    return (
      <Box
        sx={{
          height: "100%",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Box
          sx={(theme) => ({
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            gap: theme.spacing(0.5),
          })}
        >
          <FaceIcon
            sx={(theme) => ({
              fontSize: 110,
              color: alpha(theme.palette.background.default, 0.5),
            })}
          />
          <Typography
            color="secondary"
            variant="h4"
            children="Nothing to see here"
          />
          <Typography
            variant="body2"
            children={
              <span>
                You will see <i>Asset Flows</i> here after running{" "}
                <b>Calculate</b>
              </span>
            }
          />
        </Box>
      </Box>
    );
  }

  return (
    <DataTable
      tableContainerClassName="meshScroll"
      height={props.height}
      data={simulatedAssetflows}
      columns={assetflowColumns}
    />
  );
};

const Actual = (props: AssetflowsProps) => {
  const {
    formData,
    assetflows,
    assetflowsLoaded,
    assetflowLoadError,
    clearAssetflowLoadError,
  } = useBuilderContext();

  const assetflowColumns: RowType<
    Assetflow,
    Omit<
      Assetflow.AsObject,
      "id" | "ownerid" | "auditentry" | "state" | "legid" | "smartinstrumentid"
    >
  > = useMemo(
    () => ({
      number: {
        title: "No.",
        renderCell: (rowData: Assetflow) => {
          return rowData.getNumber();
        },
      },
      sequencenumber: {
        title: "Seq No.",
        renderCell: (rowData: Assetflow) => {
          return rowData.getSequencenumber();
        },
      },
      fixed: {
        title: "Fixed",
        renderCell: (rowData: Assetflow) => {
          return rowData.getFixed() ? "True" : "False";
        },
      },
      paymentdate: {
        title: "Payment Date",
        renderCell: (rowData: Assetflow) => {
          return protobufTimestampToDayjs(
            rowData.getPaymentdate() ?? new Timestamp(),
          )
            .tz(timezoneToString(formData.smartInstrument.getTimezone()))
            .format(DateTimeFormatWithOffset);
        },
      },
      recorddate: {
        title: "Record Date",
        renderCell: (rowData: Assetflow) => {
          return protobufTimestampToDayjs(
            rowData.getRecorddate() ?? new Timestamp(),
          )
            .tz(timezoneToString(formData.smartInstrument.getTimezone()))
            .format(DateTimeFormatWithOffset);
        },
      },
      category: {
        title: "Category",
        renderCell: (rowData: Assetflow) => {
          return assetflowCategoryToString(rowData.getCategory());
        },
      },
      amount: {
        title: "Amount",
        renderCell: (rowData: Assetflow) => {
          return `${rowData.getAmount()?.getToken()?.getCode()} ${formatTextNum(
            decimalToBigNumber(rowData.getAmount()?.getValue()),
            { noDecimalPlaces: 7 },
          )}`;
        },
      },
      data: {
        title: "Data",
        renderCell: (rowData: Assetflow) => {
          return <AssetflowDataColumn Assetflow={rowData} />;
        },
      },
    }),
    [formData.smartInstrument.getTimezone()],
  );

  if (!assetflowsLoaded) {
    return (
      <Box
        sx={(theme) => ({
          height: "100%",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyItems: "center",
          gap: theme.spacing(2),
        })}
      >
        <CircularProgress size={70} />
        <Typography variant="h5" color="textSecondary" children="Loading..." />
      </Box>
    );
  }

  if (assetflowLoadError) {
    return (
      <Box
        sx={{
          height: "100%",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Box
          sx={(theme) => ({
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            gap: theme.spacing(0.5),
          })}
        >
          <WarningIcon
            sx={(theme) => ({
              fontSize: 110,
              color: alpha(theme.palette.background.default, 0.5),
            })}
          />
          <Typography
            color="secondary"
            variant="h4"
            children="Something Went Wrong"
          />
          <Typography variant="body2" children={assetflowLoadError} />
          <Button onClick={clearAssetflowLoadError}>Try Again</Button>
        </Box>
      </Box>
    );
  }

  if (!assetflows.length) {
    return (
      <Box
        sx={{
          height: "100%",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Box
          sx={(theme) => ({
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            gap: theme.spacing(0.5),
          })}
        >
          <FaceIcon
            sx={(theme) => ({
              fontSize: 110,
              color: alpha(theme.palette.background.default, 0.5),
            })}
          />
          <Typography
            color="secondary"
            variant="h4"
            children="Nothing to see here"
          />
          <Typography
            variant="body2"
            children={
              <span>
                You will see <i>Asset Flows</i> once they have been generated.
              </span>
            }
          />
        </Box>
      </Box>
    );
  }

  return (
    <DataTable
      tableContainerClassName="meshScroll"
      height={props.height}
      data={assetflows}
      columns={assetflowColumns}
    />
  );
};

interface AssetflowDataColumnProps {
  Assetflow: Assetflow;
}

const AssetflowDataColumn = (props: AssetflowDataColumnProps) => {
  return (
    <Popover
      anchorOrigin={{ vertical: "top", horizontal: "center" }}
      popOverComponent={<AssetflowData Assetflow={props.Assetflow} />}
    >
      <Tooltip title="Select to view assetflow data" placement="top">
        <IconButton>
          <QueryStats />
        </IconButton>
      </Tooltip>
    </Popover>
  );
};

interface AssetflowDataProps {
  Assetflow: Assetflow;
}

const AssetflowData = (props: AssetflowDataProps) => {
  const data = props.Assetflow.getData()?.getFloatingrateassetflowdata();

  if (!data) {
    return <div>No Additional Data</div>;
  }

  return (
    <Card style={{ backgroundColor: "#1d1f21" }}>
      <CardContent>
        <ul>
          <li>
            <p>
              <strong>Notional</strong>:{" "}
              <Amount
                amount={LedgerAmount.fromFutureAmount(data.getNotional())}
                formatTextNumOpts={{ noDecimalPlaces: 0 }}
              />
            </p>
          </li>
          <li>
            <p>
              <strong>Rate</strong>:{" "}
              {formatTextNum(decimalToBigNumber(data.getRate()), {
                noDecimalPlaces: 7,
              })}
            </p>
          </li>
          <li>
            <p>
              <strong>Accrual Days</strong>: {data.getAccrualdays()}
            </p>
          </li>
          <li>
            <p>
              <strong>Accrual for Period</strong>:{" "}
              <Amount
                amount={LedgerAmount.fromFutureAmount(
                  data.getAccrualforperiod(),
                )}
                formatTextNumOpts={{ noDecimalPlaces: 7 }}
              />{" "}
            </p>
          </li>
          <li>
            <p>
              <strong>Deferred Amount</strong>{" "}
              <Amount
                amount={LedgerAmount.fromFutureAmount(data.getDeferredamount())}
                formatTextNumOpts={{ noDecimalPlaces: 7 }}
              />{" "}
            </p>
          </li>
          <li>
            <p>
              <strong>Deferral Buildup</strong>{" "}
              <Amount
                amount={LedgerAmount.fromFutureAmount(
                  data.getDeferralbuildup(),
                )}
                formatTextNumOpts={{ noDecimalPlaces: 7 }}
              />{" "}
            </p>
          </li>
          <li>
            <p>
              <strong>Rate Sample Date</strong>{" "}
              {data.getRatesampledate()?.toDate().toDateString()}
            </p>
          </li>
          <li>
            <p>
              <strong>Accrual Start Date</strong>{" "}
              {data.getAccrualstartdate()?.toDate().toDateString()}
            </p>
          </li>
          <li>
            <p>
              <strong>Accrual End Date</strong>{" "}
              {data.getAccrualenddate()?.toDate().toDateString()}
            </p>
          </li>
        </ul>
      </CardContent>
    </Card>
  );
};

import { useEffect, useRef, useState } from "react";
import {
  Box,
  Button,
  Card,
  Divider,
  IconButton,
  MenuItem,
  Tooltip,
  Typography,
  useTheme,
} from "@mui/material";
import {
  allMediaEntryTypes,
  mediaEntryTypeToString,
} from "@mesh/common-js/dist/marketing";
import { ReactSortable } from "react-sortablejs";
import DeleteIcon from "@mui/icons-material/Delete";
import { Refresh as ResetIcon } from "@mui/icons-material";
import { TextField } from "components/FormFields";
import { ValidationResult } from "common/validation";
import { LoadingButton } from "@mui/lab";
import { enqueueSnackbar } from "notistack";
import { useApplicationContext } from "context/Application/Application";
import { PublicMediaController } from "james/media/publicMediaController";
import { MediaEntry } from "@mesh/common-js/dist/marketing/mediaEntry_pb";
import { MediaEntryType } from "@mesh/common-js/dist/marketing/mediaEntryType_pb";
import { NoDataSplashCard } from "../NoDataSplashCard";
import { youtubeUrlToEmbed } from "./YoutubeUrlToEmbed";

export type MediaEntriesFormProps = {
  mediaEntries: MediaEntry[];
  disabled: boolean;
  readOnly: boolean;
  formDataValidationResult: ValidationResult;
  onChange: (updatedMediaEntries: MediaEntry[]) => void;
};

export const MediaEntriesForm = (props: MediaEntriesFormProps) => {
  const theme = useTheme();
  const { authContext } = useApplicationContext();

  // TODO: Add validations
  // const getFieldValidation = (field: string, index: number) => {
  //   return props.formDataValidationResult.fieldValidations[
  //     `mediaEntries-${index}-${field}`
  //   ];
  // };

  // State for the list of media entries
  const [mediaEntries, setMediaEntries] = useState(
    props.mediaEntries.map((mediaEntry, idx) => ({
      id: idx,
      mediaEntry,
    })),
  );

  // State for the new media entries being added
  const [currentMediaEntry, setCurrentMediaEntry] = useState<MediaEntry>(
    new MediaEntry().setType(MediaEntryType.IMAGE_MEDIA_ENTRY_TYPE),
  );

  // State for the last saved media entries
  const [savedMediaEntries, setSavedMediaEntries] = useState(
    props.mediaEntries,
  );

  // Helper function to compare media entries for unsaved changes
  const areMediaEntriesDifferent = (
    mediaEntriesA: MediaEntry[],
    mediaEntriesB: MediaEntry[],
  ) => {
    if (mediaEntriesA.length !== mediaEntriesB.length) return true;
    return mediaEntriesA.some((a, idx) => {
      const b = mediaEntriesB[idx];
      return (
        a.getType() !== b.getType() ||
        a.getUrl() !== b.getUrl() ||
        a.getThumbnailurl() !== b.getThumbnailurl()
      );
    });
  };

  // Determine unsaved changes
  const hasUnsavedChanges =
    !props.readOnly &&
    areMediaEntriesDifferent(
      mediaEntries.map((item) => item.mediaEntry),
      savedMediaEntries,
    );

  // Handle changes in readOnly prop
  useEffect(() => {
    if (props.readOnly) {
      // Save the current state when switching to readOnly
      setSavedMediaEntries(mediaEntries.map((item) => item.mediaEntry));
      // Reset current media entry form
      setCurrentMediaEntry(
        new MediaEntry().setType(currentMediaEntry.getType()),
      );
    }
  }, [props.readOnly]);

  // Helpers to handle image uploading
  const [uploading, setUploading] = useState(false);
  const fileInputRef = useRef<HTMLInputElement>(null);
  const handleFileSelect = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };
  const uploadFileToUrl = async (url: string, file: File) => {
    const response = await fetch(url, {
      method: "PUT",
      headers: {
        "Content-Type": file.type,
      },
      body: file,
    });
    if (!response.ok) {
      throw new Error("Failed to upload file");
    }
  };
  const handleFileUpload = async (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    if (!event.target.files || !event.target.files[0]) return;

    const file = event.target.files[0];
    setUploading(true);

    try {
      // Generate upload and download URLs
      const uploadResponse = await PublicMediaController.GenerateImageUploadUrl(
        {
          context: authContext,
        },
      );
      const uploadUrl = uploadResponse.url;

      // Upload the file
      await uploadFileToUrl(uploadUrl, file);

      // Generate download URL
      const downloadResponse =
        await PublicMediaController.GenerateImageDownloadUrl({
          context: authContext,
          mediaAssetID: uploadResponse.imageID,
        });
      const imageUrl = downloadResponse.url;

      // Update currentMediaEntry based on type
      setCurrentMediaEntry((prev) => {
        const updatedEntry = new MediaEntry().setType(prev.getType());
        if (prev.getType() === MediaEntryType.IMAGE_MEDIA_ENTRY_TYPE) {
          updatedEntry.setUrl(imageUrl).setThumbnailurl(imageUrl);
        } else if (
          prev.getType() === MediaEntryType.EMBEDDED_VIDEO_MEDIA_ENTRY_TYPE
        ) {
          updatedEntry.setUrl(prev.getUrl()).setThumbnailurl(imageUrl);
        }
        return updatedEntry;
      });
    } catch (error) {
      enqueueSnackbar("Failed to upload file. Please try again.", {
        variant: "error",
      });
    } finally {
      setUploading(false);
    }
  };

  const renderCurrentMediaEntryContent = () => {
    if (currentMediaEntry.getType() === MediaEntryType.IMAGE_MEDIA_ENTRY_TYPE) {
      return currentMediaEntry.getUrl() ? (
        <TextField
          id={"instrumentMarketingContent-currentMediaEntry-imageUrl-textField"}
          disabled={true}
          label={`${mediaEntryTypeToString(currentMediaEntry.getType())} Link`}
          value={currentMediaEntry.getUrl()}
          sx={{ flexGrow: 1 }}
        />
      ) : (
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            height: "100%",
            flexGrow: 1,
          }}
        >
          <input
            type="file"
            ref={fileInputRef}
            accept="image/*"
            style={{ display: "none" }}
            onChange={handleFileUpload}
          />
          <LoadingButton
            id={
              "instrumentMarketingContent-newMediaEntry-uploadImageUrl-loadingButton"
            }
            sx={{
              alignSelf: "stretch",
              mt: theme.spacing(1),
              mb: theme.spacing(0.6),
            }}
            variant="outlined"
            color="secondary"
            loading={uploading}
            onClick={handleFileSelect}
            disabled={uploading || props.disabled}
          >
            Upload Image
          </LoadingButton>
        </Box>
      );
    } else if (
      currentMediaEntry.getType() ===
      MediaEntryType.EMBEDDED_VIDEO_MEDIA_ENTRY_TYPE
    ) {
      return (
        <Box
          sx={{
            display: "flex",
            height: "100%",
            alignItems: "center",
            flexGrow: 1,
            gap: theme.spacing(2),
          }}
        >
          {/* URL Field */}
          <TextField
            id={
              "instrumentMarketingContent-currentMediaEntry-embeddedVideoUrl-textField"
            }
            disabled={props.disabled}
            label={`${mediaEntryTypeToString(currentMediaEntry.getType())} Link`}
            value={currentMediaEntry.getUrl()}
            placeholder={`Enter ${mediaEntryTypeToString(currentMediaEntry.getType()).toLowerCase()} link...`}
            sx={{
              width: "48%",
            }}
            onChange={(e) => {
              setCurrentMediaEntry((prev) =>
                new MediaEntry()
                  .setType(prev.getType())
                  .setUrl(e.target.value)
                  .setThumbnailurl(prev.getThumbnailurl()),
              );
            }}
          />

          {/* Thumbnail URL or Upload Button */}
          {currentMediaEntry.getThumbnailurl() ? (
            <TextField
              id={
                "instrumentMarketingContent-currentMediaEntry-thumbnailUrl-textField"
              }
              disabled={true}
              label={`Thumbnail Link`}
              value={currentMediaEntry.getThumbnailurl()}
              sx={{ flexGrow: 1 }}
            />
          ) : (
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                height: "100%",
              }}
            >
              <input
                type="file"
                ref={fileInputRef}
                accept="image/*"
                style={{ display: "none" }}
                onChange={handleFileUpload}
              />
              <LoadingButton
                id={
                  "instrumentMarketingContent-newMediaEntry-uploadThumbnailUrl-loadingButton"
                }
                sx={{
                  alignSelf: "stretch",
                  width: "auto",
                  mt: theme.spacing(1),
                  mb: theme.spacing(0.6),
                }}
                variant="outlined"
                color="secondary"
                loading={uploading}
                onClick={handleFileSelect}
                disabled={uploading || props.disabled}
              >
                Upload Thumbnail
              </LoadingButton>
            </Box>
          )}
        </Box>
      );
    }
    return (
      <Typography sx={{ color: theme.palette.text.secondary }}>
        Media Entry type not recognised
      </Typography>
    );
  };

  return (
    <Card
      sx={{
        width: "100%",
        display: "flex",
        flexDirection: "column",
        border: `1px solid ${theme.palette.custom.grape}`,
        backgroundColor: theme.palette.custom.midnight,
        borderRadius: theme.shape.borderRadius,
        p: theme.spacing(2),
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          mb: theme.spacing(2),
        }}
      >
        <Typography variant="h6">Images & Videos</Typography>
        {hasUnsavedChanges && (
          <Box
            sx={{
              width: 8,
              height: 8,
              borderRadius: "50%",
              backgroundColor: theme.palette.warning.light,
            }}
          />
        )}
      </Box>

      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
        }}
      >
        {/* Section with added media entries */}
        {!mediaEntries.length && props.readOnly ? (
          <NoDataSplashCard
            dataDescriptionTitle={"Media"}
            dataDescriptionBody={"Images and Videos"}
          />
        ) : (
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
            }}
          >
            <ReactSortable
              animation={200}
              easing="ease-out"
              disabled={props.readOnly}
              list={mediaEntries}
              style={{
                display: "flex",
                flexDirection: "column",
                gap: theme.spacing(2),
                cursor: props.readOnly ? "default" : "grab",
              }}
              setList={(newList) => {
                setMediaEntries(newList);
                props.onChange(newList.map((item) => item.mediaEntry));
              }}
            >
              {mediaEntries.map(({ id, mediaEntry }, idx) => (
                <Card
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    height: theme.spacing(11),
                    p: theme.spacing(2),
                  }}
                  key={id}
                >
                  <Box
                    sx={{
                      display: "flex",
                      width: "100%",
                      gap: theme.spacing(1.5),
                      alignItems: "center",
                    }}
                  >
                    <TextField
                      id={`instrumentMarketingContent-mediaEntry-${idx}-type-selectField`}
                      sx={{ width: "20%" }}
                      label={"Type"}
                      readOnly={true}
                      value={mediaEntryTypeToString(mediaEntry.getType())}
                    />
                    <Divider orientation="vertical" flexItem />
                    <TextField
                      id={`instrumentMarketingContent-mediaEntry-${idx}-url-textField`}
                      sx={{ width: "40%" }}
                      label={"Link"}
                      readOnly={true}
                      value={mediaEntry.getUrl()}
                    />
                    <Divider orientation="vertical" flexItem />
                    <TextField
                      id={`instrumentMarketingContent-mediaEntry-${idx}-thumbnailUrl-textField`}
                      sx={{ width: "35%" }}
                      label={"Thumbnail Link"}
                      readOnly={true}
                      value={mediaEntry.getThumbnailurl()}
                    />
                    <Box
                      sx={{
                        width: "5%",
                        height: theme.spacing(8),
                        borderRadius: 0.5,
                        overflow: "hidden",
                        display: "flex",
                      }}
                    >
                      <img
                        src={mediaEntry.getThumbnailurl()}
                        style={{
                          maxWidth: "100%",
                          maxHeight: "100%",
                          objectFit: "contain",
                          borderRadius: "inherit",
                        }}
                      />
                    </Box>
                    {!props.readOnly && (
                      <Divider orientation="vertical" flexItem />
                    )}
                  </Box>

                  {/* Delete Icon */}
                  {!props.readOnly && (
                    <Tooltip title="Delete Media Entry" placement="top">
                      <IconButton
                        id={`instrumentMarketingContent-mediaEntry-${idx}-delete-iconButton`}
                        disabled={props.disabled}
                        aria-label="delete"
                        size="medium"
                        sx={{ ml: theme.spacing(2) }}
                        onClick={() => {
                          const updatedMediaEntries = mediaEntries.filter(
                            (_, index) => index !== idx,
                          );
                          setMediaEntries(updatedMediaEntries);
                          props.onChange(
                            updatedMediaEntries.map((item) => item.mediaEntry),
                          );
                        }}
                      >
                        <DeleteIcon />
                      </IconButton>
                    </Tooltip>
                  )}
                </Card>
              ))}
            </ReactSortable>
          </Box>
        )}

        {/* Section for adding a new media entry */}
        {!props.readOnly && (
          <>
            <Box
              sx={{
                display: "flex",
                gap: theme.spacing(2),
                mt: theme.spacing(2),
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  height: "100%",
                  flexGrow: 1,
                  gap: theme.spacing(2),
                }}
              >
                <TextField
                  size="small"
                  id={
                    "instrumentMarketingContent-newMediaEntry-type-selectField"
                  }
                  label={"Type"}
                  disabled={uploading || props.disabled}
                  readOnly={props.readOnly}
                  select
                  placeholder="Select..."
                  value={currentMediaEntry.getType()}
                  sx={{ width: "20%" }}
                  onChange={(e) => {
                    if (e) {
                      setCurrentMediaEntry(() =>
                        new MediaEntry().setType(
                          Number(e.target.value) as MediaEntryType,
                        ),
                      );
                    }
                  }}
                >
                  {allMediaEntryTypes
                    .filter(
                      (type) =>
                        type !== MediaEntryType.UNDEFINED_MEDIA_ENTRY_TYPE,
                    )
                    .filter(
                      (type) => type !== MediaEntryType.VIDEO_MEDIA_ENTRY_TYPE,
                    )
                    .map((v) => (
                      <MenuItem key={v} value={v}>
                        {mediaEntryTypeToString(v)}
                      </MenuItem>
                    ))}
                </TextField>
                {renderCurrentMediaEntryContent()}
              </Box>
              <Box
                sx={{
                  display: "flex",
                  mr: theme.spacing(2),
                }}
              >
                <Tooltip title="Reset" placement="top">
                  <IconButton
                    id={
                      "instrumentMarketingContent-newMediaEntry-reset-iconButton"
                    }
                    aria-label="reset"
                    size="medium"
                    disabled={uploading}
                    onClick={() => {
                      setCurrentMediaEntry(
                        new MediaEntry().setType(currentMediaEntry.getType()),
                      );
                    }}
                  >
                    <ResetIcon />
                  </IconButton>
                </Tooltip>
              </Box>
            </Box>
            <Button
              id={"instrumentMarketingContent-newMediaEntry-add-button"}
              sx={{ mt: 2 }}
              variant="contained"
              color="primary"
              disabled={
                !currentMediaEntry.getType() || !currentMediaEntry.getUrl()
              }
              onClick={() => {
                const updatedEntry = new MediaEntry().setType(
                  currentMediaEntry.getType(),
                );

                if (
                  currentMediaEntry.getType() ===
                  MediaEntryType.EMBEDDED_VIDEO_MEDIA_ENTRY_TYPE
                ) {
                  const { embedUrl, thumbnailUrl } = youtubeUrlToEmbed(
                    currentMediaEntry.getUrl(),
                  );
                  updatedEntry.setUrl(embedUrl);

                  // If no thumbnail URL is provided, use the generated YouTube thumbnail URL
                  if (!currentMediaEntry.getThumbnailurl()) {
                    updatedEntry.setThumbnailurl(thumbnailUrl);
                  } else {
                    updatedEntry.setThumbnailurl(
                      currentMediaEntry.getThumbnailurl(),
                    );
                  }
                } else if (
                  currentMediaEntry.getType() ===
                  MediaEntryType.IMAGE_MEDIA_ENTRY_TYPE
                ) {
                  updatedEntry
                    .setUrl(currentMediaEntry.getUrl())
                    .setThumbnailurl(currentMediaEntry.getThumbnailurl());
                }

                const updatedMediaEntries = [
                  ...mediaEntries,
                  { id: mediaEntries.length, mediaEntry: updatedEntry },
                ];
                setMediaEntries(updatedMediaEntries);
                props.onChange(
                  updatedMediaEntries.map((item) => item.mediaEntry),
                );

                // Reset current media entry form
                setCurrentMediaEntry(
                  new MediaEntry().setType(currentMediaEntry.getType()),
                );
              }}
            >
              Add Media
            </Button>
          </>
        )}
      </Box>
    </Card>
  );
};

"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.newStellarAccountViewModel = newStellarAccountViewModel;
exports.getAvailableBalance = getAvailableBalance;
exports.getTokenBalance = getTokenBalance;
const bignumber_js_1 = require("bignumber.js");
const ledger_1 = require("../../ledger");
const num_1 = require("../../num");
const decimal_pb_1 = require("../../num/decimal_pb");
const model_pb_1 = require("./model_pb");
function newStellarAccountViewModel(model) {
    const m = new model_pb_1.Model();
    if (!model)
        return m;
    m.setId(model.getId())
        .setOwnerid(model.getOwnerid())
        .setNumber(model.getNumber())
        .setLabel(model.getLabel())
        .setState(model.getState())
        .setNetwork(model.getNetwork())
        .setLedgerid(model.getLedgerid())
        .setAuditentry(model.getAuditentry())
        .setBalancesList(model.getBalancesList())
        .setSignatoriesList(model.getSignatoriesList())
        .setAuthflags(model.getAuthflags())
        .setGroupname(model.getGroupname())
        .setClientname(model.getClientname())
        .setCreatedtime(model.getCreatedtime());
    return m;
}
function getAvailableBalance(b) {
    var _a, _b, _c, _d, _e, _f;
    const amount = (0, num_1.decimalToBigNumber)((_b = (_a = b === null || b === void 0 ? void 0 : b.getAmount()) === null || _a === void 0 ? void 0 : _a.getValue()) !== null && _b !== void 0 ? _b : new decimal_pb_1.Decimal());
    const sellingLiabilities = (0, num_1.decimalToBigNumber)((_d = (_c = b === null || b === void 0 ? void 0 : b.getSellingliabilities()) === null || _c === void 0 ? void 0 : _c.getValue()) !== null && _d !== void 0 ? _d : new decimal_pb_1.Decimal());
    // get available balance subtracting only selling liabilities
    // (buying liabilities should not decrease to available balance since it's going to credit your account in the future)
    const balance = amount.minus(sellingLiabilities);
    // return either the positive balance or zero
    if (balance.isPositive()) {
        return new ledger_1.TokenWrapper((_e = b === null || b === void 0 ? void 0 : b.getAmount()) === null || _e === void 0 ? void 0 : _e.getToken()).newAmountOf(balance);
    }
    else {
        return new ledger_1.TokenWrapper((_f = b === null || b === void 0 ? void 0 : b.getAmount()) === null || _f === void 0 ? void 0 : _f.getToken()).newAmountOf(new bignumber_js_1.BigNumber("0"));
    }
}
function getTokenBalance(account, token) {
    var _a;
    if (!token) {
        return new model_pb_1.Balance();
    }
    for (const balance of account.getBalancesList()) {
        if (new ledger_1.TokenWrapper((_a = balance.getAmount()) === null || _a === void 0 ? void 0 : _a.getToken()).isEqualTo(token)) {
            return balance;
        }
    }
}

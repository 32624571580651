import React from "react";
import { Box, Button, Typography } from "@mui/material";
import background from "./background.jpg";
import {
  DataLinkInfoType,
  InteractionAction,
  InteractionDriver,
  InteractionType,
} from "const/gtm";

export const SecondaryMarketBanner = () => {
  return (
    <Box
      id="secondary_market_banner"
      sx={{
        height: "200px",
        backgroundSize: "1280px",
        display: "flex",
        justifyContent: "flex-end",
        alignItems: "center",
        backgroundImage: `url(${background})`,
      }}
      data-component-info={JSON.stringify({
        component_id: "hero_banner",
        component_business_name: "hero banner",
        component_title: "secondary market",
        component_driver: "showcase",
      })}
      data-testid="secondary_market_banner"
    >
      <Box
        sx={(theme) => ({
          width: {
            lg: 550,
            md: 450,
            sm: 250,
          },
          ml: {
            lg: "50px",
            md: "80px",
            sm: "60px",
          },
          mr: {
            lg: "118px",
            md: "100px",
            sm: "60px",
          },
          "& .red": {
            color: theme.palette.primary.main,
          },
        })}
      >
        <Box>
          <Typography
            textAlign="start"
            fontSize={28}
            letterSpacing={0}
            lineHeight={"32px"}
            pb={"4px"}
          >
            Secondary Market Now Open
          </Typography>
          <Typography
            textAlign="start"
            fontSize={18}
            letterSpacing={0}
            color={"#cfc501"}
            pb={"16px"}
          >
            Die MOS 10Y Prime +2% Floating Rate Bond
          </Typography>
        </Box>
        <Button
          id="secondaryMarket-viewAsset-button"
          variant="contained"
          color="primary"
          href="https://app.mesh.trade/public/market/asset-overview?code=ZAMOSBFL0001&issuer=GD7TASWQ6E2LIZ4XP3QWRJEKGRRXPQUPQWGKHWVQ3W4JK5ON5G7BV4QN&network=Stellar%20
Public&utm_source=mesh-app&utm_medium=organic&utm_campaign=mtg_grow_ttl_mos-secondary-markets_ssc-61_aug-24_dec-24_za_pfmza_usage_mesh-app_cro_none_none_none&utm_marketi
ng_tactic=cro&utm_creative_format=image&utm_id=mtg_grow_ttl_mos-secondary-markets_ssc-61&utm_content=mos-secondary-markets-banner&utm_term=organic_mos-secondary-markets-
banner&utm_source_platform=carousel-banners"
          rel="noopener noreferrer"
          target="_blank"
          data-link-info={JSON.stringify({
            content_interaction_id: "hero-banner-link",
            content_interaction_action: InteractionAction.Click,
            content_interaction_text: "view asset",
            content_interaction_type: InteractionType.Button,
            content_interaction_driver: InteractionDriver.Explore,
          } as DataLinkInfoType)}
        >
          View Asset
        </Button>
      </Box>
    </Box>
  );
};

"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.allMediaEntryTypes = void 0;
exports.mediaEntryTypeToString = mediaEntryTypeToString;
exports.stringToMediaEntryType = stringToMediaEntryType;
const mediaEntryType_pb_1 = require("./mediaEntryType_pb");
// Get all media entry types as enum values
exports.allMediaEntryTypes = Object.values(mediaEntryType_pb_1.MediaEntryType).filter((value) => typeof value === "number");
// Define explicit mappings between MediaEntryType enums and custom string representations
const mediaEntryTypeToStringMapping = {
    [mediaEntryType_pb_1.MediaEntryType.UNDEFINED_MEDIA_ENTRY_TYPE]: "-",
    [mediaEntryType_pb_1.MediaEntryType.IMAGE_MEDIA_ENTRY_TYPE]: "Image",
    [mediaEntryType_pb_1.MediaEntryType.VIDEO_MEDIA_ENTRY_TYPE]: "Video",
    [mediaEntryType_pb_1.MediaEntryType.EMBEDDED_VIDEO_MEDIA_ENTRY_TYPE]: "Embedded Video",
};
// Reverse mapping from string to MediaEntryType enum
const stringToMediaEntryTypeMapping = {};
for (const [key, value] of Object.entries(mediaEntryTypeToStringMapping)) {
    stringToMediaEntryTypeMapping[value] = Number(key);
}
class UnsupportedMediaEntryTypeError extends Error {
    constructor(mediaEntryType) {
        const message = `Unsupported MediaEntryType: ${mediaEntryType}`;
        super(message);
        this.mediaEntryType = mediaEntryType;
    }
}
/**
 * Converts a MediaEntryType enum instance to a custom string representation.
 * @param {MediaEntryType} mediaEntryType - The media entry type to convert.
 * @returns {string} The custom string representation of the media entry type.
 */
function mediaEntryTypeToString(mediaEntryType) {
    if (mediaEntryType in mediaEntryTypeToStringMapping) {
        return mediaEntryTypeToStringMapping[mediaEntryType];
    }
    else {
        throw new UnsupportedMediaEntryTypeError(mediaEntryType);
    }
}
class UnsupportedMediaEntryTypeStringError extends Error {
    constructor(mediaEntryTypeStr) {
        const message = `Unsupported media entry type string: ${mediaEntryTypeStr}`;
        super(message);
        this.mediaEntryTypeStr = mediaEntryTypeStr;
    }
}
/**
 * Converts a custom string representation to a MediaEntryType enum instance.
 * @param {string} mediaEntryTypeStr - The custom string representation of the media entry type.
 * @returns {MediaEntryType} The corresponding MediaEntryType enum instance.
 */
function stringToMediaEntryType(mediaEntryTypeStr) {
    if (mediaEntryTypeStr in stringToMediaEntryTypeMapping) {
        return stringToMediaEntryTypeMapping[mediaEntryTypeStr];
    }
    else {
        throw new UnsupportedMediaEntryTypeStringError(mediaEntryTypeStr);
    }
}

/**
 * @fileoverview gRPC-Web generated client stub for financial
 * @enhanceable
 * @public
 */

// Code generated by protoc-gen-grpc-web. DO NOT EDIT.
// versions:
// 	protoc-gen-grpc-web v1.5.0
// 	protoc              v5.28.0
// source: financial/assetflowDeferralReader_meshproto.proto


/* eslint-disable */
// @ts-nocheck



const grpc = {};
grpc.web = require('grpc-web');


var security_context_pb = require('../security/context_pb.js')

var search_criterion_pb = require('../search/criterion_pb.js')

var search_query_pb = require('../search/query_pb.js')

var search_sorting_pb = require('../search/sorting_pb.js')

var financial_assetflowDeferral_pb = require('../financial/assetflowDeferral_pb.js')

var ledger_futureAmount_pb = require('../ledger/futureAmount_pb.js')
const proto = {};
proto.financial = require('./assetflowDeferralReader_meshproto_pb.js');

/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?grpc.web.ClientOptions} options
 * @constructor
 * @struct
 * @final
 */
proto.financial.AssetflowDeferralReaderClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options.format = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname.replace(/\/+$/, '');

};


/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?grpc.web.ClientOptions} options
 * @constructor
 * @struct
 * @final
 */
proto.financial.AssetflowDeferralReaderPromiseClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options.format = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname.replace(/\/+$/, '');

};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.financial.ReadOneAssetflowDeferralRequest,
 *   !proto.financial.ReadOneAssetflowDeferralResponse>}
 */
const methodDescriptor_AssetflowDeferralReader_ReadOneAssetflowDeferral = new grpc.web.MethodDescriptor(
  '/financial.AssetflowDeferralReader/ReadOneAssetflowDeferral',
  grpc.web.MethodType.UNARY,
  proto.financial.ReadOneAssetflowDeferralRequest,
  proto.financial.ReadOneAssetflowDeferralResponse,
  /**
   * @param {!proto.financial.ReadOneAssetflowDeferralRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.financial.ReadOneAssetflowDeferralResponse.deserializeBinary
);


/**
 * @param {!proto.financial.ReadOneAssetflowDeferralRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.financial.ReadOneAssetflowDeferralResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.financial.ReadOneAssetflowDeferralResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.financial.AssetflowDeferralReaderClient.prototype.readOneAssetflowDeferral =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/financial.AssetflowDeferralReader/ReadOneAssetflowDeferral',
      request,
      metadata || {},
      methodDescriptor_AssetflowDeferralReader_ReadOneAssetflowDeferral,
      callback);
};


/**
 * @param {!proto.financial.ReadOneAssetflowDeferralRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.financial.ReadOneAssetflowDeferralResponse>}
 *     Promise that resolves to the response
 */
proto.financial.AssetflowDeferralReaderPromiseClient.prototype.readOneAssetflowDeferral =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/financial.AssetflowDeferralReader/ReadOneAssetflowDeferral',
      request,
      metadata || {},
      methodDescriptor_AssetflowDeferralReader_ReadOneAssetflowDeferral);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.financial.ReadManyAssetflowDeferralRequest,
 *   !proto.financial.ReadManyAssetflowDeferralResponse>}
 */
const methodDescriptor_AssetflowDeferralReader_ReadManyAssetflowDeferral = new grpc.web.MethodDescriptor(
  '/financial.AssetflowDeferralReader/ReadManyAssetflowDeferral',
  grpc.web.MethodType.UNARY,
  proto.financial.ReadManyAssetflowDeferralRequest,
  proto.financial.ReadManyAssetflowDeferralResponse,
  /**
   * @param {!proto.financial.ReadManyAssetflowDeferralRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.financial.ReadManyAssetflowDeferralResponse.deserializeBinary
);


/**
 * @param {!proto.financial.ReadManyAssetflowDeferralRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.financial.ReadManyAssetflowDeferralResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.financial.ReadManyAssetflowDeferralResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.financial.AssetflowDeferralReaderClient.prototype.readManyAssetflowDeferral =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/financial.AssetflowDeferralReader/ReadManyAssetflowDeferral',
      request,
      metadata || {},
      methodDescriptor_AssetflowDeferralReader_ReadManyAssetflowDeferral,
      callback);
};


/**
 * @param {!proto.financial.ReadManyAssetflowDeferralRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.financial.ReadManyAssetflowDeferralResponse>}
 *     Promise that resolves to the response
 */
proto.financial.AssetflowDeferralReaderPromiseClient.prototype.readManyAssetflowDeferral =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/financial.AssetflowDeferralReader/ReadManyAssetflowDeferral',
      request,
      metadata || {},
      methodDescriptor_AssetflowDeferralReader_ReadManyAssetflowDeferral);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.financial.ReadAllAssetflowDeferralRequest,
 *   !proto.financial.ReadAllAssetflowDeferralResponse>}
 */
const methodDescriptor_AssetflowDeferralReader_ReadAllAssetflowDeferral = new grpc.web.MethodDescriptor(
  '/financial.AssetflowDeferralReader/ReadAllAssetflowDeferral',
  grpc.web.MethodType.UNARY,
  proto.financial.ReadAllAssetflowDeferralRequest,
  proto.financial.ReadAllAssetflowDeferralResponse,
  /**
   * @param {!proto.financial.ReadAllAssetflowDeferralRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.financial.ReadAllAssetflowDeferralResponse.deserializeBinary
);


/**
 * @param {!proto.financial.ReadAllAssetflowDeferralRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.financial.ReadAllAssetflowDeferralResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.financial.ReadAllAssetflowDeferralResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.financial.AssetflowDeferralReaderClient.prototype.readAllAssetflowDeferral =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/financial.AssetflowDeferralReader/ReadAllAssetflowDeferral',
      request,
      metadata || {},
      methodDescriptor_AssetflowDeferralReader_ReadAllAssetflowDeferral,
      callback);
};


/**
 * @param {!proto.financial.ReadAllAssetflowDeferralRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.financial.ReadAllAssetflowDeferralResponse>}
 *     Promise that resolves to the response
 */
proto.financial.AssetflowDeferralReaderPromiseClient.prototype.readAllAssetflowDeferral =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/financial.AssetflowDeferralReader/ReadAllAssetflowDeferral',
      request,
      metadata || {},
      methodDescriptor_AssetflowDeferralReader_ReadAllAssetflowDeferral);
};


module.exports = proto.financial;


import {
  SmartInstrument,
  SmartInstrumentState,
} from "@mesh/common-js/dist/financial/smartInstrument_pb";
import {
  MarketListingViewModel,
  Reader as MarketListingViewReader,
} from "james/views/marketListingView";
import {
  Model as AssetHolderViewModel,
  Reader,
} from "james/views/ledgerAssetHolderView";
import React, { useContext, useEffect, useState } from "react";
import { useApplicationContext } from "context/Application/Application";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useIsMounted } from "hooks";
import { useErrorContext } from "context/Error";
import { useAPIContext } from "context/API";
import { AssetFetcher, FetchAssetRequest, Token } from "james/ledger";
import { FutureToken } from "@mesh/common-js/dist/ledger/futureToken_pb";
import { AssetWrapper } from "james/financial/AssetWrapper";
import { networkFromFutureNetwork } from "james/ledger/Network";
import { ReadOneSmartInstrumentRequest } from "@mesh/common-js/dist/financial/smartInstrumentReader_meshproto_pb";
import {
  newTextExactCriterion,
  newUint32ExactCriterion,
} from "@mesh/common-js/dist/search";
import { TokenIdentifier } from "james/search/identifier";
import { TextExactCriterion } from "james/search/criterion";
import { AssetInspector } from "james/financial/AssetInspector";
import { Asset } from "james/financial/Asset";
import dayjs from "dayjs";

export interface AssetInformation {
  issuerName: string | undefined;
  assetName: string | undefined;
  assetIssuedUnits: number;
  assetState: string | undefined;
  assetMaturityDate: string | undefined;
}

export type SecuritiesRegisterContextType = {
  apiCallInProgress: boolean;
  initialised: boolean;
  initialisationError: string | null;
  clearInitialisationError: () => void;
  marketListingViewModel: MarketListingViewModel | undefined;
  assetInformation: AssetInformation | undefined;
  assetHolderViewModels: AssetHolderViewModel[];
};

const SecuritiesRegisterContext =
  React.createContext<SecuritiesRegisterContextType>({
    apiCallInProgress: true,
    initialised: false,
    initialisationError: null,
    clearInitialisationError: () => {},
    marketListingViewModel: new MarketListingViewModel(),
    assetInformation: {
      issuerName: "",
      assetName: "",
      assetIssuedUnits: 0,
      assetState: "",
      assetMaturityDate: "-",
    },
    assetHolderViewModels: [],
  });

export const useSecuritiesRegisterContext = () =>
  useContext(SecuritiesRegisterContext);

export const SecuritiesRegisterContextProvider = ({
  system,
  children,
}: {
  system: boolean;
  children: React.ReactNode;
}) => {
  const { authContext } = useApplicationContext();
  const { errorContextErrorTranslator, errorContextDefaultErrorFeedback } =
    useErrorContext();
  const navigate = useNavigate();
  const isMounted = useIsMounted();
  const [searchParams] = useSearchParams();

  const {
    financial: { smartInstrumentReader, smartInstrumentReaderUNSCOPED },
  } = useAPIContext();

  const [loading, setLoading] = useState(true);
  const [dataLoadError, setDataLoadError] = useState<string | null>(null);
  const [asset, setAsset] = useState<Asset | undefined>();
  const [smartInstrument, setSmartInstrument] = useState<
    SmartInstrument | undefined
  >();

  const [marketListingViewModel, setMarketListingViewModel] = useState<
    MarketListingViewModel | undefined
  >();
  const [assetHolderViewModels, setAssetHolderViewModels] = useState<
    AssetHolderViewModel[]
  >([]);
  const [assetInformation, setAssetInformation] = useState<
    AssetInformation | undefined
  >();

  const [token, setToken] = useState<Token>(new Token());

  useEffect(() => {
    if (dataLoadError || !isMounted()) {
      return;
    }

    // get the token for the asset that is to be listed from the url
    const tokenCode = searchParams.get("code");
    const tokenIssuer = searchParams.get("issuer");
    const tokenNetwork = Number(searchParams.get("network"));

    if (!tokenCode || !tokenIssuer || !tokenNetwork) {
      console.error("token not found in url");
      navigate({
        pathname: "/builder",
      });
      return;
    }

    const futureToken = new FutureToken().setNetwork(tokenNetwork);
    setToken(
      new Token({
        code: tokenCode.toUpperCase(),
        issuer: tokenIssuer.toUpperCase(),
        network: networkFromFutureNetwork(futureToken.getNetwork()),
      }),
    );
  }, [dataLoadError, isMounted]);

  // Get the asset
  useEffect(() => {
    (async () => {
      if (token.isUndefined() || !isMounted()) {
        return;
      }

      try {
        // Try to fetch the smart instrument first
        const smartInstrument = (
          system
            ? await smartInstrumentReaderUNSCOPED.readOneSmartInstrumentUNSCOPED(
                new ReadOneSmartInstrumentRequest()
                  .setContext(authContext.toFuture())
                  .setCriteriaList([
                    newTextExactCriterion("token.code", token.code),
                    newTextExactCriterion("token.issuer", token.issuer),
                    newUint32ExactCriterion(
                      "token.network",
                      token.toFutureToken().getNetwork(),
                    ),
                  ]),
              )
            : await smartInstrumentReader.readOneSmartInstrument(
                new ReadOneSmartInstrumentRequest()
                  .setContext(authContext.toFuture())
                  .setCriteriaList([
                    newTextExactCriterion("token.code", token.code),
                    newTextExactCriterion("token.issuer", token.issuer),
                    newUint32ExactCriterion(
                      "token.network",
                      token.toFutureToken().getNetwork(),
                    ),
                  ]),
              )
        ).getSmartinstrument();

        if (smartInstrument === undefined) {
          throw new Error("instrument not found");
        }

        let maturityDate = "-";
        smartInstrument.getLegsList().forEach((leg) => {
          if (leg.getBulletsmartinstrumentleg() !== undefined) {
            const legDate = leg
              .getBulletsmartinstrumentleg()
              ?.getDate()
              ?.toDate();
            if (legDate !== undefined) {
              maturityDate = dayjs(legDate).format("YYYY-MM-DD");
            } else {
              maturityDate = "-";
            }
          }
        });

        setAssetInformation({
          issuerName: "",
          assetName: smartInstrument.getName() ?? "",
          assetState: smartInstrument.getState().toString() ?? "",
          assetIssuedUnits: 0,
          assetMaturityDate: maturityDate,
        });
        setSmartInstrument(smartInstrument);
        setAsset(new AssetWrapper(smartInstrument));
      } catch (e: unknown) {
        if (`${e}`.includes("not found")) {
          try {
            const fetchAssetRequest: FetchAssetRequest = {
              context: authContext,
              identifier: TokenIdentifier(token, "token"),
            };
            const fetchedAsset = (
              await AssetFetcher.FetchAsset(fetchAssetRequest)
            ).asset as Asset;

            setAsset(fetchedAsset);
            setAssetInformation({
              issuerName: "",
              assetName: fetchedAsset?.assetName().toString() ?? "",
              // @ts-expect-error: ts(2339) FIXME: this will work after migration to smart instruments
              assetState: fetchedAsset?.state ?? "Issued",
              assetIssuedUnits: 0,
              assetMaturityDate: dayjs(
                // @ts-expect-error: ts(2339) FIXME: this will work after migration to smart instruments
                fetchedAsset?.maturityDate ?? undefined,
              ).format("YYYY-MM-DD"),
            });
          } catch (fetchError: unknown) {
            errorContextDefaultErrorFeedback(
              fetchError,
              "error fetching asset",
            );
            setDataLoadError(String(fetchError));
            setAsset(undefined);
          }
        } else {
          errorContextDefaultErrorFeedback(
            e,
            "error fetching smart instrument",
          );
          setDataLoadError(String(e));
          setAsset(undefined);
        }
      }
    })();
  }, [token, isMounted]);

  // Get the asset holders
  useEffect(() => {
    (async () => {
      if (token.isUndefined() || !isMounted()) {
        return;
      }

      try {
        const assetHolderViewModels = (
          await Reader.Read({
            context: authContext,
            token: token,
          })
        ).models;

        if (assetHolderViewModels === undefined) {
          throw new Error("asset holder view models not found");
        }
        setAssetHolderViewModels(assetHolderViewModels);
      } catch (e) {
        const err = errorContextErrorTranslator.translateError(e);
        setDataLoadError(err.message);
        errorContextDefaultErrorFeedback(
          e,
          "error retrieving asset holder view models",
        );
        setAssetHolderViewModels([]);
      }
    })();
  }, [token, isMounted]);

  // Get the market listing view model
  useEffect(() => {
    (async () => {
      if (token.isUndefined() || !isMounted) {
        return;
      }

      try {
        const marketListingViewModel = (
          await MarketListingViewReader.ReadOne({
            context: authContext,
            criteria: {
              "token.code": TextExactCriterion(token.code),
              "token.issuer": TextExactCriterion(token.issuer),
              "token.network": TextExactCriterion(token.network),
            },
          })
        ).model;

        if (marketListingViewModel.id === "") {
          throw new TypeError("market listing view model is not found");
        }
        setMarketListingViewModel(marketListingViewModel);
      } catch (e) {
        const err = errorContextErrorTranslator.translateError(e);
        setDataLoadError(err.message);
        errorContextDefaultErrorFeedback(
          e,
          "error retrieving market listing view model",
        );
        setMarketListingViewModel(undefined);
      }
    })();
  }, [token, isMounted]);

  // Get asset information
  useEffect(() => {
    (async () => {
      if (asset == undefined || token.isUndefined() || !isMounted()) {
        return;
      }

      try {
        const [totalIssuedUnitsResponse, issuerNameResponse] =
          await Promise.all([
            AssetInspector.GetTotalIssuedUnitsByToken({
              token: token,
            }),
            AssetInspector.GetAssetTokenIssuerName({
              token: token,
            }),
          ]);
        const issuerName = issuerNameResponse.name;
        const totalIssuedUnits =
          totalIssuedUnitsResponse.amount.value.toNumber();

        let assetState;
        if (asset["@type"] === "Asset Wrapper") {
          assetState = (
            smartInstrument?.getState() ??
            SmartInstrumentState.UNDEFINED_SMART_INSTRUMENT_STATE
          ).toString();
        } else {
          // @ts-expect-error: ts(2339) FIXME: this will work after migration to smart instruments
          assetState = asset?.state ?? "Issued";
        }
        setAssetInformation({
          issuerName: issuerName,
          assetName: assetInformation?.assetName ?? "",
          assetState: assetState,
          assetIssuedUnits: totalIssuedUnits,
          assetMaturityDate: assetInformation?.assetMaturityDate ?? "-",
        });
      } catch (e) {
        const err = errorContextErrorTranslator.translateError(e);
        setDataLoadError(err.message);
        errorContextDefaultErrorFeedback(
          e,
          "error retrieving asset information",
        );
        setAssetInformation(undefined);
      }
    })();
  }, [asset, isMounted]);

  useEffect(() => {
    if (
      marketListingViewModel === undefined ||
      assetInformation === undefined ||
      assetHolderViewModels === undefined
    ) {
      setLoading(true);
    }
    setLoading(false);
  }, [marketListingViewModel, assetInformation, assetHolderViewModels]);

  return (
    <SecuritiesRegisterContext.Provider
      value={{
        apiCallInProgress: loading,
        initialised: !dataLoadError,
        initialisationError: dataLoadError,
        clearInitialisationError: () => setDataLoadError(null),
        marketListingViewModel: marketListingViewModel,
        assetInformation: assetInformation,
        assetHolderViewModels: assetHolderViewModels,
      }}
    >
      {children}
    </SecuritiesRegisterContext.Provider>
  );
};

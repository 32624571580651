import { SmartInstrument } from "@mesh/common-js/dist/financial/smartInstrument_pb";
import {
  ScheduleConfigurationWrapper,
  SmartInstrumentLegWrapper,
  businessDayConventionToString,
  calendarToString,
  dateGenerationRuleToString,
  dayCountConventionToString,
  frequencyToString,
  timeUnitToString,
  scheduleConfigurationTypeToString,
  smartInstrumentStateToString,
  unitToString,
} from "@mesh/common-js/dist/financial";
import { Workbook, Worksheet } from "exceljs";
import { protobufTimestampToDayjs } from "@mesh/common-js/dist/googleProtobufConverters";
import { Timestamp } from "google-protobuf/google/protobuf/timestamp_pb";
import { SmartInstrumentLegType } from "@mesh/common-js/dist/financial/smartInstrumentLegType_pb";
import { SmartInstrumentLeg } from "@mesh/common-js/dist/financial/smartInstrumentLeg_pb";
import { BulletSmartInstrumentLeg } from "@mesh/common-js/dist/financial/smartInstrumentLegBullet_pb";
import { assetflowCategoryToString } from "@mesh/common-js/dist/financial/assetFlowCategory";
import { decimalToBigNumber } from "@mesh/common-js/dist/num";
import { ScheduleConfiguration } from "@mesh/common-js/dist/financial/scheduleConfiguration_pb";
import { NonPerpetualScheduleConfiguration } from "@mesh/common-js/dist/financial/scheduleConfigurationNonPerpetual_pb";
import { ScheduleConfigurationType } from "@mesh/common-js/dist/financial/scheduleConfigurationType_pb";
import { FloatingRateSmartInstrumentLeg } from "@mesh/common-js/dist/financial/smartInstrumentLegFloatingRate_pb";
import { timezoneToString } from "@mesh/common-js/dist/i8n";
import { RateReset } from "@mesh/common-js/dist/financial/rateReset_pb";
import { Assetflow } from "@mesh/common-js/dist/financial/assetflow_pb";
import { TimeUnit } from "@mesh/common-js/dist/financial/timeUnit_pb";
import { BusinessDayConvention } from "@mesh/common-js/dist/financial/businessDayConvention_pb";

export function smartInstrumentToExcelWorkbook(
  smartInstrument: SmartInstrument,
  wb: Workbook,
) {
  // write instrument
  const instrumentSheet = wb.addWorksheet("Instrument");
  Object.entries({
    State: smartInstrumentStateToString(smartInstrument.getState()),
    Name: smartInstrument.getName(),
    Token: smartInstrument.getToken()?.getCode(),
    "Issue Date": protobufTimestampToDayjs(
      smartInstrument.getIssuedate() ?? new Timestamp(),
    ).toDate(),
    Timezone: timezoneToString(smartInstrument.getTimezone()),
    "Unit Category": unitToString(smartInstrument.getUnit()),
  }).forEach((entry) => instrumentSheet.addRow([entry[0], entry[1]]));

  // write legs
  smartInstrument
    .getLegsList()
    .forEach((leg, idx) => writeSmartInstrumentLeg(leg, idx, wb));

  return wb;
}

function writeSmartInstrumentLeg(
  leg: SmartInstrumentLeg,
  idx: number,
  wb: Workbook,
) {
  switch (new SmartInstrumentLegWrapper(leg).smartInstrumentLegType) {
    case SmartInstrumentLegType.UNDEFINED_SMART_INSTRUMENT_LEG_TYPE:
      return;

    case SmartInstrumentLegType.BULLET_SMART_INSTRUMENT_LEG_TYPE:
      {
        const l = leg.getBulletsmartinstrumentleg();
        if (l) {
          writeBulletSmartInstrumentLeg(l, idx, wb);
        }
      }
      break;

    case SmartInstrumentLegType.FLOATING_RATE_SMART_INSTRUMENT_LEG_TYPE:
      {
        const l = leg.getFloatingratesmartinstrumentleg();
        if (l) {
          writeFloatingRateSmartInstrumentLeg(l, idx, wb);
        }
      }
      break;
  }
}

const illegalSheetNameChars = ["*", "?", ":", "\\", "/", "[", "]"];

function writeBulletSmartInstrumentLeg(
  leg: BulletSmartInstrumentLeg,
  idx: number,
  wb: Workbook,
) {
  let sheetName = `${idx} - ${leg.getName()}`;
  illegalSheetNameChars.forEach((c) => {
    sheetName = sheetName.replaceAll(c, "");
  });
  const legSheet = wb.addWorksheet(sheetName);
  Object.entries({
    Name: leg.getName(),
    "Assetflow Category": assetflowCategoryToString(leg.getAssetflowcategory()),
    Asset: leg.getAmount()?.getToken()?.getCode(),
    Amount: decimalToBigNumber(leg.getAmount()?.getValue()).toNumber(),
    Date: protobufTimestampToDayjs(leg.getDate() ?? new Timestamp()).toDate(),
    Calendars: leg
      .getCalendarsList()
      .map((c) => calendarToString(c))
      .join(", "),
    "Business Day Convention": businessDayConventionToString(
      leg.getBusinessdayconvention(),
    ),
  }).forEach((entry) => legSheet.addRow([entry[0], entry[1]]));
}

function writeFloatingRateSmartInstrumentLeg(
  leg: FloatingRateSmartInstrumentLeg,
  idx: number,
  wb: Workbook,
) {
  let sheetName = `${idx} - ${leg.getName()}`;
  illegalSheetNameChars.forEach((c) => {
    sheetName = sheetName.replaceAll(c, "");
  });
  const legSheet = wb.addWorksheet(sheetName);
  Object.entries({
    Name: leg.getName(),
    "Assetflow Category": assetflowCategoryToString(leg.getAssetflowcategory()),
    "Notional Asset": leg.getNotional()?.getToken()?.getCode(),
    "Notional Amount": decimalToBigNumber(
      leg.getNotional()?.getValue(),
    ).toNumber(),
    "Day Count Convention": dayCountConventionToString(
      leg.getDaycountconvention(),
    ),
    "Reference Rate": leg.getReferenceratesourceid(),
    "Reference Rate Factor": decimalToBigNumber(
      leg.getReferenceratefactor(),
    ).toNumber(),
    "Rate Spread": decimalToBigNumber(leg.getSpread()).toNumber(),
    "Rate Floor": decimalToBigNumber(leg.getFloor()).toNumber(),
    "Rate Reset Period Unit": timeUnitToString(
      leg.getRateresetperiod()?.getPeriod()?.getTimeunit() ??
        TimeUnit.UNDEFINED_TIME_UNIT,
    ),
    "Rate Reset Period Count":
      leg.getRateresetperiod()?.getPeriod()?.getCount() ?? 0,
    "Rate Reset Business Day Convention": businessDayConventionToString(
      leg.getRateresetperiod()?.getBusinessdayconvention() ??
        BusinessDayConvention.UNDEFINED_BUSINESS_DAY_CONVENTION,
    ),
    Deferrability: "?????????????",
  }).forEach((entry) => legSheet.addRow([entry[0], entry[1]]));

  const scheduleConfig = leg.getScheduleconfiguration();
  if (!scheduleConfig) {
    return;
  }
  legSheet.addRow([
    "Schedule Configuration",
    scheduleConfigurationTypeToString(
      new ScheduleConfigurationWrapper(scheduleConfig)
        .scheduleConfigurationType,
    ),
  ]);
  writeScheduleConfiguration(scheduleConfig, legSheet);
}

function writeScheduleConfiguration(
  config: ScheduleConfiguration,
  ws: Worksheet,
) {
  switch (new ScheduleConfigurationWrapper(config).scheduleConfigurationType) {
    case ScheduleConfigurationType.UNDEFINED_SCHEDULE_CONFIGURATION_TYPE:
      return;

    case ScheduleConfigurationType.PERPETUAL_SCHEDULE_CONFIGURATION_TYPE:
      return;

    case ScheduleConfigurationType.NON_PERPETUAL_SCHEDULE_CONFIGURATION_TYPE: {
      const c = config.getNonperpetualscheduleconfiguration();
      if (!c) {
        return;
      }
      writeNonPerpetualScheduleConfiguration(c, ws);
      return;
    }
  }
}

function writeNonPerpetualScheduleConfiguration(
  config: NonPerpetualScheduleConfiguration,
  ws: Worksheet,
) {
  Object.entries({
    "Start Date": protobufTimestampToDayjs(
      config.getStartdate() ?? new Timestamp(),
    ).toDate(),
    "First Scheduled Date": config.getStartdate()
      ? protobufTimestampToDayjs(
          config.getStartdate() ?? new Timestamp(),
        ).toDate()
      : null,
    "End Date": protobufTimestampToDayjs(
      config.getEnddate() ?? new Timestamp(),
    ).toDate(),
    "Second to Last Scheduled Date": config.getSecondtolastscheduleddate()
      ? protobufTimestampToDayjs(
          config.getSecondtolastscheduleddate() ?? new Timestamp(),
        ).toDate()
      : null,
    Frequency: frequencyToString(config.getFrequency()),
    "Business Day Convention": businessDayConventionToString(
      config.getBusinessdayconvention(),
    ),
    "End Date Business Day Convention": businessDayConventionToString(
      config.getEnddatebusinessdayconvention(),
    ),
    Calendars: config
      .getCalendarsList()
      .map((c) => calendarToString(c))
      .join(", "),
    "End of Month Convention": config.getEndofmonthadjustment() ? "Yes" : "No",
    "Date Generation Rule": dateGenerationRuleToString(
      config.getDategenerationrule(),
    ),
  }).forEach((entry) => ws.addRow([entry[0], entry[1]]));
}

export function writeRateResetsToExcelWorkbook(
  rateResets: RateReset[],
  wb: Workbook,
  smartInstrumentLegs: SmartInstrumentLeg[],
) {
  const legNameIdx: { [key: string]: string } = smartInstrumentLegs.reduce(
    (prev, cur) => ({
      ...prev,
      [new SmartInstrumentLegWrapper(cur).id]: new SmartInstrumentLegWrapper(
        cur,
      ).name,
    }),
    {},
  );
  const instrumentSheet = wb.addWorksheet("Rate Resets");

  instrumentSheet.addRow(["No.", "Seq. No.", "Leg", "Fixed", "Date", "Rate"]);
  rateResets.forEach((rr) => {
    instrumentSheet.addRow([
      rr.getNumber(),
      rr.getSequencenumber(),
      legNameIdx[rr.getLegid()] ?? "-",
      rr.getFixed() ? "True" : "False",
      protobufTimestampToDayjs(
        rr.getRatesampledate() ?? new Timestamp(),
      ).toDate(),
      decimalToBigNumber(rr.getRate()).toNumber(),
    ]);
  });
}

export function writeAssetflowsToExcelWorkbook(
  assetflows: Assetflow[],
  wb: Workbook,
  smartInstrumentLegs: SmartInstrumentLeg[],
) {
  const legNameIdx: { [key: string]: string } = smartInstrumentLegs.reduce(
    (prev, cur) => ({
      ...prev,
      [new SmartInstrumentLegWrapper(cur).id]: new SmartInstrumentLegWrapper(
        cur,
      ).name,
    }),
    {},
  );
  const instrumentSheet = wb.addWorksheet("Asset Flows");

  instrumentSheet.addRow([
    "No.",
    "Seq. No.",
    "Fixed",
    "Leg",
    "Category",
    "Payment Date",
    "Record Date",
    "Asset",
    "Amount",
  ]);
  assetflows.forEach((af) => {
    instrumentSheet.addRow([
      af.getNumber(),
      af.getSequencenumber(),
      af.getFixed() ? "True" : "False",
      legNameIdx[af.getLegid()] ?? "-",
      assetflowCategoryToString(af.getCategory()),
      protobufTimestampToDayjs(af.getPaymentdate() ?? new Timestamp()).toDate(),
      protobufTimestampToDayjs(af.getRecorddate() ?? new Timestamp()).toDate(),
      af.getAmount()?.getToken()?.getCode(),
      decimalToBigNumber(af.getAmount()?.getValue()).toNumber(),
    ]);
  });
}

import config from "react-global-configuration";
import { Amount, Token } from "james/ledger";
import {
  jsonRPCRequest,
  JSONRPCRequestOpts,
} from "utilities/network/jsonRPCRequest/jsonRPCRequest";
import { Context } from "james/security";
import { Spot, SpotType } from "./Spot";
import { BigNumber } from "bignumber.js";

export type SubmitSpotRequest = {
  context: Context;
  sourceAccountID: string;
  spotType: SpotType;
  listingID: string;
  baseAmount: Amount;
  quoteAmount: Amount;
  estimatedPrice: Amount;
  slippage: BigNumber;
  path: Token[];
};

export type SubmitSpotResponse = {
  spot: Spot;
};

export type ResolveSpotIDStateRequest = {
  context: Context;
  spotID: string;
};

export type ResolveSpotIDStateResponse = {
  spot: Spot;
};

export const SpotStateControllerServiceProviderName =
  "market-SpotStateController";

export const SpotStateController = {
  async SubmitSpot(
    request: SubmitSpotRequest,
    opts?: JSONRPCRequestOpts,
  ): Promise<SubmitSpotResponse> {
    const response = await jsonRPCRequest(
      {
        url: config.get("meshConsistencyAuthorizedURL"),
        method: `${SpotStateControllerServiceProviderName}.SubmitSpot`,
        request,
      },
      opts,
    );

    return { spot: new Spot(response.spot) };
  },
  async ResolveSpotIDState(
    request: ResolveSpotIDStateRequest,
    opts?: JSONRPCRequestOpts,
  ): Promise<ResolveSpotIDStateResponse> {
    const response = await jsonRPCRequest(
      {
        url: config.get("meshConsistencyAuthorizedURL"),
        method: `${SpotStateControllerServiceProviderName}.ResolveSpotIDState`,
        request,
      },
      opts,
    );

    return { spot: new Spot(response.spot) };
  },
};

import React from "react";
import {
  Box,
  Button,
  CardContent,
  Typography,
  useTheme,
  Card,
  CardHeader,
  CircularProgress,
  Breadcrumbs,
  Link,
  Paper,
} from "@mui/material";
import { ViewMode, useMarketingContentContext } from "./Context";
import { useApplicationContext } from "context/Application/Application";
import {
  SocialMediaLinksForm,
  InvestmentObjectiveForm,
  MediaEntriesForm,
  NewsArticlesForm,
  IndependentReviewsForm,
} from "./components";
import { IndependentReview } from "@mesh/common-js/dist/marketing/independentReview_pb";
import { SocialMediaLinks } from "@mesh/common-js/dist/marketing/socialMediaLinks_pb";
import { NewsArticle } from "@mesh/common-js/dist/marketing/newsArticle_pb";
import { MediaEntry } from "@mesh/common-js/dist/marketing/mediaEntry_pb";

export const Layout = () => {
  const {
    apiCallInProgress,
    initialisationError,
    clearInitialisationError,
    initialised,
    viewMode,
    instrumentData,
    formData,
    formDataUpdater,
    formDataValidationResult,
  } = useMarketingContentContext();
  const theme = useTheme();

  if (initialisationError) {
    return (
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          height: window.innerHeight - 55,
        }}
      >
        <Card>
          <CardHeader title={"Error Initialising Builder"} />
          <CardContent
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyItems: "center",
              gap: theme.spacing(2),
            }}
          >
            <Typography>
              Something went wrong while initialising the builder.
            </Typography>
            <Button
              variant="contained"
              color="primary"
              onClick={clearInitialisationError}
            >
              Try Again
            </Button>
          </CardContent>
        </Card>
      </Box>
    );
  }

  if (!initialised || viewMode === ViewMode.Undefined) {
    return (
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          height: window.innerHeight - 55,
        }}
      >
        <Card>
          <CardContent
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyItems: "center",
              gap: theme.spacing(2),
            }}
          >
            <CircularProgress size={70} />
            <Typography
              variant="h5"
              color="textSecondary"
              children="Initialising Builder..."
            />
          </CardContent>
        </Card>
      </Box>
    );
  }

  return (
    <Box
      className="meshScroll"
      sx={{
        maxHeight: "92vh",
        overflowY: "auto",
        overflowX: "hidden",
        scrollbarColor: `${theme.palette.secondary.main} transparent`,
        scrollbarWidth: "thin",
      }}
    >
      <Paper
        sx={{
          display: "flex",
          flexDirection: "column",
          pb: theme.spacing(1),
          mb: theme.spacing(1),
        }}
      >
        <Breadcrumbs sx={{ m: theme.spacing(2) }} aria-label="breadcrumb">
          <Link underline="hover" color="secondary" href="/builder">
            Issuance Hub
          </Link>
          <Link
            underline="hover"
            color="secondary"
            href="/builder/marketing/table"
          >
            Marketing Content
          </Link>
          <Typography color="text.secondary">{instrumentData?.name}</Typography>
        </Breadcrumbs>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            mx: theme.spacing(2),
            my: theme.spacing(1),
          }}
        >
          <Typography
            variant="h4"
            sx={{
              fontSize: theme.typography.h3,
              fontWeight: theme.typography.fontWeightBold,
            }}
          >
            Marketing Content
          </Typography>
          <Box
            sx={{
              display: "flex",
              gap: theme.spacing(2),
            }}
          >
            <ActionButtons />
          </Box>
        </Box>
        <Box
          sx={{
            display: "flex",
            gap: theme.spacing(2),
            m: theme.spacing(1, 2),
          }}
        >
          {(() => {
            const socialMediaLinks =
              formData.marketingContent.getSocialmedialinks();
            return socialMediaLinks ? (
              <SocialMediaLinksForm
                socialMediaLinks={socialMediaLinks}
                disabled={apiCallInProgress}
                readOnly={viewMode === ViewMode.View}
                formDataValidationResult={formDataValidationResult}
                onChange={(e) => {
                  const updatedLinks = new SocialMediaLinks()
                    .setWeb(e.getWeb())
                    .setFacebook(e.getFacebook())
                    .setLinkedin(e.getLinkedin())
                    .setYoutube(e.getYoutube())
                    .setInstagram(e.getInstagram())
                    .setTwitter(e.getTwitter());

                  formDataUpdater.socialMediaLinks(updatedLinks);
                }}
              />
            ) : (
              <Typography variant="body1" color="text.secondary">
                No social media links available.
              </Typography>
            );
          })()}
          <InvestmentObjectiveForm
            investmentObjective={formData.marketingContent.getInvestmentobjective()}
            disabled={apiCallInProgress}
            readOnly={viewMode === ViewMode.View}
            formDataValidationResult={formDataValidationResult}
            onChange={(e) => {
              formDataUpdater.investmentObjective(e);
            }}
          />
        </Box>
        <Box
          sx={{
            display: "flex",
            gap: theme.spacing(2),
            m: theme.spacing(1, 2),
          }}
        >
          <NewsArticlesForm
            newsArticles={formData.marketingContent.getNewsarticlesList()}
            disabled={apiCallInProgress}
            readOnly={viewMode === ViewMode.View}
            formDataValidationResult={formDataValidationResult}
            onChange={(e) => {
              // Create a new array of `NewsArticle` instances
              const updatedNewsArticles = e.map((s) =>
                new NewsArticle()
                  .setDate(s.getDate())
                  .setUrl(s.getUrl())
                  .setExcerpt(s.getExcerpt()),
              );

              // Update the formData with the new list of articles
              formDataUpdater.setNewsArticles(updatedNewsArticles);
            }}
          />
        </Box>
        <Box
          sx={{
            display: "flex",
            gap: theme.spacing(2),
            m: theme.spacing(1, 2),
          }}
        >
          <MediaEntriesForm
            mediaEntries={formData.marketingContent.getMediaentriesList()}
            disabled={apiCallInProgress}
            readOnly={viewMode === ViewMode.View}
            formDataValidationResult={formDataValidationResult}
            onChange={(e) => {
              // Create a new array of MediaEntry instances
              const updatedMediaEntries = e.map((s) =>
                new MediaEntry()
                  .setType(s.getType())
                  .setUrl(s.getUrl())
                  .setThumbnailurl(s.getThumbnailurl()),
              );

              // Update the formData with the new list of media entries
              formDataUpdater.setMediaEntries(updatedMediaEntries);
            }}
          />
        </Box>
        <Box
          sx={{
            display: "flex",
            gap: theme.spacing(2),
            m: theme.spacing(1, 2),
          }}
        >
          <IndependentReviewsForm
            reviews={formData.marketingContent.getIndependentreviewsList()}
            disabled={apiCallInProgress}
            readOnly={viewMode === ViewMode.View}
            formDataValidationResult={formDataValidationResult}
            onChange={(e) => {
              // Create a new array of IndependentReview instances
              const updatedIndependentReviews = e.map((s) =>
                new IndependentReview()
                  .setTitle(s.getTitle())
                  .setSummary(s.getSummary())
                  .setDate(s.getDate())
                  .setUrl(s.getUrl())
                  .setReviewerdetails(s.getReviewerdetails()),
              );

              // Update the formData with the new list of reviews
              formDataUpdater.setIndependentReviews(updatedIndependentReviews);
            }}
          />
        </Box>
      </Paper>
    </Box>
  );
};

function ActionButtons() {
  const { viewConfiguration } = useApplicationContext();
  const {
    saveOrCreateAction: saveAction,
    discardChanges,
    apiCallInProgress,
    viewMode,
    setEditViewMode,
  } = useMarketingContentContext();

  const availableActionButtons: React.ReactNode[] = [];
  switch (viewMode) {
    case ViewMode.Edit:
      availableActionButtons.push(
        <Button
          id={"marketingContentForm-cancel-button"}
          variant="outlined"
          disabled={apiCallInProgress}
          onClick={discardChanges}
        >
          Cancel
        </Button>,
      );
      availableActionButtons.push(
        <Button
          id={"marketingContentForm-save-button"}
          variant="contained"
          color="primary"
          disabled={apiCallInProgress}
          onClick={saveAction}
        >
          Save
        </Button>,
      );
      break;
    case ViewMode.View:
      if (viewConfiguration["Smart Instruments"]?.Write?.UpdateDraft) {
        availableActionButtons.push(
          <Button
            id={"marketingContentForm-edit-button"}
            variant="contained"
            color="primary"
            disabled={apiCallInProgress}
            onClick={setEditViewMode}
          >
            Edit
          </Button>,
        );
      }
  }

  return (
    <>
      {availableActionButtons.map((b, idx) => (
        <React.Fragment key={idx}>{b}</React.Fragment>
      ))}
    </>
  );
}

import React, { useCallback, useState } from "react";
import { Box } from "@mui/material";
import meshMiniLogo from "assets/images/logo/meshLogo.svg";
import { VerifyEmailCard } from "views/SignUp/components/VerifyEmailOTP/VerifyEmailCard";
import { useQuery, useQueryClient } from "react-query";
import { useSnackbar } from "notistack";
import { useTimer } from "hooks/useTimer";
import { useFirebaseContext } from "context/Firebase";
import { useNavigate } from "react-router-dom";
import { useErrorContext } from "context/Error";
import {
  CompanyRegisteredName,
  InitiateCompanyAdminRegistration,
  InitiateUserRegistration,
} from "../../const";
import { isWidthDown } from "@mui/material/Hidden/withWidth";
import { useWidth } from "utilities/general";
import { BuildQueryKey } from "utilities/reactQuery";
import { UserRegisteringLoader } from "components/Loaders";
import { useUserRegistrationHelper } from "hooks/helperAPI/useUserRegistrationHelper";
import background from "assets/images/background/background.png";
import { TOTPVerifier } from "james/security/verification/TOTPVerifier";
import {
  EmailTOTPSender,
  EmailTOTPSenderServiceProviderName,
} from "james/security/verification/EmailTOTPSender";
import { useAppNoticeContext } from "context/AppNotice/AppNotice";

export function VerifyEmailOTP() {
  const width = useWidth();
  const { enqueueSnackbar } = useSnackbar();
  const { NotificationBannerHeight } = useAppNoticeContext();

  const { firebaseUserIDToken } = useFirebaseContext();
  const {
    registerIndividualUser,
    individualUserRegistrationInProgress,
    companyUserRegistrationInProgress,
    registerCompanyUser,
  } = useUserRegistrationHelper();
  const [confirmationCode, setConfirmationCode] = useState("");
  const isMobile = isWidthDown("sm", width);
  const { timerValue, timerProgress, timerCompleted, startTimer, stopTimer } =
    useTimer();
  const { errorContextDefaultErrorFeedback } = useErrorContext();
  const { firebaseUser, firebaseLogout } = useFirebaseContext();
  const [totpResendLoading, setTotpResendLoading] = useState(false);
  const reactQueryClient = useQueryClient();
  const [enableSendOTPQuery, setEnableOTPQuery] = useState(true);
  const navigate = useNavigate();

  const handleOnOTPResend = useCallback(async () => {
    setTotpResendLoading(true);
    try {
      enqueueSnackbar("Sending OTP Email", {
        variant: "info",
      });
      await EmailTOTPSender.SendEmailTOTP({
        firebaseToken: firebaseUserIDToken,
      });
      startTimer(120);
      enqueueSnackbar("Email Sent", {
        variant: "success",
      });
    } catch (e) {
      errorContextDefaultErrorFeedback(e);
      // logout the user from firebase and redirect to /sign-in
      firebaseLogout().then(() => navigate("/sign-in"));
    } finally {
      setTotpResendLoading(false);
    }
  }, [firebaseUserIDToken]);

  useQuery(
    BuildQueryKey(`${EmailTOTPSenderServiceProviderName}":"SendEmailTOTP"`),
    async () => {
      setTotpResendLoading(true);
      enqueueSnackbar("Sending OTP Email", {
        variant: "info",
      });

      return await EmailTOTPSender.SendEmailTOTP({
        firebaseToken: firebaseUserIDToken,
      });
    },
    {
      enabled: !!firebaseUserIDToken && enableSendOTPQuery,
      staleTime: Infinity,
      cacheTime: Infinity,
      retry: false,
      onError: (e) => {
        errorContextDefaultErrorFeedback(e);

        setTotpResendLoading(false);

        // logout the user from firebase and redirect to /sign-in
        firebaseLogout().then(() => navigate("/sign-in"));
      },
      onSuccess: () => {
        enqueueSnackbar("Email Sent", {
          variant: "success",
        });
        startTimer(120);
        setTotpResendLoading(false);
        // disable query and remove it from cache
        setEnableOTPQuery(false);

        reactQueryClient.removeQueries(
          BuildQueryKey(
            `${EmailTOTPSenderServiceProviderName}":"SendEmailTOTP"`,
          ),
        );
      },
    },
  );

  const handleOnVerifyEmail = useCallback(async () => {
    enqueueSnackbar("Verifying OTP", {
      variant: "info",
    });
    try {
      await TOTPVerifier.VerifyEmail({
        firebaseToken: firebaseUserIDToken,
        totp: confirmationCode,
      });
      enqueueSnackbar("Email Verified", {
        variant: "success",
      });
      stopTimer();
      await firebaseUser?.reload();
      if (localStorage.getItem(InitiateCompanyAdminRegistration)) {
        const companyName = localStorage.getItem(CompanyRegisteredName);
        if (!companyName) {
          return;
        }
        await registerCompanyUser(firebaseUserIDToken, companyName);
        localStorage.removeItem(InitiateCompanyAdminRegistration);
      } else {
        await registerIndividualUser(firebaseUserIDToken);
        localStorage.removeItem(InitiateUserRegistration);
      }
      navigate("/");
    } catch (e) {
      errorContextDefaultErrorFeedback(e);
    }
  }, [firebaseUserIDToken, confirmationCode]);

  const setOTPValues = (): [number, number, boolean] => {
    return [timerProgress, timerValue, timerCompleted];
  };

  if (
    individualUserRegistrationInProgress ||
    companyUserRegistrationInProgress
  ) {
    return <UserRegisteringLoader />;
  }

  if (isMobile) {
    return (
      <VerifyEmailCard
        handleOnOTPResend={handleOnOTPResend}
        handleOnVerifyEmail={handleOnVerifyEmail}
        totpResendLoading={totpResendLoading}
        confirmationCode={confirmationCode}
        setConfirmationCode={setConfirmationCode}
        setOTPValues={setOTPValues}
      />
    );
  }

  return (
    <Box
      sx={{
        position: "relative",
        display: "flex",
        flexDirection: "column",
        backgroundImage: `url(${background})`,
        backgroundSize: "cover",
        height: `calc(100vh - ${NotificationBannerHeight}px)`,
        overflowY: {
          xs: "auto",
          md: "hidden",
        },
        width: "100%",
      }}
    >
      <Box
        component={"img"}
        sx={{
          position: "fixed",
          width: 230,
        }}
        alt=""
        src={meshMiniLogo}
      />
      <Box
        className="meshScroll"
        sx={{
          height: "100%",
          display: "grid",
          gridTemplateRows: `
            "a"
            "b"
            "c"
            `,
          justifyContent: "center",
          minWidth: "100vw",
          overflowY: "auto",
        }}
      >
        <Box
          sx={{
            gridArea: "b",
            pb: 2,
            pt: 4,
          }}
        >
          <VerifyEmailCard
            handleOnOTPResend={handleOnOTPResend}
            handleOnVerifyEmail={handleOnVerifyEmail}
            totpResendLoading={totpResendLoading}
            confirmationCode={confirmationCode}
            setConfirmationCode={setConfirmationCode}
            setOTPValues={setOTPValues}
          />
        </Box>
      </Box>
    </Box>
  );
}

/**
 * @fileoverview gRPC-Web generated client stub for stellar
 * @enhanceable
 * @public
 */

// Code generated by protoc-gen-grpc-web. DO NOT EDIT.
// versions:
// 	protoc-gen-grpc-web v1.5.0
// 	protoc              v5.28.0
// source: stellar/transactionReaderUNSCOPED_meshproto.proto


/* eslint-disable */
// @ts-nocheck



const grpc = {};
grpc.web = require('grpc-web');


var security_context_pb = require('../security/context_pb.js')

var search_criterion_pb = require('../search/criterion_pb.js')

var search_query_pb = require('../search/query_pb.js')

var search_sorting_pb = require('../search/sorting_pb.js')

var stellar_transaction_pb = require('../stellar/transaction_pb.js')

var stellar_transactionSigningRequirements_pb = require('../stellar/transactionSigningRequirements_pb.js')

var stellar_network_pb = require('../stellar/network_pb.js')

var stellar_transactionReader_meshproto_pb = require('../stellar/transactionReader_meshproto_pb.js')
const proto = {};
proto.stellar = require('./transactionReaderUNSCOPED_meshproto_pb.js');

/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?grpc.web.ClientOptions} options
 * @constructor
 * @struct
 * @final
 */
proto.stellar.TransactionReaderUNSCOPEDClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options.format = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname.replace(/\/+$/, '');

};


/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?grpc.web.ClientOptions} options
 * @constructor
 * @struct
 * @final
 */
proto.stellar.TransactionReaderUNSCOPEDPromiseClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options.format = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname.replace(/\/+$/, '');

};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.stellar.ReadOneTransactionRequest,
 *   !proto.stellar.ReadOneTransactionResponse>}
 */
const methodDescriptor_TransactionReaderUNSCOPED_ReadOneTransactionUNSCOPED = new grpc.web.MethodDescriptor(
  '/stellar.TransactionReaderUNSCOPED/ReadOneTransactionUNSCOPED',
  grpc.web.MethodType.UNARY,
  stellar_transactionReader_meshproto_pb.ReadOneTransactionRequest,
  stellar_transactionReader_meshproto_pb.ReadOneTransactionResponse,
  /**
   * @param {!proto.stellar.ReadOneTransactionRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  stellar_transactionReader_meshproto_pb.ReadOneTransactionResponse.deserializeBinary
);


/**
 * @param {!proto.stellar.ReadOneTransactionRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.stellar.ReadOneTransactionResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.stellar.ReadOneTransactionResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.stellar.TransactionReaderUNSCOPEDClient.prototype.readOneTransactionUNSCOPED =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/stellar.TransactionReaderUNSCOPED/ReadOneTransactionUNSCOPED',
      request,
      metadata || {},
      methodDescriptor_TransactionReaderUNSCOPED_ReadOneTransactionUNSCOPED,
      callback);
};


/**
 * @param {!proto.stellar.ReadOneTransactionRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.stellar.ReadOneTransactionResponse>}
 *     Promise that resolves to the response
 */
proto.stellar.TransactionReaderUNSCOPEDPromiseClient.prototype.readOneTransactionUNSCOPED =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/stellar.TransactionReaderUNSCOPED/ReadOneTransactionUNSCOPED',
      request,
      metadata || {},
      methodDescriptor_TransactionReaderUNSCOPED_ReadOneTransactionUNSCOPED);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.stellar.ReadManyTransactionRequest,
 *   !proto.stellar.ReadManyTransactionResponse>}
 */
const methodDescriptor_TransactionReaderUNSCOPED_ReadManyTransactionUNSCOPED = new grpc.web.MethodDescriptor(
  '/stellar.TransactionReaderUNSCOPED/ReadManyTransactionUNSCOPED',
  grpc.web.MethodType.UNARY,
  stellar_transactionReader_meshproto_pb.ReadManyTransactionRequest,
  stellar_transactionReader_meshproto_pb.ReadManyTransactionResponse,
  /**
   * @param {!proto.stellar.ReadManyTransactionRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  stellar_transactionReader_meshproto_pb.ReadManyTransactionResponse.deserializeBinary
);


/**
 * @param {!proto.stellar.ReadManyTransactionRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.stellar.ReadManyTransactionResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.stellar.ReadManyTransactionResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.stellar.TransactionReaderUNSCOPEDClient.prototype.readManyTransactionUNSCOPED =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/stellar.TransactionReaderUNSCOPED/ReadManyTransactionUNSCOPED',
      request,
      metadata || {},
      methodDescriptor_TransactionReaderUNSCOPED_ReadManyTransactionUNSCOPED,
      callback);
};


/**
 * @param {!proto.stellar.ReadManyTransactionRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.stellar.ReadManyTransactionResponse>}
 *     Promise that resolves to the response
 */
proto.stellar.TransactionReaderUNSCOPEDPromiseClient.prototype.readManyTransactionUNSCOPED =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/stellar.TransactionReaderUNSCOPED/ReadManyTransactionUNSCOPED',
      request,
      metadata || {},
      methodDescriptor_TransactionReaderUNSCOPED_ReadManyTransactionUNSCOPED);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.stellar.ReadAllTransactionRequest,
 *   !proto.stellar.ReadAllTransactionResponse>}
 */
const methodDescriptor_TransactionReaderUNSCOPED_ReadAllTransactionUNSCOPED = new grpc.web.MethodDescriptor(
  '/stellar.TransactionReaderUNSCOPED/ReadAllTransactionUNSCOPED',
  grpc.web.MethodType.UNARY,
  stellar_transactionReader_meshproto_pb.ReadAllTransactionRequest,
  stellar_transactionReader_meshproto_pb.ReadAllTransactionResponse,
  /**
   * @param {!proto.stellar.ReadAllTransactionRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  stellar_transactionReader_meshproto_pb.ReadAllTransactionResponse.deserializeBinary
);


/**
 * @param {!proto.stellar.ReadAllTransactionRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.stellar.ReadAllTransactionResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.stellar.ReadAllTransactionResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.stellar.TransactionReaderUNSCOPEDClient.prototype.readAllTransactionUNSCOPED =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/stellar.TransactionReaderUNSCOPED/ReadAllTransactionUNSCOPED',
      request,
      metadata || {},
      methodDescriptor_TransactionReaderUNSCOPED_ReadAllTransactionUNSCOPED,
      callback);
};


/**
 * @param {!proto.stellar.ReadAllTransactionRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.stellar.ReadAllTransactionResponse>}
 *     Promise that resolves to the response
 */
proto.stellar.TransactionReaderUNSCOPEDPromiseClient.prototype.readAllTransactionUNSCOPED =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/stellar.TransactionReaderUNSCOPED/ReadAllTransactionUNSCOPED',
      request,
      metadata || {},
      methodDescriptor_TransactionReaderUNSCOPED_ReadAllTransactionUNSCOPED);
};


module.exports = proto.stellar;


import React, { useEffect, useState } from "react";
import {
  Box,
  Dialog,
  DialogContent,
  DialogTitle,
  Divider,
  IconButton,
  MenuItem,
  Typography,
  Button,
  Skeleton,
  CircularProgress,
} from "@mui/material";
import { useAPIContext } from "context/API";
import { Client } from "james/client";
import { Close } from "@mui/icons-material";
import AddIcon from "@mui/icons-material/Add";
import {
  allFeeTypes,
  feeTypeToString,
} from "@mesh/common-js/dist/remuneration/feeSchedule";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import { TextField, TextNumField } from "@mesh/common-js-react/dist/FormFields";
import { useApplicationContext } from "context/Application/Application";
import {
  FeeConfiguration,
  FeeType,
} from "@mesh/common-js/dist/remuneration/feeSchedule_pb";
import { UpdateFeeScheduleFeeConfigurationsRequest } from "@mesh/common-js/dist/remuneration/feeScheduleUpdater_pb";
import { Decimal } from "@mesh/common-js/dist/num/decimal_pb";
import { ReadOneFeeScheduleRequest } from "@mesh/common-js/dist/remuneration/feeScheduleReader_meshproto_pb";
import { CreateNewFeeScheduleRequest } from "@mesh/common-js/dist/remuneration/feeScheduleCreator_pb";
import { newTextExactCriterion } from "@mesh/common-js/dist/search";
import { decimalToBigNumber } from "@mesh/common-js/dist/num/decimalConversions";
import { useSnackbar } from "notistack";

interface FeeDialog {
  client: Client;
  closeDialog: () => void;
  open: boolean;
}

export const FeeDialog = ({ client, closeDialog, open }: FeeDialog) => {
  const { authContext } = useApplicationContext();
  const { remuneration } = useAPIContext();
  const { enqueueSnackbar } = useSnackbar();
  const [feeConfigurations, setFeeConfigurations] = useState<
    FeeConfiguration[]
  >([]);
  const [loading, setLoading] = useState(true);

  const [feePercentage, setFeePercentage] = useState<Decimal>();
  const [feeType, setFeeType] = useState<FeeType>(0);

  const [newFeeSchedule, setNewFeeSchedule] = useState(false);

  const [savingFeeSchedule, setSavingFeeSchedule] = useState(false);

  useEffect(() => {
    if (!client) {
      closeDialog();
      return;
    }
    setTimeout(async () => {
      setLoading(true);
      try {
        const readFeeScheduleResponse =
          await remuneration.feeScheduleReader.readOneFeeSchedule(
            new ReadOneFeeScheduleRequest()
              .setContext(authContext.toFuture())
              .setCriteriaList([newTextExactCriterion("clientid", client.id)]),
          );
        setFeeConfigurations(
          readFeeScheduleResponse
            .getFeeschedule()
            ?.getFeeconfigurationsList() ?? [],
        );
      } catch (e) {
        if (
          e &&
          typeof e === "object" &&
          "message" in e &&
          e.message === "feeSchedule not found"
        ) {
          setNewFeeSchedule(true);
        } else {
          console.error(e);
          enqueueSnackbar(`could not retrieve fee schedule: ${e}`, {
            variant: "error",
          });
          closeDialog();
          return;
        }
      }
      setLoading(false);
    });
  }, [client]);

  const saveFeeSchedule = async () => {
    setSavingFeeSchedule(true);
    try {
      if (newFeeSchedule) {
        await remuneration.feeScheduleCreator.createNewFeeSchedule(
          new CreateNewFeeScheduleRequest()
            .setContext(authContext.toFuture())
            .setClientid(client?.id ?? "")
            .setFeeconfigurationsList(feeConfigurations),
        );
      } else {
        await remuneration.feeScheduleUpdater.updateFeeScheduleFeeConfigurations(
          new UpdateFeeScheduleFeeConfigurationsRequest()
            .setContext(authContext.toFuture())
            .setClientid(client?.id ?? "")
            .setFeeconfigurationsList(feeConfigurations),
        );
      }
      setSavingFeeSchedule(false);
      setNewFeeSchedule(false);
      enqueueSnackbar("Fee schedule updated successfully", {
        variant: "success",
      });
      closeDialog();
    } catch (e) {
      enqueueSnackbar("could not update fee schedule", {
        variant: "error",
      });
      closeDialog();
      console.error(e);
    }
  };

  const filteredFeeTypes = allFeeTypes.filter(
    (v) => !feeConfigurations.some((obj) => obj.getFeetype() === v),
  );

  return (
    <Dialog
      open={open}
      PaperProps={{
        sx: {
          width: 680,
        },
      }}
    >
      <DialogTitle>
        <Typography>Set Client Fees</Typography>
        {savingFeeSchedule ? (
          <CircularProgress size={24} sx={{ m: 1 }} />
        ) : (
          <IconButton sx={{ ml: "auto" }} onClick={closeDialog}>
            <Close />
          </IconButton>
        )}
      </DialogTitle>
      <DialogContent sx={{ px: 3 }}>
        {loading ? (
          <LoadingState />
        ) : (
          <>
            <Box sx={{ pb: 1, pt: 3 }}>
              <Typography variant="h4">{client.name}</Typography>
              <Typography color="textSecondary">{client.shortName}</Typography>
            </Box>
            {/* List */}
            <>
              <Box
                sx={(theme) => ({
                  display: "grid",
                  gridTemplateColumns: "328px 1fr",
                  columnGap: 3,
                  alignItems: "center",
                  color: theme.palette.text.primary,
                  mt: 3,
                })}
              >
                <Typography variant="h5">Fee Type</Typography>
                <Typography variant="h5">Fee Percentage</Typography>
              </Box>
              <Divider sx={{ mt: 0.5 }} />
            </>
            {feeConfigurations.length > 0 ? (
              <Box
                className="meshScroll"
                sx={(theme) => ({
                  display: "grid",
                  gridTemplateColumns: "328px 1fr 1fr",
                  columnGap: 3,
                  alignItems: "center",
                  color: theme.palette.text.secondary,
                  maxHeight: 240,
                  overflowY: "auto",
                })}
              >
                {feeConfigurations.map((v, idx) => {
                  return (
                    <React.Fragment key={idx}>
                      <Typography>{feeTypeToString(v.getFeetype())}</Typography>
                      <Typography>
                        {decimalToBigNumber(v.getAmount()).toFormat()}%
                      </Typography>
                      <IconButton
                        sx={{ ml: "auto" }}
                        onClick={() => {
                          setFeeConfigurations(
                            feeConfigurations.filter((_v, i) => i !== idx),
                          );
                        }}
                      >
                        <DeleteOutlineIcon />
                      </IconButton>
                    </React.Fragment>
                  );
                })}
              </Box>
            ) : (
              <Typography
                sx={(theme) => ({
                  mt: 1,
                  mb: 2,
                  color: theme.palette.text.disabled,
                })}
              >
                {newFeeSchedule
                  ? "No custom fee configurations listed yet"
                  : "Saving will update without custom fee configurations"}
              </Typography>
            )}

            {/* Add Item */}
            {filteredFeeTypes.length > 1 && (
              <Box
                sx={{ display: "flex", mt: 1, gap: 2, alignItems: "center" }}
              >
                <TextField
                  select
                  fullWidth
                  value={feeType}
                  defaultValue={0}
                  onChange={(e) => setFeeType(Number(e.target.value))}
                  sx={{
                    width: 400,
                  }}
                >
                  {filteredFeeTypes.map((fee, idx) => (
                    <MenuItem key={idx} value={fee}>
                      {feeTypeToString(fee)}
                    </MenuItem>
                  ))}
                </TextField>
                <TextNumField
                  label={"Fee Percentage"}
                  value={feePercentage}
                  onChange={(e: Decimal) => {
                    setFeePercentage(
                      decimalToBigNumber(e).gt(100)
                        ? new Decimal().setValue("100")
                        : e,
                    );
                  }}
                  InputProps={{
                    endAdornment: <Typography>%</Typography>,
                  }}
                />
                <IconButton
                  disabled={!feePercentage || !feeType}
                  sx={(theme) => ({
                    width: 32,
                    height: 32,
                    color: theme.palette.text.secondary,
                    "&.Mui-disabled": {
                      color: theme.palette.text.disabled,
                    },
                  })}
                  onClick={() => {
                    setFeeConfigurations([
                      ...feeConfigurations,
                      new FeeConfiguration()
                        .setAmount(feePercentage)
                        .setFeetype(feeType ?? 0),
                    ]);
                    setFeeType(0);
                    setFeePercentage(new Decimal());
                  }}
                >
                  {!!feePercentage && !!feeType && <AddIcon />}
                </IconButton>
              </Box>
            )}

            <Box
              sx={{
                display: "flex",
                justifyContent: "flex-end",
                mt: 3,
              }}
            >
              <Button
                variant="contained"
                color="primary"
                onClick={saveFeeSchedule}
                disabled={loading || savingFeeSchedule}
              >
                Save
              </Button>
            </Box>
          </>
        )}
      </DialogContent>
    </Dialog>
  );
};

const LoadingState = () => {
  return (
    <Box sx={{ width: "100%", pt: 3, pb: 1 }}>
      <Skeleton width="200px" height="40px" />
      <Skeleton width="180px" height="32px" />
      <Skeleton width="90%" height="56px" sx={{ mt: 2 }} />
      <Skeleton sx={{ ml: "auto", width: 80, height: 48, mt: 1 }} />
    </Box>
  );
};

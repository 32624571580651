// source: views/stellarRegisteredLiquidityPoolView/modelReader.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global =
    (typeof globalThis !== 'undefined' && globalThis) ||
    (typeof window !== 'undefined' && window) ||
    (typeof global !== 'undefined' && global) ||
    (typeof self !== 'undefined' && self) ||
    (function () { return this; }).call(null) ||
    Function('return this')();

var security_context_pb = require('../../security/context_pb.js');
goog.object.extend(proto, security_context_pb);
var search_criterion_pb = require('../../search/criterion_pb.js');
goog.object.extend(proto, search_criterion_pb);
var search_query_pb = require('../../search/query_pb.js');
goog.object.extend(proto, search_query_pb);
var views_stellarRegisteredLiquidityPoolView_model_pb = require('../../views/stellarRegisteredLiquidityPoolView/model_pb.js');
goog.object.extend(proto, views_stellarRegisteredLiquidityPoolView_model_pb);
goog.exportSymbol('proto.stellarRegisteredLiquidityPoolView.ReadManyModelRequest', null, global);
goog.exportSymbol('proto.stellarRegisteredLiquidityPoolView.ReadManyModelResponse', null, global);
goog.exportSymbol('proto.stellarRegisteredLiquidityPoolView.ReadOneModelRequest', null, global);
goog.exportSymbol('proto.stellarRegisteredLiquidityPoolView.ReadOneModelResponse', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.stellarRegisteredLiquidityPoolView.ReadOneModelRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.stellarRegisteredLiquidityPoolView.ReadOneModelRequest.repeatedFields_, null);
};
goog.inherits(proto.stellarRegisteredLiquidityPoolView.ReadOneModelRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.stellarRegisteredLiquidityPoolView.ReadOneModelRequest.displayName = 'proto.stellarRegisteredLiquidityPoolView.ReadOneModelRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.stellarRegisteredLiquidityPoolView.ReadOneModelResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.stellarRegisteredLiquidityPoolView.ReadOneModelResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.stellarRegisteredLiquidityPoolView.ReadOneModelResponse.displayName = 'proto.stellarRegisteredLiquidityPoolView.ReadOneModelResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.stellarRegisteredLiquidityPoolView.ReadManyModelRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.stellarRegisteredLiquidityPoolView.ReadManyModelRequest.repeatedFields_, null);
};
goog.inherits(proto.stellarRegisteredLiquidityPoolView.ReadManyModelRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.stellarRegisteredLiquidityPoolView.ReadManyModelRequest.displayName = 'proto.stellarRegisteredLiquidityPoolView.ReadManyModelRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.stellarRegisteredLiquidityPoolView.ReadManyModelResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.stellarRegisteredLiquidityPoolView.ReadManyModelResponse.repeatedFields_, null);
};
goog.inherits(proto.stellarRegisteredLiquidityPoolView.ReadManyModelResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.stellarRegisteredLiquidityPoolView.ReadManyModelResponse.displayName = 'proto.stellarRegisteredLiquidityPoolView.ReadManyModelResponse';
}

/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.stellarRegisteredLiquidityPoolView.ReadOneModelRequest.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.stellarRegisteredLiquidityPoolView.ReadOneModelRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.stellarRegisteredLiquidityPoolView.ReadOneModelRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.stellarRegisteredLiquidityPoolView.ReadOneModelRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.stellarRegisteredLiquidityPoolView.ReadOneModelRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
context: (f = msg.getContext()) && security_context_pb.ContextFuture.toObject(includeInstance, f),
criteriaList: jspb.Message.toObjectList(msg.getCriteriaList(),
    search_criterion_pb.Criterion.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.stellarRegisteredLiquidityPoolView.ReadOneModelRequest}
 */
proto.stellarRegisteredLiquidityPoolView.ReadOneModelRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.stellarRegisteredLiquidityPoolView.ReadOneModelRequest;
  return proto.stellarRegisteredLiquidityPoolView.ReadOneModelRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.stellarRegisteredLiquidityPoolView.ReadOneModelRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.stellarRegisteredLiquidityPoolView.ReadOneModelRequest}
 */
proto.stellarRegisteredLiquidityPoolView.ReadOneModelRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new security_context_pb.ContextFuture;
      reader.readMessage(value,security_context_pb.ContextFuture.deserializeBinaryFromReader);
      msg.setContext(value);
      break;
    case 2:
      var value = new search_criterion_pb.Criterion;
      reader.readMessage(value,search_criterion_pb.Criterion.deserializeBinaryFromReader);
      msg.addCriteria(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.stellarRegisteredLiquidityPoolView.ReadOneModelRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.stellarRegisteredLiquidityPoolView.ReadOneModelRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.stellarRegisteredLiquidityPoolView.ReadOneModelRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.stellarRegisteredLiquidityPoolView.ReadOneModelRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getContext();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      security_context_pb.ContextFuture.serializeBinaryToWriter
    );
  }
  f = message.getCriteriaList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      search_criterion_pb.Criterion.serializeBinaryToWriter
    );
  }
};


/**
 * optional security.ContextFuture context = 1;
 * @return {?proto.security.ContextFuture}
 */
proto.stellarRegisteredLiquidityPoolView.ReadOneModelRequest.prototype.getContext = function() {
  return /** @type{?proto.security.ContextFuture} */ (
    jspb.Message.getWrapperField(this, security_context_pb.ContextFuture, 1));
};


/**
 * @param {?proto.security.ContextFuture|undefined} value
 * @return {!proto.stellarRegisteredLiquidityPoolView.ReadOneModelRequest} returns this
*/
proto.stellarRegisteredLiquidityPoolView.ReadOneModelRequest.prototype.setContext = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.stellarRegisteredLiquidityPoolView.ReadOneModelRequest} returns this
 */
proto.stellarRegisteredLiquidityPoolView.ReadOneModelRequest.prototype.clearContext = function() {
  return this.setContext(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.stellarRegisteredLiquidityPoolView.ReadOneModelRequest.prototype.hasContext = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * repeated search.Criterion criteria = 2;
 * @return {!Array<!proto.search.Criterion>}
 */
proto.stellarRegisteredLiquidityPoolView.ReadOneModelRequest.prototype.getCriteriaList = function() {
  return /** @type{!Array<!proto.search.Criterion>} */ (
    jspb.Message.getRepeatedWrapperField(this, search_criterion_pb.Criterion, 2));
};


/**
 * @param {!Array<!proto.search.Criterion>} value
 * @return {!proto.stellarRegisteredLiquidityPoolView.ReadOneModelRequest} returns this
*/
proto.stellarRegisteredLiquidityPoolView.ReadOneModelRequest.prototype.setCriteriaList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.search.Criterion=} opt_value
 * @param {number=} opt_index
 * @return {!proto.search.Criterion}
 */
proto.stellarRegisteredLiquidityPoolView.ReadOneModelRequest.prototype.addCriteria = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.search.Criterion, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.stellarRegisteredLiquidityPoolView.ReadOneModelRequest} returns this
 */
proto.stellarRegisteredLiquidityPoolView.ReadOneModelRequest.prototype.clearCriteriaList = function() {
  return this.setCriteriaList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.stellarRegisteredLiquidityPoolView.ReadOneModelResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.stellarRegisteredLiquidityPoolView.ReadOneModelResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.stellarRegisteredLiquidityPoolView.ReadOneModelResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.stellarRegisteredLiquidityPoolView.ReadOneModelResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
model: (f = msg.getModel()) && views_stellarRegisteredLiquidityPoolView_model_pb.Model.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.stellarRegisteredLiquidityPoolView.ReadOneModelResponse}
 */
proto.stellarRegisteredLiquidityPoolView.ReadOneModelResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.stellarRegisteredLiquidityPoolView.ReadOneModelResponse;
  return proto.stellarRegisteredLiquidityPoolView.ReadOneModelResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.stellarRegisteredLiquidityPoolView.ReadOneModelResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.stellarRegisteredLiquidityPoolView.ReadOneModelResponse}
 */
proto.stellarRegisteredLiquidityPoolView.ReadOneModelResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new views_stellarRegisteredLiquidityPoolView_model_pb.Model;
      reader.readMessage(value,views_stellarRegisteredLiquidityPoolView_model_pb.Model.deserializeBinaryFromReader);
      msg.setModel(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.stellarRegisteredLiquidityPoolView.ReadOneModelResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.stellarRegisteredLiquidityPoolView.ReadOneModelResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.stellarRegisteredLiquidityPoolView.ReadOneModelResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.stellarRegisteredLiquidityPoolView.ReadOneModelResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getModel();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      views_stellarRegisteredLiquidityPoolView_model_pb.Model.serializeBinaryToWriter
    );
  }
};


/**
 * optional Model model = 1;
 * @return {?proto.stellarRegisteredLiquidityPoolView.Model}
 */
proto.stellarRegisteredLiquidityPoolView.ReadOneModelResponse.prototype.getModel = function() {
  return /** @type{?proto.stellarRegisteredLiquidityPoolView.Model} */ (
    jspb.Message.getWrapperField(this, views_stellarRegisteredLiquidityPoolView_model_pb.Model, 1));
};


/**
 * @param {?proto.stellarRegisteredLiquidityPoolView.Model|undefined} value
 * @return {!proto.stellarRegisteredLiquidityPoolView.ReadOneModelResponse} returns this
*/
proto.stellarRegisteredLiquidityPoolView.ReadOneModelResponse.prototype.setModel = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.stellarRegisteredLiquidityPoolView.ReadOneModelResponse} returns this
 */
proto.stellarRegisteredLiquidityPoolView.ReadOneModelResponse.prototype.clearModel = function() {
  return this.setModel(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.stellarRegisteredLiquidityPoolView.ReadOneModelResponse.prototype.hasModel = function() {
  return jspb.Message.getField(this, 1) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.stellarRegisteredLiquidityPoolView.ReadManyModelRequest.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.stellarRegisteredLiquidityPoolView.ReadManyModelRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.stellarRegisteredLiquidityPoolView.ReadManyModelRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.stellarRegisteredLiquidityPoolView.ReadManyModelRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.stellarRegisteredLiquidityPoolView.ReadManyModelRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
context: (f = msg.getContext()) && security_context_pb.ContextFuture.toObject(includeInstance, f),
criteriaList: jspb.Message.toObjectList(msg.getCriteriaList(),
    search_criterion_pb.Criterion.toObject, includeInstance),
query: (f = msg.getQuery()) && search_query_pb.Query.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.stellarRegisteredLiquidityPoolView.ReadManyModelRequest}
 */
proto.stellarRegisteredLiquidityPoolView.ReadManyModelRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.stellarRegisteredLiquidityPoolView.ReadManyModelRequest;
  return proto.stellarRegisteredLiquidityPoolView.ReadManyModelRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.stellarRegisteredLiquidityPoolView.ReadManyModelRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.stellarRegisteredLiquidityPoolView.ReadManyModelRequest}
 */
proto.stellarRegisteredLiquidityPoolView.ReadManyModelRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new security_context_pb.ContextFuture;
      reader.readMessage(value,security_context_pb.ContextFuture.deserializeBinaryFromReader);
      msg.setContext(value);
      break;
    case 2:
      var value = new search_criterion_pb.Criterion;
      reader.readMessage(value,search_criterion_pb.Criterion.deserializeBinaryFromReader);
      msg.addCriteria(value);
      break;
    case 3:
      var value = new search_query_pb.Query;
      reader.readMessage(value,search_query_pb.Query.deserializeBinaryFromReader);
      msg.setQuery(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.stellarRegisteredLiquidityPoolView.ReadManyModelRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.stellarRegisteredLiquidityPoolView.ReadManyModelRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.stellarRegisteredLiquidityPoolView.ReadManyModelRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.stellarRegisteredLiquidityPoolView.ReadManyModelRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getContext();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      security_context_pb.ContextFuture.serializeBinaryToWriter
    );
  }
  f = message.getCriteriaList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      search_criterion_pb.Criterion.serializeBinaryToWriter
    );
  }
  f = message.getQuery();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      search_query_pb.Query.serializeBinaryToWriter
    );
  }
};


/**
 * optional security.ContextFuture context = 1;
 * @return {?proto.security.ContextFuture}
 */
proto.stellarRegisteredLiquidityPoolView.ReadManyModelRequest.prototype.getContext = function() {
  return /** @type{?proto.security.ContextFuture} */ (
    jspb.Message.getWrapperField(this, security_context_pb.ContextFuture, 1));
};


/**
 * @param {?proto.security.ContextFuture|undefined} value
 * @return {!proto.stellarRegisteredLiquidityPoolView.ReadManyModelRequest} returns this
*/
proto.stellarRegisteredLiquidityPoolView.ReadManyModelRequest.prototype.setContext = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.stellarRegisteredLiquidityPoolView.ReadManyModelRequest} returns this
 */
proto.stellarRegisteredLiquidityPoolView.ReadManyModelRequest.prototype.clearContext = function() {
  return this.setContext(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.stellarRegisteredLiquidityPoolView.ReadManyModelRequest.prototype.hasContext = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * repeated search.Criterion criteria = 2;
 * @return {!Array<!proto.search.Criterion>}
 */
proto.stellarRegisteredLiquidityPoolView.ReadManyModelRequest.prototype.getCriteriaList = function() {
  return /** @type{!Array<!proto.search.Criterion>} */ (
    jspb.Message.getRepeatedWrapperField(this, search_criterion_pb.Criterion, 2));
};


/**
 * @param {!Array<!proto.search.Criterion>} value
 * @return {!proto.stellarRegisteredLiquidityPoolView.ReadManyModelRequest} returns this
*/
proto.stellarRegisteredLiquidityPoolView.ReadManyModelRequest.prototype.setCriteriaList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.search.Criterion=} opt_value
 * @param {number=} opt_index
 * @return {!proto.search.Criterion}
 */
proto.stellarRegisteredLiquidityPoolView.ReadManyModelRequest.prototype.addCriteria = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.search.Criterion, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.stellarRegisteredLiquidityPoolView.ReadManyModelRequest} returns this
 */
proto.stellarRegisteredLiquidityPoolView.ReadManyModelRequest.prototype.clearCriteriaList = function() {
  return this.setCriteriaList([]);
};


/**
 * optional search.Query query = 3;
 * @return {?proto.search.Query}
 */
proto.stellarRegisteredLiquidityPoolView.ReadManyModelRequest.prototype.getQuery = function() {
  return /** @type{?proto.search.Query} */ (
    jspb.Message.getWrapperField(this, search_query_pb.Query, 3));
};


/**
 * @param {?proto.search.Query|undefined} value
 * @return {!proto.stellarRegisteredLiquidityPoolView.ReadManyModelRequest} returns this
*/
proto.stellarRegisteredLiquidityPoolView.ReadManyModelRequest.prototype.setQuery = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.stellarRegisteredLiquidityPoolView.ReadManyModelRequest} returns this
 */
proto.stellarRegisteredLiquidityPoolView.ReadManyModelRequest.prototype.clearQuery = function() {
  return this.setQuery(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.stellarRegisteredLiquidityPoolView.ReadManyModelRequest.prototype.hasQuery = function() {
  return jspb.Message.getField(this, 3) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.stellarRegisteredLiquidityPoolView.ReadManyModelResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.stellarRegisteredLiquidityPoolView.ReadManyModelResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.stellarRegisteredLiquidityPoolView.ReadManyModelResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.stellarRegisteredLiquidityPoolView.ReadManyModelResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.stellarRegisteredLiquidityPoolView.ReadManyModelResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
recordsList: jspb.Message.toObjectList(msg.getRecordsList(),
    views_stellarRegisteredLiquidityPoolView_model_pb.Model.toObject, includeInstance),
total: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.stellarRegisteredLiquidityPoolView.ReadManyModelResponse}
 */
proto.stellarRegisteredLiquidityPoolView.ReadManyModelResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.stellarRegisteredLiquidityPoolView.ReadManyModelResponse;
  return proto.stellarRegisteredLiquidityPoolView.ReadManyModelResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.stellarRegisteredLiquidityPoolView.ReadManyModelResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.stellarRegisteredLiquidityPoolView.ReadManyModelResponse}
 */
proto.stellarRegisteredLiquidityPoolView.ReadManyModelResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new views_stellarRegisteredLiquidityPoolView_model_pb.Model;
      reader.readMessage(value,views_stellarRegisteredLiquidityPoolView_model_pb.Model.deserializeBinaryFromReader);
      msg.addRecords(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setTotal(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.stellarRegisteredLiquidityPoolView.ReadManyModelResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.stellarRegisteredLiquidityPoolView.ReadManyModelResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.stellarRegisteredLiquidityPoolView.ReadManyModelResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.stellarRegisteredLiquidityPoolView.ReadManyModelResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getRecordsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      views_stellarRegisteredLiquidityPoolView_model_pb.Model.serializeBinaryToWriter
    );
  }
  f = message.getTotal();
  if (f !== 0) {
    writer.writeInt64(
      2,
      f
    );
  }
};


/**
 * repeated Model records = 1;
 * @return {!Array<!proto.stellarRegisteredLiquidityPoolView.Model>}
 */
proto.stellarRegisteredLiquidityPoolView.ReadManyModelResponse.prototype.getRecordsList = function() {
  return /** @type{!Array<!proto.stellarRegisteredLiquidityPoolView.Model>} */ (
    jspb.Message.getRepeatedWrapperField(this, views_stellarRegisteredLiquidityPoolView_model_pb.Model, 1));
};


/**
 * @param {!Array<!proto.stellarRegisteredLiquidityPoolView.Model>} value
 * @return {!proto.stellarRegisteredLiquidityPoolView.ReadManyModelResponse} returns this
*/
proto.stellarRegisteredLiquidityPoolView.ReadManyModelResponse.prototype.setRecordsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.stellarRegisteredLiquidityPoolView.Model=} opt_value
 * @param {number=} opt_index
 * @return {!proto.stellarRegisteredLiquidityPoolView.Model}
 */
proto.stellarRegisteredLiquidityPoolView.ReadManyModelResponse.prototype.addRecords = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.stellarRegisteredLiquidityPoolView.Model, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.stellarRegisteredLiquidityPoolView.ReadManyModelResponse} returns this
 */
proto.stellarRegisteredLiquidityPoolView.ReadManyModelResponse.prototype.clearRecordsList = function() {
  return this.setRecordsList([]);
};


/**
 * optional int64 total = 2;
 * @return {number}
 */
proto.stellarRegisteredLiquidityPoolView.ReadManyModelResponse.prototype.getTotal = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.stellarRegisteredLiquidityPoolView.ReadManyModelResponse} returns this
 */
proto.stellarRegisteredLiquidityPoolView.ReadManyModelResponse.prototype.setTotal = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


goog.object.extend(exports, proto.stellarRegisteredLiquidityPoolView);

import { jsonRPCRequest } from "utilities/network/jsonRPCRequest";
import config from "react-global-configuration";
import { Token } from "james/ledger/Token";
import { Amount } from "james/ledger/Amount";

export const AssetInspectorServiceProviderName = "ledger-AssetInspector";

interface GetAssetTokenIssuerNameRequest {
  token: Token;
}

interface GetAssetTokenIssuerNameResponse {
  name: string;
}

interface GetTotalIssuedUnitsByTokenRequest {
  token: Token;
}

interface GetTotalIssuedUnitsByTokenResponse {
  amount: Amount;
}

export const AssetInspector = {
  serviceProviderName: AssetInspectorServiceProviderName,
  async GetAssetTokenIssuerName(
    request: GetAssetTokenIssuerNameRequest,
  ): Promise<GetAssetTokenIssuerNameResponse> {
    const response = await jsonRPCRequest({
      url: config.get("meshPublicURL"),
      method: `${AssetInspectorServiceProviderName}.GetAssetTokenIssuerName`,
      request,
    });

    return {
      name: response.name,
    };
  },
  async GetTotalIssuedUnitsByToken(
    request: GetTotalIssuedUnitsByTokenRequest,
  ): Promise<GetTotalIssuedUnitsByTokenResponse> {
    const response = await jsonRPCRequest({
      url: config.get("meshPublicURL"),
      method: `${AssetInspectorServiceProviderName}.GetTotalIssuedUnitsByToken`,
      request,
    });

    return {
      amount: new Amount(response.amount),
    };
  },
};

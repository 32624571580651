import config from "react-global-configuration";
import {
  jsonRPCRequest,
  JSONRPCRequestOpts,
} from "utilities/network/jsonRPCRequest/jsonRPCRequest";
import { Context } from "james/security";
import { SubscriptionUnderwriting } from "./SubscriptionUnderwriting";

export type ResolveSubscriptionUnderwritingIDStateRequest = {
  context: Context;
  subscriptionUnderwritingID: string;
  ignoreResolutionCount: boolean;
};

export type ResolveSubscriptionUnderwritingIDStateResponse = {
  subscriptionUnderwriting: SubscriptionUnderwriting;
};

export type FundSubscriptionUnderwritingIDRequest = {
  context: Context;
  accountID: string;
  subscriptionUnderwritingID: string;
};

export type FundSubscriptionUnderwritingIDResponse = {
  subscriptionUnderwriting: SubscriptionUnderwriting;
  transactionID: string;
};

export const SubscriptionUnderwritingStateControllerServiceProviderName =
  "market-SubscriptionUnderwritingStateController";

export const SubscriptionUnderwritingStateController = {
  async ResolveSubscriptionUnderwritingIDState(
    request: ResolveSubscriptionUnderwritingIDStateRequest,
    opts?: JSONRPCRequestOpts,
  ): Promise<ResolveSubscriptionUnderwritingIDStateResponse> {
    const response = await jsonRPCRequest(
      {
        url: config.get("meshConsistencyAuthorizedURL"),
        method: `${SubscriptionUnderwritingStateControllerServiceProviderName}.ResolveSubscriptionUnderwritingIDState`,
        request,
      },
      opts,
    );

    return {
      subscriptionUnderwriting: new SubscriptionUnderwriting(
        response.subscriptionUnderwriting,
      ),
    };
  },
  async FundSubscriptionUnderwritingID(
    request: FundSubscriptionUnderwritingIDRequest,
    opts?: JSONRPCRequestOpts,
  ): Promise<FundSubscriptionUnderwritingIDResponse> {
    const response = await jsonRPCRequest(
      {
        url: config.get("meshConsistencyAuthorizedURL"),
        method: `${SubscriptionUnderwritingStateControllerServiceProviderName}.FundSubscriptionUnderwritingID`,
        request,
      },
      opts,
    );

    return {
      subscriptionUnderwriting: new SubscriptionUnderwriting(
        response.subscriptionUnderwriting,
      ),
      transactionID: response.transactionID,
    };
  },
};

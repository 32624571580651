import React, { ReactNode } from "react";
import { Box, Card, Theme, useMediaQuery } from "@mui/material";
import meshMiniLogo from "assets/images/logo/meshLogo.svg";
import { useWindowSize } from "utilities/general";
import { useAppNoticeContext } from "context/AppNotice/AppNotice";

export function OnboardingCard({
  children,
  linksFooter,
  dataComponentAttribute,
}: {
  children: ReactNode;
  linksFooter: ReactNode;
  dataComponentAttribute?: string;
}) {
  const isMobile = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down("sm"),
  );
  const windowSize = useWindowSize();
  const { NotificationBannerHeight } = useAppNoticeContext();

  return (
    <Card
      sx={(theme) => ({
        display: "grid",
        gridTemplateRows: "1fr, auto",
        justifyItems: "center",
        width: !isMobile ? "374px" : windowSize[0],
        backgroundColor: theme.palette.custom.midnight,
        padding: isMobile ? theme.spacing(0, 3) : theme.spacing(0, 4),
        maxWidth: !isMobile ? "374px" : windowSize[0],
        height: !isMobile
          ? "568px"
          : `calc(${windowSize[1]}px - ${NotificationBannerHeight}px)`,
        alignItems: "center",
        overflowY: !isMobile ? "hidden" : "auto",
        borderRadius: isMobile ? 0 : "5px",
      })}
      data-component-info={dataComponentAttribute}
    >
      {isMobile && (
        <Box
          component="img"
          sx={{
            width: 220,
            height: 100,
          }}
          src={meshMiniLogo}
        />
      )}
      {children}
      {linksFooter}
    </Card>
  );
}

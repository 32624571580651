import { Card, Typography, useTheme } from "@mui/material";

export type NoDataSplashCardProps = {
  dataDescriptionTitle: string;
  dataDescriptionBody: string;
};

export const NoDataSplashCard = (props: NoDataSplashCardProps) => {
  const theme = useTheme();

  return (
    <Card
      sx={{
        width: "100%",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        p: theme.spacing(4),
        mb: theme.spacing(2),
      }}
    >
      <Typography
        variant="subtitle1"
        sx={{
          mb: theme.spacing(1),
          color: theme.palette.secondary.main,
        }}
      >
        No {props.dataDescriptionTitle} Found
      </Typography>
      <Typography variant="body1">
        {props.dataDescriptionBody} will show up here once at least one has been
        created.
      </Typography>
    </Card>
  );
};

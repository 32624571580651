import React, { useCallback, useContext, useRef } from "react";
import { Model, Reader } from "james/views/ledgerTokenView";
import { TextExactCriterion } from "james/search/criterion";
import { sleep } from "utilities/general";
import { Token } from "james/ledger";
import { FutureToken } from "@mesh/common-js/dist/ledger/futureToken_pb";
import { TokenCategory } from "james/views/ledgerTokenView/Model";

interface ContextType {
  getLedgerTokenViewModel: (token?: Token | FutureToken) => Promise<Model>;
}

const Context = React.createContext({} as ContextType);

export function LedgerTokenViewContext({
  children,
}: {
  children?: React.ReactNode;
}) {
  const { current: fetchInProgressFor } = useRef<{ [key: string]: boolean }>(
    {},
  );
  const { current: modelCache } = useRef<{ [key: string]: Model }>({});

  const getLedgerTokenViewModel = useCallback(
    async (token?: Token | FutureToken) => {
      if (!token) {
        throw new TypeError(
          "token given to getLedgerTokenViewModel in LedgerTokenViewContext is undefined",
        );
      }
      const _token =
        token instanceof FutureToken ? Token.fromFutureToken(token) : token;

      // generate a unique token string
      const tokenString = `${_token.code}-${_token.issuer}-${_token.network}`;
      try {
        // if the model is already stored, use it
        if (modelCache[`${_token.code}-${_token.issuer}-${_token.network}`]) {
          return modelCache[tokenString];
        }

        // check if a fetch for the model has been recorded
        if (fetchInProgressFor[tokenString]) {
          // if so then model fetch is in progress,
          // wait for that fetch to finish

          // keep track of how many wait sleep cycles have taken place
          let waitCount = 0;

          // for as long as a model is not in the cache...
          while (!modelCache[tokenString]) {
            // wait for 500ms
            await sleep(500);

            // check if wait count exceeded (wait up to 5s)
            if (waitCount > 10) {
              // exceeded, return blank model
              throw new Error(
                `timeout waiting for ledger on platform token view model to be fetched: '${tokenString}'`,
              );
            }

            // otherwise increment wait count and go again
            waitCount++;
          }

          // model is now set, return it
          return modelCache[tokenString];
        }

        // mark fetch in progress
        fetchInProgressFor[tokenString] = true;

        // perform fetch
        modelCache[tokenString] = (
          await Reader.ReadOne({
            criteria: {
              "token.code": TextExactCriterion(_token.code),
              "token.issuer": TextExactCriterion(_token.issuer),
              "token.network": TextExactCriterion(_token.network),
            },
          })
        ).model;

        // mark fetch complete
        delete fetchInProgressFor[tokenString];

        // and return it
        return modelCache[tokenString];
      } catch (error) {
        console.error("error fetching token view model", error);

        // mark fetch complete
        delete fetchInProgressFor[tokenString];

        // return dummy view model to prevent UI breaks
        return new Model({
          id: "",
          ownerID: "",
          token: _token,
          tokenCategory: TokenCategory.Unknown,
          name: _token.code,
          issuer: "-",
          publicIconURL: "",
        } as Model);
      }
    },
    [modelCache, fetchInProgressFor],
  );

  return (
    <Context.Provider
      value={{
        getLedgerTokenViewModel,
      }}
    >
      {children}
    </Context.Provider>
  );
}

const useLedgerTokenViewContext = () => useContext(Context);
export { useLedgerTokenViewContext };

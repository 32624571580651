/**
 * @fileoverview gRPC-Web generated client stub for stellarAccountView
 * @enhanceable
 * @public
 */

// Code generated by protoc-gen-grpc-web. DO NOT EDIT.
// versions:
// 	protoc-gen-grpc-web v1.5.0
// 	protoc              v5.28.0
// source: views/stellarAccountView/modelReaderUNSCOPED.proto


/* eslint-disable */
// @ts-nocheck



const grpc = {};
grpc.web = require('grpc-web');


var views_stellarAccountView_modelReader_pb = require('../../views/stellarAccountView/modelReader_pb.js')
const proto = {};
proto.stellarAccountView = require('./modelReaderUNSCOPED_pb.js');

/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?grpc.web.ClientOptions} options
 * @constructor
 * @struct
 * @final
 */
proto.stellarAccountView.ModelReaderUNSCOPEDClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options.format = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname.replace(/\/+$/, '');

};


/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?grpc.web.ClientOptions} options
 * @constructor
 * @struct
 * @final
 */
proto.stellarAccountView.ModelReaderUNSCOPEDPromiseClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options.format = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname.replace(/\/+$/, '');

};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.stellarAccountView.ReadOneModelRequest,
 *   !proto.stellarAccountView.ReadOneModelResponse>}
 */
const methodDescriptor_ModelReaderUNSCOPED_ReadOneModelUNSCOPED = new grpc.web.MethodDescriptor(
  '/stellarAccountView.ModelReaderUNSCOPED/ReadOneModelUNSCOPED',
  grpc.web.MethodType.UNARY,
  views_stellarAccountView_modelReader_pb.ReadOneModelRequest,
  views_stellarAccountView_modelReader_pb.ReadOneModelResponse,
  /**
   * @param {!proto.stellarAccountView.ReadOneModelRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  views_stellarAccountView_modelReader_pb.ReadOneModelResponse.deserializeBinary
);


/**
 * @param {!proto.stellarAccountView.ReadOneModelRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.stellarAccountView.ReadOneModelResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.stellarAccountView.ReadOneModelResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.stellarAccountView.ModelReaderUNSCOPEDClient.prototype.readOneModelUNSCOPED =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/stellarAccountView.ModelReaderUNSCOPED/ReadOneModelUNSCOPED',
      request,
      metadata || {},
      methodDescriptor_ModelReaderUNSCOPED_ReadOneModelUNSCOPED,
      callback);
};


/**
 * @param {!proto.stellarAccountView.ReadOneModelRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.stellarAccountView.ReadOneModelResponse>}
 *     Promise that resolves to the response
 */
proto.stellarAccountView.ModelReaderUNSCOPEDPromiseClient.prototype.readOneModelUNSCOPED =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/stellarAccountView.ModelReaderUNSCOPED/ReadOneModelUNSCOPED',
      request,
      metadata || {},
      methodDescriptor_ModelReaderUNSCOPED_ReadOneModelUNSCOPED);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.stellarAccountView.ReadManyModelRequest,
 *   !proto.stellarAccountView.ReadManyModelResponse>}
 */
const methodDescriptor_ModelReaderUNSCOPED_ReadManyModelUNSCOPED = new grpc.web.MethodDescriptor(
  '/stellarAccountView.ModelReaderUNSCOPED/ReadManyModelUNSCOPED',
  grpc.web.MethodType.UNARY,
  views_stellarAccountView_modelReader_pb.ReadManyModelRequest,
  views_stellarAccountView_modelReader_pb.ReadManyModelResponse,
  /**
   * @param {!proto.stellarAccountView.ReadManyModelRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  views_stellarAccountView_modelReader_pb.ReadManyModelResponse.deserializeBinary
);


/**
 * @param {!proto.stellarAccountView.ReadManyModelRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.stellarAccountView.ReadManyModelResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.stellarAccountView.ReadManyModelResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.stellarAccountView.ModelReaderUNSCOPEDClient.prototype.readManyModelUNSCOPED =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/stellarAccountView.ModelReaderUNSCOPED/ReadManyModelUNSCOPED',
      request,
      metadata || {},
      methodDescriptor_ModelReaderUNSCOPED_ReadManyModelUNSCOPED,
      callback);
};


/**
 * @param {!proto.stellarAccountView.ReadManyModelRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.stellarAccountView.ReadManyModelResponse>}
 *     Promise that resolves to the response
 */
proto.stellarAccountView.ModelReaderUNSCOPEDPromiseClient.prototype.readManyModelUNSCOPED =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/stellarAccountView.ModelReaderUNSCOPED/ReadManyModelUNSCOPED',
      request,
      metadata || {},
      methodDescriptor_ModelReaderUNSCOPED_ReadManyModelUNSCOPED);
};


module.exports = proto.stellarAccountView;


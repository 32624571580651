/**
 * @fileoverview gRPC-Web generated client stub for marketingInstrumentMarketingContentView
 * @enhanceable
 * @public
 */

// Code generated by protoc-gen-grpc-web. DO NOT EDIT.
// versions:
// 	protoc-gen-grpc-web v1.5.0
// 	protoc              v5.28.0
// source: views/marketingInstrumentMarketingContentView/modelReader_meshproto.proto


/* eslint-disable */
// @ts-nocheck



const grpc = {};
grpc.web = require('grpc-web');


var security_context_pb = require('../../security/context_pb.js')

var search_criterion_pb = require('../../search/criterion_pb.js')

var search_query_pb = require('../../search/query_pb.js')

var search_sorting_pb = require('../../search/sorting_pb.js')

var views_marketingInstrumentMarketingContentView_model_pb = require('../../views/marketingInstrumentMarketingContentView/model_pb.js')

var marketing_socialMediaLinks_pb = require('../../marketing/socialMediaLinks_pb.js')

var marketing_independentReview_pb = require('../../marketing/independentReview_pb.js')

var marketing_mediaEntry_pb = require('../../marketing/mediaEntry_pb.js')

var marketing_newsArticle_pb = require('../../marketing/newsArticle_pb.js')
const proto = {};
proto.marketingInstrumentMarketingContentView = require('./modelReader_meshproto_pb.js');

/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?grpc.web.ClientOptions} options
 * @constructor
 * @struct
 * @final
 */
proto.marketingInstrumentMarketingContentView.ModelReaderClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options.format = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname.replace(/\/+$/, '');

};


/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?grpc.web.ClientOptions} options
 * @constructor
 * @struct
 * @final
 */
proto.marketingInstrumentMarketingContentView.ModelReaderPromiseClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options.format = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname.replace(/\/+$/, '');

};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.marketingInstrumentMarketingContentView.ReadOneModelRequest,
 *   !proto.marketingInstrumentMarketingContentView.ReadOneModelResponse>}
 */
const methodDescriptor_ModelReader_ReadOneModel = new grpc.web.MethodDescriptor(
  '/marketingInstrumentMarketingContentView.ModelReader/ReadOneModel',
  grpc.web.MethodType.UNARY,
  proto.marketingInstrumentMarketingContentView.ReadOneModelRequest,
  proto.marketingInstrumentMarketingContentView.ReadOneModelResponse,
  /**
   * @param {!proto.marketingInstrumentMarketingContentView.ReadOneModelRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.marketingInstrumentMarketingContentView.ReadOneModelResponse.deserializeBinary
);


/**
 * @param {!proto.marketingInstrumentMarketingContentView.ReadOneModelRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.marketingInstrumentMarketingContentView.ReadOneModelResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.marketingInstrumentMarketingContentView.ReadOneModelResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.marketingInstrumentMarketingContentView.ModelReaderClient.prototype.readOneModel =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/marketingInstrumentMarketingContentView.ModelReader/ReadOneModel',
      request,
      metadata || {},
      methodDescriptor_ModelReader_ReadOneModel,
      callback);
};


/**
 * @param {!proto.marketingInstrumentMarketingContentView.ReadOneModelRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.marketingInstrumentMarketingContentView.ReadOneModelResponse>}
 *     Promise that resolves to the response
 */
proto.marketingInstrumentMarketingContentView.ModelReaderPromiseClient.prototype.readOneModel =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/marketingInstrumentMarketingContentView.ModelReader/ReadOneModel',
      request,
      metadata || {},
      methodDescriptor_ModelReader_ReadOneModel);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.marketingInstrumentMarketingContentView.ReadManyModelRequest,
 *   !proto.marketingInstrumentMarketingContentView.ReadManyModelResponse>}
 */
const methodDescriptor_ModelReader_ReadManyModel = new grpc.web.MethodDescriptor(
  '/marketingInstrumentMarketingContentView.ModelReader/ReadManyModel',
  grpc.web.MethodType.UNARY,
  proto.marketingInstrumentMarketingContentView.ReadManyModelRequest,
  proto.marketingInstrumentMarketingContentView.ReadManyModelResponse,
  /**
   * @param {!proto.marketingInstrumentMarketingContentView.ReadManyModelRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.marketingInstrumentMarketingContentView.ReadManyModelResponse.deserializeBinary
);


/**
 * @param {!proto.marketingInstrumentMarketingContentView.ReadManyModelRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.marketingInstrumentMarketingContentView.ReadManyModelResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.marketingInstrumentMarketingContentView.ReadManyModelResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.marketingInstrumentMarketingContentView.ModelReaderClient.prototype.readManyModel =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/marketingInstrumentMarketingContentView.ModelReader/ReadManyModel',
      request,
      metadata || {},
      methodDescriptor_ModelReader_ReadManyModel,
      callback);
};


/**
 * @param {!proto.marketingInstrumentMarketingContentView.ReadManyModelRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.marketingInstrumentMarketingContentView.ReadManyModelResponse>}
 *     Promise that resolves to the response
 */
proto.marketingInstrumentMarketingContentView.ModelReaderPromiseClient.prototype.readManyModel =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/marketingInstrumentMarketingContentView.ModelReader/ReadManyModel',
      request,
      metadata || {},
      methodDescriptor_ModelReader_ReadManyModel);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.marketingInstrumentMarketingContentView.ReadAllModelRequest,
 *   !proto.marketingInstrumentMarketingContentView.ReadAllModelResponse>}
 */
const methodDescriptor_ModelReader_ReadAllModel = new grpc.web.MethodDescriptor(
  '/marketingInstrumentMarketingContentView.ModelReader/ReadAllModel',
  grpc.web.MethodType.UNARY,
  proto.marketingInstrumentMarketingContentView.ReadAllModelRequest,
  proto.marketingInstrumentMarketingContentView.ReadAllModelResponse,
  /**
   * @param {!proto.marketingInstrumentMarketingContentView.ReadAllModelRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.marketingInstrumentMarketingContentView.ReadAllModelResponse.deserializeBinary
);


/**
 * @param {!proto.marketingInstrumentMarketingContentView.ReadAllModelRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.marketingInstrumentMarketingContentView.ReadAllModelResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.marketingInstrumentMarketingContentView.ReadAllModelResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.marketingInstrumentMarketingContentView.ModelReaderClient.prototype.readAllModel =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/marketingInstrumentMarketingContentView.ModelReader/ReadAllModel',
      request,
      metadata || {},
      methodDescriptor_ModelReader_ReadAllModel,
      callback);
};


/**
 * @param {!proto.marketingInstrumentMarketingContentView.ReadAllModelRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.marketingInstrumentMarketingContentView.ReadAllModelResponse>}
 *     Promise that resolves to the response
 */
proto.marketingInstrumentMarketingContentView.ModelReaderPromiseClient.prototype.readAllModel =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/marketingInstrumentMarketingContentView.ModelReader/ReadAllModel',
      request,
      metadata || {},
      methodDescriptor_ModelReader_ReadAllModel);
};


module.exports = proto.marketingInstrumentMarketingContentView;


"use strict";
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.TextNumField = TextNumField;
const jsx_runtime_1 = require("react/jsx-runtime");
const react_1 = require("react");
const num_1 = require("@mesh/common-js/dist/num");
const TextField_1 = require("./TextField");
const bignumber_js_1 = require("bignumber.js");
const decimal_pb_1 = require("@mesh/common-js/dist/num/decimal_pb");
function TextNumField(_a) {
    var { disallowNegative, noDecimalPlaces, value: externalValue } = _a, props = __rest(_a, ["disallowNegative", "noDecimalPlaces", "value"]);
    const formattedExternalValue = (0, num_1.formatTextNum)(externalValue ? (0, num_1.decimalToBigNumber)(externalValue) : "", {
        disallowNegative,
        noDecimalPlaces,
    });
    const [neverChanged, setNeverChanged] = (0, react_1.useState)(true);
    const [renderedValue, setRenderedValue] = (0, react_1.useState)(formattedExternalValue);
    const prevRenderedValue = usePrevious(renderedValue);
    // parse relevant values before doing any maths
    const bigRenderedValue = new bignumber_js_1.BigNumber((0, num_1.stripThousandSeparators)(renderedValue));
    const bigExternalValue = new bignumber_js_1.BigNumber(externalValue ? (0, num_1.decimalToBigNumber)(externalValue) : "");
    // to update rendered value when value of field is updated by an action
    // external to this component
    if (
    // (if formatted external value is different to both the
    // current and last rendered values) AND
    !(formattedExternalValue === renderedValue ||
        formattedExternalValue === prevRenderedValue) &&
        // the current external value and rendered values are
        // valid numbers that differ numerically OR
        ((!bigExternalValue.isNaN() &&
            !bigRenderedValue.isNaN() &&
            !bigExternalValue.eq(bigRenderedValue)) ||
            // the current external value is a valid number
            // and the rendered value is not
            (!bigExternalValue.isNaN() && bigRenderedValue.isNaN()))) {
        setRenderedValue(formattedExternalValue);
    }
    const handleChange = (e) => {
        // format changed value to implement rounding, special character processing etc
        let value = e.target.value;
        if (value === "0-" || /^[-]\D$/g.test(value)) {
            value = "-";
        }
        const formattedValue = (0, num_1.formatTextNum)(value, {
            disallowNegative,
            noDecimalPlaces,
        });
        const bigFormattedWithoutSeparators = new bignumber_js_1.BigNumber((0, num_1.stripThousandSeparators)(formattedValue));
        if (
        // if an onChange handler is given AND
        props.onChange && // new value IS a number AND
            ((!bigFormattedWithoutSeparators.isNaN() &&
                // old value is NOT a number OR
                (bigRenderedValue.isNaN() ||
                    // old value is a number that differs numerically from the new value
                    !bigRenderedValue.eq(bigFormattedWithoutSeparators))) ||
                // new formatted value is a different length to the old value OR
                formattedValue.length !== renderedValue.length ||
                // value has changed from the rendered value in
                // such a way that the formatted version is ''
                formattedValue === "")) {
            // call on change
            props.onChange(formattedValue === ""
                ? // call with 0 if formatted value is blank
                    // so that clearing a field triggers onChange(value = 0)
                    // even if field is shown blank
                    new decimal_pb_1.Decimal()
                : (0, num_1.bigNumberToDecimal)(bigFormattedWithoutSeparators));
        }
        // indicate that field has changed at least once
        if (neverChanged) {
            setNeverChanged(false);
        }
        // update value rendered in text field
        setRenderedValue(formattedValue);
    };
    return ((0, jsx_runtime_1.jsx)(TextField_1.TextField, Object.assign({}, props, { value: 
        // If field has never been changed, the value to render is '0' and the
        // field is not in read only mode, then render '' instead of the zero.
        // This is to show number fields as blank on blank forms on first render
        // instead of the 0 that a blank BigNumber.toString() will resolve to.
        neverChanged && renderedValue === "0" && !props.readOnly
            ? ""
            : renderedValue, placeholder: "0." + (0, num_1.giveMe0s)(noDecimalPlaces ? noDecimalPlaces : 2), onChange: handleChange, inputProps: Object.assign(Object.assign({}, props.inputProps), { type: undefined }) })));
}
function usePrevious(value) {
    const ref = (0, react_1.useRef)();
    (0, react_1.useEffect)(() => {
        ref.current = value;
    });
    return ref.current;
}

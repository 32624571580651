import { Amount } from "james/ledger";
import { Entry } from "james/audit/Entry";
import { BigNumber } from "bignumber.js";

const SubscriptionUnderwritingTypeName =
  "mesh::market/SubscriptionUnderwriting";

export enum SubscriptionUnderwritingState {
  AwaitingFunding = "Awaiting Funding",
  FundingInProgress = "Funding in Progress",
  FundingUnderInvestigation = "Funding Under Investigation",
  FundingFailed = "Funding Failed",
  AwaitingSettlement = "Awaiting Settlement",
  RefundInProgress = "Refund in Progress",
  RefundUnderInvestigation = "Refund Under Investigation",
  Settled = "Settled",
  ZeroObligation = "Zero Obligation",
}

export const AllSubscriptionUnderwritingStates: SubscriptionUnderwritingState[] =
  [
    SubscriptionUnderwritingState.AwaitingFunding,
    SubscriptionUnderwritingState.FundingInProgress,
    SubscriptionUnderwritingState.FundingUnderInvestigation,
    SubscriptionUnderwritingState.FundingFailed,
    SubscriptionUnderwritingState.AwaitingSettlement,
    SubscriptionUnderwritingState.RefundInProgress,
    SubscriptionUnderwritingState.RefundUnderInvestigation,
    SubscriptionUnderwritingState.Settled,
    SubscriptionUnderwritingState.ZeroObligation,
  ];

export class SubscriptionUnderwriting {
  public ["@type"]: string = SubscriptionUnderwritingTypeName;
  public id = "";
  public ownerID = "";
  public subscriptionOrderBookID = "";
  public fundingSourceAccountLedgerID = "";
  public number = "";
  public state: SubscriptionUnderwritingState | "" = "";
  public percentage: BigNumber = new BigNumber("0");
  public maxTakeUpAmount: Amount = new Amount();
  public maxTakeUpTokens: Amount = new Amount();
  public takenUpAmount: Amount = new Amount();
  public takenUpTokens: Amount = new Amount();
  public resolutionCount = 0;
  public auditEntry: Entry = new Entry();
  public holdingAccountLedgerID = "";

  constructor(l?: SubscriptionUnderwriting) {
    if (!l) {
      return;
    }
    this.id = l.id;
    this.ownerID = l.ownerID;
    this.subscriptionOrderBookID = l.subscriptionOrderBookID;
    this.holdingAccountLedgerID = l.holdingAccountLedgerID;
    this.number = l.number;
    this.state = l.state;
    this.percentage = new BigNumber(l.percentage);
    this.maxTakeUpAmount = new Amount(l.maxTakeUpAmount);
    this.maxTakeUpTokens = new Amount(l.maxTakeUpTokens);
    this.takenUpAmount = new Amount(l.takenUpAmount);
    this.takenUpTokens = new Amount(l.takenUpTokens);
    this.resolutionCount = l.resolutionCount;
    this.auditEntry = new Entry(l.auditEntry);
    this.holdingAccountLedgerID = l.holdingAccountLedgerID;
  }
}

import React, {
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import { BigNumber } from "bignumber.js";
import dayjs from "dayjs";
import { PriceHistorian, SpotCalculator, SpotPricer } from "pkgTemp/market";
import {
  Spot,
  SpotType,
  SpotState,
  SubscriptionOrder,
  SubscriptionOrderState,
} from "james/market";
import { MeshPriceHistorian } from "james/market/PriceHistorian";
import { MarketListingViewModel } from "james/views/marketListingView";
import { Model as LedgerTokenViewModel } from "james/views/ledgerTokenView";
import {
  Model as MarketSpotViewModel,
  MarketSpotViewNotificationChannelName,
  MarketSpotViewModelChangedNotification,
  MarketSpotViewModelChangedNotificationTypeName,
} from "james/views/marketSpotView";
import {
  ModelChangedNotification as MarketSubscriptionOrderViewModelChangedNotification,
  ModelChangedNotificationTypeName as MarketSubscriptionOrderViewModelChangedNotificationTypeName,
  subscriptionOrderViewNotificationChannelName as MarketSubscriptionOrderViewNotificationChannelName,
} from "james/views/marketSubscriptionOrderView";
import { GroupNotificationChannel } from "james/group";
import { useNotificationContext } from "context/Notification";
import { Notification } from "james/notification/Notification";
import { useStellarContext } from "context/Stellar";
import { useGTMTriggersPusher } from "hooks/analytics/useGTMTriggersPusher";
import {
  TransactionDetails,
  TransactionStage,
  TransactionTypes,
} from "types/gtm";
import { useSnackbar } from "notistack";
import { SpotTradeFailedDialog } from "./components/SpotTradeFailedDialog/SpotTradeFailedDialog";
import { SubscriptionOrderSubmittedDialog } from "./components/SubscriptionOrderSubmittedDialog/SubscriptionOrderSubmittedDialog";

type SubscriptionOrderRegistrationData = {
  subscriptionOrder: SubscriptionOrder;
  assetListingViewModel: MarketListingViewModel;
  ledgerTokenViewModel: LedgerTokenViewModel;
};

interface ContextType {
  marketContextSpotPricer: SpotPricer;
  marketContextPriceHistorian: PriceHistorian;
  marketContextSpotCalculator: SpotCalculator;
  marketContextMonitorSpot: (
    spot: Spot,
    spotMetaData: {
      assetListingViewModel: MarketListingViewModel;
      ledgerTokenViewModel: LedgerTokenViewModel;
      fee: BigNumber;
    },
  ) => Promise<void>;
  marketContextMonitorSubscriptionOrder: (
    data: SubscriptionOrderRegistrationData,
  ) => Promise<void>;
}

const Context = React.createContext({} as ContextType);

export function MarketContext({ children }: { children?: React.ReactNode }) {
  const { enqueueSnackbar } = useSnackbar();
  const {
    pushTransactionStart,
    pushTransactionAbandon,
    pushTransactionComplete,
  } = useGTMTriggersPusher();

  // construct market service providers
  const { stellarContextSpotPricer } = useStellarContext();
  const { current: marketContextSpotPricer } = useRef(stellarContextSpotPricer);
  const { current: marketContextPriceHistorian } = useRef(
    new MeshPriceHistorian(),
  );
  const { current: marketContextSpotCalculator } = useRef(
    new SpotCalculator({
      feeMultiplier: new BigNumber("0.002"),
      vatRate: new BigNumber("0.15"),
      spotPricer: marketContextSpotPricer,
    }),
  );

  // construct trade monitoring service provider
  const { registerNotificationCallback } = useNotificationContext();

  const [failedSpotTrades, setFailedSpotTrades] = useState<
    MarketSpotViewModel[]
  >([]);
  const [
    awaitingSettlementSubscriptionOrders,
    setAwaitingSettlementSubscriptionOrders,
  ] = useState<SubscriptionOrderRegistrationData[]>([]);

  const notificationDeRegistrationFunctionIdx = useRef<{
    [key: string]: () => void;
  }>({});

  useEffect(() => {
    return () => {
      Object.keys(notificationDeRegistrationFunctionIdx.current).forEach(
        (entityID) => {
          if (notificationDeRegistrationFunctionIdx.current[entityID]) {
            notificationDeRegistrationFunctionIdx.current[entityID]();
          }
        },
      );
    };
  }, []);

  const marketContextMonitorSpot = useCallback(
    async (
      spot: Spot,
      spotMetaData: {
        assetListingViewModel: MarketListingViewModel;
        fee: BigNumber;
        ledgerTokenViewModel: LedgerTokenViewModel;
      },
    ) => {
      notificationDeRegistrationFunctionIdx.current[spot.id] =
        await registerNotificationCallback(
          new GroupNotificationChannel({
            groupID: spot.ownerID,
            name: MarketSpotViewNotificationChannelName,
            private: true,
          }),
          [MarketSpotViewModelChangedNotificationTypeName],
          (n: Notification) => {
            if (
              n instanceof MarketSpotViewModelChangedNotification &&
              n.model.spotID === spot.id
            ) {
              switch (n.model.state) {
                case SpotState.Pending:
                  // trigger the transaction started analytics event
                  pushTransactionStart({
                    // use the spot ID to uniquely identify the transaction
                    transaction_id: n.model.number,
                    transaction_type: TransactionTypes.spotTrade,
                    // use the asset short name as transaction_asset_name
                    transaction_asset_name:
                      spotMetaData.ledgerTokenViewModel.name,
                    // use the combination asset code:issuer:network to create an asset id
                    transaction_asset_id: `${spotMetaData.ledgerTokenViewModel.token.code}:${spotMetaData.ledgerTokenViewModel.token.issuer}:${spotMetaData.ledgerTokenViewModel.token.network}`,
                    // use the price on model to set asset sell price
                    transaction_asset_sell_price:
                      spot.type === SpotType.Sell
                        ? spot.estimatedPrice.value.toString()
                        : "0",
                    // use the price on model to set asset buy price
                    transaction_asset_buy_price: SpotType.Buy
                      ? spot.estimatedPrice.value.toString()
                      : "0",
                    // set the type of transaction in the case spot trades
                    transaction_asset_type:
                      spotMetaData.assetListingViewModel.assetType,
                    // set transaction asset issuer
                    transaction_asset_issuer:
                      spotMetaData.ledgerTokenViewModel.issuer,
                    // use the instrument risk profile to set the asset risk rating
                    transaction_asset_risk_rating:
                      spotMetaData.assetListingViewModel.instrumentRiskProfile,
                    transaction_stage: TransactionStage.start,
                    // this is the date in which the transaction is initiated
                    transaction_date: dayjs().format(),
                    // set the transaction slippage
                    transaction_slippage: spot.slippage.toString(),
                    // set transaction trade fee
                    transaction_trade_fee: spotMetaData.fee.toString(),
                    // set the transaction currency
                    transaction_currency: spot.quoteAmount.token.code,
                    // use the quote amount on the spot as the investment amount
                    transaction_investment_amount:
                      spot.quoteAmount.value.toString(),
                    // use asset listing instrument risk profile as investor profile
                    transaction_asset_investor_profile:
                      spotMetaData.assetListingViewModel.instrumentRiskProfile.toString(),
                  } as TransactionDetails);

                  return;

                case SpotState.Settled:
                  enqueueSnackbar(`Trade #${n.model.number} has settled`, {
                    variant: "success",
                  });

                  pushTransactionComplete({
                    // use the spot number to uniquelly identify the transaction
                    transaction_id: n.model.number,
                    transaction_type: TransactionTypes.spotTrade,
                    // use the asset short name as transaction_asset_name
                    transaction_asset_name:
                      spotMetaData.ledgerTokenViewModel.name,
                    // use the combination asset code:issuer:network to create an asset id
                    transaction_asset_id: `${spotMetaData.ledgerTokenViewModel.token.code}:${spotMetaData.ledgerTokenViewModel.token.issuer}:${spotMetaData.ledgerTokenViewModel.token.network}`,
                    // use the price on model to set asset sell price
                    transaction_asset_sell_price:
                      spot.type === SpotType.Sell
                        ? spot.estimatedPrice.value.toString()
                        : "0",
                    // use the price on model to set asset buy price
                    transaction_asset_buy_price: SpotType.Buy
                      ? spot.estimatedPrice.value.toString()
                      : "0",
                    // set the type of transaction in the case spot trades
                    transaction_asset_type:
                      spotMetaData.assetListingViewModel.assetType,
                    // set transaction asset issuer
                    transaction_asset_issuer:
                      spotMetaData.ledgerTokenViewModel.issuer,
                    // use the instrument risk profile to set the asset risk rating
                    transaction_asset_risk_rating:
                      spotMetaData.assetListingViewModel.instrumentRiskProfile,
                    transaction_stage: TransactionStage.complete,
                    // this is the date in which the transaction is initiated
                    transaction_date: dayjs().format(),
                    // set the transaction slippage
                    transaction_slippage: spot.slippage.toString(),
                    // set transaction trade fee
                    transaction_trade_fee: spotMetaData.fee.toString(),
                    // set the transaction currency
                    transaction_currency: spot.quoteAmount.token.code,
                    // use the quote amount on the spot as the investment amount
                    transaction_investment_amount:
                      spot.quoteAmount.value.toString(),
                    // use asset listing instrument risk profile as investor profile
                    transaction_asset_investor_profile:
                      spotMetaData.assetListingViewModel.instrumentRiskProfile.toString(),
                  } as TransactionDetails);

                  break;

                case SpotState.Failed:
                  // trigger the transaction abandoned event
                  pushTransactionAbandon({
                    // use the spot ID to uniquely identify the transaction
                    transaction_id: n.model.number,
                    transaction_type: TransactionTypes.spotTrade,
                    // use the asset short name as transaction_asset_name
                    transaction_asset_name:
                      spotMetaData.ledgerTokenViewModel.name,
                    // use the combination asset code:issuer:network to create an asset id
                    transaction_asset_id: `${spotMetaData.ledgerTokenViewModel.token.code}:${spotMetaData.ledgerTokenViewModel.token.issuer}:${spotMetaData.ledgerTokenViewModel.token.network}`,
                    // use the price on model to set asset sell price
                    transaction_asset_sell_price:
                      spot.estimatedPrice.token.string(),
                    // use the price on model to set asset buy price
                    transaction_asset_buy_price:
                      spot.estimatedPrice.token.string(),
                    // set the type of transaction in the case spot trades
                    transaction_asset_type:
                      spotMetaData.assetListingViewModel.assetType,
                    // set transaction asset issuer
                    transaction_asset_issuer:
                      spotMetaData.ledgerTokenViewModel.issuer,
                    // use the instrument risk profile to set the asset risk rating
                    transaction_asset_risk_rating:
                      spotMetaData.assetListingViewModel.instrumentRiskProfile,
                    transaction_stage: TransactionStage.abandon,
                    // this is the date in which the transaction is initiated
                    transaction_date: dayjs().format(),
                    // set the transaction slippage
                    transaction_slippage: spot.slippage.toString(),
                    // set transaction trade fee
                    transaction_trade_fee: spotMetaData.fee.toString(),
                    // set the transaction currency
                    transaction_currency: spot.quoteAmount.token.code,
                    // use the quote amount on the spot as the investment amount
                    transaction_investment_amount:
                      spot.quoteAmount.value.toString(),
                    // use asset listing instrument risk profile as investor profile
                    transaction_asset_investor_profile:
                      spotMetaData.assetListingViewModel.instrumentRiskProfile.toString(),
                  } as TransactionDetails);

                  setFailedSpotTrades((prev) => [...prev, n.model]);
                  break;

                case SpotState.UnderInvestigation:
                  enqueueSnackbar(
                    `Something has gone wrong with Trade #${n.model.number} - it's status is being investigated`,
                    { variant: "warning" },
                  );
                  break;
                default:
                  break;
              }
              if (notificationDeRegistrationFunctionIdx.current[spot.id]) {
                notificationDeRegistrationFunctionIdx.current[spot.id]();
              }
            }
          },
        );
    },
    [registerNotificationCallback, enqueueSnackbar],
  );

  const marketContextMonitorSubscriptionOrder = useCallback(
    async (data: SubscriptionOrderRegistrationData) => {
      notificationDeRegistrationFunctionIdx.current[data.subscriptionOrder.id] =
        await registerNotificationCallback(
          new GroupNotificationChannel({
            groupID: data.subscriptionOrder.ownerID,
            name: MarketSubscriptionOrderViewNotificationChannelName,
            private: true,
          }),
          [MarketSubscriptionOrderViewModelChangedNotificationTypeName],
          (n: Notification) => {
            if (
              n instanceof
                MarketSubscriptionOrderViewModelChangedNotification &&
              n.model.subscriptionOrderID === data.subscriptionOrder.id
            ) {
              switch (n.model.state) {
                case SubscriptionOrderState.SubmissionInProgress:
                case SubscriptionOrderState.Failing:
                  // Do nothing during transient states
                  // Return so that deregister is not called.
                  return;

                case SubscriptionOrderState.AwaitingSettlement:
                  setAwaitingSettlementSubscriptionOrders((prev) => [
                    ...prev,
                    data,
                  ]);
                  enqueueSnackbar(
                    `Subscription #${n.model.number} is awaiting settlement`,
                    { variant: "success" },
                  );
                  break;

                case SubscriptionOrderState.Failed:
                  enqueueSnackbar(
                    `Subscription #${n.model.number} has failed`,
                    {
                      variant: "error",
                    },
                  );
                  break;

                case SubscriptionOrderState.FailureUnderInvestigation:
                case SubscriptionOrderState.SubmissionUnderInvestigation:
                  enqueueSnackbar(
                    `Something has gone wrong with Subscription #${n.model.number} - it's status is being investigated`,
                    { variant: "warning" },
                  );
                  break;
              }
              if (
                notificationDeRegistrationFunctionIdx.current[
                  data.subscriptionOrder.id
                ]
              ) {
                notificationDeRegistrationFunctionIdx.current[
                  data.subscriptionOrder.id
                ]();
              }
            }
          },
        );
    },
    [registerNotificationCallback],
  );

  return (
    <Context.Provider
      value={{
        marketContextSpotPricer,
        marketContextPriceHistorian,
        marketContextSpotCalculator,
        marketContextMonitorSpot,
        marketContextMonitorSubscriptionOrder,
      }}
    >
      {children}
      {awaitingSettlementSubscriptionOrders.map((data) => (
        <SubscriptionOrderSubmittedDialog
          key={data.subscriptionOrder.id}
          marketListingViewModel={data.assetListingViewModel}
          ledgerTokenViewModel={data.ledgerTokenViewModel}
          closeDialog={() =>
            setAwaitingSettlementSubscriptionOrders((prev) =>
              prev.filter(
                (prevData) =>
                  prevData.subscriptionOrder.id !== data.subscriptionOrder.id,
              ),
            )
          }
        />
      ))}
      {failedSpotTrades.map((spotTrade) => (
        <SpotTradeFailedDialog
          key={spotTrade.id}
          marketSpotViewModel={spotTrade}
          closeDialog={() =>
            setFailedSpotTrades((prev) =>
              prev.filter((prevSpotTrade) => prevSpotTrade.id !== spotTrade.id),
            )
          }
        />
      ))}
    </Context.Provider>
  );
}

const useMarketContext = () => useContext(Context);
export { useMarketContext };

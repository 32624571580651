import React from "react";
import {
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogProps,
  DialogTitle,
  IconButton,
  Theme,
  Tooltip,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { Close, Info } from "@mui/icons-material";
import { useLimitOrderController } from "../../../hooks/useLimitOrderController";
import { useExchangeDashboardStore } from "../../../store";
import { Amount } from "components/Ledger/Amount";
import { Amount as AmountType } from "james/ledger";
import { IconViewUpload } from "components/Ledger/Token/IconViewUpload";
import { useExchangeStore } from "views/Marketplace/components/Exchange/store";
import { decimalToBigNumber } from "@mesh/common-js/dist/num";

interface LimitOrderConfirmationDialogProps extends DialogProps {
  closeDialog: () => void;
}

export const LimitOrderConfirmationDialog = ({
  closeDialog,
  ...props
}: LimitOrderConfirmationDialogProps) => {
  const smDown = useMediaQuery((theme: Theme) => theme.breakpoints.down("sm"));
  const { submitLimitOrder } = useLimitOrderController();
  const store = useExchangeDashboardStore();
  const exchangeStore = useExchangeStore();

  return (
    <Dialog
      open={props.open}
      fullScreen={smDown}
      PaperProps={{
        sx: {
          width: {
            sm: 380,
          },
        },
      }}
    >
      <DialogTitle
        sx={{
          py: 2,
          pl: 3,
          pr: 2,
        }}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            gap: 2,
          }}
        >
          {exchangeStore.selectedMarketListing && (
            <>
              <IconViewUpload
                token={exchangeStore.selectedMarketListing?.token}
              />
              <Box>
                <Typography
                  sx={{
                    display: "-webkit-box",
                    overflow: "hidden",
                    WebkitBoxOrient: "vertical",
                    WebkitLineClamp: 2,
                  }}
                  variant="h5"
                  id={`smallHeader-heading-typography-${exchangeStore.selectedMarketListing.assetName}`}
                  children={exchangeStore.selectedMarketListing.assetName}
                />
                <Typography
                  noWrap
                  id={`smallHeader-caption-typography-${exchangeStore.selectedMarketListing.assetName}`}
                  variant={"caption"}
                  sx={(theme) => ({ color: theme.palette.text.secondary })}
                  children={exchangeStore.selectedMarketListing.token.code}
                />
              </Box>
            </>
          )}

          <IconButton
            sx={{
              ml: "auto",
            }}
            onClick={() => {
              closeDialog();
            }}
          >
            <Close />
          </IconButton>
        </Box>
      </DialogTitle>
      <DialogContent
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: 2,
          pb: 3,
          px: {
            sm: 6,
            xs: 3,
          },
        }}
      >
        <Typography
          sx={{
            mt: 3,
            mb: 1,
            fontWeight: "bold",
          }}
          variant="h4"
        >
          Confirm Trade
        </Typography>

        <Box>
          <Typography color="textSecondary" variant="caption">
            Price
          </Typography>
          <Amount
            amount={AmountType.fromFutureAmount(store.tradeCardState.price)}
          />
        </Box>
        <Box>
          <Typography color="textSecondary" variant="caption">
            Amount
          </Typography>
          <Amount
            amount={AmountType.fromFutureAmount(store.tradeCardState.amount)}
          />
        </Box>
      </DialogContent>

      <DialogContent
        sx={(theme) => ({
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
          backgroundColor: theme.palette.custom.midnight,
          px: 0,
          pb: 3,
        })}
      >
        <Box
          sx={{
            "& > *": {
              px: {
                sm: 6,
                xs: 3,
              },
              gap: 2,
            },
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "flex-start",
            }}
          >
            <Typography variant="caption">Total Value Amount</Typography>
            <Amount
              codeTypographyProps={{
                sx: (theme) => ({
                  fontWeight: "bold",
                  color: theme.palette.custom.yellow,
                }),
              }}
              valueTypographyProps={{
                sx: (theme) => ({
                  fontWeight: "bold",
                  color: theme.palette.custom.darkYellow,
                }),
              }}
              amount={AmountType.fromFutureAmount(
                store.tradeCardState.estimatedTotal,
              )}
            />
          </Box>
          <Box
            sx={(theme) => ({
              display: "flex",
              alignItems: "center",
              gap: 2,
              color: theme.palette.text.secondary,
            })}
          >
            <Typography variant="body2">Total Fee:</Typography>
            <Amount
              valueTypographyProps={{
                variant: "body2",
              }}
              codeTypographyProps={{
                variant: "body2",
              }}
              amount={AmountType.fromFutureAmount(
                store.tradeCardState.fee.fee,
              ).setValue(
                decimalToBigNumber(
                  store.tradeCardState.fee.vat.getValue(),
                ).plus(
                  decimalToBigNumber(store.tradeCardState.fee.fee.getValue()),
                ),
              )}
            />
          </Box>
        </Box>
        <Box
          sx={{
            "& > *": {
              px: {
                sm: 6,
                xs: 3,
              },
            },
          }}
        >
          <Box
            sx={(theme) => ({
              display: "flex",
              alignItems: "center",
              gap: 1,
              color: theme.palette.text.secondary,
              mb: 2,
            })}
          >
            <Typography variant="body2">
              <b>Please note:</b> This order is good 'til cancelled.
            </Typography>
            <Tooltip
              title="A good ‘til cancelled (GTC) order is 
an order to buy or sell an instrument 
that remains valid until it is either 
completed or cancelled"
            >
              <Info sx={{ fontSize: "16px" }} />
            </Tooltip>
          </Box>
          <Box
            sx={(theme) => ({
              display: "grid",
              gridTemplateColumns: "1fr 1fr",
              width: "100%",
              gap: 2,
              pt: 2,
              borderTop: `1px solid ${theme.palette.divider}`,
              "& > *": {
                height: {
                  sm: "36px",
                  xs: "48px",
                },
              },
            })}
          >
            <Button
              fullWidth
              variant="outlined"
              onClick={() => {
                closeDialog();
              }}
            >
              Cancel
            </Button>
            <Button
              fullWidth
              color="primary"
              variant="contained"
              onClick={() => {
                submitLimitOrder();
                closeDialog();
              }}
              disabled={store.loading || !store.tradeCardState.isSignatory}
            >
              Confirm
            </Button>
          </Box>
        </Box>
      </DialogContent>
    </Dialog>
  );
};

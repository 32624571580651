import { User } from "@mesh/common-js/dist/iam/user_pb";
import {
  createContext,
  FC,
  ReactNode,
  useContext,
  useEffect,
  useState,
} from "react";
import { useSnackbar } from "notistack";
import { useErrorContext } from "../Error";
import { useAPIContext } from "../API";
import { useApplicationContext } from "context/Application/Application";
import { FieldMask } from "google-protobuf/google/protobuf/field_mask_pb";
import { GetUserRequest } from "@mesh/common-js/dist/iam/userReadService_pb";
import { useFirebaseContext } from "context/Firebase";
import { useUserLoggedInGTMObserver } from "context/Application/hooks/useUserLoggedInGTMObserver";
import { userToPast } from "james/user/User";
import { UpdateRequest } from "@mesh/common-js/dist/iam/userWriteService_pb";

export type UserContextType = {
  apiCallInProgress: boolean;

  myUser: User;
  setMyUser: (user: User) => void;

  userLoaded: boolean;
  userLoadError: string | null;
  clearUserLoadError: () => void;
  refreshMyUser: () => void;

  updateUser: (user: User, updateFields: string[]) => Promise<User>;
  retrieveUser: () => Promise<User>;
};

export const defaultContext: UserContextType = {
  apiCallInProgress: false,

  myUser: new User(),
  setMyUser: () => null,

  userLoaded: false,
  userLoadError: null,
  clearUserLoadError: () => null,
  refreshMyUser: () => null,

  updateUser: () => new Promise(() => null),
  retrieveUser: () => new Promise(() => null),
};

const Context = createContext<UserContextType>(defaultContext);

export const UserContext: FC<{
  children: ReactNode;
}> = ({ children }) => {
  const { enqueueSnackbar } = useSnackbar();
  const { errorContextErrorTranslator } = useErrorContext();
  const {
    user: { readService, writeService },
  } = useAPIContext();
  const { authContext, userAuthenticated, myClient } = useApplicationContext();
  const { firebaseAuthenticated } = useFirebaseContext();
  const [myUser, setMyUser] = useState<User>(new User());
  const [userLoaded, setUserLoaded] = useState<boolean>(false);
  const [fetchingUser, setFetchingUser] = useState<boolean>(false);
  const [updatingUser, setUpdatingUser] = useState<boolean>(false);
  const refreshMyUser = () => setUserLoaded(false);
  const [userLoadError, setUserLoadError] = useState<string | null>(null);
  const clearUserLoadError = () => setUserLoadError(null);
  const fieldMask = new FieldMask();
  const loggedIn = firebaseAuthenticated && userAuthenticated;

  useEffect(() => {
    if (!loggedIn) {
      return;
    }
    (async () => {
      if (userLoaded || userLoadError) {
        return;
      }

      setFetchingUser(true);
      let userResponse: User | undefined;
      try {
        userResponse = await readService.getUser(
          new GetUserRequest().setContext(authContext.toFuture()),
        );
      } catch (e) {
        setUserLoadError("Could not fetch person");
        setUserLoaded(false);
        setFetchingUser(false);
      }

      if (userResponse) {
        setMyUser(userResponse);
        setUserLoaded(true);
      }
      setFetchingUser(false);
    })();
  }, [userLoadError, userLoaded, loggedIn]);

  const retrieveUser = async () => {
    setFetchingUser(true);
    let userResponse: User | undefined;
    try {
      userResponse = await readService.getUser(
        new GetUserRequest().setContext(authContext.toFuture()),
      );
    } catch (e) {
      setUserLoadError("Could not fetch person");
      setUserLoaded(false);
      setFetchingUser(false);
    }

    if (userResponse) {
      setUserLoaded(true);
      setFetchingUser(false);
      return userResponse;
    }
    setFetchingUser(false);

    return new User();
  };

  const updateUser = async (user: User, updateFields: string[]) => {
    if (!user) {
      return myUser;
    }

    setUpdatingUser(true);
    let updatedUser: User | undefined;
    try {
      updatedUser = await writeService.updateUser(
        new UpdateRequest()
          .setContext(authContext.toFuture())
          .setUser(user)
          .setUpdateMask(fieldMask.setPathsList(updateFields)),
      );
      enqueueSnackbar("Person details updated", {
        variant: "success",
      });
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (e: any) {
      const err = errorContextErrorTranslator.translateError(e);
      enqueueSnackbar(
        `Error submitting funding order: ${
          e.message.split(": ")[1]
            ? e.message.split(": ")[1]
            : err.message
              ? err.message
              : err.toString()
        }`,
        { variant: "error" },
      );
      return myUser;
    }

    setUpdatingUser(false);
    if (updatedUser) {
      setMyUser(updatedUser);
      return updatedUser;
    }

    return myUser;
  };

  useUserLoggedInGTMObserver(loggedIn, userToPast(myUser), myClient);

  return (
    <Context.Provider
      value={{
        apiCallInProgress: fetchingUser || updatingUser,

        myUser,
        setMyUser,

        userLoaded,
        userLoadError,
        clearUserLoadError,
        refreshMyUser,

        retrieveUser,
        updateUser,
      }}
    >
      {children}
    </Context.Provider>
  );
};

const useUserContext = () => useContext(Context);
export { useUserContext };

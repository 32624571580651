// source: market/offPlatformTransferRecipientStateController.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global =
    (typeof globalThis !== 'undefined' && globalThis) ||
    (typeof window !== 'undefined' && window) ||
    (typeof global !== 'undefined' && global) ||
    (typeof self !== 'undefined' && self) ||
    (function () { return this; }).call(null) ||
    Function('return this')();

var security_context_pb = require('../security/context_pb.js');
goog.object.extend(proto, security_context_pb);
var ledger_futureNetwork_pb = require('../ledger/futureNetwork_pb.js');
goog.object.extend(proto, ledger_futureNetwork_pb);
var market_offPlatformTransferRecipient_pb = require('../market/offPlatformTransferRecipient_pb.js');
goog.object.extend(proto, market_offPlatformTransferRecipient_pb);
goog.exportSymbol('proto.market.AddOffPlatformTransferRecipientRequest', null, global);
goog.exportSymbol('proto.market.AddOffPlatformTransferRecipientResponse', null, global);
goog.exportSymbol('proto.market.MarkOffPlatformTransferRecipientActiveRequest', null, global);
goog.exportSymbol('proto.market.MarkOffPlatformTransferRecipientActiveResponse', null, global);
goog.exportSymbol('proto.market.MarkOffPlatformTransferRecipientInactiveRequest', null, global);
goog.exportSymbol('proto.market.MarkOffPlatformTransferRecipientInactiveResponse', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.market.AddOffPlatformTransferRecipientRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.market.AddOffPlatformTransferRecipientRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.market.AddOffPlatformTransferRecipientRequest.displayName = 'proto.market.AddOffPlatformTransferRecipientRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.market.AddOffPlatformTransferRecipientResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.market.AddOffPlatformTransferRecipientResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.market.AddOffPlatformTransferRecipientResponse.displayName = 'proto.market.AddOffPlatformTransferRecipientResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.market.MarkOffPlatformTransferRecipientActiveRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.market.MarkOffPlatformTransferRecipientActiveRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.market.MarkOffPlatformTransferRecipientActiveRequest.displayName = 'proto.market.MarkOffPlatformTransferRecipientActiveRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.market.MarkOffPlatformTransferRecipientActiveResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.market.MarkOffPlatformTransferRecipientActiveResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.market.MarkOffPlatformTransferRecipientActiveResponse.displayName = 'proto.market.MarkOffPlatformTransferRecipientActiveResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.market.MarkOffPlatformTransferRecipientInactiveRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.market.MarkOffPlatformTransferRecipientInactiveRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.market.MarkOffPlatformTransferRecipientInactiveRequest.displayName = 'proto.market.MarkOffPlatformTransferRecipientInactiveRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.market.MarkOffPlatformTransferRecipientInactiveResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.market.MarkOffPlatformTransferRecipientInactiveResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.market.MarkOffPlatformTransferRecipientInactiveResponse.displayName = 'proto.market.MarkOffPlatformTransferRecipientInactiveResponse';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.market.AddOffPlatformTransferRecipientRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.market.AddOffPlatformTransferRecipientRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.market.AddOffPlatformTransferRecipientRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.market.AddOffPlatformTransferRecipientRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
context: (f = msg.getContext()) && security_context_pb.ContextFuture.toObject(includeInstance, f),
address: jspb.Message.getFieldWithDefault(msg, 2, ""),
network: jspb.Message.getFieldWithDefault(msg, 3, 0),
externalreference: jspb.Message.getFieldWithDefault(msg, 5, ""),
label: jspb.Message.getFieldWithDefault(msg, 6, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.market.AddOffPlatformTransferRecipientRequest}
 */
proto.market.AddOffPlatformTransferRecipientRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.market.AddOffPlatformTransferRecipientRequest;
  return proto.market.AddOffPlatformTransferRecipientRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.market.AddOffPlatformTransferRecipientRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.market.AddOffPlatformTransferRecipientRequest}
 */
proto.market.AddOffPlatformTransferRecipientRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new security_context_pb.ContextFuture;
      reader.readMessage(value,security_context_pb.ContextFuture.deserializeBinaryFromReader);
      msg.setContext(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setAddress(value);
      break;
    case 3:
      var value = /** @type {!proto.ledger.FutureNetwork} */ (reader.readEnum());
      msg.setNetwork(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setExternalreference(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setLabel(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.market.AddOffPlatformTransferRecipientRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.market.AddOffPlatformTransferRecipientRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.market.AddOffPlatformTransferRecipientRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.market.AddOffPlatformTransferRecipientRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getContext();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      security_context_pb.ContextFuture.serializeBinaryToWriter
    );
  }
  f = message.getAddress();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getNetwork();
  if (f !== 0.0) {
    writer.writeEnum(
      3,
      f
    );
  }
  f = message.getExternalreference();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getLabel();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
};


/**
 * optional security.ContextFuture context = 1;
 * @return {?proto.security.ContextFuture}
 */
proto.market.AddOffPlatformTransferRecipientRequest.prototype.getContext = function() {
  return /** @type{?proto.security.ContextFuture} */ (
    jspb.Message.getWrapperField(this, security_context_pb.ContextFuture, 1));
};


/**
 * @param {?proto.security.ContextFuture|undefined} value
 * @return {!proto.market.AddOffPlatformTransferRecipientRequest} returns this
*/
proto.market.AddOffPlatformTransferRecipientRequest.prototype.setContext = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.market.AddOffPlatformTransferRecipientRequest} returns this
 */
proto.market.AddOffPlatformTransferRecipientRequest.prototype.clearContext = function() {
  return this.setContext(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.market.AddOffPlatformTransferRecipientRequest.prototype.hasContext = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional string address = 2;
 * @return {string}
 */
proto.market.AddOffPlatformTransferRecipientRequest.prototype.getAddress = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.market.AddOffPlatformTransferRecipientRequest} returns this
 */
proto.market.AddOffPlatformTransferRecipientRequest.prototype.setAddress = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional ledger.FutureNetwork network = 3;
 * @return {!proto.ledger.FutureNetwork}
 */
proto.market.AddOffPlatformTransferRecipientRequest.prototype.getNetwork = function() {
  return /** @type {!proto.ledger.FutureNetwork} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {!proto.ledger.FutureNetwork} value
 * @return {!proto.market.AddOffPlatformTransferRecipientRequest} returns this
 */
proto.market.AddOffPlatformTransferRecipientRequest.prototype.setNetwork = function(value) {
  return jspb.Message.setProto3EnumField(this, 3, value);
};


/**
 * optional string externalReference = 5;
 * @return {string}
 */
proto.market.AddOffPlatformTransferRecipientRequest.prototype.getExternalreference = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.market.AddOffPlatformTransferRecipientRequest} returns this
 */
proto.market.AddOffPlatformTransferRecipientRequest.prototype.setExternalreference = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string label = 6;
 * @return {string}
 */
proto.market.AddOffPlatformTransferRecipientRequest.prototype.getLabel = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.market.AddOffPlatformTransferRecipientRequest} returns this
 */
proto.market.AddOffPlatformTransferRecipientRequest.prototype.setLabel = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.market.AddOffPlatformTransferRecipientResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.market.AddOffPlatformTransferRecipientResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.market.AddOffPlatformTransferRecipientResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.market.AddOffPlatformTransferRecipientResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
offplatformtransferrecipient: (f = msg.getOffplatformtransferrecipient()) && market_offPlatformTransferRecipient_pb.OffPlatformTransferRecipient.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.market.AddOffPlatformTransferRecipientResponse}
 */
proto.market.AddOffPlatformTransferRecipientResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.market.AddOffPlatformTransferRecipientResponse;
  return proto.market.AddOffPlatformTransferRecipientResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.market.AddOffPlatformTransferRecipientResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.market.AddOffPlatformTransferRecipientResponse}
 */
proto.market.AddOffPlatformTransferRecipientResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new market_offPlatformTransferRecipient_pb.OffPlatformTransferRecipient;
      reader.readMessage(value,market_offPlatformTransferRecipient_pb.OffPlatformTransferRecipient.deserializeBinaryFromReader);
      msg.setOffplatformtransferrecipient(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.market.AddOffPlatformTransferRecipientResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.market.AddOffPlatformTransferRecipientResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.market.AddOffPlatformTransferRecipientResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.market.AddOffPlatformTransferRecipientResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getOffplatformtransferrecipient();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      market_offPlatformTransferRecipient_pb.OffPlatformTransferRecipient.serializeBinaryToWriter
    );
  }
};


/**
 * optional OffPlatformTransferRecipient offPlatformTransferRecipient = 1;
 * @return {?proto.market.OffPlatformTransferRecipient}
 */
proto.market.AddOffPlatformTransferRecipientResponse.prototype.getOffplatformtransferrecipient = function() {
  return /** @type{?proto.market.OffPlatformTransferRecipient} */ (
    jspb.Message.getWrapperField(this, market_offPlatformTransferRecipient_pb.OffPlatformTransferRecipient, 1));
};


/**
 * @param {?proto.market.OffPlatformTransferRecipient|undefined} value
 * @return {!proto.market.AddOffPlatformTransferRecipientResponse} returns this
*/
proto.market.AddOffPlatformTransferRecipientResponse.prototype.setOffplatformtransferrecipient = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.market.AddOffPlatformTransferRecipientResponse} returns this
 */
proto.market.AddOffPlatformTransferRecipientResponse.prototype.clearOffplatformtransferrecipient = function() {
  return this.setOffplatformtransferrecipient(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.market.AddOffPlatformTransferRecipientResponse.prototype.hasOffplatformtransferrecipient = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.market.MarkOffPlatformTransferRecipientActiveRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.market.MarkOffPlatformTransferRecipientActiveRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.market.MarkOffPlatformTransferRecipientActiveRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.market.MarkOffPlatformTransferRecipientActiveRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
context: (f = msg.getContext()) && security_context_pb.ContextFuture.toObject(includeInstance, f),
offplatformtransferrecipientid: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.market.MarkOffPlatformTransferRecipientActiveRequest}
 */
proto.market.MarkOffPlatformTransferRecipientActiveRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.market.MarkOffPlatformTransferRecipientActiveRequest;
  return proto.market.MarkOffPlatformTransferRecipientActiveRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.market.MarkOffPlatformTransferRecipientActiveRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.market.MarkOffPlatformTransferRecipientActiveRequest}
 */
proto.market.MarkOffPlatformTransferRecipientActiveRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new security_context_pb.ContextFuture;
      reader.readMessage(value,security_context_pb.ContextFuture.deserializeBinaryFromReader);
      msg.setContext(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setOffplatformtransferrecipientid(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.market.MarkOffPlatformTransferRecipientActiveRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.market.MarkOffPlatformTransferRecipientActiveRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.market.MarkOffPlatformTransferRecipientActiveRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.market.MarkOffPlatformTransferRecipientActiveRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getContext();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      security_context_pb.ContextFuture.serializeBinaryToWriter
    );
  }
  f = message.getOffplatformtransferrecipientid();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional security.ContextFuture context = 1;
 * @return {?proto.security.ContextFuture}
 */
proto.market.MarkOffPlatformTransferRecipientActiveRequest.prototype.getContext = function() {
  return /** @type{?proto.security.ContextFuture} */ (
    jspb.Message.getWrapperField(this, security_context_pb.ContextFuture, 1));
};


/**
 * @param {?proto.security.ContextFuture|undefined} value
 * @return {!proto.market.MarkOffPlatformTransferRecipientActiveRequest} returns this
*/
proto.market.MarkOffPlatformTransferRecipientActiveRequest.prototype.setContext = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.market.MarkOffPlatformTransferRecipientActiveRequest} returns this
 */
proto.market.MarkOffPlatformTransferRecipientActiveRequest.prototype.clearContext = function() {
  return this.setContext(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.market.MarkOffPlatformTransferRecipientActiveRequest.prototype.hasContext = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional string offPlatformTransferRecipientID = 2;
 * @return {string}
 */
proto.market.MarkOffPlatformTransferRecipientActiveRequest.prototype.getOffplatformtransferrecipientid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.market.MarkOffPlatformTransferRecipientActiveRequest} returns this
 */
proto.market.MarkOffPlatformTransferRecipientActiveRequest.prototype.setOffplatformtransferrecipientid = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.market.MarkOffPlatformTransferRecipientActiveResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.market.MarkOffPlatformTransferRecipientActiveResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.market.MarkOffPlatformTransferRecipientActiveResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.market.MarkOffPlatformTransferRecipientActiveResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
offplatformtransferrecipient: (f = msg.getOffplatformtransferrecipient()) && market_offPlatformTransferRecipient_pb.OffPlatformTransferRecipient.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.market.MarkOffPlatformTransferRecipientActiveResponse}
 */
proto.market.MarkOffPlatformTransferRecipientActiveResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.market.MarkOffPlatformTransferRecipientActiveResponse;
  return proto.market.MarkOffPlatformTransferRecipientActiveResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.market.MarkOffPlatformTransferRecipientActiveResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.market.MarkOffPlatformTransferRecipientActiveResponse}
 */
proto.market.MarkOffPlatformTransferRecipientActiveResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new market_offPlatformTransferRecipient_pb.OffPlatformTransferRecipient;
      reader.readMessage(value,market_offPlatformTransferRecipient_pb.OffPlatformTransferRecipient.deserializeBinaryFromReader);
      msg.setOffplatformtransferrecipient(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.market.MarkOffPlatformTransferRecipientActiveResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.market.MarkOffPlatformTransferRecipientActiveResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.market.MarkOffPlatformTransferRecipientActiveResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.market.MarkOffPlatformTransferRecipientActiveResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getOffplatformtransferrecipient();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      market_offPlatformTransferRecipient_pb.OffPlatformTransferRecipient.serializeBinaryToWriter
    );
  }
};


/**
 * optional OffPlatformTransferRecipient offPlatformTransferRecipient = 1;
 * @return {?proto.market.OffPlatformTransferRecipient}
 */
proto.market.MarkOffPlatformTransferRecipientActiveResponse.prototype.getOffplatformtransferrecipient = function() {
  return /** @type{?proto.market.OffPlatformTransferRecipient} */ (
    jspb.Message.getWrapperField(this, market_offPlatformTransferRecipient_pb.OffPlatformTransferRecipient, 1));
};


/**
 * @param {?proto.market.OffPlatformTransferRecipient|undefined} value
 * @return {!proto.market.MarkOffPlatformTransferRecipientActiveResponse} returns this
*/
proto.market.MarkOffPlatformTransferRecipientActiveResponse.prototype.setOffplatformtransferrecipient = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.market.MarkOffPlatformTransferRecipientActiveResponse} returns this
 */
proto.market.MarkOffPlatformTransferRecipientActiveResponse.prototype.clearOffplatformtransferrecipient = function() {
  return this.setOffplatformtransferrecipient(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.market.MarkOffPlatformTransferRecipientActiveResponse.prototype.hasOffplatformtransferrecipient = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.market.MarkOffPlatformTransferRecipientInactiveRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.market.MarkOffPlatformTransferRecipientInactiveRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.market.MarkOffPlatformTransferRecipientInactiveRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.market.MarkOffPlatformTransferRecipientInactiveRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
context: (f = msg.getContext()) && security_context_pb.ContextFuture.toObject(includeInstance, f),
offplatformtransferrecipientid: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.market.MarkOffPlatformTransferRecipientInactiveRequest}
 */
proto.market.MarkOffPlatformTransferRecipientInactiveRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.market.MarkOffPlatformTransferRecipientInactiveRequest;
  return proto.market.MarkOffPlatformTransferRecipientInactiveRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.market.MarkOffPlatformTransferRecipientInactiveRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.market.MarkOffPlatformTransferRecipientInactiveRequest}
 */
proto.market.MarkOffPlatformTransferRecipientInactiveRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new security_context_pb.ContextFuture;
      reader.readMessage(value,security_context_pb.ContextFuture.deserializeBinaryFromReader);
      msg.setContext(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setOffplatformtransferrecipientid(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.market.MarkOffPlatformTransferRecipientInactiveRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.market.MarkOffPlatformTransferRecipientInactiveRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.market.MarkOffPlatformTransferRecipientInactiveRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.market.MarkOffPlatformTransferRecipientInactiveRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getContext();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      security_context_pb.ContextFuture.serializeBinaryToWriter
    );
  }
  f = message.getOffplatformtransferrecipientid();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional security.ContextFuture context = 1;
 * @return {?proto.security.ContextFuture}
 */
proto.market.MarkOffPlatformTransferRecipientInactiveRequest.prototype.getContext = function() {
  return /** @type{?proto.security.ContextFuture} */ (
    jspb.Message.getWrapperField(this, security_context_pb.ContextFuture, 1));
};


/**
 * @param {?proto.security.ContextFuture|undefined} value
 * @return {!proto.market.MarkOffPlatformTransferRecipientInactiveRequest} returns this
*/
proto.market.MarkOffPlatformTransferRecipientInactiveRequest.prototype.setContext = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.market.MarkOffPlatformTransferRecipientInactiveRequest} returns this
 */
proto.market.MarkOffPlatformTransferRecipientInactiveRequest.prototype.clearContext = function() {
  return this.setContext(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.market.MarkOffPlatformTransferRecipientInactiveRequest.prototype.hasContext = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional string offPlatformTransferRecipientID = 2;
 * @return {string}
 */
proto.market.MarkOffPlatformTransferRecipientInactiveRequest.prototype.getOffplatformtransferrecipientid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.market.MarkOffPlatformTransferRecipientInactiveRequest} returns this
 */
proto.market.MarkOffPlatformTransferRecipientInactiveRequest.prototype.setOffplatformtransferrecipientid = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.market.MarkOffPlatformTransferRecipientInactiveResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.market.MarkOffPlatformTransferRecipientInactiveResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.market.MarkOffPlatformTransferRecipientInactiveResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.market.MarkOffPlatformTransferRecipientInactiveResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
offplatformtransferrecipient: (f = msg.getOffplatformtransferrecipient()) && market_offPlatformTransferRecipient_pb.OffPlatformTransferRecipient.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.market.MarkOffPlatformTransferRecipientInactiveResponse}
 */
proto.market.MarkOffPlatformTransferRecipientInactiveResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.market.MarkOffPlatformTransferRecipientInactiveResponse;
  return proto.market.MarkOffPlatformTransferRecipientInactiveResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.market.MarkOffPlatformTransferRecipientInactiveResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.market.MarkOffPlatformTransferRecipientInactiveResponse}
 */
proto.market.MarkOffPlatformTransferRecipientInactiveResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new market_offPlatformTransferRecipient_pb.OffPlatformTransferRecipient;
      reader.readMessage(value,market_offPlatformTransferRecipient_pb.OffPlatformTransferRecipient.deserializeBinaryFromReader);
      msg.setOffplatformtransferrecipient(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.market.MarkOffPlatformTransferRecipientInactiveResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.market.MarkOffPlatformTransferRecipientInactiveResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.market.MarkOffPlatformTransferRecipientInactiveResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.market.MarkOffPlatformTransferRecipientInactiveResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getOffplatformtransferrecipient();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      market_offPlatformTransferRecipient_pb.OffPlatformTransferRecipient.serializeBinaryToWriter
    );
  }
};


/**
 * optional OffPlatformTransferRecipient offPlatformTransferRecipient = 1;
 * @return {?proto.market.OffPlatformTransferRecipient}
 */
proto.market.MarkOffPlatformTransferRecipientInactiveResponse.prototype.getOffplatformtransferrecipient = function() {
  return /** @type{?proto.market.OffPlatformTransferRecipient} */ (
    jspb.Message.getWrapperField(this, market_offPlatformTransferRecipient_pb.OffPlatformTransferRecipient, 1));
};


/**
 * @param {?proto.market.OffPlatformTransferRecipient|undefined} value
 * @return {!proto.market.MarkOffPlatformTransferRecipientInactiveResponse} returns this
*/
proto.market.MarkOffPlatformTransferRecipientInactiveResponse.prototype.setOffplatformtransferrecipient = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.market.MarkOffPlatformTransferRecipientInactiveResponse} returns this
 */
proto.market.MarkOffPlatformTransferRecipientInactiveResponse.prototype.clearOffplatformtransferrecipient = function() {
  return this.setOffplatformtransferrecipient(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.market.MarkOffPlatformTransferRecipientInactiveResponse.prototype.hasOffplatformtransferrecipient = function() {
  return jspb.Message.getField(this, 1) != null;
};


goog.object.extend(exports, proto.market);

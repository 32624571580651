import { Client } from "./Client";
import { NotFoundError, Horizon } from "stellar-sdk";
import { BigNumber } from "bignumber.js";
import * as Sentry from "@sentry/react";

import {
  Model as Account,
  Balance,
} from "@mesh/common-js/dist/views/stellarAccountView/model_pb";
import {
  AuthFlags,
  Signatory,
  AccountState,
} from "@mesh/common-js/dist/stellar/enrichedAccount_pb";

import { StellarNetwork } from "./Network";
import { primitiveAssetToToken, tokenFromAssetString } from "./token";
import { NewLiquidityPoolToken } from "./liquidityPoolToken";
import dayjs from "dayjs";
import { newAmountFromBigNumber } from "@mesh/common-js/dist/ledger";
import { dayjsToProtobufTimestamp } from "@mesh/common-js/dist/googleProtobufConverters";
import { pasrseSignatoryType } from ".";

export type PopulateAccountWithLedgerDetailsRequest = {
  account: Account;
};

export type PopulateAccountWithLedgerDetailsResponse = {
  account: Account;
};

export type NewStellarAccountLedgerDetailsPopulatorProps = {
  client: Client;
};

export type NewStellarAccountLedgerDetailsPopulator = {
  client: Client;
};

export class StellarAccountLedgerDetailsPopulator {
  private readonly stellarClient: Client;

  constructor(props: NewStellarAccountLedgerDetailsPopulator) {
    this.stellarClient = props.client;
  }

  async PopulateAccountWithLedgerDetails(
    request: PopulateAccountWithLedgerDetailsRequest,
  ): Promise<PopulateAccountWithLedgerDetailsResponse> {
    //
    // retrieve the account from stellar
    //

    const account = request.account;

    // clear current balance that may have been set
    account.setBalancesList([]);

    let stellarAccount: undefined | Horizon.ServerApi.AccountRecord = undefined;
    try {
      stellarAccount = await this.stellarClient.accountRecord(
        account.getLedgerid(),
      );
    } catch (e) {
      if (
        e instanceof NotFoundError ||
        `${e}`.includes("Error: Request failed with status code 404")
      ) {
        // we assume the account is closed
        account.setState(AccountState.CLOSED_ACCOUNT_STATE);
        return { account };
      }
      const err = e as Error;

      // throw error
      Sentry.captureException(
        `could not retrieve account from stellar: ${
          err.message ? err.message : err.toString()
        }`,
      );
      throw new Error(
        `could not retrieve account from stellar: ${
          err.message ? err.message : err.toString()
        }`,
      );
    }

    // set the account state to open
    account.setState(AccountState.OPEN_ACCOUNT_STATE);

    // populate the account balance
    for (const b of stellarAccount.balances) {
      switch (b.asset_type) {
        case "native":
          {
            const token = primitiveAssetToToken(
              {
                asset_code: "XLM",
                asset_issuer: this.stellarClient.network,
                asset_type: b.asset_type,
              },
              this.stellarClient.network as StellarNetwork,
            ).toFutureToken();

            account.setBalancesList([
              ...account.getBalancesList(),
              new Balance()
                .setAmount(
                  newAmountFromBigNumber(new BigNumber(b.balance), token),
                )
                .setLimit(newAmountFromBigNumber(new BigNumber("0"), token))
                .setBuyingliabilities(
                  newAmountFromBigNumber(
                    new BigNumber(b.buying_liabilities),
                    token,
                  ),
                )
                .setSellingliabilities(
                  newAmountFromBigNumber(
                    new BigNumber(b.selling_liabilities),
                    token,
                  ),
                ),
            ]);
          }
          break;

        case "liquidity_pool_shares":
          try {
            const liquidityPoolRecordResponse =
              await this.stellarClient.liquidityPoolRecord(b.liquidity_pool_id);

            const tokenA = tokenFromAssetString(
              liquidityPoolRecordResponse.reserves[0].asset,
              this.stellarClient.network as StellarNetwork,
            );

            const tokenB = tokenFromAssetString(
              liquidityPoolRecordResponse.reserves[1].asset,
              this.stellarClient.network as StellarNetwork,
            );

            const token = NewLiquidityPoolToken(tokenA, tokenB).toFutureToken();

            account.setBalancesList([
              ...account.getBalancesList(),
              new Balance()
                .setAmount(
                  newAmountFromBigNumber(new BigNumber(b.balance), token),
                )
                .setLimit(
                  newAmountFromBigNumber(new BigNumber(b.balance), token),
                )
                .setBuyingliabilities(
                  newAmountFromBigNumber(new BigNumber("0"), token),
                )
                .setSellingliabilities(
                  newAmountFromBigNumber(new BigNumber("0"), token),
                ),
            ]);
          } catch (e) {
            const err = e as Error;
            Sentry.captureException(
              `could not determine liquidity pool shares balance: ${
                err.message ? err.message : err.toString()
              }`,
            );
            throw new Error(
              `could not determine liquidity pool shares balance: ${
                err.message ? err.message : err.toString()
              }`,
            );
          }
          break;

        default: {
          const token = primitiveAssetToToken(
            {
              asset_code: b.asset_code,
              asset_issuer: b.asset_issuer,
              asset_type: b.asset_type,
            },
            this.stellarClient.network as StellarNetwork,
          ).toFutureToken();

          account.setBalancesList([
            ...account.getBalancesList(),
            new Balance()
              .setAmount(
                newAmountFromBigNumber(new BigNumber(b.balance), token),
              )
              .setLimit(newAmountFromBigNumber(new BigNumber(b.limit), token))
              .setBuyingliabilities(
                newAmountFromBigNumber(
                  new BigNumber(b.buying_liabilities),
                  token,
                ),
              )
              .setSellingliabilities(
                newAmountFromBigNumber(
                  new BigNumber(b.selling_liabilities),
                  token,
                ),
              ),
          ]);
        }
      }
    }

    // populate the account signatory details
    for (const s of stellarAccount.signers) {
      account.setSignatoriesList([
        ...account.getSignatoriesList(),
        new Signatory()
          .setType(pasrseSignatoryType(s.type))
          .setKey(s.key)
          .setWeight(s.weight),
      ]);
    }

    // populate the auth flags
    account.setAuthflags(
      new AuthFlags()
        .setAuthimmutable(stellarAccount.flags.auth_immutable)
        .setAuthrevocable(stellarAccount.flags.auth_revocable)
        .setAuthrequired(stellarAccount.flags.auth_required),
    );

    // set created at time based on first operation
    account.setCreatedtime(
      dayjsToProtobufTimestamp(
        dayjs((await stellarAccount.operations()).records[0].created_at),
      ),
    );

    return { account };
  }
}

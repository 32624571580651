// source: views/marketingInstrumentMarketingContentView/modelUpdater.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global =
    (typeof globalThis !== 'undefined' && globalThis) ||
    (typeof window !== 'undefined' && window) ||
    (typeof global !== 'undefined' && global) ||
    (typeof self !== 'undefined' && self) ||
    (function () { return this; }).call(null) ||
    Function('return this')();

var security_context_pb = require('../../security/context_pb.js');
goog.object.extend(proto, security_context_pb);
var marketing_instrumentMarketingContent_pb = require('../../marketing/instrumentMarketingContent_pb.js');
goog.object.extend(proto, marketing_instrumentMarketingContent_pb);
goog.exportSymbol('proto.marketingInstrumentMarketingContentView.FullUpdateRequest', null, global);
goog.exportSymbol('proto.marketingInstrumentMarketingContentView.FullUpdateResponse', null, global);
goog.exportSymbol('proto.marketingInstrumentMarketingContentView.UpdateForInstrumentMarketingContentIDRequest', null, global);
goog.exportSymbol('proto.marketingInstrumentMarketingContentView.UpdateForInstrumentMarketingContentIDResponse', null, global);
goog.exportSymbol('proto.marketingInstrumentMarketingContentView.UpdateForInstrumentMarketingContentRequest', null, global);
goog.exportSymbol('proto.marketingInstrumentMarketingContentView.UpdateForInstrumentMarketingContentResponse', null, global);
goog.exportSymbol('proto.marketingInstrumentMarketingContentView.UpdateForSmartInstrumentIDRequest', null, global);
goog.exportSymbol('proto.marketingInstrumentMarketingContentView.UpdateForSmartInstrumentIDResponse', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.marketingInstrumentMarketingContentView.UpdateForInstrumentMarketingContentIDRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.marketingInstrumentMarketingContentView.UpdateForInstrumentMarketingContentIDRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.marketingInstrumentMarketingContentView.UpdateForInstrumentMarketingContentIDRequest.displayName = 'proto.marketingInstrumentMarketingContentView.UpdateForInstrumentMarketingContentIDRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.marketingInstrumentMarketingContentView.UpdateForInstrumentMarketingContentIDResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.marketingInstrumentMarketingContentView.UpdateForInstrumentMarketingContentIDResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.marketingInstrumentMarketingContentView.UpdateForInstrumentMarketingContentIDResponse.displayName = 'proto.marketingInstrumentMarketingContentView.UpdateForInstrumentMarketingContentIDResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.marketingInstrumentMarketingContentView.UpdateForInstrumentMarketingContentRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.marketingInstrumentMarketingContentView.UpdateForInstrumentMarketingContentRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.marketingInstrumentMarketingContentView.UpdateForInstrumentMarketingContentRequest.displayName = 'proto.marketingInstrumentMarketingContentView.UpdateForInstrumentMarketingContentRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.marketingInstrumentMarketingContentView.UpdateForInstrumentMarketingContentResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.marketingInstrumentMarketingContentView.UpdateForInstrumentMarketingContentResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.marketingInstrumentMarketingContentView.UpdateForInstrumentMarketingContentResponse.displayName = 'proto.marketingInstrumentMarketingContentView.UpdateForInstrumentMarketingContentResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.marketingInstrumentMarketingContentView.UpdateForSmartInstrumentIDRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.marketingInstrumentMarketingContentView.UpdateForSmartInstrumentIDRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.marketingInstrumentMarketingContentView.UpdateForSmartInstrumentIDRequest.displayName = 'proto.marketingInstrumentMarketingContentView.UpdateForSmartInstrumentIDRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.marketingInstrumentMarketingContentView.UpdateForSmartInstrumentIDResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.marketingInstrumentMarketingContentView.UpdateForSmartInstrumentIDResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.marketingInstrumentMarketingContentView.UpdateForSmartInstrumentIDResponse.displayName = 'proto.marketingInstrumentMarketingContentView.UpdateForSmartInstrumentIDResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.marketingInstrumentMarketingContentView.FullUpdateRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.marketingInstrumentMarketingContentView.FullUpdateRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.marketingInstrumentMarketingContentView.FullUpdateRequest.displayName = 'proto.marketingInstrumentMarketingContentView.FullUpdateRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.marketingInstrumentMarketingContentView.FullUpdateResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.marketingInstrumentMarketingContentView.FullUpdateResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.marketingInstrumentMarketingContentView.FullUpdateResponse.displayName = 'proto.marketingInstrumentMarketingContentView.FullUpdateResponse';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.marketingInstrumentMarketingContentView.UpdateForInstrumentMarketingContentIDRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.marketingInstrumentMarketingContentView.UpdateForInstrumentMarketingContentIDRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.marketingInstrumentMarketingContentView.UpdateForInstrumentMarketingContentIDRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.marketingInstrumentMarketingContentView.UpdateForInstrumentMarketingContentIDRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
context: (f = msg.getContext()) && security_context_pb.ContextFuture.toObject(includeInstance, f),
instrumentmarketingcontentid: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.marketingInstrumentMarketingContentView.UpdateForInstrumentMarketingContentIDRequest}
 */
proto.marketingInstrumentMarketingContentView.UpdateForInstrumentMarketingContentIDRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.marketingInstrumentMarketingContentView.UpdateForInstrumentMarketingContentIDRequest;
  return proto.marketingInstrumentMarketingContentView.UpdateForInstrumentMarketingContentIDRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.marketingInstrumentMarketingContentView.UpdateForInstrumentMarketingContentIDRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.marketingInstrumentMarketingContentView.UpdateForInstrumentMarketingContentIDRequest}
 */
proto.marketingInstrumentMarketingContentView.UpdateForInstrumentMarketingContentIDRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new security_context_pb.ContextFuture;
      reader.readMessage(value,security_context_pb.ContextFuture.deserializeBinaryFromReader);
      msg.setContext(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setInstrumentmarketingcontentid(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.marketingInstrumentMarketingContentView.UpdateForInstrumentMarketingContentIDRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.marketingInstrumentMarketingContentView.UpdateForInstrumentMarketingContentIDRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.marketingInstrumentMarketingContentView.UpdateForInstrumentMarketingContentIDRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.marketingInstrumentMarketingContentView.UpdateForInstrumentMarketingContentIDRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getContext();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      security_context_pb.ContextFuture.serializeBinaryToWriter
    );
  }
  f = message.getInstrumentmarketingcontentid();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional security.ContextFuture context = 1;
 * @return {?proto.security.ContextFuture}
 */
proto.marketingInstrumentMarketingContentView.UpdateForInstrumentMarketingContentIDRequest.prototype.getContext = function() {
  return /** @type{?proto.security.ContextFuture} */ (
    jspb.Message.getWrapperField(this, security_context_pb.ContextFuture, 1));
};


/**
 * @param {?proto.security.ContextFuture|undefined} value
 * @return {!proto.marketingInstrumentMarketingContentView.UpdateForInstrumentMarketingContentIDRequest} returns this
*/
proto.marketingInstrumentMarketingContentView.UpdateForInstrumentMarketingContentIDRequest.prototype.setContext = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.marketingInstrumentMarketingContentView.UpdateForInstrumentMarketingContentIDRequest} returns this
 */
proto.marketingInstrumentMarketingContentView.UpdateForInstrumentMarketingContentIDRequest.prototype.clearContext = function() {
  return this.setContext(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.marketingInstrumentMarketingContentView.UpdateForInstrumentMarketingContentIDRequest.prototype.hasContext = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional string instrumentMarketingContentID = 2;
 * @return {string}
 */
proto.marketingInstrumentMarketingContentView.UpdateForInstrumentMarketingContentIDRequest.prototype.getInstrumentmarketingcontentid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.marketingInstrumentMarketingContentView.UpdateForInstrumentMarketingContentIDRequest} returns this
 */
proto.marketingInstrumentMarketingContentView.UpdateForInstrumentMarketingContentIDRequest.prototype.setInstrumentmarketingcontentid = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.marketingInstrumentMarketingContentView.UpdateForInstrumentMarketingContentIDResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.marketingInstrumentMarketingContentView.UpdateForInstrumentMarketingContentIDResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.marketingInstrumentMarketingContentView.UpdateForInstrumentMarketingContentIDResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.marketingInstrumentMarketingContentView.UpdateForInstrumentMarketingContentIDResponse.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.marketingInstrumentMarketingContentView.UpdateForInstrumentMarketingContentIDResponse}
 */
proto.marketingInstrumentMarketingContentView.UpdateForInstrumentMarketingContentIDResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.marketingInstrumentMarketingContentView.UpdateForInstrumentMarketingContentIDResponse;
  return proto.marketingInstrumentMarketingContentView.UpdateForInstrumentMarketingContentIDResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.marketingInstrumentMarketingContentView.UpdateForInstrumentMarketingContentIDResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.marketingInstrumentMarketingContentView.UpdateForInstrumentMarketingContentIDResponse}
 */
proto.marketingInstrumentMarketingContentView.UpdateForInstrumentMarketingContentIDResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.marketingInstrumentMarketingContentView.UpdateForInstrumentMarketingContentIDResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.marketingInstrumentMarketingContentView.UpdateForInstrumentMarketingContentIDResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.marketingInstrumentMarketingContentView.UpdateForInstrumentMarketingContentIDResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.marketingInstrumentMarketingContentView.UpdateForInstrumentMarketingContentIDResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.marketingInstrumentMarketingContentView.UpdateForInstrumentMarketingContentRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.marketingInstrumentMarketingContentView.UpdateForInstrumentMarketingContentRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.marketingInstrumentMarketingContentView.UpdateForInstrumentMarketingContentRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.marketingInstrumentMarketingContentView.UpdateForInstrumentMarketingContentRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
context: (f = msg.getContext()) && security_context_pb.ContextFuture.toObject(includeInstance, f),
instrumentmarketingcontent: (f = msg.getInstrumentmarketingcontent()) && marketing_instrumentMarketingContent_pb.InstrumentMarketingContent.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.marketingInstrumentMarketingContentView.UpdateForInstrumentMarketingContentRequest}
 */
proto.marketingInstrumentMarketingContentView.UpdateForInstrumentMarketingContentRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.marketingInstrumentMarketingContentView.UpdateForInstrumentMarketingContentRequest;
  return proto.marketingInstrumentMarketingContentView.UpdateForInstrumentMarketingContentRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.marketingInstrumentMarketingContentView.UpdateForInstrumentMarketingContentRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.marketingInstrumentMarketingContentView.UpdateForInstrumentMarketingContentRequest}
 */
proto.marketingInstrumentMarketingContentView.UpdateForInstrumentMarketingContentRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new security_context_pb.ContextFuture;
      reader.readMessage(value,security_context_pb.ContextFuture.deserializeBinaryFromReader);
      msg.setContext(value);
      break;
    case 2:
      var value = new marketing_instrumentMarketingContent_pb.InstrumentMarketingContent;
      reader.readMessage(value,marketing_instrumentMarketingContent_pb.InstrumentMarketingContent.deserializeBinaryFromReader);
      msg.setInstrumentmarketingcontent(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.marketingInstrumentMarketingContentView.UpdateForInstrumentMarketingContentRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.marketingInstrumentMarketingContentView.UpdateForInstrumentMarketingContentRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.marketingInstrumentMarketingContentView.UpdateForInstrumentMarketingContentRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.marketingInstrumentMarketingContentView.UpdateForInstrumentMarketingContentRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getContext();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      security_context_pb.ContextFuture.serializeBinaryToWriter
    );
  }
  f = message.getInstrumentmarketingcontent();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      marketing_instrumentMarketingContent_pb.InstrumentMarketingContent.serializeBinaryToWriter
    );
  }
};


/**
 * optional security.ContextFuture context = 1;
 * @return {?proto.security.ContextFuture}
 */
proto.marketingInstrumentMarketingContentView.UpdateForInstrumentMarketingContentRequest.prototype.getContext = function() {
  return /** @type{?proto.security.ContextFuture} */ (
    jspb.Message.getWrapperField(this, security_context_pb.ContextFuture, 1));
};


/**
 * @param {?proto.security.ContextFuture|undefined} value
 * @return {!proto.marketingInstrumentMarketingContentView.UpdateForInstrumentMarketingContentRequest} returns this
*/
proto.marketingInstrumentMarketingContentView.UpdateForInstrumentMarketingContentRequest.prototype.setContext = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.marketingInstrumentMarketingContentView.UpdateForInstrumentMarketingContentRequest} returns this
 */
proto.marketingInstrumentMarketingContentView.UpdateForInstrumentMarketingContentRequest.prototype.clearContext = function() {
  return this.setContext(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.marketingInstrumentMarketingContentView.UpdateForInstrumentMarketingContentRequest.prototype.hasContext = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional marketing.InstrumentMarketingContent instrumentMarketingContent = 2;
 * @return {?proto.marketing.InstrumentMarketingContent}
 */
proto.marketingInstrumentMarketingContentView.UpdateForInstrumentMarketingContentRequest.prototype.getInstrumentmarketingcontent = function() {
  return /** @type{?proto.marketing.InstrumentMarketingContent} */ (
    jspb.Message.getWrapperField(this, marketing_instrumentMarketingContent_pb.InstrumentMarketingContent, 2));
};


/**
 * @param {?proto.marketing.InstrumentMarketingContent|undefined} value
 * @return {!proto.marketingInstrumentMarketingContentView.UpdateForInstrumentMarketingContentRequest} returns this
*/
proto.marketingInstrumentMarketingContentView.UpdateForInstrumentMarketingContentRequest.prototype.setInstrumentmarketingcontent = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.marketingInstrumentMarketingContentView.UpdateForInstrumentMarketingContentRequest} returns this
 */
proto.marketingInstrumentMarketingContentView.UpdateForInstrumentMarketingContentRequest.prototype.clearInstrumentmarketingcontent = function() {
  return this.setInstrumentmarketingcontent(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.marketingInstrumentMarketingContentView.UpdateForInstrumentMarketingContentRequest.prototype.hasInstrumentmarketingcontent = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.marketingInstrumentMarketingContentView.UpdateForInstrumentMarketingContentResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.marketingInstrumentMarketingContentView.UpdateForInstrumentMarketingContentResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.marketingInstrumentMarketingContentView.UpdateForInstrumentMarketingContentResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.marketingInstrumentMarketingContentView.UpdateForInstrumentMarketingContentResponse.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.marketingInstrumentMarketingContentView.UpdateForInstrumentMarketingContentResponse}
 */
proto.marketingInstrumentMarketingContentView.UpdateForInstrumentMarketingContentResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.marketingInstrumentMarketingContentView.UpdateForInstrumentMarketingContentResponse;
  return proto.marketingInstrumentMarketingContentView.UpdateForInstrumentMarketingContentResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.marketingInstrumentMarketingContentView.UpdateForInstrumentMarketingContentResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.marketingInstrumentMarketingContentView.UpdateForInstrumentMarketingContentResponse}
 */
proto.marketingInstrumentMarketingContentView.UpdateForInstrumentMarketingContentResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.marketingInstrumentMarketingContentView.UpdateForInstrumentMarketingContentResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.marketingInstrumentMarketingContentView.UpdateForInstrumentMarketingContentResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.marketingInstrumentMarketingContentView.UpdateForInstrumentMarketingContentResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.marketingInstrumentMarketingContentView.UpdateForInstrumentMarketingContentResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.marketingInstrumentMarketingContentView.UpdateForSmartInstrumentIDRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.marketingInstrumentMarketingContentView.UpdateForSmartInstrumentIDRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.marketingInstrumentMarketingContentView.UpdateForSmartInstrumentIDRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.marketingInstrumentMarketingContentView.UpdateForSmartInstrumentIDRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
context: (f = msg.getContext()) && security_context_pb.ContextFuture.toObject(includeInstance, f),
smartinstrumentid: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.marketingInstrumentMarketingContentView.UpdateForSmartInstrumentIDRequest}
 */
proto.marketingInstrumentMarketingContentView.UpdateForSmartInstrumentIDRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.marketingInstrumentMarketingContentView.UpdateForSmartInstrumentIDRequest;
  return proto.marketingInstrumentMarketingContentView.UpdateForSmartInstrumentIDRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.marketingInstrumentMarketingContentView.UpdateForSmartInstrumentIDRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.marketingInstrumentMarketingContentView.UpdateForSmartInstrumentIDRequest}
 */
proto.marketingInstrumentMarketingContentView.UpdateForSmartInstrumentIDRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new security_context_pb.ContextFuture;
      reader.readMessage(value,security_context_pb.ContextFuture.deserializeBinaryFromReader);
      msg.setContext(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setSmartinstrumentid(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.marketingInstrumentMarketingContentView.UpdateForSmartInstrumentIDRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.marketingInstrumentMarketingContentView.UpdateForSmartInstrumentIDRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.marketingInstrumentMarketingContentView.UpdateForSmartInstrumentIDRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.marketingInstrumentMarketingContentView.UpdateForSmartInstrumentIDRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getContext();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      security_context_pb.ContextFuture.serializeBinaryToWriter
    );
  }
  f = message.getSmartinstrumentid();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional security.ContextFuture context = 1;
 * @return {?proto.security.ContextFuture}
 */
proto.marketingInstrumentMarketingContentView.UpdateForSmartInstrumentIDRequest.prototype.getContext = function() {
  return /** @type{?proto.security.ContextFuture} */ (
    jspb.Message.getWrapperField(this, security_context_pb.ContextFuture, 1));
};


/**
 * @param {?proto.security.ContextFuture|undefined} value
 * @return {!proto.marketingInstrumentMarketingContentView.UpdateForSmartInstrumentIDRequest} returns this
*/
proto.marketingInstrumentMarketingContentView.UpdateForSmartInstrumentIDRequest.prototype.setContext = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.marketingInstrumentMarketingContentView.UpdateForSmartInstrumentIDRequest} returns this
 */
proto.marketingInstrumentMarketingContentView.UpdateForSmartInstrumentIDRequest.prototype.clearContext = function() {
  return this.setContext(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.marketingInstrumentMarketingContentView.UpdateForSmartInstrumentIDRequest.prototype.hasContext = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional string smartInstrumentID = 2;
 * @return {string}
 */
proto.marketingInstrumentMarketingContentView.UpdateForSmartInstrumentIDRequest.prototype.getSmartinstrumentid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.marketingInstrumentMarketingContentView.UpdateForSmartInstrumentIDRequest} returns this
 */
proto.marketingInstrumentMarketingContentView.UpdateForSmartInstrumentIDRequest.prototype.setSmartinstrumentid = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.marketingInstrumentMarketingContentView.UpdateForSmartInstrumentIDResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.marketingInstrumentMarketingContentView.UpdateForSmartInstrumentIDResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.marketingInstrumentMarketingContentView.UpdateForSmartInstrumentIDResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.marketingInstrumentMarketingContentView.UpdateForSmartInstrumentIDResponse.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.marketingInstrumentMarketingContentView.UpdateForSmartInstrumentIDResponse}
 */
proto.marketingInstrumentMarketingContentView.UpdateForSmartInstrumentIDResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.marketingInstrumentMarketingContentView.UpdateForSmartInstrumentIDResponse;
  return proto.marketingInstrumentMarketingContentView.UpdateForSmartInstrumentIDResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.marketingInstrumentMarketingContentView.UpdateForSmartInstrumentIDResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.marketingInstrumentMarketingContentView.UpdateForSmartInstrumentIDResponse}
 */
proto.marketingInstrumentMarketingContentView.UpdateForSmartInstrumentIDResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.marketingInstrumentMarketingContentView.UpdateForSmartInstrumentIDResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.marketingInstrumentMarketingContentView.UpdateForSmartInstrumentIDResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.marketingInstrumentMarketingContentView.UpdateForSmartInstrumentIDResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.marketingInstrumentMarketingContentView.UpdateForSmartInstrumentIDResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.marketingInstrumentMarketingContentView.FullUpdateRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.marketingInstrumentMarketingContentView.FullUpdateRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.marketingInstrumentMarketingContentView.FullUpdateRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.marketingInstrumentMarketingContentView.FullUpdateRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
context: (f = msg.getContext()) && security_context_pb.ContextFuture.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.marketingInstrumentMarketingContentView.FullUpdateRequest}
 */
proto.marketingInstrumentMarketingContentView.FullUpdateRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.marketingInstrumentMarketingContentView.FullUpdateRequest;
  return proto.marketingInstrumentMarketingContentView.FullUpdateRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.marketingInstrumentMarketingContentView.FullUpdateRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.marketingInstrumentMarketingContentView.FullUpdateRequest}
 */
proto.marketingInstrumentMarketingContentView.FullUpdateRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new security_context_pb.ContextFuture;
      reader.readMessage(value,security_context_pb.ContextFuture.deserializeBinaryFromReader);
      msg.setContext(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.marketingInstrumentMarketingContentView.FullUpdateRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.marketingInstrumentMarketingContentView.FullUpdateRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.marketingInstrumentMarketingContentView.FullUpdateRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.marketingInstrumentMarketingContentView.FullUpdateRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getContext();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      security_context_pb.ContextFuture.serializeBinaryToWriter
    );
  }
};


/**
 * optional security.ContextFuture context = 1;
 * @return {?proto.security.ContextFuture}
 */
proto.marketingInstrumentMarketingContentView.FullUpdateRequest.prototype.getContext = function() {
  return /** @type{?proto.security.ContextFuture} */ (
    jspb.Message.getWrapperField(this, security_context_pb.ContextFuture, 1));
};


/**
 * @param {?proto.security.ContextFuture|undefined} value
 * @return {!proto.marketingInstrumentMarketingContentView.FullUpdateRequest} returns this
*/
proto.marketingInstrumentMarketingContentView.FullUpdateRequest.prototype.setContext = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.marketingInstrumentMarketingContentView.FullUpdateRequest} returns this
 */
proto.marketingInstrumentMarketingContentView.FullUpdateRequest.prototype.clearContext = function() {
  return this.setContext(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.marketingInstrumentMarketingContentView.FullUpdateRequest.prototype.hasContext = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.marketingInstrumentMarketingContentView.FullUpdateResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.marketingInstrumentMarketingContentView.FullUpdateResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.marketingInstrumentMarketingContentView.FullUpdateResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.marketingInstrumentMarketingContentView.FullUpdateResponse.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.marketingInstrumentMarketingContentView.FullUpdateResponse}
 */
proto.marketingInstrumentMarketingContentView.FullUpdateResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.marketingInstrumentMarketingContentView.FullUpdateResponse;
  return proto.marketingInstrumentMarketingContentView.FullUpdateResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.marketingInstrumentMarketingContentView.FullUpdateResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.marketingInstrumentMarketingContentView.FullUpdateResponse}
 */
proto.marketingInstrumentMarketingContentView.FullUpdateResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.marketingInstrumentMarketingContentView.FullUpdateResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.marketingInstrumentMarketingContentView.FullUpdateResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.marketingInstrumentMarketingContentView.FullUpdateResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.marketingInstrumentMarketingContentView.FullUpdateResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};


goog.object.extend(exports, proto.marketingInstrumentMarketingContentView);

import { Amount } from "james/ledger";
import { Fee, FeeState } from "./Fee";
import { Entry } from "../audit/Entry";
import { BigNumber } from "bignumber.js";

export const InstrumentStablecoinTokenBurningFeeTypeName =
  "mesh::remuneration/InstrumentStablecoinTokenBurningFee";

export class InstrumentStablecoinTokenBurningFee implements Fee {
  public ["@type"]: string = InstrumentStablecoinTokenBurningFeeTypeName;

  public id = "";

  public responsibleGroupID = "";

  public instrumentStablecoinID = "";

  public perTokenAmount: Amount = new Amount();

  public noTokensBurned: Amount = new Amount();

  public totalAmount: Amount = new Amount();

  public vatRate: BigNumber = BigNumber("0");

  public transactionID = "";

  public auditEntry: Entry = new Entry();

  public state: FeeState = FeeState.Pending;

  constructor(fee?: InstrumentStablecoinTokenBurningFee) {
    if (!fee) {
      return;
    }
    this.id = fee.id;
    this.responsibleGroupID = fee.responsibleGroupID;
    this.instrumentStablecoinID = fee.instrumentStablecoinID;
    this.perTokenAmount = new Amount(fee.perTokenAmount);
    this.noTokensBurned = new Amount(fee.noTokensBurned);
    this.totalAmount = new Amount(fee.totalAmount);
    this.vatRate = new BigNumber(fee.vatRate);
    this.transactionID = fee.transactionID;
    this.auditEntry = new Entry(fee.auditEntry);
    this.state = fee.state;
  }

  feeAmount(): Amount {
    return this.totalAmount;
  }

  feeVATRate(): BigNumber {
    return this.vatRate;
  }

  feeTotal(): Amount {
    return this.totalAmount.setValue(
      this.totalAmount.value.plus(
        this.totalAmount.value.multipliedBy(this.vatRate),
      ),
    );
  }

  feeID(): string {
    return this.id;
  }

  feeName(): string {
    return "Token Burning Fee";
  }

  feeResponsibleGroupID(): string {
    return this.responsibleGroupID;
  }

  feeTransactionID(): string {
    return this.transactionID;
  }

  feeState(): FeeState {
    return this.state;
  }
}

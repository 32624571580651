import { useErrorContext } from "context/Error";
import { usePersonViewStore } from "../store";
import { personToFuture } from "james/legal/person/Person";
import { AllowedPersonUpdatePaths } from "@mesh/common-js/dist/legal/personWriteService_pb";
import { usePersonContext } from "context/Person/PersonService";

export const useSavePersonDetails = () => {
  const store = usePersonViewStore();
  const { errorContextDefaultErrorFeedback } = useErrorContext();
  const { updatePerson } = usePersonContext();

  const savePersonDetails = async () => {
    store.setLoading(true);
    try {
      const person = store.getPersonObject();
      await updatePerson(personToFuture(person), [
        AllowedPersonUpdatePaths.FIRST_NAME.toString(),
        AllowedPersonUpdatePaths.MIDDLE_NAMES.toString(),
        AllowedPersonUpdatePaths.LAST_NAME.toString(),
        AllowedPersonUpdatePaths.NATIONALITY.toString(),
        AllowedPersonUpdatePaths.IDENTIFICATION_TYPE.toString(),
        AllowedPersonUpdatePaths.IDENTIFICATION_NUMBER.toString(),
        AllowedPersonUpdatePaths.INFLUENTIAL_PERSON.toString(),
        AllowedPersonUpdatePaths.IDENTIFICATION_NUMBER_EXPIRY.toString(),
        AllowedPersonUpdatePaths.DATE_OF_BIRTH.toString(),
        AllowedPersonUpdatePaths.TAX_REFERENCE.toString(),
        AllowedPersonUpdatePaths.SARS_TAX_DECLARATION.toString(),
        AllowedPersonUpdatePaths.RESIDENTIAL_ADDRESS.toString(),
        AllowedPersonUpdatePaths.SOURCE_OF_WEALTH.toString(),
        AllowedPersonUpdatePaths.SOURCE_OF_INCOME.toString(),
      ]);
    } catch (e) {
      console.error("error saving person information", e);
      errorContextDefaultErrorFeedback(e, "error saving person information");
    }
    store.setLoading(false);
  };

  return {
    savePersonDetails,
  };
};

import {
  Autocomplete,
  Box,
  Checkbox,
  CircularProgress,
  Divider,
  FormControlLabel,
  MenuItem,
  Tooltip,
  Typography,
} from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import {
  allSmartInstrumentTypes,
  allUnitCategories,
  smartInstrumentTypeToString,
  unitToString,
} from "@mesh/common-js/dist/financial";
import {
  DateTimeField,
  TextField,
} from "@mesh/common-js-react/dist/FormFields";
import { Info as InfoIcon } from "@mui/icons-material";
import { allTimezones, timezoneToString } from "@mesh/common-js/dist/i8n";
import { Timezone } from "@mesh/common-js/dist/i8n/timezone_pb";
import { ViewMode, useBuilderContext } from "../../../../Context";
import {
  SmartInstrument,
  SmartInstrumentState,
} from "@mesh/common-js/dist/financial/smartInstrument_pb";
import { futureNetworkToString } from "@mesh/common-js/dist/ledger";
import { FutureNetwork } from "@mesh/common-js/dist/ledger/futureNetwork_pb";
import {
  ReadManySmartInstrumentRequest,
  ReadManySmartInstrumentResponse,
} from "@mesh/common-js/dist/financial/smartInstrumentReader_meshproto_pb";
import { useAPIContext } from "context/API";
import { useApplicationContext } from "context/Application/Application";
import { useIsMounted } from "hooks";
import { Query } from "@mesh/common-js/dist/search/query_pb";
import {
  newTextExactCriterion,
  newTextSubstringCriterion,
} from "@mesh/common-js/dist/search";
import { Criterion } from "@mesh/common-js/dist/search/criterion_pb";
import { allIssuerOptions, IssuerOption } from "../../../../useValidatedForm";

export type InstrumentSectionProps = {
  system: boolean;
};

const fixedQuery = new Query().setOffset(0).setLimit(15);

export const InstrumentSection = (props: InstrumentSectionProps) => {
  const {
    apiCallInProgress,
    viewMode,
    formData,
    formDataValidationResult,
    formDataUpdater,
    potentialTokenNetworks,
    potentialGroupOwners,
  } = useBuilderContext();
  const isMounted = useIsMounted();
  const { authContext } = useApplicationContext();
  const {
    financial: { smartInstrumentReader, smartInstrumentReaderUNSCOPED },
  } = useAPIContext();
  const [
    readPotentialLinkableInstrumentsRequest,
    setReadPotentialLinkableInstrumentsRequest,
  ] = useState<ReadManySmartInstrumentRequest>(
    (() => {
      // prepare initial request
      const initialRequest = new ReadManySmartInstrumentRequest()
        .setContext(authContext.toFuture())
        .setQuery(fixedQuery);

      // if an instrument is already linked then set that as the the only criteria
      // (otherwise leave criteria blank to get first page of items)
      if (formData.smartInstrument.getLinkedsmartinstrumentid()) {
        initialRequest.setCriteriaList([
          newTextExactCriterion(
            "id",
            formData.smartInstrument.getLinkedsmartinstrumentid(),
          ),
        ]);
      }

      return initialRequest;
    })(),
  );
  const [
    readPotentialLinkableInstrumentsResponse,
    setReadPotentialLinkableInstrumentsResponseReadResponse,
  ] = useState<ReadManySmartInstrumentResponse>(
    new ReadManySmartInstrumentResponse(),
  );
  const [loadingLinkableInstruments, setLoadingLinkableInstruments] =
    useState(false);
  const timeoutRef = useRef<NodeJS.Timeout | undefined>(undefined);
  useEffect(() => {
    setLoadingLinkableInstruments(true);
    clearTimeout(timeoutRef.current);
    timeoutRef.current = setTimeout(async () => {
      try {
        let response: ReadManySmartInstrumentResponse;
        if (props.system) {
          response =
            await smartInstrumentReaderUNSCOPED.readManySmartInstrumentUNSCOPED(
              readPotentialLinkableInstrumentsRequest,
            );
        } else {
          response = await smartInstrumentReader.readManySmartInstrument(
            readPotentialLinkableInstrumentsRequest,
          );
        }
        if (isMounted()) {
          setReadPotentialLinkableInstrumentsResponseReadResponse(response);
        }
      } catch (e) {
        console.error("error reading smart instrument", e);
      }
      setLoadingLinkableInstruments(false);
    }, 400);
  }, [readPotentialLinkableInstrumentsRequest, isMounted]);

  const disabled = apiCallInProgress;
  const readOnly = viewMode === ViewMode.View;

  return (
    <Box sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
      <Box sx={{ display: "flex", flexDirection: "column", gap: 1 }}>
        <Typography variant="h6">Instrument</Typography>
        <Box
          sx={(theme) => ({
            display: "flex",
            flexWrap: "wrap",
            columnGap: theme.spacing(1),
          })}
        >
          <TextField
            id={"smartInstrumentBuilder-instrument-name-textField"}
            fullWidth
            sx={{ maxWidth: 320 }}
            label="Name"
            readOnly={readOnly}
            disabled={disabled}
            placeholder="Enter an Instrument Name"
            value={formData.smartInstrument.getName()}
            onChange={(e) => formDataUpdater.name(e.target.value)}
            error={!!formDataValidationResult.fieldValidations.name}
            helperText={formDataValidationResult.fieldValidations.name}
          />
          <TextField
            id="smartInstrumentBuilder-instrument-type-select"
            readOnly={readOnly}
            disabled={disabled}
            fullWidth
            sx={{ maxWidth: 320 }}
            label="Type"
            select
            value={formData.smartInstrument.getType()}
            onChange={(e) => formDataUpdater.type(Number(e.target.value))}
            error={!!formDataValidationResult.fieldValidations.type}
            helperText={formDataValidationResult.fieldValidations.type}
          >
            {allSmartInstrumentTypes.map((v) => {
              return (
                <MenuItem key={v} value={v}>
                  {smartInstrumentTypeToString(v)}
                </MenuItem>
              );
            })}
          </TextField>
          <TextField
            id="smartInstrumentBuilder-instrument-unit-select"
            readOnly={readOnly}
            disabled={disabled}
            fullWidth
            sx={{ maxWidth: 320 }}
            label="Unit Type"
            select
            value={formData.smartInstrument.getUnit()}
            onChange={(e) => formDataUpdater.unit(Number(e.target.value))}
            error={!!formDataValidationResult.fieldValidations.unit}
            helperText={formDataValidationResult.fieldValidations.unit}
          >
            {allUnitCategories.map((v) => {
              return (
                <MenuItem key={v} value={v}>
                  {unitToString(v)}
                </MenuItem>
              );
            })}
          </TextField>
          <TextField
            id={"smartInstrumentBuilder-instrument-ownerID-select"}
            fullWidth
            sx={{ maxWidth: 320 }}
            readOnly={readOnly}
            disabled={disabled}
            label="Owner Group"
            select
            value={formData.smartInstrument.getOwnerid()}
            onChange={(e) => formDataUpdater.ownerID(e.target.value)}
            error={!!formDataValidationResult.fieldValidations.ownerID}
            helperText={formDataValidationResult.fieldValidations.ownerID}
          >
            {potentialGroupOwners.map((group) => (
              <MenuItem key={group.id} value={group.id}>
                {group.name}
              </MenuItem>
            ))}
          </TextField>
          <DateTimeField
            id="smartInstrumentBuilder-instrument-issueDate-dateField"
            sx={{ width: "320px" }}
            readOnly={readOnly}
            disabled={disabled}
            label={"Issue Date"}
            value={formData.smartInstrument.getIssuedate()}
            onChange={(newValue) => formDataUpdater.issueDate(newValue)}
            error={!!formDataValidationResult.fieldValidations.issueDate}
            helperText={formDataValidationResult.fieldValidations.issueDate}
          />
          <Box sx={{ display: "flex", alignItems: "center", width: "100%" }}>
            <TextField
              id="smartInstrumentBuilder-instrument-timezone-select"
              label="Timezone"
              readOnly={readOnly}
              disabled={disabled}
              fullWidth
              sx={{ maxWidth: 290 }}
              select
              value={formData.smartInstrument.getTimezone()}
              onChange={(e) => formDataUpdater.timezone(Number(e.target.value))}
              error={!!formDataValidationResult.fieldValidations.timezone}
              helperText={formDataValidationResult.fieldValidations.timezone}
            >
              {allTimezones
                .filter((tz) => tz != Timezone.UNDEFINED_TIMEZONE)
                .map((tz, idx) => (
                  <MenuItem key={idx} value={tz}>
                    {timezoneToString(tz)}
                  </MenuItem>
                ))}
            </TextField>
            <Tooltip
              placement={"top"}
              title="Determines the local time context for the instrument."
            >
              <InfoIcon sx={{ ml: 1 }} />
            </Tooltip>
          </Box>
        </Box>
      </Box>

      <Divider />

      <Box sx={{ display: "flex", flexDirection: "column", gap: 1 }}>
        <Typography variant="h6">Tokenisation</Typography>
        <Box
          sx={(theme) => ({
            display: "flex",
            flexWrap: "wrap",
            columnGap: theme.spacing(1),
          })}
        >
          <TextField
            id={"smartInstrumentBuilder-instrument-tokenCode-textField"}
            fullWidth
            sx={{ maxWidth: 320 }}
            label="Token Code (Optional)"
            readOnly={readOnly}
            disabled={disabled}
            placeholder="Enter a Token Code"
            value={formData.smartInstrument.getToken()?.getCode() ?? ""}
            onChange={(e) => formDataUpdater.tokenCode(e.target.value)}
            error={!!formDataValidationResult.fieldValidations.tokenCode}
            helperText={formDataValidationResult.fieldValidations.tokenCode}
          />
          <TextField
            id="smartInstrumentBuilder-instrument-tokenNetwork-select"
            readOnly={readOnly}
            disabled={disabled}
            fullWidth
            sx={{ maxWidth: 320 }}
            label="Token Network"
            select
            value={
              formData.smartInstrument.getToken()?.getNetwork() ??
              FutureNetwork.UNDEFINED_NETWORK
            }
            onChange={(e) =>
              formDataUpdater.tokenNetwork(Number(e.target.value))
            }
            error={!!formDataValidationResult.fieldValidations.tokenNetwork}
            helperText={formDataValidationResult.fieldValidations.tokenNetwork}
          >
            {potentialTokenNetworks.map((v) => {
              return (
                <MenuItem key={v} value={v}>
                  {futureNetworkToString(v)}
                </MenuItem>
              );
            })}
          </TextField>
          {formData.smartInstrument.getState() ===
          SmartInstrumentState.DRAFT_SMART_INSTRUMENT_STATE ? (
            <Box
              sx={(theme) => ({
                border: `1px solid ${theme.palette.divider}`,
                padding: theme.spacing(1),
                display: "flex",
                flexDirection: "column",
                gap: theme.spacing(1),
              })}
            >
              <Box
                sx={{ display: "flex", alignItems: "center", width: "100%" }}
              >
                <TextField
                  id="smartInstrumentBuilder-instrument-timezone-select"
                  label="Issuer Option"
                  readOnly={readOnly}
                  disabled={disabled}
                  fullWidth
                  sx={{ maxWidth: 290 }}
                  select
                  value={formData.issuerOption}
                  onChange={(e) =>
                    formDataUpdater.issuerOption(e.target.value as IssuerOption)
                  }
                >
                  {allIssuerOptions.map((io, idx) => (
                    <MenuItem key={idx} value={io}>
                      {io}
                    </MenuItem>
                  ))}
                </TextField>
                <Tooltip
                  placement={"top"}
                  title={
                    <div>
                      <Typography sx={{ display: "block" }} variant="caption">
                        - <b>{IssuerOption.Network}</b>: Token Minted by Network
                      </Typography>
                      <Typography sx={{ display: "block" }} variant="caption">
                        - <b>{IssuerOption.ManualExisting}</b>: existing token
                        mint (on/off platform)
                      </Typography>
                      <Typography sx={{ display: "block" }} variant="caption">
                        - <b>{IssuerOption.New}</b>: new mint generated on
                        PreIssuance/Issuance
                      </Typography>
                    </div>
                  }
                >
                  <InfoIcon sx={{ ml: 1 }} />
                </Tooltip>
              </Box>
              {(() => {
                switch (formData.issuerOption) {
                  case IssuerOption.ManualExisting:
                    return (
                      <TextField
                        id={
                          "smartInstrumentBuilder-instrument-tokenIsser-textField"
                        }
                        fullWidth
                        sx={{ maxWidth: 320 }}
                        label="Token Issuer"
                        readOnly={readOnly}
                        disabled={disabled}
                        placeholder="Enter an Issuer"
                        value={
                          formData.smartInstrument.getToken()?.getIssuer() ?? ""
                        }
                        onChange={(e) =>
                          formDataUpdater.tokenIssuer(e.target.value)
                        }
                        error={
                          !!formDataValidationResult.fieldValidations
                            .tokenIssuer
                        }
                        helperText={
                          formDataValidationResult.fieldValidations.tokenIssuer
                        }
                      />
                    );

                  case IssuerOption.Network:
                    return (
                      <TextField
                        id={
                          "smartInstrumentBuilder-instrument-tokenIsser-textField"
                        }
                        fullWidth
                        sx={{ maxWidth: 400 }}
                        label="Token Issuer"
                        disabled
                        value={formData.smartInstrument.getToken()?.getIssuer()}
                      />
                    );

                  case IssuerOption.New:
                  default:
                    return (
                      <TextField
                        id={
                          "smartInstrumentBuilder-instrument-tokenIsser-textField"
                        }
                        fullWidth
                        sx={{ maxWidth: 400 }}
                        label="Token Issuer"
                        disabled
                        value={"-- Generate New --"}
                      />
                    );
                }
              })()}
            </Box>
          ) : (
            <TextField
              id={"smartInstrumentBuilder-instrument-tokenIsser-textField"}
              fullWidth
              sx={{ maxWidth: 320 }}
              label="Token Issuer"
              readOnly={readOnly}
              value={formData.smartInstrument.getToken()?.getIssuer() ?? ""}
            />
          )}
          <Box
            className="checkboxes"
            sx={{
              display: "flex",
              alignItems: "center",
              gap: 1,
              width: "100%",
              maxWidth: 322,
            }}
          >
            <FormControlLabel
              sx={{ width: "100%", cursor: "text" }}
              id="smartInstrumentBuilder-instrument-directMintingAllowed-checkBox"
              disabled={disabled}
              control={<Checkbox disableTouchRipple={readOnly} />}
              label="Direct Minting Allowed"
              checked={formData.smartInstrument.getDirectmintingallowed()}
              onChange={(_, checked) => {
                if (readOnly) {
                  return;
                }
                formDataUpdater.directMintingAllowed(checked);
              }}
            />
            <Tooltip
              placement={"top"}
              title="Indicates whether the instrument can be freely minted (i.e. without a primary market sale). Only relevant if the token mint is on platform."
            >
              <InfoIcon sx={{ mr: "auto" }} />
            </Tooltip>
          </Box>
        </Box>
      </Box>

      <Divider />

      <Box sx={{ display: "flex", flexDirection: "column", gap: 1 }}>
        <Typography variant="h6">Linked Instrument</Typography>
        <Box>
          <Autocomplete
            clearOnBlur={false}
            clearOnEscape={true}
            disabled={disabled}
            readOnly={readOnly}
            sx={{ width: 500 }}
            // when form opens then search for a fresh list
            onOpen={() => {
              if (readOnly || disabled) {
                return;
              }
              setReadPotentialLinkableInstrumentsRequest(
                new ReadManySmartInstrumentRequest()
                  .setContext(authContext.toFuture())
                  .setCriteriaList([])
                  .setQuery(fixedQuery),
              );
            }}
            // when form closes if the instrument is still set then make sure that the
            // list of possible options includes the currently linked instrument
            onClose={() => {
              if (readOnly || disabled) {
                return;
              }
              const linkedInstrumentID =
                formData.smartInstrument.getLinkedsmartinstrumentid();
              if (
                linkedInstrumentID &&
                !readPotentialLinkableInstrumentsResponse
                  .getRecordsList()
                  .find((r) => r.getId() === linkedInstrumentID)
              ) {
                setReadPotentialLinkableInstrumentsRequest(
                  new ReadManySmartInstrumentRequest()
                    .setContext(authContext.toFuture())
                    .setCriteriaList([
                      newTextExactCriterion("id", linkedInstrumentID),
                    ])
                    .setQuery(fixedQuery),
                );
              }
            }}
            // while form is open search by name
            onInputChange={(_, newText, reason) => {
              if (readOnly || disabled) {
                return;
              }
              let criteria: Criterion[];

              if (newText && reason !== "reset") {
                criteria = [newTextSubstringCriterion("name", newText)];
              } else {
                criteria = [];
              }

              setReadPotentialLinkableInstrumentsRequest(
                new ReadManySmartInstrumentRequest()
                  .setContext(authContext.toFuture())
                  .setCriteriaList(criteria)
                  .setQuery(fixedQuery),
              );
            }}
            isOptionEqualToValue={(option, value) =>
              option.getId() === value.getId()
            }
            getOptionLabel={(option) => option.getName()}
            options={readPotentialLinkableInstrumentsResponse.getRecordsList()}
            loading={loadingLinkableInstruments}
            value={
              readPotentialLinkableInstrumentsResponse
                .getRecordsList()
                .find(
                  (i) =>
                    i.getId() ===
                    formData.smartInstrument.getLinkedsmartinstrumentid(),
                ) ?? null
            }
            onChange={(
              _: React.SyntheticEvent,
              value: SmartInstrument | null,
            ) => {
              if (value) {
                formDataUpdater.linkedInstrumentID(value.getId());
              } else {
                formDataUpdater.linkedInstrumentID("");
              }
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                variant={readOnly ? "standard" : "outlined"}
                label="Linked Instrument"
                placeholder={
                  readOnly
                    ? formData.smartInstrument.getLinkedsmartinstrumentid()
                      ? "!!ERROR Link not showing!!"
                      : "NOT LINKED"
                    : "Search to find instrument to link..."
                }
                InputLabelProps={{ shrink: true }}
                InputProps={{
                  ...params.InputProps,
                  endAdornment: (
                    <React.Fragment>
                      {loadingLinkableInstruments ? (
                        <CircularProgress color="inherit" size={20} />
                      ) : null}
                      {readOnly ? undefined : params.InputProps.endAdornment}
                    </React.Fragment>
                  ),
                }}
              />
            )}
          />
        </Box>
      </Box>
    </Box>
  );
};

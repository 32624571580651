"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.allTransactionFailureCodes = void 0;
exports.transactionFailureCodeToString = transactionFailureCodeToString;
exports.stringToTransactionFailureCode = stringToTransactionFailureCode;
const transactionFailureReason_pb_1 = require("./transactionFailureReason_pb");
// Get all transactionFailureCodes as enum values
exports.allTransactionFailureCodes = Object.values(transactionFailureReason_pb_1.TransactionFailureCode).filter((value) => typeof value === "number");
// Define explicit mappings between TransactionFailureCode enums and custom string representations
const transactionFailureCodeToStringMapping = {
    [transactionFailureReason_pb_1.TransactionFailureCode.UNDEFINED_TRANSACTION_FAILURE_CODE]: "-",
    [transactionFailureReason_pb_1.TransactionFailureCode.SYSTEM_FAILURE_TRANSACTION_FAILURE_CODE]: "system failure",
    [transactionFailureReason_pb_1.TransactionFailureCode.INSUFFICIENT_BALANCE_TRANSACTION_FAILURE_CODE]: "insufficient balance",
    [transactionFailureReason_pb_1.TransactionFailureCode.BAD_AUTHORISATION_TRANSACTION_FAILURE_CODE]: "bad authorisation",
    [transactionFailureReason_pb_1.TransactionFailureCode.INCORRECT_ACCOUNT_CONFIGURATION_TRANSACTION_FAILURE_CODE]: "incorrect account configuration",
    [transactionFailureReason_pb_1.TransactionFailureCode.PRICE_MOVED_TOO_MUCH_TRANSACTION_FAILURE_CODE]: "price moved too much",
    [transactionFailureReason_pb_1.TransactionFailureCode.MAXIMUM_SUBSCRIPTION_LIMIT_REACHED_TRANSACTION_FAILURE_CODE]: "subscription limit reached",
    [transactionFailureReason_pb_1.TransactionFailureCode.OFFER_NOT_FOUND_TRANSACTION_FAILURE_CODE]: "offer not found",
    [transactionFailureReason_pb_1.TransactionFailureCode.SELF_CROSS_TRANSACTION_FAILURE_CODE]: "self cross",
};
// Reverse mapping from string to TransactionFailureCode enum
const stringToTransactionFailureCodeMapping = {};
for (const [key, value] of Object.entries(transactionFailureCodeToStringMapping)) {
    stringToTransactionFailureCodeMapping[value] = Number(key);
}
class UnsupportedTransactionFailureCodeError extends Error {
    constructor(transactionFailureCode) {
        const message = `Unsupported TransactionFailureCode: ${transactionFailureCode}`;
        super(message);
        this.transactionFailureCode = transactionFailureCode;
    }
}
/**
 * Converts a TransactionFailureCode enum instance to a custom string representation.
 * @param {TransactionFailureCode} transactionFailureCode - The transactionFailureCode to convert.
 * @returns {string} The custom string representation of the transactionFailureCode.
 */
function transactionFailureCodeToString(transactionFailureCode) {
    if (transactionFailureCode in transactionFailureCodeToStringMapping) {
        return transactionFailureCodeToStringMapping[transactionFailureCode];
    }
    else {
        throw new UnsupportedTransactionFailureCodeError(transactionFailureCode);
    }
}
class UnsupportedTransactionFailureCodeStringError extends Error {
    constructor(transactionFailureCodeStr) {
        const message = `Unsupported transactionFailureCode string: ${transactionFailureCodeStr}`;
        super(message);
        this.transactionFailureCodeStr = transactionFailureCodeStr;
    }
}
/**
 * Converts a custom string representation to a TransactionFailureCode enum instance.
 * @param {string} transactionFailureCodeStr - The custom string representation of the transactionFailureCode.
 * @returns {TransactionFailureCode} The corresponding TransactionFailureCode enum instance.
 */
function stringToTransactionFailureCode(transactionFailureCodeStr) {
    if (transactionFailureCodeStr in stringToTransactionFailureCodeMapping) {
        return stringToTransactionFailureCodeMapping[transactionFailureCodeStr];
    }
    else {
        throw new UnsupportedTransactionFailureCodeStringError(transactionFailureCodeStr);
    }
}

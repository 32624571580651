import React, { useCallback, useEffect, useState } from "react";
import { styled } from "@mui/material/styles";
import { BPTable } from "components/Table";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Autocomplete,
  Box,
  Button,
  Card,
  CardContent,
  IconButton,
  TextField,
  TextFieldProps,
  Tooltip,
  Typography,
} from "@mui/material";
import { NewSorting, Query } from "james/search/query";
import {
  Cancel as CancelIcon,
  Close as ClearIcon,
  Error as ErrorIcon,
  ExpandMore as ExpandMoreIcon,
  HowToReg as InitiatingUserIcon,
  Launch,
  Refresh as ReloadIcon,
} from "@mui/icons-material";
import { Amount } from "components/Ledger/Amount";
import dayjs from "dayjs";
import { DirectOrderStateChip } from "./Chips";
import {
  Model as MarketDirectOrderViewModel,
  Updater,
} from "james/views/marketDirectOrderView";
import { useUnscopedRead } from "james/views/marketDirectOrderView/UnscopedReader";
import {
  AllDirectOrderStates,
  DirectOrderState,
  DirectOrderType,
} from "james/market/DirectOrder";
import {
  DateRangeCriterion,
  DateRangeValue,
} from "james/search/criterion/date/Range";
import {
  TextExactCriterion,
  TextListCriterion,
  TextSubstringCriterion,
} from "james/search/criterion";
import { useIsMounted } from "hooks";
import { DateField } from "components/FormFields";
import { Amount as LedgerAmount } from "james/ledger";
import { FETable } from "components/Table/FETable";
import { useSnackbar } from "notistack";
import { DirectOrderStateController } from "james/market/DirectOrderStateController";
import { DirectOrderMaximumNoOfAutomatedStateResolutions } from "james/market";
import { dateIsValid } from "utilities/date/dateIsValid";
import { Model as StellarAccountViewModel } from "@mesh/common-js/dist/views/stellarAccountView/model_pb";
import {
  Model as StellarTransactionHistoryViewModel,
  Reader as StellarTransactionHistoryViewReader,
} from "james/views/stellarTransactionHistoryView";
import { IDIdentifier } from "james/search/identifier";
import { useWindowSize } from "utilities/general";
import { useStellarContext } from "context/Stellar";
import { useApplicationContext } from "context/Application/Application";
import { useAppNoticeContext } from "context/AppNotice/AppNotice";
import { useErrorContext } from "context/Error";
import { TransactionTable } from "components/Ledger/Transaction";
import { formatTextNum } from "../../../utilities/number";
import { useAPIContext } from "context/API";
import { ReadOneModelRequest } from "@mesh/common-js/dist/views/stellarAccountView/modelReader_pb";
import { newTextExactCriterion } from "@mesh/common-js/dist/search";
import { AccountState } from "@mesh/common-js/dist/stellar/enrichedAccount_pb";
import { Network } from "@mesh/common-js/dist/stellar/network_pb";

const PREFIX = "DirectOrder";

const classes = {
  initiatingPartyCellLayout: `${PREFIX}-initiatingPartyCellLayout`,
  initiatingPartyIcon: `${PREFIX}-initiatingPartyIcon`,
  textSearchFilterField: `${PREFIX}-textSearchFilterField`,
  statusSelectFilterField: `${PREFIX}-statusSelectFilterField`,
  clearIcon: `${PREFIX}-clearIcon`,
  dateFilterField: `${PREFIX}-dateFilterField`,
};

const Root = styled("div")(({ theme }) => ({
  [`& .${classes.initiatingPartyCellLayout}`]: {
    display: "grid",
    gridTemplateColumns: "auto 1fr",
    gridColumnGap: theme.spacing(1.5),
    alignItems: "center",
  },

  [`& .${classes.initiatingPartyIcon}`]: {
    color: theme.palette.custom.lavender,
    fontSize: 16,
  },

  [`& .${classes.textSearchFilterField}`]: {
    width: 260,
  },

  [`& .${classes.statusSelectFilterField}`]: {
    width: 330,
  },

  [`& .${classes.clearIcon}`]: {
    marginRight: theme.spacing(-2),
    color: theme.palette.action.disabled,
    cursor: "pointer",
    "&:hover": {
      color: theme.palette.action.active,
    },
  },

  [`& .${classes.dateFilterField}`]: {
    width: 160,
  },
}));

const initialQuery = new Query({
  limit: 25,
  offset: 0,
  sorting: [NewSorting("number", "desc")],
});

export function DirectOrder() {
  const isMounted = useIsMounted();
  const { viewConfiguration, authContext } = useApplicationContext();
  const { enqueueSnackbar } = useSnackbar();

  const {
    unscopedReadRequest,
    unscopedReadResponse,
    setUnscopedReadRequest,
    loading,
  } = useUnscopedRead({
    context: authContext,
    criteria: {},
    query: new Query(initialQuery),
  });
  const [, windowHeight] = useWindowSize();
  const { NotificationBannerHeight: noticeBannerHeight } =
    useAppNoticeContext();

  // table criteria
  const [textSearchCriteria, setTextSearchCriteria] = useState("");
  const [orderStatusesForCriterion, setOrderStatusesForCriterion] = useState<
    DirectOrderState[]
  >([]);
  const [orderDateTimeCriterionFrom, setOrderDateTimeCriterionFrom] = useState<
    DateRangeValue | undefined
  >(undefined);
  const [orderDateTimeCriterionTo, setOrderDateTimeCriterionTo] = useState<
    DateRangeValue | undefined
  >(undefined);
  const { errorContextErrorTranslator } = useErrorContext();
  useEffect(() => {
    // prepare new initial criteria for table mode
    /* eslint-disable */
    let criteria: any = {};
    /* eslint-enable */

    // populate criteria with those criteria set as necessary
    if (textSearchCriteria) {
      criteria = {
        ...criteria,
        $or: [
          { number: TextSubstringCriterion(textSearchCriteria) },
          { assetName: TextSubstringCriterion(textSearchCriteria) },
          {
            initiatingPartyClientName:
              TextSubstringCriterion(textSearchCriteria),
          },
          {
            counterpartyClientName: TextSubstringCriterion(textSearchCriteria),
          },
        ],
      };
    }

    if (orderStatusesForCriterion.length) {
      criteria.state = TextListCriterion(orderStatusesForCriterion);
    }

    if (orderDateTimeCriterionFrom || orderDateTimeCriterionTo) {
      criteria.orderDateTime = DateRangeCriterion(
        orderDateTimeCriterionFrom,
        orderDateTimeCriterionTo,
      );
    }

    if (isMounted()) {
      // update read request
      setUnscopedReadRequest({
        context: unscopedReadRequest.context,
        query: initialQuery,
        criteria,
      });
    }
  }, [
    isMounted,
    textSearchCriteria,
    orderStatusesForCriterion,
    orderDateTimeCriterionFrom,
    orderDateTimeCriterionTo,
    setUnscopedReadRequest,
    unscopedReadRequest.context,
  ]);

  // selection of market direct order view model for resolution
  const [
    selectedMarketDirectOrderViewModel,
    setSelectedMarketDirectOrderViewModel,
  ] = useState<MarketDirectOrderViewModel | undefined>(undefined);
  const [resolutionInProgress, setResolutionInProgress] = useState(false);
  const handleResolveDirectOrderState = async () => {
    if (!selectedMarketDirectOrderViewModel) {
      console.error("selected market direct order view model not set");
      return;
    }

    setResolutionInProgress(true);
    try {
      await DirectOrderStateController.ResolveDirectOrderState({
        context: authContext,
        directOrderIdentifier: IDIdentifier(
          selectedMarketDirectOrderViewModel.directOrderID,
        ),
        ignoreFailureCount: true,
      });
      setSelectedMarketDirectOrderViewModel(undefined);
      setUnscopedReadRequest({ ...unscopedReadRequest });
      enqueueSnackbar("Direct Order State Resolution Completed", {
        variant: "success",
      });
    } catch (e) {
      const err = errorContextErrorTranslator.translateError(e);
      console.error(`error resolving direct order state`, e);
      enqueueSnackbar(`error resolving direct order state: ${err.message}`, {
        variant: "error",
      });
    }
    setResolutionInProgress(false);
  };

  const [apiLoading, setAPILoading] = useState(false);

  const handleFullUpdate = async () => {
    setAPILoading(true);
    try {
      await Updater.FullUpdate({
        context: authContext,
      });
      enqueueSnackbar("Full Update Complete", { variant: "success" });
    } catch (e) {
      const err = errorContextErrorTranslator.translateError(e);
      console.error(
        `error performing full update: ${
          err.message ? err.message : err.toString()
        }`,
      );
      enqueueSnackbar(
        `Error Performing Full Update: ${
          err.message ? err.message : err.toString()
        }`,
        { variant: "error" },
      );
    }
    setAPILoading(false);
  };

  return (
    <Root>
      <BPTable
        singleSelect
        height={window.innerHeight - 140 - noticeBannerHeight}
        title={"Direct Orders"}
        loading={loading || resolutionInProgress || apiLoading}
        style={{ gap: 3 }}
        data={unscopedReadResponse.models}
        totalNoRecords={unscopedReadResponse.total}
        query={unscopedReadRequest.query}
        onQueryChange={(query) =>
          setUnscopedReadRequest({
            ...unscopedReadRequest,
            query,
          })
        }
        /* eslint-disable */
        onSingleSelectChange={(data: { [p: string]: any } | undefined) =>
          setSelectedMarketDirectOrderViewModel(
            data as MarketDirectOrderViewModel,
          )
        }
        /* eslint-enable */
        filters={[
          <TextField
            variant="outlined"
            margin="dense"
            className={classes.textSearchFilterField}
            id="directOrdersOpsTable-textSearch-textField"
            label="Search"
            placeholder="Start Typing..."
            value={textSearchCriteria}
            InputLabelProps={{ shrink: true }}
            onChange={(e) => setTextSearchCriteria(e.target.value)}
          />,
          <Autocomplete
            isOptionEqualToValue={(option, value) => option === value}
            id="directOrdersOpsTable-state-autocomplete"
            disabled={loading}
            multiple
            options={AllDirectOrderStates}
            filterSelectedOptions
            onChange={(_, value: DirectOrderState[]) =>
              setOrderStatusesForCriterion(value)
            }
            ChipProps={{
              color: "info",
              size: "small",
              deleteIcon: (
                <CancelIcon
                  sx={(theme) => ({
                    color: `${theme.palette.text.secondary} !important`,
                    "&:hover": {
                      color: `${theme.palette.secondary.contrastText} !important`,
                    },
                  })}
                />
              ),
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                className={classes.statusSelectFilterField}
                label="State"
                variant="outlined"
                margin="dense"
                InputLabelProps={{ shrink: true }}
                placeholder={
                  orderStatusesForCriterion.length ? undefined : "Select..."
                }
              />
            )}
          />,
          <DateField
            label="From"
            disabled={loading}
            id="directOrdersOpsTable-orderDateTimeFrom-dateField"
            className={classes.dateFilterField}
            value={
              orderDateTimeCriterionFrom
                ? orderDateTimeCriterionFrom.date
                : null
            }
            onChange={(newValue) => {
              if (!(newValue && dateIsValid(newValue))) {
                setOrderDateTimeCriterionFrom(undefined);
              } else {
                setOrderDateTimeCriterionFrom(
                  newValue
                    ? {
                        date: newValue.startOf("day").format(),
                        inclusive: true,
                        ignore: false,
                      }
                    : undefined,
                );
              }
            }}
            renderInput={(textFieldProps: TextFieldProps) => (
              <TextField
                {...textFieldProps}
                variant="outlined"
                margin="dense"
                InputLabelProps={{ shrink: true }}
                InputProps={{
                  endAdornment: (() => (
                    <>
                      {orderDateTimeCriterionFrom && (
                        <Tooltip title="Clear" placement="top">
                          <IconButton
                            className={classes.clearIcon}
                            size="small"
                            onClick={() =>
                              setOrderDateTimeCriterionFrom(undefined)
                            }
                          >
                            <ClearIcon />
                          </IconButton>
                        </Tooltip>
                      )}
                      {textFieldProps.InputProps &&
                      textFieldProps.InputProps.endAdornment
                        ? textFieldProps.InputProps.endAdornment
                        : null}
                    </>
                  ))(),
                }}
              />
            )}
          />,
          <DateField
            label="To"
            disabled={loading}
            id="directOrdersOpsTable-orderDateTimeTo-dateField"
            className={classes.dateFilterField}
            value={
              orderDateTimeCriterionTo ? orderDateTimeCriterionTo.date : null
            }
            onChange={(newValue) => {
              if (!(newValue && dateIsValid(newValue))) {
                setOrderDateTimeCriterionTo(undefined);
              } else {
                setOrderDateTimeCriterionTo(
                  newValue
                    ? {
                        date: newValue.endOf("day").format(),
                        inclusive: true,
                        ignore: false,
                      }
                    : undefined,
                );
              }
            }}
            renderInput={(textFieldProps: TextFieldProps) => (
              <TextField
                {...textFieldProps}
                variant="outlined"
                margin="dense"
                InputLabelProps={{ shrink: true }}
                InputProps={{
                  endAdornment: (() => (
                    <>
                      {orderDateTimeCriterionTo && (
                        <Tooltip title="Clear" placement="top">
                          <IconButton
                            className={classes.clearIcon}
                            size="small"
                            onClick={() => {
                              setOrderDateTimeCriterionTo(undefined);
                            }}
                          >
                            <ClearIcon />
                          </IconButton>
                        </Tooltip>
                      )}
                      {textFieldProps.InputProps &&
                      textFieldProps.InputProps.endAdornment
                        ? textFieldProps.InputProps.endAdornment
                        : null}
                    </>
                  ))(),
                }}
              />
            )}
          />,
        ]}
        toolBarControls={(() => {
          const controls: React.ReactNode[] = [];

          // show resolve direct order button if:
          // - state is ClearanceFailed and
          // - failureCount greater than maximum allowed no. of automated failures
          if (
            viewConfiguration.Trading?.["Direct Order"]?.ResolveState &&
            selectedMarketDirectOrderViewModel &&
            selectedMarketDirectOrderViewModel.stateResolutionCount >=
              DirectOrderMaximumNoOfAutomatedStateResolutions
          ) {
            controls.push(
              <Button
                variant="contained"
                color="primary"
                children="Resolve State"
                onClick={handleResolveDirectOrderState}
              />,
            );
          }

          controls.push(
            <IconButton
              disabled={loading}
              size="small"
              id="directOrderOps-tableRefresh-button"
              onClick={() =>
                setUnscopedReadRequest({
                  ...unscopedReadRequest,
                  query: new Query(initialQuery),
                })
              }
            >
              <ReloadIcon />
            </IconButton>,
          );

          if (
            viewConfiguration.Trading?.["Direct Order"]?.ViewModel?.FullUpdate
          ) {
            controls.push(
              <Button
                variant="outlined"
                id="directOrder-viewFullUpdate-button"
                children="full view update"
                onClick={handleFullUpdate}
              />,
            );
          }

          return controls;
        })()}
        columns={[
          {
            field: "number",
            label: "Order No.",
          },
          {
            label: "Buyer",
            field: "",
            sortable: false,
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            accessor: (data: { [p: string]: any }) => {
              const marketDirectOrderViewModel =
                data as MarketDirectOrderViewModel;
              // if order is of type BUY
              if (
                marketDirectOrderViewModel.orderType === DirectOrderType.Buy
              ) {
                // then the initiating party is the buyer
                return (
                  <div className={classes.initiatingPartyCellLayout}>
                    <Typography
                      children={
                        marketDirectOrderViewModel.initiatingPartyClientName
                      }
                    />
                    <InitiatingUserIcon
                      className={classes.initiatingPartyIcon}
                    />
                  </div>
                );
              }
              // otherwise this is a sell order, the counterparty is the seller
              return marketDirectOrderViewModel.counterpartyClientName;
            },
          },
          {
            label: "Seller",
            field: "",
            sortable: false,
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            accessor: (data: { [p: string]: any }) => {
              const marketDirectOrderViewModel =
                data as MarketDirectOrderViewModel;

              // if order is of type SELL
              if (
                marketDirectOrderViewModel.orderType === DirectOrderType.Sell
              ) {
                // then the initiating party is the seller
                return (
                  <div className={classes.initiatingPartyCellLayout}>
                    <Typography
                      children={
                        marketDirectOrderViewModel.initiatingPartyClientName
                      }
                    />
                    <InitiatingUserIcon
                      className={classes.initiatingPartyIcon}
                    />
                  </div>
                );
              }
              // otherwise this is a buy order, the counterparty is the buyer
              return marketDirectOrderViewModel.counterpartyClientName;
            },
          },
          {
            label: "Instr.",
            field: "assetName",
            maxWidth: 400,
          },
          {
            field: "amountIncl.value.float",
            label: "Amount",
            accessor: (data) => {
              const directOrder = data as MarketDirectOrderViewModel;
              return <Amount amount={directOrder.amountIncl} />;
            },
          },
          {
            field: "price.value.float",
            label: "Price",
            accessor: (data) => {
              const directOrder = data as MarketDirectOrderViewModel;
              return <Amount amount={directOrder.price} />;
            },
          },
          {
            field: "feeAmount.value.float",
            label: "Fee",
            accessor: (data) => {
              const directOrder = data as MarketDirectOrderViewModel;
              return directOrder.feeAmount.isUndefined() ? (
                "-"
              ) : (
                <Amount
                  amount={directOrder.feeAmount}
                  formatTextNumOpts={{
                    noDecimalPlaces: 7,
                    addDecimalPadding: false,
                  }}
                />
              );
            },
          },
          {
            field: "vatRate",
            label: "VAT Amount",
            accessor: (data) => {
              return formatTextNum(
                data.feeAmount.value.multipliedBy(data.vatRate),
                {
                  addDecimalPadding: true,
                },
              );
            },
          },
          {
            field: "orderDateTime",
            label: "Time Submitted",
            minWidth: 180,
            accessor: (data) =>
              dayjs((data as MarketDirectOrderViewModel).orderDateTime).format(
                "DD/MM/YYYY HH:mm:ss",
              ),
          },
          {
            label: "State",
            field: "state",
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            accessor: (data: { [p: string]: any }) => {
              const model = data as MarketDirectOrderViewModel;
              if (model.state === DirectOrderState.Failed) {
                return (
                  <Box
                    sx={(theme) => ({
                      display: "flex",
                      gap: theme.spacing(1),
                      alignItems: "center",
                    })}
                  >
                    <DirectOrderStateChip
                      state={(data as MarketDirectOrderViewModel).state}
                    />
                    <Tooltip
                      title={model.failureReasons.join(", ")}
                      placement={"top"}
                    >
                      <ErrorIcon
                        sx={{
                          color: "secondary.light",
                          cursor: "pointer",
                        }}
                      />
                    </Tooltip>
                  </Box>
                );
              }
              return (
                <DirectOrderStateChip
                  state={(data as MarketDirectOrderViewModel).state}
                />
              );
            },
          },
          {
            label: "Resolutions",
            field: "stateResolutionCount",
            accessor: (data) => (
              <Typography sx={{ ml: 3 }}>
                {(data as MarketDirectOrderViewModel).stateResolutionCount
                  ? (data as MarketDirectOrderViewModel).stateResolutionCount
                  : "-"}
              </Typography>
            ),
          },
        ]}
        expandRowComponent={{
          maxHeight: windowHeight / 2,
          component: (selectedRowData) => {
            const marketDirectOrderViewModel =
              selectedRowData as MarketDirectOrderViewModel;
            return (
              <Box sx={{ width: "100%" }}>
                <Accordion>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon color={"primary"} />}
                  >
                    <Typography variant={"h6"} children={"Clearance Account"} />
                  </AccordionSummary>
                  <AccordionDetails>
                    <ClearanceAccount
                      orderNumber={marketDirectOrderViewModel.number}
                      clearanceAccountLedgerID={
                        marketDirectOrderViewModel.clearanceAccountLedgerID
                      }
                    />
                  </AccordionDetails>
                </Accordion>
                <Accordion>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon color={"primary"} />}
                  >
                    <Typography variant={"h6"} children={"Transactions"} />
                  </AccordionSummary>
                  <AccordionDetails>
                    <TransactionTable
                      height={400}
                      title={`Transactions for Subscription Order No ${marketDirectOrderViewModel.number}`}
                      constantCriteria={[
                        newTextExactCriterion(
                          "metadata.directOrderNumber",
                          marketDirectOrderViewModel.number,
                        ),
                      ]}
                    />
                  </AccordionDetails>
                </Accordion>
              </Box>
            );
          },
        }}
      />
    </Root>
  );
}

type ClearanceAccountProps = {
  orderNumber: string;
  clearanceAccountLedgerID: string;
};

function ClearanceAccount(props: ClearanceAccountProps) {
  const {
    views: { stellarAccountViewModelReader },
  } = useAPIContext();
  const isMounted = useIsMounted();
  const { enqueueSnackbar } = useSnackbar();
  const { authContext, viewConfiguration } = useApplicationContext();
  const canViewClearanceAccount =
    viewConfiguration?.Trading?.["Direct Order"]?.ViewClearanceAccount;

  const [clearanceAccountViewModel, setClearanceAccountViewModel] = useState<
    StellarAccountViewModel | undefined
  >(undefined);
  const [txnHistory, setTxnHistory] = useState<
    StellarTransactionHistoryViewModel[]
  >([]);

  const { stellarAccountContextPopulateModelWithLedgerDetails } =
    useStellarContext();

  useEffect(() => {
    (async () => {
      try {
        const readOneRepsonse =
          await stellarAccountViewModelReader.readOneModel(
            new ReadOneModelRequest()
              .setContext(authContext.toFuture())
              .setCriteriaList([
                newTextExactCriterion(
                  "ledgerid",
                  props.clearanceAccountLedgerID,
                ),
              ]),
          );

        // populate account with ledger details
        const retrievedClearanceAccount =
          await stellarAccountContextPopulateModelWithLedgerDetails(
            readOneRepsonse.getModel() ?? new StellarAccountViewModel(),
          );

        const retrievedTxnHistory = (
          await StellarTransactionHistoryViewReader.Read({
            context: authContext,
            criteria: {
              accountID: TextExactCriterion(retrievedClearanceAccount.getId()),
            },
            query: new Query({
              limit: 0,
              offset: 0,
              sorting: [NewSorting("effectID", "desc")],
            }),
          })
        ).models;
        if (isMounted()) {
          setClearanceAccountViewModel(retrievedClearanceAccount);
          setTxnHistory(retrievedTxnHistory);
        }
      } catch (e) {
        console.error(
          `error retrieving clearance account for order ${props.orderNumber}`,
        );
        enqueueSnackbar(
          `error retrieving clearance account for order ${props.orderNumber}`,
          { variant: "error" },
        );
      }
    })();
  }, [
    authContext,
    canViewClearanceAccount,
    props.orderNumber,
    enqueueSnackbar,
  ]);

  const openAccountOnStellarExpert = useCallback(() => {
    if (!clearanceAccountViewModel) {
      return;
    }
    switch (clearanceAccountViewModel.getNetwork()) {
      case Network.STELLAR_TEST_SDF_NETWORK:
        window.open(
          `https://stellar.expert/explorer/testnet/account/${clearanceAccountViewModel.getLedgerid()}`,
          "_blank",
        );
        break;
      case Network.STELLAR_PUBLIC_NETWORK:
        window.open(
          `https://stellar.expert/explorer/public/account/${clearanceAccountViewModel.getLedgerid()}`,
          "_blank",
        );
        break;
    }
  }, [clearanceAccountViewModel]);

  if (canViewClearanceAccount) {
    if (clearanceAccountViewModel) {
      const header = (
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
          }}
        >
          <Typography
            children={`Account #${clearanceAccountViewModel.getNumber()} ${clearanceAccountViewModel.getState()}`}
          />
          {!!txnHistory.length && (
            <Tooltip
              placement="top"
              title="View on Stellar Expert (Blockchain Explorer)"
            >
              <IconButton
                onClick={(e) => {
                  e.stopPropagation();
                  openAccountOnStellarExpert();
                }}
                sx={(theme) => ({ marginRight: theme.spacing(1) })}
                size="small"
              >
                <Launch
                  sx={{
                    fill: "text.secondary",
                  }}
                />
              </IconButton>
            </Tooltip>
          )}
        </Box>
      );

      const txnHistoryTable = (
        <FETable
          disableSelect
          title={"Transaction History"}
          data={txnHistory}
          initialDenseTable
          height={270}
          columns={[
            {
              label: "Description",
              field: "description",
            },
            {
              label: "Operation",
              field: "effectType",
            },
            {
              label: "Amount",
              field: "amount",
              accessor: (data) => {
                return (
                  <Amount
                    amount={(data as StellarTransactionHistoryViewModel).amount}
                  />
                );
              },
            },
          ]}
        />
      );

      switch (clearanceAccountViewModel.getState()) {
        case AccountState.CLOSED_ACCOUNT_STATE:
          return (
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                gap: 1,
              }}
            >
              {header}
              {txnHistory.length ? (
                txnHistoryTable
              ) : (
                <Typography variant={"subtitle1"} children={"Never Opened"} />
              )}
            </Box>
          );

        case AccountState.OPEN_ACCOUNT_STATE:
          return (
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                gap: 1,
              }}
            >
              <Typography children={"Balances"} />
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  gap: 1,
                }}
              >
                {clearanceAccountViewModel.getBalancesList().map((b, idx) => (
                  <Card key={idx}>
                    <CardContent
                      sx={(theme) => ({
                        padding: 1,
                        paddingBottom: `${theme.spacing(1)} !important`,
                        backgroundColor: theme.palette.background.default,
                      })}
                    >
                      <Amount
                        amount={LedgerAmount.fromFutureAmount(b.getAmount())}
                      />
                    </CardContent>
                  </Card>
                ))}
              </Box>
              {txnHistoryTable}
            </Box>
          );

        default:
          return (
            <Typography
              variant={"subtitle1"}
              children={`Unexpected Account State: ${clearanceAccountViewModel.getState()}`}
            />
          );
      }
    } else {
      return (
        <Typography
          variant={"subtitle1"}
          children={"Fetching clearance account..."}
        />
      );
    }
  } else {
    return (
      <Typography
        variant={"subtitle1"}
        children={"Insufficient permissions to view clearance account"}
      />
    );
  }
}

import React, { useEffect, useMemo, useState } from "react";
import { TransactionQueueEntry } from "james/ledger/TransactionQueueEntry";
import { useApplicationContext } from "context/Application/Application";
import {
  TextExactCriterion,
  TextSubstringCriterion,
} from "james/search/criterion";
import { useSearchTransactionQueueEntries } from "james/ledger";
import { Query } from "james/search/query";
import { BPTable } from "components/Table";
import {
  Box,
  IconButton,
  InputAdornment,
  Tooltip,
  Typography,
} from "@mui/material";
import { Clear as ClearIcon, Refresh as ReloadIcon } from "@mui/icons-material";
import { TextField } from "components/FormFields";
import { TransactionTable } from "../Transaction";
import { useIsMounted } from "../../../hooks";
import { useAppNoticeContext } from "context/AppNotice/AppNotice";
import { newTextExactCriterion } from "@mesh/common-js/dist/search";

const initialQuery = new Query({
  limit: 15,
  offset: 0,
  sorting: [],
});

type TransactionQueueEntryTableProps = {
  transactionQueueID: string;
  height?: number;
  collapsible?: boolean;
  initialCollapsedState?: boolean;
};
export function TransactionQueueEntryTable(
  props: TransactionQueueEntryTableProps,
) {
  const isMounted = useIsMounted();
  const { authContext } = useApplicationContext();

  const constantCriteria = useMemo(
    () => ({ queueID: TextExactCriterion(props.transactionQueueID) }),
    [props.transactionQueueID],
  );
  const {
    searchTransactionQueueEntriesResponse,
    loading: readTransactionQueueEntryViewLoading,
    setSearchTransactionQueueEntriesRequest,
    searchTransactionQueueEntriesRequest,
  } = useSearchTransactionQueueEntries({
    context: authContext,
    query: new Query(initialQuery),
    criteria: constantCriteria,
  });
  const [textSearchCriterion, setTextSearchCriterion] = useState("");
  useEffect(() => {
    // prepare new initial criteria
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    let criteria: any = { ...constantCriteria };

    if (textSearchCriterion) {
      criteria = {
        ...criteria,
        $or: [
          { id: TextSubstringCriterion(textSearchCriterion) },
          { key: TextSubstringCriterion(textSearchCriterion) },
          { transactionBatchID: TextSubstringCriterion(textSearchCriterion) },
        ],
      };
    }

    if (isMounted()) {
      setSearchTransactionQueueEntriesRequest({
        context: searchTransactionQueueEntriesRequest.context,
        query: searchTransactionQueueEntriesRequest.query,
        criteria,
      });
    }
  }, [textSearchCriterion]);
  const { NotificationBannerHeight: noticeBannerHeight } =
    useAppNoticeContext();

  return (
    <BPTable
      collapsible={props.collapsible}
      initialCollapsedState={props.initialCollapsedState}
      disableSelect
      initialDenseTable
      loading={readTransactionQueueEntryViewLoading}
      height={
        props.height
          ? props.height - noticeBannerHeight
          : 400 - noticeBannerHeight
      }
      title={`Queue ${props.transactionQueueID}`}
      data={searchTransactionQueueEntriesResponse.records}
      columns={[
        {
          label: "Entries in Queue",
          field: "key",
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          accessor: (selectedRowData: { [key: string]: any }) => {
            const transactionQueueEntry =
              selectedRowData as TransactionQueueEntry;
            return (
              <TransactionTable
                initialDenseTable
                height={200}
                title={
                  <Box
                    sx={(theme) => ({
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                      justifyItems: "center",
                      gap: theme.spacing(1),
                    })}
                  >
                    <Typography>{transactionQueueEntry.key}</Typography>
                  </Box>
                }
                constantCriteria={[
                  newTextExactCriterion(
                    "metadata.transactionQueueEntryID",
                    transactionQueueEntry.id,
                  ),
                ]}
              />
            );
          },
        },
      ]}
      query={searchTransactionQueueEntriesRequest.query}
      onQueryChange={(query: Query) =>
        setSearchTransactionQueueEntriesRequest({
          ...searchTransactionQueueEntriesRequest,
          query,
        })
      }
      totalNoRecords={searchTransactionQueueEntriesResponse.total}
      toolBarControls={(() => {
        const controls: React.ReactNode[] = [];

        controls.push(
          <Tooltip title={"Refresh"}>
            <span>
              <IconButton
                onClick={() =>
                  setSearchTransactionQueueEntriesRequest({
                    ...searchTransactionQueueEntriesRequest,
                    query: new Query(initialQuery),
                  })
                }
                id={"financialTransactionQueueEntriesTable-refresh-iconButton"}
                disabled={readTransactionQueueEntryViewLoading}
                size={"small"}
              >
                <ReloadIcon />
              </IconButton>
            </span>
          </Tooltip>,
        );

        return controls;
      })()}
      filters={[
        <TextField
          id={"financialTransactionQueueEntriesTable-textFieldSearch-textField"}
          variant="outlined"
          margin="dense"
          sx={{ width: 400 }}
          label="Search Text Fields"
          placeholder="Start Typing..."
          InputLabelProps={{ shrink: true }}
          InputProps={{
            endAdornment: textSearchCriterion ? (
              <InputAdornment
                position="end"
                children={
                  <IconButton
                    size="small"
                    onClick={() => setTextSearchCriterion("")}
                  >
                    <ClearIcon />
                  </IconButton>
                }
              />
            ) : undefined,
          }}
          value={textSearchCriterion}
          onChange={(e) => setTextSearchCriterion(e.target.value)}
        />,
      ]}
    />
  );
}

/**
 * @fileoverview gRPC-Web generated client stub for market
 * @enhanceable
 * @public
 */

// Code generated by protoc-gen-grpc-web. DO NOT EDIT.
// versions:
// 	protoc-gen-grpc-web v1.5.0
// 	protoc              v5.28.0
// source: market/assetTransferrer.proto


/* eslint-disable */
// @ts-nocheck



const grpc = {};
grpc.web = require('grpc-web');


var security_context_pb = require('../security/context_pb.js')

var ledger_futureAmount_pb = require('../ledger/futureAmount_pb.js')
const proto = {};
proto.market = require('./assetTransferrer_pb.js');

/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?grpc.web.ClientOptions} options
 * @constructor
 * @struct
 * @final
 */
proto.market.AssetTransferrerClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options.format = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname.replace(/\/+$/, '');

};


/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?grpc.web.ClientOptions} options
 * @constructor
 * @struct
 * @final
 */
proto.market.AssetTransferrerPromiseClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options.format = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname.replace(/\/+$/, '');

};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.market.TransferAssetRequest,
 *   !proto.market.TransferAssetResponse>}
 */
const methodDescriptor_AssetTransferrer_TransferAsset = new grpc.web.MethodDescriptor(
  '/market.AssetTransferrer/TransferAsset',
  grpc.web.MethodType.UNARY,
  proto.market.TransferAssetRequest,
  proto.market.TransferAssetResponse,
  /**
   * @param {!proto.market.TransferAssetRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.market.TransferAssetResponse.deserializeBinary
);


/**
 * @param {!proto.market.TransferAssetRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.market.TransferAssetResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.market.TransferAssetResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.market.AssetTransferrerClient.prototype.transferAsset =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/market.AssetTransferrer/TransferAsset',
      request,
      metadata || {},
      methodDescriptor_AssetTransferrer_TransferAsset,
      callback);
};


/**
 * @param {!proto.market.TransferAssetRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.market.TransferAssetResponse>}
 *     Promise that resolves to the response
 */
proto.market.AssetTransferrerPromiseClient.prototype.transferAsset =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/market.AssetTransferrer/TransferAsset',
      request,
      metadata || {},
      methodDescriptor_AssetTransferrer_TransferAsset);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.market.TransferAssetOffPlatformRequest,
 *   !proto.market.TransferAssetOffPlatformResponse>}
 */
const methodDescriptor_AssetTransferrer_TransferAssetOffPlatform = new grpc.web.MethodDescriptor(
  '/market.AssetTransferrer/TransferAssetOffPlatform',
  grpc.web.MethodType.UNARY,
  proto.market.TransferAssetOffPlatformRequest,
  proto.market.TransferAssetOffPlatformResponse,
  /**
   * @param {!proto.market.TransferAssetOffPlatformRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.market.TransferAssetOffPlatformResponse.deserializeBinary
);


/**
 * @param {!proto.market.TransferAssetOffPlatformRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.market.TransferAssetOffPlatformResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.market.TransferAssetOffPlatformResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.market.AssetTransferrerClient.prototype.transferAssetOffPlatform =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/market.AssetTransferrer/TransferAssetOffPlatform',
      request,
      metadata || {},
      methodDescriptor_AssetTransferrer_TransferAssetOffPlatform,
      callback);
};


/**
 * @param {!proto.market.TransferAssetOffPlatformRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.market.TransferAssetOffPlatformResponse>}
 *     Promise that resolves to the response
 */
proto.market.AssetTransferrerPromiseClient.prototype.transferAssetOffPlatform =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/market.AssetTransferrer/TransferAssetOffPlatform',
      request,
      metadata || {},
      methodDescriptor_AssetTransferrer_TransferAssetOffPlatform);
};


module.exports = proto.market;


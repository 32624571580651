import React from "react";
import { Box, Theme, useMediaQuery } from "@mui/material";
import meshMiniLogo from "assets/images/logo/meshLogo.svg";
import { ClientSelectorCard } from "./components/ClientSelectorCard";
import background from "assets/images/background/background.png";
import { useAppNoticeContext } from "context/AppNotice/AppNotice";

export const ClientSelector = () => {
  const smUp = useMediaQuery((theme: Theme) => theme.breakpoints.up("sm"));
  const { NotificationBannerHeight } = useAppNoticeContext();

  return (
    <>
      {smUp ? (
        <Box
          sx={{
            position: "relative",
            display: "flex",
            flexDirection: "column",
            backgroundImage: `url(${background})`,
            backgroundSize: "cover",
            height: `calc(100vh - ${NotificationBannerHeight}px)`,
            overflowY: {
              xs: "auto",
              md: "hidden",
            },
            width: "100%",
          }}
        >
          <Box
            sx={{
              width: 168,
              height: 77,
              position: "fixed",
            }}
          >
            <img alt="" src={meshMiniLogo} />
          </Box>
          <Box
            className="meshScroll"
            id={"sign_up"}
            sx={{
              height: "100%",
              display: "grid",
              gridTemplateRows: `
                "a"
                "b"
                "c"
              `,
              justifyContent: "center",
              minWidth: "100vw",
              overflowY: "auto",
            }}
          >
            <Box
              sx={{
                gridArea: "b",
                pb: 2,
                pt: 4,
              }}
            >
              <ClientSelectorCard />
            </Box>
          </Box>
        </Box>
      ) : (
        <>
          <ClientSelectorCard />
        </>
      )}
    </>
  );
};

// source: marketing/instrumentMarketingContentUpdater.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global =
    (typeof globalThis !== 'undefined' && globalThis) ||
    (typeof window !== 'undefined' && window) ||
    (typeof global !== 'undefined' && global) ||
    (typeof self !== 'undefined' && self) ||
    (function () { return this; }).call(null) ||
    Function('return this')();

var marketing_instrumentMarketingContent_pb = require('../marketing/instrumentMarketingContent_pb.js');
goog.object.extend(proto, marketing_instrumentMarketingContent_pb);
var marketing_socialMediaLinks_pb = require('../marketing/socialMediaLinks_pb.js');
goog.object.extend(proto, marketing_socialMediaLinks_pb);
var marketing_independentReview_pb = require('../marketing/independentReview_pb.js');
goog.object.extend(proto, marketing_independentReview_pb);
var marketing_mediaEntry_pb = require('../marketing/mediaEntry_pb.js');
goog.object.extend(proto, marketing_mediaEntry_pb);
var marketing_newsArticle_pb = require('../marketing/newsArticle_pb.js');
goog.object.extend(proto, marketing_newsArticle_pb);
var security_context_pb = require('../security/context_pb.js');
goog.object.extend(proto, security_context_pb);
goog.exportSymbol('proto.marketing.UpdateIndependentReviewsRequest', null, global);
goog.exportSymbol('proto.marketing.UpdateIndependentReviewsResponse', null, global);
goog.exportSymbol('proto.marketing.UpdateInvestmentObjectiveRequest', null, global);
goog.exportSymbol('proto.marketing.UpdateInvestmentObjectiveResponse', null, global);
goog.exportSymbol('proto.marketing.UpdateMediaEntriesRequest', null, global);
goog.exportSymbol('proto.marketing.UpdateMediaEntriesResponse', null, global);
goog.exportSymbol('proto.marketing.UpdateNewsArticlesRequest', null, global);
goog.exportSymbol('proto.marketing.UpdateNewsArticlesResponse', null, global);
goog.exportSymbol('proto.marketing.UpdateSocialMediaLinksRequest', null, global);
goog.exportSymbol('proto.marketing.UpdateSocialMediaLinksResponse', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.marketing.UpdateInvestmentObjectiveRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.marketing.UpdateInvestmentObjectiveRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.marketing.UpdateInvestmentObjectiveRequest.displayName = 'proto.marketing.UpdateInvestmentObjectiveRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.marketing.UpdateInvestmentObjectiveResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.marketing.UpdateInvestmentObjectiveResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.marketing.UpdateInvestmentObjectiveResponse.displayName = 'proto.marketing.UpdateInvestmentObjectiveResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.marketing.UpdateSocialMediaLinksRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.marketing.UpdateSocialMediaLinksRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.marketing.UpdateSocialMediaLinksRequest.displayName = 'proto.marketing.UpdateSocialMediaLinksRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.marketing.UpdateSocialMediaLinksResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.marketing.UpdateSocialMediaLinksResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.marketing.UpdateSocialMediaLinksResponse.displayName = 'proto.marketing.UpdateSocialMediaLinksResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.marketing.UpdateNewsArticlesRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.marketing.UpdateNewsArticlesRequest.repeatedFields_, null);
};
goog.inherits(proto.marketing.UpdateNewsArticlesRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.marketing.UpdateNewsArticlesRequest.displayName = 'proto.marketing.UpdateNewsArticlesRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.marketing.UpdateNewsArticlesResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.marketing.UpdateNewsArticlesResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.marketing.UpdateNewsArticlesResponse.displayName = 'proto.marketing.UpdateNewsArticlesResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.marketing.UpdateMediaEntriesRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.marketing.UpdateMediaEntriesRequest.repeatedFields_, null);
};
goog.inherits(proto.marketing.UpdateMediaEntriesRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.marketing.UpdateMediaEntriesRequest.displayName = 'proto.marketing.UpdateMediaEntriesRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.marketing.UpdateMediaEntriesResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.marketing.UpdateMediaEntriesResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.marketing.UpdateMediaEntriesResponse.displayName = 'proto.marketing.UpdateMediaEntriesResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.marketing.UpdateIndependentReviewsRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.marketing.UpdateIndependentReviewsRequest.repeatedFields_, null);
};
goog.inherits(proto.marketing.UpdateIndependentReviewsRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.marketing.UpdateIndependentReviewsRequest.displayName = 'proto.marketing.UpdateIndependentReviewsRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.marketing.UpdateIndependentReviewsResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.marketing.UpdateIndependentReviewsResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.marketing.UpdateIndependentReviewsResponse.displayName = 'proto.marketing.UpdateIndependentReviewsResponse';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.marketing.UpdateInvestmentObjectiveRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.marketing.UpdateInvestmentObjectiveRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.marketing.UpdateInvestmentObjectiveRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.marketing.UpdateInvestmentObjectiveRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
context: (f = msg.getContext()) && security_context_pb.ContextFuture.toObject(includeInstance, f),
instrumentmarketingcontentid: jspb.Message.getFieldWithDefault(msg, 2, ""),
investmentobjective: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.marketing.UpdateInvestmentObjectiveRequest}
 */
proto.marketing.UpdateInvestmentObjectiveRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.marketing.UpdateInvestmentObjectiveRequest;
  return proto.marketing.UpdateInvestmentObjectiveRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.marketing.UpdateInvestmentObjectiveRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.marketing.UpdateInvestmentObjectiveRequest}
 */
proto.marketing.UpdateInvestmentObjectiveRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new security_context_pb.ContextFuture;
      reader.readMessage(value,security_context_pb.ContextFuture.deserializeBinaryFromReader);
      msg.setContext(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setInstrumentmarketingcontentid(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setInvestmentobjective(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.marketing.UpdateInvestmentObjectiveRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.marketing.UpdateInvestmentObjectiveRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.marketing.UpdateInvestmentObjectiveRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.marketing.UpdateInvestmentObjectiveRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getContext();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      security_context_pb.ContextFuture.serializeBinaryToWriter
    );
  }
  f = message.getInstrumentmarketingcontentid();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getInvestmentobjective();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional security.ContextFuture context = 1;
 * @return {?proto.security.ContextFuture}
 */
proto.marketing.UpdateInvestmentObjectiveRequest.prototype.getContext = function() {
  return /** @type{?proto.security.ContextFuture} */ (
    jspb.Message.getWrapperField(this, security_context_pb.ContextFuture, 1));
};


/**
 * @param {?proto.security.ContextFuture|undefined} value
 * @return {!proto.marketing.UpdateInvestmentObjectiveRequest} returns this
*/
proto.marketing.UpdateInvestmentObjectiveRequest.prototype.setContext = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.marketing.UpdateInvestmentObjectiveRequest} returns this
 */
proto.marketing.UpdateInvestmentObjectiveRequest.prototype.clearContext = function() {
  return this.setContext(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.marketing.UpdateInvestmentObjectiveRequest.prototype.hasContext = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional string instrumentMarketingContentID = 2;
 * @return {string}
 */
proto.marketing.UpdateInvestmentObjectiveRequest.prototype.getInstrumentmarketingcontentid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.marketing.UpdateInvestmentObjectiveRequest} returns this
 */
proto.marketing.UpdateInvestmentObjectiveRequest.prototype.setInstrumentmarketingcontentid = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string investmentObjective = 3;
 * @return {string}
 */
proto.marketing.UpdateInvestmentObjectiveRequest.prototype.getInvestmentobjective = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.marketing.UpdateInvestmentObjectiveRequest} returns this
 */
proto.marketing.UpdateInvestmentObjectiveRequest.prototype.setInvestmentobjective = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.marketing.UpdateInvestmentObjectiveResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.marketing.UpdateInvestmentObjectiveResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.marketing.UpdateInvestmentObjectiveResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.marketing.UpdateInvestmentObjectiveResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
instrumentmarketingcontent: (f = msg.getInstrumentmarketingcontent()) && marketing_instrumentMarketingContent_pb.InstrumentMarketingContent.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.marketing.UpdateInvestmentObjectiveResponse}
 */
proto.marketing.UpdateInvestmentObjectiveResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.marketing.UpdateInvestmentObjectiveResponse;
  return proto.marketing.UpdateInvestmentObjectiveResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.marketing.UpdateInvestmentObjectiveResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.marketing.UpdateInvestmentObjectiveResponse}
 */
proto.marketing.UpdateInvestmentObjectiveResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new marketing_instrumentMarketingContent_pb.InstrumentMarketingContent;
      reader.readMessage(value,marketing_instrumentMarketingContent_pb.InstrumentMarketingContent.deserializeBinaryFromReader);
      msg.setInstrumentmarketingcontent(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.marketing.UpdateInvestmentObjectiveResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.marketing.UpdateInvestmentObjectiveResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.marketing.UpdateInvestmentObjectiveResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.marketing.UpdateInvestmentObjectiveResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getInstrumentmarketingcontent();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      marketing_instrumentMarketingContent_pb.InstrumentMarketingContent.serializeBinaryToWriter
    );
  }
};


/**
 * optional InstrumentMarketingContent instrumentMarketingContent = 1;
 * @return {?proto.marketing.InstrumentMarketingContent}
 */
proto.marketing.UpdateInvestmentObjectiveResponse.prototype.getInstrumentmarketingcontent = function() {
  return /** @type{?proto.marketing.InstrumentMarketingContent} */ (
    jspb.Message.getWrapperField(this, marketing_instrumentMarketingContent_pb.InstrumentMarketingContent, 1));
};


/**
 * @param {?proto.marketing.InstrumentMarketingContent|undefined} value
 * @return {!proto.marketing.UpdateInvestmentObjectiveResponse} returns this
*/
proto.marketing.UpdateInvestmentObjectiveResponse.prototype.setInstrumentmarketingcontent = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.marketing.UpdateInvestmentObjectiveResponse} returns this
 */
proto.marketing.UpdateInvestmentObjectiveResponse.prototype.clearInstrumentmarketingcontent = function() {
  return this.setInstrumentmarketingcontent(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.marketing.UpdateInvestmentObjectiveResponse.prototype.hasInstrumentmarketingcontent = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.marketing.UpdateSocialMediaLinksRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.marketing.UpdateSocialMediaLinksRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.marketing.UpdateSocialMediaLinksRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.marketing.UpdateSocialMediaLinksRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
context: (f = msg.getContext()) && security_context_pb.ContextFuture.toObject(includeInstance, f),
instrumentmarketingcontentid: jspb.Message.getFieldWithDefault(msg, 2, ""),
socialmedialinks: (f = msg.getSocialmedialinks()) && marketing_socialMediaLinks_pb.SocialMediaLinks.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.marketing.UpdateSocialMediaLinksRequest}
 */
proto.marketing.UpdateSocialMediaLinksRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.marketing.UpdateSocialMediaLinksRequest;
  return proto.marketing.UpdateSocialMediaLinksRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.marketing.UpdateSocialMediaLinksRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.marketing.UpdateSocialMediaLinksRequest}
 */
proto.marketing.UpdateSocialMediaLinksRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new security_context_pb.ContextFuture;
      reader.readMessage(value,security_context_pb.ContextFuture.deserializeBinaryFromReader);
      msg.setContext(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setInstrumentmarketingcontentid(value);
      break;
    case 3:
      var value = new marketing_socialMediaLinks_pb.SocialMediaLinks;
      reader.readMessage(value,marketing_socialMediaLinks_pb.SocialMediaLinks.deserializeBinaryFromReader);
      msg.setSocialmedialinks(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.marketing.UpdateSocialMediaLinksRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.marketing.UpdateSocialMediaLinksRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.marketing.UpdateSocialMediaLinksRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.marketing.UpdateSocialMediaLinksRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getContext();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      security_context_pb.ContextFuture.serializeBinaryToWriter
    );
  }
  f = message.getInstrumentmarketingcontentid();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getSocialmedialinks();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      marketing_socialMediaLinks_pb.SocialMediaLinks.serializeBinaryToWriter
    );
  }
};


/**
 * optional security.ContextFuture context = 1;
 * @return {?proto.security.ContextFuture}
 */
proto.marketing.UpdateSocialMediaLinksRequest.prototype.getContext = function() {
  return /** @type{?proto.security.ContextFuture} */ (
    jspb.Message.getWrapperField(this, security_context_pb.ContextFuture, 1));
};


/**
 * @param {?proto.security.ContextFuture|undefined} value
 * @return {!proto.marketing.UpdateSocialMediaLinksRequest} returns this
*/
proto.marketing.UpdateSocialMediaLinksRequest.prototype.setContext = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.marketing.UpdateSocialMediaLinksRequest} returns this
 */
proto.marketing.UpdateSocialMediaLinksRequest.prototype.clearContext = function() {
  return this.setContext(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.marketing.UpdateSocialMediaLinksRequest.prototype.hasContext = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional string instrumentMarketingContentID = 2;
 * @return {string}
 */
proto.marketing.UpdateSocialMediaLinksRequest.prototype.getInstrumentmarketingcontentid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.marketing.UpdateSocialMediaLinksRequest} returns this
 */
proto.marketing.UpdateSocialMediaLinksRequest.prototype.setInstrumentmarketingcontentid = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional SocialMediaLinks socialMediaLinks = 3;
 * @return {?proto.marketing.SocialMediaLinks}
 */
proto.marketing.UpdateSocialMediaLinksRequest.prototype.getSocialmedialinks = function() {
  return /** @type{?proto.marketing.SocialMediaLinks} */ (
    jspb.Message.getWrapperField(this, marketing_socialMediaLinks_pb.SocialMediaLinks, 3));
};


/**
 * @param {?proto.marketing.SocialMediaLinks|undefined} value
 * @return {!proto.marketing.UpdateSocialMediaLinksRequest} returns this
*/
proto.marketing.UpdateSocialMediaLinksRequest.prototype.setSocialmedialinks = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.marketing.UpdateSocialMediaLinksRequest} returns this
 */
proto.marketing.UpdateSocialMediaLinksRequest.prototype.clearSocialmedialinks = function() {
  return this.setSocialmedialinks(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.marketing.UpdateSocialMediaLinksRequest.prototype.hasSocialmedialinks = function() {
  return jspb.Message.getField(this, 3) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.marketing.UpdateSocialMediaLinksResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.marketing.UpdateSocialMediaLinksResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.marketing.UpdateSocialMediaLinksResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.marketing.UpdateSocialMediaLinksResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
instrumentmarketingcontent: (f = msg.getInstrumentmarketingcontent()) && marketing_instrumentMarketingContent_pb.InstrumentMarketingContent.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.marketing.UpdateSocialMediaLinksResponse}
 */
proto.marketing.UpdateSocialMediaLinksResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.marketing.UpdateSocialMediaLinksResponse;
  return proto.marketing.UpdateSocialMediaLinksResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.marketing.UpdateSocialMediaLinksResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.marketing.UpdateSocialMediaLinksResponse}
 */
proto.marketing.UpdateSocialMediaLinksResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new marketing_instrumentMarketingContent_pb.InstrumentMarketingContent;
      reader.readMessage(value,marketing_instrumentMarketingContent_pb.InstrumentMarketingContent.deserializeBinaryFromReader);
      msg.setInstrumentmarketingcontent(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.marketing.UpdateSocialMediaLinksResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.marketing.UpdateSocialMediaLinksResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.marketing.UpdateSocialMediaLinksResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.marketing.UpdateSocialMediaLinksResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getInstrumentmarketingcontent();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      marketing_instrumentMarketingContent_pb.InstrumentMarketingContent.serializeBinaryToWriter
    );
  }
};


/**
 * optional InstrumentMarketingContent instrumentMarketingContent = 1;
 * @return {?proto.marketing.InstrumentMarketingContent}
 */
proto.marketing.UpdateSocialMediaLinksResponse.prototype.getInstrumentmarketingcontent = function() {
  return /** @type{?proto.marketing.InstrumentMarketingContent} */ (
    jspb.Message.getWrapperField(this, marketing_instrumentMarketingContent_pb.InstrumentMarketingContent, 1));
};


/**
 * @param {?proto.marketing.InstrumentMarketingContent|undefined} value
 * @return {!proto.marketing.UpdateSocialMediaLinksResponse} returns this
*/
proto.marketing.UpdateSocialMediaLinksResponse.prototype.setInstrumentmarketingcontent = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.marketing.UpdateSocialMediaLinksResponse} returns this
 */
proto.marketing.UpdateSocialMediaLinksResponse.prototype.clearInstrumentmarketingcontent = function() {
  return this.setInstrumentmarketingcontent(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.marketing.UpdateSocialMediaLinksResponse.prototype.hasInstrumentmarketingcontent = function() {
  return jspb.Message.getField(this, 1) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.marketing.UpdateNewsArticlesRequest.repeatedFields_ = [3];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.marketing.UpdateNewsArticlesRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.marketing.UpdateNewsArticlesRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.marketing.UpdateNewsArticlesRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.marketing.UpdateNewsArticlesRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
context: (f = msg.getContext()) && security_context_pb.ContextFuture.toObject(includeInstance, f),
instrumentmarketingcontentid: jspb.Message.getFieldWithDefault(msg, 2, ""),
newsarticlesList: jspb.Message.toObjectList(msg.getNewsarticlesList(),
    marketing_newsArticle_pb.NewsArticle.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.marketing.UpdateNewsArticlesRequest}
 */
proto.marketing.UpdateNewsArticlesRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.marketing.UpdateNewsArticlesRequest;
  return proto.marketing.UpdateNewsArticlesRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.marketing.UpdateNewsArticlesRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.marketing.UpdateNewsArticlesRequest}
 */
proto.marketing.UpdateNewsArticlesRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new security_context_pb.ContextFuture;
      reader.readMessage(value,security_context_pb.ContextFuture.deserializeBinaryFromReader);
      msg.setContext(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setInstrumentmarketingcontentid(value);
      break;
    case 3:
      var value = new marketing_newsArticle_pb.NewsArticle;
      reader.readMessage(value,marketing_newsArticle_pb.NewsArticle.deserializeBinaryFromReader);
      msg.addNewsarticles(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.marketing.UpdateNewsArticlesRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.marketing.UpdateNewsArticlesRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.marketing.UpdateNewsArticlesRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.marketing.UpdateNewsArticlesRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getContext();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      security_context_pb.ContextFuture.serializeBinaryToWriter
    );
  }
  f = message.getInstrumentmarketingcontentid();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getNewsarticlesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      3,
      f,
      marketing_newsArticle_pb.NewsArticle.serializeBinaryToWriter
    );
  }
};


/**
 * optional security.ContextFuture context = 1;
 * @return {?proto.security.ContextFuture}
 */
proto.marketing.UpdateNewsArticlesRequest.prototype.getContext = function() {
  return /** @type{?proto.security.ContextFuture} */ (
    jspb.Message.getWrapperField(this, security_context_pb.ContextFuture, 1));
};


/**
 * @param {?proto.security.ContextFuture|undefined} value
 * @return {!proto.marketing.UpdateNewsArticlesRequest} returns this
*/
proto.marketing.UpdateNewsArticlesRequest.prototype.setContext = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.marketing.UpdateNewsArticlesRequest} returns this
 */
proto.marketing.UpdateNewsArticlesRequest.prototype.clearContext = function() {
  return this.setContext(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.marketing.UpdateNewsArticlesRequest.prototype.hasContext = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional string instrumentMarketingContentID = 2;
 * @return {string}
 */
proto.marketing.UpdateNewsArticlesRequest.prototype.getInstrumentmarketingcontentid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.marketing.UpdateNewsArticlesRequest} returns this
 */
proto.marketing.UpdateNewsArticlesRequest.prototype.setInstrumentmarketingcontentid = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * repeated NewsArticle newsArticles = 3;
 * @return {!Array<!proto.marketing.NewsArticle>}
 */
proto.marketing.UpdateNewsArticlesRequest.prototype.getNewsarticlesList = function() {
  return /** @type{!Array<!proto.marketing.NewsArticle>} */ (
    jspb.Message.getRepeatedWrapperField(this, marketing_newsArticle_pb.NewsArticle, 3));
};


/**
 * @param {!Array<!proto.marketing.NewsArticle>} value
 * @return {!proto.marketing.UpdateNewsArticlesRequest} returns this
*/
proto.marketing.UpdateNewsArticlesRequest.prototype.setNewsarticlesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 3, value);
};


/**
 * @param {!proto.marketing.NewsArticle=} opt_value
 * @param {number=} opt_index
 * @return {!proto.marketing.NewsArticle}
 */
proto.marketing.UpdateNewsArticlesRequest.prototype.addNewsarticles = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 3, opt_value, proto.marketing.NewsArticle, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.marketing.UpdateNewsArticlesRequest} returns this
 */
proto.marketing.UpdateNewsArticlesRequest.prototype.clearNewsarticlesList = function() {
  return this.setNewsarticlesList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.marketing.UpdateNewsArticlesResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.marketing.UpdateNewsArticlesResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.marketing.UpdateNewsArticlesResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.marketing.UpdateNewsArticlesResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
instrumentmarketingcontent: (f = msg.getInstrumentmarketingcontent()) && marketing_instrumentMarketingContent_pb.InstrumentMarketingContent.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.marketing.UpdateNewsArticlesResponse}
 */
proto.marketing.UpdateNewsArticlesResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.marketing.UpdateNewsArticlesResponse;
  return proto.marketing.UpdateNewsArticlesResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.marketing.UpdateNewsArticlesResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.marketing.UpdateNewsArticlesResponse}
 */
proto.marketing.UpdateNewsArticlesResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new marketing_instrumentMarketingContent_pb.InstrumentMarketingContent;
      reader.readMessage(value,marketing_instrumentMarketingContent_pb.InstrumentMarketingContent.deserializeBinaryFromReader);
      msg.setInstrumentmarketingcontent(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.marketing.UpdateNewsArticlesResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.marketing.UpdateNewsArticlesResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.marketing.UpdateNewsArticlesResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.marketing.UpdateNewsArticlesResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getInstrumentmarketingcontent();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      marketing_instrumentMarketingContent_pb.InstrumentMarketingContent.serializeBinaryToWriter
    );
  }
};


/**
 * optional InstrumentMarketingContent instrumentMarketingContent = 1;
 * @return {?proto.marketing.InstrumentMarketingContent}
 */
proto.marketing.UpdateNewsArticlesResponse.prototype.getInstrumentmarketingcontent = function() {
  return /** @type{?proto.marketing.InstrumentMarketingContent} */ (
    jspb.Message.getWrapperField(this, marketing_instrumentMarketingContent_pb.InstrumentMarketingContent, 1));
};


/**
 * @param {?proto.marketing.InstrumentMarketingContent|undefined} value
 * @return {!proto.marketing.UpdateNewsArticlesResponse} returns this
*/
proto.marketing.UpdateNewsArticlesResponse.prototype.setInstrumentmarketingcontent = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.marketing.UpdateNewsArticlesResponse} returns this
 */
proto.marketing.UpdateNewsArticlesResponse.prototype.clearInstrumentmarketingcontent = function() {
  return this.setInstrumentmarketingcontent(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.marketing.UpdateNewsArticlesResponse.prototype.hasInstrumentmarketingcontent = function() {
  return jspb.Message.getField(this, 1) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.marketing.UpdateMediaEntriesRequest.repeatedFields_ = [3];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.marketing.UpdateMediaEntriesRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.marketing.UpdateMediaEntriesRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.marketing.UpdateMediaEntriesRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.marketing.UpdateMediaEntriesRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
context: (f = msg.getContext()) && security_context_pb.ContextFuture.toObject(includeInstance, f),
instrumentmarketingcontentid: jspb.Message.getFieldWithDefault(msg, 2, ""),
mediaentriesList: jspb.Message.toObjectList(msg.getMediaentriesList(),
    marketing_mediaEntry_pb.MediaEntry.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.marketing.UpdateMediaEntriesRequest}
 */
proto.marketing.UpdateMediaEntriesRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.marketing.UpdateMediaEntriesRequest;
  return proto.marketing.UpdateMediaEntriesRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.marketing.UpdateMediaEntriesRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.marketing.UpdateMediaEntriesRequest}
 */
proto.marketing.UpdateMediaEntriesRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new security_context_pb.ContextFuture;
      reader.readMessage(value,security_context_pb.ContextFuture.deserializeBinaryFromReader);
      msg.setContext(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setInstrumentmarketingcontentid(value);
      break;
    case 3:
      var value = new marketing_mediaEntry_pb.MediaEntry;
      reader.readMessage(value,marketing_mediaEntry_pb.MediaEntry.deserializeBinaryFromReader);
      msg.addMediaentries(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.marketing.UpdateMediaEntriesRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.marketing.UpdateMediaEntriesRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.marketing.UpdateMediaEntriesRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.marketing.UpdateMediaEntriesRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getContext();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      security_context_pb.ContextFuture.serializeBinaryToWriter
    );
  }
  f = message.getInstrumentmarketingcontentid();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getMediaentriesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      3,
      f,
      marketing_mediaEntry_pb.MediaEntry.serializeBinaryToWriter
    );
  }
};


/**
 * optional security.ContextFuture context = 1;
 * @return {?proto.security.ContextFuture}
 */
proto.marketing.UpdateMediaEntriesRequest.prototype.getContext = function() {
  return /** @type{?proto.security.ContextFuture} */ (
    jspb.Message.getWrapperField(this, security_context_pb.ContextFuture, 1));
};


/**
 * @param {?proto.security.ContextFuture|undefined} value
 * @return {!proto.marketing.UpdateMediaEntriesRequest} returns this
*/
proto.marketing.UpdateMediaEntriesRequest.prototype.setContext = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.marketing.UpdateMediaEntriesRequest} returns this
 */
proto.marketing.UpdateMediaEntriesRequest.prototype.clearContext = function() {
  return this.setContext(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.marketing.UpdateMediaEntriesRequest.prototype.hasContext = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional string instrumentMarketingContentID = 2;
 * @return {string}
 */
proto.marketing.UpdateMediaEntriesRequest.prototype.getInstrumentmarketingcontentid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.marketing.UpdateMediaEntriesRequest} returns this
 */
proto.marketing.UpdateMediaEntriesRequest.prototype.setInstrumentmarketingcontentid = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * repeated MediaEntry mediaEntries = 3;
 * @return {!Array<!proto.marketing.MediaEntry>}
 */
proto.marketing.UpdateMediaEntriesRequest.prototype.getMediaentriesList = function() {
  return /** @type{!Array<!proto.marketing.MediaEntry>} */ (
    jspb.Message.getRepeatedWrapperField(this, marketing_mediaEntry_pb.MediaEntry, 3));
};


/**
 * @param {!Array<!proto.marketing.MediaEntry>} value
 * @return {!proto.marketing.UpdateMediaEntriesRequest} returns this
*/
proto.marketing.UpdateMediaEntriesRequest.prototype.setMediaentriesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 3, value);
};


/**
 * @param {!proto.marketing.MediaEntry=} opt_value
 * @param {number=} opt_index
 * @return {!proto.marketing.MediaEntry}
 */
proto.marketing.UpdateMediaEntriesRequest.prototype.addMediaentries = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 3, opt_value, proto.marketing.MediaEntry, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.marketing.UpdateMediaEntriesRequest} returns this
 */
proto.marketing.UpdateMediaEntriesRequest.prototype.clearMediaentriesList = function() {
  return this.setMediaentriesList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.marketing.UpdateMediaEntriesResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.marketing.UpdateMediaEntriesResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.marketing.UpdateMediaEntriesResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.marketing.UpdateMediaEntriesResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
instrumentmarketingcontent: (f = msg.getInstrumentmarketingcontent()) && marketing_instrumentMarketingContent_pb.InstrumentMarketingContent.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.marketing.UpdateMediaEntriesResponse}
 */
proto.marketing.UpdateMediaEntriesResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.marketing.UpdateMediaEntriesResponse;
  return proto.marketing.UpdateMediaEntriesResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.marketing.UpdateMediaEntriesResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.marketing.UpdateMediaEntriesResponse}
 */
proto.marketing.UpdateMediaEntriesResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new marketing_instrumentMarketingContent_pb.InstrumentMarketingContent;
      reader.readMessage(value,marketing_instrumentMarketingContent_pb.InstrumentMarketingContent.deserializeBinaryFromReader);
      msg.setInstrumentmarketingcontent(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.marketing.UpdateMediaEntriesResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.marketing.UpdateMediaEntriesResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.marketing.UpdateMediaEntriesResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.marketing.UpdateMediaEntriesResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getInstrumentmarketingcontent();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      marketing_instrumentMarketingContent_pb.InstrumentMarketingContent.serializeBinaryToWriter
    );
  }
};


/**
 * optional InstrumentMarketingContent instrumentMarketingContent = 1;
 * @return {?proto.marketing.InstrumentMarketingContent}
 */
proto.marketing.UpdateMediaEntriesResponse.prototype.getInstrumentmarketingcontent = function() {
  return /** @type{?proto.marketing.InstrumentMarketingContent} */ (
    jspb.Message.getWrapperField(this, marketing_instrumentMarketingContent_pb.InstrumentMarketingContent, 1));
};


/**
 * @param {?proto.marketing.InstrumentMarketingContent|undefined} value
 * @return {!proto.marketing.UpdateMediaEntriesResponse} returns this
*/
proto.marketing.UpdateMediaEntriesResponse.prototype.setInstrumentmarketingcontent = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.marketing.UpdateMediaEntriesResponse} returns this
 */
proto.marketing.UpdateMediaEntriesResponse.prototype.clearInstrumentmarketingcontent = function() {
  return this.setInstrumentmarketingcontent(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.marketing.UpdateMediaEntriesResponse.prototype.hasInstrumentmarketingcontent = function() {
  return jspb.Message.getField(this, 1) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.marketing.UpdateIndependentReviewsRequest.repeatedFields_ = [3];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.marketing.UpdateIndependentReviewsRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.marketing.UpdateIndependentReviewsRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.marketing.UpdateIndependentReviewsRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.marketing.UpdateIndependentReviewsRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
context: (f = msg.getContext()) && security_context_pb.ContextFuture.toObject(includeInstance, f),
instrumentmarketingcontentid: jspb.Message.getFieldWithDefault(msg, 2, ""),
independentreviewsList: jspb.Message.toObjectList(msg.getIndependentreviewsList(),
    marketing_independentReview_pb.IndependentReview.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.marketing.UpdateIndependentReviewsRequest}
 */
proto.marketing.UpdateIndependentReviewsRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.marketing.UpdateIndependentReviewsRequest;
  return proto.marketing.UpdateIndependentReviewsRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.marketing.UpdateIndependentReviewsRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.marketing.UpdateIndependentReviewsRequest}
 */
proto.marketing.UpdateIndependentReviewsRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new security_context_pb.ContextFuture;
      reader.readMessage(value,security_context_pb.ContextFuture.deserializeBinaryFromReader);
      msg.setContext(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setInstrumentmarketingcontentid(value);
      break;
    case 3:
      var value = new marketing_independentReview_pb.IndependentReview;
      reader.readMessage(value,marketing_independentReview_pb.IndependentReview.deserializeBinaryFromReader);
      msg.addIndependentreviews(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.marketing.UpdateIndependentReviewsRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.marketing.UpdateIndependentReviewsRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.marketing.UpdateIndependentReviewsRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.marketing.UpdateIndependentReviewsRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getContext();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      security_context_pb.ContextFuture.serializeBinaryToWriter
    );
  }
  f = message.getInstrumentmarketingcontentid();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getIndependentreviewsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      3,
      f,
      marketing_independentReview_pb.IndependentReview.serializeBinaryToWriter
    );
  }
};


/**
 * optional security.ContextFuture context = 1;
 * @return {?proto.security.ContextFuture}
 */
proto.marketing.UpdateIndependentReviewsRequest.prototype.getContext = function() {
  return /** @type{?proto.security.ContextFuture} */ (
    jspb.Message.getWrapperField(this, security_context_pb.ContextFuture, 1));
};


/**
 * @param {?proto.security.ContextFuture|undefined} value
 * @return {!proto.marketing.UpdateIndependentReviewsRequest} returns this
*/
proto.marketing.UpdateIndependentReviewsRequest.prototype.setContext = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.marketing.UpdateIndependentReviewsRequest} returns this
 */
proto.marketing.UpdateIndependentReviewsRequest.prototype.clearContext = function() {
  return this.setContext(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.marketing.UpdateIndependentReviewsRequest.prototype.hasContext = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional string instrumentMarketingContentID = 2;
 * @return {string}
 */
proto.marketing.UpdateIndependentReviewsRequest.prototype.getInstrumentmarketingcontentid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.marketing.UpdateIndependentReviewsRequest} returns this
 */
proto.marketing.UpdateIndependentReviewsRequest.prototype.setInstrumentmarketingcontentid = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * repeated IndependentReview independentReviews = 3;
 * @return {!Array<!proto.marketing.IndependentReview>}
 */
proto.marketing.UpdateIndependentReviewsRequest.prototype.getIndependentreviewsList = function() {
  return /** @type{!Array<!proto.marketing.IndependentReview>} */ (
    jspb.Message.getRepeatedWrapperField(this, marketing_independentReview_pb.IndependentReview, 3));
};


/**
 * @param {!Array<!proto.marketing.IndependentReview>} value
 * @return {!proto.marketing.UpdateIndependentReviewsRequest} returns this
*/
proto.marketing.UpdateIndependentReviewsRequest.prototype.setIndependentreviewsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 3, value);
};


/**
 * @param {!proto.marketing.IndependentReview=} opt_value
 * @param {number=} opt_index
 * @return {!proto.marketing.IndependentReview}
 */
proto.marketing.UpdateIndependentReviewsRequest.prototype.addIndependentreviews = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 3, opt_value, proto.marketing.IndependentReview, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.marketing.UpdateIndependentReviewsRequest} returns this
 */
proto.marketing.UpdateIndependentReviewsRequest.prototype.clearIndependentreviewsList = function() {
  return this.setIndependentreviewsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.marketing.UpdateIndependentReviewsResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.marketing.UpdateIndependentReviewsResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.marketing.UpdateIndependentReviewsResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.marketing.UpdateIndependentReviewsResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
instrumentmarketingcontent: (f = msg.getInstrumentmarketingcontent()) && marketing_instrumentMarketingContent_pb.InstrumentMarketingContent.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.marketing.UpdateIndependentReviewsResponse}
 */
proto.marketing.UpdateIndependentReviewsResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.marketing.UpdateIndependentReviewsResponse;
  return proto.marketing.UpdateIndependentReviewsResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.marketing.UpdateIndependentReviewsResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.marketing.UpdateIndependentReviewsResponse}
 */
proto.marketing.UpdateIndependentReviewsResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new marketing_instrumentMarketingContent_pb.InstrumentMarketingContent;
      reader.readMessage(value,marketing_instrumentMarketingContent_pb.InstrumentMarketingContent.deserializeBinaryFromReader);
      msg.setInstrumentmarketingcontent(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.marketing.UpdateIndependentReviewsResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.marketing.UpdateIndependentReviewsResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.marketing.UpdateIndependentReviewsResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.marketing.UpdateIndependentReviewsResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getInstrumentmarketingcontent();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      marketing_instrumentMarketingContent_pb.InstrumentMarketingContent.serializeBinaryToWriter
    );
  }
};


/**
 * optional InstrumentMarketingContent instrumentMarketingContent = 1;
 * @return {?proto.marketing.InstrumentMarketingContent}
 */
proto.marketing.UpdateIndependentReviewsResponse.prototype.getInstrumentmarketingcontent = function() {
  return /** @type{?proto.marketing.InstrumentMarketingContent} */ (
    jspb.Message.getWrapperField(this, marketing_instrumentMarketingContent_pb.InstrumentMarketingContent, 1));
};


/**
 * @param {?proto.marketing.InstrumentMarketingContent|undefined} value
 * @return {!proto.marketing.UpdateIndependentReviewsResponse} returns this
*/
proto.marketing.UpdateIndependentReviewsResponse.prototype.setInstrumentmarketingcontent = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.marketing.UpdateIndependentReviewsResponse} returns this
 */
proto.marketing.UpdateIndependentReviewsResponse.prototype.clearInstrumentmarketingcontent = function() {
  return this.setInstrumentmarketingcontent(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.marketing.UpdateIndependentReviewsResponse.prototype.hasInstrumentmarketingcontent = function() {
  return jspb.Message.getField(this, 1) != null;
};


goog.object.extend(exports, proto.marketing);

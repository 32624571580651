import React, { ReactNode, useEffect, useState } from "react";
import {
  Box,
  Button,
  Card,
  CardContent,
  IconButton,
  Tooltip,
  Typography,
} from "@mui/material";
import {
  Error as ErrorIcon,
  FileCopy as CopyPasteIcon,
  OpenInNew as OpenInNewIcon,
  Refresh as ReloadIcon,
  RemoveRedEye as ViewTransactionIcon,
} from "@mui/icons-material";
import { useSnackbar } from "notistack";
import { useAppNoticeContext } from "context/AppNotice/AppNotice";
import { useErrorContext } from "context/Error";
import {
  Transaction,
  TransactionState,
} from "@mesh/common-js/dist/ledger/transaction_pb";
import { Criterion } from "@mesh/common-js/dist/search/criterion_pb";
import { useAPIContext } from "context/API";
import { FetchAllTransactionsRequest } from "@mesh/common-js/dist/ledger/transactionFetcher_pb";
import { useApplicationContext } from "context/Application/Application";
import { FETable } from "components/Table/FETable";
import { futureNetworkToString } from "@mesh/common-js/dist/ledger";
import { TransactionWrapper } from "@mesh/common-js/dist/ledger/transactionWrapper";
import { protobufTimestampToDayjs } from "@mesh/common-js/dist/googleProtobufConverters";
import { Timestamp } from "google-protobuf/google/protobuf/timestamp_pb";
import { DateTimeFormat } from "const/dateformats";
import { TransactionStateChip } from "./Chips";
import { Popover } from "components/PopOver/Popover";
import { TransactionFailureReasonsCard } from "./FailureReasonsCard";
import { Network } from "@mesh/common-js/dist/stellar/network_pb";
import ReactJson from "react-json-view";

export type TransactionTableProps = {
  title?: string | ReactNode;
  constantCriteria: Criterion[];
  height?: number;
  initialDenseTable?: boolean;
};

export const TransactionTable = (props: TransactionTableProps) => {
  const {
    ledger: { transactionFetcher },
  } = useAPIContext();
  const { enqueueSnackbar } = useSnackbar();
  const { viewConfiguration, authContext } = useApplicationContext();
  const [apiLoading, setAPILoading] = useState(false);
  const { NotificationBannerHeight: noticeBannerHeight } =
    useAppNoticeContext();

  const { errorContextErrorTranslator } = useErrorContext();

  const [transactions, setTransactions] = useState<Transaction[]>([]);
  const [fetchingTransactions, setFetchingTransactions] = useState(false);
  const [reloadToggle, setReloadToggle] = useState(false);
  const reload = () => setReloadToggle((prev) => !prev);
  useEffect(() => {
    (async () => {
      setFetchingTransactions(true);
      try {
        setTransactions(
          (
            await transactionFetcher.fetchAllTransactions(
              new FetchAllTransactionsRequest()
                .setContext(authContext.toFuture())
                .setCriteriaList(props.constantCriteria),
            )
          ).getTransactionsList(),
        );
      } catch (e) {
        console.error("error fetching all relevant transactions", e);
      }
      setFetchingTransactions(false);
    })();
  }, [authContext, reloadToggle]);

  const [selectedTransaction, setSelectedTransaction] = useState<
    Transaction | undefined
  >(undefined);
  const handleResolveState = async () => {
    if (!selectedTransaction) {
      console.error("selected ledger transaction view model not set");
      return;
    }

    setAPILoading(true);
    try {
      // await TransactionSubmissionResolver.ResolveTransactionIDSubmission({
      //   context: authContext,
      //   transactionID: selectedTransaction.transactionID(),
      //   ignoreConfirmationCount: true,
      // });
      setSelectedTransaction(undefined);
      reload();
      enqueueSnackbar("Transaction State Resolution Completed", {
        variant: "success",
      });
    } catch (e) {
      const err = errorContextErrorTranslator.translateError(e);
      console.error(`error resolving transaction state`, e);
      enqueueSnackbar(`error resolving transaction state: ${err.message}`, {
        variant: "error",
      });
    }
    setAPILoading(false);
  };

  const loading = fetchingTransactions || apiLoading;

  return (
    <FETable
      initialDenseTable={props.initialDenseTable}
      loading={loading || apiLoading}
      height={
        props.height
          ? props.height - noticeBannerHeight
          : 100 - noticeBannerHeight
      }
      singleSelect
      onSingleSelectChange={(data) =>
        setSelectedTransaction(data as Transaction)
      }
      title={props.title ? props.title : "Transactions"}
      data={transactions}
      toolBarControls={(() => {
        const controls: React.ReactNode[] = [];

        if (
          viewConfiguration?.Ledger?.Transaction?.Resolve &&
          selectedTransaction
        ) {
          controls.push(
            <Button
              variant={"outlined"}
              id={"ledgerTransactionTable-resolveState-button"}
              children={"Resolve State"}
              onClick={handleResolveState}
            />,
          );
        }

        controls.push(
          <IconButton
            id={"ledgerTransactionTable-reload-iconButton"}
            size={"small"}
            disabled={loading}
            onClick={reload}
          >
            <ReloadIcon />
          </IconButton>,
        );

        return controls;
      })()}
      columns={[
        {
          label: "ID",
          field: "id",
          accessor: (data) => {
            const txn = data as Transaction;
            return (
              <Box
                sx={(theme) => ({
                  display: "flex",
                  flexDirection: "row",
                  gap: theme.spacing(0.5),
                })}
              >
                <Typography
                  sx={{
                    width: 100,
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    whiteSpace: "nowrap",
                  }}
                  variant="body1"
                  children={new TransactionWrapper(txn).getId()}
                />
                <CopyPasteIcon
                  sx={(theme) => ({
                    fontSize: 20,
                    color: theme.palette.action.disabled,
                    "&:hover": {
                      color: theme.palette.action.active,
                    },
                    cursor: "pointer",
                  })}
                  onClick={() =>
                    navigator.clipboard
                      .writeText(txn.getStellartransaction()?.getId() ?? "")
                      .then(() => enqueueSnackbar("Source Account ID copied"))
                  }
                />
              </Box>
            );
          },
        },
        {
          label: "Description",
          field: "metaData.description",
          accessor: (data) => {
            return new TransactionWrapper(data as Transaction).getDescription();
          },
        },
        {
          label: "Last Modified",
          field: "auditEntry.time",
          accessor: (data) =>
            protobufTimestampToDayjs(
              new TransactionWrapper(data as Transaction)
                .getAuditentry()
                ?.getTime() ?? new Timestamp(),
            ).format(DateTimeFormat),
        },
        {
          label: "State",
          field: "state",

          accessor: (data) => {
            const txn = new TransactionWrapper(data as Transaction);
            if (txn.state === TransactionState.FAILED_TRANSACTION_STATE) {
              return (
                <Box
                  sx={(theme) => ({
                    display: "flex",
                    flexDirection: "row",
                    gap: theme.spacing(0.5),
                  })}
                >
                  <TransactionStateChip state={txn.state} />
                  <Popover
                    anchorOrigin={{
                      vertical: "top",
                      horizontal: "center",
                    }}
                    popOverComponent={
                      <TransactionFailureReasonsCard
                        transaction={txn.transaction}
                      />
                    }
                  >
                    <Tooltip
                      title="Select to view failure reasons"
                      placement="top"
                    >
                      <ErrorIcon
                        sx={{
                          color: "secondary.light",
                          cursor: "pointer",
                        }}
                      />
                    </Tooltip>
                  </Popover>
                </Box>
              );
            } else {
              return <TransactionStateChip state={txn.state} />;
            }
          },
        },
        {
          label: "Resolutions",
          field: "stateresolutioncount",
          accessor: (data) => {
            return new TransactionWrapper(
              data as Transaction,
            ).getStateresolutioncount();
          },
        },
        {
          label: "Network",
          field: "network",
          accessor: (data) => {
            return futureNetworkToString(
              new TransactionWrapper(data as Transaction).getNetwork(),
            );
          },
        },
        {
          field: "",
          label: "",
          minWidth: 40,
          accessor: (data) => {
            const controls: React.ReactNode[] = [];
            const txn = data as Transaction;

            // add stellar specific controls
            const stellarTransaction = txn.getStellartransaction();
            if (stellarTransaction) {
              // if ledger ID is set then transaction add button for transaction to be viewed in stellar expert
              if (stellarTransaction.getLedgerid()) {
                controls.push(
                  <Tooltip
                    placement="top"
                    title="View Transaction in Stellar Expert"
                  >
                    <IconButton
                      size="small"
                      id={`ledgerTransactionTable-${stellarTransaction.getId()}-openTransactionInStellarExpert-iconButton`}
                      onClick={() =>
                        window.open(
                          `https://stellar.expert/explorer/${
                            stellarTransaction.getNetwork() ===
                            Network.STELLAR_TEST_SDF_NETWORK
                              ? "testnet"
                              : "public"
                          }/tx/${stellarTransaction.getLedgerid()}`,
                          "_blank",
                        )
                      }
                    >
                      <OpenInNewIcon />
                    </IconButton>
                  </Tooltip>,
                );
              }
            }

            controls.push(
              <Popover
                anchorOrigin={{
                  vertical: "top",
                  horizontal: "center",
                }}
                popOverComponent={
                  <Card style={{ backgroundColor: "#1d1f21" }}>
                    <CardContent>
                      <ReactJson
                        name={false}
                        enableClipboard={(e) =>
                          navigator.clipboard
                            .writeText(JSON.stringify(e))
                            .then(() => enqueueSnackbar("copied"))
                        }
                        src={txn.toObject()}
                        theme="google"
                      />
                    </CardContent>
                  </Card>
                }
              >
                <Tooltip
                  title="Select to view transaction in pop-up"
                  placement="top"
                >
                  <ViewTransactionIcon
                    sx={(theme) => ({
                      color: theme.palette.action.disabled,
                      "&:hover": {
                        color: theme.palette.action.active,
                      },
                      cursor: "pointer",
                    })}
                  />
                </Tooltip>
              </Popover>,
            );

            return (
              <Box
                sx={(theme) => ({
                  display: "flex",
                  flexDirection: "row",
                  gap: theme.spacing(0.5),
                })}
              >
                {controls.map((c, idx) => (
                  <React.Fragment key={idx}>{c}</React.Fragment>
                ))}
              </Box>
            );
          },
        },
      ]}
    />
  );
};

"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.criteriaFromToken = criteriaFromToken;
exports.criteriaFromStellarToken = criteriaFromStellarToken;
exports.tokensAreEqual = tokensAreEqual;
exports.tokenIsUndefined = tokenIsUndefined;
exports.tokenToFilter = tokenToFilter;
exports.tokenToString = tokenToString;
exports.futureTokenToStellarToken = futureTokenToStellarToken;
exports.futureTokenFromStellarToken = futureTokenFromStellarToken;
exports.futureAmountFromStellarToken = futureAmountFromStellarToken;
const bignumber_js_1 = require("bignumber.js");
const search_1 = require("../search");
const amount_pb_1 = require("../stellar/amount_pb");
const network_pb_1 = require("../stellar/network_pb");
const token_pb_1 = require("../stellar/token_pb");
const futureNetwork_pb_1 = require("./futureNetwork_pb");
const futureToken_pb_1 = require("./futureToken_pb");
const network_1 = require("./network");
const num_1 = require("../num");
function criteriaFromToken(token) {
    var _a, _b, _c;
    return [
        (0, search_1.newTextExactCriterion)("token.code", (_a = token === null || token === void 0 ? void 0 : token.getCode()) !== null && _a !== void 0 ? _a : ""),
        (0, search_1.newTextExactCriterion)("token.issuer", (_b = token === null || token === void 0 ? void 0 : token.getIssuer()) !== null && _b !== void 0 ? _b : ""),
        (0, search_1.newUint32ExactCriterion)("token.network", (_c = token === null || token === void 0 ? void 0 : token.getNetwork()) !== null && _c !== void 0 ? _c : futureNetwork_pb_1.FutureNetwork.UNDEFINED_NETWORK),
    ];
}
function criteriaFromStellarToken(token) {
    var _a, _b, _c;
    return [
        (0, search_1.newTextExactCriterion)("token.code", (_a = token === null || token === void 0 ? void 0 : token.getCode()) !== null && _a !== void 0 ? _a : ""),
        (0, search_1.newTextExactCriterion)("token.issuer", (_b = token === null || token === void 0 ? void 0 : token.getIssuer()) !== null && _b !== void 0 ? _b : ""),
        (0, search_1.newUint32ExactCriterion)("token.network", (_c = token === null || token === void 0 ? void 0 : token.getNetwork()) !== null && _c !== void 0 ? _c : futureNetwork_pb_1.FutureNetwork.UNDEFINED_NETWORK),
    ];
}
function tokensAreEqual(t, t2) {
    var _a, _b, _c, _d, _e, _f;
    return (((_a = t === null || t === void 0 ? void 0 : t.getCode()) !== null && _a !== void 0 ? _a : "") === ((_b = t2 === null || t2 === void 0 ? void 0 : t2.getCode()) !== null && _b !== void 0 ? _b : "") &&
        ((_c = t === null || t === void 0 ? void 0 : t.getIssuer()) !== null && _c !== void 0 ? _c : "") === ((_d = t2 === null || t2 === void 0 ? void 0 : t2.getIssuer()) !== null && _d !== void 0 ? _d : "") &&
        ((_e = t === null || t === void 0 ? void 0 : t.getNetwork()) !== null && _e !== void 0 ? _e : futureNetwork_pb_1.FutureNetwork.UNDEFINED_NETWORK) ===
            ((_f = t2 === null || t2 === void 0 ? void 0 : t2.getNetwork()) !== null && _f !== void 0 ? _f : futureNetwork_pb_1.FutureNetwork.UNDEFINED_NETWORK));
}
function tokenIsUndefined(t) {
    if (!t) {
        return true;
    }
    return ((t.getCode() === "-" || t.getCode() === "") &&
        (t.getIssuer() === "-" || t.getIssuer() === "") &&
        (t.getNetwork() === futureNetwork_pb_1.FutureNetwork.NULL_NETWORK ||
            t.getNetwork() === futureNetwork_pb_1.FutureNetwork.UNDEFINED_NETWORK));
}
function tokenToFilter(t) {
    var _a, _b, _c;
    return [
        (0, search_1.newTextExactCriterion)("token.code", (_a = t === null || t === void 0 ? void 0 : t.getCode()) !== null && _a !== void 0 ? _a : ""),
        (0, search_1.newTextExactCriterion)("token.issuer", (_b = t === null || t === void 0 ? void 0 : t.getIssuer()) !== null && _b !== void 0 ? _b : ""),
        (0, search_1.newUint32ExactCriterion)("token.network", (_c = t === null || t === void 0 ? void 0 : t.getNetwork()) !== null && _c !== void 0 ? _c : futureNetwork_pb_1.FutureNetwork.UNDEFINED_NETWORK),
    ];
}
function tokenToString(t) {
    var _a, _b, _c;
    return `${(_a = t === null || t === void 0 ? void 0 : t.getCode()) !== null && _a !== void 0 ? _a : "-"} by ${(_b = t === null || t === void 0 ? void 0 : t.getIssuer()) !== null && _b !== void 0 ? _b : "-"} on ${(_c = t === null || t === void 0 ? void 0 : t.getNetwork()) !== null && _c !== void 0 ? _c : futureNetwork_pb_1.FutureNetwork.UNDEFINED_NETWORK}`;
}
function futureTokenToStellarToken(t) {
    var _a, _b, _c;
    return new token_pb_1.Token()
        .setCode((_a = t === null || t === void 0 ? void 0 : t.getCode()) !== null && _a !== void 0 ? _a : "")
        .setIssuer((_b = t === null || t === void 0 ? void 0 : t.getIssuer()) !== null && _b !== void 0 ? _b : "")
        .setNetwork((0, network_1.futureNetworkToStellarNetwork)((_c = t === null || t === void 0 ? void 0 : t.getNetwork()) !== null && _c !== void 0 ? _c : futureNetwork_pb_1.FutureNetwork.UNDEFINED_NETWORK));
}
function futureTokenFromStellarToken(t) {
    var _a, _b, _c;
    return new futureToken_pb_1.FutureToken()
        .setCode((_a = t === null || t === void 0 ? void 0 : t.getCode()) !== null && _a !== void 0 ? _a : "")
        .setIssuer((_b = t === null || t === void 0 ? void 0 : t.getIssuer()) !== null && _b !== void 0 ? _b : "")
        .setNetwork((0, network_1.futureNetworkFromStellarNetwork)((_c = t === null || t === void 0 ? void 0 : t.getNetwork()) !== null && _c !== void 0 ? _c : network_pb_1.Network.UNDEFINED_NETWORK));
}
function futureAmountFromStellarToken(t, value) {
    value = new bignumber_js_1.BigNumber(value);
    switch (t === null || t === void 0 ? void 0 : t.getNetwork()) {
        case network_pb_1.Network.STELLAR_PUBLIC_NETWORK:
        case network_pb_1.Network.STELLAR_TEST_SDF_NETWORK:
            return new amount_pb_1.Amount()
                .setToken(t)
                .setValue((0, num_1.bigNumberToDecimal)(value.decimalPlaces(7, bignumber_js_1.BigNumber.ROUND_DOWN)));
        default:
            throw new Error("unexpected token, expected stellar token");
    }
}

import {
  Box,
  Button,
  Card,
  Divider,
  IconButton,
  Tooltip,
  Typography,
  useTheme,
} from "@mui/material";
import { useState, useEffect, useRef } from "react";
import { ReactSortable } from "react-sortablejs";
import DeleteIcon from "@mui/icons-material/Delete";
import { ValidationResult } from "common/validation";
import { TextField, DateField } from "components/FormFields";
import { Refresh as ResetIcon } from "@mui/icons-material";
import { PublicMediaController } from "james/media/publicMediaController";
import {
  IndependentReview,
  ReviewerDetails,
} from "@mesh/common-js/dist/marketing/independentReview_pb";
import {
  protobufTimestampToDayjs,
  dayjsToProtobufTimestamp,
} from "@mesh/common-js/dist/googleProtobufConverters";
import { NoDataSplashCard } from "../NoDataSplashCard";
import { useApplicationContext } from "context/Application/Application";
import { enqueueSnackbar } from "notistack";
import { LoadingButton } from "@mui/lab";
import dayjs from "dayjs";

export type IndependentReviewsFormProps = {
  reviews: IndependentReview[];
  disabled: boolean;
  readOnly: boolean;
  formDataValidationResult: ValidationResult;
  onChange: (updatedIndependentReviews: IndependentReview[]) => void;
};

export const IndependentReviewsForm = (props: IndependentReviewsFormProps) => {
  const theme = useTheme();
  const { authContext } = useApplicationContext();

  // TODO: Add validations
  // const getFieldValidation = (field: string, index: number) => {
  //   return props.formDataValidationResult.fieldValidations[
  //     `independentReviews-${index}-${field}`
  //   ];
  // };

  // State for the list of reviews
  const [reviews, setReviews] = useState(
    props.reviews.map((review, idx) => ({
      id: idx,
      review,
    })),
  );

  // State for the new review being added
  const [currentReview, setCurrentReview] = useState<IndependentReview>(
    new IndependentReview()
      .setDate(dayjsToProtobufTimestamp(dayjs()))
      .setReviewerdetails(new ReviewerDetails()),
  );

  // State for the last saved reviews
  const [savedReviews, setSavedReviews] = useState(props.reviews);

  // Helper function to compare reviews for unsaved changes
  const areReviewsDifferent = (
    reviewsA: IndependentReview[],
    reviewsB: IndependentReview[],
  ) => {
    if (reviewsA.length !== reviewsB.length) return true;
    return reviewsA.some((a, idx) => {
      const b = reviewsB[idx];
      return (
        a.getTitle() !== b.getTitle() ||
        a.getSummary() !== b.getSummary() ||
        a.getDate() !== b.getDate() ||
        a.getUrl() !== b.getUrl() ||
        a.getReviewerdetails()?.getName() !==
          b.getReviewerdetails()?.getName() ||
        a.getReviewerdetails()?.getBio() !== b.getReviewerdetails()?.getBio() ||
        a.getReviewerdetails()?.getProfilepictureurl() !==
          b.getReviewerdetails()?.getProfilepictureurl()
      );
    });
  };

  // Determine unsaved changes
  const hasUnsavedChanges =
    !props.readOnly &&
    areReviewsDifferent(
      reviews.map((item) => item.review),
      savedReviews,
    );

  // Handle changes in readOnly prop
  useEffect(() => {
    if (props.readOnly) {
      // Save the current state when switching to readOnly
      setSavedReviews(reviews.map((item) => item.review));
      // Reset the current review
      setCurrentReview(
        new IndependentReview()
          .setDate(dayjsToProtobufTimestamp(dayjs()))
          .setReviewerdetails(new ReviewerDetails()),
      );
    }
  }, [props.readOnly]);

  // Helpers to handle image uploading
  const [uploading, setUploading] = useState(false);
  const fileInputRef = useRef<HTMLInputElement>(null);
  const handleFileSelect = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };
  const uploadFileToUrl = async (url: string, file: File) => {
    const response = await fetch(url, {
      method: "PUT",
      headers: {
        "Content-Type": file.type,
      },
      body: file,
    });
    if (!response.ok) {
      throw new Error("Failed to upload file");
    }
  };
  const handleFileUpload = async (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    if (!event.target.files || !event.target.files[0]) return;

    const file = event.target.files[0];
    setUploading(true);

    try {
      // Generate upload and download URLs
      const uploadResponse = await PublicMediaController.GenerateImageUploadUrl(
        {
          context: authContext,
        },
      );
      const uploadUrl = uploadResponse.url;

      // Upload the file
      await uploadFileToUrl(uploadUrl, file);

      // Generate download URL
      const downloadResponse =
        await PublicMediaController.GenerateImageDownloadUrl({
          context: authContext,
          mediaAssetID: uploadResponse.imageID,
        });
      const imageUrl = downloadResponse.url;

      // Update currentMediaEntry based on type
      setCurrentReview((prev) => {
        const updatedEntry = new IndependentReview()
          .setTitle(prev.getTitle())
          .setSummary(prev.getSummary())
          .setDate(prev.getDate())
          .setUrl(prev.getUrl())
          .setReviewerdetails(
            new ReviewerDetails()
              .setName(prev.getReviewerdetails()?.getName() || "")
              .setBio(prev.getReviewerdetails()?.getBio() || "")
              .setProfilepictureurl(imageUrl),
          );

        return updatedEntry;
      });
    } catch (error) {
      enqueueSnackbar("Failed to upload file. Please try again.", {
        variant: "error",
      });
    } finally {
      setUploading(false);
    }
  };

  return (
    <Card
      sx={{
        width: "100%",
        display: "flex",
        flexDirection: "column",
        border: `1px solid ${theme.palette.custom.grape}`,
        backgroundColor: theme.palette.custom.midnight,
        borderRadius: theme.shape.borderRadius,
        p: theme.spacing(2),
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          mb: theme.spacing(2),
        }}
      >
        <Typography variant="h6">Independent Reviews</Typography>
        {hasUnsavedChanges && (
          <Box
            sx={{
              width: 8,
              height: 8,
              borderRadius: "50%",
              backgroundColor: theme.palette.warning.light,
            }}
          />
        )}
      </Box>

      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
        }}
      >
        {/* Section with added reviews */}
        {!reviews.length && props.readOnly ? (
          <NoDataSplashCard
            dataDescriptionTitle={"Independent Reviews"}
            dataDescriptionBody={"Reviews"}
          />
        ) : (
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
            }}
          >
            <ReactSortable
              animation={200}
              easing="ease-out"
              disabled={props.readOnly}
              list={reviews}
              style={{
                display: "flex",
                flexDirection: "column",
                gap: theme.spacing(2),
                cursor: props.readOnly ? "default" : "grab",
              }}
              setList={(newList) => {
                setReviews(newList);
                props.onChange(newList.map((item) => item.review));
              }}
            >
              {reviews.map(({ id, review }, idx) => (
                <Card
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                    alignItems: "flex-start",
                    p: theme.spacing(2),
                    gap: theme.spacing(2),
                  }}
                  key={id}
                >
                  {/* Main Content */}
                  <Box sx={{ flex: 1 }}>
                    <Box
                      sx={{
                        display: "flex",
                        gap: theme.spacing(1.5),
                        alignItems: "center",
                      }}
                    >
                      <Box sx={{ width: "20%" }}>
                        <DateField
                          id={`instrumentMarketingContent-independentReview-${idx}-dateField`}
                          label={"Date"}
                          readOnly={true}
                          value={
                            review.getDate()
                              ? protobufTimestampToDayjs(review.getDate()!)
                              : null
                          }
                        />
                      </Box>
                      <Divider orientation="vertical" flexItem />
                      <TextField
                        id={`instrumentMarketingContent-independentReview-${idx}-title-textField`}
                        sx={{ width: "40%" }}
                        label={"Title"}
                        readOnly={true}
                        value={review.getTitle()}
                      />
                      <Divider orientation="vertical" flexItem />
                      <TextField
                        id={`instrumentMarketingContent-independentReview-${idx}-url-textField`}
                        sx={{ width: "40%" }}
                        label={"Report URL"}
                        readOnly={true}
                        value={review.getUrl()}
                      />
                    </Box>
                    <TextField
                      multiline
                      rows={3}
                      id={`instrumentMarketingContent-independentReview-${idx}-summary-textField`}
                      label={"Summary"}
                      readOnly={true}
                      value={review.getSummary()}
                      sx={{
                        width: "100%",
                        "& .MuiInputBase-root": {
                          border: "1px solid",
                          borderColor: theme.palette.divider,
                          borderRadius: theme.spacing(0.5),
                          padding: theme.spacing(0.5),
                        },
                      }}
                    />
                    <Typography
                      variant="body2"
                      sx={{
                        fontWeight: "bold",
                        mt: theme.spacing(1.5),
                        mb: theme.spacing(0.5),
                      }}
                    >
                      Reviewer Details
                    </Typography>
                    <Box
                      sx={{
                        display: "flex",
                        gap: theme.spacing(1.5),
                      }}
                    >
                      <TextField
                        id={`instrumentMarketingContent-independentReview-${idx}-reviewerDetails-name-textField`}
                        sx={{ width: "50%" }}
                        label={"Name"}
                        readOnly={true}
                        value={review.getReviewerdetails()?.getName()}
                      />
                      <Divider orientation="vertical" flexItem />
                      <TextField
                        id={`instrumentMarketingContent-independentReview-${idx}-reviewerDetails-profilePictureUrl-textField`}
                        sx={{ width: "50%" }}
                        label={"Profile Picture URL"}
                        readOnly={true}
                        value={review
                          .getReviewerdetails()
                          ?.getProfilepictureurl()}
                      />
                    </Box>
                    <TextField
                      multiline
                      rows={3}
                      id={`instrumentMarketingContent-independentReview-${idx}-reviewerDetails-bio-textField`}
                      label={"Bio"}
                      readOnly={true}
                      value={review.getReviewerdetails()?.getBio()}
                      sx={{
                        width: "100%",
                        "& .MuiInputBase-root": {
                          border: "1px solid",
                          borderColor: theme.palette.divider,
                          borderRadius: theme.spacing(0.5),
                          padding: theme.spacing(0.5),
                        },
                      }}
                    />
                  </Box>

                  {/* Delete Icon */}
                  {!props.readOnly && (
                    <Box
                      sx={{
                        height: "100%",
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      <Divider orientation="vertical" />
                      <Tooltip title="Delete Review" placement="top">
                        <IconButton
                          id={`instrumentMarketingContent-independentReview-${idx}-delete-iconButton`}
                          disabled={props.disabled}
                          aria-label="delete"
                          size="medium"
                          sx={{
                            ml: theme.spacing(2),
                          }}
                          onClick={() => {
                            const updatedReviews = reviews.filter(
                              (_, index) => index !== idx,
                            );
                            setReviews(updatedReviews);
                            props.onChange(
                              updatedReviews.map((item) => item.review),
                            );
                          }}
                        >
                          <DeleteIcon />
                        </IconButton>
                      </Tooltip>
                    </Box>
                  )}
                </Card>
              ))}
            </ReactSortable>
          </Box>
        )}

        {/* Section for adding a new review */}
        {!props.readOnly && (
          <>
            <Box
              sx={{
                display: "flex",
                gap: theme.spacing(2),
                mt: theme.spacing(2),
                mb: theme.spacing(1),
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  height: "100%",
                  flexGrow: 1,
                  gap: theme.spacing(2),
                }}
              >
                <DateField
                  id={
                    "instrumentMarketingContent-newIndependentReview-dateField"
                  }
                  disabled={props.disabled}
                  label={"Date"}
                  sx={{ width: "20%" }}
                  value={
                    currentReview.getDate()
                      ? protobufTimestampToDayjs(currentReview.getDate()!)
                      : null
                  }
                  onChange={(e) => {
                    if (e) {
                      setCurrentReview((prev) =>
                        new IndependentReview()
                          .setTitle(prev.getTitle())
                          .setSummary(prev.getSummary())
                          .setDate(dayjsToProtobufTimestamp(e))
                          .setUrl(prev.getUrl())
                          .setReviewerdetails(prev.getReviewerdetails()),
                      );
                    }
                  }}
                />
                <TextField
                  id={
                    "instrumentMarketingContent-newIndependentReview-title-textField"
                  }
                  disabled={props.disabled}
                  label={"Title"}
                  sx={{ width: "38%" }}
                  value={currentReview.getTitle()}
                  placeholder={"Enter review title..."}
                  onChange={(e) => {
                    setCurrentReview((prev) =>
                      new IndependentReview()
                        .setTitle(e.target.value)
                        .setSummary(prev.getSummary())
                        .setDate(prev.getDate())
                        .setUrl(prev.getUrl())
                        .setReviewerdetails(prev.getReviewerdetails()),
                    );
                  }}
                />
                <TextField
                  id={
                    "instrumentMarketingContent-newIndependentReview-url-textField"
                  }
                  disabled={props.disabled}
                  label={"Report URL"}
                  sx={{ flexGrow: 1 }}
                  value={currentReview.getUrl()}
                  placeholder={"Enter report URL..."}
                  onChange={(e) => {
                    setCurrentReview((prev) =>
                      new IndependentReview()
                        .setTitle(prev.getTitle())
                        .setSummary(prev.getSummary())
                        .setDate(prev.getDate())
                        .setUrl(e.target.value)
                        .setReviewerdetails(prev.getReviewerdetails()),
                    );
                  }}
                />
              </Box>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  mr: theme.spacing(2),
                }}
              >
                <Tooltip title="Reset" placement="top">
                  <IconButton
                    id={
                      "instrumentMarketingContent-newIndependentReview-reset-iconButton"
                    }
                    aria-label="reset"
                    size="medium"
                    disabled={uploading}
                    onClick={() => {
                      setCurrentReview(
                        new IndependentReview()
                          .setDate(dayjsToProtobufTimestamp(dayjs()))
                          .setReviewerdetails(new ReviewerDetails()),
                      );
                    }}
                  >
                    <ResetIcon />
                  </IconButton>
                </Tooltip>
              </Box>
            </Box>
            <TextField
              multiline
              rows={3}
              id={
                "instrumentMarketingContent-newIndependentReview-summary-textField"
              }
              disabled={props.disabled}
              label="Summary"
              value={currentReview.getSummary()}
              placeholder={"Enter review summary..."}
              onChange={(e) => {
                setCurrentReview((prev) =>
                  new IndependentReview()
                    .setTitle(prev.getTitle())
                    .setSummary(e.target.value)
                    .setDate(prev.getDate())
                    .setUrl(prev.getUrl())
                    .setReviewerdetails(prev.getReviewerdetails()),
                );
              }}
            />
            <Box
              sx={{
                display: "flex",
                mt: theme.spacing(2),
              }}
            >
              <Typography variant="body2" sx={{ fontWeight: "bold" }}>
                Reviewer Details
              </Typography>
            </Box>
            <Box
              sx={{
                display: "flex",
                gap: theme.spacing(2),
                mt: theme.spacing(2),
                mb: theme.spacing(1),
              }}
            >
              <TextField
                id={
                  "instrumentMarketingContent-newIndependentReview-reviewerDetails-name-textField"
                }
                disabled={props.disabled}
                label={"Name"}
                sx={{ width: "47%" }}
                value={currentReview.getReviewerdetails()?.getName()}
                placeholder={"Enter reviewer name..."}
                onChange={(e) => {
                  setCurrentReview((prev) =>
                    new IndependentReview()
                      .setTitle(prev.getTitle())
                      .setSummary(prev.getSummary())
                      .setDate(prev.getDate())
                      .setUrl(prev.getUrl())
                      .setReviewerdetails(
                        new ReviewerDetails()
                          .setName(e.target.value)
                          .setBio(prev.getReviewerdetails()?.getBio() || "")
                          .setProfilepictureurl(
                            prev.getReviewerdetails()?.getProfilepictureurl() ||
                              "",
                          ),
                      ),
                  );
                }}
              />
              {currentReview.getReviewerdetails()?.getProfilepictureurl() ? (
                <TextField
                  id={
                    "instrumentMarketingContent-newIndependentReview-profilePictureUrl-textField"
                  }
                  disabled={props.disabled}
                  label={"Profile Picture URL"}
                  sx={{ width: "53%" }}
                  value={currentReview
                    .getReviewerdetails()
                    ?.getProfilepictureurl()}
                  onChange={(e) => {
                    setCurrentReview((prev) =>
                      new IndependentReview()
                        .setTitle(prev.getTitle())
                        .setSummary(prev.getSummary())
                        .setDate(prev.getDate())
                        .setUrl(prev.getUrl())
                        .setReviewerdetails(
                          new ReviewerDetails()
                            .setName(prev.getReviewerdetails()?.getName() || "")
                            .setBio(prev.getReviewerdetails()?.getBio() || "")
                            .setProfilepictureurl(e.target.value),
                        ),
                    );
                  }}
                />
              ) : (
                <Box
                  sx={{
                    display: "flex",
                    width: "53%",
                  }}
                >
                  <input
                    type="file"
                    ref={fileInputRef}
                    accept="image/*"
                    style={{ display: "none" }}
                    onChange={handleFileUpload}
                  />
                  <LoadingButton
                    id={
                      "instrumentMarketingContent-newIndependentReview-reviewerDetails-uploadProfilePictureUrl-loadingButton"
                    }
                    sx={{
                      mt: theme.spacing(1),
                      mb: theme.spacing(0.6),
                    }}
                    variant="outlined"
                    color="secondary"
                    loading={uploading}
                    onClick={handleFileSelect}
                    disabled={uploading || props.disabled}
                  >
                    Upload Profile Picture
                  </LoadingButton>
                </Box>
              )}
            </Box>
            <TextField
              multiline
              rows={3}
              id={
                "instrumentMarketingContent-newIndependentReview-reviewerDetails-bio-textField"
              }
              disabled={props.disabled}
              label="Bio"
              value={currentReview.getReviewerdetails()?.getBio()}
              placeholder={"Enter reviewer bio..."}
              onChange={(e) => {
                setCurrentReview((prev) =>
                  new IndependentReview()
                    .setTitle(prev.getTitle())
                    .setSummary(prev.getSummary())
                    .setDate(prev.getDate())
                    .setUrl(prev.getUrl())
                    .setReviewerdetails(
                      new ReviewerDetails()
                        .setName(prev.getReviewerdetails()?.getName() || "")
                        .setBio(e.target.value)
                        .setProfilepictureurl(
                          prev.getReviewerdetails()?.getProfilepictureurl() ||
                            "",
                        ),
                    ),
                );
              }}
            />
            <Button
              variant="contained"
              color="primary"
              disabled={
                !currentReview.getDate() ||
                !currentReview.getTitle() ||
                !currentReview.getUrl() ||
                !currentReview.getSummary() ||
                !currentReview.getReviewerdetails()?.getName() ||
                !currentReview.getReviewerdetails()?.getProfilepictureurl() ||
                !currentReview.getReviewerdetails()?.getBio()
              }
              onClick={() => {
                const updatedReviews = [
                  ...reviews,
                  { id: reviews.length, review: currentReview },
                ];
                setReviews(updatedReviews);
                props.onChange(updatedReviews.map((item) => item.review));

                // Reset current review
                setCurrentReview(
                  new IndependentReview()
                    .setDate(dayjsToProtobufTimestamp(dayjs()))
                    .setReviewerdetails(new ReviewerDetails()),
                );
              }}
              sx={{ mt: theme.spacing(2) }}
            >
              Add Review
            </Button>
          </>
        )}
      </Box>
    </Card>
  );
};

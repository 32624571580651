import React, { useEffect, useState } from "react";
import { DirectOrderType } from "james/market/DirectOrder";
import { MarketListingViewModel } from "james/views/marketListingView";
import { IndicativePrice, Listing, QuoteParameter } from "james/market";
import { Balance } from "@mesh/common-js/dist/views/stellarAccountView/model_pb";
import { Model as LedgerTokenViewModel } from "james/views/ledgerTokenView";
import { HeaderProps } from "views/Marketplace/components/PlaceOrderDialog/components/Header/Header";
import { SellCard } from "./SellCard";
import { BuyCard } from "./BuyCard";
import { useIsMounted } from "hooks";
import { LedgerAccountCategory } from "james/ledger";
import { Header } from "../Header";
import { LoadingDialogContent } from "../LoadingDialogContent";
import { useAccountContext } from "context/Account/Account";
import { useSnackbar } from "notistack";
import { LedgerIDIdentifier } from "james/search/identifier";
import { useApplicationContext } from "context/Application/Application";
import { useErrorContext } from "context/Error";
import { getTokenBalance } from "@mesh/common-js/dist/views/stellarAccountView";

export interface IssuerDirectOrderWrapperCardProps extends HeaderProps {
  listing: Listing;
  directOrderType: DirectOrderType;
  marketListingViewModel: MarketListingViewModel;
  indicativePrice: IndicativePrice;
  assetIssuanceTokenViewModel: LedgerTokenViewModel;
  assetValuationTokenViewModel: LedgerTokenViewModel;
  marketMechanismQuoteParameter: QuoteParameter;
}

export interface IssuerDirectOrderCardProps
  extends IssuerDirectOrderWrapperCardProps {
  userIsSignatoryOnTradingAcc: boolean;
  assetIssuanceTokenBalance: Balance;
  assetValuationTokenBalance: Balance;
}

function IssuerDirectOrderCard(props: IssuerDirectOrderWrapperCardProps) {
  const isMounted = useIsMounted();
  const { errorContextErrorTranslator } = useErrorContext();
  const { enqueueSnackbar } = useSnackbar();
  const { authContext } = useApplicationContext();
  const [userIsSignatoryOnTradingAcc, setUserIsSignatoryOnTradingAcc] =
    useState(false);
  const [assetIssuanceTokenBalance, setAssetIssuanceTokenBalance] = useState<
    Balance | undefined
  >(undefined);
  const [assetValuationTokenBalance, setAssetValuationTokenBalance] = useState<
    Balance | undefined
  >(undefined);
  const { stellarAccountContext } = useAccountContext();

  // get required data on load
  useEffect(() => {
    (async () => {
      try {
        if (stellarAccountContext.error) {
          console.error(`initialisation error::${stellarAccountContext.error}`);
          enqueueSnackbar(
            `Initialisation Error: ${stellarAccountContext.error}`,
            {
              variant: "error",
            },
          );
          if (isMounted()) {
            props.closeDialog();
          }
        }

        // wait for the account context data to load before proceeding
        if (stellarAccountContext.loading) {
          return;
        }

        // retrieve the trading account owned by the group that owns the asset
        const retrievedAcc = stellarAccountContext.accounts.find(
          (val) =>
            val.getOwnerid() === props.marketListingViewModel.assetOwnerID &&
            val.getLabel() === LedgerAccountCategory.Trading,
        );

        if (!retrievedAcc) {
          // Decide what to do in this instance
          return;
        }

        // determine if the executing user is a signatory this account
        try {
          if (isMounted()) {
            setUserIsSignatoryOnTradingAcc(
              await stellarAccountContext.checkUserSignatoryOnAccount(
                LedgerIDIdentifier(retrievedAcc.getLedgerid()),
              ),
            );
          }
        } catch (e) {
          const err = errorContextErrorTranslator.translateError(e);
          console.error(
            `error determining if user is signatory on destination account: ${
              err.message ? err.message : err.toString()
            }`,
          );
          enqueueSnackbar("Error Determining Signatory Status", {
            variant: "error",
          });
        }

        // find relevant token balances
        const retrievedAssetIssuanceTokenBalance = getTokenBalance(
          retrievedAcc,
          props.marketListingViewModel.token.toFutureToken(),
        );
        const retrievedValuationTokenBalance = getTokenBalance(
          retrievedAcc,
          props.marketMechanismQuoteParameter.quoteToken.toFutureToken(),
        );

        if (isMounted()) {
          setAssetIssuanceTokenBalance(
            retrievedAssetIssuanceTokenBalance ??
              new Balance()
                .setAmount(
                  props.marketListingViewModel.token
                    .newAmountOf("0")
                    .toFutureAmount(),
                )
                .setLimit(
                  props.marketListingViewModel.token
                    .newAmountOf("0")
                    .toFutureAmount(),
                )
                .setSellingliabilities(
                  props.marketListingViewModel.token
                    .newAmountOf("0")
                    .toFutureAmount(),
                )
                .setBuyingliabilities(
                  props.marketListingViewModel.token
                    .newAmountOf("0")
                    .toFutureAmount(),
                ),
          );
          setAssetValuationTokenBalance(
            retrievedValuationTokenBalance ??
              new Balance()
                .setAmount(
                  props.marketMechanismQuoteParameter.quoteToken
                    .newAmountOf("0")
                    .toFutureAmount(),
                )
                .setLimit(
                  props.marketMechanismQuoteParameter.quoteToken
                    .newAmountOf("0")
                    .toFutureAmount(),
                )
                .setSellingliabilities(
                  props.marketListingViewModel.token
                    .newAmountOf("0")
                    .toFutureAmount(),
                )
                .setBuyingliabilities(
                  props.marketListingViewModel.token
                    .newAmountOf("0")
                    .toFutureAmount(),
                ),
          );
        }
      } catch (e) {
        const err = errorContextErrorTranslator.translateError(e);
        console.error(
          `error getting required data: ${
            err.message ? err.message : err.toString()
          }`,
        );
        if (isMounted()) {
          props.closeDialog();
        }
      }
    })();
  }, [
    authContext,
    isMounted,
    enqueueSnackbar,
    props.marketListingViewModel,
    stellarAccountContext.loading,
    stellarAccountContext.error,
  ]);

  if (!(assetIssuanceTokenBalance && assetValuationTokenBalance)) {
    return (
      <>
        <Header {...props} />
        <LoadingDialogContent />
      </>
    );
  }

  return props.directOrderType === DirectOrderType.Sell ? (
    <SellCard
      {...{
        ...props,
        assetIssuanceTokenBalance,
        assetValuationTokenBalance,
        userIsSignatoryOnTradingAcc,
      }}
    />
  ) : (
    <BuyCard
      {...{
        ...props,
        assetIssuanceTokenBalance,
        assetValuationTokenBalance,
        userIsSignatoryOnTradingAcc,
      }}
    />
  );
}

export { IssuerDirectOrderCard };

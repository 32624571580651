// source: financial/paymentStateController.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global =
    (typeof globalThis !== 'undefined' && globalThis) ||
    (typeof window !== 'undefined' && window) ||
    (typeof global !== 'undefined' && global) ||
    (typeof self !== 'undefined' && self) ||
    (function () { return this; }).call(null) ||
    Function('return this')();

var security_context_pb = require('../security/context_pb.js');
goog.object.extend(proto, security_context_pb);
var financial_paymentTrigger_pb = require('../financial/paymentTrigger_pb.js');
goog.object.extend(proto, financial_paymentTrigger_pb);
var financial_payment_pb = require('../financial/payment_pb.js');
goog.object.extend(proto, financial_payment_pb);
var ledger_futureAmount_pb = require('../ledger/futureAmount_pb.js');
goog.object.extend(proto, ledger_futureAmount_pb);
goog.exportSymbol('proto.financial.CleanUpPaymentRequest', null, global);
goog.exportSymbol('proto.financial.CleanUpPaymentResponse', null, global);
goog.exportSymbol('proto.financial.InitialisePaymentRequest', null, global);
goog.exportSymbol('proto.financial.InitialisePaymentResponse', null, global);
goog.exportSymbol('proto.financial.MarkPaymentCleanupFailedRequest', null, global);
goog.exportSymbol('proto.financial.MarkPaymentCleanupFailedResponse', null, global);
goog.exportSymbol('proto.financial.MarkPaymentCleanupUnderInvestigationRequest', null, global);
goog.exportSymbol('proto.financial.MarkPaymentCleanupUnderInvestigationResponse', null, global);
goog.exportSymbol('proto.financial.MarkPaymentCompleteRequest', null, global);
goog.exportSymbol('proto.financial.MarkPaymentCompleteResponse', null, global);
goog.exportSymbol('proto.financial.MarkPaymentFailedRequest', null, global);
goog.exportSymbol('proto.financial.MarkPaymentFailedResponse', null, global);
goog.exportSymbol('proto.financial.MarkPaymentPaidRequest', null, global);
goog.exportSymbol('proto.financial.MarkPaymentPaidResponse', null, global);
goog.exportSymbol('proto.financial.PayPaymentRequest', null, global);
goog.exportSymbol('proto.financial.PayPaymentResponse', null, global);
goog.exportSymbol('proto.financial.ResolvePaymentIDStateRequest', null, global);
goog.exportSymbol('proto.financial.ResolvePaymentIDStateResponse', null, global);
goog.exportSymbol('proto.financial.ResolvePaymentStateRequest', null, global);
goog.exportSymbol('proto.financial.ResolvePaymentStateResponse', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.financial.InitialisePaymentRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.financial.InitialisePaymentRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.financial.InitialisePaymentRequest.displayName = 'proto.financial.InitialisePaymentRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.financial.InitialisePaymentResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.financial.InitialisePaymentResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.financial.InitialisePaymentResponse.displayName = 'proto.financial.InitialisePaymentResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.financial.PayPaymentRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.financial.PayPaymentRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.financial.PayPaymentRequest.displayName = 'proto.financial.PayPaymentRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.financial.PayPaymentResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.financial.PayPaymentResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.financial.PayPaymentResponse.displayName = 'proto.financial.PayPaymentResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.financial.MarkPaymentFailedRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.financial.MarkPaymentFailedRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.financial.MarkPaymentFailedRequest.displayName = 'proto.financial.MarkPaymentFailedRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.financial.MarkPaymentFailedResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.financial.MarkPaymentFailedResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.financial.MarkPaymentFailedResponse.displayName = 'proto.financial.MarkPaymentFailedResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.financial.MarkPaymentPaidRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.financial.MarkPaymentPaidRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.financial.MarkPaymentPaidRequest.displayName = 'proto.financial.MarkPaymentPaidRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.financial.MarkPaymentPaidResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.financial.MarkPaymentPaidResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.financial.MarkPaymentPaidResponse.displayName = 'proto.financial.MarkPaymentPaidResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.financial.CleanUpPaymentRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.financial.CleanUpPaymentRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.financial.CleanUpPaymentRequest.displayName = 'proto.financial.CleanUpPaymentRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.financial.CleanUpPaymentResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.financial.CleanUpPaymentResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.financial.CleanUpPaymentResponse.displayName = 'proto.financial.CleanUpPaymentResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.financial.MarkPaymentCleanupUnderInvestigationRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.financial.MarkPaymentCleanupUnderInvestigationRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.financial.MarkPaymentCleanupUnderInvestigationRequest.displayName = 'proto.financial.MarkPaymentCleanupUnderInvestigationRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.financial.MarkPaymentCleanupUnderInvestigationResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.financial.MarkPaymentCleanupUnderInvestigationResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.financial.MarkPaymentCleanupUnderInvestigationResponse.displayName = 'proto.financial.MarkPaymentCleanupUnderInvestigationResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.financial.MarkPaymentCleanupFailedRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.financial.MarkPaymentCleanupFailedRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.financial.MarkPaymentCleanupFailedRequest.displayName = 'proto.financial.MarkPaymentCleanupFailedRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.financial.MarkPaymentCleanupFailedResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.financial.MarkPaymentCleanupFailedResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.financial.MarkPaymentCleanupFailedResponse.displayName = 'proto.financial.MarkPaymentCleanupFailedResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.financial.MarkPaymentCompleteRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.financial.MarkPaymentCompleteRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.financial.MarkPaymentCompleteRequest.displayName = 'proto.financial.MarkPaymentCompleteRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.financial.MarkPaymentCompleteResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.financial.MarkPaymentCompleteResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.financial.MarkPaymentCompleteResponse.displayName = 'proto.financial.MarkPaymentCompleteResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.financial.ResolvePaymentStateRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.financial.ResolvePaymentStateRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.financial.ResolvePaymentStateRequest.displayName = 'proto.financial.ResolvePaymentStateRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.financial.ResolvePaymentStateResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.financial.ResolvePaymentStateResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.financial.ResolvePaymentStateResponse.displayName = 'proto.financial.ResolvePaymentStateResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.financial.ResolvePaymentIDStateRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.financial.ResolvePaymentIDStateRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.financial.ResolvePaymentIDStateRequest.displayName = 'proto.financial.ResolvePaymentIDStateRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.financial.ResolvePaymentIDStateResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.financial.ResolvePaymentIDStateResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.financial.ResolvePaymentIDStateResponse.displayName = 'proto.financial.ResolvePaymentIDStateResponse';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.financial.InitialisePaymentRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.financial.InitialisePaymentRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.financial.InitialisePaymentRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.financial.InitialisePaymentRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
context: (f = msg.getContext()) && security_context_pb.ContextFuture.toObject(includeInstance, f),
paymenttrigger: (f = msg.getPaymenttrigger()) && financial_paymentTrigger_pb.PaymentTrigger.toObject(includeInstance, f),
amount: (f = msg.getAmount()) && ledger_futureAmount_pb.FutureAmount.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.financial.InitialisePaymentRequest}
 */
proto.financial.InitialisePaymentRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.financial.InitialisePaymentRequest;
  return proto.financial.InitialisePaymentRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.financial.InitialisePaymentRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.financial.InitialisePaymentRequest}
 */
proto.financial.InitialisePaymentRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new security_context_pb.ContextFuture;
      reader.readMessage(value,security_context_pb.ContextFuture.deserializeBinaryFromReader);
      msg.setContext(value);
      break;
    case 2:
      var value = new financial_paymentTrigger_pb.PaymentTrigger;
      reader.readMessage(value,financial_paymentTrigger_pb.PaymentTrigger.deserializeBinaryFromReader);
      msg.setPaymenttrigger(value);
      break;
    case 3:
      var value = new ledger_futureAmount_pb.FutureAmount;
      reader.readMessage(value,ledger_futureAmount_pb.FutureAmount.deserializeBinaryFromReader);
      msg.setAmount(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.financial.InitialisePaymentRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.financial.InitialisePaymentRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.financial.InitialisePaymentRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.financial.InitialisePaymentRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getContext();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      security_context_pb.ContextFuture.serializeBinaryToWriter
    );
  }
  f = message.getPaymenttrigger();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      financial_paymentTrigger_pb.PaymentTrigger.serializeBinaryToWriter
    );
  }
  f = message.getAmount();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      ledger_futureAmount_pb.FutureAmount.serializeBinaryToWriter
    );
  }
};


/**
 * optional security.ContextFuture context = 1;
 * @return {?proto.security.ContextFuture}
 */
proto.financial.InitialisePaymentRequest.prototype.getContext = function() {
  return /** @type{?proto.security.ContextFuture} */ (
    jspb.Message.getWrapperField(this, security_context_pb.ContextFuture, 1));
};


/**
 * @param {?proto.security.ContextFuture|undefined} value
 * @return {!proto.financial.InitialisePaymentRequest} returns this
*/
proto.financial.InitialisePaymentRequest.prototype.setContext = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.financial.InitialisePaymentRequest} returns this
 */
proto.financial.InitialisePaymentRequest.prototype.clearContext = function() {
  return this.setContext(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.financial.InitialisePaymentRequest.prototype.hasContext = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional PaymentTrigger paymentTrigger = 2;
 * @return {?proto.financial.PaymentTrigger}
 */
proto.financial.InitialisePaymentRequest.prototype.getPaymenttrigger = function() {
  return /** @type{?proto.financial.PaymentTrigger} */ (
    jspb.Message.getWrapperField(this, financial_paymentTrigger_pb.PaymentTrigger, 2));
};


/**
 * @param {?proto.financial.PaymentTrigger|undefined} value
 * @return {!proto.financial.InitialisePaymentRequest} returns this
*/
proto.financial.InitialisePaymentRequest.prototype.setPaymenttrigger = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.financial.InitialisePaymentRequest} returns this
 */
proto.financial.InitialisePaymentRequest.prototype.clearPaymenttrigger = function() {
  return this.setPaymenttrigger(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.financial.InitialisePaymentRequest.prototype.hasPaymenttrigger = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional ledger.FutureAmount amount = 3;
 * @return {?proto.ledger.FutureAmount}
 */
proto.financial.InitialisePaymentRequest.prototype.getAmount = function() {
  return /** @type{?proto.ledger.FutureAmount} */ (
    jspb.Message.getWrapperField(this, ledger_futureAmount_pb.FutureAmount, 3));
};


/**
 * @param {?proto.ledger.FutureAmount|undefined} value
 * @return {!proto.financial.InitialisePaymentRequest} returns this
*/
proto.financial.InitialisePaymentRequest.prototype.setAmount = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.financial.InitialisePaymentRequest} returns this
 */
proto.financial.InitialisePaymentRequest.prototype.clearAmount = function() {
  return this.setAmount(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.financial.InitialisePaymentRequest.prototype.hasAmount = function() {
  return jspb.Message.getField(this, 3) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.financial.InitialisePaymentResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.financial.InitialisePaymentResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.financial.InitialisePaymentResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.financial.InitialisePaymentResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
payment: (f = msg.getPayment()) && financial_payment_pb.Payment.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.financial.InitialisePaymentResponse}
 */
proto.financial.InitialisePaymentResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.financial.InitialisePaymentResponse;
  return proto.financial.InitialisePaymentResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.financial.InitialisePaymentResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.financial.InitialisePaymentResponse}
 */
proto.financial.InitialisePaymentResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new financial_payment_pb.Payment;
      reader.readMessage(value,financial_payment_pb.Payment.deserializeBinaryFromReader);
      msg.setPayment(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.financial.InitialisePaymentResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.financial.InitialisePaymentResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.financial.InitialisePaymentResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.financial.InitialisePaymentResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPayment();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      financial_payment_pb.Payment.serializeBinaryToWriter
    );
  }
};


/**
 * optional Payment payment = 1;
 * @return {?proto.financial.Payment}
 */
proto.financial.InitialisePaymentResponse.prototype.getPayment = function() {
  return /** @type{?proto.financial.Payment} */ (
    jspb.Message.getWrapperField(this, financial_payment_pb.Payment, 1));
};


/**
 * @param {?proto.financial.Payment|undefined} value
 * @return {!proto.financial.InitialisePaymentResponse} returns this
*/
proto.financial.InitialisePaymentResponse.prototype.setPayment = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.financial.InitialisePaymentResponse} returns this
 */
proto.financial.InitialisePaymentResponse.prototype.clearPayment = function() {
  return this.setPayment(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.financial.InitialisePaymentResponse.prototype.hasPayment = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.financial.PayPaymentRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.financial.PayPaymentRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.financial.PayPaymentRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.financial.PayPaymentRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
context: (f = msg.getContext()) && security_context_pb.ContextFuture.toObject(includeInstance, f),
payment: (f = msg.getPayment()) && financial_payment_pb.Payment.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.financial.PayPaymentRequest}
 */
proto.financial.PayPaymentRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.financial.PayPaymentRequest;
  return proto.financial.PayPaymentRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.financial.PayPaymentRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.financial.PayPaymentRequest}
 */
proto.financial.PayPaymentRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new security_context_pb.ContextFuture;
      reader.readMessage(value,security_context_pb.ContextFuture.deserializeBinaryFromReader);
      msg.setContext(value);
      break;
    case 2:
      var value = new financial_payment_pb.Payment;
      reader.readMessage(value,financial_payment_pb.Payment.deserializeBinaryFromReader);
      msg.setPayment(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.financial.PayPaymentRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.financial.PayPaymentRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.financial.PayPaymentRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.financial.PayPaymentRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getContext();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      security_context_pb.ContextFuture.serializeBinaryToWriter
    );
  }
  f = message.getPayment();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      financial_payment_pb.Payment.serializeBinaryToWriter
    );
  }
};


/**
 * optional security.ContextFuture context = 1;
 * @return {?proto.security.ContextFuture}
 */
proto.financial.PayPaymentRequest.prototype.getContext = function() {
  return /** @type{?proto.security.ContextFuture} */ (
    jspb.Message.getWrapperField(this, security_context_pb.ContextFuture, 1));
};


/**
 * @param {?proto.security.ContextFuture|undefined} value
 * @return {!proto.financial.PayPaymentRequest} returns this
*/
proto.financial.PayPaymentRequest.prototype.setContext = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.financial.PayPaymentRequest} returns this
 */
proto.financial.PayPaymentRequest.prototype.clearContext = function() {
  return this.setContext(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.financial.PayPaymentRequest.prototype.hasContext = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional Payment payment = 2;
 * @return {?proto.financial.Payment}
 */
proto.financial.PayPaymentRequest.prototype.getPayment = function() {
  return /** @type{?proto.financial.Payment} */ (
    jspb.Message.getWrapperField(this, financial_payment_pb.Payment, 2));
};


/**
 * @param {?proto.financial.Payment|undefined} value
 * @return {!proto.financial.PayPaymentRequest} returns this
*/
proto.financial.PayPaymentRequest.prototype.setPayment = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.financial.PayPaymentRequest} returns this
 */
proto.financial.PayPaymentRequest.prototype.clearPayment = function() {
  return this.setPayment(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.financial.PayPaymentRequest.prototype.hasPayment = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.financial.PayPaymentResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.financial.PayPaymentResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.financial.PayPaymentResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.financial.PayPaymentResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
payment: (f = msg.getPayment()) && financial_payment_pb.Payment.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.financial.PayPaymentResponse}
 */
proto.financial.PayPaymentResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.financial.PayPaymentResponse;
  return proto.financial.PayPaymentResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.financial.PayPaymentResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.financial.PayPaymentResponse}
 */
proto.financial.PayPaymentResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new financial_payment_pb.Payment;
      reader.readMessage(value,financial_payment_pb.Payment.deserializeBinaryFromReader);
      msg.setPayment(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.financial.PayPaymentResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.financial.PayPaymentResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.financial.PayPaymentResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.financial.PayPaymentResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPayment();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      financial_payment_pb.Payment.serializeBinaryToWriter
    );
  }
};


/**
 * optional Payment payment = 1;
 * @return {?proto.financial.Payment}
 */
proto.financial.PayPaymentResponse.prototype.getPayment = function() {
  return /** @type{?proto.financial.Payment} */ (
    jspb.Message.getWrapperField(this, financial_payment_pb.Payment, 1));
};


/**
 * @param {?proto.financial.Payment|undefined} value
 * @return {!proto.financial.PayPaymentResponse} returns this
*/
proto.financial.PayPaymentResponse.prototype.setPayment = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.financial.PayPaymentResponse} returns this
 */
proto.financial.PayPaymentResponse.prototype.clearPayment = function() {
  return this.setPayment(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.financial.PayPaymentResponse.prototype.hasPayment = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.financial.MarkPaymentFailedRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.financial.MarkPaymentFailedRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.financial.MarkPaymentFailedRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.financial.MarkPaymentFailedRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
context: (f = msg.getContext()) && security_context_pb.ContextFuture.toObject(includeInstance, f),
payment: (f = msg.getPayment()) && financial_payment_pb.Payment.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.financial.MarkPaymentFailedRequest}
 */
proto.financial.MarkPaymentFailedRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.financial.MarkPaymentFailedRequest;
  return proto.financial.MarkPaymentFailedRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.financial.MarkPaymentFailedRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.financial.MarkPaymentFailedRequest}
 */
proto.financial.MarkPaymentFailedRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new security_context_pb.ContextFuture;
      reader.readMessage(value,security_context_pb.ContextFuture.deserializeBinaryFromReader);
      msg.setContext(value);
      break;
    case 2:
      var value = new financial_payment_pb.Payment;
      reader.readMessage(value,financial_payment_pb.Payment.deserializeBinaryFromReader);
      msg.setPayment(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.financial.MarkPaymentFailedRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.financial.MarkPaymentFailedRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.financial.MarkPaymentFailedRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.financial.MarkPaymentFailedRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getContext();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      security_context_pb.ContextFuture.serializeBinaryToWriter
    );
  }
  f = message.getPayment();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      financial_payment_pb.Payment.serializeBinaryToWriter
    );
  }
};


/**
 * optional security.ContextFuture context = 1;
 * @return {?proto.security.ContextFuture}
 */
proto.financial.MarkPaymentFailedRequest.prototype.getContext = function() {
  return /** @type{?proto.security.ContextFuture} */ (
    jspb.Message.getWrapperField(this, security_context_pb.ContextFuture, 1));
};


/**
 * @param {?proto.security.ContextFuture|undefined} value
 * @return {!proto.financial.MarkPaymentFailedRequest} returns this
*/
proto.financial.MarkPaymentFailedRequest.prototype.setContext = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.financial.MarkPaymentFailedRequest} returns this
 */
proto.financial.MarkPaymentFailedRequest.prototype.clearContext = function() {
  return this.setContext(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.financial.MarkPaymentFailedRequest.prototype.hasContext = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional Payment payment = 2;
 * @return {?proto.financial.Payment}
 */
proto.financial.MarkPaymentFailedRequest.prototype.getPayment = function() {
  return /** @type{?proto.financial.Payment} */ (
    jspb.Message.getWrapperField(this, financial_payment_pb.Payment, 2));
};


/**
 * @param {?proto.financial.Payment|undefined} value
 * @return {!proto.financial.MarkPaymentFailedRequest} returns this
*/
proto.financial.MarkPaymentFailedRequest.prototype.setPayment = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.financial.MarkPaymentFailedRequest} returns this
 */
proto.financial.MarkPaymentFailedRequest.prototype.clearPayment = function() {
  return this.setPayment(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.financial.MarkPaymentFailedRequest.prototype.hasPayment = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.financial.MarkPaymentFailedResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.financial.MarkPaymentFailedResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.financial.MarkPaymentFailedResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.financial.MarkPaymentFailedResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
payment: (f = msg.getPayment()) && financial_payment_pb.Payment.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.financial.MarkPaymentFailedResponse}
 */
proto.financial.MarkPaymentFailedResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.financial.MarkPaymentFailedResponse;
  return proto.financial.MarkPaymentFailedResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.financial.MarkPaymentFailedResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.financial.MarkPaymentFailedResponse}
 */
proto.financial.MarkPaymentFailedResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new financial_payment_pb.Payment;
      reader.readMessage(value,financial_payment_pb.Payment.deserializeBinaryFromReader);
      msg.setPayment(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.financial.MarkPaymentFailedResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.financial.MarkPaymentFailedResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.financial.MarkPaymentFailedResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.financial.MarkPaymentFailedResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPayment();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      financial_payment_pb.Payment.serializeBinaryToWriter
    );
  }
};


/**
 * optional Payment payment = 1;
 * @return {?proto.financial.Payment}
 */
proto.financial.MarkPaymentFailedResponse.prototype.getPayment = function() {
  return /** @type{?proto.financial.Payment} */ (
    jspb.Message.getWrapperField(this, financial_payment_pb.Payment, 1));
};


/**
 * @param {?proto.financial.Payment|undefined} value
 * @return {!proto.financial.MarkPaymentFailedResponse} returns this
*/
proto.financial.MarkPaymentFailedResponse.prototype.setPayment = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.financial.MarkPaymentFailedResponse} returns this
 */
proto.financial.MarkPaymentFailedResponse.prototype.clearPayment = function() {
  return this.setPayment(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.financial.MarkPaymentFailedResponse.prototype.hasPayment = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.financial.MarkPaymentPaidRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.financial.MarkPaymentPaidRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.financial.MarkPaymentPaidRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.financial.MarkPaymentPaidRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
context: (f = msg.getContext()) && security_context_pb.ContextFuture.toObject(includeInstance, f),
payment: (f = msg.getPayment()) && financial_payment_pb.Payment.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.financial.MarkPaymentPaidRequest}
 */
proto.financial.MarkPaymentPaidRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.financial.MarkPaymentPaidRequest;
  return proto.financial.MarkPaymentPaidRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.financial.MarkPaymentPaidRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.financial.MarkPaymentPaidRequest}
 */
proto.financial.MarkPaymentPaidRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new security_context_pb.ContextFuture;
      reader.readMessage(value,security_context_pb.ContextFuture.deserializeBinaryFromReader);
      msg.setContext(value);
      break;
    case 2:
      var value = new financial_payment_pb.Payment;
      reader.readMessage(value,financial_payment_pb.Payment.deserializeBinaryFromReader);
      msg.setPayment(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.financial.MarkPaymentPaidRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.financial.MarkPaymentPaidRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.financial.MarkPaymentPaidRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.financial.MarkPaymentPaidRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getContext();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      security_context_pb.ContextFuture.serializeBinaryToWriter
    );
  }
  f = message.getPayment();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      financial_payment_pb.Payment.serializeBinaryToWriter
    );
  }
};


/**
 * optional security.ContextFuture context = 1;
 * @return {?proto.security.ContextFuture}
 */
proto.financial.MarkPaymentPaidRequest.prototype.getContext = function() {
  return /** @type{?proto.security.ContextFuture} */ (
    jspb.Message.getWrapperField(this, security_context_pb.ContextFuture, 1));
};


/**
 * @param {?proto.security.ContextFuture|undefined} value
 * @return {!proto.financial.MarkPaymentPaidRequest} returns this
*/
proto.financial.MarkPaymentPaidRequest.prototype.setContext = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.financial.MarkPaymentPaidRequest} returns this
 */
proto.financial.MarkPaymentPaidRequest.prototype.clearContext = function() {
  return this.setContext(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.financial.MarkPaymentPaidRequest.prototype.hasContext = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional Payment payment = 2;
 * @return {?proto.financial.Payment}
 */
proto.financial.MarkPaymentPaidRequest.prototype.getPayment = function() {
  return /** @type{?proto.financial.Payment} */ (
    jspb.Message.getWrapperField(this, financial_payment_pb.Payment, 2));
};


/**
 * @param {?proto.financial.Payment|undefined} value
 * @return {!proto.financial.MarkPaymentPaidRequest} returns this
*/
proto.financial.MarkPaymentPaidRequest.prototype.setPayment = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.financial.MarkPaymentPaidRequest} returns this
 */
proto.financial.MarkPaymentPaidRequest.prototype.clearPayment = function() {
  return this.setPayment(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.financial.MarkPaymentPaidRequest.prototype.hasPayment = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.financial.MarkPaymentPaidResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.financial.MarkPaymentPaidResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.financial.MarkPaymentPaidResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.financial.MarkPaymentPaidResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
payment: (f = msg.getPayment()) && financial_payment_pb.Payment.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.financial.MarkPaymentPaidResponse}
 */
proto.financial.MarkPaymentPaidResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.financial.MarkPaymentPaidResponse;
  return proto.financial.MarkPaymentPaidResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.financial.MarkPaymentPaidResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.financial.MarkPaymentPaidResponse}
 */
proto.financial.MarkPaymentPaidResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new financial_payment_pb.Payment;
      reader.readMessage(value,financial_payment_pb.Payment.deserializeBinaryFromReader);
      msg.setPayment(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.financial.MarkPaymentPaidResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.financial.MarkPaymentPaidResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.financial.MarkPaymentPaidResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.financial.MarkPaymentPaidResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPayment();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      financial_payment_pb.Payment.serializeBinaryToWriter
    );
  }
};


/**
 * optional Payment payment = 1;
 * @return {?proto.financial.Payment}
 */
proto.financial.MarkPaymentPaidResponse.prototype.getPayment = function() {
  return /** @type{?proto.financial.Payment} */ (
    jspb.Message.getWrapperField(this, financial_payment_pb.Payment, 1));
};


/**
 * @param {?proto.financial.Payment|undefined} value
 * @return {!proto.financial.MarkPaymentPaidResponse} returns this
*/
proto.financial.MarkPaymentPaidResponse.prototype.setPayment = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.financial.MarkPaymentPaidResponse} returns this
 */
proto.financial.MarkPaymentPaidResponse.prototype.clearPayment = function() {
  return this.setPayment(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.financial.MarkPaymentPaidResponse.prototype.hasPayment = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.financial.CleanUpPaymentRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.financial.CleanUpPaymentRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.financial.CleanUpPaymentRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.financial.CleanUpPaymentRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
context: (f = msg.getContext()) && security_context_pb.ContextFuture.toObject(includeInstance, f),
payment: (f = msg.getPayment()) && financial_payment_pb.Payment.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.financial.CleanUpPaymentRequest}
 */
proto.financial.CleanUpPaymentRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.financial.CleanUpPaymentRequest;
  return proto.financial.CleanUpPaymentRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.financial.CleanUpPaymentRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.financial.CleanUpPaymentRequest}
 */
proto.financial.CleanUpPaymentRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new security_context_pb.ContextFuture;
      reader.readMessage(value,security_context_pb.ContextFuture.deserializeBinaryFromReader);
      msg.setContext(value);
      break;
    case 2:
      var value = new financial_payment_pb.Payment;
      reader.readMessage(value,financial_payment_pb.Payment.deserializeBinaryFromReader);
      msg.setPayment(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.financial.CleanUpPaymentRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.financial.CleanUpPaymentRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.financial.CleanUpPaymentRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.financial.CleanUpPaymentRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getContext();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      security_context_pb.ContextFuture.serializeBinaryToWriter
    );
  }
  f = message.getPayment();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      financial_payment_pb.Payment.serializeBinaryToWriter
    );
  }
};


/**
 * optional security.ContextFuture context = 1;
 * @return {?proto.security.ContextFuture}
 */
proto.financial.CleanUpPaymentRequest.prototype.getContext = function() {
  return /** @type{?proto.security.ContextFuture} */ (
    jspb.Message.getWrapperField(this, security_context_pb.ContextFuture, 1));
};


/**
 * @param {?proto.security.ContextFuture|undefined} value
 * @return {!proto.financial.CleanUpPaymentRequest} returns this
*/
proto.financial.CleanUpPaymentRequest.prototype.setContext = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.financial.CleanUpPaymentRequest} returns this
 */
proto.financial.CleanUpPaymentRequest.prototype.clearContext = function() {
  return this.setContext(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.financial.CleanUpPaymentRequest.prototype.hasContext = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional Payment payment = 2;
 * @return {?proto.financial.Payment}
 */
proto.financial.CleanUpPaymentRequest.prototype.getPayment = function() {
  return /** @type{?proto.financial.Payment} */ (
    jspb.Message.getWrapperField(this, financial_payment_pb.Payment, 2));
};


/**
 * @param {?proto.financial.Payment|undefined} value
 * @return {!proto.financial.CleanUpPaymentRequest} returns this
*/
proto.financial.CleanUpPaymentRequest.prototype.setPayment = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.financial.CleanUpPaymentRequest} returns this
 */
proto.financial.CleanUpPaymentRequest.prototype.clearPayment = function() {
  return this.setPayment(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.financial.CleanUpPaymentRequest.prototype.hasPayment = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.financial.CleanUpPaymentResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.financial.CleanUpPaymentResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.financial.CleanUpPaymentResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.financial.CleanUpPaymentResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
payment: (f = msg.getPayment()) && financial_payment_pb.Payment.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.financial.CleanUpPaymentResponse}
 */
proto.financial.CleanUpPaymentResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.financial.CleanUpPaymentResponse;
  return proto.financial.CleanUpPaymentResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.financial.CleanUpPaymentResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.financial.CleanUpPaymentResponse}
 */
proto.financial.CleanUpPaymentResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new financial_payment_pb.Payment;
      reader.readMessage(value,financial_payment_pb.Payment.deserializeBinaryFromReader);
      msg.setPayment(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.financial.CleanUpPaymentResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.financial.CleanUpPaymentResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.financial.CleanUpPaymentResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.financial.CleanUpPaymentResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPayment();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      financial_payment_pb.Payment.serializeBinaryToWriter
    );
  }
};


/**
 * optional Payment payment = 1;
 * @return {?proto.financial.Payment}
 */
proto.financial.CleanUpPaymentResponse.prototype.getPayment = function() {
  return /** @type{?proto.financial.Payment} */ (
    jspb.Message.getWrapperField(this, financial_payment_pb.Payment, 1));
};


/**
 * @param {?proto.financial.Payment|undefined} value
 * @return {!proto.financial.CleanUpPaymentResponse} returns this
*/
proto.financial.CleanUpPaymentResponse.prototype.setPayment = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.financial.CleanUpPaymentResponse} returns this
 */
proto.financial.CleanUpPaymentResponse.prototype.clearPayment = function() {
  return this.setPayment(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.financial.CleanUpPaymentResponse.prototype.hasPayment = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.financial.MarkPaymentCleanupUnderInvestigationRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.financial.MarkPaymentCleanupUnderInvestigationRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.financial.MarkPaymentCleanupUnderInvestigationRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.financial.MarkPaymentCleanupUnderInvestigationRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
context: (f = msg.getContext()) && security_context_pb.ContextFuture.toObject(includeInstance, f),
payment: (f = msg.getPayment()) && financial_payment_pb.Payment.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.financial.MarkPaymentCleanupUnderInvestigationRequest}
 */
proto.financial.MarkPaymentCleanupUnderInvestigationRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.financial.MarkPaymentCleanupUnderInvestigationRequest;
  return proto.financial.MarkPaymentCleanupUnderInvestigationRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.financial.MarkPaymentCleanupUnderInvestigationRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.financial.MarkPaymentCleanupUnderInvestigationRequest}
 */
proto.financial.MarkPaymentCleanupUnderInvestigationRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new security_context_pb.ContextFuture;
      reader.readMessage(value,security_context_pb.ContextFuture.deserializeBinaryFromReader);
      msg.setContext(value);
      break;
    case 2:
      var value = new financial_payment_pb.Payment;
      reader.readMessage(value,financial_payment_pb.Payment.deserializeBinaryFromReader);
      msg.setPayment(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.financial.MarkPaymentCleanupUnderInvestigationRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.financial.MarkPaymentCleanupUnderInvestigationRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.financial.MarkPaymentCleanupUnderInvestigationRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.financial.MarkPaymentCleanupUnderInvestigationRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getContext();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      security_context_pb.ContextFuture.serializeBinaryToWriter
    );
  }
  f = message.getPayment();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      financial_payment_pb.Payment.serializeBinaryToWriter
    );
  }
};


/**
 * optional security.ContextFuture context = 1;
 * @return {?proto.security.ContextFuture}
 */
proto.financial.MarkPaymentCleanupUnderInvestigationRequest.prototype.getContext = function() {
  return /** @type{?proto.security.ContextFuture} */ (
    jspb.Message.getWrapperField(this, security_context_pb.ContextFuture, 1));
};


/**
 * @param {?proto.security.ContextFuture|undefined} value
 * @return {!proto.financial.MarkPaymentCleanupUnderInvestigationRequest} returns this
*/
proto.financial.MarkPaymentCleanupUnderInvestigationRequest.prototype.setContext = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.financial.MarkPaymentCleanupUnderInvestigationRequest} returns this
 */
proto.financial.MarkPaymentCleanupUnderInvestigationRequest.prototype.clearContext = function() {
  return this.setContext(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.financial.MarkPaymentCleanupUnderInvestigationRequest.prototype.hasContext = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional Payment payment = 2;
 * @return {?proto.financial.Payment}
 */
proto.financial.MarkPaymentCleanupUnderInvestigationRequest.prototype.getPayment = function() {
  return /** @type{?proto.financial.Payment} */ (
    jspb.Message.getWrapperField(this, financial_payment_pb.Payment, 2));
};


/**
 * @param {?proto.financial.Payment|undefined} value
 * @return {!proto.financial.MarkPaymentCleanupUnderInvestigationRequest} returns this
*/
proto.financial.MarkPaymentCleanupUnderInvestigationRequest.prototype.setPayment = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.financial.MarkPaymentCleanupUnderInvestigationRequest} returns this
 */
proto.financial.MarkPaymentCleanupUnderInvestigationRequest.prototype.clearPayment = function() {
  return this.setPayment(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.financial.MarkPaymentCleanupUnderInvestigationRequest.prototype.hasPayment = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.financial.MarkPaymentCleanupUnderInvestigationResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.financial.MarkPaymentCleanupUnderInvestigationResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.financial.MarkPaymentCleanupUnderInvestigationResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.financial.MarkPaymentCleanupUnderInvestigationResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
payment: (f = msg.getPayment()) && financial_payment_pb.Payment.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.financial.MarkPaymentCleanupUnderInvestigationResponse}
 */
proto.financial.MarkPaymentCleanupUnderInvestigationResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.financial.MarkPaymentCleanupUnderInvestigationResponse;
  return proto.financial.MarkPaymentCleanupUnderInvestigationResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.financial.MarkPaymentCleanupUnderInvestigationResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.financial.MarkPaymentCleanupUnderInvestigationResponse}
 */
proto.financial.MarkPaymentCleanupUnderInvestigationResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new financial_payment_pb.Payment;
      reader.readMessage(value,financial_payment_pb.Payment.deserializeBinaryFromReader);
      msg.setPayment(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.financial.MarkPaymentCleanupUnderInvestigationResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.financial.MarkPaymentCleanupUnderInvestigationResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.financial.MarkPaymentCleanupUnderInvestigationResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.financial.MarkPaymentCleanupUnderInvestigationResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPayment();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      financial_payment_pb.Payment.serializeBinaryToWriter
    );
  }
};


/**
 * optional Payment payment = 1;
 * @return {?proto.financial.Payment}
 */
proto.financial.MarkPaymentCleanupUnderInvestigationResponse.prototype.getPayment = function() {
  return /** @type{?proto.financial.Payment} */ (
    jspb.Message.getWrapperField(this, financial_payment_pb.Payment, 1));
};


/**
 * @param {?proto.financial.Payment|undefined} value
 * @return {!proto.financial.MarkPaymentCleanupUnderInvestigationResponse} returns this
*/
proto.financial.MarkPaymentCleanupUnderInvestigationResponse.prototype.setPayment = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.financial.MarkPaymentCleanupUnderInvestigationResponse} returns this
 */
proto.financial.MarkPaymentCleanupUnderInvestigationResponse.prototype.clearPayment = function() {
  return this.setPayment(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.financial.MarkPaymentCleanupUnderInvestigationResponse.prototype.hasPayment = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.financial.MarkPaymentCleanupFailedRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.financial.MarkPaymentCleanupFailedRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.financial.MarkPaymentCleanupFailedRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.financial.MarkPaymentCleanupFailedRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
context: (f = msg.getContext()) && security_context_pb.ContextFuture.toObject(includeInstance, f),
payment: (f = msg.getPayment()) && financial_payment_pb.Payment.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.financial.MarkPaymentCleanupFailedRequest}
 */
proto.financial.MarkPaymentCleanupFailedRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.financial.MarkPaymentCleanupFailedRequest;
  return proto.financial.MarkPaymentCleanupFailedRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.financial.MarkPaymentCleanupFailedRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.financial.MarkPaymentCleanupFailedRequest}
 */
proto.financial.MarkPaymentCleanupFailedRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new security_context_pb.ContextFuture;
      reader.readMessage(value,security_context_pb.ContextFuture.deserializeBinaryFromReader);
      msg.setContext(value);
      break;
    case 2:
      var value = new financial_payment_pb.Payment;
      reader.readMessage(value,financial_payment_pb.Payment.deserializeBinaryFromReader);
      msg.setPayment(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.financial.MarkPaymentCleanupFailedRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.financial.MarkPaymentCleanupFailedRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.financial.MarkPaymentCleanupFailedRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.financial.MarkPaymentCleanupFailedRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getContext();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      security_context_pb.ContextFuture.serializeBinaryToWriter
    );
  }
  f = message.getPayment();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      financial_payment_pb.Payment.serializeBinaryToWriter
    );
  }
};


/**
 * optional security.ContextFuture context = 1;
 * @return {?proto.security.ContextFuture}
 */
proto.financial.MarkPaymentCleanupFailedRequest.prototype.getContext = function() {
  return /** @type{?proto.security.ContextFuture} */ (
    jspb.Message.getWrapperField(this, security_context_pb.ContextFuture, 1));
};


/**
 * @param {?proto.security.ContextFuture|undefined} value
 * @return {!proto.financial.MarkPaymentCleanupFailedRequest} returns this
*/
proto.financial.MarkPaymentCleanupFailedRequest.prototype.setContext = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.financial.MarkPaymentCleanupFailedRequest} returns this
 */
proto.financial.MarkPaymentCleanupFailedRequest.prototype.clearContext = function() {
  return this.setContext(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.financial.MarkPaymentCleanupFailedRequest.prototype.hasContext = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional Payment payment = 2;
 * @return {?proto.financial.Payment}
 */
proto.financial.MarkPaymentCleanupFailedRequest.prototype.getPayment = function() {
  return /** @type{?proto.financial.Payment} */ (
    jspb.Message.getWrapperField(this, financial_payment_pb.Payment, 2));
};


/**
 * @param {?proto.financial.Payment|undefined} value
 * @return {!proto.financial.MarkPaymentCleanupFailedRequest} returns this
*/
proto.financial.MarkPaymentCleanupFailedRequest.prototype.setPayment = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.financial.MarkPaymentCleanupFailedRequest} returns this
 */
proto.financial.MarkPaymentCleanupFailedRequest.prototype.clearPayment = function() {
  return this.setPayment(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.financial.MarkPaymentCleanupFailedRequest.prototype.hasPayment = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.financial.MarkPaymentCleanupFailedResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.financial.MarkPaymentCleanupFailedResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.financial.MarkPaymentCleanupFailedResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.financial.MarkPaymentCleanupFailedResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
payment: (f = msg.getPayment()) && financial_payment_pb.Payment.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.financial.MarkPaymentCleanupFailedResponse}
 */
proto.financial.MarkPaymentCleanupFailedResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.financial.MarkPaymentCleanupFailedResponse;
  return proto.financial.MarkPaymentCleanupFailedResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.financial.MarkPaymentCleanupFailedResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.financial.MarkPaymentCleanupFailedResponse}
 */
proto.financial.MarkPaymentCleanupFailedResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new financial_payment_pb.Payment;
      reader.readMessage(value,financial_payment_pb.Payment.deserializeBinaryFromReader);
      msg.setPayment(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.financial.MarkPaymentCleanupFailedResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.financial.MarkPaymentCleanupFailedResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.financial.MarkPaymentCleanupFailedResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.financial.MarkPaymentCleanupFailedResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPayment();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      financial_payment_pb.Payment.serializeBinaryToWriter
    );
  }
};


/**
 * optional Payment payment = 1;
 * @return {?proto.financial.Payment}
 */
proto.financial.MarkPaymentCleanupFailedResponse.prototype.getPayment = function() {
  return /** @type{?proto.financial.Payment} */ (
    jspb.Message.getWrapperField(this, financial_payment_pb.Payment, 1));
};


/**
 * @param {?proto.financial.Payment|undefined} value
 * @return {!proto.financial.MarkPaymentCleanupFailedResponse} returns this
*/
proto.financial.MarkPaymentCleanupFailedResponse.prototype.setPayment = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.financial.MarkPaymentCleanupFailedResponse} returns this
 */
proto.financial.MarkPaymentCleanupFailedResponse.prototype.clearPayment = function() {
  return this.setPayment(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.financial.MarkPaymentCleanupFailedResponse.prototype.hasPayment = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.financial.MarkPaymentCompleteRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.financial.MarkPaymentCompleteRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.financial.MarkPaymentCompleteRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.financial.MarkPaymentCompleteRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
context: (f = msg.getContext()) && security_context_pb.ContextFuture.toObject(includeInstance, f),
payment: (f = msg.getPayment()) && financial_payment_pb.Payment.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.financial.MarkPaymentCompleteRequest}
 */
proto.financial.MarkPaymentCompleteRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.financial.MarkPaymentCompleteRequest;
  return proto.financial.MarkPaymentCompleteRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.financial.MarkPaymentCompleteRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.financial.MarkPaymentCompleteRequest}
 */
proto.financial.MarkPaymentCompleteRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new security_context_pb.ContextFuture;
      reader.readMessage(value,security_context_pb.ContextFuture.deserializeBinaryFromReader);
      msg.setContext(value);
      break;
    case 2:
      var value = new financial_payment_pb.Payment;
      reader.readMessage(value,financial_payment_pb.Payment.deserializeBinaryFromReader);
      msg.setPayment(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.financial.MarkPaymentCompleteRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.financial.MarkPaymentCompleteRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.financial.MarkPaymentCompleteRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.financial.MarkPaymentCompleteRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getContext();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      security_context_pb.ContextFuture.serializeBinaryToWriter
    );
  }
  f = message.getPayment();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      financial_payment_pb.Payment.serializeBinaryToWriter
    );
  }
};


/**
 * optional security.ContextFuture context = 1;
 * @return {?proto.security.ContextFuture}
 */
proto.financial.MarkPaymentCompleteRequest.prototype.getContext = function() {
  return /** @type{?proto.security.ContextFuture} */ (
    jspb.Message.getWrapperField(this, security_context_pb.ContextFuture, 1));
};


/**
 * @param {?proto.security.ContextFuture|undefined} value
 * @return {!proto.financial.MarkPaymentCompleteRequest} returns this
*/
proto.financial.MarkPaymentCompleteRequest.prototype.setContext = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.financial.MarkPaymentCompleteRequest} returns this
 */
proto.financial.MarkPaymentCompleteRequest.prototype.clearContext = function() {
  return this.setContext(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.financial.MarkPaymentCompleteRequest.prototype.hasContext = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional Payment payment = 2;
 * @return {?proto.financial.Payment}
 */
proto.financial.MarkPaymentCompleteRequest.prototype.getPayment = function() {
  return /** @type{?proto.financial.Payment} */ (
    jspb.Message.getWrapperField(this, financial_payment_pb.Payment, 2));
};


/**
 * @param {?proto.financial.Payment|undefined} value
 * @return {!proto.financial.MarkPaymentCompleteRequest} returns this
*/
proto.financial.MarkPaymentCompleteRequest.prototype.setPayment = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.financial.MarkPaymentCompleteRequest} returns this
 */
proto.financial.MarkPaymentCompleteRequest.prototype.clearPayment = function() {
  return this.setPayment(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.financial.MarkPaymentCompleteRequest.prototype.hasPayment = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.financial.MarkPaymentCompleteResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.financial.MarkPaymentCompleteResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.financial.MarkPaymentCompleteResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.financial.MarkPaymentCompleteResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
payment: (f = msg.getPayment()) && financial_payment_pb.Payment.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.financial.MarkPaymentCompleteResponse}
 */
proto.financial.MarkPaymentCompleteResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.financial.MarkPaymentCompleteResponse;
  return proto.financial.MarkPaymentCompleteResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.financial.MarkPaymentCompleteResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.financial.MarkPaymentCompleteResponse}
 */
proto.financial.MarkPaymentCompleteResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new financial_payment_pb.Payment;
      reader.readMessage(value,financial_payment_pb.Payment.deserializeBinaryFromReader);
      msg.setPayment(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.financial.MarkPaymentCompleteResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.financial.MarkPaymentCompleteResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.financial.MarkPaymentCompleteResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.financial.MarkPaymentCompleteResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPayment();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      financial_payment_pb.Payment.serializeBinaryToWriter
    );
  }
};


/**
 * optional Payment payment = 1;
 * @return {?proto.financial.Payment}
 */
proto.financial.MarkPaymentCompleteResponse.prototype.getPayment = function() {
  return /** @type{?proto.financial.Payment} */ (
    jspb.Message.getWrapperField(this, financial_payment_pb.Payment, 1));
};


/**
 * @param {?proto.financial.Payment|undefined} value
 * @return {!proto.financial.MarkPaymentCompleteResponse} returns this
*/
proto.financial.MarkPaymentCompleteResponse.prototype.setPayment = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.financial.MarkPaymentCompleteResponse} returns this
 */
proto.financial.MarkPaymentCompleteResponse.prototype.clearPayment = function() {
  return this.setPayment(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.financial.MarkPaymentCompleteResponse.prototype.hasPayment = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.financial.ResolvePaymentStateRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.financial.ResolvePaymentStateRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.financial.ResolvePaymentStateRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.financial.ResolvePaymentStateRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
context: (f = msg.getContext()) && security_context_pb.ContextFuture.toObject(includeInstance, f),
payment: (f = msg.getPayment()) && financial_payment_pb.Payment.toObject(includeInstance, f),
manualinvocation: jspb.Message.getBooleanFieldWithDefault(msg, 3, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.financial.ResolvePaymentStateRequest}
 */
proto.financial.ResolvePaymentStateRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.financial.ResolvePaymentStateRequest;
  return proto.financial.ResolvePaymentStateRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.financial.ResolvePaymentStateRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.financial.ResolvePaymentStateRequest}
 */
proto.financial.ResolvePaymentStateRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new security_context_pb.ContextFuture;
      reader.readMessage(value,security_context_pb.ContextFuture.deserializeBinaryFromReader);
      msg.setContext(value);
      break;
    case 2:
      var value = new financial_payment_pb.Payment;
      reader.readMessage(value,financial_payment_pb.Payment.deserializeBinaryFromReader);
      msg.setPayment(value);
      break;
    case 3:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setManualinvocation(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.financial.ResolvePaymentStateRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.financial.ResolvePaymentStateRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.financial.ResolvePaymentStateRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.financial.ResolvePaymentStateRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getContext();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      security_context_pb.ContextFuture.serializeBinaryToWriter
    );
  }
  f = message.getPayment();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      financial_payment_pb.Payment.serializeBinaryToWriter
    );
  }
  f = message.getManualinvocation();
  if (f) {
    writer.writeBool(
      3,
      f
    );
  }
};


/**
 * optional security.ContextFuture context = 1;
 * @return {?proto.security.ContextFuture}
 */
proto.financial.ResolvePaymentStateRequest.prototype.getContext = function() {
  return /** @type{?proto.security.ContextFuture} */ (
    jspb.Message.getWrapperField(this, security_context_pb.ContextFuture, 1));
};


/**
 * @param {?proto.security.ContextFuture|undefined} value
 * @return {!proto.financial.ResolvePaymentStateRequest} returns this
*/
proto.financial.ResolvePaymentStateRequest.prototype.setContext = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.financial.ResolvePaymentStateRequest} returns this
 */
proto.financial.ResolvePaymentStateRequest.prototype.clearContext = function() {
  return this.setContext(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.financial.ResolvePaymentStateRequest.prototype.hasContext = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional Payment payment = 2;
 * @return {?proto.financial.Payment}
 */
proto.financial.ResolvePaymentStateRequest.prototype.getPayment = function() {
  return /** @type{?proto.financial.Payment} */ (
    jspb.Message.getWrapperField(this, financial_payment_pb.Payment, 2));
};


/**
 * @param {?proto.financial.Payment|undefined} value
 * @return {!proto.financial.ResolvePaymentStateRequest} returns this
*/
proto.financial.ResolvePaymentStateRequest.prototype.setPayment = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.financial.ResolvePaymentStateRequest} returns this
 */
proto.financial.ResolvePaymentStateRequest.prototype.clearPayment = function() {
  return this.setPayment(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.financial.ResolvePaymentStateRequest.prototype.hasPayment = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional bool manualInvocation = 3;
 * @return {boolean}
 */
proto.financial.ResolvePaymentStateRequest.prototype.getManualinvocation = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 3, false));
};


/**
 * @param {boolean} value
 * @return {!proto.financial.ResolvePaymentStateRequest} returns this
 */
proto.financial.ResolvePaymentStateRequest.prototype.setManualinvocation = function(value) {
  return jspb.Message.setProto3BooleanField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.financial.ResolvePaymentStateResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.financial.ResolvePaymentStateResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.financial.ResolvePaymentStateResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.financial.ResolvePaymentStateResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
payment: (f = msg.getPayment()) && financial_payment_pb.Payment.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.financial.ResolvePaymentStateResponse}
 */
proto.financial.ResolvePaymentStateResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.financial.ResolvePaymentStateResponse;
  return proto.financial.ResolvePaymentStateResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.financial.ResolvePaymentStateResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.financial.ResolvePaymentStateResponse}
 */
proto.financial.ResolvePaymentStateResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new financial_payment_pb.Payment;
      reader.readMessage(value,financial_payment_pb.Payment.deserializeBinaryFromReader);
      msg.setPayment(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.financial.ResolvePaymentStateResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.financial.ResolvePaymentStateResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.financial.ResolvePaymentStateResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.financial.ResolvePaymentStateResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPayment();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      financial_payment_pb.Payment.serializeBinaryToWriter
    );
  }
};


/**
 * optional Payment payment = 1;
 * @return {?proto.financial.Payment}
 */
proto.financial.ResolvePaymentStateResponse.prototype.getPayment = function() {
  return /** @type{?proto.financial.Payment} */ (
    jspb.Message.getWrapperField(this, financial_payment_pb.Payment, 1));
};


/**
 * @param {?proto.financial.Payment|undefined} value
 * @return {!proto.financial.ResolvePaymentStateResponse} returns this
*/
proto.financial.ResolvePaymentStateResponse.prototype.setPayment = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.financial.ResolvePaymentStateResponse} returns this
 */
proto.financial.ResolvePaymentStateResponse.prototype.clearPayment = function() {
  return this.setPayment(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.financial.ResolvePaymentStateResponse.prototype.hasPayment = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.financial.ResolvePaymentIDStateRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.financial.ResolvePaymentIDStateRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.financial.ResolvePaymentIDStateRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.financial.ResolvePaymentIDStateRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
context: (f = msg.getContext()) && security_context_pb.ContextFuture.toObject(includeInstance, f),
paymentid: jspb.Message.getFieldWithDefault(msg, 2, ""),
manualinvocation: jspb.Message.getBooleanFieldWithDefault(msg, 3, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.financial.ResolvePaymentIDStateRequest}
 */
proto.financial.ResolvePaymentIDStateRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.financial.ResolvePaymentIDStateRequest;
  return proto.financial.ResolvePaymentIDStateRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.financial.ResolvePaymentIDStateRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.financial.ResolvePaymentIDStateRequest}
 */
proto.financial.ResolvePaymentIDStateRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new security_context_pb.ContextFuture;
      reader.readMessage(value,security_context_pb.ContextFuture.deserializeBinaryFromReader);
      msg.setContext(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setPaymentid(value);
      break;
    case 3:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setManualinvocation(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.financial.ResolvePaymentIDStateRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.financial.ResolvePaymentIDStateRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.financial.ResolvePaymentIDStateRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.financial.ResolvePaymentIDStateRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getContext();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      security_context_pb.ContextFuture.serializeBinaryToWriter
    );
  }
  f = message.getPaymentid();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getManualinvocation();
  if (f) {
    writer.writeBool(
      3,
      f
    );
  }
};


/**
 * optional security.ContextFuture context = 1;
 * @return {?proto.security.ContextFuture}
 */
proto.financial.ResolvePaymentIDStateRequest.prototype.getContext = function() {
  return /** @type{?proto.security.ContextFuture} */ (
    jspb.Message.getWrapperField(this, security_context_pb.ContextFuture, 1));
};


/**
 * @param {?proto.security.ContextFuture|undefined} value
 * @return {!proto.financial.ResolvePaymentIDStateRequest} returns this
*/
proto.financial.ResolvePaymentIDStateRequest.prototype.setContext = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.financial.ResolvePaymentIDStateRequest} returns this
 */
proto.financial.ResolvePaymentIDStateRequest.prototype.clearContext = function() {
  return this.setContext(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.financial.ResolvePaymentIDStateRequest.prototype.hasContext = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional string paymentID = 2;
 * @return {string}
 */
proto.financial.ResolvePaymentIDStateRequest.prototype.getPaymentid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.financial.ResolvePaymentIDStateRequest} returns this
 */
proto.financial.ResolvePaymentIDStateRequest.prototype.setPaymentid = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional bool manualInvocation = 3;
 * @return {boolean}
 */
proto.financial.ResolvePaymentIDStateRequest.prototype.getManualinvocation = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 3, false));
};


/**
 * @param {boolean} value
 * @return {!proto.financial.ResolvePaymentIDStateRequest} returns this
 */
proto.financial.ResolvePaymentIDStateRequest.prototype.setManualinvocation = function(value) {
  return jspb.Message.setProto3BooleanField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.financial.ResolvePaymentIDStateResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.financial.ResolvePaymentIDStateResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.financial.ResolvePaymentIDStateResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.financial.ResolvePaymentIDStateResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
payment: (f = msg.getPayment()) && financial_payment_pb.Payment.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.financial.ResolvePaymentIDStateResponse}
 */
proto.financial.ResolvePaymentIDStateResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.financial.ResolvePaymentIDStateResponse;
  return proto.financial.ResolvePaymentIDStateResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.financial.ResolvePaymentIDStateResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.financial.ResolvePaymentIDStateResponse}
 */
proto.financial.ResolvePaymentIDStateResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new financial_payment_pb.Payment;
      reader.readMessage(value,financial_payment_pb.Payment.deserializeBinaryFromReader);
      msg.setPayment(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.financial.ResolvePaymentIDStateResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.financial.ResolvePaymentIDStateResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.financial.ResolvePaymentIDStateResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.financial.ResolvePaymentIDStateResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPayment();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      financial_payment_pb.Payment.serializeBinaryToWriter
    );
  }
};


/**
 * optional Payment payment = 1;
 * @return {?proto.financial.Payment}
 */
proto.financial.ResolvePaymentIDStateResponse.prototype.getPayment = function() {
  return /** @type{?proto.financial.Payment} */ (
    jspb.Message.getWrapperField(this, financial_payment_pb.Payment, 1));
};


/**
 * @param {?proto.financial.Payment|undefined} value
 * @return {!proto.financial.ResolvePaymentIDStateResponse} returns this
*/
proto.financial.ResolvePaymentIDStateResponse.prototype.setPayment = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.financial.ResolvePaymentIDStateResponse} returns this
 */
proto.financial.ResolvePaymentIDStateResponse.prototype.clearPayment = function() {
  return this.setPayment(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.financial.ResolvePaymentIDStateResponse.prototype.hasPayment = function() {
  return jspb.Message.getField(this, 1) != null;
};


goog.object.extend(exports, proto.financial);

import dayjs, { Dayjs } from "dayjs";

export class NewsArticle {
  public date: Dayjs = dayjs();
  public excerpt = "";
  public url = "";

  constructor(n?: NewsArticle) {
    if (!n) return;

    this.date = n.date;
    this.excerpt = n.excerpt;
    this.url = n.url;
  }
}

import React, { useEffect, useRef, useState } from "react";
import { Box, Theme, useMediaQuery } from "@mui/material";
import meshMiniLogo from "assets/images/logo/meshLogo.svg";
import { IndividualClientRegisterCard } from "./components/IndividualClient/IndividualClientRegisterCard";
import { UserRegisteringLoader } from "components/Loaders";
import { useUserRegistrationHelper } from "hooks/helperAPI/useUserRegistrationHelper";
import { useFirebaseContext } from "context/Firebase";
import background from "assets/images/background/background.png";
import { InitiateUserRegistration } from "../../const";
import { CompanyClientRegisterCard } from "./components/CompanyClient/CompanyClientRegisterCard";

export const Register = () => {
  const smUp = useMediaQuery((theme: Theme) => theme.breakpoints.up("sm"));

  const { firebaseUserIDToken, firebaseUser } = useFirebaseContext();
  const userRegistrationStarted = useRef(false);
  const [companyName, setCompanyName] = useState("");
  const {
    registerIndividualUser,
    individualUserRegistrationInProgress,
    companyUserRegistrationInProgress,
    registerCompanyUser,
  } = useUserRegistrationHelper();
  const isIndividualClient = localStorage.getItem(InitiateUserRegistration);

  useEffect(() => {
    if (!firebaseUserIDToken) {
      return;
    }

    if (!firebaseUser) {
      return;
    }

    if (!firebaseUser.emailVerified) {
      return;
    }

    if (userRegistrationStarted.current) {
      return;
    }

    userRegistrationStarted.current = true;

    if (isIndividualClient) {
      registerIndividualUser(firebaseUserIDToken).finally(
        () => (userRegistrationStarted.current = false),
      );
    } else {
      registerCompanyUser(firebaseUserIDToken, companyName).finally(
        () => (userRegistrationStarted.current = false),
      );
    }
  }, [firebaseUserIDToken, firebaseUser]);

  if (
    individualUserRegistrationInProgress ||
    companyUserRegistrationInProgress
  ) {
    return <UserRegisteringLoader />;
  }

  return (
    <>
      {smUp ? (
        <Box
          sx={{
            position: "relative",
            display: "flex",
            flexDirection: "column",
            backgroundImage: `url(${background})`,
            backgroundSize: "cover",
            height: "100vh",
            overflowY: {
              xs: "auto",
              md: "hidden",
            },
            width: "100%",
          }}
        >
          <Box
            sx={{
              width: 168,
              height: 77,
              position: "fixed",
            }}
          >
            <img alt="" src={meshMiniLogo} />
          </Box>
          <Box
            className="meshScroll"
            sx={{
              height: "100%",
              display: "grid",
              gridTemplateRows: `
                "a"
                "b"
                "c"
              `,
              justifyContent: "center",
              minWidth: "100vw",
              overflowY: "auto",
            }}
          >
            <Box
              sx={{
                gridArea: "b",
                pb: 2,
                pt: 4,
              }}
            >
              {isIndividualClient ? (
                <IndividualClientRegisterCard />
              ) : (
                <CompanyClientRegisterCard setCompanyName={setCompanyName} />
              )}
            </Box>
          </Box>
        </Box>
      ) : isIndividualClient ? (
        <IndividualClientRegisterCard />
      ) : (
        <CompanyClientRegisterCard setCompanyName={setCompanyName} />
      )}
    </>
  );
};

/**
 * @fileoverview gRPC-Web generated client stub for financial
 * @enhanceable
 * @public
 */

// Code generated by protoc-gen-grpc-web. DO NOT EDIT.
// versions:
// 	protoc-gen-grpc-web v1.5.0
// 	protoc              v5.28.0
// source: financial/smartInstrumentStateController.proto


/* eslint-disable */
// @ts-nocheck



const grpc = {};
grpc.web = require('grpc-web');


var google_protobuf_timestamp_pb = require('google-protobuf/google/protobuf/timestamp_pb.js')

var financial_smartInstrument_pb = require('../financial/smartInstrument_pb.js')

var financial_smartInstrumentLeg_pb = require('../financial/smartInstrumentLeg_pb.js')

var financial_assetClass_pb = require('../financial/assetClass_pb.js')

var ledger_futureAmount_pb = require('../ledger/futureAmount_pb.js')

var ledger_futureToken_pb = require('../ledger/futureToken_pb.js')

var financial_unit_pb = require('../financial/unit_pb.js')

var financial_smartInstrumentType_pb = require('../financial/smartInstrumentType_pb.js')

var financial_callabilityConfiguration_pb = require('../financial/callabilityConfiguration_pb.js')

var i8n_timezone_pb = require('../i8n/timezone_pb.js')

var security_context_pb = require('../security/context_pb.js')

var financial_smartInstrumentAttribute_pb = require('../financial/smartInstrumentAttribute_pb.js')
const proto = {};
proto.financial = require('./smartInstrumentStateController_pb.js');

/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?grpc.web.ClientOptions} options
 * @constructor
 * @struct
 * @final
 */
proto.financial.SmartInstrumentStateControllerClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options.format = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname.replace(/\/+$/, '');

};


/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?grpc.web.ClientOptions} options
 * @constructor
 * @struct
 * @final
 */
proto.financial.SmartInstrumentStateControllerPromiseClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options.format = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname.replace(/\/+$/, '');

};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.financial.CreateDraftSmartInstrumentRequest,
 *   !proto.financial.CreateDraftSmartInstrumentResponse>}
 */
const methodDescriptor_SmartInstrumentStateController_CreateDraftSmartInstrument = new grpc.web.MethodDescriptor(
  '/financial.SmartInstrumentStateController/CreateDraftSmartInstrument',
  grpc.web.MethodType.UNARY,
  proto.financial.CreateDraftSmartInstrumentRequest,
  proto.financial.CreateDraftSmartInstrumentResponse,
  /**
   * @param {!proto.financial.CreateDraftSmartInstrumentRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.financial.CreateDraftSmartInstrumentResponse.deserializeBinary
);


/**
 * @param {!proto.financial.CreateDraftSmartInstrumentRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.financial.CreateDraftSmartInstrumentResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.financial.CreateDraftSmartInstrumentResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.financial.SmartInstrumentStateControllerClient.prototype.createDraftSmartInstrument =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/financial.SmartInstrumentStateController/CreateDraftSmartInstrument',
      request,
      metadata || {},
      methodDescriptor_SmartInstrumentStateController_CreateDraftSmartInstrument,
      callback);
};


/**
 * @param {!proto.financial.CreateDraftSmartInstrumentRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.financial.CreateDraftSmartInstrumentResponse>}
 *     Promise that resolves to the response
 */
proto.financial.SmartInstrumentStateControllerPromiseClient.prototype.createDraftSmartInstrument =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/financial.SmartInstrumentStateController/CreateDraftSmartInstrument',
      request,
      metadata || {},
      methodDescriptor_SmartInstrumentStateController_CreateDraftSmartInstrument);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.financial.PreIssueSmartInstrumentIDRequest,
 *   !proto.financial.PreIssueSmartInstrumentIDResponse>}
 */
const methodDescriptor_SmartInstrumentStateController_PreIssueSmartInstrumentID = new grpc.web.MethodDescriptor(
  '/financial.SmartInstrumentStateController/PreIssueSmartInstrumentID',
  grpc.web.MethodType.UNARY,
  proto.financial.PreIssueSmartInstrumentIDRequest,
  proto.financial.PreIssueSmartInstrumentIDResponse,
  /**
   * @param {!proto.financial.PreIssueSmartInstrumentIDRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.financial.PreIssueSmartInstrumentIDResponse.deserializeBinary
);


/**
 * @param {!proto.financial.PreIssueSmartInstrumentIDRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.financial.PreIssueSmartInstrumentIDResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.financial.PreIssueSmartInstrumentIDResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.financial.SmartInstrumentStateControllerClient.prototype.preIssueSmartInstrumentID =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/financial.SmartInstrumentStateController/PreIssueSmartInstrumentID',
      request,
      metadata || {},
      methodDescriptor_SmartInstrumentStateController_PreIssueSmartInstrumentID,
      callback);
};


/**
 * @param {!proto.financial.PreIssueSmartInstrumentIDRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.financial.PreIssueSmartInstrumentIDResponse>}
 *     Promise that resolves to the response
 */
proto.financial.SmartInstrumentStateControllerPromiseClient.prototype.preIssueSmartInstrumentID =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/financial.SmartInstrumentStateController/PreIssueSmartInstrumentID',
      request,
      metadata || {},
      methodDescriptor_SmartInstrumentStateController_PreIssueSmartInstrumentID);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.financial.PreIssueSmartInstrumentRequest,
 *   !proto.financial.PreIssueSmartInstrumentResponse>}
 */
const methodDescriptor_SmartInstrumentStateController_PreIssueSmartInstrument = new grpc.web.MethodDescriptor(
  '/financial.SmartInstrumentStateController/PreIssueSmartInstrument',
  grpc.web.MethodType.UNARY,
  proto.financial.PreIssueSmartInstrumentRequest,
  proto.financial.PreIssueSmartInstrumentResponse,
  /**
   * @param {!proto.financial.PreIssueSmartInstrumentRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.financial.PreIssueSmartInstrumentResponse.deserializeBinary
);


/**
 * @param {!proto.financial.PreIssueSmartInstrumentRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.financial.PreIssueSmartInstrumentResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.financial.PreIssueSmartInstrumentResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.financial.SmartInstrumentStateControllerClient.prototype.preIssueSmartInstrument =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/financial.SmartInstrumentStateController/PreIssueSmartInstrument',
      request,
      metadata || {},
      methodDescriptor_SmartInstrumentStateController_PreIssueSmartInstrument,
      callback);
};


/**
 * @param {!proto.financial.PreIssueSmartInstrumentRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.financial.PreIssueSmartInstrumentResponse>}
 *     Promise that resolves to the response
 */
proto.financial.SmartInstrumentStateControllerPromiseClient.prototype.preIssueSmartInstrument =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/financial.SmartInstrumentStateController/PreIssueSmartInstrument',
      request,
      metadata || {},
      methodDescriptor_SmartInstrumentStateController_PreIssueSmartInstrument);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.financial.MarkSmartInstrumentPreIssuanceFailedRequest,
 *   !proto.financial.MarkSmartInstrumentPreIssuanceFailedResponse>}
 */
const methodDescriptor_SmartInstrumentStateController_MarkSmartInstrumentPreIssuanceFailed = new grpc.web.MethodDescriptor(
  '/financial.SmartInstrumentStateController/MarkSmartInstrumentPreIssuanceFailed',
  grpc.web.MethodType.UNARY,
  proto.financial.MarkSmartInstrumentPreIssuanceFailedRequest,
  proto.financial.MarkSmartInstrumentPreIssuanceFailedResponse,
  /**
   * @param {!proto.financial.MarkSmartInstrumentPreIssuanceFailedRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.financial.MarkSmartInstrumentPreIssuanceFailedResponse.deserializeBinary
);


/**
 * @param {!proto.financial.MarkSmartInstrumentPreIssuanceFailedRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.financial.MarkSmartInstrumentPreIssuanceFailedResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.financial.MarkSmartInstrumentPreIssuanceFailedResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.financial.SmartInstrumentStateControllerClient.prototype.markSmartInstrumentPreIssuanceFailed =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/financial.SmartInstrumentStateController/MarkSmartInstrumentPreIssuanceFailed',
      request,
      metadata || {},
      methodDescriptor_SmartInstrumentStateController_MarkSmartInstrumentPreIssuanceFailed,
      callback);
};


/**
 * @param {!proto.financial.MarkSmartInstrumentPreIssuanceFailedRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.financial.MarkSmartInstrumentPreIssuanceFailedResponse>}
 *     Promise that resolves to the response
 */
proto.financial.SmartInstrumentStateControllerPromiseClient.prototype.markSmartInstrumentPreIssuanceFailed =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/financial.SmartInstrumentStateController/MarkSmartInstrumentPreIssuanceFailed',
      request,
      metadata || {},
      methodDescriptor_SmartInstrumentStateController_MarkSmartInstrumentPreIssuanceFailed);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.financial.MarkSmartInstrumentPreIssuedRequest,
 *   !proto.financial.MarkSmartInstrumentPreIssuedResponse>}
 */
const methodDescriptor_SmartInstrumentStateController_MarkSmartInstrumentPreIssued = new grpc.web.MethodDescriptor(
  '/financial.SmartInstrumentStateController/MarkSmartInstrumentPreIssued',
  grpc.web.MethodType.UNARY,
  proto.financial.MarkSmartInstrumentPreIssuedRequest,
  proto.financial.MarkSmartInstrumentPreIssuedResponse,
  /**
   * @param {!proto.financial.MarkSmartInstrumentPreIssuedRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.financial.MarkSmartInstrumentPreIssuedResponse.deserializeBinary
);


/**
 * @param {!proto.financial.MarkSmartInstrumentPreIssuedRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.financial.MarkSmartInstrumentPreIssuedResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.financial.MarkSmartInstrumentPreIssuedResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.financial.SmartInstrumentStateControllerClient.prototype.markSmartInstrumentPreIssued =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/financial.SmartInstrumentStateController/MarkSmartInstrumentPreIssued',
      request,
      metadata || {},
      methodDescriptor_SmartInstrumentStateController_MarkSmartInstrumentPreIssued,
      callback);
};


/**
 * @param {!proto.financial.MarkSmartInstrumentPreIssuedRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.financial.MarkSmartInstrumentPreIssuedResponse>}
 *     Promise that resolves to the response
 */
proto.financial.SmartInstrumentStateControllerPromiseClient.prototype.markSmartInstrumentPreIssued =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/financial.SmartInstrumentStateController/MarkSmartInstrumentPreIssued',
      request,
      metadata || {},
      methodDescriptor_SmartInstrumentStateController_MarkSmartInstrumentPreIssued);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.financial.IssueSmartInstrumentIDRequest,
 *   !proto.financial.IssueSmartInstrumentIDResponse>}
 */
const methodDescriptor_SmartInstrumentStateController_IssueSmartInstrumentID = new grpc.web.MethodDescriptor(
  '/financial.SmartInstrumentStateController/IssueSmartInstrumentID',
  grpc.web.MethodType.UNARY,
  proto.financial.IssueSmartInstrumentIDRequest,
  proto.financial.IssueSmartInstrumentIDResponse,
  /**
   * @param {!proto.financial.IssueSmartInstrumentIDRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.financial.IssueSmartInstrumentIDResponse.deserializeBinary
);


/**
 * @param {!proto.financial.IssueSmartInstrumentIDRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.financial.IssueSmartInstrumentIDResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.financial.IssueSmartInstrumentIDResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.financial.SmartInstrumentStateControllerClient.prototype.issueSmartInstrumentID =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/financial.SmartInstrumentStateController/IssueSmartInstrumentID',
      request,
      metadata || {},
      methodDescriptor_SmartInstrumentStateController_IssueSmartInstrumentID,
      callback);
};


/**
 * @param {!proto.financial.IssueSmartInstrumentIDRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.financial.IssueSmartInstrumentIDResponse>}
 *     Promise that resolves to the response
 */
proto.financial.SmartInstrumentStateControllerPromiseClient.prototype.issueSmartInstrumentID =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/financial.SmartInstrumentStateController/IssueSmartInstrumentID',
      request,
      metadata || {},
      methodDescriptor_SmartInstrumentStateController_IssueSmartInstrumentID);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.financial.IssueSmartInstrumentRequest,
 *   !proto.financial.IssueSmartInstrumentResponse>}
 */
const methodDescriptor_SmartInstrumentStateController_IssueSmartInstrument = new grpc.web.MethodDescriptor(
  '/financial.SmartInstrumentStateController/IssueSmartInstrument',
  grpc.web.MethodType.UNARY,
  proto.financial.IssueSmartInstrumentRequest,
  proto.financial.IssueSmartInstrumentResponse,
  /**
   * @param {!proto.financial.IssueSmartInstrumentRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.financial.IssueSmartInstrumentResponse.deserializeBinary
);


/**
 * @param {!proto.financial.IssueSmartInstrumentRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.financial.IssueSmartInstrumentResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.financial.IssueSmartInstrumentResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.financial.SmartInstrumentStateControllerClient.prototype.issueSmartInstrument =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/financial.SmartInstrumentStateController/IssueSmartInstrument',
      request,
      metadata || {},
      methodDescriptor_SmartInstrumentStateController_IssueSmartInstrument,
      callback);
};


/**
 * @param {!proto.financial.IssueSmartInstrumentRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.financial.IssueSmartInstrumentResponse>}
 *     Promise that resolves to the response
 */
proto.financial.SmartInstrumentStateControllerPromiseClient.prototype.issueSmartInstrument =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/financial.SmartInstrumentStateController/IssueSmartInstrument',
      request,
      metadata || {},
      methodDescriptor_SmartInstrumentStateController_IssueSmartInstrument);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.financial.MarkSmartInstrumentIssuanceFailedRequest,
 *   !proto.financial.MarkSmartInstrumentIssuanceFailedResponse>}
 */
const methodDescriptor_SmartInstrumentStateController_MarkSmartInstrumentIssuanceFailed = new grpc.web.MethodDescriptor(
  '/financial.SmartInstrumentStateController/MarkSmartInstrumentIssuanceFailed',
  grpc.web.MethodType.UNARY,
  proto.financial.MarkSmartInstrumentIssuanceFailedRequest,
  proto.financial.MarkSmartInstrumentIssuanceFailedResponse,
  /**
   * @param {!proto.financial.MarkSmartInstrumentIssuanceFailedRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.financial.MarkSmartInstrumentIssuanceFailedResponse.deserializeBinary
);


/**
 * @param {!proto.financial.MarkSmartInstrumentIssuanceFailedRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.financial.MarkSmartInstrumentIssuanceFailedResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.financial.MarkSmartInstrumentIssuanceFailedResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.financial.SmartInstrumentStateControllerClient.prototype.markSmartInstrumentIssuanceFailed =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/financial.SmartInstrumentStateController/MarkSmartInstrumentIssuanceFailed',
      request,
      metadata || {},
      methodDescriptor_SmartInstrumentStateController_MarkSmartInstrumentIssuanceFailed,
      callback);
};


/**
 * @param {!proto.financial.MarkSmartInstrumentIssuanceFailedRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.financial.MarkSmartInstrumentIssuanceFailedResponse>}
 *     Promise that resolves to the response
 */
proto.financial.SmartInstrumentStateControllerPromiseClient.prototype.markSmartInstrumentIssuanceFailed =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/financial.SmartInstrumentStateController/MarkSmartInstrumentIssuanceFailed',
      request,
      metadata || {},
      methodDescriptor_SmartInstrumentStateController_MarkSmartInstrumentIssuanceFailed);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.financial.MarkSmartInstrumentIssuedRequest,
 *   !proto.financial.MarkSmartInstrumentIssuedResponse>}
 */
const methodDescriptor_SmartInstrumentStateController_MarkSmartInstrumentIssued = new grpc.web.MethodDescriptor(
  '/financial.SmartInstrumentStateController/MarkSmartInstrumentIssued',
  grpc.web.MethodType.UNARY,
  proto.financial.MarkSmartInstrumentIssuedRequest,
  proto.financial.MarkSmartInstrumentIssuedResponse,
  /**
   * @param {!proto.financial.MarkSmartInstrumentIssuedRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.financial.MarkSmartInstrumentIssuedResponse.deserializeBinary
);


/**
 * @param {!proto.financial.MarkSmartInstrumentIssuedRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.financial.MarkSmartInstrumentIssuedResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.financial.MarkSmartInstrumentIssuedResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.financial.SmartInstrumentStateControllerClient.prototype.markSmartInstrumentIssued =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/financial.SmartInstrumentStateController/MarkSmartInstrumentIssued',
      request,
      metadata || {},
      methodDescriptor_SmartInstrumentStateController_MarkSmartInstrumentIssued,
      callback);
};


/**
 * @param {!proto.financial.MarkSmartInstrumentIssuedRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.financial.MarkSmartInstrumentIssuedResponse>}
 *     Promise that resolves to the response
 */
proto.financial.SmartInstrumentStateControllerPromiseClient.prototype.markSmartInstrumentIssued =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/financial.SmartInstrumentStateController/MarkSmartInstrumentIssued',
      request,
      metadata || {},
      methodDescriptor_SmartInstrumentStateController_MarkSmartInstrumentIssued);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.financial.CancelSmartInstrumentRequest,
 *   !proto.financial.CancelSmartInstrumentResponse>}
 */
const methodDescriptor_SmartInstrumentStateController_CancelSmartInstrument = new grpc.web.MethodDescriptor(
  '/financial.SmartInstrumentStateController/CancelSmartInstrument',
  grpc.web.MethodType.UNARY,
  proto.financial.CancelSmartInstrumentRequest,
  proto.financial.CancelSmartInstrumentResponse,
  /**
   * @param {!proto.financial.CancelSmartInstrumentRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.financial.CancelSmartInstrumentResponse.deserializeBinary
);


/**
 * @param {!proto.financial.CancelSmartInstrumentRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.financial.CancelSmartInstrumentResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.financial.CancelSmartInstrumentResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.financial.SmartInstrumentStateControllerClient.prototype.cancelSmartInstrument =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/financial.SmartInstrumentStateController/CancelSmartInstrument',
      request,
      metadata || {},
      methodDescriptor_SmartInstrumentStateController_CancelSmartInstrument,
      callback);
};


/**
 * @param {!proto.financial.CancelSmartInstrumentRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.financial.CancelSmartInstrumentResponse>}
 *     Promise that resolves to the response
 */
proto.financial.SmartInstrumentStateControllerPromiseClient.prototype.cancelSmartInstrument =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/financial.SmartInstrumentStateController/CancelSmartInstrument',
      request,
      metadata || {},
      methodDescriptor_SmartInstrumentStateController_CancelSmartInstrument);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.financial.ResolveSmartInstrumentStateRequest,
 *   !proto.financial.ResolveSmartInstrumentStateResponse>}
 */
const methodDescriptor_SmartInstrumentStateController_ResolveSmartInstrumentState = new grpc.web.MethodDescriptor(
  '/financial.SmartInstrumentStateController/ResolveSmartInstrumentState',
  grpc.web.MethodType.UNARY,
  proto.financial.ResolveSmartInstrumentStateRequest,
  proto.financial.ResolveSmartInstrumentStateResponse,
  /**
   * @param {!proto.financial.ResolveSmartInstrumentStateRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.financial.ResolveSmartInstrumentStateResponse.deserializeBinary
);


/**
 * @param {!proto.financial.ResolveSmartInstrumentStateRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.financial.ResolveSmartInstrumentStateResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.financial.ResolveSmartInstrumentStateResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.financial.SmartInstrumentStateControllerClient.prototype.resolveSmartInstrumentState =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/financial.SmartInstrumentStateController/ResolveSmartInstrumentState',
      request,
      metadata || {},
      methodDescriptor_SmartInstrumentStateController_ResolveSmartInstrumentState,
      callback);
};


/**
 * @param {!proto.financial.ResolveSmartInstrumentStateRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.financial.ResolveSmartInstrumentStateResponse>}
 *     Promise that resolves to the response
 */
proto.financial.SmartInstrumentStateControllerPromiseClient.prototype.resolveSmartInstrumentState =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/financial.SmartInstrumentStateController/ResolveSmartInstrumentState',
      request,
      metadata || {},
      methodDescriptor_SmartInstrumentStateController_ResolveSmartInstrumentState);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.financial.ResolveSmartInstrumentIDStateRequest,
 *   !proto.financial.ResolveSmartInstrumentIDStateResponse>}
 */
const methodDescriptor_SmartInstrumentStateController_ResolveSmartInstrumentIDState = new grpc.web.MethodDescriptor(
  '/financial.SmartInstrumentStateController/ResolveSmartInstrumentIDState',
  grpc.web.MethodType.UNARY,
  proto.financial.ResolveSmartInstrumentIDStateRequest,
  proto.financial.ResolveSmartInstrumentIDStateResponse,
  /**
   * @param {!proto.financial.ResolveSmartInstrumentIDStateRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.financial.ResolveSmartInstrumentIDStateResponse.deserializeBinary
);


/**
 * @param {!proto.financial.ResolveSmartInstrumentIDStateRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.financial.ResolveSmartInstrumentIDStateResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.financial.ResolveSmartInstrumentIDStateResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.financial.SmartInstrumentStateControllerClient.prototype.resolveSmartInstrumentIDState =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/financial.SmartInstrumentStateController/ResolveSmartInstrumentIDState',
      request,
      metadata || {},
      methodDescriptor_SmartInstrumentStateController_ResolveSmartInstrumentIDState,
      callback);
};


/**
 * @param {!proto.financial.ResolveSmartInstrumentIDStateRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.financial.ResolveSmartInstrumentIDStateResponse>}
 *     Promise that resolves to the response
 */
proto.financial.SmartInstrumentStateControllerPromiseClient.prototype.resolveSmartInstrumentIDState =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/financial.SmartInstrumentStateController/ResolveSmartInstrumentIDState',
      request,
      metadata || {},
      methodDescriptor_SmartInstrumentStateController_ResolveSmartInstrumentIDState);
};


module.exports = proto.financial;


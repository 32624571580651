import { ValidationResult } from "common/validation";
import { Model as StellarAccountViewModel } from "@mesh/common-js/dist/views/stellarAccountView/model_pb";
import { Fee } from "james/remuneration/Fee";
import { BigNumber } from "bignumber.js";
import { FutureAmount } from "@mesh/common-js/dist/ledger/futureAmount_pb";
import { decimalToBigNumber } from "@mesh/common-js/dist/num";
import { Decimal } from "@mesh/common-js/dist/num/decimal_pb";
import { OffPlatformTransferRecipient } from "@mesh/common-js/src/market/offPlatformTransferRecipient_pb";

export type State = {
  userID: string;
  transferAmount: FutureAmount;
  mZARBalance: FutureAmount;
  transferTokenAvailableBalance: FutureAmount;
  transferFees: Fee[] | undefined;
  ledgerAccountModel: StellarAccountViewModel;
  selectedRecipient: OffPlatformTransferRecipient | null;
};

export type FormUpdaterSpecsType = {
  userID: (value: string, prevState?: State) => State;
  transferTokenAvailableBalance: (
    value: FutureAmount,
    prevState?: State,
  ) => State;
  mZARBalance: (value: FutureAmount, prevState?: State) => State;
  transferAmount: (value: FutureAmount, prevState?: State) => State;
  transferFees: (value: Fee[] | undefined, prevState?: State) => State;
  ledgerAccountModel: (
    value: StellarAccountViewModel,
    prevState?: State,
  ) => State;
  selectedRecipient: (
    value: OffPlatformTransferRecipient,
    prevState?: State,
  ) => State;
};

export const formUpdaterSpecs: FormUpdaterSpecsType = {
  userID(value: string, prevState: State | undefined): State {
    return {
      ...(prevState as State),
      userID: value,
    };
  },
  transferTokenAvailableBalance(
    value: FutureAmount,
    prevState: State | undefined,
  ): State {
    return {
      ...(prevState as State),
      transferTokenAvailableBalance: value,
    };
  },
  transferAmount(value: FutureAmount, prevState: State | undefined): State {
    return {
      ...(prevState as State),
      transferAmount: value,
    };
  },
  transferFees(value: Fee[] | undefined, prevState: State | undefined): State {
    return {
      ...(prevState as State),
      transferFees: value,
    };
  },
  mZARBalance(value: FutureAmount, prevState: State | undefined): State {
    return {
      ...(prevState as State),
      mZARBalance: value,
    };
  },
  ledgerAccountModel(
    value: StellarAccountViewModel,
    prevState: State | undefined,
  ): State {
    return {
      ...(prevState as State),
      ledgerAccountModel: value,
    };
  },
  selectedRecipient(
    value: OffPlatformTransferRecipient,
    prevState: State | undefined,
  ): State {
    return {
      ...(prevState as State),
      selectedRecipient: value,
    };
  },
};

type UnfilteredValidationResultType = {
  valid: boolean;
  fieldValidations: { [key: string]: string | null };
};

export let UnfilteredValidationResult: UnfilteredValidationResultType = {
  valid: true,
  fieldValidations: {},
};

// define your validation func
export const validationFunc = async (
  formState: State,
): Promise<ValidationResult> => {
  const newValidationState: ValidationResult = {
    valid: true,
    fieldValidations: {},
  };

  // validate that transfer FutureAmount is not less than 0.0002
  if (
    decimalToBigNumber(
      formState.transferAmount?.getValue() || new Decimal(),
    ).lt(new BigNumber(0.00002))
  ) {
    newValidationState.fieldValidations.transferAmount =
      "Cannot be less than 0.0000200";
  }

  // validate that transfer FutureAmount greater than 0
  if (
    decimalToBigNumber(
      formState.transferAmount.getValue() || new Decimal(),
    ).isZero()
  ) {
    newValidationState.fieldValidations.transferAmount =
      "Cannot be empty or zero";
    newValidationState.fieldValidations.transferHoverText =
      "All fields must be populated";
  }

  if (
    formState.selectedRecipient &&
    formState.selectedRecipient.getExternalreference() === ""
  ) {
    newValidationState.fieldValidations.selectedRecipient =
      "Memo on recipient cannot empty";
    newValidationState.fieldValidations.transferHoverText =
      "Memo on recipient cannot empty";
  }

  if (formState.transferFees) {
    // calculate total fee FutureAmount
    const totalFeeAmount = formState.transferFees
      .reduce(
        (total, current) =>
          current
            .feeAmount()
            .setValue(
              current
                .feeTotal()
                .value.plus(
                  decimalToBigNumber(total.getValue() || new Decimal()),
                ),
            )
            .toFutureAmount(),
        new FutureAmount(),
      )
      .getValue();

    // in this scenario we want to validate if the total transfer FutureAmount is less than the total available
    // when transferred token and fee token are the same
    if (
      formState.mZARBalance.getToken()?.toString() ===
      formState.transferTokenAvailableBalance.getToken()?.toString()
    ) {
      if (
        decimalToBigNumber(totalFeeAmount || new Decimal())
          .plus(
            decimalToBigNumber(
              formState.transferAmount.getValue() || new Decimal(),
            ),
          )
          .gt(
            decimalToBigNumber(
              formState.transferTokenAvailableBalance.getValue() ||
                new Decimal(),
            ),
          )
      ) {
        newValidationState.fieldValidations.transferFees =
          "Insufficient balance for fees";
        newValidationState.fieldValidations.transferHoverText =
          "Insufficient balance for fees";
      }
    } else if (
      decimalToBigNumber(totalFeeAmount || new Decimal()).gt(
        decimalToBigNumber(formState.mZARBalance.getValue() || new Decimal()),
      )
    ) {
      newValidationState.fieldValidations.transferFees =
        "Insufficient balance for fees";
      newValidationState.fieldValidations.transferHoverText =
        "Insufficient balance for fees";
    }

    if (
      decimalToBigNumber(
        formState.transferTokenAvailableBalance.getValue() || new Decimal(),
      ).lt(
        decimalToBigNumber(
          formState.transferAmount.getValue() || new Decimal(),
        ),
      )
    ) {
      newValidationState.fieldValidations.transferAmount =
        "Insufficient balance to make transfer";
      newValidationState.fieldValidations.transferHoverText =
        "Insufficient balance to make transfer";
    }
  }

  if (Object.keys(newValidationState.fieldValidations).length > 0) {
    newValidationState.valid = false;
  }

  UnfilteredValidationResult = newValidationState;
  return newValidationState;
};

/**
 * @fileoverview gRPC-Web generated client stub for market
 * @enhanceable
 * @public
 */

// Code generated by protoc-gen-grpc-web. DO NOT EDIT.
// versions:
// 	protoc-gen-grpc-web v1.5.0
// 	protoc              v5.28.0
// source: market/offPlatformTransferRecipientStateController.proto


/* eslint-disable */
// @ts-nocheck



const grpc = {};
grpc.web = require('grpc-web');


var security_context_pb = require('../security/context_pb.js')

var ledger_futureNetwork_pb = require('../ledger/futureNetwork_pb.js')

var market_offPlatformTransferRecipient_pb = require('../market/offPlatformTransferRecipient_pb.js')
const proto = {};
proto.market = require('./offPlatformTransferRecipientStateController_pb.js');

/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?grpc.web.ClientOptions} options
 * @constructor
 * @struct
 * @final
 */
proto.market.OffPlatformTransferRecipientStateControllerClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options.format = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname.replace(/\/+$/, '');

};


/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?grpc.web.ClientOptions} options
 * @constructor
 * @struct
 * @final
 */
proto.market.OffPlatformTransferRecipientStateControllerPromiseClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options.format = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname.replace(/\/+$/, '');

};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.market.AddOffPlatformTransferRecipientRequest,
 *   !proto.market.AddOffPlatformTransferRecipientResponse>}
 */
const methodDescriptor_OffPlatformTransferRecipientStateController_AddOffPlatformTransferRecipient = new grpc.web.MethodDescriptor(
  '/market.OffPlatformTransferRecipientStateController/AddOffPlatformTransferRecipient',
  grpc.web.MethodType.UNARY,
  proto.market.AddOffPlatformTransferRecipientRequest,
  proto.market.AddOffPlatformTransferRecipientResponse,
  /**
   * @param {!proto.market.AddOffPlatformTransferRecipientRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.market.AddOffPlatformTransferRecipientResponse.deserializeBinary
);


/**
 * @param {!proto.market.AddOffPlatformTransferRecipientRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.market.AddOffPlatformTransferRecipientResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.market.AddOffPlatformTransferRecipientResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.market.OffPlatformTransferRecipientStateControllerClient.prototype.addOffPlatformTransferRecipient =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/market.OffPlatformTransferRecipientStateController/AddOffPlatformTransferRecipient',
      request,
      metadata || {},
      methodDescriptor_OffPlatformTransferRecipientStateController_AddOffPlatformTransferRecipient,
      callback);
};


/**
 * @param {!proto.market.AddOffPlatformTransferRecipientRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.market.AddOffPlatformTransferRecipientResponse>}
 *     Promise that resolves to the response
 */
proto.market.OffPlatformTransferRecipientStateControllerPromiseClient.prototype.addOffPlatformTransferRecipient =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/market.OffPlatformTransferRecipientStateController/AddOffPlatformTransferRecipient',
      request,
      metadata || {},
      methodDescriptor_OffPlatformTransferRecipientStateController_AddOffPlatformTransferRecipient);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.market.MarkOffPlatformTransferRecipientActiveRequest,
 *   !proto.market.MarkOffPlatformTransferRecipientActiveResponse>}
 */
const methodDescriptor_OffPlatformTransferRecipientStateController_MarkOffPlatformTransferRecipientActive = new grpc.web.MethodDescriptor(
  '/market.OffPlatformTransferRecipientStateController/MarkOffPlatformTransferRecipientActive',
  grpc.web.MethodType.UNARY,
  proto.market.MarkOffPlatformTransferRecipientActiveRequest,
  proto.market.MarkOffPlatformTransferRecipientActiveResponse,
  /**
   * @param {!proto.market.MarkOffPlatformTransferRecipientActiveRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.market.MarkOffPlatformTransferRecipientActiveResponse.deserializeBinary
);


/**
 * @param {!proto.market.MarkOffPlatformTransferRecipientActiveRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.market.MarkOffPlatformTransferRecipientActiveResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.market.MarkOffPlatformTransferRecipientActiveResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.market.OffPlatformTransferRecipientStateControllerClient.prototype.markOffPlatformTransferRecipientActive =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/market.OffPlatformTransferRecipientStateController/MarkOffPlatformTransferRecipientActive',
      request,
      metadata || {},
      methodDescriptor_OffPlatformTransferRecipientStateController_MarkOffPlatformTransferRecipientActive,
      callback);
};


/**
 * @param {!proto.market.MarkOffPlatformTransferRecipientActiveRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.market.MarkOffPlatformTransferRecipientActiveResponse>}
 *     Promise that resolves to the response
 */
proto.market.OffPlatformTransferRecipientStateControllerPromiseClient.prototype.markOffPlatformTransferRecipientActive =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/market.OffPlatformTransferRecipientStateController/MarkOffPlatformTransferRecipientActive',
      request,
      metadata || {},
      methodDescriptor_OffPlatformTransferRecipientStateController_MarkOffPlatformTransferRecipientActive);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.market.MarkOffPlatformTransferRecipientInactiveRequest,
 *   !proto.market.MarkOffPlatformTransferRecipientInactiveResponse>}
 */
const methodDescriptor_OffPlatformTransferRecipientStateController_MarkOffPlatformTransferRecipientInactive = new grpc.web.MethodDescriptor(
  '/market.OffPlatformTransferRecipientStateController/MarkOffPlatformTransferRecipientInactive',
  grpc.web.MethodType.UNARY,
  proto.market.MarkOffPlatformTransferRecipientInactiveRequest,
  proto.market.MarkOffPlatformTransferRecipientInactiveResponse,
  /**
   * @param {!proto.market.MarkOffPlatformTransferRecipientInactiveRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.market.MarkOffPlatformTransferRecipientInactiveResponse.deserializeBinary
);


/**
 * @param {!proto.market.MarkOffPlatformTransferRecipientInactiveRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.market.MarkOffPlatformTransferRecipientInactiveResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.market.MarkOffPlatformTransferRecipientInactiveResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.market.OffPlatformTransferRecipientStateControllerClient.prototype.markOffPlatformTransferRecipientInactive =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/market.OffPlatformTransferRecipientStateController/MarkOffPlatformTransferRecipientInactive',
      request,
      metadata || {},
      methodDescriptor_OffPlatformTransferRecipientStateController_MarkOffPlatformTransferRecipientInactive,
      callback);
};


/**
 * @param {!proto.market.MarkOffPlatformTransferRecipientInactiveRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.market.MarkOffPlatformTransferRecipientInactiveResponse>}
 *     Promise that resolves to the response
 */
proto.market.OffPlatformTransferRecipientStateControllerPromiseClient.prototype.markOffPlatformTransferRecipientInactive =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/market.OffPlatformTransferRecipientStateController/MarkOffPlatformTransferRecipientInactive',
      request,
      metadata || {},
      methodDescriptor_OffPlatformTransferRecipientStateController_MarkOffPlatformTransferRecipientInactive);
};


module.exports = proto.market;


import {
  CouldNotGetPrice,
  ExcessiveTruncationError,
  InvalidSpotTypeError,
  PriceSpotRequest,
  PriceSpotResponse,
  SpotPricer,
  SpotType,
  UnexpectedAmountError,
} from "pkgTemp/market";
import { Amount, Token } from "pkgTemp/ledger";
import { Client } from "./Client";
import { primitiveAssetToToken, tokenToAsset } from "./token";
import { BigNumber } from "bignumber.js";
import { StellarNetwork } from "./Network";

export type NewStellarSpotPricerProps = {
  client: Client;
};

export class StellarSpotPricer implements SpotPricer {
  private readonly stellarClient: Client;

  constructor(props: NewStellarSpotPricerProps) {
    this.stellarClient = props.client;
  }

  private async getStrictReceive(
    sourceToken: Token,
    destinationAmount: Amount,
  ): Promise<{ sendAmount: Amount; path: Token[] }> {
    // get strict receive paths
    const strictReceiveResponse = await this.stellarClient.strictReceivePaths(
      [tokenToAsset(sourceToken)],
      tokenToAsset(destinationAmount.token),
      destinationAmount.value.toString(),
    );

    // confirm that at least 1 record page was returned
    if (!strictReceiveResponse.records.length) {
      throw new CouldNotGetPrice(
        "wanted at least 1 payment path record for strict receive, got none",
      );
    }
    const paymentPathRecord = strictReceiveResponse.records[0];

    // prepare send amount
    const rawSourceAmount = new BigNumber(paymentPathRecord.source_amount);
    const sendAmount = sourceToken.newAmountOf(rawSourceAmount);

    // check for excessive truncation error
    if (sendAmount.value.isZero()) {
      throw new ExcessiveTruncationError(
        "strictReceiveResponse.source_amount",
        rawSourceAmount,
      );
    }

    return {
      sendAmount,
      path: paymentPathRecord.path.map((asset) =>
        primitiveAssetToToken(
          asset,
          this.stellarClient.network as StellarNetwork.TestSDFNetwork,
        ),
      ),
    };
  }

  private async getStrictSend(
    sourceAmount: Amount,
    destinationToken: Token,
  ): Promise<{ receiveAmount: Amount; path: Token[] }> {
    // get strict send paths
    const strictSendResponse = await this.stellarClient.strictSendPaths(
      tokenToAsset(sourceAmount.token),
      sourceAmount.value.toString(),
      [tokenToAsset(destinationToken)],
    );

    // confirm that at least 1 record page was returned
    if (!strictSendResponse.records.length) {
      throw new CouldNotGetPrice(
        "wanted at least 1 payment path record for strict send, got none",
      );
    }
    const paymentPathRecord = strictSendResponse.records[0];

    // prepare receive amount
    const rawDestinationAmount = new BigNumber(
      paymentPathRecord.destination_amount,
    );
    const receiveAmount = destinationToken.newAmountOf(
      paymentPathRecord.destination_amount,
    );

    // check for excessive truncation error
    if (receiveAmount.value.isZero()) {
      throw new ExcessiveTruncationError(
        "strictSendResponse.destination_amount",
        rawDestinationAmount,
      );
    }

    return {
      receiveAmount,
      path: paymentPathRecord.path.map((asset) =>
        primitiveAssetToToken(
          asset,
          this.stellarClient.network as StellarNetwork,
        ),
      ),
    };
  }

  async PriceSpot(request: PriceSpotRequest): Promise<PriceSpotResponse> {
    // confirm that neither base nor quote amount are undefined
    if (request.baseAmount.isUndefined()) {
      throw new UnexpectedAmountError("expected base amount to be defined");
    }
    if (request.quoteAmount.isUndefined()) {
      throw new UnexpectedAmountError("expected quote amount to be defined");
    }

    // confirm that only one of the given amounts (base or quote) are zero
    if (
      request.baseAmount.value.isZero() === request.quoteAmount.value.isZero()
    ) {
      throw new UnexpectedAmountError(
        "only one of base or quote amount should be zero",
      );
    }

    // confirm that neither base nor quote amount are < 0
    if (request.baseAmount.value.isNegative()) {
      throw new UnexpectedAmountError(
        `base amount '${request.baseAmount.value}' is < 0`,
      );
    }
    if (request.quoteAmount.value.isNegative()) {
      throw new UnexpectedAmountError(
        `quote amount '${request.quoteAmount.value}' is < 0`,
      );
    }

    // proceed based on given spot type and whether given base or quote amount is zero
    switch (request.spotType) {
      case SpotType.Buy: {
        if (request.baseAmount.value.isZero()) {
          // BUY with base amount zero:
          // Send an exact amount of the quote asset and receive some amount of the base asset

          // get strict send
          const { receiveAmount, path } = await this.getStrictSend(
            request.quoteAmount,
            request.baseAmount.token,
          );

          // prepare estimated price
          const rawEstimatedPrice = request.quoteAmount.value.dividedBy(
            receiveAmount.value,
          );
          const estimatedPrice =
            request.quoteAmount.setValue(rawEstimatedPrice);

          // check for excessive truncation error
          if (estimatedPrice.value.isZero()) {
            throw new ExcessiveTruncationError(
              "estimatedPrice",
              rawEstimatedPrice,
            );
          }

          // construct and return response
          return {
            baseAmount: receiveAmount,
            quoteAmount: request.quoteAmount,
            estimatedPrice,
            path,
          };
        } else {
          // BUY with quote amount zero:
          // Receive an exact amount of base asset, sending some amount of the quote asset

          // get strict receive paths
          const { sendAmount, path } = await this.getStrictReceive(
            request.quoteAmount.token,
            request.baseAmount,
          );

          // prepare estimated price
          const rawEstimatedPrice = sendAmount.value.dividedBy(
            request.baseAmount.value,
          );
          const estimatedPrice =
            request.quoteAmount.setValue(rawEstimatedPrice);

          // check for excessive truncation error
          if (estimatedPrice.value.isZero()) {
            throw new ExcessiveTruncationError(
              "estimatedPrice",
              rawEstimatedPrice,
            );
          }

          // construct and return response
          return {
            baseAmount: request.baseAmount,
            quoteAmount: sendAmount,
            estimatedPrice,
            path,
          };
        }
      }

      case SpotType.Sell: {
        if (request.baseAmount.value.isZero()) {
          // SELL with base amount zero:
          // Receive an exact amount of the quote asset and send some amount of the base asset

          // get strict receive paths
          const { sendAmount, path } = await this.getStrictReceive(
            request.baseAmount.token,
            request.quoteAmount,
          );

          // prepare estimated price
          const rawEstimatedPrice = request.quoteAmount.value.dividedBy(
            sendAmount.value,
          );
          const estimatedPrice = request.quoteAmount.setValue(
            request.quoteAmount.value.dividedBy(sendAmount.value),
          );

          // check for excessive truncation error
          if (estimatedPrice.value.isZero()) {
            throw new ExcessiveTruncationError(
              "estimatedPrice",
              rawEstimatedPrice,
            );
          }

          // construct and return response
          return {
            baseAmount: sendAmount,
            quoteAmount: request.quoteAmount,
            estimatedPrice,
            path,
          };
        } else {
          // SELL with quote amount zero:
          // Send an exact amount of the base asset and receive some amount of the quote asset

          // get strict send
          const { receiveAmount, path } = await this.getStrictSend(
            request.baseAmount,
            request.quoteAmount.token,
          );

          // prepare estimated price
          const rawEstimatedPrice = receiveAmount.value.dividedBy(
            request.baseAmount.value,
          );
          const estimatedPrice = request.quoteAmount.setValue(
            receiveAmount.value.dividedBy(request.baseAmount.value),
          );

          // check for excessive truncation error
          if (estimatedPrice.value.isZero()) {
            throw new ExcessiveTruncationError(
              "estimatedPrice",
              rawEstimatedPrice,
            );
          }

          // construct and return response
          return {
            baseAmount: request.baseAmount,
            quoteAmount: receiveAmount,
            estimatedPrice,
            path,
          };
        }
      }

      default:
        throw new InvalidSpotTypeError(request.spotType);
    }
  }
}

/**
 * @fileoverview gRPC-Web generated client stub for financial
 * @enhanceable
 * @public
 */

// Code generated by protoc-gen-grpc-web. DO NOT EDIT.
// versions:
// 	protoc-gen-grpc-web v1.5.0
// 	protoc              v5.28.0
// source: financial/rateSourceReader_meshproto.proto


/* eslint-disable */
// @ts-nocheck



const grpc = {};
grpc.web = require('grpc-web');


var security_context_pb = require('../security/context_pb.js')

var search_criterion_pb = require('../search/criterion_pb.js')

var search_query_pb = require('../search/query_pb.js')

var search_sorting_pb = require('../search/sorting_pb.js')

var financial_rateSource_pb = require('../financial/rateSource_pb.js')

var i8n_timezone_pb = require('../i8n/timezone_pb.js')
const proto = {};
proto.financial = require('./rateSourceReader_meshproto_pb.js');

/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?grpc.web.ClientOptions} options
 * @constructor
 * @struct
 * @final
 */
proto.financial.RateSourceReaderClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options.format = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname.replace(/\/+$/, '');

};


/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?grpc.web.ClientOptions} options
 * @constructor
 * @struct
 * @final
 */
proto.financial.RateSourceReaderPromiseClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options.format = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname.replace(/\/+$/, '');

};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.financial.ReadOneRateSourceRequest,
 *   !proto.financial.ReadOneRateSourceResponse>}
 */
const methodDescriptor_RateSourceReader_ReadOneRateSource = new grpc.web.MethodDescriptor(
  '/financial.RateSourceReader/ReadOneRateSource',
  grpc.web.MethodType.UNARY,
  proto.financial.ReadOneRateSourceRequest,
  proto.financial.ReadOneRateSourceResponse,
  /**
   * @param {!proto.financial.ReadOneRateSourceRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.financial.ReadOneRateSourceResponse.deserializeBinary
);


/**
 * @param {!proto.financial.ReadOneRateSourceRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.financial.ReadOneRateSourceResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.financial.ReadOneRateSourceResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.financial.RateSourceReaderClient.prototype.readOneRateSource =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/financial.RateSourceReader/ReadOneRateSource',
      request,
      metadata || {},
      methodDescriptor_RateSourceReader_ReadOneRateSource,
      callback);
};


/**
 * @param {!proto.financial.ReadOneRateSourceRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.financial.ReadOneRateSourceResponse>}
 *     Promise that resolves to the response
 */
proto.financial.RateSourceReaderPromiseClient.prototype.readOneRateSource =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/financial.RateSourceReader/ReadOneRateSource',
      request,
      metadata || {},
      methodDescriptor_RateSourceReader_ReadOneRateSource);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.financial.ReadManyRateSourceRequest,
 *   !proto.financial.ReadManyRateSourceResponse>}
 */
const methodDescriptor_RateSourceReader_ReadManyRateSource = new grpc.web.MethodDescriptor(
  '/financial.RateSourceReader/ReadManyRateSource',
  grpc.web.MethodType.UNARY,
  proto.financial.ReadManyRateSourceRequest,
  proto.financial.ReadManyRateSourceResponse,
  /**
   * @param {!proto.financial.ReadManyRateSourceRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.financial.ReadManyRateSourceResponse.deserializeBinary
);


/**
 * @param {!proto.financial.ReadManyRateSourceRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.financial.ReadManyRateSourceResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.financial.ReadManyRateSourceResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.financial.RateSourceReaderClient.prototype.readManyRateSource =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/financial.RateSourceReader/ReadManyRateSource',
      request,
      metadata || {},
      methodDescriptor_RateSourceReader_ReadManyRateSource,
      callback);
};


/**
 * @param {!proto.financial.ReadManyRateSourceRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.financial.ReadManyRateSourceResponse>}
 *     Promise that resolves to the response
 */
proto.financial.RateSourceReaderPromiseClient.prototype.readManyRateSource =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/financial.RateSourceReader/ReadManyRateSource',
      request,
      metadata || {},
      methodDescriptor_RateSourceReader_ReadManyRateSource);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.financial.ReadAllRateSourceRequest,
 *   !proto.financial.ReadAllRateSourceResponse>}
 */
const methodDescriptor_RateSourceReader_ReadAllRateSource = new grpc.web.MethodDescriptor(
  '/financial.RateSourceReader/ReadAllRateSource',
  grpc.web.MethodType.UNARY,
  proto.financial.ReadAllRateSourceRequest,
  proto.financial.ReadAllRateSourceResponse,
  /**
   * @param {!proto.financial.ReadAllRateSourceRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.financial.ReadAllRateSourceResponse.deserializeBinary
);


/**
 * @param {!proto.financial.ReadAllRateSourceRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.financial.ReadAllRateSourceResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.financial.ReadAllRateSourceResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.financial.RateSourceReaderClient.prototype.readAllRateSource =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/financial.RateSourceReader/ReadAllRateSource',
      request,
      metadata || {},
      methodDescriptor_RateSourceReader_ReadAllRateSource,
      callback);
};


/**
 * @param {!proto.financial.ReadAllRateSourceRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.financial.ReadAllRateSourceResponse>}
 *     Promise that resolves to the response
 */
proto.financial.RateSourceReaderPromiseClient.prototype.readAllRateSource =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/financial.RateSourceReader/ReadAllRateSource',
      request,
      metadata || {},
      methodDescriptor_RateSourceReader_ReadAllRateSource);
};


module.exports = proto.financial;


// source: financial/assetflowDataFloatingRate.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global =
    (typeof globalThis !== 'undefined' && globalThis) ||
    (typeof window !== 'undefined' && window) ||
    (typeof global !== 'undefined' && global) ||
    (typeof self !== 'undefined' && self) ||
    (function () { return this; }).call(null) ||
    Function('return this')();

var num_decimal_pb = require('../num/decimal_pb.js');
goog.object.extend(proto, num_decimal_pb);
var google_protobuf_timestamp_pb = require('google-protobuf/google/protobuf/timestamp_pb.js');
goog.object.extend(proto, google_protobuf_timestamp_pb);
var ledger_futureAmount_pb = require('../ledger/futureAmount_pb.js');
goog.object.extend(proto, ledger_futureAmount_pb);
goog.exportSymbol('proto.financial.FloatingRateAssetflowData', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.financial.FloatingRateAssetflowData = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.financial.FloatingRateAssetflowData, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.financial.FloatingRateAssetflowData.displayName = 'proto.financial.FloatingRateAssetflowData';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.financial.FloatingRateAssetflowData.prototype.toObject = function(opt_includeInstance) {
  return proto.financial.FloatingRateAssetflowData.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.financial.FloatingRateAssetflowData} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.financial.FloatingRateAssetflowData.toObject = function(includeInstance, msg) {
  var f, obj = {
rate: (f = msg.getRate()) && num_decimal_pb.Decimal.toObject(includeInstance, f),
ratesampledate: (f = msg.getRatesampledate()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
accrualstartdate: (f = msg.getAccrualstartdate()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
accrualenddate: (f = msg.getAccrualenddate()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
accrualdays: jspb.Message.getFieldWithDefault(msg, 5, 0),
notional: (f = msg.getNotional()) && ledger_futureAmount_pb.FutureAmount.toObject(includeInstance, f),
accrualforperiod: (f = msg.getAccrualforperiod()) && ledger_futureAmount_pb.FutureAmount.toObject(includeInstance, f),
deferredamount: (f = msg.getDeferredamount()) && ledger_futureAmount_pb.FutureAmount.toObject(includeInstance, f),
deferralbuildup: (f = msg.getDeferralbuildup()) && ledger_futureAmount_pb.FutureAmount.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.financial.FloatingRateAssetflowData}
 */
proto.financial.FloatingRateAssetflowData.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.financial.FloatingRateAssetflowData;
  return proto.financial.FloatingRateAssetflowData.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.financial.FloatingRateAssetflowData} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.financial.FloatingRateAssetflowData}
 */
proto.financial.FloatingRateAssetflowData.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new num_decimal_pb.Decimal;
      reader.readMessage(value,num_decimal_pb.Decimal.deserializeBinaryFromReader);
      msg.setRate(value);
      break;
    case 2:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setRatesampledate(value);
      break;
    case 3:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setAccrualstartdate(value);
      break;
    case 4:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setAccrualenddate(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setAccrualdays(value);
      break;
    case 6:
      var value = new ledger_futureAmount_pb.FutureAmount;
      reader.readMessage(value,ledger_futureAmount_pb.FutureAmount.deserializeBinaryFromReader);
      msg.setNotional(value);
      break;
    case 7:
      var value = new ledger_futureAmount_pb.FutureAmount;
      reader.readMessage(value,ledger_futureAmount_pb.FutureAmount.deserializeBinaryFromReader);
      msg.setAccrualforperiod(value);
      break;
    case 8:
      var value = new ledger_futureAmount_pb.FutureAmount;
      reader.readMessage(value,ledger_futureAmount_pb.FutureAmount.deserializeBinaryFromReader);
      msg.setDeferredamount(value);
      break;
    case 9:
      var value = new ledger_futureAmount_pb.FutureAmount;
      reader.readMessage(value,ledger_futureAmount_pb.FutureAmount.deserializeBinaryFromReader);
      msg.setDeferralbuildup(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.financial.FloatingRateAssetflowData.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.financial.FloatingRateAssetflowData.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.financial.FloatingRateAssetflowData} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.financial.FloatingRateAssetflowData.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getRate();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      num_decimal_pb.Decimal.serializeBinaryToWriter
    );
  }
  f = message.getRatesampledate();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getAccrualstartdate();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getAccrualenddate();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getAccrualdays();
  if (f !== 0) {
    writer.writeUint32(
      5,
      f
    );
  }
  f = message.getNotional();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      ledger_futureAmount_pb.FutureAmount.serializeBinaryToWriter
    );
  }
  f = message.getAccrualforperiod();
  if (f != null) {
    writer.writeMessage(
      7,
      f,
      ledger_futureAmount_pb.FutureAmount.serializeBinaryToWriter
    );
  }
  f = message.getDeferredamount();
  if (f != null) {
    writer.writeMessage(
      8,
      f,
      ledger_futureAmount_pb.FutureAmount.serializeBinaryToWriter
    );
  }
  f = message.getDeferralbuildup();
  if (f != null) {
    writer.writeMessage(
      9,
      f,
      ledger_futureAmount_pb.FutureAmount.serializeBinaryToWriter
    );
  }
};


/**
 * optional num.Decimal rate = 1;
 * @return {?proto.num.Decimal}
 */
proto.financial.FloatingRateAssetflowData.prototype.getRate = function() {
  return /** @type{?proto.num.Decimal} */ (
    jspb.Message.getWrapperField(this, num_decimal_pb.Decimal, 1));
};


/**
 * @param {?proto.num.Decimal|undefined} value
 * @return {!proto.financial.FloatingRateAssetflowData} returns this
*/
proto.financial.FloatingRateAssetflowData.prototype.setRate = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.financial.FloatingRateAssetflowData} returns this
 */
proto.financial.FloatingRateAssetflowData.prototype.clearRate = function() {
  return this.setRate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.financial.FloatingRateAssetflowData.prototype.hasRate = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional google.protobuf.Timestamp rateSampleDate = 2;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.financial.FloatingRateAssetflowData.prototype.getRatesampledate = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 2));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.financial.FloatingRateAssetflowData} returns this
*/
proto.financial.FloatingRateAssetflowData.prototype.setRatesampledate = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.financial.FloatingRateAssetflowData} returns this
 */
proto.financial.FloatingRateAssetflowData.prototype.clearRatesampledate = function() {
  return this.setRatesampledate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.financial.FloatingRateAssetflowData.prototype.hasRatesampledate = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional google.protobuf.Timestamp accrualStartDate = 3;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.financial.FloatingRateAssetflowData.prototype.getAccrualstartdate = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 3));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.financial.FloatingRateAssetflowData} returns this
*/
proto.financial.FloatingRateAssetflowData.prototype.setAccrualstartdate = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.financial.FloatingRateAssetflowData} returns this
 */
proto.financial.FloatingRateAssetflowData.prototype.clearAccrualstartdate = function() {
  return this.setAccrualstartdate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.financial.FloatingRateAssetflowData.prototype.hasAccrualstartdate = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional google.protobuf.Timestamp accrualEndDate = 4;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.financial.FloatingRateAssetflowData.prototype.getAccrualenddate = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 4));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.financial.FloatingRateAssetflowData} returns this
*/
proto.financial.FloatingRateAssetflowData.prototype.setAccrualenddate = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.financial.FloatingRateAssetflowData} returns this
 */
proto.financial.FloatingRateAssetflowData.prototype.clearAccrualenddate = function() {
  return this.setAccrualenddate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.financial.FloatingRateAssetflowData.prototype.hasAccrualenddate = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional uint32 accrualDays = 5;
 * @return {number}
 */
proto.financial.FloatingRateAssetflowData.prototype.getAccrualdays = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.financial.FloatingRateAssetflowData} returns this
 */
proto.financial.FloatingRateAssetflowData.prototype.setAccrualdays = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};


/**
 * optional ledger.FutureAmount notional = 6;
 * @return {?proto.ledger.FutureAmount}
 */
proto.financial.FloatingRateAssetflowData.prototype.getNotional = function() {
  return /** @type{?proto.ledger.FutureAmount} */ (
    jspb.Message.getWrapperField(this, ledger_futureAmount_pb.FutureAmount, 6));
};


/**
 * @param {?proto.ledger.FutureAmount|undefined} value
 * @return {!proto.financial.FloatingRateAssetflowData} returns this
*/
proto.financial.FloatingRateAssetflowData.prototype.setNotional = function(value) {
  return jspb.Message.setWrapperField(this, 6, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.financial.FloatingRateAssetflowData} returns this
 */
proto.financial.FloatingRateAssetflowData.prototype.clearNotional = function() {
  return this.setNotional(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.financial.FloatingRateAssetflowData.prototype.hasNotional = function() {
  return jspb.Message.getField(this, 6) != null;
};


/**
 * optional ledger.FutureAmount accrualForPeriod = 7;
 * @return {?proto.ledger.FutureAmount}
 */
proto.financial.FloatingRateAssetflowData.prototype.getAccrualforperiod = function() {
  return /** @type{?proto.ledger.FutureAmount} */ (
    jspb.Message.getWrapperField(this, ledger_futureAmount_pb.FutureAmount, 7));
};


/**
 * @param {?proto.ledger.FutureAmount|undefined} value
 * @return {!proto.financial.FloatingRateAssetflowData} returns this
*/
proto.financial.FloatingRateAssetflowData.prototype.setAccrualforperiod = function(value) {
  return jspb.Message.setWrapperField(this, 7, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.financial.FloatingRateAssetflowData} returns this
 */
proto.financial.FloatingRateAssetflowData.prototype.clearAccrualforperiod = function() {
  return this.setAccrualforperiod(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.financial.FloatingRateAssetflowData.prototype.hasAccrualforperiod = function() {
  return jspb.Message.getField(this, 7) != null;
};


/**
 * optional ledger.FutureAmount deferredAmount = 8;
 * @return {?proto.ledger.FutureAmount}
 */
proto.financial.FloatingRateAssetflowData.prototype.getDeferredamount = function() {
  return /** @type{?proto.ledger.FutureAmount} */ (
    jspb.Message.getWrapperField(this, ledger_futureAmount_pb.FutureAmount, 8));
};


/**
 * @param {?proto.ledger.FutureAmount|undefined} value
 * @return {!proto.financial.FloatingRateAssetflowData} returns this
*/
proto.financial.FloatingRateAssetflowData.prototype.setDeferredamount = function(value) {
  return jspb.Message.setWrapperField(this, 8, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.financial.FloatingRateAssetflowData} returns this
 */
proto.financial.FloatingRateAssetflowData.prototype.clearDeferredamount = function() {
  return this.setDeferredamount(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.financial.FloatingRateAssetflowData.prototype.hasDeferredamount = function() {
  return jspb.Message.getField(this, 8) != null;
};


/**
 * optional ledger.FutureAmount deferralBuildUp = 9;
 * @return {?proto.ledger.FutureAmount}
 */
proto.financial.FloatingRateAssetflowData.prototype.getDeferralbuildup = function() {
  return /** @type{?proto.ledger.FutureAmount} */ (
    jspb.Message.getWrapperField(this, ledger_futureAmount_pb.FutureAmount, 9));
};


/**
 * @param {?proto.ledger.FutureAmount|undefined} value
 * @return {!proto.financial.FloatingRateAssetflowData} returns this
*/
proto.financial.FloatingRateAssetflowData.prototype.setDeferralbuildup = function(value) {
  return jspb.Message.setWrapperField(this, 9, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.financial.FloatingRateAssetflowData} returns this
 */
proto.financial.FloatingRateAssetflowData.prototype.clearDeferralbuildup = function() {
  return this.setDeferralbuildup(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.financial.FloatingRateAssetflowData.prototype.hasDeferralbuildup = function() {
  return jspb.Message.getField(this, 9) != null;
};


goog.object.extend(exports, proto.financial);

import { create } from "zustand";
import { Model as StellarTransactionHistoryView } from "james/views/stellarTransactionHistoryView/Model";
import { ReadRequest } from "james/views/stellarTransactionHistoryView";
import { Query } from "james/search/query";
import { Model as StellarAccountView } from "@mesh/common-js/dist/views/stellarAccountView/model_pb";

interface TransactionHistoryViewState {
  loading: boolean;
  accounts: StellarAccountView[];
  selectedHoldingsAccount: StellarAccountView | null;
  selectedAccount: StellarAccountView | null;
  transactionViewModels: StellarTransactionHistoryView[];
  total: number;
  request: Omit<ReadRequest, "context">;
  lastUpdateDate: string;
  lastUpdateBy: string;
}

interface TransactionHistoryViewActions {
  setLoading: (val: boolean) => void;
  setLastUpdateDate: (val: string) => void;
  setAccounts: (val: StellarAccountView[]) => void;
  setSelectedHoldingsAccount: (val: StellarAccountView | null) => void;
  setSelectedAccount: (val: StellarAccountView | null) => void;
  setTransactionViewModels: (val: StellarTransactionHistoryView[]) => void;
  setRequest: (val: Omit<ReadRequest, "context">) => void;
  setTotal: (val: number) => void;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  setCriteria: (val: any) => void;
  setQuery: (val: Query) => void;
  setLastUpdateBy: (val: string) => void;
  clear: () => void;
}

const initialTransactionHistoryViewState: TransactionHistoryViewState = {
  loading: false,
  accounts: [],
  selectedHoldingsAccount: null,
  selectedAccount: null,
  transactionViewModels: [],
  total: 0,
  request: {
    criteria: undefined,
  },
  lastUpdateDate: "",
  lastUpdateBy: "",
};

export const useTransactionHistoryViewStore = create<
  TransactionHistoryViewState & TransactionHistoryViewActions
>((set, get) => ({
  ...initialTransactionHistoryViewState,
  setLoading: (val: boolean) => set({ loading: val }),
  setAccounts: (val: StellarAccountView[]) => set({ accounts: val }),
  setSelectedAccount: (val: StellarAccountView | null) =>
    set({ selectedAccount: val }),
  setSelectedHoldingsAccount: (val: StellarAccountView | null) =>
    set({ selectedHoldingsAccount: val }),
  setTransactionViewModels: (val: StellarTransactionHistoryView[]) =>
    set({ transactionViewModels: val }),
  setTotal: (val: number) => set({ total: val }),
  setLastUpdateDate: (val: string) => set({ lastUpdateDate: val }),
  setRequest: (val: Omit<ReadRequest, "context">) => set({ request: val }),
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  setCriteria: (val: any) => {
    const request = get().request;
    set({
      request: {
        criteria: val,
        query: request.query,
      },
    });
  },
  setQuery: (val: Query) => {
    const request = get().request;
    set({
      request: {
        criteria: request.criteria,
        query: val,
      },
    });
  },
  setLastUpdateBy: (val: string) => set({ lastUpdateBy: val }),
  clear: () => set(() => initialTransactionHistoryViewState),
}));

// source: marketing/instrumentMarketingContent.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global =
    (typeof globalThis !== 'undefined' && globalThis) ||
    (typeof window !== 'undefined' && window) ||
    (typeof global !== 'undefined' && global) ||
    (typeof self !== 'undefined' && self) ||
    (function () { return this; }).call(null) ||
    Function('return this')();

var audit_entry_pb = require('../audit/entry_pb.js');
goog.object.extend(proto, audit_entry_pb);
var marketing_socialMediaLinks_pb = require('../marketing/socialMediaLinks_pb.js');
goog.object.extend(proto, marketing_socialMediaLinks_pb);
var marketing_independentReview_pb = require('../marketing/independentReview_pb.js');
goog.object.extend(proto, marketing_independentReview_pb);
var marketing_mediaEntry_pb = require('../marketing/mediaEntry_pb.js');
goog.object.extend(proto, marketing_mediaEntry_pb);
var marketing_newsArticle_pb = require('../marketing/newsArticle_pb.js');
goog.object.extend(proto, marketing_newsArticle_pb);
goog.exportSymbol('proto.marketing.InstrumentMarketingContent', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.marketing.InstrumentMarketingContent = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.marketing.InstrumentMarketingContent.repeatedFields_, null);
};
goog.inherits(proto.marketing.InstrumentMarketingContent, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.marketing.InstrumentMarketingContent.displayName = 'proto.marketing.InstrumentMarketingContent';
}

/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.marketing.InstrumentMarketingContent.repeatedFields_ = [7,8,9];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.marketing.InstrumentMarketingContent.prototype.toObject = function(opt_includeInstance) {
  return proto.marketing.InstrumentMarketingContent.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.marketing.InstrumentMarketingContent} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.marketing.InstrumentMarketingContent.toObject = function(includeInstance, msg) {
  var f, obj = {
id: jspb.Message.getFieldWithDefault(msg, 1, ""),
ownerid: jspb.Message.getFieldWithDefault(msg, 2, ""),
auditentry: (f = msg.getAuditentry()) && audit_entry_pb.Entry.toObject(includeInstance, f),
instrumentid: jspb.Message.getFieldWithDefault(msg, 4, ""),
investmentobjective: jspb.Message.getFieldWithDefault(msg, 5, ""),
socialmedialinks: (f = msg.getSocialmedialinks()) && marketing_socialMediaLinks_pb.SocialMediaLinks.toObject(includeInstance, f),
newsarticlesList: jspb.Message.toObjectList(msg.getNewsarticlesList(),
    marketing_newsArticle_pb.NewsArticle.toObject, includeInstance),
mediaentriesList: jspb.Message.toObjectList(msg.getMediaentriesList(),
    marketing_mediaEntry_pb.MediaEntry.toObject, includeInstance),
independentreviewsList: jspb.Message.toObjectList(msg.getIndependentreviewsList(),
    marketing_independentReview_pb.IndependentReview.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.marketing.InstrumentMarketingContent}
 */
proto.marketing.InstrumentMarketingContent.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.marketing.InstrumentMarketingContent;
  return proto.marketing.InstrumentMarketingContent.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.marketing.InstrumentMarketingContent} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.marketing.InstrumentMarketingContent}
 */
proto.marketing.InstrumentMarketingContent.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setOwnerid(value);
      break;
    case 3:
      var value = new audit_entry_pb.Entry;
      reader.readMessage(value,audit_entry_pb.Entry.deserializeBinaryFromReader);
      msg.setAuditentry(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setInstrumentid(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setInvestmentobjective(value);
      break;
    case 6:
      var value = new marketing_socialMediaLinks_pb.SocialMediaLinks;
      reader.readMessage(value,marketing_socialMediaLinks_pb.SocialMediaLinks.deserializeBinaryFromReader);
      msg.setSocialmedialinks(value);
      break;
    case 7:
      var value = new marketing_newsArticle_pb.NewsArticle;
      reader.readMessage(value,marketing_newsArticle_pb.NewsArticle.deserializeBinaryFromReader);
      msg.addNewsarticles(value);
      break;
    case 8:
      var value = new marketing_mediaEntry_pb.MediaEntry;
      reader.readMessage(value,marketing_mediaEntry_pb.MediaEntry.deserializeBinaryFromReader);
      msg.addMediaentries(value);
      break;
    case 9:
      var value = new marketing_independentReview_pb.IndependentReview;
      reader.readMessage(value,marketing_independentReview_pb.IndependentReview.deserializeBinaryFromReader);
      msg.addIndependentreviews(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.marketing.InstrumentMarketingContent.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.marketing.InstrumentMarketingContent.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.marketing.InstrumentMarketingContent} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.marketing.InstrumentMarketingContent.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getOwnerid();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getAuditentry();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      audit_entry_pb.Entry.serializeBinaryToWriter
    );
  }
  f = message.getInstrumentid();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getInvestmentobjective();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getSocialmedialinks();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      marketing_socialMediaLinks_pb.SocialMediaLinks.serializeBinaryToWriter
    );
  }
  f = message.getNewsarticlesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      7,
      f,
      marketing_newsArticle_pb.NewsArticle.serializeBinaryToWriter
    );
  }
  f = message.getMediaentriesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      8,
      f,
      marketing_mediaEntry_pb.MediaEntry.serializeBinaryToWriter
    );
  }
  f = message.getIndependentreviewsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      9,
      f,
      marketing_independentReview_pb.IndependentReview.serializeBinaryToWriter
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.marketing.InstrumentMarketingContent.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.marketing.InstrumentMarketingContent} returns this
 */
proto.marketing.InstrumentMarketingContent.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string ownerID = 2;
 * @return {string}
 */
proto.marketing.InstrumentMarketingContent.prototype.getOwnerid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.marketing.InstrumentMarketingContent} returns this
 */
proto.marketing.InstrumentMarketingContent.prototype.setOwnerid = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional audit.Entry auditEntry = 3;
 * @return {?proto.audit.Entry}
 */
proto.marketing.InstrumentMarketingContent.prototype.getAuditentry = function() {
  return /** @type{?proto.audit.Entry} */ (
    jspb.Message.getWrapperField(this, audit_entry_pb.Entry, 3));
};


/**
 * @param {?proto.audit.Entry|undefined} value
 * @return {!proto.marketing.InstrumentMarketingContent} returns this
*/
proto.marketing.InstrumentMarketingContent.prototype.setAuditentry = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.marketing.InstrumentMarketingContent} returns this
 */
proto.marketing.InstrumentMarketingContent.prototype.clearAuditentry = function() {
  return this.setAuditentry(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.marketing.InstrumentMarketingContent.prototype.hasAuditentry = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional string instrumentID = 4;
 * @return {string}
 */
proto.marketing.InstrumentMarketingContent.prototype.getInstrumentid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.marketing.InstrumentMarketingContent} returns this
 */
proto.marketing.InstrumentMarketingContent.prototype.setInstrumentid = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string investmentObjective = 5;
 * @return {string}
 */
proto.marketing.InstrumentMarketingContent.prototype.getInvestmentobjective = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.marketing.InstrumentMarketingContent} returns this
 */
proto.marketing.InstrumentMarketingContent.prototype.setInvestmentobjective = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional SocialMediaLinks socialMediaLinks = 6;
 * @return {?proto.marketing.SocialMediaLinks}
 */
proto.marketing.InstrumentMarketingContent.prototype.getSocialmedialinks = function() {
  return /** @type{?proto.marketing.SocialMediaLinks} */ (
    jspb.Message.getWrapperField(this, marketing_socialMediaLinks_pb.SocialMediaLinks, 6));
};


/**
 * @param {?proto.marketing.SocialMediaLinks|undefined} value
 * @return {!proto.marketing.InstrumentMarketingContent} returns this
*/
proto.marketing.InstrumentMarketingContent.prototype.setSocialmedialinks = function(value) {
  return jspb.Message.setWrapperField(this, 6, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.marketing.InstrumentMarketingContent} returns this
 */
proto.marketing.InstrumentMarketingContent.prototype.clearSocialmedialinks = function() {
  return this.setSocialmedialinks(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.marketing.InstrumentMarketingContent.prototype.hasSocialmedialinks = function() {
  return jspb.Message.getField(this, 6) != null;
};


/**
 * repeated NewsArticle newsArticles = 7;
 * @return {!Array<!proto.marketing.NewsArticle>}
 */
proto.marketing.InstrumentMarketingContent.prototype.getNewsarticlesList = function() {
  return /** @type{!Array<!proto.marketing.NewsArticle>} */ (
    jspb.Message.getRepeatedWrapperField(this, marketing_newsArticle_pb.NewsArticle, 7));
};


/**
 * @param {!Array<!proto.marketing.NewsArticle>} value
 * @return {!proto.marketing.InstrumentMarketingContent} returns this
*/
proto.marketing.InstrumentMarketingContent.prototype.setNewsarticlesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 7, value);
};


/**
 * @param {!proto.marketing.NewsArticle=} opt_value
 * @param {number=} opt_index
 * @return {!proto.marketing.NewsArticle}
 */
proto.marketing.InstrumentMarketingContent.prototype.addNewsarticles = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 7, opt_value, proto.marketing.NewsArticle, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.marketing.InstrumentMarketingContent} returns this
 */
proto.marketing.InstrumentMarketingContent.prototype.clearNewsarticlesList = function() {
  return this.setNewsarticlesList([]);
};


/**
 * repeated MediaEntry mediaEntries = 8;
 * @return {!Array<!proto.marketing.MediaEntry>}
 */
proto.marketing.InstrumentMarketingContent.prototype.getMediaentriesList = function() {
  return /** @type{!Array<!proto.marketing.MediaEntry>} */ (
    jspb.Message.getRepeatedWrapperField(this, marketing_mediaEntry_pb.MediaEntry, 8));
};


/**
 * @param {!Array<!proto.marketing.MediaEntry>} value
 * @return {!proto.marketing.InstrumentMarketingContent} returns this
*/
proto.marketing.InstrumentMarketingContent.prototype.setMediaentriesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 8, value);
};


/**
 * @param {!proto.marketing.MediaEntry=} opt_value
 * @param {number=} opt_index
 * @return {!proto.marketing.MediaEntry}
 */
proto.marketing.InstrumentMarketingContent.prototype.addMediaentries = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 8, opt_value, proto.marketing.MediaEntry, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.marketing.InstrumentMarketingContent} returns this
 */
proto.marketing.InstrumentMarketingContent.prototype.clearMediaentriesList = function() {
  return this.setMediaentriesList([]);
};


/**
 * repeated IndependentReview independentReviews = 9;
 * @return {!Array<!proto.marketing.IndependentReview>}
 */
proto.marketing.InstrumentMarketingContent.prototype.getIndependentreviewsList = function() {
  return /** @type{!Array<!proto.marketing.IndependentReview>} */ (
    jspb.Message.getRepeatedWrapperField(this, marketing_independentReview_pb.IndependentReview, 9));
};


/**
 * @param {!Array<!proto.marketing.IndependentReview>} value
 * @return {!proto.marketing.InstrumentMarketingContent} returns this
*/
proto.marketing.InstrumentMarketingContent.prototype.setIndependentreviewsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 9, value);
};


/**
 * @param {!proto.marketing.IndependentReview=} opt_value
 * @param {number=} opt_index
 * @return {!proto.marketing.IndependentReview}
 */
proto.marketing.InstrumentMarketingContent.prototype.addIndependentreviews = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 9, opt_value, proto.marketing.IndependentReview, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.marketing.InstrumentMarketingContent} returns this
 */
proto.marketing.InstrumentMarketingContent.prototype.clearIndependentreviewsList = function() {
  return this.setIndependentreviewsList([]);
};


goog.object.extend(exports, proto.marketing);

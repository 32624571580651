import { Network } from "@mesh/common-js/dist/stellar/network_pb";
import { Token } from "@mesh/common-js/dist/stellar/token_pb";

export function NewLiquidityPoolToken(tokenA: Token, tokenB: Token): Token {
  return new Token()
    .setCode(`${tokenA.getCode()}/${tokenB.getCode()}`)
    .setIssuer(`${tokenA.getIssuer()}/${tokenB.getIssuer()}`)
    .setNetwork(tokenA.getNetwork());
}

export function IsLiquidityPoolToken(token: Token): boolean {
  return (
    token.getCode().split("/").length === 2 &&
    token.getIssuer().split("/").length === 2 &&
    (token.getNetwork() === Network.STELLAR_TEST_SDF_NETWORK ||
      token.getNetwork() === Network.STELLAR_PUBLIC_NETWORK)
  );
}

export function LiquidityPoolTokenToReserveTokens(
  token: Token,
): [Token, Token] {
  if (!IsLiquidityPoolToken(token)) {
    throw new TypeError(`expected liquidity pool token but got: ${token}`);
  }

  const tokenCodes = token.getCode().split("/");
  const tokenIssuers = token.getIssuer().split("/");

  return [
    new Token()
      .setCode(tokenCodes[0])
      .setIssuer(tokenIssuers[0])
      .setNetwork(token.getNetwork()),
    new Token()
      .setCode(tokenCodes[1])
      .setIssuer(tokenIssuers[1])
      .setNetwork(token.getNetwork()),
  ];
}

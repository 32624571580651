// source: stellar/enrichedAccount.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global =
    (typeof globalThis !== 'undefined' && globalThis) ||
    (typeof window !== 'undefined' && window) ||
    (typeof global !== 'undefined' && global) ||
    (typeof self !== 'undefined' && self) ||
    (function () { return this; }).call(null) ||
    Function('return this')();

var audit_entry_pb = require('../audit/entry_pb.js');
goog.object.extend(proto, audit_entry_pb);
var stellar_network_pb = require('../stellar/network_pb.js');
goog.object.extend(proto, stellar_network_pb);
var stellar_amount_pb = require('../stellar/amount_pb.js');
goog.object.extend(proto, stellar_amount_pb);
goog.exportSymbol('proto.stellar.AccountState', null, global);
goog.exportSymbol('proto.stellar.AuthFlags', null, global);
goog.exportSymbol('proto.stellar.Balance', null, global);
goog.exportSymbol('proto.stellar.EnrichedAccount', null, global);
goog.exportSymbol('proto.stellar.Signatory', null, global);
goog.exportSymbol('proto.stellar.SignatoryType', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.stellar.EnrichedAccount = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.stellar.EnrichedAccount.repeatedFields_, null);
};
goog.inherits(proto.stellar.EnrichedAccount, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.stellar.EnrichedAccount.displayName = 'proto.stellar.EnrichedAccount';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.stellar.Balance = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.stellar.Balance, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.stellar.Balance.displayName = 'proto.stellar.Balance';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.stellar.Signatory = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.stellar.Signatory, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.stellar.Signatory.displayName = 'proto.stellar.Signatory';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.stellar.AuthFlags = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.stellar.AuthFlags, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.stellar.AuthFlags.displayName = 'proto.stellar.AuthFlags';
}

/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.stellar.EnrichedAccount.repeatedFields_ = [10,11];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.stellar.EnrichedAccount.prototype.toObject = function(opt_includeInstance) {
  return proto.stellar.EnrichedAccount.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.stellar.EnrichedAccount} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.stellar.EnrichedAccount.toObject = function(includeInstance, msg) {
  var f, obj = {
id: jspb.Message.getFieldWithDefault(msg, 1, ""),
ownerid: jspb.Message.getFieldWithDefault(msg, 2, ""),
number: jspb.Message.getFieldWithDefault(msg, 3, ""),
label: jspb.Message.getFieldWithDefault(msg, 4, ""),
state: jspb.Message.getFieldWithDefault(msg, 5, 0),
network: jspb.Message.getFieldWithDefault(msg, 6, 0),
ledgerid: jspb.Message.getFieldWithDefault(msg, 7, ""),
system: jspb.Message.getBooleanFieldWithDefault(msg, 8, false),
auditentry: (f = msg.getAuditentry()) && audit_entry_pb.Entry.toObject(includeInstance, f),
balancesList: jspb.Message.toObjectList(msg.getBalancesList(),
    proto.stellar.Balance.toObject, includeInstance),
signatoriesList: jspb.Message.toObjectList(msg.getSignatoriesList(),
    proto.stellar.Signatory.toObject, includeInstance),
authflags: (f = msg.getAuthflags()) && proto.stellar.AuthFlags.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.stellar.EnrichedAccount}
 */
proto.stellar.EnrichedAccount.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.stellar.EnrichedAccount;
  return proto.stellar.EnrichedAccount.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.stellar.EnrichedAccount} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.stellar.EnrichedAccount}
 */
proto.stellar.EnrichedAccount.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setOwnerid(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setNumber(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setLabel(value);
      break;
    case 5:
      var value = /** @type {!proto.stellar.AccountState} */ (reader.readEnum());
      msg.setState(value);
      break;
    case 6:
      var value = /** @type {!proto.stellar.Network} */ (reader.readEnum());
      msg.setNetwork(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setLedgerid(value);
      break;
    case 8:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setSystem(value);
      break;
    case 9:
      var value = new audit_entry_pb.Entry;
      reader.readMessage(value,audit_entry_pb.Entry.deserializeBinaryFromReader);
      msg.setAuditentry(value);
      break;
    case 10:
      var value = new proto.stellar.Balance;
      reader.readMessage(value,proto.stellar.Balance.deserializeBinaryFromReader);
      msg.addBalances(value);
      break;
    case 11:
      var value = new proto.stellar.Signatory;
      reader.readMessage(value,proto.stellar.Signatory.deserializeBinaryFromReader);
      msg.addSignatories(value);
      break;
    case 12:
      var value = new proto.stellar.AuthFlags;
      reader.readMessage(value,proto.stellar.AuthFlags.deserializeBinaryFromReader);
      msg.setAuthflags(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.stellar.EnrichedAccount.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.stellar.EnrichedAccount.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.stellar.EnrichedAccount} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.stellar.EnrichedAccount.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getOwnerid();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getNumber();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getLabel();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getState();
  if (f !== 0.0) {
    writer.writeEnum(
      5,
      f
    );
  }
  f = message.getNetwork();
  if (f !== 0.0) {
    writer.writeEnum(
      6,
      f
    );
  }
  f = message.getLedgerid();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getSystem();
  if (f) {
    writer.writeBool(
      8,
      f
    );
  }
  f = message.getAuditentry();
  if (f != null) {
    writer.writeMessage(
      9,
      f,
      audit_entry_pb.Entry.serializeBinaryToWriter
    );
  }
  f = message.getBalancesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      10,
      f,
      proto.stellar.Balance.serializeBinaryToWriter
    );
  }
  f = message.getSignatoriesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      11,
      f,
      proto.stellar.Signatory.serializeBinaryToWriter
    );
  }
  f = message.getAuthflags();
  if (f != null) {
    writer.writeMessage(
      12,
      f,
      proto.stellar.AuthFlags.serializeBinaryToWriter
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.stellar.EnrichedAccount.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.stellar.EnrichedAccount} returns this
 */
proto.stellar.EnrichedAccount.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string ownerID = 2;
 * @return {string}
 */
proto.stellar.EnrichedAccount.prototype.getOwnerid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.stellar.EnrichedAccount} returns this
 */
proto.stellar.EnrichedAccount.prototype.setOwnerid = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string number = 3;
 * @return {string}
 */
proto.stellar.EnrichedAccount.prototype.getNumber = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.stellar.EnrichedAccount} returns this
 */
proto.stellar.EnrichedAccount.prototype.setNumber = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string label = 4;
 * @return {string}
 */
proto.stellar.EnrichedAccount.prototype.getLabel = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.stellar.EnrichedAccount} returns this
 */
proto.stellar.EnrichedAccount.prototype.setLabel = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional AccountState state = 5;
 * @return {!proto.stellar.AccountState}
 */
proto.stellar.EnrichedAccount.prototype.getState = function() {
  return /** @type {!proto.stellar.AccountState} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {!proto.stellar.AccountState} value
 * @return {!proto.stellar.EnrichedAccount} returns this
 */
proto.stellar.EnrichedAccount.prototype.setState = function(value) {
  return jspb.Message.setProto3EnumField(this, 5, value);
};


/**
 * optional Network network = 6;
 * @return {!proto.stellar.Network}
 */
proto.stellar.EnrichedAccount.prototype.getNetwork = function() {
  return /** @type {!proto.stellar.Network} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {!proto.stellar.Network} value
 * @return {!proto.stellar.EnrichedAccount} returns this
 */
proto.stellar.EnrichedAccount.prototype.setNetwork = function(value) {
  return jspb.Message.setProto3EnumField(this, 6, value);
};


/**
 * optional string ledgerID = 7;
 * @return {string}
 */
proto.stellar.EnrichedAccount.prototype.getLedgerid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.stellar.EnrichedAccount} returns this
 */
proto.stellar.EnrichedAccount.prototype.setLedgerid = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional bool system = 8;
 * @return {boolean}
 */
proto.stellar.EnrichedAccount.prototype.getSystem = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 8, false));
};


/**
 * @param {boolean} value
 * @return {!proto.stellar.EnrichedAccount} returns this
 */
proto.stellar.EnrichedAccount.prototype.setSystem = function(value) {
  return jspb.Message.setProto3BooleanField(this, 8, value);
};


/**
 * optional audit.Entry auditEntry = 9;
 * @return {?proto.audit.Entry}
 */
proto.stellar.EnrichedAccount.prototype.getAuditentry = function() {
  return /** @type{?proto.audit.Entry} */ (
    jspb.Message.getWrapperField(this, audit_entry_pb.Entry, 9));
};


/**
 * @param {?proto.audit.Entry|undefined} value
 * @return {!proto.stellar.EnrichedAccount} returns this
*/
proto.stellar.EnrichedAccount.prototype.setAuditentry = function(value) {
  return jspb.Message.setWrapperField(this, 9, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.stellar.EnrichedAccount} returns this
 */
proto.stellar.EnrichedAccount.prototype.clearAuditentry = function() {
  return this.setAuditentry(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.stellar.EnrichedAccount.prototype.hasAuditentry = function() {
  return jspb.Message.getField(this, 9) != null;
};


/**
 * repeated Balance balances = 10;
 * @return {!Array<!proto.stellar.Balance>}
 */
proto.stellar.EnrichedAccount.prototype.getBalancesList = function() {
  return /** @type{!Array<!proto.stellar.Balance>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.stellar.Balance, 10));
};


/**
 * @param {!Array<!proto.stellar.Balance>} value
 * @return {!proto.stellar.EnrichedAccount} returns this
*/
proto.stellar.EnrichedAccount.prototype.setBalancesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 10, value);
};


/**
 * @param {!proto.stellar.Balance=} opt_value
 * @param {number=} opt_index
 * @return {!proto.stellar.Balance}
 */
proto.stellar.EnrichedAccount.prototype.addBalances = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 10, opt_value, proto.stellar.Balance, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.stellar.EnrichedAccount} returns this
 */
proto.stellar.EnrichedAccount.prototype.clearBalancesList = function() {
  return this.setBalancesList([]);
};


/**
 * repeated Signatory signatories = 11;
 * @return {!Array<!proto.stellar.Signatory>}
 */
proto.stellar.EnrichedAccount.prototype.getSignatoriesList = function() {
  return /** @type{!Array<!proto.stellar.Signatory>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.stellar.Signatory, 11));
};


/**
 * @param {!Array<!proto.stellar.Signatory>} value
 * @return {!proto.stellar.EnrichedAccount} returns this
*/
proto.stellar.EnrichedAccount.prototype.setSignatoriesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 11, value);
};


/**
 * @param {!proto.stellar.Signatory=} opt_value
 * @param {number=} opt_index
 * @return {!proto.stellar.Signatory}
 */
proto.stellar.EnrichedAccount.prototype.addSignatories = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 11, opt_value, proto.stellar.Signatory, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.stellar.EnrichedAccount} returns this
 */
proto.stellar.EnrichedAccount.prototype.clearSignatoriesList = function() {
  return this.setSignatoriesList([]);
};


/**
 * optional AuthFlags authFlags = 12;
 * @return {?proto.stellar.AuthFlags}
 */
proto.stellar.EnrichedAccount.prototype.getAuthflags = function() {
  return /** @type{?proto.stellar.AuthFlags} */ (
    jspb.Message.getWrapperField(this, proto.stellar.AuthFlags, 12));
};


/**
 * @param {?proto.stellar.AuthFlags|undefined} value
 * @return {!proto.stellar.EnrichedAccount} returns this
*/
proto.stellar.EnrichedAccount.prototype.setAuthflags = function(value) {
  return jspb.Message.setWrapperField(this, 12, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.stellar.EnrichedAccount} returns this
 */
proto.stellar.EnrichedAccount.prototype.clearAuthflags = function() {
  return this.setAuthflags(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.stellar.EnrichedAccount.prototype.hasAuthflags = function() {
  return jspb.Message.getField(this, 12) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.stellar.Balance.prototype.toObject = function(opt_includeInstance) {
  return proto.stellar.Balance.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.stellar.Balance} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.stellar.Balance.toObject = function(includeInstance, msg) {
  var f, obj = {
amount: (f = msg.getAmount()) && stellar_amount_pb.Amount.toObject(includeInstance, f),
limit: (f = msg.getLimit()) && stellar_amount_pb.Amount.toObject(includeInstance, f),
sponsor: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.stellar.Balance}
 */
proto.stellar.Balance.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.stellar.Balance;
  return proto.stellar.Balance.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.stellar.Balance} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.stellar.Balance}
 */
proto.stellar.Balance.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new stellar_amount_pb.Amount;
      reader.readMessage(value,stellar_amount_pb.Amount.deserializeBinaryFromReader);
      msg.setAmount(value);
      break;
    case 2:
      var value = new stellar_amount_pb.Amount;
      reader.readMessage(value,stellar_amount_pb.Amount.deserializeBinaryFromReader);
      msg.setLimit(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setSponsor(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.stellar.Balance.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.stellar.Balance.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.stellar.Balance} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.stellar.Balance.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAmount();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      stellar_amount_pb.Amount.serializeBinaryToWriter
    );
  }
  f = message.getLimit();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      stellar_amount_pb.Amount.serializeBinaryToWriter
    );
  }
  f = message.getSponsor();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional Amount amount = 1;
 * @return {?proto.stellar.Amount}
 */
proto.stellar.Balance.prototype.getAmount = function() {
  return /** @type{?proto.stellar.Amount} */ (
    jspb.Message.getWrapperField(this, stellar_amount_pb.Amount, 1));
};


/**
 * @param {?proto.stellar.Amount|undefined} value
 * @return {!proto.stellar.Balance} returns this
*/
proto.stellar.Balance.prototype.setAmount = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.stellar.Balance} returns this
 */
proto.stellar.Balance.prototype.clearAmount = function() {
  return this.setAmount(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.stellar.Balance.prototype.hasAmount = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional Amount limit = 2;
 * @return {?proto.stellar.Amount}
 */
proto.stellar.Balance.prototype.getLimit = function() {
  return /** @type{?proto.stellar.Amount} */ (
    jspb.Message.getWrapperField(this, stellar_amount_pb.Amount, 2));
};


/**
 * @param {?proto.stellar.Amount|undefined} value
 * @return {!proto.stellar.Balance} returns this
*/
proto.stellar.Balance.prototype.setLimit = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.stellar.Balance} returns this
 */
proto.stellar.Balance.prototype.clearLimit = function() {
  return this.setLimit(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.stellar.Balance.prototype.hasLimit = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional string sponsor = 3;
 * @return {string}
 */
proto.stellar.Balance.prototype.getSponsor = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.stellar.Balance} returns this
 */
proto.stellar.Balance.prototype.setSponsor = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.stellar.Signatory.prototype.toObject = function(opt_includeInstance) {
  return proto.stellar.Signatory.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.stellar.Signatory} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.stellar.Signatory.toObject = function(includeInstance, msg) {
  var f, obj = {
type: jspb.Message.getFieldWithDefault(msg, 1, 0),
key: jspb.Message.getFieldWithDefault(msg, 2, ""),
weight: jspb.Message.getFieldWithDefault(msg, 3, 0),
sponsor: jspb.Message.getFieldWithDefault(msg, 4, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.stellar.Signatory}
 */
proto.stellar.Signatory.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.stellar.Signatory;
  return proto.stellar.Signatory.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.stellar.Signatory} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.stellar.Signatory}
 */
proto.stellar.Signatory.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!proto.stellar.SignatoryType} */ (reader.readEnum());
      msg.setType(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setKey(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setWeight(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setSponsor(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.stellar.Signatory.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.stellar.Signatory.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.stellar.Signatory} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.stellar.Signatory.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getType();
  if (f !== 0.0) {
    writer.writeEnum(
      1,
      f
    );
  }
  f = message.getKey();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getWeight();
  if (f !== 0) {
    writer.writeInt32(
      3,
      f
    );
  }
  f = message.getSponsor();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
};


/**
 * optional SignatoryType type = 1;
 * @return {!proto.stellar.SignatoryType}
 */
proto.stellar.Signatory.prototype.getType = function() {
  return /** @type {!proto.stellar.SignatoryType} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {!proto.stellar.SignatoryType} value
 * @return {!proto.stellar.Signatory} returns this
 */
proto.stellar.Signatory.prototype.setType = function(value) {
  return jspb.Message.setProto3EnumField(this, 1, value);
};


/**
 * optional string key = 2;
 * @return {string}
 */
proto.stellar.Signatory.prototype.getKey = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.stellar.Signatory} returns this
 */
proto.stellar.Signatory.prototype.setKey = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional int32 weight = 3;
 * @return {number}
 */
proto.stellar.Signatory.prototype.getWeight = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.stellar.Signatory} returns this
 */
proto.stellar.Signatory.prototype.setWeight = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional string sponsor = 4;
 * @return {string}
 */
proto.stellar.Signatory.prototype.getSponsor = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.stellar.Signatory} returns this
 */
proto.stellar.Signatory.prototype.setSponsor = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.stellar.AuthFlags.prototype.toObject = function(opt_includeInstance) {
  return proto.stellar.AuthFlags.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.stellar.AuthFlags} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.stellar.AuthFlags.toObject = function(includeInstance, msg) {
  var f, obj = {
authrequired: jspb.Message.getBooleanFieldWithDefault(msg, 1, false),
authrevocable: jspb.Message.getBooleanFieldWithDefault(msg, 2, false),
authimmutable: jspb.Message.getBooleanFieldWithDefault(msg, 3, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.stellar.AuthFlags}
 */
proto.stellar.AuthFlags.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.stellar.AuthFlags;
  return proto.stellar.AuthFlags.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.stellar.AuthFlags} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.stellar.AuthFlags}
 */
proto.stellar.AuthFlags.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setAuthrequired(value);
      break;
    case 2:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setAuthrevocable(value);
      break;
    case 3:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setAuthimmutable(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.stellar.AuthFlags.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.stellar.AuthFlags.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.stellar.AuthFlags} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.stellar.AuthFlags.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAuthrequired();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
  f = message.getAuthrevocable();
  if (f) {
    writer.writeBool(
      2,
      f
    );
  }
  f = message.getAuthimmutable();
  if (f) {
    writer.writeBool(
      3,
      f
    );
  }
};


/**
 * optional bool authRequired = 1;
 * @return {boolean}
 */
proto.stellar.AuthFlags.prototype.getAuthrequired = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.stellar.AuthFlags} returns this
 */
proto.stellar.AuthFlags.prototype.setAuthrequired = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};


/**
 * optional bool authRevocable = 2;
 * @return {boolean}
 */
proto.stellar.AuthFlags.prototype.getAuthrevocable = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 2, false));
};


/**
 * @param {boolean} value
 * @return {!proto.stellar.AuthFlags} returns this
 */
proto.stellar.AuthFlags.prototype.setAuthrevocable = function(value) {
  return jspb.Message.setProto3BooleanField(this, 2, value);
};


/**
 * optional bool authImmutable = 3;
 * @return {boolean}
 */
proto.stellar.AuthFlags.prototype.getAuthimmutable = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 3, false));
};


/**
 * @param {boolean} value
 * @return {!proto.stellar.AuthFlags} returns this
 */
proto.stellar.AuthFlags.prototype.setAuthimmutable = function(value) {
  return jspb.Message.setProto3BooleanField(this, 3, value);
};


/**
 * @enum {number}
 */
proto.stellar.AccountState = {
  UNDEFINED_ACCOUNT_STATE: 0,
  OPEN_ACCOUNT_STATE: 3,
  CLOSED_ACCOUNT_STATE: 4
};

/**
 * @enum {number}
 */
proto.stellar.SignatoryType = {
  UNDEFINED_SIGNATORY_TYPE: 0,
  SHA256_HASH_SIGNATORY_TYPE: 1,
  ED25519_SIGNATORY_TYPE: 2,
  PRE_AUTH_TX_SIGNATORY_TYPE: 3
};

goog.object.extend(exports, proto.stellar);

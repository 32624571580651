import React, { useCallback } from "react";
import { Box, Theme, useMediaQuery } from "@mui/material";
import meshMiniLogo from "assets/images/logo/meshLogo.svg";
import { countries } from "james/country";
import { useFirebaseContext } from "context/Firebase";
import { useNavigate } from "react-router-dom";
import { CountrySelectorCard } from "./components/CountrySelectorCard/CountrySelectorCard";
import { useUserRegistrationHelper } from "hooks/helperAPI/useUserRegistrationHelper";
import { UserRegisteringLoader } from "components/Loaders";
import background from "assets/images/background/background.png";
import { useAppNoticeContext } from "context/AppNotice/AppNotice";

interface CountrySelectorProps {
  selectedCountry: (countryCode: string) => void;
}

export const CountrySelector = (props: CountrySelectorProps) => {
  const smUp = useMediaQuery((theme: Theme) => theme.breakpoints.up("sm"));
  const { NotificationBannerHeight } = useAppNoticeContext();
  const { firebaseAuthenticated, firebaseUserIDToken, firebaseUser } =
    useFirebaseContext();
  const navigate = useNavigate();
  const { registerIndividualUser, individualUserRegistrationInProgress } =
    useUserRegistrationHelper();

  const handleCountryOfResidenceSelection = useCallback(
    async (countryCode: string) => {
      if (countryCode === countries[200].value) {
        if (firebaseAuthenticated) {
          // start the user registration process once the user click submit c
          if (!firebaseUser?.emailVerified) {
            navigate("/sign-up/verify-email");
          } else {
            await registerIndividualUser(firebaseUserIDToken);
          }
        } else {
          navigate("/sign-up/register");
        }
      } else {
        props.selectedCountry(countryCode);
        navigate("/sign-up/non-sa-client");
      }
    },
    [
      firebaseAuthenticated,
      firebaseUserIDToken,
      registerIndividualUser,
      firebaseUser,
    ],
  );
  if (individualUserRegistrationInProgress) {
    return <UserRegisteringLoader />;
  }

  return (
    <>
      {smUp ? (
        <Box
          sx={{
            position: "relative",
            display: "flex",
            flexDirection: "column",
            backgroundImage: `url(${background})`,
            backgroundSize: "cover",
            height: `calc(100vh - ${NotificationBannerHeight}px)`,
            overflowY: {
              xs: "auto",
              md: "hidden",
            },
            width: "100%",
          }}
        >
          <Box
            sx={{
              width: 168,
              height: 77,
              position: "fixed",
            }}
          >
            <img alt="" src={meshMiniLogo} />
          </Box>
          <Box
            className="meshScroll"
            sx={{
              height: "100%",
              display: "grid",
              gridTemplateRows: `
                "a"
                "b"
                "c"
              `,
              justifyContent: "center",
              minWidth: "100vw",
              overflowY: "auto",
            }}
          >
            <Box
              sx={{
                gridArea: "b",
                pb: 2,
                pt: 4,
              }}
            >
              <CountrySelectorCard
                onSubmit={handleCountryOfResidenceSelection}
                onGoBack={() => navigate("/sign-up")}
              />
            </Box>
          </Box>
        </Box>
      ) : (
        <>
          <CountrySelectorCard
            onSubmit={handleCountryOfResidenceSelection}
            onGoBack={() => navigate("/sign-up")}
          />
        </>
      )}
    </>
  );
};

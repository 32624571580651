import { AuditEntry } from "james/audit";
import { Asset } from "james/financial/Asset";
import { Token } from "james/ledger/Token";
import dayjs from "dayjs";

export const YieldBearingStablecoinTypeName =
  "mesh::financial/YieldBearingStablecoin";

export class YieldBearingStablecoin implements Asset {
  public ["@type"]: string = YieldBearingStablecoinTypeName;

  public id = "";

  public ownerID = "";

  public name = "";

  public shortName = "";

  public underlyingAssetID = "";

  public token: Token = new Token();

  public issueDate: string = dayjs().format();

  public auditEntry: AuditEntry = new AuditEntry();

  constructor(yieldBearingStablecoin?: YieldBearingStablecoin) {
    if (!yieldBearingStablecoin) {
      return;
    }
    this.id = yieldBearingStablecoin.id;
    this.ownerID = yieldBearingStablecoin.ownerID;
    this.name = yieldBearingStablecoin.name;
    this.shortName = yieldBearingStablecoin.shortName;
    this.underlyingAssetID = yieldBearingStablecoin.underlyingAssetID;
    this.token = new Token(yieldBearingStablecoin.token);
    this.issueDate = yieldBearingStablecoin.issueDate;
    this.auditEntry = new AuditEntry(yieldBearingStablecoin.auditEntry);
  }

  assetID(): string {
    return this.id;
  }

  assetOwnerID(): string {
    return this.ownerID;
  }

  assetToken(): Token {
    return this.token;
  }

  assetIssueDate(): string {
    return this.issueDate;
  }

  assetName(): string {
    return this.name;
  }

  assetShortName(): string {
    return this.shortName;
  }
}

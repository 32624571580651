import React, { useRef, useState } from "react";
import { Model } from "@mesh/common-js/dist/views/stellarAccountView/model_pb";
import { Card, CardContent, Grid, Typography } from "@mui/material";
import { FundAccountDialog } from "../FundAccountDialog/FundAccountDialog";
import { DefundAccountDialog } from "../DefundAccountDialog/DefundAccountDialog";

interface AccountCardProps {
  accountModel: Model;
  selected?: boolean;
  onClick: () => void;
  fundAccountOnClick?: () => void;
  defundAccountOnClick?: () => void;
}

const removeWhiteSpaceFromString = (input: string) => input.replace(/\s/g, "");

export function AccountCard(props: AccountCardProps) {
  const cardEl = useRef<HTMLDivElement | null>(null);
  const [displayFundAccDialog, setDisplayFundAccDialog] = useState(false);
  const [displayDefundAccountDialog, setDisplayDefundAccountDialog] =
    useState(false);

  return (
    <>
      <Card
        id={`accountCard-${removeWhiteSpaceFromString(
          props.accountModel.getLabel(),
        )}-${props.accountModel.getNumber()}-card`}
        onClick={props.onClick}
        ref={cardEl}
        sx={(theme) => ({
          cursor: "pointer",
          borderRadius: theme.spacing(1),
          width: props.selected ? "calc(100% + 24px)" : "calc(100% - 24px)",
          height: "75px",
          margin: theme.spacing(0, 0, 2, 0),
          background: props.selected
            ? `transparent linear-gradient(to right,${theme.palette.secondary.dark} 0%,${theme.palette.background.paper} 95%)`
            : theme.palette.background.paper,
          transition: "width 1s",
          [theme.breakpoints.up("lg")]: {
            "&:hover": {
              width: "calc(100% + 24px)",
            },
          },
        })}
      >
        <CardContent
          sx={(theme) => ({
            display: "grid",
            gridTemplateColumns: "100% 1fr",
            [theme.breakpoints.up("lg")]: {
              gridTemplateColumns: "350px 1fr",
            },
          })}
        >
          <div>
            <Grid container direction="row" justifyContent="space-between">
              <Grid item xs={9}>
                <Typography
                  sx={(theme) => ({
                    color: theme.palette.text.secondary,
                    textOverflow: "ellipsis",
                    overflow: "hidden",
                    whiteSpace: "nowrap",
                  })}
                  variant="body1"
                  id={`accountCard-${removeWhiteSpaceFromString(
                    props.accountModel.getLabel(),
                  )}-${props.accountModel.getNumber()}-groupName`}
                >
                  {props.accountModel.getGroupname()}
                </Typography>
              </Grid>
              <Grid item xs={3}>
                <Typography
                  align="right"
                  sx={(theme) => ({
                    color: theme.palette.text.secondary,
                  })}
                  variant="body2"
                  id={`accountCard-${removeWhiteSpaceFromString(
                    props.accountModel.getLabel(),
                  )}-${props.accountModel.getNumber()}-accountNumberLabel`}
                >
                  Account
                </Typography>
              </Grid>
            </Grid>
            <Grid
              container
              direction="row"
              justifyContent="space-between"
              alignItems="flex-start"
            >
              <Grid item xs={10}>
                <Typography
                  variant="h5"
                  id={`accountCard-${removeWhiteSpaceFromString(
                    props.accountModel.getLabel(),
                  )}-${props.accountModel.getNumber()}-accountType`}
                >
                  {`${props.accountModel.getLabel()} Account`}
                </Typography>
              </Grid>
              <Grid item xs={2}>
                <Typography variant="body2" align="right">
                  {props.accountModel.getNumber()}
                </Typography>
              </Grid>
            </Grid>
          </div>
          <div />
        </CardContent>
      </Card>

      {displayFundAccDialog && (
        <FundAccountDialog
          accountID={props.accountModel.getId()}
          open={displayFundAccDialog}
          onClose={() => setDisplayFundAccDialog(!displayFundAccDialog)}
        />
      )}
      {displayDefundAccountDialog && (
        <DefundAccountDialog
          open={displayDefundAccountDialog}
          accountModel={props.accountModel}
          onClose={() =>
            setDisplayDefundAccountDialog(!displayDefundAccountDialog)
          }
        />
      )}
    </>
  );
}

// source: financial/paymentRecipientDataPerUnitHeld.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global =
    (typeof globalThis !== 'undefined' && globalThis) ||
    (typeof window !== 'undefined' && window) ||
    (typeof global !== 'undefined' && global) ||
    (typeof self !== 'undefined' && self) ||
    (function () { return this; }).call(null) ||
    Function('return this')();

var num_decimal_pb = require('../num/decimal_pb.js');
goog.object.extend(proto, num_decimal_pb);
var google_protobuf_timestamp_pb = require('google-protobuf/google/protobuf/timestamp_pb.js');
goog.object.extend(proto, google_protobuf_timestamp_pb);
var ledger_futureAmount_pb = require('../ledger/futureAmount_pb.js');
goog.object.extend(proto, ledger_futureAmount_pb);
goog.exportSymbol('proto.financial.HoldingEntryAccountLabel', null, global);
goog.exportSymbol('proto.financial.PerUnitHeldHoldingEntry', null, global);
goog.exportSymbol('proto.financial.PerUnitHeldPaymentRecipientData', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.financial.PerUnitHeldPaymentRecipientData = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.financial.PerUnitHeldPaymentRecipientData.repeatedFields_, null);
};
goog.inherits(proto.financial.PerUnitHeldPaymentRecipientData, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.financial.PerUnitHeldPaymentRecipientData.displayName = 'proto.financial.PerUnitHeldPaymentRecipientData';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.financial.PerUnitHeldHoldingEntry = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.financial.PerUnitHeldHoldingEntry, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.financial.PerUnitHeldHoldingEntry.displayName = 'proto.financial.PerUnitHeldHoldingEntry';
}

/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.financial.PerUnitHeldPaymentRecipientData.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.financial.PerUnitHeldPaymentRecipientData.prototype.toObject = function(opt_includeInstance) {
  return proto.financial.PerUnitHeldPaymentRecipientData.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.financial.PerUnitHeldPaymentRecipientData} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.financial.PerUnitHeldPaymentRecipientData.toObject = function(includeInstance, msg) {
  var f, obj = {
entriesList: jspb.Message.toObjectList(msg.getEntriesList(),
    proto.financial.PerUnitHeldHoldingEntry.toObject, includeInstance),
amountheld: (f = msg.getAmountheld()) && ledger_futureAmount_pb.FutureAmount.toObject(includeInstance, f),
unitamount: (f = msg.getUnitamount()) && ledger_futureAmount_pb.FutureAmount.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.financial.PerUnitHeldPaymentRecipientData}
 */
proto.financial.PerUnitHeldPaymentRecipientData.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.financial.PerUnitHeldPaymentRecipientData;
  return proto.financial.PerUnitHeldPaymentRecipientData.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.financial.PerUnitHeldPaymentRecipientData} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.financial.PerUnitHeldPaymentRecipientData}
 */
proto.financial.PerUnitHeldPaymentRecipientData.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.financial.PerUnitHeldHoldingEntry;
      reader.readMessage(value,proto.financial.PerUnitHeldHoldingEntry.deserializeBinaryFromReader);
      msg.addEntries(value);
      break;
    case 2:
      var value = new ledger_futureAmount_pb.FutureAmount;
      reader.readMessage(value,ledger_futureAmount_pb.FutureAmount.deserializeBinaryFromReader);
      msg.setAmountheld(value);
      break;
    case 3:
      var value = new ledger_futureAmount_pb.FutureAmount;
      reader.readMessage(value,ledger_futureAmount_pb.FutureAmount.deserializeBinaryFromReader);
      msg.setUnitamount(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.financial.PerUnitHeldPaymentRecipientData.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.financial.PerUnitHeldPaymentRecipientData.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.financial.PerUnitHeldPaymentRecipientData} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.financial.PerUnitHeldPaymentRecipientData.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getEntriesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.financial.PerUnitHeldHoldingEntry.serializeBinaryToWriter
    );
  }
  f = message.getAmountheld();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      ledger_futureAmount_pb.FutureAmount.serializeBinaryToWriter
    );
  }
  f = message.getUnitamount();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      ledger_futureAmount_pb.FutureAmount.serializeBinaryToWriter
    );
  }
};


/**
 * repeated PerUnitHeldHoldingEntry entries = 1;
 * @return {!Array<!proto.financial.PerUnitHeldHoldingEntry>}
 */
proto.financial.PerUnitHeldPaymentRecipientData.prototype.getEntriesList = function() {
  return /** @type{!Array<!proto.financial.PerUnitHeldHoldingEntry>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.financial.PerUnitHeldHoldingEntry, 1));
};


/**
 * @param {!Array<!proto.financial.PerUnitHeldHoldingEntry>} value
 * @return {!proto.financial.PerUnitHeldPaymentRecipientData} returns this
*/
proto.financial.PerUnitHeldPaymentRecipientData.prototype.setEntriesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.financial.PerUnitHeldHoldingEntry=} opt_value
 * @param {number=} opt_index
 * @return {!proto.financial.PerUnitHeldHoldingEntry}
 */
proto.financial.PerUnitHeldPaymentRecipientData.prototype.addEntries = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.financial.PerUnitHeldHoldingEntry, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.financial.PerUnitHeldPaymentRecipientData} returns this
 */
proto.financial.PerUnitHeldPaymentRecipientData.prototype.clearEntriesList = function() {
  return this.setEntriesList([]);
};


/**
 * optional ledger.FutureAmount amountHeld = 2;
 * @return {?proto.ledger.FutureAmount}
 */
proto.financial.PerUnitHeldPaymentRecipientData.prototype.getAmountheld = function() {
  return /** @type{?proto.ledger.FutureAmount} */ (
    jspb.Message.getWrapperField(this, ledger_futureAmount_pb.FutureAmount, 2));
};


/**
 * @param {?proto.ledger.FutureAmount|undefined} value
 * @return {!proto.financial.PerUnitHeldPaymentRecipientData} returns this
*/
proto.financial.PerUnitHeldPaymentRecipientData.prototype.setAmountheld = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.financial.PerUnitHeldPaymentRecipientData} returns this
 */
proto.financial.PerUnitHeldPaymentRecipientData.prototype.clearAmountheld = function() {
  return this.setAmountheld(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.financial.PerUnitHeldPaymentRecipientData.prototype.hasAmountheld = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional ledger.FutureAmount unitAmount = 3;
 * @return {?proto.ledger.FutureAmount}
 */
proto.financial.PerUnitHeldPaymentRecipientData.prototype.getUnitamount = function() {
  return /** @type{?proto.ledger.FutureAmount} */ (
    jspb.Message.getWrapperField(this, ledger_futureAmount_pb.FutureAmount, 3));
};


/**
 * @param {?proto.ledger.FutureAmount|undefined} value
 * @return {!proto.financial.PerUnitHeldPaymentRecipientData} returns this
*/
proto.financial.PerUnitHeldPaymentRecipientData.prototype.setUnitamount = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.financial.PerUnitHeldPaymentRecipientData} returns this
 */
proto.financial.PerUnitHeldPaymentRecipientData.prototype.clearUnitamount = function() {
  return this.setUnitamount(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.financial.PerUnitHeldPaymentRecipientData.prototype.hasUnitamount = function() {
  return jspb.Message.getField(this, 3) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.financial.PerUnitHeldHoldingEntry.prototype.toObject = function(opt_includeInstance) {
  return proto.financial.PerUnitHeldHoldingEntry.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.financial.PerUnitHeldHoldingEntry} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.financial.PerUnitHeldHoldingEntry.toObject = function(includeInstance, msg) {
  var f, obj = {
ownershiprecordid: jspb.Message.getFieldWithDefault(msg, 1, ""),
ledgeraccountid: jspb.Message.getFieldWithDefault(msg, 2, ""),
accountlabel: jspb.Message.getFieldWithDefault(msg, 3, 0),
amountheld: (f = msg.getAmountheld()) && ledger_futureAmount_pb.FutureAmount.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.financial.PerUnitHeldHoldingEntry}
 */
proto.financial.PerUnitHeldHoldingEntry.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.financial.PerUnitHeldHoldingEntry;
  return proto.financial.PerUnitHeldHoldingEntry.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.financial.PerUnitHeldHoldingEntry} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.financial.PerUnitHeldHoldingEntry}
 */
proto.financial.PerUnitHeldHoldingEntry.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setOwnershiprecordid(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setLedgeraccountid(value);
      break;
    case 3:
      var value = /** @type {!proto.financial.HoldingEntryAccountLabel} */ (reader.readEnum());
      msg.setAccountlabel(value);
      break;
    case 4:
      var value = new ledger_futureAmount_pb.FutureAmount;
      reader.readMessage(value,ledger_futureAmount_pb.FutureAmount.deserializeBinaryFromReader);
      msg.setAmountheld(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.financial.PerUnitHeldHoldingEntry.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.financial.PerUnitHeldHoldingEntry.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.financial.PerUnitHeldHoldingEntry} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.financial.PerUnitHeldHoldingEntry.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getOwnershiprecordid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getLedgeraccountid();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getAccountlabel();
  if (f !== 0.0) {
    writer.writeEnum(
      3,
      f
    );
  }
  f = message.getAmountheld();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      ledger_futureAmount_pb.FutureAmount.serializeBinaryToWriter
    );
  }
};


/**
 * optional string ownershipRecordID = 1;
 * @return {string}
 */
proto.financial.PerUnitHeldHoldingEntry.prototype.getOwnershiprecordid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.financial.PerUnitHeldHoldingEntry} returns this
 */
proto.financial.PerUnitHeldHoldingEntry.prototype.setOwnershiprecordid = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string ledgerAccountID = 2;
 * @return {string}
 */
proto.financial.PerUnitHeldHoldingEntry.prototype.getLedgeraccountid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.financial.PerUnitHeldHoldingEntry} returns this
 */
proto.financial.PerUnitHeldHoldingEntry.prototype.setLedgeraccountid = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional HoldingEntryAccountLabel accountLabel = 3;
 * @return {!proto.financial.HoldingEntryAccountLabel}
 */
proto.financial.PerUnitHeldHoldingEntry.prototype.getAccountlabel = function() {
  return /** @type {!proto.financial.HoldingEntryAccountLabel} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {!proto.financial.HoldingEntryAccountLabel} value
 * @return {!proto.financial.PerUnitHeldHoldingEntry} returns this
 */
proto.financial.PerUnitHeldHoldingEntry.prototype.setAccountlabel = function(value) {
  return jspb.Message.setProto3EnumField(this, 3, value);
};


/**
 * optional ledger.FutureAmount amountHeld = 4;
 * @return {?proto.ledger.FutureAmount}
 */
proto.financial.PerUnitHeldHoldingEntry.prototype.getAmountheld = function() {
  return /** @type{?proto.ledger.FutureAmount} */ (
    jspb.Message.getWrapperField(this, ledger_futureAmount_pb.FutureAmount, 4));
};


/**
 * @param {?proto.ledger.FutureAmount|undefined} value
 * @return {!proto.financial.PerUnitHeldHoldingEntry} returns this
*/
proto.financial.PerUnitHeldHoldingEntry.prototype.setAmountheld = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.financial.PerUnitHeldHoldingEntry} returns this
 */
proto.financial.PerUnitHeldHoldingEntry.prototype.clearAmountheld = function() {
  return this.setAmountheld(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.financial.PerUnitHeldHoldingEntry.prototype.hasAmountheld = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * @enum {number}
 */
proto.financial.HoldingEntryAccountLabel = {
  UNDEFINED_ACCOUNT_HOLDING_ENTRY_ACCOUNT_LABEL: 0,
  BENEFICIAL_OWNER_ACCOUNT_HOLDING_ENTRY_ACCOUNT_LABEL: 1,
  DIRECT_ORDER_HOLDING_ACCOUNT_HOLDING_ENTRY_ACCOUNT_LABEL: 2
};

goog.object.extend(exports, proto.financial);

import {
  Button,
  IconButton,
  Typography,
  Box,
  alpha,
  Tooltip,
} from "@mui/material";
import {
  Refresh as ReloadIcon,
  FaceOutlined as FaceIcon,
} from "@mui/icons-material";
import { useApplicationContext } from "context/Application/Application";
import React, { useEffect, useRef, useState } from "react";
import {
  ReadManyModelRequest,
  ReadManyModelResponse,
} from "@mesh/common-js/dist/views/financialPaymentFundingView/modelReader_meshproto_pb";
import { Model as FinancialPaymentFundingViewModel } from "@mesh/common-js/dist/views/financialPaymentFundingView/model_pb";
import { useAPIContext } from "context/API";
import { useIsMounted } from "hooks";
import { Query } from "@mesh/common-js/dist/search/query_pb";
import { Query as PastQuery } from "james/search/query";
import { BPTable } from "components/Table";
import { protobufTimestampToDayjs } from "@mesh/common-js/dist/googleProtobufConverters";
import { Timestamp } from "google-protobuf/google/protobuf/timestamp_pb";
import { DateTimeFormatWithOffset } from "const/dateformats";
import { enqueueSnackbar } from "notistack";
import { FullUpdateRequest } from "@mesh/common-js/dist/views/financialPaymentFundingView/modelUpdater_pb";
import { Sorting, SortingOrder } from "@mesh/common-js/dist/search/sorting_pb";
import { Amount } from "components/Ledger/Amount";
import { Amount as LedgerAmount } from "james/ledger";
import { TransactionStateChip } from "components/Ledger/Transaction/Chips";
import { Model as FinancialPaymentViewModel } from "@mesh/common-js/dist/views/financialPaymentView/model_pb";
import { Criterion } from "@mesh/common-js/dist/search/criterion_pb";
import { newTextExactCriterion } from "@mesh/common-js/dist/search";
import { ConfirmationModal } from "./ConfirmationModal";
import { TransactionState } from "@mesh/common-js/dist/ledger/transaction_pb";

export type TableProps = {
  system: boolean;
  height: number;
  payment?: FinancialPaymentViewModel;
};

export const Table = (props: TableProps) => {
  const { viewConfiguration } = useApplicationContext();
  const isMounted = useIsMounted();
  const { authContext } = useApplicationContext();
  const {
    views: {
      financialPaymentFundingViewModelReader,
      financialPaymentFundingViewModelUNSCOPEDReader,
      financialPaymentFundingViewModelUpdater,
    },
  } = useAPIContext();
  const [readRequest, setReadRequest] = useState<ReadManyModelRequest>(
    (() => {
      const criteriaList: Criterion[] = [];
      if (props.payment) {
        criteriaList.push(
          newTextExactCriterion("paymentid", props.payment.getPaymentid()),
        );
      }
      return new ReadManyModelRequest()
        .setContext(authContext.toFuture())
        .setCriteriaList(criteriaList)
        .setQuery(
          new Query()
            .setOffset(0)
            .setLimit(15)
            .setSortingList([
              new Sorting()
                .setField("date")
                .setOrder(SortingOrder.ASC_SORTING_ORDER),
            ]),
        );
    })(),
  );
  const updateReadRequest = (newreadRequest: ReadManyModelRequest) =>
    setReadRequest(
      new ReadManyModelRequest()
        .setContext(newreadRequest.getContext())
        .setCriteriaList(newreadRequest.getCriteriaList())
        .setQuery(newreadRequest.getQuery()),
    );
  const [readResponse, setReadResponse] = useState<ReadManyModelResponse>(
    new ReadManyModelResponse(),
  );
  const [loading, setLoading] = useState(false);
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const [fundingComplete, setFundingComplete] = useState(false);
  const timeoutRef = useRef<NodeJS.Timeout | undefined>(undefined);
  useEffect(() => {
    setLoading(true);
    clearTimeout(timeoutRef.current);
    timeoutRef.current = setTimeout(async () => {
      try {
        let response: ReadManyModelResponse;
        if (props.system) {
          response =
            await financialPaymentFundingViewModelUNSCOPEDReader.readManyModelUNSCOPED(
              readRequest,
            );
        } else {
          response =
            await financialPaymentFundingViewModelReader.readManyModel(
              readRequest,
            );
        }
        if (isMounted()) {
          setReadResponse(response);
          if (response.getRecordsList().length === 0) {
            setFundingComplete(false);
          }
          let total = LedgerAmount.fromFutureAmount(
            props.payment?.getAmount(),
          ).value;
          response.getRecordsList().forEach((record) => {
            const paymentAmount = LedgerAmount.fromFutureAmount(
              record.getAmount(),
            );
            if (
              record.getFundingtransactionstate() ===
              TransactionState.SUCCESSFUL_TRANSACTION_STATE
            ) {
              total = total.minus(paymentAmount.value);
            }
          });
          // If the total is negative the payment has been overfunded
          // Stellar would automatically refund the issuer account as the overfunding would be caught in the holding account
          // This is a safety check to ensure the payment is not overfunded on purpose
          // When the system works as expected, the total should be always be 0 after funding.
          if (total.isZero() || total.isNegative()) {
            setFundingComplete(true);
          }
        }
      } catch (e) {
        console.error("error reading smart instrument", e);
      }
      setLoading(false);
    }, 400);
  }, [readRequest, isMounted]);
  const [apiLoading, setAPILoading] = useState(false);

  return (
    <>
      <BPTable
        loading={loading || apiLoading}
        height={props.height}
        noDataSplashComponent={noDataSplashComponent()}
        disableSelect
        title={"Fundings"}
        query={PastQuery.fromFutureQuery(readRequest.getQuery())}
        onQueryChange={(query) =>
          updateReadRequest(readRequest.setQuery(query.toFutureQuery()))
        }
        data={readResponse.getRecordsList()}
        totalNoRecords={readResponse.getTotal()}
        toolBarControls={(() => {
          const controls: React.ReactNode[] = [];

          if (viewConfiguration["Payments"]?.Fund && props.payment) {
            controls.push(
              <Tooltip
                title={
                  props.payment.getPaymenttriggerfixed()
                    ? ""
                    : "Cannot fund until payment trigger is fixed"
                }
                placement="top"
              >
                <span>
                  <Button
                    disabled={
                      !props.payment.getPaymenttriggerfixed() || fundingComplete
                    }
                    onClick={() => {
                      setShowConfirmationModal(true);
                    }}
                    color="primary"
                    variant="contained"
                  >
                    Fund
                  </Button>
                </span>
              </Tooltip>,
            );
          }

          if (viewConfiguration["PaymentFundings"]?.ViewModelFullUpdate) {
            controls.push(
              <Button
                onClick={async () => {
                  setAPILoading(true);
                  try {
                    await financialPaymentFundingViewModelUpdater.fullUpdate(
                      new FullUpdateRequest().setContext(
                        authContext.toFuture(),
                      ),
                    );
                  } catch (e) {
                    console.error(`error performing full update: ${e}`);
                    enqueueSnackbar(`error performing full update: ${e}`);
                  }
                  setAPILoading(false);
                }}
                variant="outlined"
              >
                Full Update
              </Button>,
            );
          }

          controls.push(
            <IconButton
              id={"paymentfundingTable-reload-iconButton"}
              size={"small"}
              disabled={loading}
              onClick={() =>
                setReadRequest(
                  new ReadManyModelRequest()
                    .setContext(readRequest.getContext())
                    .setCriteriaList(readRequest.getCriteriaList())
                    .setQuery(readRequest.getQuery()),
                )
              }
            >
              <ReloadIcon />
            </IconButton>,
          );

          return controls;
        })()}
        columns={[
          {
            label: "Date",
            field: "date",
            accessor: (data) => {
              const model = data as FinancialPaymentFundingViewModel;
              return (
                <Typography
                  // id={`paymentfundingTable-spotNumber-text-${model.number}`}
                  color={"inherit"}
                  variant={"body1"}
                >
                  {protobufTimestampToDayjs(model.getDate() ?? new Timestamp())
                    //   .tz(timezoneToString(model.getTimezone()))
                    .format(DateTimeFormatWithOffset)}
                </Typography>
              );
            },
          },
          {
            label: "Amount",
            field: "amount.value.float",
            accessor: (data) => {
              const model = data as FinancialPaymentFundingViewModel;
              const amount = model.getAmount();
              return (
                <Amount
                  amount={
                    amount
                      ? LedgerAmount.fromFutureAmount(amount)
                      : new LedgerAmount()
                  }
                />
              );
            },
          },
          {
            label: "State",
            field: "state",
            accessor: (data) => {
              const model = data as FinancialPaymentFundingViewModel;
              return (
                <Typography color={"inherit"} variant={"body1"}>
                  <TransactionStateChip
                    state={model.getFundingtransactionstate()}
                  />
                </Typography>
              );
            },
          },
        ]}
      />
      {props.payment && (
        <ConfirmationModal
          open={showConfirmationModal}
          setOpen={setShowConfirmationModal}
          payment={props.payment}
        />
      )}
    </>
  );
};

const noDataSplashComponent = () => {
  return (
    <Box
      sx={{
        height: "100%",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <Box
        sx={(theme) => ({
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          gap: theme.spacing(0.5),
        })}
      >
        <FaceIcon
          sx={(theme) => ({
            fontSize: 110,
            color: alpha(theme.palette.background.default, 0.5),
          })}
        />
        <Typography
          color="secondary"
          variant="h4"
          children="Nothing to see here"
        />
        <Typography
          variant="body2"
          children={
            <span>
              You will see <i>Fundings</i> here once they have been submitted.
            </span>
          }
        />
      </Box>
    </Box>
  );
};

// @generated by protoc-gen-meshjsreact v1
// @generated from file views/stellarRegisteredLiquidityPoolView/modelReader.proto (package stellarRegisteredLiquidityPoolView, syntax proto3)
/* eslint-disable */

import { ReadManyModelRequest, ReadManyModelResponse } from "@mesh/common-js/dist/views/stellarRegisteredLiquidityPoolView/modelReader_pb";
import "@mesh/common-js/dist/security/context_pb";
import { useEffect, useRef, useState } from "react";
import 'grpc-web';
/**
* Custom React hook that performs a Model read operation.
* Manages loading, error handling, and response state with debounce.
*
* @function useReadManyModel
* @param {Reader | ReaderUNSCOPED} readManyModelFunc - The `ReadManyModel` method for reading Model data from the service provider.
* @param {Object} options - Configuration options for the hook.
* @param {ContextFuture} [options.authContext] - Authorization context to attach to the request.
* @param {boolean} [options.shouldNotExecute=false] - If true, prevents the hook from automatically executing the read request.
* @returns {Object} - Contains request, response, loading status, error state, and a setter for updating the read request.
*
* @property {ReadManyModelRequest} readManyModelRequest - The current Model request object.
* @property {ReadManyModelResponse} readManyModelResponse - The latest response received from the Model read operation.
* @property {string | undefined} readManyModelError - Error message in case the request fails.
* @property {function} setReadModelRequest - Function to update the read request with new criteria or context.
*
* @throws {TypeError} Throws error if neither `initialRequest` nor `authContext` are set in the request setup.
*
* @example
* const {
*   readManyModelRequest,
*   readManyModelResponse,
*   readManyModelError,
*   setReadModelRequest,
*   readManyModelLoading
* } = useReadManyModel(modelReader.ReadMany, { authContext });
*/
export function useReadManyModel(reader, { initialRequest, authContext, shouldNotExecute, }) {
    const [readManyRequest, setReadManyRequest] = useState((() => {
        if (initialRequest) {
            return initialRequest;
        }
        if (authContext) {
            return new ReadManyModelRequest().setContext(authContext);
        }
        throw new TypeError("neither initalRequest nor authContext are set");
    })());
    const [readManyResponse, setReadManyResponse] = useState(new ReadManyModelResponse());
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(undefined);
    const timeoutRef = useRef(undefined);
    useEffect(() => {
        // Do not execute until required
        if (shouldNotExecute) {
            return;
        }
        // start loading and clear previous timeout
        setLoading(true);
        clearTimeout(timeoutRef.current);
        // start new request
        timeoutRef.current = setTimeout(async () => {
            setError(undefined);
            try {
                setReadManyResponse(await ("readManyModel" in reader ? reader.readManyModel(readManyRequest) : reader.readManyModelUNSCOPED(readManyRequest)));
            }
            catch (e) {
                console.error(`error reading many model ${e}`);
                setError(`${e}`);
            }
            setLoading(false);
        }, 400);
    }, [readManyRequest, shouldNotExecute]);
    return {
        readManyModelRequest: readManyRequest,
        readManyModelResponse: readManyResponse,
        readManyModelError: error,
        setReadModelRequest: (readManyModelRequest) => {
            setReadManyRequest(new ReadManyModelRequest().
                setContext(readManyModelRequest.getContext()).
                setCriteriaList(readManyModelRequest.getCriteriaList()).
                setQuery(readManyModelRequest.getQuery()));
        },
        readManyModelLoading: loading,
    };
}

import React, { useEffect, useState } from "react";
import { DirectOrderType } from "james/market/DirectOrder";
import { MarketListingViewModel } from "james/views/marketListingView";
import { IndicativePrice, Listing, QuoteParameter } from "james/market";
import { Model as LedgerTokenViewModel } from "james/views/ledgerTokenView";
import { HeaderProps } from "views/Marketplace/components/PlaceOrderDialog/components/Header/Header";
import { SellCard } from "./SellCard";
import { BuyCard } from "./BuyCard";
import { Model as StellarAccountViewModel } from "@mesh/common-js/dist/views/stellarAccountView/model_pb";
import { Header } from "../Header";
import { useIsMounted } from "hooks";
import { LedgerAccountCategory } from "james/ledger";
import { LoadingDialogContent } from "../LoadingDialogContent";
import { useAccountContext } from "context/Account/Account";
import { useSnackbar } from "notistack";
import { useApplicationContext } from "context/Application/Application";
import { useErrorContext } from "context/Error";

export interface InvestorDirectOrderWrapperCardProps extends HeaderProps {
  listing: Listing;
  directOrderType: DirectOrderType;
  marketListingViewModel: MarketListingViewModel;
  indicativePrice: IndicativePrice;
  assetIssuanceTokenViewModel: LedgerTokenViewModel;
  assetValuationTokenViewModel: LedgerTokenViewModel;
  marketMechanismQuoteParameter: QuoteParameter;
}

export interface InvestorDirectOrderCardProps
  extends InvestorDirectOrderWrapperCardProps {
  potentialTradingAccounts: StellarAccountViewModel[];
}

export function InvestorDirectOrderCard(
  props: InvestorDirectOrderWrapperCardProps,
) {
  const isMounted = useIsMounted();
  const { stellarAccountContext } = useAccountContext();
  const { myClient, myClientRetrievalErr } = useApplicationContext();
  const { errorContextDefaultErrorFeedback } = useErrorContext();
  const { enqueueSnackbar } = useSnackbar();
  const [potentialTradingAccounts, setPotentialTradingAccounts] = useState<
    StellarAccountViewModel[]
  >([]);

  // fetch potential trading accounts on load
  useEffect(() => {
    // wait for the account context data to load before proceeding
    if (stellarAccountContext.loading) {
      return;
    }

    // if there was an error retrieving the accounts from the stellarContext
    // show an snackbar
    if (stellarAccountContext.error) {
      console.error(`Error Retrieving Account: ${stellarAccountContext.error}`);
      enqueueSnackbar(
        `Error Retrieving Account: ${stellarAccountContext.error}`,
        { variant: "error" },
      );

      if (isMounted()) {
        props.closeDialog();
      }
    }

    if (myClientRetrievalErr && !myClient) {
      errorContextDefaultErrorFeedback(myClientRetrievalErr);
      if (isMounted()) {
        props.closeDialog();
      }
      return;
    }

    if (!myClient) {
      return;
    }

    const retrievedPotentialTradingAccounts =
      stellarAccountContext.accounts.filter(
        (val) => val.getLabel() === LedgerAccountCategory.Trading,
      );

    if (isMounted()) {
      setPotentialTradingAccounts(retrievedPotentialTradingAccounts);
    }
  }, [stellarAccountContext.loading, stellarAccountContext.error]);

  if (!potentialTradingAccounts.length) {
    return (
      <>
        <Header {...props} />
        <LoadingDialogContent />
      </>
    );
  }

  return props.directOrderType === DirectOrderType.Sell ? (
    <SellCard
      {...{
        ...props,
        potentialTradingAccounts,
      }}
    />
  ) : (
    <BuyCard
      {...{
        ...props,
        potentialTradingAccounts,
      }}
    />
  );
}

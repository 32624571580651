import React, { useCallback, useState } from "react";
import { CompanyOnboardingRequestCard } from "./components/CompanyOnboardingRequestCard/CompanyOnboardingRequestCard";
import { Box, Theme, useMediaQuery } from "@mui/material";
import meshMiniLogo from "assets/images/logo/meshLogo.svg";
import { ContactUs } from "./components/ContactUs/ContactUs";
import { LoadingScreen } from "./components/LoadingScreen/LoadingScreen";
import * as Sentry from "@sentry/react";
import { CompanyOnboardingRequestMailer } from "james/onboarding/CompanyOnboardingRequestMailer";
import { useSnackbar } from "notistack";
import background from "assets/images/background/background.png";
import { useErrorContext } from "context/Error";
import { useAppNoticeContext } from "context/AppNotice/AppNotice";

export function CompanyOnboardingRequest() {
  const { errorContextErrorTranslator } = useErrorContext();
  const [displayContactUsCard, setContactUsCard] = useState(false);
  const [loading, setLoading] = useState(false);
  const smUp = useMediaQuery((theme: Theme) => theme.breakpoints.up("sm"));
  const { enqueueSnackbar } = useSnackbar();
  const { NotificationBannerHeight } = useAppNoticeContext();

  // on send request send new company onboarding request email
  const onSendRequest = useCallback(
    async (adminName: string, adminEmail: string, companyName: string) => {
      setLoading(true);
      try {
        // set sentry user
        Sentry.setUser({
          email: adminEmail,
          id: "",
          ip_address: "{{auto}}",
        });

        await CompanyOnboardingRequestMailer.SendCompanyOnboardingRequestMail({
          adminEmail,
          adminName,
          companyName,
        });

        setContactUsCard(true);
      } catch (e) {
        const err = errorContextErrorTranslator.translateError(e);
        Sentry.captureException(
          `error sending company client onboarding request email: ${
            err.message ? err.message : err.toString()
          }`,
        );
        console.error(
          `error sending company client onboarding request email: ${
            err.message ? err.message : err.toString()
          }`,
        );

        enqueueSnackbar(
          `error sending company client onboarding request email: ${
            err.message ? err.message : err.toString()
          }`,
          { variant: "error" },
        );
      }

      setLoading(false);
    },
    [],
  );

  // display the loading view
  if (loading) {
    return <LoadingScreen />;
  }

  // display mesh contact us card
  if (displayContactUsCard) {
    return <ContactUs />;
  }

  return (
    <>
      {smUp ? (
        <Box
          sx={{
            position: "relative",
            display: "flex",
            flexDirection: "column",
            backgroundImage: `url(${background})`,
            backgroundSize: "cover",
            height: `calc(100vh - ${NotificationBannerHeight}px)`,
            overflowY: {
              xs: "auto",
              md: "hidden",
            },
            width: "100%",
          }}
        >
          <Box
            sx={{
              width: 168,
              height: 77,
              position: "fixed",
            }}
          >
            <img alt="" src={meshMiniLogo} />
          </Box>
          <Box
            className="meshScroll"
            sx={{
              height: "100%",
              display: "grid",
              gridTemplateRows: `
                "a"
                "b"
                "c"
              `,
              justifyContent: "center",
              minWidth: "100vw",
              overflowY: "auto",
            }}
          >
            <Box
              sx={{
                gridArea: "b",
                pb: 2,
                pt: 4,
              }}
            >
              <CompanyOnboardingRequestCard onSendRequest={onSendRequest} />
            </Box>
          </Box>
        </Box>
      ) : (
        <CompanyOnboardingRequestCard onSendRequest={onSendRequest} />
      )}
    </>
  );
}

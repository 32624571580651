// Code generated by protoc-gen-meshproto DO NOT EDIT.
// source: message 'RateSource' in 'financial/rateSource.proto'

// @generated by protoc-gen-meshjsreact v1
// @generated from file financial/rateSourceReader_meshproto.proto (package financial, syntax proto3)
/* eslint-disable */

import { ReadManyRateSourceRequest, ReadManyRateSourceResponse } from "@mesh/common-js/dist/financial/rateSourceReader_meshproto_pb";
import "@mesh/common-js/dist/security/context_pb";
import { useEffect, useRef, useState } from "react";
import 'grpc-web';
/**
* Custom React hook that performs a RateSource read operation.
* Manages loading, error handling, and response state with debounce.
*
* @function useReadManyRateSource
* @param {Reader | ReaderUNSCOPED} readManyRateSourceFunc - The `ReadManyRateSource` method for reading RateSource data from the service provider.
* @param {Object} options - Configuration options for the hook.
* @param {ContextFuture} [options.authContext] - Authorization context to attach to the request.
* @param {boolean} [options.shouldNotExecute=false] - If true, prevents the hook from automatically executing the read request.
* @returns {Object} - Contains request, response, loading status, error state, and a setter for updating the read request.
*
* @property {ReadManyRateSourceRequest} readManyRateSourceRequest - The current RateSource request object.
* @property {ReadManyRateSourceResponse} readManyRateSourceResponse - The latest response received from the RateSource read operation.
* @property {string | undefined} readManyRateSourceError - Error message in case the request fails.
* @property {function} setReadRateSourceRequest - Function to update the read request with new criteria or context.
*
* @throws {TypeError} Throws error if neither `initialRequest` nor `authContext` are set in the request setup.
*
* @example
* const {
*   readManyRateSourceRequest,
*   readManyRateSourceResponse,
*   readManyRateSourceError,
*   setReadRateSourceRequest,
*   readManyRateSourceLoading
* } = useReadManyRateSource(rateSourceReader.ReadMany, { authContext });
*/
export function useReadManyRateSource(reader, { initialRequest, authContext, shouldNotExecute, }) {
    const [readManyRequest, setReadManyRequest] = useState((() => {
        if (initialRequest) {
            return initialRequest;
        }
        if (authContext) {
            return new ReadManyRateSourceRequest().setContext(authContext);
        }
        throw new TypeError("neither initalRequest nor authContext are set");
    })());
    const [readManyResponse, setReadManyResponse] = useState(new ReadManyRateSourceResponse());
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(undefined);
    const timeoutRef = useRef(undefined);
    useEffect(() => {
        // Do not execute until required
        if (shouldNotExecute) {
            return;
        }
        // start loading and clear previous timeout
        setLoading(true);
        clearTimeout(timeoutRef.current);
        // start new request
        timeoutRef.current = setTimeout(async () => {
            setError(undefined);
            try {
                setReadManyResponse(await ("readManyRateSource" in reader ? reader.readManyRateSource(readManyRequest) : reader.readManyRateSourceUNSCOPED(readManyRequest)));
            }
            catch (e) {
                console.error(`error reading many rateSource ${e}`);
                setError(`${e}`);
            }
            setLoading(false);
        }, 400);
    }, [readManyRequest, shouldNotExecute]);
    return {
        readManyRateSourceRequest: readManyRequest,
        readManyRateSourceResponse: readManyResponse,
        readManyRateSourceError: error,
        setReadRateSourceRequest: (readManyRateSourceRequest) => {
            setReadManyRequest(new ReadManyRateSourceRequest().
                setContext(readManyRateSourceRequest.getContext()).
                setCriteriaList(readManyRateSourceRequest.getCriteriaList()).
                setQuery(readManyRateSourceRequest.getQuery()));
        },
        readManyRateSourceLoading: loading,
    };
}

import React, { useContext, useMemo } from "react";
import { FeeScheduleCreatorPromiseClient } from "@mesh/common-js/dist/remuneration/feeScheduleCreator_grpc_web_pb";
import { FeeScheduleUpdaterPromiseClient } from "@mesh/common-js/dist/remuneration/feeScheduleUpdater_grpc_web_pb";
import { FeeScheduleReaderPromiseClient } from "@mesh/common-js/dist/remuneration/feeScheduleReader_meshproto_grpc_web_pb";
import { InstrumentMarketingContentReaderPromiseClient } from "@mesh/common-js/dist/marketing/instrumentMarketingContentReader_meshproto_grpc_web_pb";
import { InstrumentMarketingContentReaderUNSCOPEDPromiseClient } from "@mesh/common-js/dist/marketing/instrumentMarketingContentReaderUNSCOPED_meshproto_grpc_web_pb";
import { InstrumentMarketingContentUpdaterPromiseClient } from "@mesh/common-js/dist/marketing/instrumentMarketingContentUpdater_grpc_web_pb";
import { InstrumentMarketingContentCreatorPromiseClient } from "@mesh/common-js/dist/marketing/instrumentMarketingContentCreator_grpc_web_pb";
import { ModelReaderPromiseClient as MarketingInstrumentMarketingContentViewModelReaderPromiseClient } from "@mesh/common-js/dist/views/marketingInstrumentMarketingContentView/modelReader_meshproto_grpc_web_pb";
import { ModelReaderUNSCOPEDPromiseClient as MarketingInstrumentMarketingContentViewModelReaderUNSCOPEDPromiseClient } from "@mesh/common-js/dist/views/marketingInstrumentMarketingContentView/modelReaderUNSCOPED_meshproto_grpc_web_pb";
import { ModelUpdaterPromiseClient as MarketingInstrumentMarketingContentViewModelUpdaterPromiseClient } from "@mesh/common-js/dist/views/marketingInstrumentMarketingContentView/modelUpdater_grpc_web_pb";
import { SmartInstrumentRateResetGeneratorPromiseClient } from "@mesh/common-js/dist/financial/smartInstrumentRateResetGenerator_grpc_web_pb";
import { SmartInstrumentCalculatorPromiseClient } from "@mesh/common-js/dist/financial/smartInstrumentCalculator_grpc_web_pb";
import { SmartInstrumentStateControllerPromiseClient } from "@mesh/common-js/dist/financial/smartInstrumentStateController_grpc_web_pb";
import { SmartInstrumentUpdaterPromiseClient } from "@mesh/common-js/dist/financial/smartInstrumentUpdater_grpc_web_pb";
import { SmartInstrumentReaderPromiseClient } from "@mesh/common-js/dist/financial/smartInstrumentReader_meshproto_grpc_web_pb";
import { RateSourceRecorderPromiseClient } from "@mesh/common-js/dist/financial/rateSourceRecorder_grpc_web_pb";
import { RateSourceReaderPromiseClient } from "@mesh/common-js/dist/financial/rateSourceReader_meshproto_grpc_web_pb";
import { RateSourceReaderUNSCOPEDPromiseClient } from "@mesh/common-js/dist/financial/rateSourceReaderUNSCOPED_meshproto_grpc_web_pb";
import { RateSourceRecordingReaderPromiseClient } from "@mesh/common-js/dist/financial/rateSourceRecordingReader_meshproto_grpc_web_pb";
import { RateSourceCreatorPromiseClient } from "@mesh/common-js/dist/financial/rateSourceCreator_grpc_web_pb";
import { SmartInstrumentReaderUNSCOPEDPromiseClient } from "@mesh/common-js/dist/financial/smartInstrumentReaderUNSCOPED_meshproto_grpc_web_pb";
import { SmartInstrumentOrchestratorPromiseClient } from "@mesh/common-js/dist/financial/smartInstrumentOrchestrator_grpc_web_pb";
import { RateResetReaderPromiseClient } from "@mesh/common-js/dist/financial/rateResetReader_meshproto_grpc_web_pb";
import { RateResetReaderUNSCOPEDPromiseClient } from "@mesh/common-js/dist/financial/rateResetReaderUNSCOPED_meshproto_grpc_web_pb";
import { OracleDataSynchroniserPromiseClient } from "@mesh/common-js/dist/financial/oracleDataSynchroniser_grpc_web_pb";
import { OracleDataSynchronisationOrchestratorPromiseClient } from "@mesh/common-js/dist/financial/oracleDataSynchronisationOrchestrator_grpc_web_pb";
import { AssetflowDeferralReaderPromiseClient } from "@mesh/common-js/dist/financial/assetflowDeferralReader_meshproto_grpc_web_pb";
import { AssetflowDeferralReaderUNSCOPEDPromiseClient } from "@mesh/common-js/dist/financial/assetflowDeferralReaderUNSCOPED_meshproto_grpc_web_pb";
import { AssetflowReaderPromiseClient } from "@mesh/common-js/dist/financial/assetflowReader_meshproto_grpc_web_pb";
import { AssetflowReaderUNSCOPEDPromiseClient } from "@mesh/common-js/dist/financial/assetflowReaderUNSCOPED_meshproto_grpc_web_pb";
import { ModelReaderPromiseClient as FinancialPaymentViewModelReaderPromiseClient } from "@mesh/common-js/dist/views/financialPaymentView/modelReader_meshproto_grpc_web_pb";
import { ModelReaderUNSCOPEDPromiseClient as FinancialPaymentViewModelReaderUNSCOPEDPromiseClient } from "@mesh/common-js/dist/views/financialPaymentView/modelReaderUNSCOPED_meshproto_grpc_web_pb";
import { ModelUpdaterPromiseClient as FinancialPaymentViewModelUpdaterPromiseClient } from "@mesh/common-js/dist/views/financialPaymentView/modelUpdater_grpc_web_pb";
import { ModelReaderPromiseClient as FinancialPaymentFundingViewModelReaderPromiseClient } from "@mesh/common-js/dist/views/financialPaymentFundingView/modelReader_meshproto_grpc_web_pb";
import { ModelReaderUNSCOPEDPromiseClient as FinancialPaymentFundingViewModelReaderUNSCOPEDPromiseClient } from "@mesh/common-js/dist/views/financialPaymentFundingView/modelReaderUNSCOPED_meshproto_grpc_web_pb";
import { ModelUpdaterPromiseClient as FinancialPaymentFundingViewModelUpdaterPromiseClient } from "@mesh/common-js/dist/views/financialPaymentFundingView/modelUpdater_grpc_web_pb";
import { PublicSubscriptionCommitmentManagerPromiseClient } from "@mesh/common-js/dist/market/publicSubscriptionCommitmentManager_grpc_web_pb";
import { SubscriptionCommitmentManagerPromiseClient } from "@mesh/common-js/dist/market/subscriptionCommitmentManager_grpc_web_pb";
import { AssetHolderLookupReaderUNSCOPEDPromiseClient } from "@mesh/common-js/dist/financial/assetHolderLookupReaderUNSCOPED_meshproto_grpc_web_pb";
import { AssetHolderLookupReaderPromiseClient } from "@mesh/common-js/dist/financial/assetHolderLookupReader_meshproto_grpc_web_pb";
import { PaymentRecipientReaderUNSCOPEDPromiseClient } from "@mesh/common-js/dist/financial/paymentRecipientReaderUNSCOPED_meshproto_grpc_web_pb";
import { PaymentRecipientReaderPromiseClient } from "@mesh/common-js/dist/financial/paymentRecipientReader_meshproto_grpc_web_pb";
import { PaymentTriggerFetcherPromiseClient } from "@mesh/common-js/dist/financial/paymentTriggerFetcher_grpc_web_pb";
import { PaymentTriggerFetcherUNSCOPEDPromiseClient } from "@mesh/common-js/dist/financial/paymentTriggerFetcherUNSCOPED_grpc_web_pb";
import { AssetHolderLookupOrchestratorPromiseClient } from "@mesh/common-js/dist/financial/assetHolderLookupOrchestrator_grpc_web_pb";
import { SubscriptionOrderBookOrchestratorPromiseClient } from "@mesh/common-js/dist/market/subscriptionOrderBookOrchestrator_grpc_web_pb";
import { PaymentFunderPromiseClient } from "@mesh/common-js/dist/financial/paymentFunder_grpc_web_pb";
import { PaymentStateControllerPromiseClient } from "@mesh/common-js/dist/financial/paymentStateController_grpc_web_pb";
import { PaymentOrchestratorPromiseClient } from "@mesh/common-js/dist/financial/paymentOrchestrator_grpc_web_pb";
import config from "react-global-configuration";
import { LoggingInterceptor } from "./LoggingInterceptor";
import { FundingOrderManagerPromiseClient } from "@mesh/common-js/dist/banking/fundingOrderManager_grpc_web_pb";
import { FundingOrderStateControllerPromiseClient } from "@mesh/common-js/dist/banking/fundingOrderStateController_grpc_web_pb";
import { FundingOrderReaderUNSCOPEDPromiseClient } from "@mesh/common-js/dist/banking/fundingOrderReaderUNSCOPED_meshproto_grpc_web_pb";
import { SimulatedTimeInterceptor } from "./SimulatedTimeInterceptor";
import { useSimulatedTimeContext } from "context/Time/SimulatedTime";
import { LimitOrderStateControllerPromiseClient } from "@mesh/common-js/dist/market/limitOrderStateController_grpc_web_pb";
import { ModelReaderPromiseClient as MarketLimitOrderViewReaderPromiseClient } from "@mesh/common-js/dist/views/marketLimitOrderView/modelReader_meshproto_grpc_web_pb";
import { ModelReaderPromiseClient as StellarAccountViewModelReaderPromiseClient } from "@mesh/common-js/dist/views/stellarAccountView/modelReader_grpc_web_pb";
import { ModelReaderUNSCOPEDPromiseClient as StellarAccountViewModelReaderUNSCOPEDPromiseClient } from "@mesh/common-js/dist/views/stellarAccountView/modelReaderUNSCOPED_grpc_web_pb";
import { ModelReaderUNSCOPEDPromiseClient as MarketLimitOrderViewModelReaderUnscopedPromiseClient } from "@mesh/common-js/dist/views/marketLimitOrderView/modelReaderUNSCOPED_meshproto_grpc_web_pb";
import { ModelUpdaterPromiseClient as MarketLimitOrderViewModelUpdaterPromiseClient } from "@mesh/common-js/dist/views/marketLimitOrderView/modelUpdater_grpc_web_pb";
import { LimitOrderFeeCalculatorPromiseClient } from "@mesh/common-js/dist/market/limitOrderFeeCalculator_grpc_web_pb";
import { OffPlatformTransferRecipientStateControllerPromiseClient } from "@mesh/common-js/dist/market/offPlatformTransferRecipientStateController_grpc_web_pb";
import { OffPlatformTransferRecipientReaderPromiseClient } from "@mesh/common-js/dist/market/offPlatformTransferRecipientReader_meshproto_grpc_web_pb";
import { PersonReadServicePromiseClient } from "@mesh/common-js/dist/legal/personReadService_grpc_web_pb";
import { PersonWriteServicePromiseClient } from "@mesh/common-js/dist/legal/personWriteService_grpc_web_pb";
import { UserReadServicePromiseClient } from "@mesh/common-js/dist/iam/userReadService_grpc_web_pb";
import { UserWriteServicePromiseClient } from "@mesh/common-js/dist/iam/userWriteService_grpc_web_pb";
import { AccountInspectorPromiseClient } from "@mesh/common-js/dist/stellar/accountInspector_grpc_web_pb";
import { ClaimableBalanceArchiverPromiseClient } from "@mesh/common-js/dist/stellar/claimableBalanceArchiver_grpc_web_pb";
import { AccountOperatorPromiseClient } from "@mesh/common-js/dist/ledger/stellarops/accountOperator_grpc_web_pb";
import { TransactionFailureAnalyserPromiseClient } from "@mesh/common-js/dist/ledger/transactionFailureAnalyser_grpc_web_pb";
import { TransactionFetcherPromiseClient } from "@mesh/common-js/dist/ledger/transactionFetcher_grpc_web_pb";
import { LiquidityPoolInspectorPromiseClient } from "@mesh/common-js/dist/stellar/liquidityPoolInspector_grpc_web_pb";
import { TransactionReaderUNSCOPEDPromiseClient as StellarTransactionReaderPromiseClient } from "@mesh/common-js/dist/stellar/transactionReaderUNSCOPED_meshproto_grpc_web_pb";
import { TransactionSubmissionResolverPromiseClient } from "@mesh/common-js/dist/ledger/transactionSubmissionResolver_grpc_web_pb";
import { TransactionSigningResolverPromiseClient } from "@mesh/common-js/dist/ledger/transactionSigningResolver_grpc_web_pb";
import { AssetTransferrerPromiseClient } from "@mesh/common-js/dist/market/assetTransferrer_grpc_web_pb";
import { AssetTransferrerFeeCalculatorPromiseClient } from "@mesh/common-js/dist/market/assetTransferrerFeeCalculator_grpc_web_pb";
import { LiquidityPoolCalculatorPromiseClient } from "@mesh/common-js/dist/stellar/liquidityPoolCalculator_grpc_web_pb";
import { LiquidityPoolOperatorPromiseClient } from "@mesh/common-js/dist/ledger/stellarops/liquidityPoolOperator_grpc_web_pb";
import { SourceAccountStateControllerPromiseClient } from "@mesh/common-js/dist/ledger/stellarops/sourceAccountStateController_grpc_web_pb";
import { AccountInspectorPromiseClient as LedgerAccountInspectorPromiseClient } from "@mesh/common-js/dist/ledger/accountInspector_grpc_web_pb";
import { LiquidityPoolRegistrarPromiseClient } from "@mesh/common-js/dist/stellar/liquidityPoolRegistrar_grpc_web_pb";
import { LiquidityPoolRegistrationInspectorPromiseClient } from "@mesh/common-js/dist/stellar/liquidityPoolRegistrationInspector_grpc_web_pb";
import { ModelReaderPromiseClient as StellarSourceAccountViewModelReaderPromiseClient } from "@mesh/common-js/dist/views/stellarSourceAccountView/modelReader_grpc_web_pb";
import { ModelReaderPromiseClient as StellarRegisteredLiquidityPoolViewModelReaderPromiseClient } from "@mesh/common-js/dist/views/stellarRegisteredLiquidityPoolView/modelReader_grpc_web_pb";
import { TransactionInspectorPromiseClient } from "@mesh/common-js/dist/ledger/transactionInspector_grpc_web_pb";
import { TokenTapPromiseClient } from "@mesh/common-js/dist/stellar/tokenTap_grpc_web_pb";

export type API = {
  remuneration: {
    feeScheduleCreator: FeeScheduleCreatorPromiseClient;
    feeScheduleUpdater: FeeScheduleUpdaterPromiseClient;
    feeScheduleReader: FeeScheduleReaderPromiseClient;
  };
  financial: {
    // financial.SmartInstrument
    smartInstrumentCalculator: SmartInstrumentCalculatorPromiseClient;
    smartInstrumentStateController: SmartInstrumentStateControllerPromiseClient;
    smartInstrumentOrchestrator: SmartInstrumentOrchestratorPromiseClient;
    smartInstrumentUpdater: SmartInstrumentUpdaterPromiseClient;
    smartInstrumentReader: SmartInstrumentReaderPromiseClient;
    rateSourceRecorder: RateSourceRecorderPromiseClient;
    rateSourceReader: RateSourceReaderPromiseClient;
    rateSourceReaderUNSCOPED: RateSourceReaderUNSCOPEDPromiseClient;
    rateSourceRecordingReader: RateSourceRecordingReaderPromiseClient;
    rateSourceCreator: RateSourceCreatorPromiseClient;
    smartInstrumentReaderUNSCOPED: SmartInstrumentReaderUNSCOPEDPromiseClient;
    // financial.OracleData
    oracleDataSynchroniser: OracleDataSynchroniserPromiseClient;
    oracleDataSynchronisationOrchestrator: OracleDataSynchronisationOrchestratorPromiseClient;
    // financial.RateReset
    smartInstrumentRateResetGenerator: SmartInstrumentRateResetGeneratorPromiseClient;
    rateResetReader: RateResetReaderPromiseClient;
    rateResetReaderUNSCOPED: RateResetReaderUNSCOPEDPromiseClient;
    // financial.Assetflow
    assetflowReader: AssetflowReaderPromiseClient;
    assetflowReaderUNSCOPED: AssetflowReaderUNSCOPEDPromiseClient;
    // financial.AssetflowDeferral
    assetflowDeferralReader: AssetflowDeferralReaderPromiseClient;
    assetflowDeferralReaderUNSCOPED: AssetflowDeferralReaderUNSCOPEDPromiseClient;
    // financial.PaymentTrigger
    paymentTriggerFetcher: PaymentTriggerFetcherPromiseClient;
    paymentTriggerFetcherUNSCOPED: PaymentTriggerFetcherUNSCOPEDPromiseClient;
    // financial.Payment
    paymentFunder: PaymentFunderPromiseClient;
    paymentStateController: PaymentStateControllerPromiseClient;
    paymentOrchestrator: PaymentOrchestratorPromiseClient;
    // financial.PaymentRecipient
    paymentRecipientReader: PaymentRecipientReaderPromiseClient;
    paymentRecipientReaderUNSCOPED: PaymentRecipientReaderUNSCOPEDPromiseClient;
  };
  banking: {
    fundingOrderManager: FundingOrderManagerPromiseClient;
    fundingOrderReaderUNSCOPED: FundingOrderReaderUNSCOPEDPromiseClient;
    fundingOrderStateController: FundingOrderStateControllerPromiseClient;
  };
  market: {
    subscriptionOrderBookOrchestrator: SubscriptionOrderBookOrchestratorPromiseClient;
    subscriptionCommitmentManager: SubscriptionCommitmentManagerPromiseClient;
    publicSubscriptionCommitmentManager: PublicSubscriptionCommitmentManagerPromiseClient;
    limitOrderStateControllerPromiseClient: LimitOrderStateControllerPromiseClient;
    limitOrderFeeCalculator: LimitOrderFeeCalculatorPromiseClient;
    offPlatformTransferRecipientStateController: OffPlatformTransferRecipientStateControllerPromiseClient;
    offPlatformTransferRecipientReader: OffPlatformTransferRecipientReaderPromiseClient;
    assetTransferrerFeeCalculator: AssetTransferrerFeeCalculatorPromiseClient;
    assetTransferrer: AssetTransferrerPromiseClient;
  };
  marketing: {
    instrumentMarketingContentReader: InstrumentMarketingContentReaderPromiseClient;
    instrumentMarketingContentReaderUNSCOPED: InstrumentMarketingContentReaderUNSCOPEDPromiseClient;
    instrumentMarketingContentUpdater: InstrumentMarketingContentUpdaterPromiseClient;
    instrumentMarketingContentCreator: InstrumentMarketingContentCreatorPromiseClient;
  };
  ledger: {
    transactionInspector: TransactionInspectorPromiseClient;
    transactionFetcher: TransactionFetcherPromiseClient;
    accountInspector: LedgerAccountInspectorPromiseClient;
    ledgerAssetHolderLookupReaderUNSCOPED: AssetHolderLookupReaderUNSCOPEDPromiseClient;
    ledgerAssetHolderLookupReader: AssetHolderLookupReaderPromiseClient;
    ledgerAssetHolderLookupOrchestrator: AssetHolderLookupOrchestratorPromiseClient;
    ledgerTransactionSubmissionResolver: TransactionSubmissionResolverPromiseClient;
    ledgerTransactionSigningResolver: TransactionSigningResolverPromiseClient;
    transactionFailureAnalyser: TransactionFailureAnalyserPromiseClient;
    stellarops: {
      stellarOpsAccountOperator: AccountOperatorPromiseClient;
      stellarOpsLiquidityPoolOperator: LiquidityPoolOperatorPromiseClient;
      stellarOpsSourceAccountStateController: SourceAccountStateControllerPromiseClient;
    };
  };
  stellar: {
    accountInspector: AccountInspectorPromiseClient;
    claimableBalanceArchiver: ClaimableBalanceArchiverPromiseClient;
    liquidityPoolInspector: LiquidityPoolInspectorPromiseClient;
    liquidityPoolCalculator: LiquidityPoolCalculatorPromiseClient;
    liquidityPoolRegistrar: LiquidityPoolRegistrarPromiseClient;
    liquidityPoolRegistrationInspector: LiquidityPoolRegistrationInspectorPromiseClient;
    transactionReader: StellarTransactionReaderPromiseClient;
    tokenTap: TokenTapPromiseClient;
  };
  person: {
    readService: PersonReadServicePromiseClient;
    writeService: PersonWriteServicePromiseClient;
  };
  user: {
    readService: UserReadServicePromiseClient;
    writeService: UserWriteServicePromiseClient;
  };
  views: {
    financialPaymentViewModelReader: FinancialPaymentViewModelReaderPromiseClient;
    financialPaymentViewModelUNSCOPEDReader: FinancialPaymentViewModelReaderUNSCOPEDPromiseClient;
    financialPaymentViewModelUpdater: FinancialPaymentViewModelUpdaterPromiseClient;
    financialPaymentFundingViewModelReader: FinancialPaymentFundingViewModelReaderPromiseClient;
    financialPaymentFundingViewModelUNSCOPEDReader: FinancialPaymentFundingViewModelReaderUNSCOPEDPromiseClient;
    financialPaymentFundingViewModelUpdater: FinancialPaymentFundingViewModelUpdaterPromiseClient;
    marketLimitOrderViewModelReader: MarketLimitOrderViewReaderPromiseClient;
    marketLimitOrderViewModelUnscopedReader: MarketLimitOrderViewModelReaderUnscopedPromiseClient;
    marketLimitOrderViewModelUpdater: MarketLimitOrderViewModelUpdaterPromiseClient;
    marketingInstrumentMarketingContentViewModelReader: MarketingInstrumentMarketingContentViewModelReaderPromiseClient;
    marketingInstrumentMarketingContentViewModelUNSCOPEDReader: MarketingInstrumentMarketingContentViewModelReaderUNSCOPEDPromiseClient;
    marketingInstrumentMarketingContentViewModelUpdater: MarketingInstrumentMarketingContentViewModelUpdaterPromiseClient;
    stellarAccountViewModelReader: StellarAccountViewModelReaderPromiseClient;
    stellarAccountViewModelReaderUNSCOPED: StellarAccountViewModelReaderUNSCOPEDPromiseClient;
    stellarSourceAccountViewModelReader: StellarSourceAccountViewModelReaderPromiseClient;
    stellarRegisteredLiquidityPoolViewModelReader: StellarRegisteredLiquidityPoolViewModelReaderPromiseClient;
  };
};

const defaultContext: API = {
  remuneration: {
    feeScheduleCreator: new FeeScheduleCreatorPromiseClient(""),
    feeScheduleUpdater: new FeeScheduleUpdaterPromiseClient(""),
    feeScheduleReader: new FeeScheduleReaderPromiseClient(""),
  },
  financial: {
    // financial.instrument
    smartInstrumentCalculator: new SmartInstrumentCalculatorPromiseClient(""),
    smartInstrumentStateController:
      new SmartInstrumentStateControllerPromiseClient(""),
    smartInstrumentOrchestrator: new SmartInstrumentOrchestratorPromiseClient(
      "",
    ),
    smartInstrumentUpdater: new SmartInstrumentUpdaterPromiseClient(""),
    smartInstrumentReader: new SmartInstrumentReaderPromiseClient(""),
    rateSourceRecorder: new RateSourceRecorderPromiseClient(""),
    rateSourceReader: new RateSourceReaderPromiseClient(""),
    rateSourceReaderUNSCOPED: new RateSourceReaderUNSCOPEDPromiseClient(""),
    rateSourceRecordingReader: new RateSourceRecordingReaderPromiseClient(""),
    rateSourceCreator: new RateSourceCreatorPromiseClient(""),
    smartInstrumentReaderUNSCOPED:
      new SmartInstrumentReaderUNSCOPEDPromiseClient(""),
    // financial.OracleData
    oracleDataSynchroniser: new OracleDataSynchroniserPromiseClient(""),
    oracleDataSynchronisationOrchestrator:
      new OracleDataSynchronisationOrchestratorPromiseClient(""),
    // financial.RateReset
    smartInstrumentRateResetGenerator:
      new SmartInstrumentRateResetGeneratorPromiseClient(""),
    rateResetReader: new RateResetReaderPromiseClient(""),
    rateResetReaderUNSCOPED: new RateResetReaderUNSCOPEDPromiseClient(""),
    // financial.Assetflow
    assetflowReader: new AssetflowReaderPromiseClient(""),
    assetflowReaderUNSCOPED: new AssetflowReaderUNSCOPEDPromiseClient(""),
    // financial.AssetflowDeferral
    assetflowDeferralReader: new AssetflowDeferralReaderPromiseClient(""),
    assetflowDeferralReaderUNSCOPED:
      new AssetflowDeferralReaderUNSCOPEDPromiseClient(""),
    // financial.PaymentTrigger
    paymentTriggerFetcher: new PaymentTriggerFetcherPromiseClient(""),
    paymentTriggerFetcherUNSCOPED:
      new PaymentTriggerFetcherUNSCOPEDPromiseClient(""),
    // financial.Payment
    paymentFunder: new PaymentFunderPromiseClient(""),
    paymentStateController: new PaymentStateControllerPromiseClient(""),
    paymentOrchestrator: new PaymentOrchestratorPromiseClient(""),
    // financial.PaymentRecipient
    paymentRecipientReader: new PaymentRecipientReaderPromiseClient(""),
    paymentRecipientReaderUNSCOPED:
      new PaymentRecipientReaderUNSCOPEDPromiseClient(""),
  },
  banking: {
    fundingOrderManager: new FundingOrderManagerPromiseClient(""),
    fundingOrderReaderUNSCOPED: new FundingOrderReaderUNSCOPEDPromiseClient(""),
    fundingOrderStateController: new FundingOrderStateControllerPromiseClient(
      "",
    ),
  },
  market: {
    subscriptionOrderBookOrchestrator:
      new SubscriptionOrderBookOrchestratorPromiseClient(""),
    subscriptionCommitmentManager:
      new SubscriptionCommitmentManagerPromiseClient(""),
    publicSubscriptionCommitmentManager:
      new PublicSubscriptionCommitmentManagerPromiseClient(""),
    limitOrderStateControllerPromiseClient:
      new LimitOrderStateControllerPromiseClient(""),
    limitOrderFeeCalculator: new LimitOrderFeeCalculatorPromiseClient(""),
    offPlatformTransferRecipientStateController:
      new OffPlatformTransferRecipientStateControllerPromiseClient(""),
    offPlatformTransferRecipientReader:
      new OffPlatformTransferRecipientReaderPromiseClient(""),
    assetTransferrerFeeCalculator:
      new AssetTransferrerFeeCalculatorPromiseClient(""),
    assetTransferrer: new AssetTransferrerPromiseClient(""),
  },
  marketing: {
    instrumentMarketingContentReader:
      new InstrumentMarketingContentReaderPromiseClient(""),
    instrumentMarketingContentReaderUNSCOPED:
      new InstrumentMarketingContentReaderUNSCOPEDPromiseClient(""),
    instrumentMarketingContentUpdater:
      new InstrumentMarketingContentUpdaterPromiseClient(""),
    instrumentMarketingContentCreator:
      new InstrumentMarketingContentCreatorPromiseClient(""),
  },
  ledger: {
    transactionInspector: new TransactionInspectorPromiseClient(""),
    accountInspector: new LedgerAccountInspectorPromiseClient(""),
    transactionFetcher: new TransactionFetcherPromiseClient(""),
    ledgerAssetHolderLookupReader: new AssetHolderLookupReaderPromiseClient(""),
    ledgerAssetHolderLookupReaderUNSCOPED:
      new AssetHolderLookupReaderUNSCOPEDPromiseClient(""),
    ledgerAssetHolderLookupOrchestrator:
      new AssetHolderLookupOrchestratorPromiseClient(""),
    ledgerTransactionSubmissionResolver:
      new TransactionSubmissionResolverPromiseClient(""),
    ledgerTransactionSigningResolver:
      new TransactionSigningResolverPromiseClient(""),
    transactionFailureAnalyser: new TransactionFailureAnalyserPromiseClient(""),
    stellarops: {
      stellarOpsAccountOperator: new AccountOperatorPromiseClient(""),
      stellarOpsLiquidityPoolOperator: new LiquidityPoolOperatorPromiseClient(
        "",
      ),
      stellarOpsSourceAccountStateController:
        new SourceAccountStateControllerPromiseClient(""),
    },
  },
  stellar: {
    accountInspector: new AccountInspectorPromiseClient(""),
    claimableBalanceArchiver: new ClaimableBalanceArchiverPromiseClient(""),
    liquidityPoolInspector: new LiquidityPoolInspectorPromiseClient(""),
    liquidityPoolCalculator: new LiquidityPoolCalculatorPromiseClient(""),
    liquidityPoolRegistrar: new LiquidityPoolRegistrarPromiseClient(""),
    liquidityPoolRegistrationInspector:
      new LiquidityPoolRegistrationInspectorPromiseClient(""),
    transactionReader: new StellarTransactionReaderPromiseClient(""),
    tokenTap: new TokenTapPromiseClient(""),
  },
  person: {
    readService: new PersonReadServicePromiseClient(""),
    writeService: new PersonWriteServicePromiseClient(""),
  },
  user: {
    readService: new UserReadServicePromiseClient(""),
    writeService: new UserWriteServicePromiseClient(""),
  },
  views: {
    financialPaymentViewModelReader:
      new FinancialPaymentViewModelReaderPromiseClient(""),
    financialPaymentViewModelUNSCOPEDReader:
      new FinancialPaymentViewModelReaderUNSCOPEDPromiseClient(""),
    financialPaymentViewModelUpdater:
      new FinancialPaymentViewModelUpdaterPromiseClient(""),
    financialPaymentFundingViewModelReader:
      new FinancialPaymentFundingViewModelReaderPromiseClient(""),
    financialPaymentFundingViewModelUNSCOPEDReader:
      new FinancialPaymentFundingViewModelReaderUNSCOPEDPromiseClient(""),
    financialPaymentFundingViewModelUpdater:
      new FinancialPaymentFundingViewModelUpdaterPromiseClient(""),
    marketLimitOrderViewModelReader:
      new MarketLimitOrderViewReaderPromiseClient(""),
    marketLimitOrderViewModelUnscopedReader:
      new MarketLimitOrderViewModelReaderUnscopedPromiseClient(""),
    marketLimitOrderViewModelUpdater:
      new MarketLimitOrderViewModelUpdaterPromiseClient(""),
    marketingInstrumentMarketingContentViewModelReader:
      new MarketingInstrumentMarketingContentViewModelReaderPromiseClient(""),
    marketingInstrumentMarketingContentViewModelUNSCOPEDReader:
      new MarketingInstrumentMarketingContentViewModelReaderUNSCOPEDPromiseClient(
        "",
      ),
    marketingInstrumentMarketingContentViewModelUpdater:
      new MarketingInstrumentMarketingContentViewModelUpdaterPromiseClient(""),
    stellarAccountViewModelReader:
      new StellarAccountViewModelReaderPromiseClient(""),
    stellarAccountViewModelReaderUNSCOPED:
      new StellarAccountViewModelReaderUNSCOPEDPromiseClient(""),
    stellarSourceAccountViewModelReader:
      new StellarSourceAccountViewModelReaderPromiseClient(""),
    stellarRegisteredLiquidityPoolViewModelReader:
      new StellarRegisteredLiquidityPoolViewModelReaderPromiseClient(""),
  },
};

type ServiceProviderPromiseClientConstructor<T> = new (
  hostname: string,
  credentials?:
    | {
        [index: string]: string;
      }
    | null
    | undefined,
  options?:
    | {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        [index: string]: any;
      }
    | null
    | undefined,
) => T;

export const APIContext = React.createContext<API>(defaultContext);

export const APIProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const { simulatedTimeEnabled, currentTime } = useSimulatedTimeContext();
  const loggingInterceptor = useMemo<LoggingInterceptor>(
    () => new LoggingInterceptor(),
    [],
  );
  const simulatedTimeInterceptor = useMemo<SimulatedTimeInterceptor>(() => {
    return new SimulatedTimeInterceptor(simulatedTimeEnabled, currentTime);
  }, [simulatedTimeEnabled, currentTime]);
  const apiURL = config.get("apiGatewayURL");

  const serviceProviderConstructor: <T>(
    serviceProviderConstructor: ServiceProviderPromiseClientConstructor<T>,
  ) => T = (serviceProviderConstructor) => {
    return new serviceProviderConstructor(apiURL, null, {
      withCredentials: true,
      unaryInterceptors: [loggingInterceptor, simulatedTimeInterceptor],
    });
  };

  const api: API = useMemo<API>(
    () => ({
      remuneration: {
        feeScheduleCreator: serviceProviderConstructor(
          FeeScheduleCreatorPromiseClient,
        ),
        feeScheduleUpdater: serviceProviderConstructor(
          FeeScheduleUpdaterPromiseClient,
        ),
        feeScheduleReader: serviceProviderConstructor(
          FeeScheduleReaderPromiseClient,
        ),
      },
      financial: {
        // financial.SmartInstrument
        smartInstrumentCalculator: serviceProviderConstructor(
          SmartInstrumentCalculatorPromiseClient,
        ),
        smartInstrumentStateController: serviceProviderConstructor(
          SmartInstrumentStateControllerPromiseClient,
        ),
        smartInstrumentOrchestrator: serviceProviderConstructor(
          SmartInstrumentOrchestratorPromiseClient,
        ),
        smartInstrumentUpdater: serviceProviderConstructor(
          SmartInstrumentUpdaterPromiseClient,
        ),
        smartInstrumentReader: serviceProviderConstructor(
          SmartInstrumentReaderPromiseClient,
        ),
        smartInstrumentReaderUNSCOPED: serviceProviderConstructor(
          SmartInstrumentReaderUNSCOPEDPromiseClient,
        ),
        // financial.OracleData
        oracleDataSynchroniser: serviceProviderConstructor(
          OracleDataSynchroniserPromiseClient,
        ),
        oracleDataSynchronisationOrchestrator: serviceProviderConstructor(
          OracleDataSynchronisationOrchestratorPromiseClient,
        ),
        // financial.RateReset
        smartInstrumentRateResetGenerator: serviceProviderConstructor(
          SmartInstrumentRateResetGeneratorPromiseClient,
        ),
        rateResetReader: serviceProviderConstructor(
          RateResetReaderPromiseClient,
        ),
        rateResetReaderUNSCOPED: serviceProviderConstructor(
          RateResetReaderUNSCOPEDPromiseClient,
        ),
        // financial.Assetflow
        assetflowReader: serviceProviderConstructor(
          AssetflowReaderPromiseClient,
        ),
        assetflowReaderUNSCOPED: serviceProviderConstructor(
          AssetflowReaderUNSCOPEDPromiseClient,
        ),
        // financial.AssetflowDeferral
        assetflowDeferralReader: serviceProviderConstructor(
          AssetflowDeferralReaderPromiseClient,
        ),
        assetflowDeferralReaderUNSCOPED: serviceProviderConstructor(
          AssetflowDeferralReaderUNSCOPEDPromiseClient,
        ),
        // financial.PaymentTrigger
        paymentTriggerFetcher: serviceProviderConstructor(
          PaymentTriggerFetcherPromiseClient,
        ),
        paymentTriggerFetcherUNSCOPED: serviceProviderConstructor(
          PaymentTriggerFetcherUNSCOPEDPromiseClient,
        ),
        // financial.Payment
        paymentFunder: serviceProviderConstructor(PaymentFunderPromiseClient),
        paymentStateController: serviceProviderConstructor(
          PaymentStateControllerPromiseClient,
        ),
        paymentOrchestrator: serviceProviderConstructor(
          PaymentOrchestratorPromiseClient,
        ),
        // financial.PaymentRecipient
        paymentRecipientReader: serviceProviderConstructor(
          PaymentRecipientReaderPromiseClient,
        ),
        paymentRecipientReaderUNSCOPED: serviceProviderConstructor(
          PaymentRecipientReaderUNSCOPEDPromiseClient,
        ),
        rateSourceRecorder: serviceProviderConstructor(
          RateSourceRecorderPromiseClient,
        ),
        rateSourceReader: serviceProviderConstructor(
          RateSourceReaderPromiseClient,
        ),
        rateSourceReaderUNSCOPED: serviceProviderConstructor(
          RateSourceReaderUNSCOPEDPromiseClient,
        ),
        rateSourceRecordingReader: serviceProviderConstructor(
          RateSourceRecordingReaderPromiseClient,
        ),
        rateSourceCreator: serviceProviderConstructor(
          RateSourceCreatorPromiseClient,
        ),
      },
      banking: {
        fundingOrderManager: serviceProviderConstructor(
          FundingOrderManagerPromiseClient,
        ),
        fundingOrderReaderUNSCOPED: serviceProviderConstructor(
          FundingOrderReaderUNSCOPEDPromiseClient,
        ),
        fundingOrderStateController: serviceProviderConstructor(
          FundingOrderStateControllerPromiseClient,
        ),
      },
      market: {
        subscriptionOrderBookOrchestrator: serviceProviderConstructor(
          SubscriptionOrderBookOrchestratorPromiseClient,
        ),
        subscriptionCommitmentManager: serviceProviderConstructor(
          SubscriptionCommitmentManagerPromiseClient,
        ),
        publicSubscriptionCommitmentManager: serviceProviderConstructor(
          PublicSubscriptionCommitmentManagerPromiseClient,
        ),
        limitOrderStateControllerPromiseClient: serviceProviderConstructor(
          LimitOrderStateControllerPromiseClient,
        ),
        limitOrderFeeCalculator: serviceProviderConstructor(
          LimitOrderFeeCalculatorPromiseClient,
        ),
        offPlatformTransferRecipientStateController: serviceProviderConstructor(
          OffPlatformTransferRecipientStateControllerPromiseClient,
        ),
        offPlatformTransferRecipientReader: serviceProviderConstructor(
          OffPlatformTransferRecipientReaderPromiseClient,
        ),
        assetTransferrerFeeCalculator: serviceProviderConstructor(
          AssetTransferrerFeeCalculatorPromiseClient,
        ),
        assetTransferrer: serviceProviderConstructor(
          AssetTransferrerPromiseClient,
        ),
      },
      marketing: {
        instrumentMarketingContentReader: serviceProviderConstructor(
          InstrumentMarketingContentReaderPromiseClient,
        ),
        instrumentMarketingContentReaderUNSCOPED: serviceProviderConstructor(
          InstrumentMarketingContentReaderUNSCOPEDPromiseClient,
        ),
        instrumentMarketingContentUpdater: serviceProviderConstructor(
          InstrumentMarketingContentUpdaterPromiseClient,
        ),
        instrumentMarketingContentCreator: serviceProviderConstructor(
          InstrumentMarketingContentCreatorPromiseClient,
        ),
      },
      ledger: {
        accountInspector: serviceProviderConstructor(
          LedgerAccountInspectorPromiseClient,
        ),
        transactionInspector: serviceProviderConstructor(
          TransactionInspectorPromiseClient,
        ),
        transactionFetcher: serviceProviderConstructor(
          TransactionFetcherPromiseClient,
        ),
        ledgerAssetHolderLookupReader: serviceProviderConstructor(
          AssetHolderLookupReaderPromiseClient,
        ),
        ledgerAssetHolderLookupReaderUNSCOPED: serviceProviderConstructor(
          AssetHolderLookupReaderUNSCOPEDPromiseClient,
        ),
        ledgerAssetHolderLookupOrchestrator: serviceProviderConstructor(
          AssetHolderLookupOrchestratorPromiseClient,
        ),
        ledgerTransactionSubmissionResolver: serviceProviderConstructor(
          TransactionSubmissionResolverPromiseClient,
        ),
        ledgerTransactionSigningResolver: serviceProviderConstructor(
          TransactionSigningResolverPromiseClient,
        ),
        transactionFailureAnalyser: serviceProviderConstructor(
          TransactionFailureAnalyserPromiseClient,
        ),
        stellarops: {
          stellarOpsAccountOperator: serviceProviderConstructor(
            AccountOperatorPromiseClient,
          ),
          stellarOpsLiquidityPoolOperator: serviceProviderConstructor(
            LiquidityPoolOperatorPromiseClient,
          ),
          stellarOpsSourceAccountStateController: serviceProviderConstructor(
            SourceAccountStateControllerPromiseClient,
          ),
        },
      },
      stellar: {
        accountInspector: serviceProviderConstructor(
          AccountInspectorPromiseClient,
        ),
        claimableBalanceArchiver: serviceProviderConstructor(
          ClaimableBalanceArchiverPromiseClient,
        ),
        liquidityPoolInspector: serviceProviderConstructor(
          LiquidityPoolInspectorPromiseClient,
        ),
        liquidityPoolCalculator: serviceProviderConstructor(
          LiquidityPoolCalculatorPromiseClient,
        ),
        liquidityPoolRegistrar: serviceProviderConstructor(
          LiquidityPoolRegistrarPromiseClient,
        ),
        liquidityPoolRegistrationInspector: serviceProviderConstructor(
          LiquidityPoolRegistrationInspectorPromiseClient,
        ),
        transactionReader: serviceProviderConstructor(
          StellarTransactionReaderPromiseClient,
        ),
        tokenTap: serviceProviderConstructor(TokenTapPromiseClient),
      },
      person: {
        readService: serviceProviderConstructor(PersonReadServicePromiseClient),
        writeService: serviceProviderConstructor(
          PersonWriteServicePromiseClient,
        ),
      },
      user: {
        readService: serviceProviderConstructor(UserReadServicePromiseClient),
        writeService: serviceProviderConstructor(UserWriteServicePromiseClient),
      },
      views: {
        financialPaymentViewModelReader: serviceProviderConstructor(
          FinancialPaymentViewModelReaderPromiseClient,
        ),
        financialPaymentViewModelUNSCOPEDReader: serviceProviderConstructor(
          FinancialPaymentViewModelReaderUNSCOPEDPromiseClient,
        ),
        financialPaymentViewModelUpdater: serviceProviderConstructor(
          FinancialPaymentViewModelUpdaterPromiseClient,
        ),
        financialPaymentFundingViewModelReader: serviceProviderConstructor(
          FinancialPaymentFundingViewModelReaderPromiseClient,
        ),
        financialPaymentFundingViewModelUNSCOPEDReader:
          serviceProviderConstructor(
            FinancialPaymentFundingViewModelReaderUNSCOPEDPromiseClient,
          ),
        financialPaymentFundingViewModelUpdater: serviceProviderConstructor(
          FinancialPaymentFundingViewModelUpdaterPromiseClient,
        ),
        marketLimitOrderViewModelReader: serviceProviderConstructor(
          MarketLimitOrderViewReaderPromiseClient,
        ),
        marketLimitOrderViewModelUnscopedReader: serviceProviderConstructor(
          MarketLimitOrderViewModelReaderUnscopedPromiseClient,
        ),
        marketLimitOrderViewModelUpdater: serviceProviderConstructor(
          MarketLimitOrderViewModelUpdaterPromiseClient,
        ),
        marketingInstrumentMarketingContentViewModelReader:
          serviceProviderConstructor(
            MarketingInstrumentMarketingContentViewModelReaderPromiseClient,
          ),
        marketingInstrumentMarketingContentViewModelUNSCOPEDReader:
          serviceProviderConstructor(
            MarketingInstrumentMarketingContentViewModelReaderUNSCOPEDPromiseClient,
          ),
        marketingInstrumentMarketingContentViewModelUpdater:
          serviceProviderConstructor(
            MarketingInstrumentMarketingContentViewModelUpdaterPromiseClient,
          ),
        stellarAccountViewModelReader: serviceProviderConstructor(
          StellarAccountViewModelReaderPromiseClient,
        ),
        stellarAccountViewModelReaderUNSCOPED: serviceProviderConstructor(
          StellarAccountViewModelReaderUNSCOPEDPromiseClient,
        ),
        stellarSourceAccountViewModelReader: serviceProviderConstructor(
          StellarSourceAccountViewModelReaderPromiseClient,
        ),
        stellarRegisteredLiquidityPoolViewModelReader:
          serviceProviderConstructor(
            StellarRegisteredLiquidityPoolViewModelReaderPromiseClient,
          ),
      },
    }),
    [apiURL, simulatedTimeEnabled, currentTime],
  );

  return <APIContext.Provider value={api}>{children}</APIContext.Provider>;
};

export const useAPIContext = () => useContext(APIContext);

// source: financial/smartInstrumentCalculator.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global =
    (typeof globalThis !== 'undefined' && globalThis) ||
    (typeof window !== 'undefined' && window) ||
    (typeof global !== 'undefined' && global) ||
    (typeof self !== 'undefined' && self) ||
    (function () { return this; }).call(null) ||
    Function('return this')();

var financial_smartInstrument_pb = require('../financial/smartInstrument_pb.js');
goog.object.extend(proto, financial_smartInstrument_pb);
var google_protobuf_timestamp_pb = require('google-protobuf/google/protobuf/timestamp_pb.js');
goog.object.extend(proto, google_protobuf_timestamp_pb);
var financial_rateReset_pb = require('../financial/rateReset_pb.js');
goog.object.extend(proto, financial_rateReset_pb);
var financial_assetflowDeferral_pb = require('../financial/assetflowDeferral_pb.js');
goog.object.extend(proto, financial_assetflowDeferral_pb);
var financial_assetflow_pb = require('../financial/assetflow_pb.js');
goog.object.extend(proto, financial_assetflow_pb);
var security_context_pb = require('../security/context_pb.js');
goog.object.extend(proto, security_context_pb);
goog.exportSymbol('proto.financial.CalculateSmartInstrumentAssetflowsRequest', null, global);
goog.exportSymbol('proto.financial.CalculateSmartInstrumentAssetflowsResponse', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.financial.CalculateSmartInstrumentAssetflowsRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.financial.CalculateSmartInstrumentAssetflowsRequest.repeatedFields_, null);
};
goog.inherits(proto.financial.CalculateSmartInstrumentAssetflowsRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.financial.CalculateSmartInstrumentAssetflowsRequest.displayName = 'proto.financial.CalculateSmartInstrumentAssetflowsRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.financial.CalculateSmartInstrumentAssetflowsResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.financial.CalculateSmartInstrumentAssetflowsResponse.repeatedFields_, null);
};
goog.inherits(proto.financial.CalculateSmartInstrumentAssetflowsResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.financial.CalculateSmartInstrumentAssetflowsResponse.displayName = 'proto.financial.CalculateSmartInstrumentAssetflowsResponse';
}

/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.financial.CalculateSmartInstrumentAssetflowsRequest.repeatedFields_ = [4,5];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.financial.CalculateSmartInstrumentAssetflowsRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.financial.CalculateSmartInstrumentAssetflowsRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.financial.CalculateSmartInstrumentAssetflowsRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.financial.CalculateSmartInstrumentAssetflowsRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
context: (f = msg.getContext()) && security_context_pb.ContextFuture.toObject(includeInstance, f),
smartinstrument: (f = msg.getSmartinstrument()) && financial_smartInstrument_pb.SmartInstrument.toObject(includeInstance, f),
evaluationdate: (f = msg.getEvaluationdate()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
rateresetsList: jspb.Message.toObjectList(msg.getRateresetsList(),
    financial_rateReset_pb.RateReset.toObject, includeInstance),
assetflowdeferralsList: jspb.Message.toObjectList(msg.getAssetflowdeferralsList(),
    financial_assetflowDeferral_pb.AssetflowDeferral.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.financial.CalculateSmartInstrumentAssetflowsRequest}
 */
proto.financial.CalculateSmartInstrumentAssetflowsRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.financial.CalculateSmartInstrumentAssetflowsRequest;
  return proto.financial.CalculateSmartInstrumentAssetflowsRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.financial.CalculateSmartInstrumentAssetflowsRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.financial.CalculateSmartInstrumentAssetflowsRequest}
 */
proto.financial.CalculateSmartInstrumentAssetflowsRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new security_context_pb.ContextFuture;
      reader.readMessage(value,security_context_pb.ContextFuture.deserializeBinaryFromReader);
      msg.setContext(value);
      break;
    case 2:
      var value = new financial_smartInstrument_pb.SmartInstrument;
      reader.readMessage(value,financial_smartInstrument_pb.SmartInstrument.deserializeBinaryFromReader);
      msg.setSmartinstrument(value);
      break;
    case 3:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setEvaluationdate(value);
      break;
    case 4:
      var value = new financial_rateReset_pb.RateReset;
      reader.readMessage(value,financial_rateReset_pb.RateReset.deserializeBinaryFromReader);
      msg.addRateresets(value);
      break;
    case 5:
      var value = new financial_assetflowDeferral_pb.AssetflowDeferral;
      reader.readMessage(value,financial_assetflowDeferral_pb.AssetflowDeferral.deserializeBinaryFromReader);
      msg.addAssetflowdeferrals(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.financial.CalculateSmartInstrumentAssetflowsRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.financial.CalculateSmartInstrumentAssetflowsRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.financial.CalculateSmartInstrumentAssetflowsRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.financial.CalculateSmartInstrumentAssetflowsRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getContext();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      security_context_pb.ContextFuture.serializeBinaryToWriter
    );
  }
  f = message.getSmartinstrument();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      financial_smartInstrument_pb.SmartInstrument.serializeBinaryToWriter
    );
  }
  f = message.getEvaluationdate();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getRateresetsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      4,
      f,
      financial_rateReset_pb.RateReset.serializeBinaryToWriter
    );
  }
  f = message.getAssetflowdeferralsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      5,
      f,
      financial_assetflowDeferral_pb.AssetflowDeferral.serializeBinaryToWriter
    );
  }
};


/**
 * optional security.ContextFuture context = 1;
 * @return {?proto.security.ContextFuture}
 */
proto.financial.CalculateSmartInstrumentAssetflowsRequest.prototype.getContext = function() {
  return /** @type{?proto.security.ContextFuture} */ (
    jspb.Message.getWrapperField(this, security_context_pb.ContextFuture, 1));
};


/**
 * @param {?proto.security.ContextFuture|undefined} value
 * @return {!proto.financial.CalculateSmartInstrumentAssetflowsRequest} returns this
*/
proto.financial.CalculateSmartInstrumentAssetflowsRequest.prototype.setContext = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.financial.CalculateSmartInstrumentAssetflowsRequest} returns this
 */
proto.financial.CalculateSmartInstrumentAssetflowsRequest.prototype.clearContext = function() {
  return this.setContext(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.financial.CalculateSmartInstrumentAssetflowsRequest.prototype.hasContext = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional SmartInstrument smartInstrument = 2;
 * @return {?proto.financial.SmartInstrument}
 */
proto.financial.CalculateSmartInstrumentAssetflowsRequest.prototype.getSmartinstrument = function() {
  return /** @type{?proto.financial.SmartInstrument} */ (
    jspb.Message.getWrapperField(this, financial_smartInstrument_pb.SmartInstrument, 2));
};


/**
 * @param {?proto.financial.SmartInstrument|undefined} value
 * @return {!proto.financial.CalculateSmartInstrumentAssetflowsRequest} returns this
*/
proto.financial.CalculateSmartInstrumentAssetflowsRequest.prototype.setSmartinstrument = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.financial.CalculateSmartInstrumentAssetflowsRequest} returns this
 */
proto.financial.CalculateSmartInstrumentAssetflowsRequest.prototype.clearSmartinstrument = function() {
  return this.setSmartinstrument(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.financial.CalculateSmartInstrumentAssetflowsRequest.prototype.hasSmartinstrument = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional google.protobuf.Timestamp evaluationDate = 3;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.financial.CalculateSmartInstrumentAssetflowsRequest.prototype.getEvaluationdate = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 3));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.financial.CalculateSmartInstrumentAssetflowsRequest} returns this
*/
proto.financial.CalculateSmartInstrumentAssetflowsRequest.prototype.setEvaluationdate = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.financial.CalculateSmartInstrumentAssetflowsRequest} returns this
 */
proto.financial.CalculateSmartInstrumentAssetflowsRequest.prototype.clearEvaluationdate = function() {
  return this.setEvaluationdate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.financial.CalculateSmartInstrumentAssetflowsRequest.prototype.hasEvaluationdate = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * repeated RateReset rateResets = 4;
 * @return {!Array<!proto.financial.RateReset>}
 */
proto.financial.CalculateSmartInstrumentAssetflowsRequest.prototype.getRateresetsList = function() {
  return /** @type{!Array<!proto.financial.RateReset>} */ (
    jspb.Message.getRepeatedWrapperField(this, financial_rateReset_pb.RateReset, 4));
};


/**
 * @param {!Array<!proto.financial.RateReset>} value
 * @return {!proto.financial.CalculateSmartInstrumentAssetflowsRequest} returns this
*/
proto.financial.CalculateSmartInstrumentAssetflowsRequest.prototype.setRateresetsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 4, value);
};


/**
 * @param {!proto.financial.RateReset=} opt_value
 * @param {number=} opt_index
 * @return {!proto.financial.RateReset}
 */
proto.financial.CalculateSmartInstrumentAssetflowsRequest.prototype.addRateresets = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 4, opt_value, proto.financial.RateReset, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.financial.CalculateSmartInstrumentAssetflowsRequest} returns this
 */
proto.financial.CalculateSmartInstrumentAssetflowsRequest.prototype.clearRateresetsList = function() {
  return this.setRateresetsList([]);
};


/**
 * repeated AssetflowDeferral assetflowDeferrals = 5;
 * @return {!Array<!proto.financial.AssetflowDeferral>}
 */
proto.financial.CalculateSmartInstrumentAssetflowsRequest.prototype.getAssetflowdeferralsList = function() {
  return /** @type{!Array<!proto.financial.AssetflowDeferral>} */ (
    jspb.Message.getRepeatedWrapperField(this, financial_assetflowDeferral_pb.AssetflowDeferral, 5));
};


/**
 * @param {!Array<!proto.financial.AssetflowDeferral>} value
 * @return {!proto.financial.CalculateSmartInstrumentAssetflowsRequest} returns this
*/
proto.financial.CalculateSmartInstrumentAssetflowsRequest.prototype.setAssetflowdeferralsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 5, value);
};


/**
 * @param {!proto.financial.AssetflowDeferral=} opt_value
 * @param {number=} opt_index
 * @return {!proto.financial.AssetflowDeferral}
 */
proto.financial.CalculateSmartInstrumentAssetflowsRequest.prototype.addAssetflowdeferrals = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 5, opt_value, proto.financial.AssetflowDeferral, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.financial.CalculateSmartInstrumentAssetflowsRequest} returns this
 */
proto.financial.CalculateSmartInstrumentAssetflowsRequest.prototype.clearAssetflowdeferralsList = function() {
  return this.setAssetflowdeferralsList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.financial.CalculateSmartInstrumentAssetflowsResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.financial.CalculateSmartInstrumentAssetflowsResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.financial.CalculateSmartInstrumentAssetflowsResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.financial.CalculateSmartInstrumentAssetflowsResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.financial.CalculateSmartInstrumentAssetflowsResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
assetflowsList: jspb.Message.toObjectList(msg.getAssetflowsList(),
    financial_assetflow_pb.Assetflow.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.financial.CalculateSmartInstrumentAssetflowsResponse}
 */
proto.financial.CalculateSmartInstrumentAssetflowsResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.financial.CalculateSmartInstrumentAssetflowsResponse;
  return proto.financial.CalculateSmartInstrumentAssetflowsResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.financial.CalculateSmartInstrumentAssetflowsResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.financial.CalculateSmartInstrumentAssetflowsResponse}
 */
proto.financial.CalculateSmartInstrumentAssetflowsResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new financial_assetflow_pb.Assetflow;
      reader.readMessage(value,financial_assetflow_pb.Assetflow.deserializeBinaryFromReader);
      msg.addAssetflows(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.financial.CalculateSmartInstrumentAssetflowsResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.financial.CalculateSmartInstrumentAssetflowsResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.financial.CalculateSmartInstrumentAssetflowsResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.financial.CalculateSmartInstrumentAssetflowsResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAssetflowsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      financial_assetflow_pb.Assetflow.serializeBinaryToWriter
    );
  }
};


/**
 * repeated Assetflow assetflows = 1;
 * @return {!Array<!proto.financial.Assetflow>}
 */
proto.financial.CalculateSmartInstrumentAssetflowsResponse.prototype.getAssetflowsList = function() {
  return /** @type{!Array<!proto.financial.Assetflow>} */ (
    jspb.Message.getRepeatedWrapperField(this, financial_assetflow_pb.Assetflow, 1));
};


/**
 * @param {!Array<!proto.financial.Assetflow>} value
 * @return {!proto.financial.CalculateSmartInstrumentAssetflowsResponse} returns this
*/
proto.financial.CalculateSmartInstrumentAssetflowsResponse.prototype.setAssetflowsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.financial.Assetflow=} opt_value
 * @param {number=} opt_index
 * @return {!proto.financial.Assetflow}
 */
proto.financial.CalculateSmartInstrumentAssetflowsResponse.prototype.addAssetflows = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.financial.Assetflow, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.financial.CalculateSmartInstrumentAssetflowsResponse} returns this
 */
proto.financial.CalculateSmartInstrumentAssetflowsResponse.prototype.clearAssetflowsList = function() {
  return this.setAssetflowsList([]);
};


goog.object.extend(exports, proto.financial);

import { useEffect, useRef, useState } from "react";
import { Button, IconButton, Typography, alpha } from "@mui/material";
import {
  Refresh as ReloadIcon,
  FaceOutlined as FaceIcon,
} from "@mui/icons-material";
import { Box } from "@mui/system";
import { useIsMounted } from "hooks";
import { BPTable } from "components/Table";
import { useAPIContext } from "context/API";
import { enqueueSnackbar } from "notistack";
import { useNavigate } from "react-router-dom";
import { ObjectiveChip } from "./ObjectiveChip";
import { Query as PastQuery } from "james/search/query";
import { Query } from "@mesh/common-js/dist/search/query_pb";
import { useAppNoticeContext } from "context/AppNotice/AppNotice";
import { useApplicationContext } from "context/Application/Application";
import { FullUpdateRequest } from "@mesh/common-js/dist/views/marketingInstrumentMarketingContentView/modelUpdater_pb";
import { Model as MarketingInstrumentMarketingContentViewModel } from "@mesh/common-js/dist/views/marketingInstrumentMarketingContentView/model_pb";
import {
  ReadManyModelRequest,
  ReadManyModelResponse,
} from "@mesh/common-js/dist/views/marketingInstrumentMarketingContentView/modelReader_meshproto_pb";

const initialQuery = new Query().setOffset(0).setLimit(15);

export type TableProps = {
  system: boolean;
};

export const Table = (props: TableProps) => {
  const navigate = useNavigate();
  const isMounted = useIsMounted();
  const { authContext } = useApplicationContext();
  const { viewConfiguration } = useApplicationContext();
  const { NotificationBannerHeight: noticeBannerHeight } =
    useAppNoticeContext();
  const [loading, setLoading] = useState(false);
  const [apiLoading, setAPILoading] = useState(false);
  const timeoutRef = useRef<NodeJS.Timeout | undefined>(undefined);
  const {
    views: {
      marketingInstrumentMarketingContentViewModelReader,
      marketingInstrumentMarketingContentViewModelUNSCOPEDReader,
      marketingInstrumentMarketingContentViewModelUpdater,
    },
  } = useAPIContext();
  const [readRequest, setReadRequest] = useState<ReadManyModelRequest>(
    new ReadManyModelRequest()
      .setContext(authContext.toFuture())
      .setQuery(initialQuery),
  );
  const [readResponse, setReadResponse] = useState<ReadManyModelResponse>(
    new ReadManyModelResponse(),
  );

  useEffect(() => {
    setLoading(true);
    clearTimeout(timeoutRef.current);
    timeoutRef.current = setTimeout(async () => {
      try {
        let response: ReadManyModelResponse;
        if (props.system) {
          response =
            await marketingInstrumentMarketingContentViewModelUNSCOPEDReader.readManyModelUNSCOPED(
              readRequest,
            );
        } else {
          response =
            await marketingInstrumentMarketingContentViewModelReader.readManyModel(
              readRequest,
            );
        }
        if (isMounted()) {
          setReadResponse(response);
        }
      } catch (e) {
        console.error(
          "error reading instrument marketing content view models",
          e,
        );
      }
      setLoading(false);
    }, 400);
  }, [readRequest, isMounted]);

  const [
    selectedInstrumentMarketingContent,
    setSelectedInstrumentMarketingContent,
  ] = useState<MarketingInstrumentMarketingContentViewModel | undefined>(
    undefined,
  );

  return (
    <>
      <BPTable
        loading={loading || apiLoading}
        height={window.innerHeight - 88 - noticeBannerHeight}
        singleSelect
        noDataSplashComponent={noDataSplashComponent()}
        onSingleSelectChange={(data) =>
          setSelectedInstrumentMarketingContent(
            data as MarketingInstrumentMarketingContentViewModel | undefined,
          )
        }
        title={"Instrument Marketing Content"}
        query={PastQuery.fromFutureQuery(readRequest.getQuery())}
        onQueryChange={(query) =>
          setReadRequest(readRequest.setQuery(query.toFutureQuery()))
        }
        data={readResponse.getRecordsList()}
        totalNoRecords={readResponse.getTotal()}
        toolBarControls={(() => {
          const controls: React.ReactNode[] = [];

          // FULL VIEW MODEL UPDATE
          if (viewConfiguration["Marketing"]?.ViewModelFullUpdate) {
            controls.push(
              <Button
                onClick={async () => {
                  setAPILoading(true);
                  try {
                    await marketingInstrumentMarketingContentViewModelUpdater.fullUpdate(
                      new FullUpdateRequest().setContext(
                        authContext.toFuture(),
                      ),
                    );
                  } catch (e) {
                    console.error(`error performing full update: ${e}`);
                    enqueueSnackbar(`error performing full update: ${e}`);
                  }
                  setReadRequest(
                    new ReadManyModelRequest()
                      .setContext(readRequest.getContext())
                      .setCriteriaList(readRequest.getCriteriaList())
                      .setQuery(readRequest.getQuery()),
                  );
                  setAPILoading(false);
                }}
                variant="outlined"
              >
                Full Update
              </Button>,
            );
          }

          if (selectedInstrumentMarketingContent) {
            // VIEW
            controls.push(
              <Button
                variant={"contained"}
                id={"instrumentMarketingContentTable-view-button"}
                children={"View"}
                color={"primary"}
                onClick={() => {
                  if (!selectedInstrumentMarketingContent) {
                    return;
                  }
                  const instrumentID =
                    selectedInstrumentMarketingContent.getInstrumentid();
                  if (!instrumentID) {
                    return;
                  }
                  const query = new URLSearchParams();
                  query.set("instrument-id", instrumentID);
                  navigate({
                    pathname: "/builder/marketing/content",
                    search: query.toString(),
                  });
                }}
              />,
            );
          }

          // RELOAD
          controls.push(
            <IconButton
              id={"instrumentMarketingContent-reload-iconButton"}
              size={"small"}
              disabled={loading}
              onClick={() =>
                setReadRequest(
                  new ReadManyModelRequest()
                    .setContext(readRequest.getContext())
                    .setCriteriaList(readRequest.getCriteriaList())
                    .setQuery(readRequest.getQuery()),
                )
              }
            >
              <ReloadIcon />
            </IconButton>,
          );

          return controls;
        })()}
        columns={[
          {
            label: "Instrument Name",
            field: "instrumentname",
            accessor: (data) => {
              const model =
                data as MarketingInstrumentMarketingContentViewModel;
              return (
                <Typography color={"inherit"} variant={"body1"}>
                  {model.getInstrumentname()}
                </Typography>
              );
            },
          },
          {
            label: "Objective",
            field: "investmentobjective",
            accessor: (data) => {
              const model =
                data as MarketingInstrumentMarketingContentViewModel;
              const hasInvestmentObjective =
                model.getInvestmentobjective() !== "";
              return <ObjectiveChip value={hasInvestmentObjective} />;
            },
          },
          {
            label: "Social Links",
            field: "socialLinks",
            accessor: (data) => {
              const model =
                data as MarketingInstrumentMarketingContentViewModel;
              const socialMediaLinks = model.getSocialmedialinks();
              const count = socialMediaLinks
                ? [
                    socialMediaLinks.getWeb(),
                    socialMediaLinks.getFacebook(),
                    socialMediaLinks.getLinkedin(),
                    socialMediaLinks.getYoutube(),
                    socialMediaLinks.getInstagram(),
                    socialMediaLinks.getTwitter(),
                  ].filter((link) => link !== "").length
                : 0;
              return (
                <Typography color={"inherit"} variant={"h6"}>
                  {count}
                </Typography>
              );
            },
          },
          {
            label: "Articles",
            field: "newsarticlesList",
            accessor: (data) => {
              const model =
                data as MarketingInstrumentMarketingContentViewModel;
              const count = model.getNewsarticlesList().length;
              return (
                <Typography color={"inherit"} variant={"h6"}>
                  {count}
                </Typography>
              );
            },
          },
          {
            label: "Media",
            field: "mediaentriesList",
            accessor: (data) => {
              const model =
                data as MarketingInstrumentMarketingContentViewModel;
              const count = model.getMediaentriesList().length;
              return (
                <Typography color={"inherit"} variant={"h6"}>
                  {count}
                </Typography>
              );
            },
          },
          {
            label: "Reviews",
            field: "independentreviewsList",
            accessor: (data) => {
              const model =
                data as MarketingInstrumentMarketingContentViewModel;
              const count = model.getIndependentreviewsList().length;
              return (
                <Typography color={"inherit"} variant={"h6"}>
                  {count}
                </Typography>
              );
            },
          },
        ]}
      />
    </>
  );
};

const noDataSplashComponent = () => {
  return (
    <Box
      sx={{
        height: "100%",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <Box
        sx={(theme) => ({
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          gap: theme.spacing(0.5),
        })}
      >
        <FaceIcon
          sx={(theme) => ({
            fontSize: 110,
            color: alpha(theme.palette.background.default, 0.5),
          })}
        />
        <Typography
          color="secondary"
          variant="h4"
          children="Nothing to see here"
        />
        <Typography
          variant="body2"
          children={
            <span>
              You will see <i>instrument marketing content</i> once it has been
              created.
            </span>
          }
        />
      </Box>
    </Box>
  );
};

import { TextField } from "@mesh/common-js-react/dist/FormFields";
import { InputAdornment, MenuItem, Tooltip, Typography } from "@mui/material";
import { Box } from "@mui/system";
import { ValidationResult } from "common/validation";
import { TextNumField } from "components/FormFields";
import { TokenIconViewUpload } from "components/Ledger/Token";
import { Token } from "james/ledger";
import { Mechanism, MechanismType } from "james/market";
import { Model as LedgerTokenViewModel } from "james/views/ledgerTokenView";
import { Info as InfoIcon } from "@mui/icons-material";
import React from "react";

export type DirectOrderMarketMechanismFormFormProps = {
  ledgerTokenViewModels: LedgerTokenViewModel[];
  marketMechanism: Mechanism;
  onUpdate: (updatedMarketMechanism: Mechanism) => void;
  disabled: boolean;
  readOnly: boolean;
  formDataValidationResult: ValidationResult;
};

export const DirectOrderMarketMechanismForm = (
  props: DirectOrderMarketMechanismFormFormProps,
) => {
  if (props.marketMechanism.type !== MechanismType.DirectOrder) {
    return "invalid market mechanism";
  }

  // direct order market mechanism assumed to have only 1 quote pair
  if (
    props.marketMechanism.quoteParameters.length === 0 ||
    props.marketMechanism.quoteParameters.length > 1
  ) {
    return "invalid market direct order mechanism";
  }

  const getFieldValidation = (field: string) => {
    return props.formDataValidationResult.fieldValidations[
      `${MechanismType.DirectOrder}-${field}`
    ];
  };

  // get quote parameter
  const quoteParameter = props.marketMechanism.quoteParameters[0];

  return (
    <Box
      sx={(theme) => ({
        display: "flex",
        flexDirection: "row",
        gap: theme.spacing(1),
        alignItems: "center",
      })}
    >
      <TextField
        id="marketListing-mechanisms-directOrderMechanism-quoteToken-selectField"
        disabled={props.disabled}
        readOnly={props.readOnly}
        fullWidth
        sx={{ maxWidth: 200 }}
        label="Quote Token"
        select
        value={
          props.ledgerTokenViewModels.find((cst) =>
            cst.token.isEqualTo(quoteParameter.quoteToken),
          )?.id ?? ""
        }
        onChange={(e) => {
          quoteParameter.quoteToken =
            props.ledgerTokenViewModels.find((cst) => cst.id === e.target.value)
              ?.token ?? new Token();
          props.marketMechanism.quoteParameters = [quoteParameter];
          props.onUpdate(props.marketMechanism);
        }}
        error={!!getFieldValidation("quoteToken")}
        helperText={getFieldValidation("quoteToken")}
      >
        {props.ledgerTokenViewModels.map((cst, idx) => (
          <MenuItem key={idx} value={cst.id}>
            <Box
              sx={(theme) => ({
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                gap: theme.spacing(1),
              })}
            >
              <TokenIconViewUpload size={23} token={cst.token} />
              <Typography children={cst.token.code} />
            </Box>
          </MenuItem>
        ))}
      </TextField>
      <TextNumField
        id="marketListing-mechanisms-directOrderMechanism-minimumDealSize-textNumField"
        readOnly={props.readOnly}
        disabled={props.disabled}
        fullWidth
        noDecimalPlaces={7}
        label={"Minimum Deal Size"}
        disallowNegative
        value={quoteParameter.minimumDealSize.value}
        sx={{ maxWidth: 320 }}
        onChange={(e) => {
          quoteParameter.minimumDealSize =
            quoteParameter.minimumDealSize.setValue(e.target.value);
          props.marketMechanism.quoteParameters = [quoteParameter];
          props.onUpdate(props.marketMechanism);
        }}
        InputProps={{
          startAdornment: (
            <InputAdornment position={"start"} sx={{ mb: 0.5 }}>
              {quoteParameter.minimumDealSize.token.code}
            </InputAdornment>
          ),
          endAdornment: (
            <InputAdornment
              position="end"
              children={
                <Tooltip
                  placement="top"
                  title={`Minimum amount of "${quoteParameter.minimumDealSize.token.code}" that can be bought or sold for "${quoteParameter.quoteToken.code}"`}
                >
                  <InfoIcon
                    sx={(theme) => ({
                      cursor: "pointer",
                      color: theme.palette.text.secondary,
                      width: "16px",
                      height: "16px",
                      mb: 0.5,
                    })}
                  />
                </Tooltip>
              }
            />
          ),
        }}
        error={!!getFieldValidation("minimumDealSize")}
        helperText={getFieldValidation("minimumDealSize")}
      />
      <TextNumField
        id="marketListing-mechanisms-directOrderMechanism-maximumDealSize-textNumField"
        readOnly={props.readOnly}
        disabled={props.disabled}
        fullWidth
        noDecimalPlaces={7}
        label={"Maximum Deal Size"}
        disallowNegative
        value={quoteParameter.maximumDealSize.value}
        sx={{ maxWidth: 320 }}
        onChange={(e) => {
          quoteParameter.maximumDealSize =
            quoteParameter.maximumDealSize.setValue(e.target.value);
          props.marketMechanism.quoteParameters = [quoteParameter];
          props.onUpdate(props.marketMechanism);
        }}
        InputProps={{
          startAdornment: (
            <InputAdornment position={"start"} sx={{ mb: 0.5 }}>
              {quoteParameter.maximumDealSize.token.code}
            </InputAdornment>
          ),
          endAdornment: (
            <InputAdornment
              position="end"
              children={
                <Tooltip
                  placement="top"
                  title={`Maximum amount of "${quoteParameter.maximumDealSize.token.code}" that can be bought or sold for "${quoteParameter.quoteToken.code}"`}
                >
                  <InfoIcon
                    sx={(theme) => ({
                      cursor: "pointer",
                      color: theme.palette.text.secondary,
                      width: "16px",
                      height: "16px",
                      mb: 0.5,
                    })}
                  />
                </Tooltip>
              }
            />
          ),
        }}
        error={!!getFieldValidation("maximumDealSize")}
        helperText={getFieldValidation("maximumDealSize")}
      />
    </Box>
  );
};

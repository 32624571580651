import { ValidationResult } from "common/validation";
import { Context } from "james/security";
import { Model as StellarAccountViewModel } from "@mesh/common-js/dist/views/stellarAccountView/model_pb";
import { TransferAccountType } from "./TransferMainDialog";
import { BigNumber } from "bignumber.js";
import { TokenCategory } from "james/views/ledgerTokenView/Model";
import { FutureAmount } from "@mesh/common-js/dist/ledger/futureAmount_pb";
import { AccountInspectorPromiseClient } from "@mesh/common-js/dist/stellar/accountInspector_grpc_web_pb";
import { CheckAccountExistsRequest } from "@mesh/common-js/dist/stellar/accountInspector_pb";
import { newTextExactCriterion } from "@mesh/common-js/dist/search";
import { futureAmountToStellarAmount } from "@mesh/common-js/dist/ledger";
import { decimalToBigNumber } from "@mesh/common-js/dist/num";
import { CalculateAssetTransferFeeResponse } from "@mesh/common-js/dist/market/assetTransferrerFeeCalculator_pb";

export type State = {
  accountInspector: AccountInspectorPromiseClient;
  userID: string;
  transferAmount: FutureAmount;
  accountType: string;
  accountID: string;
  reference: string;
  referenceCheckbox: boolean;
  transferTokenAvailableBalance: FutureAmount;
  mZARBalance: FutureAmount;
  transferFees: CalculateAssetTransferFeeResponse;
  ledgerAccountModel: StellarAccountViewModel;
  tokenCategory: TokenCategory | "";
};

export type FormUpdaterSpecsType = {
  userID: (value: string, prevState?: State) => State;
  accountID: (value: string, prevState?: State) => State;
  accountType: (value: string, prevState?: State) => State;
  transferTokenAvailableBalance: (
    value: FutureAmount,
    prevState?: State,
  ) => State;
  mZARBalance: (value: FutureAmount, prevState?: State) => State;
  reference: (value: string, prevState?: State) => State;
  referenceCheckbox: (value: boolean, prevState?: State) => State;
  transferAmount: (value: FutureAmount, prevState?: State) => State;
  transferFees: (
    value: CalculateAssetTransferFeeResponse,
    prevState?: State,
  ) => State;
  tokenCategory: (value: TokenCategory | "", prevState?: State) => State;
  ledgerAccountModel: (
    value: StellarAccountViewModel,
    prevState?: State,
  ) => State;
};

export const formUpdaterSpecs: FormUpdaterSpecsType = {
  userID(value: string, prevState: State | undefined): State {
    return {
      ...(prevState as State),
      userID: value,
    };
  },
  accountID(value: string, prevState: State | undefined): State {
    return {
      ...(prevState as State),
      accountID: value,
    };
  },
  accountType(value: string, prevState: State | undefined): State {
    return {
      ...(prevState as State),
      accountType: value,
    };
  },
  transferTokenAvailableBalance(
    value: FutureAmount,
    prevState: State | undefined,
  ): State {
    return {
      ...(prevState as State),
      transferTokenAvailableBalance: value,
    };
  },
  reference(value: string, prevState: State | undefined): State {
    return {
      ...(prevState as State),
      reference: value,
    };
  },
  referenceCheckbox(value: boolean, prevState: State | undefined): State {
    return {
      ...(prevState as State),
      referenceCheckbox: value,
    };
  },
  transferAmount(value: FutureAmount, prevState: State | undefined): State {
    return {
      ...(prevState as State),
      transferAmount: value,
    };
  },
  transferFees(
    value: CalculateAssetTransferFeeResponse,
    prevState: State | undefined,
  ): State {
    return {
      ...(prevState as State),
      transferFees: value,
    };
  },
  tokenCategory(
    value: TokenCategory | "",
    prevState: State | undefined,
  ): State {
    return {
      ...(prevState as State),
      tokenCategory: value,
    };
  },
  mZARBalance(value: FutureAmount, prevState: State | undefined): State {
    return {
      ...(prevState as State),
      mZARBalance: value,
    };
  },
  ledgerAccountModel(
    value: StellarAccountViewModel,
    prevState: State | undefined,
  ): State {
    return {
      ...(prevState as State),
      ledgerAccountModel: value,
    };
  },
};

type UnfilteredValidationResultType = {
  valid: boolean;
  fieldValidations: { [key: string]: string | null };
};

export let UnfilteredValidationResult: UnfilteredValidationResultType = {
  valid: true,
  fieldValidations: {},
};

// define your validation func
export const validationFunc = async (
  formState: State,
): Promise<ValidationResult> => {
  const newValidationState: ValidationResult = {
    valid: true,
    fieldValidations: {},
  };

  if (formState.referenceCheckbox && !formState.reference) {
    newValidationState.fieldValidations.reference = "Cannot be blank";
    newValidationState.fieldValidations.transferHoverText =
      "All fields must be populated";
  }

  // validate that the reference is 40 characters long
  if (formState.reference.length > 40) {
    newValidationState.fieldValidations.reference =
      "Cannot be longer than 40 characters";
  }

  // validate that the stellar account ID is populated
  if (!formState.accountID) {
    newValidationState.fieldValidations.accountID = "Cannot be blank";
    newValidationState.fieldValidations.transferHoverText =
      "All fields must be populated";
  } else if (
    formState.accountID === formState.ledgerAccountModel.getNumber() ||
    formState.accountID === formState.ledgerAccountModel.getLedgerid()
  ) {
    newValidationState.fieldValidations.accountID =
      "From and To accounts cannot be the same";
  }

  // validate that the recipient account type is populated
  if (!formState.accountType) {
    newValidationState.fieldValidations.accountType = "Cannot be blank";
    newValidationState.fieldValidations.transferHoverText =
      "All fields must be populated";
  }

  // validate that transfer amount is not less than 0.0002
  if (
    decimalToBigNumber(formState.transferAmount.getValue()).lt(
      new BigNumber(0.00002),
    )
  ) {
    newValidationState.fieldValidations.transferAmount =
      "Cannot be less than 0.0000200";
  }

  // validate that transfer amount greater than 0
  if (decimalToBigNumber(formState.transferAmount.getValue()).isZero()) {
    newValidationState.fieldValidations.transferAmount =
      "Cannot be empty or zero";
    newValidationState.fieldValidations.transferHoverText =
      "All fields must be populated";
  }

  // only validate transfer fees when user is not MC user
  // calculate total fee amount
  const totalFeeAmount = futureAmountToStellarAmount(
    formState.transferFees.getFeeamount(),
  );
  const totalFeeAmountToken = totalFeeAmount.getToken();

  // check if fee amount has a value
  if (!totalFeeAmount.getValue()) {
    newValidationState.fieldValidations.transferFees = "no transfer fee value";
    newValidationState.fieldValidations.transferHoverText =
      "no transfer fee value";
  }

  // check if fee amount has a token
  if (!totalFeeAmount.getToken()) {
    newValidationState.fieldValidations.transferFees =
      "transfer fee has no token";
    newValidationState.fieldValidations.transferHoverText =
      "transfer fee has no token";
  }

  // convert decimal to big number for validation
  const totalFeeAmountValue = decimalToBigNumber(totalFeeAmount.getValue());

  // in this scenario we want to validate if the total transfer amount is less than the total available
  // when transferred token and fee token are the same
  if (formState.tokenCategory === TokenCategory.DigitalInstrument) {
    if (
      totalFeeAmountValue.gt(
        decimalToBigNumber(formState.mZARBalance.getValue()),
      )
    ) {
      newValidationState.fieldValidations.transferFees =
        "Insufficient balance for fees";
      newValidationState.fieldValidations.transferHoverText =
        "Insufficient balance for fees";
    }
  } else if (
    totalFeeAmountToken?.getCode() ===
    formState.mZARBalance.getToken()?.getCode()
  ) {
    if (
      totalFeeAmountValue
        .plus(decimalToBigNumber(formState.transferAmount.getValue()))
        .gt(decimalToBigNumber(formState.mZARBalance.getValue()))
    ) {
      newValidationState.fieldValidations.transferFees =
        "Insufficient balance for fees";
      newValidationState.fieldValidations.transferHoverText =
        "Insufficient balance for fees";
    }
  } else {
    if (
      totalFeeAmountValue
        .plus(decimalToBigNumber(formState.transferAmount.getValue()))
        .gt(
          decimalToBigNumber(
            formState.transferTokenAvailableBalance.getValue(),
          ),
        )
    ) {
      newValidationState.fieldValidations.transferFees =
        "Insufficient balance for fees";
      newValidationState.fieldValidations.transferHoverText =
        "Insufficient balance for fees";
    }
  }

  if (
    decimalToBigNumber(formState.transferTokenAvailableBalance.getValue()).lt(
      decimalToBigNumber(formState.transferAmount.getValue()),
    )
  ) {
    newValidationState.fieldValidations.transferAmount =
      "Insufficient balance to make transfer";
    newValidationState.fieldValidations.transferHoverText =
      "Insufficient balance to make transfer";
  }

  if (formState.userID && formState.accountID && formState.accountType) {
    if (formState.accountType === TransferAccountType.MeshAccountType) {
      const LedgerAccountExistsResponse =
        await formState.accountInspector.checkAccountExists(
          new CheckAccountExistsRequest()
            .setContext(
              new Context({
                userID: formState.userID,
              }).toFuture(),
            )
            .setCriteriaList([
              newTextExactCriterion("number", formState.accountID),
            ]),
        );

      if (!LedgerAccountExistsResponse.getExists()) {
        newValidationState.fieldValidations.accountID =
          "Mesh account does not exist";
      }
    } else if (
      formState.accountType === TransferAccountType.StellarAccountType
    ) {
      try {
        const StellarAccountExistsResponse =
          await formState.accountInspector.checkAccountExists(
            new CheckAccountExistsRequest()
              .setContext(
                new Context({
                  userID: formState.userID,
                }).toFuture(),
              )
              .setCriteriaList([
                newTextExactCriterion("ledgerid", formState.accountID),
              ]),
          );

        if (!StellarAccountExistsResponse.getExists()) {
          newValidationState.fieldValidations.accountID =
            "The Stellar account has no linked Mesh account";
        }
      } catch (e) {
        newValidationState.fieldValidations.accountID =
          "The Stellar account has no linked Mesh account";
      }
    }
  }

  if (Object.keys(newValidationState.fieldValidations).length > 0) {
    newValidationState.valid = false;
  }

  UnfilteredValidationResult = newValidationState;
  return newValidationState;
};

import { Amount } from "james/ledger";
import dayjs from "dayjs";
import { Entry } from "../audit/Entry";
import { BigNumber } from "bignumber.js";

export enum DirectOrderType {
  Buy = "Buy",
  Sell = "Sell",
}

export enum DirectOrderState {
  Pending = "Pending",
  AwaitingConfirmation = "Awaiting Confirmation",
  Accepted = "Accepted",
  Settled = "Settled",
  Cancelling = "Cancelling",
  Cancelled = "Cancelled",
  Declining = "Declining",
  Declined = "Declined",
  Failing = "Failing",
  Failed = "Failed",
  ClearanceFailed = "Clearance Failed",
  UnderInvestigation = "Under Investigation",
}

export const AllDirectOrderStates: DirectOrderState[] = [
  DirectOrderState.Pending,
  DirectOrderState.AwaitingConfirmation,
  DirectOrderState.Accepted,
  DirectOrderState.Settled,
  DirectOrderState.Declining,
  DirectOrderState.Declined,
  DirectOrderState.Cancelling,
  DirectOrderState.Cancelled,
  DirectOrderState.Failing,
  DirectOrderState.Failed,
  DirectOrderState.ClearanceFailed,
  DirectOrderState.UnderInvestigation,
];

const DirectOrderTypeName = "mesh::market/DirectOrder";

export class DirectOrder {
  public ["@type"]: string = DirectOrderTypeName;

  public id = "";

  public type: DirectOrderType | "" = "";

  public number = "";

  public listingID = "";

  public state: DirectOrderState | "" = "";

  public initiatingPartyGroupID = "";

  public initiatingUserID = "";

  public counterpartyGroupID = "";

  public amountIncl: Amount = new Amount();

  public estimatedAmountIncl: Amount = new Amount();

  public price: Amount = new Amount();

  public estimatedPrice: Amount = new Amount();

  public tokens: Amount = new Amount();

  public estimatedTokens: Amount = new Amount();

  public feeAmount: Amount = new Amount();

  public estimatedFeeAmount: Amount = new Amount();

  public vatAmount: Amount = new Amount();

  public estimatedVATAmount: Amount = new Amount();

  public timeSubmitted: string = dayjs().format();

  public timeApproved: string = dayjs().format();

  public clearanceAccountLedgerID = "";

  public stateResolutionCount = 0;

  public slippage: BigNumber = new BigNumber(0);
  public slippageFailed = false;

  public auditEntry: Entry = new Entry();

  constructor(o?: DirectOrder) {
    if (!o) {
      return;
    }
    this.id = o.id;
    this.type = o.type;
    this.number = o.number;
    this.listingID = o.listingID;
    this.state = o.state;

    this.initiatingPartyGroupID = o.initiatingPartyGroupID;
    this.initiatingUserID = o.initiatingUserID;

    this.counterpartyGroupID = o.counterpartyGroupID;

    this.amountIncl = new Amount(o.amountIncl);
    this.estimatedAmountIncl = new Amount(o.estimatedAmountIncl);

    this.price = new Amount(o.price);
    this.estimatedPrice = new Amount(o.estimatedPrice);

    this.tokens = new Amount(o.tokens);
    this.estimatedTokens = new Amount(o.estimatedTokens);

    this.feeAmount = new Amount(o.feeAmount);
    this.estimatedFeeAmount = new Amount(o.estimatedFeeAmount);

    this.vatAmount = new Amount(o.vatAmount);
    this.estimatedVATAmount = new Amount(o.estimatedVATAmount);

    this.timeSubmitted = o.timeSubmitted;
    this.timeApproved = o.timeApproved;

    this.clearanceAccountLedgerID = o.clearanceAccountLedgerID;

    this.stateResolutionCount = o.stateResolutionCount;

    this.auditEntry = new Entry(o.auditEntry);

    this.slippage = new BigNumber(o.slippage);
    this.slippageFailed = o.slippageFailed;
  }
}

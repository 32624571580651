import { Asset, Horizon } from "stellar-sdk";
import { IsLiquidityPoolToken } from "./liquidityPoolToken";
import { AccountChangedNotification } from "./AccountChangedNotification";
import { AccountOpenedNotification } from "./AccountOpenedNotification";
import { Token as StellarToken } from "@mesh/common-js/dist/stellar/token_pb";
import { Network } from "@mesh/common-js/dist/stellar/network_pb";

export * from "./Network";

export * from "./liquidityPoolToken";

// NOTE: no all of the decimals represented here as we are limited to 15 significant digits by javascript number
export const MaxSetLimitAmount = "922337203685.477";

export const NativeAssetTokenCode = "XLM";

export const StellarAccountNotificationChannelName = "StellarAccount";

export const StellarAccountPortfolioNotificationChannelName =
  "StellarAccountPortfolio";

export { AccountOpenedNotification, AccountChangedNotification };

export function getNetworkServer(network: Network | "-"): Horizon.Server {
  switch (network) {
    case Network.STELLAR_PUBLIC_NETWORK:
      return new Horizon.Server("https://horizon.mesh.trade");

    case Network.STELLAR_TEST_SDF_NETWORK:
      return new Horizon.Server("https://horizon-testnet.mesh.trade");

    default:
      throw new Error(`unexpected network ${network}`);
  }
}

export function tokenToAsset(token: StellarToken): Asset {
  if (
    ![
      Network.STELLAR_TEST_SDF_NETWORK,
      Network.STELLAR_PUBLIC_NETWORK,
    ].includes(token.getNetwork())
  ) {
    throw new TypeError(
      `given token is not on a supported stellar network: ${token.getNetwork()}`,
    );
  }
  if (IsLiquidityPoolToken(token)) {
    throw new TypeError(
      `liquidity pool token '${token.getCode()}:${token.getIssuer()}' cannot be converted to asset`,
    );
  }

  if (token.getCode() === NativeAssetTokenCode) {
    return new Asset(NativeAssetTokenCode);
  }
  return new Asset(token.getCode(), token.getIssuer());
}

import React, { useState } from "react";
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  IconButton,
  Typography,
} from "@mui/material";
import { Query as PastQuery } from "james/search/query";
import { useAPIContext } from "../../../../context/API";
import { useApplicationContext } from "context/Application/Application";
import { protobufTimestampToDayjs } from "@mesh/common-js/dist/googleProtobufConverters";
import { timezoneToString } from "@mesh/common-js/dist/i8n";
import { Refresh as ReloadIcon } from "@mui/icons-material";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import { ReadManyRateSourceRequest } from "@mesh/common-js/dist/financial/rateSourceReader_meshproto_pb";
import { useReadManyRateSource } from "@mesh/common-js-react/dist/financial/rateSourceReader_meshproto_meshjsreact";
import { Query } from "@mesh/common-js/dist/search/query_pb";
import { RateSource } from "@mesh/common-js/dist/financial/rateSource_pb";
import { Timestamp } from "google-protobuf/google/protobuf/timestamp_pb";
import { DateTimeFormatWithOffset } from "const/dateformats";
import { BPTable } from "components/Table";
import { useAppNoticeContext } from "context/AppNotice/AppNotice";
import { RateSourceCRUDDialog } from "./components";
dayjs.extend(utc);

const initialQuery = new Query().setOffset(0).setLimit(15);

export const RateSources = () => {
  const { NotificationBannerHeight: noticeBannerHeight } =
    useAppNoticeContext();
  const { authContext, viewConfiguration } = useApplicationContext();
  const [showRateSourceCRUDDialog, setShowRateSourceCRUDDialog] =
    useState(false);
  const {
    financial: { rateSourceReader },
  } = useAPIContext();
  const {
    readManyRateSourceError,
    readManyRateSourceRequest,
    readManyRateSourceResponse,
    setReadRateSourceRequest,
    readManyRateSourceLoading,
  } = useReadManyRateSource(rateSourceReader, {
    initialRequest: new ReadManyRateSourceRequest()
      .setContext(authContext.toFuture())
      .setQuery(initialQuery),
  });
  const reloadRateSources = () =>
    setReadRateSourceRequest(readManyRateSourceRequest);

  if (readManyRateSourceError) {
    return (
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          height: 200,
        }}
      >
        <Card>
          <CardHeader title={"Error Fetching Rate Sources"} />
          <CardContent
            sx={(theme) => ({
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyItems: "center",
              gap: theme.spacing(2),
            })}
          >
            <Typography>
              Something went wrong while fetching rate sources.
            </Typography>
            <Button
              variant="contained"
              color="primary"
              onClick={reloadRateSources}
            >
              Try Again
            </Button>
          </CardContent>
        </Card>
      </Box>
    );
  }

  return (
    <>
      <BPTable
        disableSelect
        title={"Rate Sources"}
        height={window.innerHeight - 140 - noticeBannerHeight}
        loading={readManyRateSourceLoading}
        toolBarControls={(() => {
          const controls: React.ReactNode[] = [];

          if (viewConfiguration["Rate Sources"]?.["Create"]) {
            controls.push(
              <Button
                variant="contained"
                color="primary"
                disabled={
                  readManyRateSourceLoading || readManyRateSourceLoading
                }
                onClick={() => setShowRateSourceCRUDDialog(true)}
              >
                Create New
              </Button>,
            );
          }

          controls.push(
            <IconButton
              size={"small"}
              disabled={readManyRateSourceLoading}
              onClick={reloadRateSources}
            >
              <ReloadIcon />
            </IconButton>,
          );

          return controls;
        })()}
        columns={[
          {
            label: "Name",
            field: "name",
            accessor: (data) => {
              data as RateSource;
              return data.getName();
            },
          },
          {
            label: "Timezone",
            field: "timezone",
            accessor: (data) => {
              data as RateSource;
              return timezoneToString(data.getTimezone());
            },
          },
          {
            label: "Last Modified",
            field: "auditentry.date",
            accessor: (data) => {
              const rowData = data as RateSource;
              return protobufTimestampToDayjs(
                rowData.getAuditentry()?.getTime() ?? new Timestamp(),
              )
                .tz(timezoneToString(data.getTimezone()))
                .format(DateTimeFormatWithOffset);
            },
          },
        ]}
        query={PastQuery.fromFutureQuery(readManyRateSourceRequest.getQuery())}
        onQueryChange={(query) =>
          setReadRateSourceRequest(
            readManyRateSourceRequest.setQuery(query.toFutureQuery()),
          )
        }
        data={readManyRateSourceResponse.getRecordsList()}
        totalNoRecords={readManyRateSourceResponse.getTotal()}
      />

      {showRateSourceCRUDDialog && (
        <RateSourceCRUDDialog
          closeDialog={() => setShowRateSourceCRUDDialog(false)}
          onRateSourceSaved={reloadRateSources}
        />
      )}
    </>
  );
};

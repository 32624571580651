import {
  Box,
  Button,
  CircularProgress,
  IconButton,
  Tab,
  Tabs,
  Tooltip,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import {
  DocumentsSection,
  InstrumentSection,
  AttributesForm,
} from "./components";
import { LegsForm } from "../LegsForm";
import { SmartInstrumentStateChip } from "../StateChip";
import { SmartInstrumentState } from "@mesh/common-js/dist/financial/smartInstrument_pb";
import { useBuilderContext, ViewMode } from "../../Context";
import { useApplicationContext } from "context/Application/Application";
import { useNavigate } from "react-router-dom";
import dayjs from "dayjs";
import { protobufTimestampToDayjs } from "@mesh/common-js/dist/googleProtobufConverters";
import { Timestamp } from "google-protobuf/google/protobuf/timestamp_pb";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";
import { Refresh } from "@mui/icons-material";
import { useStellarAccountContext } from "context/Account/StellarAccount";
import { CategoryOwnerIDNetworkIdentifier } from "james/search/identifier";
import { LedgerAccountCategory, Token } from "james/ledger";
import { IssuerOption } from "../../useValidatedForm";
dayjs.extend(utc);
dayjs.extend(timezone);

export type InstrumentFormProps = {
  system: boolean;
  height: number;
};

enum InstrumentFormTab {
  Instrument = "Instrument",
  Attributes = "Attributes",
  Documents = "Documents",
  Legs = "Legs",
}

const allInstrumentFormTabs: InstrumentFormTab[] = [
  InstrumentFormTab.Instrument,
  InstrumentFormTab.Legs,
  InstrumentFormTab.Attributes,
  InstrumentFormTab.Documents,
];

export const InstrumentForm = (props: InstrumentFormProps) => {
  const { formData, apiCallInProgress } = useBuilderContext();

  const [selectedTab, setSelectedTab] = useState(InstrumentFormTab.Instrument);

  return (
    <>
      <Box
        sx={(theme) => ({
          backgroundColor: theme.palette.custom.grapeDark,
          display: "grid",
          gridTemplateColumns: "auto 1fr",
          alignItems: "center",
          paddingRight: theme.spacing(1),
          boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
        })}
      >
        <Tabs scrollButtons visibleScrollbar value={selectedTab}>
          {allInstrumentFormTabs.map(
            (instrumentFormTab: InstrumentFormTab, tabIdx: number) => (
              <Tab
                key={tabIdx}
                value={instrumentFormTab}
                label={instrumentFormTab}
                onClick={() => setSelectedTab(instrumentFormTab)}
              />
            ),
          )}
        </Tabs>
        <Box
          sx={(theme) => ({
            justifySelf: "end",
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            gap: theme.spacing(1),
          })}
        >
          {apiCallInProgress && <CircularProgress size={10} />}
          <SmartInstrumentStateChip
            state={formData.smartInstrument.getState()}
          />
          <InstrumentActionsButtons />
        </Box>
      </Box>
      <Box
        className="meshScroll"
        sx={(theme) => ({
          padding: theme.spacing(2, 2, 0, 2),
          height: props.height - 50,
          overflowY: "auto",
          overflowX: "hidden",
        })}
      >
        {(() => {
          switch (selectedTab) {
            case InstrumentFormTab.Instrument:
              return <InstrumentSection system={props.system} />;

            case InstrumentFormTab.Documents:
              return <DocumentsSection />;

            case InstrumentFormTab.Attributes:
              return <AttributesForm />;

            case InstrumentFormTab.Legs:
              return <LegsForm height={props.height - 50} />;

            default:
              return null;
          }
        })()}
      </Box>
    </>
  );
};

function InstrumentActionsButtons() {
  const {
    checkUserSignatoryOnAccount,
    loading: stellarAccountContextLoading,
    accounts,
  } = useStellarAccountContext();
  const navigate = useNavigate();
  const { viewConfiguration } = useApplicationContext();
  const {
    formData,
    reloadFormData,
    saveDraftOrCreateAction: saveAction,
    preIssueAction,
    issueAction,
    apiCallInProgress,
    viewMode,
    setEditViewMode,
    formDataValidationResult,
  } = useBuilderContext();

  const [tradingAccSignatory, setTradingAccSignatory] = useState(false);
  useEffect(() => {
    (async () => {
      if (stellarAccountContextLoading || !accounts.length) {
        return;
      }
      try {
        setTradingAccSignatory(
          await checkUserSignatoryOnAccount(
            CategoryOwnerIDNetworkIdentifier(
              LedgerAccountCategory.Trading,
              formData.smartInstrument.getOwnerid(),
              Token.fromFutureToken(formData.smartInstrument.getToken())
                .network,
            ),
          ),
        );
      } catch (e) {
        console.error("error determing signatory status", e);
      }
    })();
  }, [stellarAccountContextLoading]);

  const availableInstrumentActionButtons: React.ReactNode[] = [];
  switch (formData.smartInstrument.getState()) {
    case SmartInstrumentState.DRAFT_SMART_INSTRUMENT_STATE:
      switch (viewMode) {
        case ViewMode.Edit:
          // note: no need to check view configuration permissions as the user
          // should not have been able to get into view mode if they don't have
          // the right permisions
          availableInstrumentActionButtons.push(
            <Button
              id={"smartInstrumentForm-save-button"}
              variant="contained"
              color="primary"
              disabled={apiCallInProgress}
              onClick={saveAction}
            >
              Save
            </Button>,
          );
          break;

        case ViewMode.View:
          if (viewConfiguration["Smart Instruments"]?.Write?.UpdateDraft) {
            availableInstrumentActionButtons.push(
              <Button
                id={"smartInstrumentForm-edit-button"}
                variant="contained"
                color="primary"
                disabled={apiCallInProgress}
                onClick={setEditViewMode}
              >
                Edit
              </Button>,
            );
          }

          if (
            viewConfiguration["Smart Instruments"]?.Write?.PreIssue &&
            viewConfiguration["Smart Instruments"]?.Write?.Issue &&
            formData.smartInstrument.getId() !== ""
          ) {
            // determine if next is issuance or pre-issuance
            const issuanceNext = (() => {
              const now = dayjs();
              const issueDate = protobufTimestampToDayjs(
                formData.smartInstrument.getIssuedate() ?? new Timestamp(),
              );
              return issueDate.isAfter(now);
            })();

            availableInstrumentActionButtons.push(
              <Tooltip
                placement="top"
                title={(() => {
                  if (!formDataValidationResult.valid) {
                    const problems: string[] = [];
                    Object.keys(
                      formDataValidationResult.fieldValidations,
                    ).forEach((k) => {
                      if (formDataValidationResult.fieldValidations[k]) {
                        problems.push(
                          `${k}: ${formDataValidationResult.fieldValidations[k]}`,
                        );
                      }
                    });
                    return (
                      <Box>
                        <Typography>
                          All problems need to be resolved before instrument can
                          be {issuanceNext ? "Issued" : "Pre-Issued"}:
                        </Typography>
                        <ul>
                          {problems.map((p, idx) => (
                            <li key={idx}>{p}</li>
                          ))}
                        </ul>
                      </Box>
                    );
                  }

                  if (
                    formData.issuerOption === IssuerOption.New &&
                    !tradingAccSignatory
                  ) {
                    return "You are not a signatory on the owner group trading account";
                  }

                  return "";
                })()}
              >
                <span>
                  {issuanceNext ? (
                    <Button
                      id={"smartInstrumentForm-pre-issue-button"}
                      variant="contained"
                      color="primary"
                      disabled={
                        !formDataValidationResult.valid ||
                        apiCallInProgress ||
                        (formData.issuerOption === IssuerOption.New &&
                          !tradingAccSignatory)
                      }
                      onClick={preIssueAction}
                    >
                      Pre-Issue
                    </Button>
                  ) : (
                    <Button
                      id={"smartInstrumentForm-issue-button"}
                      variant="contained"
                      color="primary"
                      disabled={
                        !formDataValidationResult.valid ||
                        apiCallInProgress ||
                        (formData.issuerOption === IssuerOption.New &&
                          !tradingAccSignatory)
                      }
                      onClick={issueAction}
                    >
                      Issue
                    </Button>
                  )}
                </span>
              </Tooltip>,
            );
          }
          availableInstrumentActionButtons.push(
            <IconButton
              id={"smartInstrumentForm-refresh-button"}
              onClick={reloadFormData}
            >
              <Refresh />
            </IconButton>,
          );
          break;
      }
      break;

    case SmartInstrumentState.PREISSUING_SMART_INSTRUMENT_STATE:
      availableInstrumentActionButtons.push(
        <IconButton
          id={"smartInstrumentForm-refresh-button"}
          onClick={reloadFormData}
        >
          <Refresh />
        </IconButton>,
      );
      break;

    case SmartInstrumentState.PREISSUANCE_FAILED_SMART_INSTRUMENT_STATE:
      if (
        viewConfiguration["Smart Instruments"]?.Write?.PreIssue &&
        formData.smartInstrument.getId() !== ""
      ) {
        availableInstrumentActionButtons.push(
          <Tooltip
            placement="top"
            title={(() => {
              if (
                formData.issuerOption === IssuerOption.New &&
                !tradingAccSignatory
              ) {
                return "You are not a signatory on the owner group trading account";
              }

              return "";
            })()}
          >
            <span>
              <Button
                id={"smartInstrumentForm-pre-issue-button"}
                variant="contained"
                color="primary"
                disabled={
                  !formDataValidationResult.valid ||
                  apiCallInProgress ||
                  (formData.issuerOption === IssuerOption.New &&
                    !tradingAccSignatory)
                }
                onClick={preIssueAction}
              >
                Retry Pre-Issue
              </Button>
            </span>
          </Tooltip>,
        );
      }
      availableInstrumentActionButtons.push(
        <IconButton
          id={"smartInstrumentForm-refresh-button"}
          onClick={reloadFormData}
        >
          <Refresh />
        </IconButton>,
      );
      break;

    case SmartInstrumentState.ISSUING_SMART_INSTRUMENT_STATE:
      availableInstrumentActionButtons.push(
        <IconButton
          id={"smartInstrumentForm-refresh-button"}
          onClick={reloadFormData}
        >
          <Refresh />
        </IconButton>,
      );
      break;

    case SmartInstrumentState.ISSUANCE_FAILED_SMART_INSTRUMENT_STATE:
      if (
        viewConfiguration["Smart Instruments"]?.Write?.Issue &&
        formData.smartInstrument.getId() !== ""
      ) {
        availableInstrumentActionButtons.push(
          <Tooltip
            placement="top"
            title={(() => {
              if (
                formData.issuerOption === IssuerOption.New &&
                !tradingAccSignatory
              ) {
                return "You are not a signatory on the owner group trading account";
              }

              return "";
            })()}
          >
            <span>
              <Button
                id={"smartInstrumentForm-issue-button"}
                variant="contained"
                color="primary"
                disabled={
                  !formDataValidationResult.valid ||
                  apiCallInProgress ||
                  (formData.issuerOption === IssuerOption.New &&
                    !tradingAccSignatory)
                }
                onClick={issueAction}
              >
                Retry Issue
              </Button>
            </span>
          </Tooltip>,
        );
      }
      availableInstrumentActionButtons.push(
        <IconButton
          id={"smartInstrumentForm-refresh-button"}
          onClick={reloadFormData}
        >
          <Refresh />
        </IconButton>,
      );
      break;

    case SmartInstrumentState.PREISSUED_SMART_INSTRUMENT_STATE:
    case SmartInstrumentState.ISSUED_SMART_INSTRUMENT_STATE:
    case SmartInstrumentState.MATURED_SMART_INSTRUMENT_STATE:
    case SmartInstrumentState.CANCELLED_SMART_INSTRUMENT_STATE:
      availableInstrumentActionButtons.push(
        <Button
          id={"smartInstrumentForm-marketListing-button"}
          variant="contained"
          color="primary"
          disabled={apiCallInProgress}
          onClick={() => {
            const token = formData.smartInstrument.getToken();
            if (!token) {
              return;
            }

            const query = new URLSearchParams();

            query.set("token-code", token.getCode());
            query.set("token-issuer", token.getIssuer());
            query.set("token-network", token.getNetwork().toString());

            navigate({
              pathname: "/builder/market-listing/listing",
              search: query.toString(),
            });
          }}
        >
          View Listing
        </Button>,
      );
      availableInstrumentActionButtons.push(
        <IconButton
          id={"smartInstrumentForm-refresh-button"}
          onClick={reloadFormData}
        >
          <Refresh />
        </IconButton>,
      );
      break;

    case SmartInstrumentState.UNDEFINED_SMART_INSTRUMENT_STATE:
    default:
      console.error(
        `unexpected smart instrument state: ${formData.smartInstrument.getState()}`,
      );
  }

  return (
    <>
      {availableInstrumentActionButtons.map((b, idx) => (
        <React.Fragment key={idx}>{b}</React.Fragment>
      ))}
    </>
  );
}

import { createTheme, Theme, ThemeOptions } from "@mui/material/styles";

declare module "@mui/material/styles" {
  type DefaultTheme = Theme;

  interface Palette {
    custom: {
      midnight: string;
      spaceblue: string;
      cardInner: string;
      lavender: string;
      lavenderLight: string;
      grape: string;
      grapeLight: string;
      grapeDark: string;
      darkYellow: string;
      mildYellow: string;
      yellow: string;
      green: string;
      red: string;
      opaqueBackground: string;
      pantone: {
        _2587: string;
        _513: string;
        _246: string;
        _1788: string;
        _1787: string;
        _1585: string;
        _1375: string;
        _1235: string;
        _611: string;
        _383: string;
        _353: string;
        _3255: string;
        _326: string;
        _632: string;
        _640: string;
      };
    };
  }

  interface TypeText {
    hint: string;
    tertiary: string;
  }
}

interface CustomThemeOptions extends ThemeOptions {
  Palette?: {
    custom: {
      midnight: string;
      spaceblue: string;
      cardInner: string;
      lavender: string;
      lavenderLight: string;
      grape: string;
      grapeLight: string;
      grapeDark: string;
      darkYellow: string;
      mildYellow: string;
      yellow: string;
      green: string;
      red: string;
      opaqueBackground: string;
      pantone: {
        _2587: string;
        _513: string;
        _246: string;
        _1788: string;
        _1787: string;
        _1585: string;
        _1375: string;
        _1235: string;
        _611: string;
        _383: string;
        _353: string;
        _3255: string;
        _326: string;
        _632: string;
        _640: string;
      };
    };
  };
}

export const defaultTheme = createTheme({
  breakpoints: {
    values: {
      xs: 0,
      mb: 360, //this is for edge case mobile only
      sm: 600,
      md: 960,
      lg: 1280,
      xl: 1920,
    },
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          minWidth: 110,
          textTransform: "capitalize",
          letterSpacing: "0.3px",
        },
        outlined: {
          color: "#ffffff",
          border: "1px solid rgba(255, 255, 255, 0.23)",
        },
      },
      defaultProps: {
        color: "inherit",
      },
    },
    MuiTextField: {
      defaultProps: {
        variant: "outlined",
        margin: "dense",
        size: "small",
        InputLabelProps: { shrink: true },
      },
      styleOverrides: {
        root: {
          "& label.Mui-focused": {
            color: "#30b0b0",
          },
          "& .MuiOutlinedInput-root": {
            "&.Mui-focused fieldset": {
              borderColor: "#30b0b0",
            },
          },
        },
      },
    },
    MuiTooltip: {
      defaultProps: {
        enterTouchDelay: 50,
        leaveTouchDelay: 3000,
        arrow: true,
      },
      styleOverrides: {
        tooltip: {
          backgroundColor: "#CFCEFD",
          color: "black",
        },
        arrow: {
          color: "#CFCEFD",
        },
      },
    },
    MuiTabs: {
      styleOverrides: {
        indicator: {
          backgroundColor: "#ffffff",
        },
      },
    },
    MuiMenuItem: {
      styleOverrides: {
        root: {
          backgroundColor: "transparent !important",
          "&:hover": {
            backgroundColor: "rgba(0, 0, 0, 0.16) !important",
          },
          "&:focus": {
            backgroundColor: "rgba(0, 0, 0, 0.2) !important",
          },
        },
      },
    },
    MuiAutocomplete: {
      styleOverrides: {
        listbox: {
          '& .MuiAutocomplete-option[aria-selected="true"]': {
            backgroundColor: "unset",
          },
          '& .MuiAutocomplete-option[aria-selected="true"].Mui-focused': {
            backgroundColor: "rgba(0, 0, 0, 0.2)",
          },
        },
        paper: {
          backgroundColor: "#46426C !important",
        },
      },
    },
    MuiDialog: {
      styleOverrides: {
        paper: {
          backgroundImage: "unset",
        },
      },
    },
    MuiCardHeader: {
      styleOverrides: {
        root: {
          padding: "14px",
          backgroundColor: "#232041",
        },
        action: {
          marginTop: 0,
          paddingRight: "8px",
        },
      },
    },
    MuiPaper: {
      styleOverrides: {
        rounded: {
          borderRadius: 10,
        },
        root: {
          backgroundImage: "unset",
        },
      },
    },
    MuiLink: {
      styleOverrides: {
        root: {
          color: "#30b0b0",
          cursor: "pointer",
          "&:hover": {
            color: "#acdcdd",
          },
        },
      },
    },
    MuiMenu: {
      styleOverrides: {
        paper: {
          backgroundColor: "#46426C !important",
        },
      },
    },
    MuiDialogTitle: {
      styleOverrides: {
        root: {
          display: "grid",
          gridTemplateColumns: "1fr auto",
          padding: "8px 8px 8px 16px",
          alignItems: "center",
          backgroundColor: "#232041",
        },
      },
    },
    MuiDialogContent: {
      styleOverrides: {
        root: {
          padding: "16px",
        },
      },
    },
    MuiDialogActions: {
      styleOverrides: {
        root: {
          padding: "16px",
        },
        spacing: {
          gap: 8,
        },
      },
    },
    MuiIconButton: {
      styleOverrides: {
        root: {
          color: "rgba(255, 255, 255, 0.3)",
          cursor: "pointer",
          "&:hover": {
            color: "#ffffff",
          },
        },
      },
    },
    MuiChip: {
      styleOverrides: {
        colorSecondary: {
          color: "#232041",
          backgroundColor: "#acdcdd",
        },
        deleteIcon: {
          color: "rgba(255, 255, 255, 0.60)",
        },
      },
    },
    MuiStepIcon: {
      styleOverrides: {
        root: {
          "&.Mui-completed": {
            color: "#4caf50",
          },
          "&.Mui-active": {
            color: "#4caf50",
          },
          "&.Mui-error": {
            color: "#ff9800",
          },
        },
      },
    },
  },
  shape: {
    borderRadius: 4,
  },
  palette: {
    mode: "dark",
    primary: {
      light: "#e95e86",
      main: "#de1b55",
      dark: "#a40f4b",
    },
    secondary: {
      light: "#acdcdd",
      main: "#30b0b0",
      dark: "#00827e",
      contrastText: "#fff",
    },
    error: {
      light: "#e57373",
      main: "#f44336",
      dark: "#D32F2F",
      contrastText: "#fff",
    },
    info: {
      light: "#64B5F6",
      main: "#2196F3",
      dark: "#1976D2",
      contrastText: "#fff",
    },
    warning: {
      light: "#FFB74D",
      main: "#ff9800",
      dark: "#F57C00",
      contrastText: "#fff",
    },
    success: {
      light: "#81C784",
      main: "#4caf50",
      dark: "#388E3C",
      contrastText: "#fff",
    },
    text: {
      primary: "#F5F5F5",
      secondary: "rgba(255, 255, 255, 0.68)",
      tertiary: "rgba(255, 255, 255, 0.80)",
      hint: "rgba(255, 255, 255, 0.68)",
      disabled: "rgba(255, 255, 255, 0.38)",
    },
    divider: "rgba(255, 255, 255, 0.12)",
    background: {
      paper: "#2E2B50",
      default: "rgba(18, 16, 39, 1)",
    },
    action: {
      active: "#fff",
      hover: "rgba(0, 0, 0, 0.2)",
      hoverOpacity: 0.1,
      selected: "rgba(0, 0, 0, 0.38)",
      disabled: "rgba(255, 255, 255, 0.3)",
      disabledBackground: "rgba(255, 255, 255, 0.12)",
    },
    custom: {
      midnight: "#232041",
      cardInner: "#2E2B50",
      spaceblue: "#171433",
      lavender: "#A3A1FB",
      lavenderLight: "#CFCEFD",
      grape: "#46426C",
      grapeLight: "#605B88",
      grapeDark: "#363069",
      mildYellow: "#ffca81",
      darkYellow: "#FFCA83",
      yellow: "#FFCA8361",
      green: "#4AD99161",
      red: "#D94A4B61",
      opaqueBackground: "rgba(46, 43, 80, 0.7)",
      pantone: {
        _2587: "#8246AF",
        _513: "#8e258c",
        _246: "#c21cac",
        _326: "#00b2a9",
        _353: "#7ee9a6",
        _383: "#a2ad00",
        _611: "#d5c833",
        _640: "#0082bb",
        _632: "#009bbb",
        _1235: "#faad19",
        _1375: "#ffa02f",
        _1585: "#ff6d22",
        _1788: "#ea2839",
        _1787: "#f53f5b",
        _3255: "#3ad6c5",
      },
    },
  },
  typography: {
    fontFamily: '"Poppins", "Helvetica", "Arial", sans-serif',
    fontWeightBold: "bold",
    fontWeightMedium: 600,
    fontWeightRegular: 400,
    fontWeightLight: 300,
    h1: {
      fontSize: 38,
      lineHeight: "45px",
      letterSpacing: "-0.5px",
    },
    h2: {
      fontWeight: 400,
      fontSize: 26,
      lineHeight: "28px",
      letterSpacing: "0px",
    },
    h3: {
      fontWeight: 700,
      fontSize: 24,
      lineHeight: "29px",
      letterSpacing: "0.25px",
    },
    h4: {
      fontSize: 20,
      lineHeight: "24px",
      letterSpacing: 0,
    },
    h5: {
      fontSize: 16,
      lineHeight: "21px",
      letterSpacing: "0.25px",
    },
    h6: {
      fontWeight: 600,
      fontSize: 14,
      lineHeight: "16px",
      letterSpacing: "0.25px",
    },
    subtitle1: {
      fontSize: 16,
      lineHeight: "21px",
      letterSpacing: "0.15px",
    },
    subtitle2: {
      fontWeight: 400,
      fontSize: 14,
      lineHeight: "21px",
      letterSpacing: "0.1px",
    },
    body1: {
      fontSize: 14,
      lineHeight: "19px",
      letterSpacing: "0.5px",
    },
    body2: {
      fontSize: 12,
      lineHeight: "16px",
      letterSpacing: "0.25px",
    },
    body3: {
      fontSize: 10,
      lineHeight: "12px",
      letterSpacing: "0.20px",
    },
    button: {
      fontSize: 14,
      lineHeight: "21px",
      letterSpacing: "1.25px",
    },
    caption: {
      fontSize: 12,
      lineHeight: "21px",
      letterSpacing: "0.4px",
    },
    overline: {
      fontSize: 12,
      lineHeight: "15px",
      letterSpacing: "1px",
    },
  },
} as CustomThemeOptions);

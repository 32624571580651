import React, { useState } from "react";
import {
  Autocomplete,
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  InputAdornment,
  TextField,
  Typography,
  useTheme,
} from "@mui/material";
import { useErrorContext } from "context/Error";
import { useApplicationContext } from "context/Application/Application";
import { useSnackbar } from "notistack";
import { LoadingButton } from "@mui/lab";
import { SubscriptionOrderBook } from "james/market/SubscriptionOrderBook";
import {
  Model as potUnderwriterViewModel,
  usepotUnderwriterViewReaderRead,
} from "james/views/potUnderwriterView";
import {
  TextNEExactCriterion,
  TextSubstringCriterion,
} from "james/search/criterion";
import {
  AddUnderwritingToSubscriptionOrderBookRequest,
  SubscriptionOrderBookUpdater,
} from "james/market";
import { BigNumber } from "bignumber.js";
import { TextNumField } from "components/FormFields";

interface Props {
  onClose: () => void;
  subscriptionOrderBook: SubscriptionOrderBook;
}

export const UnderwritingManagementDialog: React.FC<Props> = ({
  onClose,
  subscriptionOrderBook,
}: Props) => {
  const { authContext, loginClaims } = useApplicationContext();
  const theme = useTheme();
  const { errorContextDefaultWarningFeedback } = useErrorContext();
  const { enqueueSnackbar } = useSnackbar();

  const {
    loading: loadingPotentialCounterparties,
    readResponse: { models: potentialCounterParties },
    readRequest: potentialCounterpartiesReadRequest,
    setReadRequest: setPotentialCounterpartiesReadRequest,
  } = usepotUnderwriterViewReaderRead({
    context: authContext,
    criteria: { id: TextNEExactCriterion(loginClaims.clientID) },
  });

  const [apiLoading, setAPILoading] = useState(false);
  const [request, setRequest] =
    useState<AddUnderwritingToSubscriptionOrderBookRequest>({
      context: authContext,
      underwritingClientID: "",
      subscriptionOrderBookID: subscriptionOrderBook.id,
      percentage: BigNumber("100"),
    });
  const addUnderwriter = async () => {
    setAPILoading(true);
    try {
      await SubscriptionOrderBookUpdater.AddUnderwritingToSubscriptionOrderBook(
        request,
      );
      enqueueSnackbar("Underwriter Added", { variant: "success" });
      onClose();
    } catch (e) {
      console.error("error adding underwriting", e);
      errorContextDefaultWarningFeedback(e, "error adding underwriting");
    }
    setAPILoading(false);
  };

  return (
    <Dialog open>
      <DialogTitle sx={(theme) => ({ padding: theme.spacing(2) })}>
        Underwriting for Book {subscriptionOrderBook.number}
      </DialogTitle>
      <DialogContent
        style={{
          alignItems: "center",
          minWidth: "590px",
          padding: theme.spacing(3),
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: theme.spacing(2),
          }}
        >
          <Typography>Details of new Underwriting:</Typography>
          <Autocomplete
            isOptionEqualToValue={(option, value) => option === value}
            fullWidth
            disabled={apiLoading}
            getOptionLabel={(option: potUnderwriterViewModel) => option.name}
            options={potentialCounterParties}
            loading={loadingPotentialCounterparties}
            onChange={(_, selectedModel: potUnderwriterViewModel | null) => {
              setRequest({
                ...request,
                underwritingClientID: selectedModel?.clientID ?? "",
              });
            }}
            value={(() => {
              const selectedModel = potentialCounterParties.find(
                (cp) => cp.clientID === request.underwritingClientID,
              );
              return selectedModel || null;
            })()}
            onClose={() =>
              setPotentialCounterpartiesReadRequest({
                ...potentialCounterpartiesReadRequest,
                criteria: {
                  id: TextNEExactCriterion(loginClaims.clientID),
                },
              })
            }
            renderInput={(params) => (
              <TextField
                {...params}
                fullWidth
                label="Underwriter Client"
                variant="outlined"
                InputLabelProps={{ shrink: true }}
                onChange={(e) => {
                  if (e.target.value === "") {
                    setPotentialCounterpartiesReadRequest({
                      ...potentialCounterpartiesReadRequest,
                      criteria: {
                        id: TextNEExactCriterion(loginClaims.clientID),
                      },
                    });
                  } else {
                    setPotentialCounterpartiesReadRequest({
                      ...potentialCounterpartiesReadRequest,
                      criteria: {
                        ...potentialCounterpartiesReadRequest.criteria,
                        name: TextSubstringCriterion(e.target.value),
                      },
                    });
                  }
                }}
                InputProps={{
                  ...params.InputProps,
                  placeholder: "Select/Start Typing...",
                  endAdornment: (
                    <>
                      {loadingPotentialCounterparties ? (
                        <CircularProgress color="inherit" size={20} />
                      ) : null}
                      {params.InputProps.endAdornment}
                    </>
                  ),
                }}
              />
            )}
          />
          <TextNumField
            label={"Percentage"}
            value={request.percentage}
            disallowNegative
            noDecimalPlaces={7}
            onChange={(e) =>
              setRequest({
                ...request,
                percentage: new BigNumber(e.target.value),
              })
            }
            InputProps={{
              endAdornment: (
                <InputAdornment position={"end"}>
                  <Typography
                    color={"textSecondary"}
                    variant={"body1"}
                    children={"%"}
                  />
                </InputAdornment>
              ),
            }}
            disabled={apiLoading}
          />
        </Box>
        <DialogActions>
          <Button
            id="fundReceipt-updateFunding"
            variant="outlined"
            fullWidth
            size="small"
            disabled={apiLoading}
            onClick={onClose}
            children="Close"
          />
          <LoadingButton
            loading={apiLoading}
            variant={"contained"}
            color={"primary"}
            fullWidth
            size="small"
            onClick={addUnderwriter}
          >
            Add Underwriter
          </LoadingButton>
        </DialogActions>
      </DialogContent>
    </Dialog>
  );
};

import { ValidationResult } from "common/validation";
import { RateSource } from "@mesh/common-js/dist/financial/rateSource_pb";
import { Timezone } from "@mesh/common-js/dist/i8n/timezone_pb";

export type FormData = {
  rateSource: RateSource;
};

export type FormUpdaterSpecsType = {
  rateSource: (rateSource: RateSource, prevFormData?: FormData) => FormData;
  name: (name: string, prevFormData?: FormData) => FormData;
  description: (description: string, prevFormData?: FormData) => FormData;
  timezone: (timezone: Timezone, prevFormData?: FormData) => FormData;
};

export const formUpdaterSpecs: FormUpdaterSpecsType = {
  rateSource(rateSource: RateSource, prevFormData?: FormData) {
    const formData = prevFormData as FormData;
    return {
      ...formData,
      rateSource: RateSource.deserializeBinary(rateSource.serializeBinary()),
    };
  },
  name(name: string, prevFormData?: FormData) {
    const formData = prevFormData as FormData;
    return {
      ...formData,
      rateSource: formData.rateSource.setName(name),
    };
  },
  description(description: string, prevFormData?: FormData) {
    const formData = prevFormData as FormData;
    return {
      ...formData,
      rateSource: formData.rateSource.setDescription(description),
    };
  },
  timezone(timezone: Timezone, prevFormData?: FormData) {
    const formData = prevFormData as FormData;
    return {
      ...formData,
      rateSource: formData.rateSource.setTimezone(timezone),
    };
  },
};

export const formDataValidationFunc = async (
  formData: FormData,
): Promise<ValidationResult> => {
  // prepare validation result
  const validationResult: ValidationResult = {
    // assumed to true -
    // any error must set to false
    valid: true,
    // contains field validations
    fieldValidations: {},
  };

  const invalid = (field: string, message: string) => {
    validationResult.valid = false;
    validationResult.fieldValidations[field] = message;
  };

  if (formData.rateSource.getName() === "") {
    invalid("name", "Must be set");
  }

  if (formData.rateSource.getDescription() === "") {
    invalid("description", "Must be set");
  }

  return validationResult;
};

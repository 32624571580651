import React, { useEffect, useRef, useState } from "react";
import {
  alpha,
  Backdrop,
  Box,
  Button,
  CircularProgress,
  Grid,
  IconButton,
  Menu,
  MenuItem,
  Theme,
  Tooltip,
  Typography,
  useMediaQuery,
} from "@mui/material";

import { TextField } from "components/FormFields/TextField";
import { MoreVert, MoreVertOutlined } from "@mui/icons-material";
import { Model } from "james/views/stellarClaimableBalanceView/Model";
import {
  ClaimClaimableBalanceResponse,
  ClaimClaimableBalanceRequest,
} from "@mesh/common-js/dist/ledger/stellarops/accountOperator_pb";
import { Amount } from "components/Ledger/Amount";
import { useSnackbar } from "notistack";
import { TransactionNotificationChannel } from "james/ledger/TransactionNotificationChannel";
import {
  TransactionFailedNotification,
  TransactionFailedNotificationTypeName,
  TransactionSubmissionResolutionFailedNotification,
  TransactionSubmissionResolutionFailedNotificationTypeName,
  TransactionSucceededNotification,
  TransactionSucceededNotificationTypeName,
} from "james/ledger/TransactionNotifications";
import { Notification } from "james/notification/Notification";
import { useNotificationContext } from "context/Notification";
import { ErrInspectedTransactionNotFoundMsg } from "james/ledger";
import { TokenIconViewUpload } from "components/Ledger/Token";
import { TokenCategory } from "james/views/ledgerTokenView/Model";
import { useErrorContext } from "context/Error";
import { useApplicationContext } from "context/Application/Application";
import { useAPIContext } from "context/API";
import { ArchiveClaimableBalanceRequest } from "@mesh/common-js/dist/stellar/claimableBalanceArchiver_pb";
import { TransactionState } from "@mesh/common-js/dist/stellar/transaction_pb";
import { RetrieveTransactionStateRequest } from "@mesh/common-js/dist/ledger/transactionInspector_pb";
import { newTextExactCriterion } from "@mesh/common-js/dist/search";

interface ClaimableBalanceCardProps {
  model: Model;
  claimaintAccountID: string;
  isUserSignatoryOnAccount: boolean;
  removeFromList: (id: string) => void; // A function to remove card from list
}

export enum OverlayMessageType {
  Undefined,
  Loading,
  ClaimProcessing,
  ClaimProcessingFailed,
  SomethingWentWrong,
}

export function ClaimableBalanceCard(props: ClaimableBalanceCardProps) {
  const {
    ledger: {
      stellarops: { stellarOpsAccountOperator },
      transactionInspector,
    },
    stellar: { claimableBalanceArchiver },
  } = useAPIContext();
  const { errorContextErrorTranslator } = useErrorContext();
  const [overLayLoadingText, setOverLayLoadingText] = useState(
    OverlayMessageType.Loading,
  );
  const actionButtonElRef = useRef<HTMLButtonElement | null>(null);
  const actionsVertButtonRef = useRef<HTMLButtonElement | null>(null);
  const [openMenu, setOpenMenu] = useState(false);
  const { registerNotificationCallback } = useNotificationContext();
  const { authContext } = useApplicationContext();
  const { enqueueSnackbar } = useSnackbar();
  const [registerForNotificationToggle, setRegisterForNotificationToggle] =
    useState(false);
  const { viewConfiguration } = useApplicationContext();
  const isMobile = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down("sm"),
  );

  useEffect(() => {
    let deregister: () => void = () => {
      return;
    };

    // determine if there is transaction ID stored in local Storage
    const txnID = localStorage.getItem(
      `claimableBalanceViewModelID-${props.model.id}-claimingTransactionID`,
    );

    if (txnID === null) {
      return;
    }

    (async () => {
      // register callback to fire once the claim ledger transaction has been submitted
      try {
        deregister = await registerNotificationCallback(
          new TransactionNotificationChannel({
            transactionID: txnID,
            private: true,
          }),
          [
            TransactionFailedNotificationTypeName,
            TransactionSubmissionResolutionFailedNotificationTypeName,
          ],
          (n: Notification) => {
            if (n instanceof TransactionFailedNotification) {
              setOverLayLoadingText(OverlayMessageType.ClaimProcessingFailed);
            }

            if (
              n instanceof TransactionSubmissionResolutionFailedNotification
            ) {
              setOverLayLoadingText(OverlayMessageType.SomethingWentWrong);
            }

            localStorage.removeItem(
              `claimableBalanceViewModelID-${props.model.id}-claimingTransactionID`,
            );
          },
        );
      } catch (e) {
        console.error(
          "error registering for transaction submission notifications",
          e,
        );
        enqueueSnackbar(
          "Unable to subscribe to transfer submission notifications",
          { variant: "warning" },
        );
      }
    })();

    return deregister;
  }, [
    registerForNotificationToggle,
    registerNotificationCallback,
    props.model.id,
  ]);

  useEffect(() => {
    (async () => {
      setOverLayLoadingText(OverlayMessageType.Loading);
      try {
        const retrieveTransactionStateResponse =
          await transactionInspector.retrieveTransactionState(
            new RetrieveTransactionStateRequest()
              .setContext(authContext.toFuture())
              .setCriteriaList([
                newTextExactCriterion(
                  "metaData.claimableBalanceID",
                  props.model.claimableBalanceID,
                ),
              ]),
          );

        switch (retrieveTransactionStateResponse.getState()) {
          case TransactionState.PENDING_TRANSACTION_STATE:
          case TransactionState.SUBMISSION_IN_PROGRESS_TRANSACTION_STATE:
            setOverLayLoadingText(OverlayMessageType.ClaimProcessing);
            return;

          case TransactionState.FAILED_TRANSACTION_STATE:
            setOverLayLoadingText(OverlayMessageType.ClaimProcessingFailed);
            return;

          case TransactionState.SUCCESSFUL_TRANSACTION_STATE:
            // If transaction was successful and model claimed field is not true then hide the
            // card manually since that would imply the view model has yet to update
            if (!props.model.claimed) {
              console.warn("view model pending update");
              props.removeFromList(props.model.id);
            }
            return;

          case TransactionState.DRAFT_TRANSACTION_STATE:
          case TransactionState.INDETERMINATE_TRANSACTION_STATE:
            setOverLayLoadingText(OverlayMessageType.SomethingWentWrong);
            return;
        }
      } catch (e) {
        if (`${e}`.includes(ErrInspectedTransactionNotFoundMsg)) {
          setOverLayLoadingText(OverlayMessageType.Undefined);
          return;
        }

        console.error(`error retrieving transaction state: ${e}`);
        enqueueSnackbar(`Error Determining Transaction State: ${e}`, {
          variant: "error",
        });
      }
      setOverLayLoadingText(OverlayMessageType.Undefined);
    })().finally();
  }, [props, enqueueSnackbar, authContext]);

  const handleClaimClaimableBalance = async () => {
    setOverLayLoadingText(OverlayMessageType.Loading);
    let claimClaimableBalanceResponse: ClaimClaimableBalanceResponse;
    try {
      claimClaimableBalanceResponse =
        await stellarOpsAccountOperator.claimClaimableBalance(
          new ClaimClaimableBalanceRequest()
            .setContext(authContext.toFuture())
            .setClaimablebalanceid(props.model.claimableBalanceID)
            .setClaimantaccountid(props.claimaintAccountID),
        );
    } catch (e) {
      const err = errorContextErrorTranslator.translateError(e);
      console.error("error claiming claimable balance", e);
      enqueueSnackbar(`Error Claiming Claimable Balance: ${err.message}`, {
        variant: "warning",
      });
      setOverLayLoadingText(OverlayMessageType.Undefined);
      return;
    }

    // register callback to fire once the claim ledger transaction has been submitted
    try {
      const deregister = await registerNotificationCallback(
        new TransactionNotificationChannel({
          transactionID: claimClaimableBalanceResponse.getTransactionid(),
          private: true,
        }),
        [
          TransactionSucceededNotificationTypeName,
          TransactionFailedNotificationTypeName,
          TransactionSubmissionResolutionFailedNotificationTypeName,
        ],
        (n: Notification) => {
          if (
            n instanceof TransactionSucceededNotification &&
            n.transactionID === claimClaimableBalanceResponse.getTransactionid()
          ) {
            enqueueSnackbar("Success! Claimable Balance Claimed", {
              variant: "success",
            });
            // on success remove the card from list
            if (props.removeFromList) {
              props.removeFromList(props.model.id);
            }
            deregister();
          }

          if (
            n instanceof TransactionFailedNotification &&
            n.transactionID === claimClaimableBalanceResponse.getTransactionid()
          ) {
            enqueueSnackbar(
              "Error! Claimable Balance Claim Submission Failed",
              {
                variant: "error",
              },
            );
            deregister();
          }

          if (
            n instanceof TransactionSubmissionResolutionFailedNotification &&
            n.transactionID === claimClaimableBalanceResponse.getTransactionid()
          ) {
            enqueueSnackbar(
              "Warning! Something Has Gone Wrong With Claimable Balance Claim Submission",
              { variant: "warning" },
            );
            deregister();
          }
        },
      );

      localStorage.setItem(
        `claimableBalanceViewModelID-${props.model.id}-claimingTransactionID`,
        claimClaimableBalanceResponse.getTransactionid(),
      );

      setOverLayLoadingText(OverlayMessageType.ClaimProcessing);
      setRegisterForNotificationToggle(!registerForNotificationToggle);
    } catch (e) {
      console.error(
        "error registering for transfer transaction notifications",
        e,
      );
    }
  };

  const handleArchiveClaimableBalance = async () => {
    try {
      setOverLayLoadingText(OverlayMessageType.Loading);

      await claimableBalanceArchiver.archiveClaimableBalance(
        new ArchiveClaimableBalanceRequest()
          .setContext(authContext.toFuture())
          .setClaimablebalanceid(props.model.claimableBalanceID)
          .setClaimantaccountledgerid(props.model.claimantAccountLedgerID),
      );
      enqueueSnackbar("Success! Claimable Balance Hidden", {
        variant: "success",
      });
      props.removeFromList(props.model.id);
    } catch (e) {
      const err = errorContextErrorTranslator.translateError(e);
      enqueueSnackbar(
        `Error! Hiding Claimable Balance: ${
          err.message ? err.message : err.toString()
        }`,
        { variant: "error" },
      );
      setOverLayLoadingText(OverlayMessageType.Undefined);
    }
  };

  return (
    <Box
      sx={(theme) => ({
        display: "grid",
        position: "relative",
        paddingLeft: theme.spacing(3),
        paddingRight: theme.spacing(3),
        gridTemplateColumns: "82px auto",
        borderRadius: "4px",
        maxHeight: 216,
        [theme.breakpoints.down("sm")]: {
          maxHeight: 322,
          borderRadius: "10px",
          alignSelf: "end",
          marginBottom: theme.spacing(2),
        },
        width: "100%",
        height: "100%",
        backgroundColor: theme.palette.background.paper,
        padding: theme.spacing(2, 3, 2, 3),
        marginBottom: theme.spacing(2),
      })}
    >
      {/* overlay component for loading state */}
      <Backdrop
        sx={(theme) => ({
          position: "absolute",
          borderRadius: "4px",
          zIndex: 1,
          backgroundColor: alpha(theme.palette.background.default, 0.9),
        })}
        open={overLayLoadingText !== OverlayMessageType.Undefined}
      >
        {(() => {
          switch (overLayLoadingText) {
            case OverlayMessageType.ClaimProcessing:
              return (
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    justifyItems: "center",
                    alignItems: "center",
                  }}
                >
                  <Typography variant="h5">
                    Your claim is being processed
                  </Typography>
                  <Typography
                    align="center"
                    sx={(theme) => ({ color: theme.palette.text.secondary })}
                    variant="caption"
                  >
                    Once complete it will be removed
                  </Typography>
                </Box>
              );

            case OverlayMessageType.SomethingWentWrong:
              return (
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    justifyItems: "center",
                    alignItems: "center",
                  }}
                >
                  <Typography variant="h5">Something went wrong</Typography>
                  <Typography
                    align="center"
                    sx={(theme) => ({ color: theme.palette.text.secondary })}
                    variant="caption"
                  >
                    We are working on the issue, please check again later
                  </Typography>
                </Box>
              );

            case OverlayMessageType.ClaimProcessingFailed:
              return (
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    justifyItems: "center",
                    alignItems: "center",
                  }}
                >
                  <Typography
                    sx={(theme) => ({
                      color: theme.palette.error.main,
                    })}
                    variant="h5"
                  >
                    Your claim failed to be processed
                  </Typography>
                  {viewConfiguration.StellarClaimableBalanceClaimer && (
                    <Tooltip
                      title={
                        !props.isUserSignatoryOnAccount
                          ? "You are not signatory on this account"
                          : ""
                      }
                      placement="top"
                    >
                      <Box component={"span"}>
                        <Button
                          component="div"
                          sx={(theme) => ({
                            width: 120,
                            marginTop: theme.spacing(1),
                          })}
                          onClick={handleClaimClaimableBalance}
                          disabled={!props.isUserSignatoryOnAccount}
                          variant="outlined"
                          children="try again"
                        />
                      </Box>
                    </Tooltip>
                  )}
                </Box>
              );

            case OverlayMessageType.Loading:
              return <CircularProgress size={45} />;

            default:
              return "";
          }
        })()}
      </Backdrop>
      <TokenIconViewUpload size={60} token={props.model.amount.token} />
      {!isMobile && (
        <Grid>
          {/* first row */}
          <Grid
            sx={(theme) => ({
              borderBottom: `1px solid ${theme.palette.divider}`,
              marginBottom: theme.spacing(0.5),
              paddingBottom: theme.spacing(0.5),
            })}
            alignItems="center"
            container
          >
            <Grid item xs={5}>
              <TextField
                id="claimableBalance-name-textfield"
                readOnly
                fullWidth
                disabled={overLayLoadingText !== OverlayMessageType.Undefined}
                label="Name"
                value={props.model.name}
              />
            </Grid>
            <Grid item xs={4}>
              <TextField
                id="claimableBalance-issuer-textfield"
                readOnly
                disabled={overLayLoadingText !== OverlayMessageType.Undefined}
                fullWidth
                label="Issuer"
                value={props.model.issuerName}
              />
            </Grid>
            <Grid
              sx={{
                display: "flex",
                justifyContent: "flex-end",
              }}
              item
              xs={3}
            >
              <Tooltip
                title={
                  !props.isUserSignatoryOnAccount
                    ? "You are not a signatory on this account"
                    : ""
                }
                placement="top"
              >
                <Box component={"span"}>
                  <Button
                    ref={actionButtonElRef}
                    sx={{
                      height: "36px",
                      width: "119px",
                      alignSelf: "center",
                    }}
                    id="claimableBalance-actions-buttons"
                    onClick={() => setOpenMenu(true)}
                    disabled={
                      overLayLoadingText !== OverlayMessageType.Undefined ||
                      !props.isUserSignatoryOnAccount
                    }
                    startIcon={<MoreVertOutlined />}
                    variant="outlined"
                    children="actions"
                  />
                </Box>
              </Tooltip>
            </Grid>
          </Grid>
          {/* second row */}
          <Grid
            sx={(theme) => ({
              borderBottom: `1px solid ${theme.palette.divider}`,
              marginBottom: theme.spacing(0.5),
              paddingBottom: theme.spacing(0.5),
            })}
            container
          >
            <Grid
              sx={{
                height: 43.5,
                alignSelf: "center",
              }}
              item
              xs={5}
            >
              <Typography
                sx={{
                  fontSize: 10,
                }}
                color="textSecondary"
                variant="caption"
              >
                Claimable Balance
              </Typography>
              <Amount
                reverse={
                  props.model.claimableBalanceTokenCategory.includes(
                    TokenCategory.InstrumentStablecoin,
                  ) ||
                  props.model.claimableBalanceTokenCategory.includes(
                    TokenCategory.DigitalInstrument,
                  )
                }
                amount={props.model.amount}
              />
            </Grid>
            <Grid item xs={7}>
              <TextField
                id="claimableBalance-issuer-textfield"
                readOnly
                fullWidth
                disabled={overLayLoadingText !== OverlayMessageType.Undefined}
                label="Sender"
                value={props.model.claimableBalanceSource}
              />
            </Grid>
          </Grid>
          {/* third row */}
          <Grid container>
            <Grid item xs={12}>
              <TextField
                id="claimableBalance-reference-textfield"
                readOnly
                fullWidth
                disabled={overLayLoadingText !== OverlayMessageType.Undefined}
                label="Reference"
                value={props.model.reference ? props.model.reference : "-"}
              />
            </Grid>
          </Grid>
          {/* Actions button menu */}
          <Menu
            id="claimableBalance-actionButton-menu"
            anchorEl={
              isMobile
                ? actionsVertButtonRef.current
                : actionButtonElRef.current
            }
            open={openMenu}
            onClose={() => setOpenMenu(!openMenu)}
            autoFocus={false}
          >
            {viewConfiguration.StellarClaimableBalanceClaimer && (
              <MenuItem
                key={1}
                id="claimableBalance-claimClaimableBalance-menuItem"
                onClick={() => {
                  handleClaimClaimableBalance().finally();
                  setOpenMenu(false);
                }}
              >
                Claim
              </MenuItem>
            )}
            {viewConfiguration.StellarClaimableBalanceArchiver && (
              <MenuItem
                key={2}
                id="claimableBalance-hideClaimableBalance-menuItem"
                onClick={() => {
                  handleArchiveClaimableBalance().finally();
                  setOpenMenu(false);
                }}
              >
                Hide
              </MenuItem>
            )}
          </Menu>
        </Grid>
      )}
      {isMobile && (
        <Box>
          {/* first row */}
          <Grid
            sx={(theme) => ({
              borderBottom: `1px solid ${theme.palette.divider}`,
              marginBottom: theme.spacing(0.5),
              paddingBottom: theme.spacing(0.5),
            })}
            container
          >
            <Grid
              sx={(theme) => ({
                borderBottom: `1px solid ${theme.palette.divider}`,
                display: "flex",
                flexDirection: "row",
                marginBottom: theme.spacing(0.5),
                paddingBottom: theme.spacing(0.5),
              })}
              item
              xs={12}
            >
              <TextField
                id="claimableBalance-name-textfield"
                readOnly
                fullWidth
                disabled={overLayLoadingText !== OverlayMessageType.Undefined}
                label="Name"
                value={props.model.name}
              />
              <Grid
                sx={{
                  display: "flex",
                  justifyContent: "flex-end",
                }}
                item
                xs={3}
              >
                <Tooltip
                  title={
                    !props.isUserSignatoryOnAccount
                      ? "You are not a signatory on this account"
                      : ""
                  }
                  placement="top"
                >
                  <span>
                    <IconButton
                      id="claimableBalance-actions-smButton"
                      size="large"
                      sx={{ color: "white" }}
                      ref={actionsVertButtonRef}
                      onClick={() => setOpenMenu(true)}
                      disabled={
                        overLayLoadingText !== OverlayMessageType.Undefined ||
                        !props.isUserSignatoryOnAccount
                      }
                    >
                      <MoreVert />
                    </IconButton>
                  </span>
                </Tooltip>
              </Grid>
            </Grid>
            <Grid item xs={8}>
              <TextField
                id="claimableBalance-issuer-textfield"
                readOnly
                disabled={overLayLoadingText !== OverlayMessageType.Undefined}
                fullWidth
                label="Issuer"
                value={props.model.issuerName}
              />
            </Grid>
          </Grid>
          {/* second row */}
          <Grid
            sx={(theme) => ({
              borderBottom: `1px solid ${theme.palette.divider}`,
              marginBottom: theme.spacing(0.5),
              paddingBottom: theme.spacing(0.5),
            })}
            container
          >
            <Grid
              sx={(theme) => ({
                borderBottom: `1px solid ${theme.palette.divider}`,
                marginBottom: theme.spacing(1),
                paddingBottom: theme.spacing(1.5),
              })}
              item
              xs={12}
            >
              <Typography
                sx={{
                  fontSize: 10,
                }}
                color="textSecondary"
                variant="caption"
              >
                Claimable Balance
              </Typography>
              <Amount
                reverse={
                  props.model.claimableBalanceTokenCategory.includes(
                    TokenCategory.InstrumentStablecoin,
                  ) ||
                  props.model.claimableBalanceTokenCategory.includes(
                    TokenCategory.DigitalInstrument,
                  )
                }
                amount={props.model.amount}
              />
            </Grid>
            <Grid item xs={8}>
              <TextField
                id="claimableBalance-issuer-textfield"
                readOnly
                fullWidth
                disabled={overLayLoadingText !== OverlayMessageType.Undefined}
                label="Sender"
                value={props.model.claimableBalanceSource}
              />
            </Grid>
          </Grid>
          {/* third row */}
          <Grid container>
            <Grid item xs={12}>
              <TextField
                id="claimableBalance-reference-textfield"
                readOnly
                fullWidth
                disabled={overLayLoadingText !== OverlayMessageType.Undefined}
                label="Reference"
                value={props.model.reference ? props.model.reference : "-"}
              />
            </Grid>
          </Grid>
          {/* Actions button menu */}
          <Menu
            id="claimableBalance-actionButton-menu"
            anchorEl={
              isMobile
                ? actionsVertButtonRef.current
                : actionButtonElRef.current
            }
            open={openMenu}
            onClose={() => setOpenMenu(!openMenu)}
            autoFocus={false}
          >
            {viewConfiguration.StellarClaimableBalanceClaimer && (
              <MenuItem
                key={1}
                id="claimableBalance-claimClaimableBalance-menuItem"
                onClick={() => {
                  handleClaimClaimableBalance().finally();
                  setOpenMenu(false);
                }}
              >
                Claim
              </MenuItem>
            )}
            {viewConfiguration.StellarClaimableBalanceArchiver && (
              <MenuItem
                key={2}
                id="claimableBalance-hideClaimableBalance-menuItem"
                onClick={() => {
                  handleArchiveClaimableBalance().finally();
                  setOpenMenu(false);
                }}
              >
                Hide
              </MenuItem>
            )}
          </Menu>
        </Box>
      )}
    </Box>
  );
}

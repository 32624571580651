import { AllStellarNetworks, StellarNetwork } from "james/stellar/Network";
import { AllBitcoinNetworks, BitcoinNetwork } from "james/bitcoin/Network";
import { AllLitecoinNetworks, LitecoinNetwork } from "james/litecoin/Network";
import { AllEthereumNetworks, EthereumNetwork } from "james/ethereum/Network";
import { AllRippleNetworks, RippleNetwork } from "james/ripple/Network";
import { AllCTSENetworks, CTSENetwork } from "james/ctse";
import {
  AllInteractiveBrokersNetworks,
  SAStockBrokersNetwork,
} from "james/interactiveBrokers";
import { FutureNetwork } from "@mesh/common-js/dist/ledger/futureNetwork_pb";

export type LedgerNetwork =
  | "-"
  | StellarNetwork
  | BitcoinNetwork
  | CTSENetwork
  | SAStockBrokersNetwork
  | LitecoinNetwork
  | EthereumNetwork
  | RippleNetwork;

export const AllLedgerNetworks: LedgerNetwork[] = [
  ...AllStellarNetworks,
  ...AllBitcoinNetworks,
  ...AllCTSENetworks,
  ...AllInteractiveBrokersNetworks,
  ...AllLitecoinNetworks,
  ...AllEthereumNetworks,
  ...AllRippleNetworks,
];

// Mapping from futureNetwork to Network
const futureNetworkToNetworkMapping: {
  [key in FutureNetwork]: LedgerNetwork;
} = {
  [FutureNetwork.UNDEFINED_NETWORK]: "-",
  [FutureNetwork.MESH_X_AGGREGATION_NETWORK]: "-",
  [FutureNetwork.STELLAR_TEST_SDF_NETWORK]: StellarNetwork.TestSDFNetwork,
  [FutureNetwork.STELLAR_PUBLIC_NETWORK]: StellarNetwork.PublicNetwork,
  [FutureNetwork.BITCOIN_TEST_NETWORK]: BitcoinNetwork.TestNetwork,
  [FutureNetwork.BITCOIN_PUBLIC_NETWORK]: BitcoinNetwork.PublicNetwork,
  [FutureNetwork.LITECOIN_TEST_NETWORK]: LitecoinNetwork.TestNetwork,
  [FutureNetwork.LITECOIN_PUBLIC_NETWORK]: LitecoinNetwork.PublicNetwork,
  [FutureNetwork.ETHEREUM_TEST_NETWORK]: EthereumNetwork.TestNetwork,
  [FutureNetwork.ETHEREUM_PUBLIC_NETWORK]: EthereumNetwork.PublicNetwork,
  [FutureNetwork.RIPPLE_TEST_NETWORK]: RippleNetwork.TestNetwork,
  [FutureNetwork.RIPPLE_PUBLIC_NETWORK]: RippleNetwork.PublicNetwork,
  [FutureNetwork.CTSE_TEST_NETWORK]: CTSENetwork.TestNetwork,
  [FutureNetwork.CTSE_PRODUCTION_NETWORK]: CTSENetwork.ProductionNetwork,
  [FutureNetwork.SA_STOCK_BROKERS_TEST_NETWORK]:
    SAStockBrokersNetwork.TestNetwork,
  [FutureNetwork.SA_STOCK_BROKERS_PRODUCTION_NETWORK]:
    SAStockBrokersNetwork.ProductionNetwork,
  [FutureNetwork.NULL_NETWORK]: "-",
};

// Reverse mapping from Network to FutureNetwork
const networkToFutureNetworkMapping: Record<string, FutureNetwork> = {};
for (const [key, value] of Object.entries(futureNetworkToNetworkMapping)) {
  networkToFutureNetworkMapping[value] = Number(key) as FutureNetwork;
}

export function networkFromFutureNetwork(
  futureNetwork: FutureNetwork,
): LedgerNetwork {
  if (futureNetwork in futureNetworkToNetworkMapping) {
    return futureNetworkToNetworkMapping[futureNetwork];
  } else {
    throw TypeError(`unsupported future network: ${futureNetwork}`);
  }
}

export function networkToFutureNetwork(network: LedgerNetwork): FutureNetwork {
  if (network in networkToFutureNetworkMapping) {
    return networkToFutureNetworkMapping[network];
  } else {
    throw TypeError(`unsupported network: ${network}`);
  }
}

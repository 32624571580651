// source: market/assetTransferrer.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global =
    (typeof globalThis !== 'undefined' && globalThis) ||
    (typeof window !== 'undefined' && window) ||
    (typeof global !== 'undefined' && global) ||
    (typeof self !== 'undefined' && self) ||
    (function () { return this; }).call(null) ||
    Function('return this')();

var security_context_pb = require('../security/context_pb.js');
goog.object.extend(proto, security_context_pb);
var ledger_futureAmount_pb = require('../ledger/futureAmount_pb.js');
goog.object.extend(proto, ledger_futureAmount_pb);
goog.exportSymbol('proto.market.TransferAssetOffPlatformRequest', null, global);
goog.exportSymbol('proto.market.TransferAssetOffPlatformResponse', null, global);
goog.exportSymbol('proto.market.TransferAssetRequest', null, global);
goog.exportSymbol('proto.market.TransferAssetRequest.ToaccountidentifierCase', null, global);
goog.exportSymbol('proto.market.TransferAssetResponse', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.market.TransferAssetRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.market.TransferAssetRequest.oneofGroups_);
};
goog.inherits(proto.market.TransferAssetRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.market.TransferAssetRequest.displayName = 'proto.market.TransferAssetRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.market.TransferAssetResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.market.TransferAssetResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.market.TransferAssetResponse.displayName = 'proto.market.TransferAssetResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.market.TransferAssetOffPlatformRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.market.TransferAssetOffPlatformRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.market.TransferAssetOffPlatformRequest.displayName = 'proto.market.TransferAssetOffPlatformRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.market.TransferAssetOffPlatformResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.market.TransferAssetOffPlatformResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.market.TransferAssetOffPlatformResponse.displayName = 'proto.market.TransferAssetOffPlatformResponse';
}

/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.market.TransferAssetRequest.oneofGroups_ = [[3,4,5]];

/**
 * @enum {number}
 */
proto.market.TransferAssetRequest.ToaccountidentifierCase = {
  TOACCOUNTIDENTIFIER_NOT_SET: 0,
  MESHACCOUNTID: 3,
  MESHACCOUNTNUMBER: 4,
  LEDGERACCOUNTID: 5
};

/**
 * @return {proto.market.TransferAssetRequest.ToaccountidentifierCase}
 */
proto.market.TransferAssetRequest.prototype.getToaccountidentifierCase = function() {
  return /** @type {proto.market.TransferAssetRequest.ToaccountidentifierCase} */(jspb.Message.computeOneofCase(this, proto.market.TransferAssetRequest.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.market.TransferAssetRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.market.TransferAssetRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.market.TransferAssetRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.market.TransferAssetRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
context: (f = msg.getContext()) && security_context_pb.ContextFuture.toObject(includeInstance, f),
fromaccountid: jspb.Message.getFieldWithDefault(msg, 2, ""),
meshaccountid: (f = jspb.Message.getField(msg, 3)) == null ? undefined : f,
meshaccountnumber: (f = jspb.Message.getField(msg, 4)) == null ? undefined : f,
ledgeraccountid: (f = jspb.Message.getField(msg, 5)) == null ? undefined : f,
amount: (f = msg.getAmount()) && ledger_futureAmount_pb.FutureAmount.toObject(includeInstance, f),
reference: jspb.Message.getFieldWithDefault(msg, 7, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.market.TransferAssetRequest}
 */
proto.market.TransferAssetRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.market.TransferAssetRequest;
  return proto.market.TransferAssetRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.market.TransferAssetRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.market.TransferAssetRequest}
 */
proto.market.TransferAssetRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new security_context_pb.ContextFuture;
      reader.readMessage(value,security_context_pb.ContextFuture.deserializeBinaryFromReader);
      msg.setContext(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setFromaccountid(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setMeshaccountid(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setMeshaccountnumber(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setLedgeraccountid(value);
      break;
    case 6:
      var value = new ledger_futureAmount_pb.FutureAmount;
      reader.readMessage(value,ledger_futureAmount_pb.FutureAmount.deserializeBinaryFromReader);
      msg.setAmount(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setReference(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.market.TransferAssetRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.market.TransferAssetRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.market.TransferAssetRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.market.TransferAssetRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getContext();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      security_context_pb.ContextFuture.serializeBinaryToWriter
    );
  }
  f = message.getFromaccountid();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 3));
  if (f != null) {
    writer.writeString(
      3,
      f
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 4));
  if (f != null) {
    writer.writeString(
      4,
      f
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 5));
  if (f != null) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getAmount();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      ledger_futureAmount_pb.FutureAmount.serializeBinaryToWriter
    );
  }
  f = message.getReference();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
};


/**
 * optional security.ContextFuture context = 1;
 * @return {?proto.security.ContextFuture}
 */
proto.market.TransferAssetRequest.prototype.getContext = function() {
  return /** @type{?proto.security.ContextFuture} */ (
    jspb.Message.getWrapperField(this, security_context_pb.ContextFuture, 1));
};


/**
 * @param {?proto.security.ContextFuture|undefined} value
 * @return {!proto.market.TransferAssetRequest} returns this
*/
proto.market.TransferAssetRequest.prototype.setContext = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.market.TransferAssetRequest} returns this
 */
proto.market.TransferAssetRequest.prototype.clearContext = function() {
  return this.setContext(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.market.TransferAssetRequest.prototype.hasContext = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional string fromAccountID = 2;
 * @return {string}
 */
proto.market.TransferAssetRequest.prototype.getFromaccountid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.market.TransferAssetRequest} returns this
 */
proto.market.TransferAssetRequest.prototype.setFromaccountid = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string meshAccountID = 3;
 * @return {string}
 */
proto.market.TransferAssetRequest.prototype.getMeshaccountid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.market.TransferAssetRequest} returns this
 */
proto.market.TransferAssetRequest.prototype.setMeshaccountid = function(value) {
  return jspb.Message.setOneofField(this, 3, proto.market.TransferAssetRequest.oneofGroups_[0], value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.market.TransferAssetRequest} returns this
 */
proto.market.TransferAssetRequest.prototype.clearMeshaccountid = function() {
  return jspb.Message.setOneofField(this, 3, proto.market.TransferAssetRequest.oneofGroups_[0], undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.market.TransferAssetRequest.prototype.hasMeshaccountid = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional string meshAccountNumber = 4;
 * @return {string}
 */
proto.market.TransferAssetRequest.prototype.getMeshaccountnumber = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.market.TransferAssetRequest} returns this
 */
proto.market.TransferAssetRequest.prototype.setMeshaccountnumber = function(value) {
  return jspb.Message.setOneofField(this, 4, proto.market.TransferAssetRequest.oneofGroups_[0], value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.market.TransferAssetRequest} returns this
 */
proto.market.TransferAssetRequest.prototype.clearMeshaccountnumber = function() {
  return jspb.Message.setOneofField(this, 4, proto.market.TransferAssetRequest.oneofGroups_[0], undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.market.TransferAssetRequest.prototype.hasMeshaccountnumber = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional string ledgerAccountID = 5;
 * @return {string}
 */
proto.market.TransferAssetRequest.prototype.getLedgeraccountid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.market.TransferAssetRequest} returns this
 */
proto.market.TransferAssetRequest.prototype.setLedgeraccountid = function(value) {
  return jspb.Message.setOneofField(this, 5, proto.market.TransferAssetRequest.oneofGroups_[0], value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.market.TransferAssetRequest} returns this
 */
proto.market.TransferAssetRequest.prototype.clearLedgeraccountid = function() {
  return jspb.Message.setOneofField(this, 5, proto.market.TransferAssetRequest.oneofGroups_[0], undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.market.TransferAssetRequest.prototype.hasLedgeraccountid = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional ledger.FutureAmount amount = 6;
 * @return {?proto.ledger.FutureAmount}
 */
proto.market.TransferAssetRequest.prototype.getAmount = function() {
  return /** @type{?proto.ledger.FutureAmount} */ (
    jspb.Message.getWrapperField(this, ledger_futureAmount_pb.FutureAmount, 6));
};


/**
 * @param {?proto.ledger.FutureAmount|undefined} value
 * @return {!proto.market.TransferAssetRequest} returns this
*/
proto.market.TransferAssetRequest.prototype.setAmount = function(value) {
  return jspb.Message.setWrapperField(this, 6, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.market.TransferAssetRequest} returns this
 */
proto.market.TransferAssetRequest.prototype.clearAmount = function() {
  return this.setAmount(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.market.TransferAssetRequest.prototype.hasAmount = function() {
  return jspb.Message.getField(this, 6) != null;
};


/**
 * optional string reference = 7;
 * @return {string}
 */
proto.market.TransferAssetRequest.prototype.getReference = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.market.TransferAssetRequest} returns this
 */
proto.market.TransferAssetRequest.prototype.setReference = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.market.TransferAssetResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.market.TransferAssetResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.market.TransferAssetResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.market.TransferAssetResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
transactionid: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.market.TransferAssetResponse}
 */
proto.market.TransferAssetResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.market.TransferAssetResponse;
  return proto.market.TransferAssetResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.market.TransferAssetResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.market.TransferAssetResponse}
 */
proto.market.TransferAssetResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setTransactionid(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.market.TransferAssetResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.market.TransferAssetResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.market.TransferAssetResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.market.TransferAssetResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTransactionid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string transactionID = 1;
 * @return {string}
 */
proto.market.TransferAssetResponse.prototype.getTransactionid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.market.TransferAssetResponse} returns this
 */
proto.market.TransferAssetResponse.prototype.setTransactionid = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.market.TransferAssetOffPlatformRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.market.TransferAssetOffPlatformRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.market.TransferAssetOffPlatformRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.market.TransferAssetOffPlatformRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
context: (f = msg.getContext()) && security_context_pb.ContextFuture.toObject(includeInstance, f),
fromaccountid: jspb.Message.getFieldWithDefault(msg, 2, ""),
recipientid: jspb.Message.getFieldWithDefault(msg, 3, ""),
recipientlabel: jspb.Message.getFieldWithDefault(msg, 4, ""),
amount: (f = msg.getAmount()) && ledger_futureAmount_pb.FutureAmount.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.market.TransferAssetOffPlatformRequest}
 */
proto.market.TransferAssetOffPlatformRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.market.TransferAssetOffPlatformRequest;
  return proto.market.TransferAssetOffPlatformRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.market.TransferAssetOffPlatformRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.market.TransferAssetOffPlatformRequest}
 */
proto.market.TransferAssetOffPlatformRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new security_context_pb.ContextFuture;
      reader.readMessage(value,security_context_pb.ContextFuture.deserializeBinaryFromReader);
      msg.setContext(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setFromaccountid(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setRecipientid(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setRecipientlabel(value);
      break;
    case 5:
      var value = new ledger_futureAmount_pb.FutureAmount;
      reader.readMessage(value,ledger_futureAmount_pb.FutureAmount.deserializeBinaryFromReader);
      msg.setAmount(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.market.TransferAssetOffPlatformRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.market.TransferAssetOffPlatformRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.market.TransferAssetOffPlatformRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.market.TransferAssetOffPlatformRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getContext();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      security_context_pb.ContextFuture.serializeBinaryToWriter
    );
  }
  f = message.getFromaccountid();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getRecipientid();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getRecipientlabel();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getAmount();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      ledger_futureAmount_pb.FutureAmount.serializeBinaryToWriter
    );
  }
};


/**
 * optional security.ContextFuture context = 1;
 * @return {?proto.security.ContextFuture}
 */
proto.market.TransferAssetOffPlatformRequest.prototype.getContext = function() {
  return /** @type{?proto.security.ContextFuture} */ (
    jspb.Message.getWrapperField(this, security_context_pb.ContextFuture, 1));
};


/**
 * @param {?proto.security.ContextFuture|undefined} value
 * @return {!proto.market.TransferAssetOffPlatformRequest} returns this
*/
proto.market.TransferAssetOffPlatformRequest.prototype.setContext = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.market.TransferAssetOffPlatformRequest} returns this
 */
proto.market.TransferAssetOffPlatformRequest.prototype.clearContext = function() {
  return this.setContext(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.market.TransferAssetOffPlatformRequest.prototype.hasContext = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional string fromAccountID = 2;
 * @return {string}
 */
proto.market.TransferAssetOffPlatformRequest.prototype.getFromaccountid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.market.TransferAssetOffPlatformRequest} returns this
 */
proto.market.TransferAssetOffPlatformRequest.prototype.setFromaccountid = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string recipientID = 3;
 * @return {string}
 */
proto.market.TransferAssetOffPlatformRequest.prototype.getRecipientid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.market.TransferAssetOffPlatformRequest} returns this
 */
proto.market.TransferAssetOffPlatformRequest.prototype.setRecipientid = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string recipientLabel = 4;
 * @return {string}
 */
proto.market.TransferAssetOffPlatformRequest.prototype.getRecipientlabel = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.market.TransferAssetOffPlatformRequest} returns this
 */
proto.market.TransferAssetOffPlatformRequest.prototype.setRecipientlabel = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional ledger.FutureAmount amount = 5;
 * @return {?proto.ledger.FutureAmount}
 */
proto.market.TransferAssetOffPlatformRequest.prototype.getAmount = function() {
  return /** @type{?proto.ledger.FutureAmount} */ (
    jspb.Message.getWrapperField(this, ledger_futureAmount_pb.FutureAmount, 5));
};


/**
 * @param {?proto.ledger.FutureAmount|undefined} value
 * @return {!proto.market.TransferAssetOffPlatformRequest} returns this
*/
proto.market.TransferAssetOffPlatformRequest.prototype.setAmount = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.market.TransferAssetOffPlatformRequest} returns this
 */
proto.market.TransferAssetOffPlatformRequest.prototype.clearAmount = function() {
  return this.setAmount(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.market.TransferAssetOffPlatformRequest.prototype.hasAmount = function() {
  return jspb.Message.getField(this, 5) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.market.TransferAssetOffPlatformResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.market.TransferAssetOffPlatformResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.market.TransferAssetOffPlatformResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.market.TransferAssetOffPlatformResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
transactionid: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.market.TransferAssetOffPlatformResponse}
 */
proto.market.TransferAssetOffPlatformResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.market.TransferAssetOffPlatformResponse;
  return proto.market.TransferAssetOffPlatformResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.market.TransferAssetOffPlatformResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.market.TransferAssetOffPlatformResponse}
 */
proto.market.TransferAssetOffPlatformResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setTransactionid(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.market.TransferAssetOffPlatformResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.market.TransferAssetOffPlatformResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.market.TransferAssetOffPlatformResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.market.TransferAssetOffPlatformResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTransactionid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string transactionID = 1;
 * @return {string}
 */
proto.market.TransferAssetOffPlatformResponse.prototype.getTransactionid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.market.TransferAssetOffPlatformResponse} returns this
 */
proto.market.TransferAssetOffPlatformResponse.prototype.setTransactionid = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


goog.object.extend(exports, proto.market);

import React from "react";
import { Model as MarketSubscriptionOrderViewModel } from "james/views/marketSubscriptionOrderView";
import { Box, Skeleton, Typography } from "@mui/material";
import { SubscriptionOrdersCard } from "./components/SubscriptionOrdersCard/SubscriptionOrdersCard";
import { Model } from "@mesh/common-js/dist/views/stellarAccountView/model_pb";

interface OrdersSubscriptionsProps {
  subscriptionOrders?: MarketSubscriptionOrderViewModel[];
  accountModel?: Model;
}

export function SubscriptionOrders(props: OrdersSubscriptionsProps) {
  return (
    <Box>
      <Box
        sx={(theme) => ({
          width: "100%",
          [theme.breakpoints.down("sm")]: {
            padding: theme.spacing(0, 2),
          },
        })}
      >
        {props.accountModel ? (
          <Typography
            sx={(theme) => ({
              color: theme.palette.custom.mildYellow,
              [theme.breakpoints.down("sm")]: {
                padding: theme.spacing(3, 0, 1, 1),
              },
              [theme.breakpoints.up("sm")]: {
                padding: theme.spacing(3, 0, 1),
              },
            })}
            variant="h6"
            children="Open Orders"
          />
        ) : (
          <Skeleton width="20%" />
        )}
        {props.subscriptionOrders && props.subscriptionOrders.length > 0 && (
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            {props.subscriptionOrders.map((s, idx) => (
              <SubscriptionOrdersCard
                key={`ordersSubscriptionsCard-${idx}`}
                subscriptionOrder={s}
              />
            ))}
          </Box>
        )}
      </Box>
    </Box>
  );
}

import React, { useEffect, useMemo, useState } from "react";
import { styled } from "@mui/material/styles";
import { Amount as LedgerAmount } from "james/ledger/Amount";
import {
  Box,
  Skeleton,
  Tooltip,
  Typography,
  TypographyProps,
} from "@mui/material";
import { formatTextNum, FormatTextNumOpts } from "utilities/number";
import { useLedgerTokenViewContext } from "context/LedgerTokenView";
import { Model as LedgerTokenViewModel } from "james/views/ledgerTokenView";
import { useIsMounted } from "hooks";
import { SystemStyleObject } from "@mui/system/styleFunctionSx/styleFunctionSx";
import cx from "classnames";
import { useErrorContext } from "context/Error";
import { FutureAmount } from "@mesh/common-js/dist/ledger/futureAmount_pb";
import { Amount as StellarAmount } from "@mesh/common-js/dist/stellar/amount_pb";

const PREFIX = "Amount";

const classes = {
  tokenAmountIssuer: `${PREFIX}-tokenAmountIssuer`,
};

const StyledBox = styled(Box)(({ theme }) => ({
  [`& .${classes.tokenAmountIssuer}`]: {
    cursor: "pointer",
    "&:hover": {
      color: theme.palette.primary.light,
    },
  },
}));

export interface AmountProps {
  id?: string;
  reverse?: boolean;
  amount?: LedgerAmount | FutureAmount | StellarAmount;
  rootStyles?: SystemStyleObject;
  skeletonStyles?: SystemStyleObject;
  formatTextNumOpts?: FormatTextNumOpts;
  codeTypographyProps?: TypographyProps;
  valueTypographyProps?: TypographyProps;
}

export function Amount(props: AmountProps) {
  const _amount = useMemo<LedgerAmount>(() => {
    if (!props.amount) {
      return new LedgerAmount();
    }
    if (props.amount instanceof LedgerAmount) {
      return props.amount as LedgerAmount;
    } else if (props.amount instanceof FutureAmount) {
      return LedgerAmount.fromFutureAmount(props.amount);
    } else {
      throw new TypeError(`unknown amount type: ${props.amount}`);
    }
  }, [props.amount]);

  const { errorContextErrorTranslator } = useErrorContext();
  const isMounted = useIsMounted();
  const { getLedgerTokenViewModel } = useLedgerTokenViewContext();
  const [model, setModel] = useState<LedgerTokenViewModel>(
    new LedgerTokenViewModel(),
  );

  useEffect(() => {
    if (!isMounted()) {
      return;
    }
    (async () => {
      // do not attempt to retrieve the view model for
      // an undefined ledger amount
      if (_amount.isUndefined()) {
        return;
      }

      try {
        const retrievedModel = await getLedgerTokenViewModel(_amount.token);
        if (isMounted()) {
          setModel(retrievedModel);
        }
      } catch (e) {
        const err = errorContextErrorTranslator.translateError(e);
        console.error(
          `error getting ledger on platform token view model: ${
            err.message ? err.message : err.toString()
          }`,
        );
      }
    })();
  }, [getLedgerTokenViewModel, isMounted, _amount]);

  // as long as model ID is not set
  // return a skeleton loader
  if (!model.id) {
    return <Skeleton sx={props.skeletonStyles} width={100} height={19} />;
  }

  const formatTokenCode = (code: string) => {
    if (code.length > 7) {
      const start = code.substring(0, 2); // First two characters
      const end = code.substring(code.length - 2); // Last two characters
      return `${start}...${end}`;
    }
    return code;
  };

  const generateToolTip = (code: string) => {
    if (code.length > 7) {
      return `${code} Issued by ${model.issuer}`;
    }
    return `Issued by ${model.issuer}`;
  };

  const nodes = [
    <Tooltip
      key={`ledger-code-tooltip-${model.id}`}
      title={generateToolTip(model.token.code)}
      placement="top-start"
    >
      <Typography
        id={props.id ? `${props.id}-code` : "ledger-amount-code"}
        variant="body1"
        className={cx(classes.tokenAmountIssuer, "codeTypography")}
        children={formatTokenCode(model.token.code)}
        {...props.codeTypographyProps}
      />
    </Tooltip>,
    <Typography
      className="valueTypography"
      id={props.id ? `${props.id}-value` : "ledger-amount-value"}
      key={`ledger-amount-value-${model.id}`}
      variant="body1"
      children={formatTextNum(_amount.value, {
        addDecimalPadding: true,
        ...props.formatTextNumOpts,
      })}
      {...props.valueTypographyProps}
    />,
  ];

  return (
    <StyledBox
      sx={[
        {
          display: "grid",
          gridTemplateColumns: "auto 1fr",
          columnGap: (theme) => theme.spacing(0.5),
          alignItems: "center",
        },
        !!props.rootStyles && { ...props.rootStyles },
      ]}
    >
      {props.reverse ? nodes.reverse() : nodes}
    </StyledBox>
  );
}

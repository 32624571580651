/**
 * @fileoverview gRPC-Web generated client stub for stellar
 * @enhanceable
 * @public
 */

// Code generated by protoc-gen-grpc-web. DO NOT EDIT.
// versions:
// 	protoc-gen-grpc-web v1.5.0
// 	protoc              v5.28.0
// source: stellar/accountInspector.proto


/* eslint-disable */
// @ts-nocheck



const grpc = {};
grpc.web = require('grpc-web');


var security_context_pb = require('../security/context_pb.js')

var search_criterion_pb = require('../search/criterion_pb.js')

var search_query_pb = require('../search/query_pb.js')

var search_sorting_pb = require('../search/sorting_pb.js')

var stellar_enrichedAccount_pb = require('../stellar/enrichedAccount_pb.js')
const proto = {};
proto.stellar = require('./accountInspector_pb.js');

/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?grpc.web.ClientOptions} options
 * @constructor
 * @struct
 * @final
 */
proto.stellar.AccountInspectorClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options.format = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname.replace(/\/+$/, '');

};


/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?grpc.web.ClientOptions} options
 * @constructor
 * @struct
 * @final
 */
proto.stellar.AccountInspectorPromiseClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options.format = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname.replace(/\/+$/, '');

};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.stellar.InspectRetrievedAccountRequest,
 *   !proto.stellar.InspectRetrievedAccountResponse>}
 */
const methodDescriptor_AccountInspector_InspectRetrievedAccount = new grpc.web.MethodDescriptor(
  '/stellar.AccountInspector/InspectRetrievedAccount',
  grpc.web.MethodType.UNARY,
  proto.stellar.InspectRetrievedAccountRequest,
  proto.stellar.InspectRetrievedAccountResponse,
  /**
   * @param {!proto.stellar.InspectRetrievedAccountRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.stellar.InspectRetrievedAccountResponse.deserializeBinary
);


/**
 * @param {!proto.stellar.InspectRetrievedAccountRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.stellar.InspectRetrievedAccountResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.stellar.InspectRetrievedAccountResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.stellar.AccountInspectorClient.prototype.inspectRetrievedAccount =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/stellar.AccountInspector/InspectRetrievedAccount',
      request,
      metadata || {},
      methodDescriptor_AccountInspector_InspectRetrievedAccount,
      callback);
};


/**
 * @param {!proto.stellar.InspectRetrievedAccountRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.stellar.InspectRetrievedAccountResponse>}
 *     Promise that resolves to the response
 */
proto.stellar.AccountInspectorPromiseClient.prototype.inspectRetrievedAccount =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/stellar.AccountInspector/InspectRetrievedAccount',
      request,
      metadata || {},
      methodDescriptor_AccountInspector_InspectRetrievedAccount);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.stellar.InspectSearchedAccountsRequest,
 *   !proto.stellar.InspectSearchedAccountsResponse>}
 */
const methodDescriptor_AccountInspector_InspectSearchedAccounts = new grpc.web.MethodDescriptor(
  '/stellar.AccountInspector/InspectSearchedAccounts',
  grpc.web.MethodType.UNARY,
  proto.stellar.InspectSearchedAccountsRequest,
  proto.stellar.InspectSearchedAccountsResponse,
  /**
   * @param {!proto.stellar.InspectSearchedAccountsRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.stellar.InspectSearchedAccountsResponse.deserializeBinary
);


/**
 * @param {!proto.stellar.InspectSearchedAccountsRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.stellar.InspectSearchedAccountsResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.stellar.InspectSearchedAccountsResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.stellar.AccountInspectorClient.prototype.inspectSearchedAccounts =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/stellar.AccountInspector/InspectSearchedAccounts',
      request,
      metadata || {},
      methodDescriptor_AccountInspector_InspectSearchedAccounts,
      callback);
};


/**
 * @param {!proto.stellar.InspectSearchedAccountsRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.stellar.InspectSearchedAccountsResponse>}
 *     Promise that resolves to the response
 */
proto.stellar.AccountInspectorPromiseClient.prototype.inspectSearchedAccounts =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/stellar.AccountInspector/InspectSearchedAccounts',
      request,
      metadata || {},
      methodDescriptor_AccountInspector_InspectSearchedAccounts);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.stellar.CheckAccountExistsRequest,
 *   !proto.stellar.CheckAccountExistsResponse>}
 */
const methodDescriptor_AccountInspector_CheckAccountExists = new grpc.web.MethodDescriptor(
  '/stellar.AccountInspector/CheckAccountExists',
  grpc.web.MethodType.UNARY,
  proto.stellar.CheckAccountExistsRequest,
  proto.stellar.CheckAccountExistsResponse,
  /**
   * @param {!proto.stellar.CheckAccountExistsRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.stellar.CheckAccountExistsResponse.deserializeBinary
);


/**
 * @param {!proto.stellar.CheckAccountExistsRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.stellar.CheckAccountExistsResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.stellar.CheckAccountExistsResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.stellar.AccountInspectorClient.prototype.checkAccountExists =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/stellar.AccountInspector/CheckAccountExists',
      request,
      metadata || {},
      methodDescriptor_AccountInspector_CheckAccountExists,
      callback);
};


/**
 * @param {!proto.stellar.CheckAccountExistsRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.stellar.CheckAccountExistsResponse>}
 *     Promise that resolves to the response
 */
proto.stellar.AccountInspectorPromiseClient.prototype.checkAccountExists =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/stellar.AccountInspector/CheckAccountExists',
      request,
      metadata || {},
      methodDescriptor_AccountInspector_CheckAccountExists);
};


module.exports = proto.stellar;


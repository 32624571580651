import React, {
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { isEqual } from "lodash";
import { useIsMounted } from "hooks";
import { useSnackbar } from "notistack";
import { useAPIContext } from "context/API";
import { useErrorContext } from "context/Error";
import { useValidatedForm } from "hooks/useForm";
import { ValidationResult } from "common/validation";
import { newTextExactCriterion } from "@mesh/common-js/dist/search";
import { useApplicationContext } from "context/Application/Application";
import { InstrumentMarketingContent } from "@mesh/common-js/dist/marketing/instrumentMarketingContent_pb";
import { ReadOneInstrumentMarketingContentRequest } from "@mesh/common-js/dist/marketing/instrumentMarketingContentReader_meshproto_pb";
import {
  formDataUpdaterSpecs,
  formDataValidationFunc,
  FormUpdaterSpecsType as FormDataUpdaterType,
  MarketingContentFormData,
} from "./useValidatedForm";
import {
  UpdateInvestmentObjectiveRequest,
  UpdateSocialMediaLinksRequest,
  UpdateNewsArticlesRequest,
  UpdateMediaEntriesRequest,
  UpdateIndependentReviewsRequest,
} from "@mesh/common-js/dist/marketing/instrumentMarketingContentUpdater_pb";

import { NewInstrumentMarketingContentRequest } from "@mesh/common-js/dist/marketing/instrumentMarketingContentCreator_pb";
import { ReadOneSmartInstrumentRequest } from "@mesh/common-js/dist/financial/smartInstrumentReader_meshproto_pb";
import { SocialMediaLinks } from "@mesh/common-js/dist/marketing/socialMediaLinks_pb";
import { IDIdentifier } from "james/search/identifier";
import { AssetFetcher } from "james/ledger";
import { Asset } from "james/financial/Asset";

export enum ViewMode {
  Undefined = "-",
  View = "view",
  Edit = "edit",
}

export interface InstrumentData {
  ownerID: string;
  name: string;
}

export type MarketingContentContextType = {
  apiCallInProgress: boolean;

  viewMode: ViewMode;
  setEditViewMode: () => void;
  setViewViewMode: () => void;

  initialised: boolean;
  initialisationError: string | null;
  clearInitialisationError: () => void;

  instrumentData: InstrumentData | undefined;

  formData: MarketingContentFormData;
  formDataValidationResult: ValidationResult;
  formDataUpdater: FormDataUpdaterType;
  reloadFormData: () => void;

  saveOrCreateAction: () => Promise<void>;
  discardChanges: () => void;
};

export const defaultContext: MarketingContentContextType = {
  apiCallInProgress: false,

  viewMode: ViewMode.View,
  setEditViewMode: () => null,
  setViewViewMode: () => null,

  initialised: false,
  initialisationError: null,
  clearInitialisationError: () => null,

  instrumentData: {
    ownerID: "",
    name: "",
  },

  formData: {
    marketingContent: new InstrumentMarketingContent(),
    marketingContentCopy: new InstrumentMarketingContent(),
  },
  formDataValidationResult: {
    valid: false,
    fieldValidations: {},
  },
  formDataUpdater: formDataUpdaterSpecs,
  reloadFormData: () => null,

  saveOrCreateAction: () => new Promise(() => null),
  discardChanges: () => null,
};

const MarketingContentContext =
  React.createContext<MarketingContentContextType>(defaultContext);

export const useMarketingContentContext = () =>
  useContext(MarketingContentContext);

export const MarketingContentContextProvider: React.FC<{
  system: boolean;
  children: React.ReactNode;
}> = ({ system, children }) => {
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const location = useLocation();
  const { errorContextErrorTranslator, errorContextDefaultErrorFeedback } =
    useErrorContext();
  const {
    financial: { smartInstrumentReader, smartInstrumentReaderUNSCOPED },
    marketing: {
      instrumentMarketingContentReader,
      instrumentMarketingContentReaderUNSCOPED,
      instrumentMarketingContentUpdater,
      instrumentMarketingContentCreator,
    },
  } = useAPIContext();
  const { authContext, viewConfiguration } = useApplicationContext();
  const isMounted = useIsMounted();
  const { enqueueSnackbar } = useSnackbar();

  const [instrumentData, setInstrumentData] = useState<
    InstrumentData | undefined
  >();

  const [formData, formDataValidationResult, formDataUpdater] =
    useValidatedForm(
      formDataValidationFunc,
      undefined,
      formDataUpdaterSpecs,
      defaultContext.formData,
      new Set<string>([]),
      { skipTouchedFieldsOnValidation: true },
    );

  // ----------- associated data -----------
  const [dataLoaded, setDataLoaded] = useState(false);
  const [dataLoadError, setDataLoadError] = useState<string | null>(null);
  const dataFetchInProgress = useRef(false);

  useEffect(() => {
    (async () => {
      // do nothing if:
      // - required data already loaded
      // - if there was a data loading error
      // - component no longer mounted
      // - data fetching is in progress
      if (
        dataLoaded ||
        dataLoadError ||
        !isMounted() ||
        dataFetchInProgress.current
      ) {
        return;
      }
      dataFetchInProgress.current = true;

      // get instrument id from the url
      const urlInstrumentID = searchParams.get("instrument-id");
      if (!urlInstrumentID) {
        console.error("instrument ID not found in url");
        navigate({
          pathname: "/marketing/table",
        });
        return;
      }

      // Try to fetch the smart instrument
      try {
        const retrievedSmartInstrument = (
          system
            ? await smartInstrumentReaderUNSCOPED.readOneSmartInstrumentUNSCOPED(
                new ReadOneSmartInstrumentRequest()
                  .setContext(authContext.toFuture())
                  .setCriteriaList([
                    newTextExactCriterion("id", urlInstrumentID),
                  ]),
              )
            : await smartInstrumentReader.readOneSmartInstrument(
                new ReadOneSmartInstrumentRequest()
                  .setContext(authContext.toFuture())
                  .setCriteriaList([
                    newTextExactCriterion("id", urlInstrumentID),
                  ]),
              )
        ).getSmartinstrument();

        if (retrievedSmartInstrument !== undefined) {
          setInstrumentData({
            ownerID: retrievedSmartInstrument.getOwnerid(),
            name: retrievedSmartInstrument.getName(),
          });
        }
      } catch (e) {
        // Try to fetch the non-smart instrument
        try {
          const retrievedAsset = (
            await AssetFetcher.FetchAsset({
              context: authContext,
              identifier: IDIdentifier(urlInstrumentID),
            })
          ).asset as Asset;

          setInstrumentData({
            ownerID: retrievedAsset.assetOwnerID(),
            name: retrievedAsset.assetName().toString(),
          });
        } catch (assetFetchError) {
          setDataLoadError("Error fetching instrument data.");
        }
      } finally {
        dataFetchInProgress.current = false;
      }

      if (isMounted()) {
        setDataLoaded(true);
      }
    })();
  }, [dataLoaded, dataLoadError, isMounted]);

  // ----------- instrument marketing content load -----------
  const [marketingContentLoaded, setMarketingContentLoaded] = useState(false);
  const reloadMarketingContent = () => setMarketingContentLoaded(false);
  const [marketingContentLoadError, setMarketingContentLoadError] = useState<
    string | null
  >(null);
  useEffect(() => {
    (async () => {
      // do nothing if:
      // - marketing content already loaded
      // - if there was an marketing content loading error
      // - component no longer mounted
      if (
        !dataLoaded ||
        marketingContentLoaded ||
        marketingContentLoadError ||
        !isMounted()
      ) {
        return;
      }

      // get instrument id from the url
      const urlInstrumentID = searchParams.get("instrument-id");
      if (!urlInstrumentID) {
        console.error("instrument ID not found in url");
        navigate({
          pathname: "/marketing/table",
        });
        return;
      }

      // prepare marketing content for state
      let marketingContentForState: InstrumentMarketingContent | undefined;

      // First try and fetch the associated marketing content
      // If one is found then update the marketing content in the form data,
      // otherwise populate a fresh new one
      try {
        marketingContentForState = (
          system
            ? await instrumentMarketingContentReaderUNSCOPED.readOneInstrumentMarketingContentUNSCOPED(
                new ReadOneInstrumentMarketingContentRequest()
                  .setContext(authContext.toFuture())
                  .setCriteriaList([
                    newTextExactCriterion("instrumentid", urlInstrumentID),
                  ]),
              )
            : await instrumentMarketingContentReader.readOneInstrumentMarketingContent(
                new ReadOneInstrumentMarketingContentRequest()
                  .setContext(authContext.toFuture())
                  .setCriteriaList([
                    newTextExactCriterion("instrumentid", urlInstrumentID),
                  ]),
              )
        ).getInstrumentmarketingcontent();
      } catch (e) {
        const err = errorContextErrorTranslator.translateError(e);
        if (`${e}`.includes("not found")) {
          // if execution reaches here then the marketing content does not yet exist - prepare a new one
          marketingContentForState = new InstrumentMarketingContent()
            .setId("")
            .setOwnerid(instrumentData ? instrumentData.ownerID : "")
            .setInstrumentid(urlInstrumentID)
            .setSocialmedialinks(new SocialMediaLinks());
        } else {
          // if anything goes wrong store a marketing content load error
          console.error("unexpected error retrieving marketing content", e);
          setMarketingContentLoadError(err.message);
          return;
        }
      }
      // only update state after fetching the associated data if the component is still mounted
      if (!isMounted()) {
        return;
      }

      // update the marketing content in state
      if (!marketingContentForState) {
        setMarketingContentLoadError(
          "Instrument marketing content not correctly initialised.",
        );
        return;
      }
      formDataUpdater.marketingContent(marketingContentForState);

      setMarketingContentLoaded(true);
    })();
  }, [
    dataLoaded,
    marketingContentLoaded,
    marketingContentLoadError,
    isMounted,
  ]);

  const clearInitialisationError = useCallback(() => {
    setMarketingContentLoadError(null);
  }, [marketingContentLoadError]);

  // ----------- view mode -----------

  const [viewMode, setViewMode] = useState<ViewMode>(ViewMode.Undefined);
  const setEditViewMode = () => {
    const query = new URLSearchParams(searchParams);
    query.set("mode", ViewMode.Edit);
    navigate({
      pathname: location.pathname,
      search: query.toString(),
    });
    setViewMode(ViewMode.Edit);
  };
  const setViewViewMode = () => {
    const query = new URLSearchParams(searchParams);
    query.set("mode", ViewMode.View);
    navigate({
      pathname: location.pathname,
      search: query.toString(),
    });
    setViewMode(ViewMode.View);
  };
  useEffect(() => {
    if (
      // if view mode is not undefined OR
      viewMode !== ViewMode.Undefined ||
      // data not yet loaded or there was an error while loading OR
      !dataLoaded ||
      dataLoadError ||
      // marketingContent not yet loaded or that was an error while loading
      !marketingContentLoaded ||
      marketingContentLoadError
    ) {
      // then do nothing
      return;
    }
    // otherwise set the initial view mode

    // prepare url search params from the existing search params
    const query = new URLSearchParams(searchParams);

    if (formData.marketingContent.getId() === "") {
      // if the instrument marketing content ID is not set and the user has permission to
      // update marketing content
      if (viewConfiguration["Marketing"]?.ContentUpdater) {
        // then edit mode should be active
        query.set("mode", ViewMode.Edit);
        navigate({
          pathname: location.pathname,
          search: query.toString(),
        });
        setViewMode(ViewMode.Edit);
      } else {
        // otherwise the user should not see this screen
        navigate({ pathname: "/builder/marketing/table" });
      }
    } else {
      // if execution reaches here then the marketing content ID is set,
      // consider if a view mode has been given in the search query
      const viewModeFromURL = searchParams.get("mode");
      let newViewMode: ViewMode = viewModeFromURL
        ? (viewModeFromURL as ViewMode)
        : ViewMode.View;

      // if edit mode was requested and the user doesn't have permission to edit then
      // default to view mode
      if (
        newViewMode === ViewMode.Edit &&
        !viewConfiguration["Marketing"]?.ContentUpdater
      ) {
        newViewMode = ViewMode.View;
      }

      // set view mode in state and url
      query.set("mode", newViewMode);
      navigate({
        pathname: location.pathname,
        search: query.toString(),
      });
      setViewMode(newViewMode);
    }
  }, [
    viewMode,
    formData.marketingContent,
    marketingContentLoaded,
    marketingContentLoadError,
    viewConfiguration,
  ]);

  // ----------- actions -----------
  const discardChanges = () => {
    // Reset the form data to its original state
    formDataUpdater.marketingContent(formData.marketingContentCopy);

    // Navigate to view mode
    const query = new URLSearchParams(searchParams);
    query.set("mode", ViewMode.View);
    navigate({
      pathname: location.pathname,
      search: query.toString(),
    });
    setViewMode(ViewMode.View);
  };
  const [saveOrCreateInProgress, setSaveOrCreateInProgress] = useState(false);
  const saveOrCreateAction = async () => {
    // saving can only be done in edit mode
    if (viewMode !== ViewMode.Edit) {
      return;
    }

    // perform save draft or create
    setSaveOrCreateInProgress(true);
    try {
      let updatedMarketingContent: InstrumentMarketingContent | undefined;
      if (formData.marketingContent.getId() === "") {
        updatedMarketingContent = (
          await instrumentMarketingContentCreator.newInstrumentMarketingContent(
            new NewInstrumentMarketingContentRequest()
              .setContext(authContext.toFuture())
              .setOwnerid(formData.marketingContent.getOwnerid())
              .setInstrumentid(formData.marketingContent.getInstrumentid())
              .setInvestmentobjective(
                formData.marketingContent.getInvestmentobjective(),
              )
              .setSocialmedialinks(
                formData.marketingContent.getSocialmedialinks(),
              )
              .setNewsarticlesList(
                formData.marketingContent.getNewsarticlesList(),
              )
              .setMediaentriesList(
                formData.marketingContent.getMediaentriesList(),
              )
              .setIndependentreviewsList(
                formData.marketingContent.getIndependentreviewsList(),
              ),
          )
        ).getInstrumentmarketingcontent();
      } else {
        // only call the api to perform update if the marketing content has changed
        if (
          isEqual(
            formData.marketingContent.toObject(),
            formData.marketingContentCopy.toObject(),
          )
        ) {
          updatedMarketingContent = formData.marketingContent;
        } else {
          // social media links update - only call api
          // if the social media links have changed
          if (
            !isEqual(
              formData.marketingContent.getSocialmedialinks()?.toObject(),
              formData.marketingContentCopy.getSocialmedialinks()?.toObject(),
            )
          ) {
            updatedMarketingContent = (
              await instrumentMarketingContentUpdater.updateSocialMediaLinks(
                new UpdateSocialMediaLinksRequest()
                  .setContext(authContext.toFuture())
                  .setInstrumentmarketingcontentid(
                    formData.marketingContent.getId(),
                  )
                  .setSocialmedialinks(
                    formData.marketingContent.getSocialmedialinks(),
                  ),
              )
            ).getInstrumentmarketingcontent();

            // replace the marketing content stored in state with the updated
            // marketing content returned from api call
            if (updatedMarketingContent) {
              formDataUpdater.marketingContent(updatedMarketingContent);
            }
          }

          // investment objective update - only call api
          // if the investment objective has changed
          if (
            !isEqual(
              formData.marketingContent.getInvestmentobjective(),
              formData.marketingContentCopy.getInvestmentobjective(),
            )
          ) {
            updatedMarketingContent = (
              await instrumentMarketingContentUpdater.updateInvestmentObjective(
                new UpdateInvestmentObjectiveRequest()
                  .setContext(authContext.toFuture())
                  .setInstrumentmarketingcontentid(
                    formData.marketingContent.getId(),
                  )
                  .setInvestmentobjective(
                    formData.marketingContent.getInvestmentobjective(),
                  ),
              )
            ).getInstrumentmarketingcontent();

            // replace the marketing content stored in state with the updated
            // marketing content returned from api call
            if (updatedMarketingContent) {
              formDataUpdater.marketingContent(updatedMarketingContent);
            }
          }

          // news articles update - only call api
          // if the news articles have changed
          if (
            !isEqual(
              formData.marketingContent.getNewsarticlesList(),
              formData.marketingContentCopy.getNewsarticlesList(),
            )
          ) {
            updatedMarketingContent = (
              await instrumentMarketingContentUpdater.updateNewsArticles(
                new UpdateNewsArticlesRequest()
                  .setContext(authContext.toFuture())
                  .setInstrumentmarketingcontentid(
                    formData.marketingContent.getId(),
                  )
                  .setNewsarticlesList(
                    formData.marketingContent.getNewsarticlesList(),
                  ),
              )
            ).getInstrumentmarketingcontent();

            // replace the marketing content stored in state with the updated
            // marketing content returned from api call
            if (updatedMarketingContent) {
              formDataUpdater.marketingContent(updatedMarketingContent);
            }
          }

          // media entries update - only call api
          // if the media entries have changed
          if (
            !isEqual(
              formData.marketingContent.getMediaentriesList(),
              formData.marketingContentCopy.getMediaentriesList(),
            )
          ) {
            updatedMarketingContent = (
              await instrumentMarketingContentUpdater.updateMediaEntries(
                new UpdateMediaEntriesRequest()
                  .setContext(authContext.toFuture())
                  .setInstrumentmarketingcontentid(
                    formData.marketingContent.getId(),
                  )
                  .setMediaentriesList(
                    formData.marketingContent.getMediaentriesList(),
                  ),
              )
            ).getInstrumentmarketingcontent();

            // replace the marketing content stored in state with the updated
            // marketing content returned from api call
            if (updatedMarketingContent) {
              formDataUpdater.marketingContent(updatedMarketingContent);
            }
          }

          // independent reviews update - only call api
          // if the independent reviews have changed
          if (
            !isEqual(
              formData.marketingContent.getIndependentreviewsList(),
              formData.marketingContentCopy.getIndependentreviewsList(),
            )
          ) {
            updatedMarketingContent = (
              await instrumentMarketingContentUpdater.updateIndependentReviews(
                new UpdateIndependentReviewsRequest()
                  .setContext(authContext.toFuture())
                  .setInstrumentmarketingcontentid(
                    formData.marketingContent.getId(),
                  )
                  .setIndependentreviewsList(
                    formData.marketingContent.getIndependentreviewsList(),
                  ),
              )
            ).getInstrumentmarketingcontent();

            // replace the marketing content stored in state with the updated
            // marketing content returned from api call
            if (updatedMarketingContent) {
              formDataUpdater.marketingContent(updatedMarketingContent);
            }
          }
        }
      }

      if (updatedMarketingContent) {
        // notify on successful updates
        if (
          isEqual(
            updatedMarketingContent.toObject(),
            formData.marketingContentCopy.toObject(),
          )
        ) {
          enqueueSnackbar("Saved with no changes", { variant: "info" });
        } else {
          enqueueSnackbar("Successfully saved changes!", {
            variant: "success",
          });
        }
        // replace the marketing content stored in state with the updated
        // marketing content returned from api call
        formDataUpdater.marketingContent(updatedMarketingContent);

        // update view mode and id url search parameters
        const query = new URLSearchParams();
        query.set("mode", ViewMode.View);
        query.set("instrument-id", updatedMarketingContent.getInstrumentid());
        navigate({
          pathname: location.pathname,
          search: query.toString(),
        });

        // set view mode to view
        setViewMode(ViewMode.View);
      } else {
        // this should never happen
        console.warn(
          "instrument marketing content not set after performing save actions",
        );
      }
    } catch (e) {
      errorContextDefaultErrorFeedback(e, "error performing save action");
    }
    setSaveOrCreateInProgress(false);
  };

  return (
    <MarketingContentContext.Provider
      value={{
        apiCallInProgress: saveOrCreateInProgress,

        viewMode,
        setEditViewMode,
        setViewViewMode,

        initialised: marketingContentLoaded,
        initialisationError: marketingContentLoadError,
        clearInitialisationError,

        instrumentData,

        formData,
        formDataValidationResult,
        formDataUpdater,
        reloadFormData: reloadMarketingContent,

        saveOrCreateAction,
        discardChanges,
      }}
    >
      {children}
    </MarketingContentContext.Provider>
  );
};

import React from "react";
import { Chip, ChipProps, Theme } from "@mui/material";
import { SourceAccountState } from "@mesh/common-js/dist/stellar/sourceAccount_pb";

export interface SourceAccountStateChipProps {
  state: SourceAccountState;
  chipProps?: ChipProps;
}

export function SourceAccountStateChip(props: SourceAccountStateChipProps) {
  switch (props.state) {
    case SourceAccountState.OPENING_SOURCE_ACCOUNT_STATE:
      return (
        <Chip
          {...props.chipProps}
          sx={(theme: Theme) => ({
            backgroundColor: theme.palette.warning.main,
          })}
          id={`sourceAccountStateChip-${props.state}-chip`}
          size={"small"}
          label={"Opening"}
        />
      );

    case SourceAccountState.OPEN_SOURCE_ACCOUNT_STATE:
      return (
        <Chip
          {...props.chipProps}
          sx={(theme: Theme) => ({
            backgroundColor: theme.palette.info.main,
          })}
          id={`sourceAccountStateChip-${props.state}-chip`}
          size={"small"}
          label={"Open"}
        />
      );

    case SourceAccountState.CLOSED_SOURCE_ACCOUNT_STATE:
      return (
        <Chip
          {...props.chipProps}
          sx={(theme: Theme) => ({
            backgroundColor: theme.palette.error.main,
          })}
          id={`sourceAccountStateChip-${props.state}-chip`}
          size={"small"}
          label={"Closed"}
        />
      );

    case SourceAccountState.UNDER_INVESTIGATION_SOURCE_ACCOUNT_STATE:
      return (
        <Chip
          {...props.chipProps}
          sx={(theme: Theme) => ({
            backgroundColor: theme.palette.error.main,
          })}
          id={`sourceAccountStateChip-${props.state}-chip`}
          size={"small"}
          label={"Under Investigation"}
        />
      );

    default:
      return <div>{props.state}</div>;
  }
}

// source: ledger/stellarops/sourceAccountStateController.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global =
    (typeof globalThis !== 'undefined' && globalThis) ||
    (typeof window !== 'undefined' && window) ||
    (typeof global !== 'undefined' && global) ||
    (typeof self !== 'undefined' && self) ||
    (function () { return this; }).call(null) ||
    Function('return this')();

var security_context_pb = require('../../security/context_pb.js');
goog.object.extend(proto, security_context_pb);
var stellar_transaction_pb = require('../../stellar/transaction_pb.js');
goog.object.extend(proto, stellar_transaction_pb);
var stellar_sourceAccount_pb = require('../../stellar/sourceAccount_pb.js');
goog.object.extend(proto, stellar_sourceAccount_pb);
goog.exportSymbol('proto.stellarops.MarkSourceAccountClosedRequest', null, global);
goog.exportSymbol('proto.stellarops.MarkSourceAccountClosedResponse', null, global);
goog.exportSymbol('proto.stellarops.MarkSourceAccountOpenRequest', null, global);
goog.exportSymbol('proto.stellarops.MarkSourceAccountOpenResponse', null, global);
goog.exportSymbol('proto.stellarops.MarkSourceAccountUnderInvestigationRequest', null, global);
goog.exportSymbol('proto.stellarops.MarkSourceAccountUnderInvestigationResponse', null, global);
goog.exportSymbol('proto.stellarops.OpenNewSourceAccountRequest', null, global);
goog.exportSymbol('proto.stellarops.OpenNewSourceAccountResponse', null, global);
goog.exportSymbol('proto.stellarops.OpenSourceAccountRequest', null, global);
goog.exportSymbol('proto.stellarops.OpenSourceAccountResponse', null, global);
goog.exportSymbol('proto.stellarops.ResolveSourceAccountIDStateRequest', null, global);
goog.exportSymbol('proto.stellarops.ResolveSourceAccountIDStateResponse', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.stellarops.OpenNewSourceAccountRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.stellarops.OpenNewSourceAccountRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.stellarops.OpenNewSourceAccountRequest.displayName = 'proto.stellarops.OpenNewSourceAccountRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.stellarops.OpenNewSourceAccountResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.stellarops.OpenNewSourceAccountResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.stellarops.OpenNewSourceAccountResponse.displayName = 'proto.stellarops.OpenNewSourceAccountResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.stellarops.OpenSourceAccountRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.stellarops.OpenSourceAccountRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.stellarops.OpenSourceAccountRequest.displayName = 'proto.stellarops.OpenSourceAccountRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.stellarops.OpenSourceAccountResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.stellarops.OpenSourceAccountResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.stellarops.OpenSourceAccountResponse.displayName = 'proto.stellarops.OpenSourceAccountResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.stellarops.MarkSourceAccountOpenRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.stellarops.MarkSourceAccountOpenRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.stellarops.MarkSourceAccountOpenRequest.displayName = 'proto.stellarops.MarkSourceAccountOpenRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.stellarops.MarkSourceAccountOpenResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.stellarops.MarkSourceAccountOpenResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.stellarops.MarkSourceAccountOpenResponse.displayName = 'proto.stellarops.MarkSourceAccountOpenResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.stellarops.MarkSourceAccountUnderInvestigationRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.stellarops.MarkSourceAccountUnderInvestigationRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.stellarops.MarkSourceAccountUnderInvestigationRequest.displayName = 'proto.stellarops.MarkSourceAccountUnderInvestigationRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.stellarops.MarkSourceAccountUnderInvestigationResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.stellarops.MarkSourceAccountUnderInvestigationResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.stellarops.MarkSourceAccountUnderInvestigationResponse.displayName = 'proto.stellarops.MarkSourceAccountUnderInvestigationResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.stellarops.MarkSourceAccountClosedRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.stellarops.MarkSourceAccountClosedRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.stellarops.MarkSourceAccountClosedRequest.displayName = 'proto.stellarops.MarkSourceAccountClosedRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.stellarops.MarkSourceAccountClosedResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.stellarops.MarkSourceAccountClosedResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.stellarops.MarkSourceAccountClosedResponse.displayName = 'proto.stellarops.MarkSourceAccountClosedResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.stellarops.ResolveSourceAccountIDStateRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.stellarops.ResolveSourceAccountIDStateRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.stellarops.ResolveSourceAccountIDStateRequest.displayName = 'proto.stellarops.ResolveSourceAccountIDStateRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.stellarops.ResolveSourceAccountIDStateResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.stellarops.ResolveSourceAccountIDStateResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.stellarops.ResolveSourceAccountIDStateResponse.displayName = 'proto.stellarops.ResolveSourceAccountIDStateResponse';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.stellarops.OpenNewSourceAccountRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.stellarops.OpenNewSourceAccountRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.stellarops.OpenNewSourceAccountRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.stellarops.OpenNewSourceAccountRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
context: (f = msg.getContext()) && security_context_pb.ContextFuture.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.stellarops.OpenNewSourceAccountRequest}
 */
proto.stellarops.OpenNewSourceAccountRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.stellarops.OpenNewSourceAccountRequest;
  return proto.stellarops.OpenNewSourceAccountRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.stellarops.OpenNewSourceAccountRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.stellarops.OpenNewSourceAccountRequest}
 */
proto.stellarops.OpenNewSourceAccountRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new security_context_pb.ContextFuture;
      reader.readMessage(value,security_context_pb.ContextFuture.deserializeBinaryFromReader);
      msg.setContext(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.stellarops.OpenNewSourceAccountRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.stellarops.OpenNewSourceAccountRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.stellarops.OpenNewSourceAccountRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.stellarops.OpenNewSourceAccountRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getContext();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      security_context_pb.ContextFuture.serializeBinaryToWriter
    );
  }
};


/**
 * optional security.ContextFuture context = 1;
 * @return {?proto.security.ContextFuture}
 */
proto.stellarops.OpenNewSourceAccountRequest.prototype.getContext = function() {
  return /** @type{?proto.security.ContextFuture} */ (
    jspb.Message.getWrapperField(this, security_context_pb.ContextFuture, 1));
};


/**
 * @param {?proto.security.ContextFuture|undefined} value
 * @return {!proto.stellarops.OpenNewSourceAccountRequest} returns this
*/
proto.stellarops.OpenNewSourceAccountRequest.prototype.setContext = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.stellarops.OpenNewSourceAccountRequest} returns this
 */
proto.stellarops.OpenNewSourceAccountRequest.prototype.clearContext = function() {
  return this.setContext(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.stellarops.OpenNewSourceAccountRequest.prototype.hasContext = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.stellarops.OpenNewSourceAccountResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.stellarops.OpenNewSourceAccountResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.stellarops.OpenNewSourceAccountResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.stellarops.OpenNewSourceAccountResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
transactionid: jspb.Message.getFieldWithDefault(msg, 1, ""),
sourceaccount: (f = msg.getSourceaccount()) && stellar_sourceAccount_pb.SourceAccount.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.stellarops.OpenNewSourceAccountResponse}
 */
proto.stellarops.OpenNewSourceAccountResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.stellarops.OpenNewSourceAccountResponse;
  return proto.stellarops.OpenNewSourceAccountResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.stellarops.OpenNewSourceAccountResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.stellarops.OpenNewSourceAccountResponse}
 */
proto.stellarops.OpenNewSourceAccountResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setTransactionid(value);
      break;
    case 2:
      var value = new stellar_sourceAccount_pb.SourceAccount;
      reader.readMessage(value,stellar_sourceAccount_pb.SourceAccount.deserializeBinaryFromReader);
      msg.setSourceaccount(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.stellarops.OpenNewSourceAccountResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.stellarops.OpenNewSourceAccountResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.stellarops.OpenNewSourceAccountResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.stellarops.OpenNewSourceAccountResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTransactionid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getSourceaccount();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      stellar_sourceAccount_pb.SourceAccount.serializeBinaryToWriter
    );
  }
};


/**
 * optional string transactionID = 1;
 * @return {string}
 */
proto.stellarops.OpenNewSourceAccountResponse.prototype.getTransactionid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.stellarops.OpenNewSourceAccountResponse} returns this
 */
proto.stellarops.OpenNewSourceAccountResponse.prototype.setTransactionid = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional stellar.SourceAccount sourceAccount = 2;
 * @return {?proto.stellar.SourceAccount}
 */
proto.stellarops.OpenNewSourceAccountResponse.prototype.getSourceaccount = function() {
  return /** @type{?proto.stellar.SourceAccount} */ (
    jspb.Message.getWrapperField(this, stellar_sourceAccount_pb.SourceAccount, 2));
};


/**
 * @param {?proto.stellar.SourceAccount|undefined} value
 * @return {!proto.stellarops.OpenNewSourceAccountResponse} returns this
*/
proto.stellarops.OpenNewSourceAccountResponse.prototype.setSourceaccount = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.stellarops.OpenNewSourceAccountResponse} returns this
 */
proto.stellarops.OpenNewSourceAccountResponse.prototype.clearSourceaccount = function() {
  return this.setSourceaccount(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.stellarops.OpenNewSourceAccountResponse.prototype.hasSourceaccount = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.stellarops.OpenSourceAccountRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.stellarops.OpenSourceAccountRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.stellarops.OpenSourceAccountRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.stellarops.OpenSourceAccountRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
context: (f = msg.getContext()) && security_context_pb.ContextFuture.toObject(includeInstance, f),
sourceaccountid: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.stellarops.OpenSourceAccountRequest}
 */
proto.stellarops.OpenSourceAccountRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.stellarops.OpenSourceAccountRequest;
  return proto.stellarops.OpenSourceAccountRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.stellarops.OpenSourceAccountRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.stellarops.OpenSourceAccountRequest}
 */
proto.stellarops.OpenSourceAccountRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new security_context_pb.ContextFuture;
      reader.readMessage(value,security_context_pb.ContextFuture.deserializeBinaryFromReader);
      msg.setContext(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setSourceaccountid(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.stellarops.OpenSourceAccountRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.stellarops.OpenSourceAccountRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.stellarops.OpenSourceAccountRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.stellarops.OpenSourceAccountRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getContext();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      security_context_pb.ContextFuture.serializeBinaryToWriter
    );
  }
  f = message.getSourceaccountid();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional security.ContextFuture context = 1;
 * @return {?proto.security.ContextFuture}
 */
proto.stellarops.OpenSourceAccountRequest.prototype.getContext = function() {
  return /** @type{?proto.security.ContextFuture} */ (
    jspb.Message.getWrapperField(this, security_context_pb.ContextFuture, 1));
};


/**
 * @param {?proto.security.ContextFuture|undefined} value
 * @return {!proto.stellarops.OpenSourceAccountRequest} returns this
*/
proto.stellarops.OpenSourceAccountRequest.prototype.setContext = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.stellarops.OpenSourceAccountRequest} returns this
 */
proto.stellarops.OpenSourceAccountRequest.prototype.clearContext = function() {
  return this.setContext(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.stellarops.OpenSourceAccountRequest.prototype.hasContext = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional string sourceAccountID = 2;
 * @return {string}
 */
proto.stellarops.OpenSourceAccountRequest.prototype.getSourceaccountid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.stellarops.OpenSourceAccountRequest} returns this
 */
proto.stellarops.OpenSourceAccountRequest.prototype.setSourceaccountid = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.stellarops.OpenSourceAccountResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.stellarops.OpenSourceAccountResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.stellarops.OpenSourceAccountResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.stellarops.OpenSourceAccountResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
transactionid: jspb.Message.getFieldWithDefault(msg, 1, ""),
sourceaccount: (f = msg.getSourceaccount()) && stellar_sourceAccount_pb.SourceAccount.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.stellarops.OpenSourceAccountResponse}
 */
proto.stellarops.OpenSourceAccountResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.stellarops.OpenSourceAccountResponse;
  return proto.stellarops.OpenSourceAccountResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.stellarops.OpenSourceAccountResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.stellarops.OpenSourceAccountResponse}
 */
proto.stellarops.OpenSourceAccountResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setTransactionid(value);
      break;
    case 2:
      var value = new stellar_sourceAccount_pb.SourceAccount;
      reader.readMessage(value,stellar_sourceAccount_pb.SourceAccount.deserializeBinaryFromReader);
      msg.setSourceaccount(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.stellarops.OpenSourceAccountResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.stellarops.OpenSourceAccountResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.stellarops.OpenSourceAccountResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.stellarops.OpenSourceAccountResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTransactionid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getSourceaccount();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      stellar_sourceAccount_pb.SourceAccount.serializeBinaryToWriter
    );
  }
};


/**
 * optional string transactionID = 1;
 * @return {string}
 */
proto.stellarops.OpenSourceAccountResponse.prototype.getTransactionid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.stellarops.OpenSourceAccountResponse} returns this
 */
proto.stellarops.OpenSourceAccountResponse.prototype.setTransactionid = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional stellar.SourceAccount sourceAccount = 2;
 * @return {?proto.stellar.SourceAccount}
 */
proto.stellarops.OpenSourceAccountResponse.prototype.getSourceaccount = function() {
  return /** @type{?proto.stellar.SourceAccount} */ (
    jspb.Message.getWrapperField(this, stellar_sourceAccount_pb.SourceAccount, 2));
};


/**
 * @param {?proto.stellar.SourceAccount|undefined} value
 * @return {!proto.stellarops.OpenSourceAccountResponse} returns this
*/
proto.stellarops.OpenSourceAccountResponse.prototype.setSourceaccount = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.stellarops.OpenSourceAccountResponse} returns this
 */
proto.stellarops.OpenSourceAccountResponse.prototype.clearSourceaccount = function() {
  return this.setSourceaccount(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.stellarops.OpenSourceAccountResponse.prototype.hasSourceaccount = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.stellarops.MarkSourceAccountOpenRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.stellarops.MarkSourceAccountOpenRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.stellarops.MarkSourceAccountOpenRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.stellarops.MarkSourceAccountOpenRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
context: (f = msg.getContext()) && security_context_pb.ContextFuture.toObject(includeInstance, f),
sourceaccount: (f = msg.getSourceaccount()) && stellar_sourceAccount_pb.SourceAccount.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.stellarops.MarkSourceAccountOpenRequest}
 */
proto.stellarops.MarkSourceAccountOpenRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.stellarops.MarkSourceAccountOpenRequest;
  return proto.stellarops.MarkSourceAccountOpenRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.stellarops.MarkSourceAccountOpenRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.stellarops.MarkSourceAccountOpenRequest}
 */
proto.stellarops.MarkSourceAccountOpenRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new security_context_pb.ContextFuture;
      reader.readMessage(value,security_context_pb.ContextFuture.deserializeBinaryFromReader);
      msg.setContext(value);
      break;
    case 2:
      var value = new stellar_sourceAccount_pb.SourceAccount;
      reader.readMessage(value,stellar_sourceAccount_pb.SourceAccount.deserializeBinaryFromReader);
      msg.setSourceaccount(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.stellarops.MarkSourceAccountOpenRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.stellarops.MarkSourceAccountOpenRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.stellarops.MarkSourceAccountOpenRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.stellarops.MarkSourceAccountOpenRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getContext();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      security_context_pb.ContextFuture.serializeBinaryToWriter
    );
  }
  f = message.getSourceaccount();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      stellar_sourceAccount_pb.SourceAccount.serializeBinaryToWriter
    );
  }
};


/**
 * optional security.ContextFuture context = 1;
 * @return {?proto.security.ContextFuture}
 */
proto.stellarops.MarkSourceAccountOpenRequest.prototype.getContext = function() {
  return /** @type{?proto.security.ContextFuture} */ (
    jspb.Message.getWrapperField(this, security_context_pb.ContextFuture, 1));
};


/**
 * @param {?proto.security.ContextFuture|undefined} value
 * @return {!proto.stellarops.MarkSourceAccountOpenRequest} returns this
*/
proto.stellarops.MarkSourceAccountOpenRequest.prototype.setContext = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.stellarops.MarkSourceAccountOpenRequest} returns this
 */
proto.stellarops.MarkSourceAccountOpenRequest.prototype.clearContext = function() {
  return this.setContext(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.stellarops.MarkSourceAccountOpenRequest.prototype.hasContext = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional stellar.SourceAccount sourceAccount = 2;
 * @return {?proto.stellar.SourceAccount}
 */
proto.stellarops.MarkSourceAccountOpenRequest.prototype.getSourceaccount = function() {
  return /** @type{?proto.stellar.SourceAccount} */ (
    jspb.Message.getWrapperField(this, stellar_sourceAccount_pb.SourceAccount, 2));
};


/**
 * @param {?proto.stellar.SourceAccount|undefined} value
 * @return {!proto.stellarops.MarkSourceAccountOpenRequest} returns this
*/
proto.stellarops.MarkSourceAccountOpenRequest.prototype.setSourceaccount = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.stellarops.MarkSourceAccountOpenRequest} returns this
 */
proto.stellarops.MarkSourceAccountOpenRequest.prototype.clearSourceaccount = function() {
  return this.setSourceaccount(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.stellarops.MarkSourceAccountOpenRequest.prototype.hasSourceaccount = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.stellarops.MarkSourceAccountOpenResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.stellarops.MarkSourceAccountOpenResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.stellarops.MarkSourceAccountOpenResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.stellarops.MarkSourceAccountOpenResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
sourceaccount: (f = msg.getSourceaccount()) && stellar_sourceAccount_pb.SourceAccount.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.stellarops.MarkSourceAccountOpenResponse}
 */
proto.stellarops.MarkSourceAccountOpenResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.stellarops.MarkSourceAccountOpenResponse;
  return proto.stellarops.MarkSourceAccountOpenResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.stellarops.MarkSourceAccountOpenResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.stellarops.MarkSourceAccountOpenResponse}
 */
proto.stellarops.MarkSourceAccountOpenResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new stellar_sourceAccount_pb.SourceAccount;
      reader.readMessage(value,stellar_sourceAccount_pb.SourceAccount.deserializeBinaryFromReader);
      msg.setSourceaccount(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.stellarops.MarkSourceAccountOpenResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.stellarops.MarkSourceAccountOpenResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.stellarops.MarkSourceAccountOpenResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.stellarops.MarkSourceAccountOpenResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSourceaccount();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      stellar_sourceAccount_pb.SourceAccount.serializeBinaryToWriter
    );
  }
};


/**
 * optional stellar.SourceAccount sourceAccount = 1;
 * @return {?proto.stellar.SourceAccount}
 */
proto.stellarops.MarkSourceAccountOpenResponse.prototype.getSourceaccount = function() {
  return /** @type{?proto.stellar.SourceAccount} */ (
    jspb.Message.getWrapperField(this, stellar_sourceAccount_pb.SourceAccount, 1));
};


/**
 * @param {?proto.stellar.SourceAccount|undefined} value
 * @return {!proto.stellarops.MarkSourceAccountOpenResponse} returns this
*/
proto.stellarops.MarkSourceAccountOpenResponse.prototype.setSourceaccount = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.stellarops.MarkSourceAccountOpenResponse} returns this
 */
proto.stellarops.MarkSourceAccountOpenResponse.prototype.clearSourceaccount = function() {
  return this.setSourceaccount(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.stellarops.MarkSourceAccountOpenResponse.prototype.hasSourceaccount = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.stellarops.MarkSourceAccountUnderInvestigationRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.stellarops.MarkSourceAccountUnderInvestigationRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.stellarops.MarkSourceAccountUnderInvestigationRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.stellarops.MarkSourceAccountUnderInvestigationRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
context: (f = msg.getContext()) && security_context_pb.ContextFuture.toObject(includeInstance, f),
sourceaccount: (f = msg.getSourceaccount()) && stellar_sourceAccount_pb.SourceAccount.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.stellarops.MarkSourceAccountUnderInvestigationRequest}
 */
proto.stellarops.MarkSourceAccountUnderInvestigationRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.stellarops.MarkSourceAccountUnderInvestigationRequest;
  return proto.stellarops.MarkSourceAccountUnderInvestigationRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.stellarops.MarkSourceAccountUnderInvestigationRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.stellarops.MarkSourceAccountUnderInvestigationRequest}
 */
proto.stellarops.MarkSourceAccountUnderInvestigationRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new security_context_pb.ContextFuture;
      reader.readMessage(value,security_context_pb.ContextFuture.deserializeBinaryFromReader);
      msg.setContext(value);
      break;
    case 2:
      var value = new stellar_sourceAccount_pb.SourceAccount;
      reader.readMessage(value,stellar_sourceAccount_pb.SourceAccount.deserializeBinaryFromReader);
      msg.setSourceaccount(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.stellarops.MarkSourceAccountUnderInvestigationRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.stellarops.MarkSourceAccountUnderInvestigationRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.stellarops.MarkSourceAccountUnderInvestigationRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.stellarops.MarkSourceAccountUnderInvestigationRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getContext();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      security_context_pb.ContextFuture.serializeBinaryToWriter
    );
  }
  f = message.getSourceaccount();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      stellar_sourceAccount_pb.SourceAccount.serializeBinaryToWriter
    );
  }
};


/**
 * optional security.ContextFuture context = 1;
 * @return {?proto.security.ContextFuture}
 */
proto.stellarops.MarkSourceAccountUnderInvestigationRequest.prototype.getContext = function() {
  return /** @type{?proto.security.ContextFuture} */ (
    jspb.Message.getWrapperField(this, security_context_pb.ContextFuture, 1));
};


/**
 * @param {?proto.security.ContextFuture|undefined} value
 * @return {!proto.stellarops.MarkSourceAccountUnderInvestigationRequest} returns this
*/
proto.stellarops.MarkSourceAccountUnderInvestigationRequest.prototype.setContext = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.stellarops.MarkSourceAccountUnderInvestigationRequest} returns this
 */
proto.stellarops.MarkSourceAccountUnderInvestigationRequest.prototype.clearContext = function() {
  return this.setContext(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.stellarops.MarkSourceAccountUnderInvestigationRequest.prototype.hasContext = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional stellar.SourceAccount sourceAccount = 2;
 * @return {?proto.stellar.SourceAccount}
 */
proto.stellarops.MarkSourceAccountUnderInvestigationRequest.prototype.getSourceaccount = function() {
  return /** @type{?proto.stellar.SourceAccount} */ (
    jspb.Message.getWrapperField(this, stellar_sourceAccount_pb.SourceAccount, 2));
};


/**
 * @param {?proto.stellar.SourceAccount|undefined} value
 * @return {!proto.stellarops.MarkSourceAccountUnderInvestigationRequest} returns this
*/
proto.stellarops.MarkSourceAccountUnderInvestigationRequest.prototype.setSourceaccount = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.stellarops.MarkSourceAccountUnderInvestigationRequest} returns this
 */
proto.stellarops.MarkSourceAccountUnderInvestigationRequest.prototype.clearSourceaccount = function() {
  return this.setSourceaccount(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.stellarops.MarkSourceAccountUnderInvestigationRequest.prototype.hasSourceaccount = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.stellarops.MarkSourceAccountUnderInvestigationResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.stellarops.MarkSourceAccountUnderInvestigationResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.stellarops.MarkSourceAccountUnderInvestigationResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.stellarops.MarkSourceAccountUnderInvestigationResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
sourceaccount: (f = msg.getSourceaccount()) && stellar_sourceAccount_pb.SourceAccount.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.stellarops.MarkSourceAccountUnderInvestigationResponse}
 */
proto.stellarops.MarkSourceAccountUnderInvestigationResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.stellarops.MarkSourceAccountUnderInvestigationResponse;
  return proto.stellarops.MarkSourceAccountUnderInvestigationResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.stellarops.MarkSourceAccountUnderInvestigationResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.stellarops.MarkSourceAccountUnderInvestigationResponse}
 */
proto.stellarops.MarkSourceAccountUnderInvestigationResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new stellar_sourceAccount_pb.SourceAccount;
      reader.readMessage(value,stellar_sourceAccount_pb.SourceAccount.deserializeBinaryFromReader);
      msg.setSourceaccount(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.stellarops.MarkSourceAccountUnderInvestigationResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.stellarops.MarkSourceAccountUnderInvestigationResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.stellarops.MarkSourceAccountUnderInvestigationResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.stellarops.MarkSourceAccountUnderInvestigationResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSourceaccount();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      stellar_sourceAccount_pb.SourceAccount.serializeBinaryToWriter
    );
  }
};


/**
 * optional stellar.SourceAccount sourceAccount = 1;
 * @return {?proto.stellar.SourceAccount}
 */
proto.stellarops.MarkSourceAccountUnderInvestigationResponse.prototype.getSourceaccount = function() {
  return /** @type{?proto.stellar.SourceAccount} */ (
    jspb.Message.getWrapperField(this, stellar_sourceAccount_pb.SourceAccount, 1));
};


/**
 * @param {?proto.stellar.SourceAccount|undefined} value
 * @return {!proto.stellarops.MarkSourceAccountUnderInvestigationResponse} returns this
*/
proto.stellarops.MarkSourceAccountUnderInvestigationResponse.prototype.setSourceaccount = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.stellarops.MarkSourceAccountUnderInvestigationResponse} returns this
 */
proto.stellarops.MarkSourceAccountUnderInvestigationResponse.prototype.clearSourceaccount = function() {
  return this.setSourceaccount(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.stellarops.MarkSourceAccountUnderInvestigationResponse.prototype.hasSourceaccount = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.stellarops.MarkSourceAccountClosedRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.stellarops.MarkSourceAccountClosedRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.stellarops.MarkSourceAccountClosedRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.stellarops.MarkSourceAccountClosedRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
context: (f = msg.getContext()) && security_context_pb.ContextFuture.toObject(includeInstance, f),
sourceaccount: (f = msg.getSourceaccount()) && stellar_sourceAccount_pb.SourceAccount.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.stellarops.MarkSourceAccountClosedRequest}
 */
proto.stellarops.MarkSourceAccountClosedRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.stellarops.MarkSourceAccountClosedRequest;
  return proto.stellarops.MarkSourceAccountClosedRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.stellarops.MarkSourceAccountClosedRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.stellarops.MarkSourceAccountClosedRequest}
 */
proto.stellarops.MarkSourceAccountClosedRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new security_context_pb.ContextFuture;
      reader.readMessage(value,security_context_pb.ContextFuture.deserializeBinaryFromReader);
      msg.setContext(value);
      break;
    case 2:
      var value = new stellar_sourceAccount_pb.SourceAccount;
      reader.readMessage(value,stellar_sourceAccount_pb.SourceAccount.deserializeBinaryFromReader);
      msg.setSourceaccount(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.stellarops.MarkSourceAccountClosedRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.stellarops.MarkSourceAccountClosedRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.stellarops.MarkSourceAccountClosedRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.stellarops.MarkSourceAccountClosedRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getContext();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      security_context_pb.ContextFuture.serializeBinaryToWriter
    );
  }
  f = message.getSourceaccount();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      stellar_sourceAccount_pb.SourceAccount.serializeBinaryToWriter
    );
  }
};


/**
 * optional security.ContextFuture context = 1;
 * @return {?proto.security.ContextFuture}
 */
proto.stellarops.MarkSourceAccountClosedRequest.prototype.getContext = function() {
  return /** @type{?proto.security.ContextFuture} */ (
    jspb.Message.getWrapperField(this, security_context_pb.ContextFuture, 1));
};


/**
 * @param {?proto.security.ContextFuture|undefined} value
 * @return {!proto.stellarops.MarkSourceAccountClosedRequest} returns this
*/
proto.stellarops.MarkSourceAccountClosedRequest.prototype.setContext = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.stellarops.MarkSourceAccountClosedRequest} returns this
 */
proto.stellarops.MarkSourceAccountClosedRequest.prototype.clearContext = function() {
  return this.setContext(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.stellarops.MarkSourceAccountClosedRequest.prototype.hasContext = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional stellar.SourceAccount sourceAccount = 2;
 * @return {?proto.stellar.SourceAccount}
 */
proto.stellarops.MarkSourceAccountClosedRequest.prototype.getSourceaccount = function() {
  return /** @type{?proto.stellar.SourceAccount} */ (
    jspb.Message.getWrapperField(this, stellar_sourceAccount_pb.SourceAccount, 2));
};


/**
 * @param {?proto.stellar.SourceAccount|undefined} value
 * @return {!proto.stellarops.MarkSourceAccountClosedRequest} returns this
*/
proto.stellarops.MarkSourceAccountClosedRequest.prototype.setSourceaccount = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.stellarops.MarkSourceAccountClosedRequest} returns this
 */
proto.stellarops.MarkSourceAccountClosedRequest.prototype.clearSourceaccount = function() {
  return this.setSourceaccount(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.stellarops.MarkSourceAccountClosedRequest.prototype.hasSourceaccount = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.stellarops.MarkSourceAccountClosedResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.stellarops.MarkSourceAccountClosedResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.stellarops.MarkSourceAccountClosedResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.stellarops.MarkSourceAccountClosedResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
sourceaccount: (f = msg.getSourceaccount()) && stellar_sourceAccount_pb.SourceAccount.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.stellarops.MarkSourceAccountClosedResponse}
 */
proto.stellarops.MarkSourceAccountClosedResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.stellarops.MarkSourceAccountClosedResponse;
  return proto.stellarops.MarkSourceAccountClosedResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.stellarops.MarkSourceAccountClosedResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.stellarops.MarkSourceAccountClosedResponse}
 */
proto.stellarops.MarkSourceAccountClosedResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new stellar_sourceAccount_pb.SourceAccount;
      reader.readMessage(value,stellar_sourceAccount_pb.SourceAccount.deserializeBinaryFromReader);
      msg.setSourceaccount(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.stellarops.MarkSourceAccountClosedResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.stellarops.MarkSourceAccountClosedResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.stellarops.MarkSourceAccountClosedResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.stellarops.MarkSourceAccountClosedResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSourceaccount();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      stellar_sourceAccount_pb.SourceAccount.serializeBinaryToWriter
    );
  }
};


/**
 * optional stellar.SourceAccount sourceAccount = 1;
 * @return {?proto.stellar.SourceAccount}
 */
proto.stellarops.MarkSourceAccountClosedResponse.prototype.getSourceaccount = function() {
  return /** @type{?proto.stellar.SourceAccount} */ (
    jspb.Message.getWrapperField(this, stellar_sourceAccount_pb.SourceAccount, 1));
};


/**
 * @param {?proto.stellar.SourceAccount|undefined} value
 * @return {!proto.stellarops.MarkSourceAccountClosedResponse} returns this
*/
proto.stellarops.MarkSourceAccountClosedResponse.prototype.setSourceaccount = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.stellarops.MarkSourceAccountClosedResponse} returns this
 */
proto.stellarops.MarkSourceAccountClosedResponse.prototype.clearSourceaccount = function() {
  return this.setSourceaccount(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.stellarops.MarkSourceAccountClosedResponse.prototype.hasSourceaccount = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.stellarops.ResolveSourceAccountIDStateRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.stellarops.ResolveSourceAccountIDStateRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.stellarops.ResolveSourceAccountIDStateRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.stellarops.ResolveSourceAccountIDStateRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
context: (f = msg.getContext()) && security_context_pb.ContextFuture.toObject(includeInstance, f),
sourceaccountid: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.stellarops.ResolveSourceAccountIDStateRequest}
 */
proto.stellarops.ResolveSourceAccountIDStateRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.stellarops.ResolveSourceAccountIDStateRequest;
  return proto.stellarops.ResolveSourceAccountIDStateRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.stellarops.ResolveSourceAccountIDStateRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.stellarops.ResolveSourceAccountIDStateRequest}
 */
proto.stellarops.ResolveSourceAccountIDStateRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new security_context_pb.ContextFuture;
      reader.readMessage(value,security_context_pb.ContextFuture.deserializeBinaryFromReader);
      msg.setContext(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setSourceaccountid(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.stellarops.ResolveSourceAccountIDStateRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.stellarops.ResolveSourceAccountIDStateRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.stellarops.ResolveSourceAccountIDStateRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.stellarops.ResolveSourceAccountIDStateRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getContext();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      security_context_pb.ContextFuture.serializeBinaryToWriter
    );
  }
  f = message.getSourceaccountid();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional security.ContextFuture context = 1;
 * @return {?proto.security.ContextFuture}
 */
proto.stellarops.ResolveSourceAccountIDStateRequest.prototype.getContext = function() {
  return /** @type{?proto.security.ContextFuture} */ (
    jspb.Message.getWrapperField(this, security_context_pb.ContextFuture, 1));
};


/**
 * @param {?proto.security.ContextFuture|undefined} value
 * @return {!proto.stellarops.ResolveSourceAccountIDStateRequest} returns this
*/
proto.stellarops.ResolveSourceAccountIDStateRequest.prototype.setContext = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.stellarops.ResolveSourceAccountIDStateRequest} returns this
 */
proto.stellarops.ResolveSourceAccountIDStateRequest.prototype.clearContext = function() {
  return this.setContext(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.stellarops.ResolveSourceAccountIDStateRequest.prototype.hasContext = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional string sourceAccountID = 2;
 * @return {string}
 */
proto.stellarops.ResolveSourceAccountIDStateRequest.prototype.getSourceaccountid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.stellarops.ResolveSourceAccountIDStateRequest} returns this
 */
proto.stellarops.ResolveSourceAccountIDStateRequest.prototype.setSourceaccountid = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.stellarops.ResolveSourceAccountIDStateResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.stellarops.ResolveSourceAccountIDStateResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.stellarops.ResolveSourceAccountIDStateResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.stellarops.ResolveSourceAccountIDStateResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
sourceaccount: (f = msg.getSourceaccount()) && stellar_sourceAccount_pb.SourceAccount.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.stellarops.ResolveSourceAccountIDStateResponse}
 */
proto.stellarops.ResolveSourceAccountIDStateResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.stellarops.ResolveSourceAccountIDStateResponse;
  return proto.stellarops.ResolveSourceAccountIDStateResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.stellarops.ResolveSourceAccountIDStateResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.stellarops.ResolveSourceAccountIDStateResponse}
 */
proto.stellarops.ResolveSourceAccountIDStateResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new stellar_sourceAccount_pb.SourceAccount;
      reader.readMessage(value,stellar_sourceAccount_pb.SourceAccount.deserializeBinaryFromReader);
      msg.setSourceaccount(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.stellarops.ResolveSourceAccountIDStateResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.stellarops.ResolveSourceAccountIDStateResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.stellarops.ResolveSourceAccountIDStateResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.stellarops.ResolveSourceAccountIDStateResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSourceaccount();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      stellar_sourceAccount_pb.SourceAccount.serializeBinaryToWriter
    );
  }
};


/**
 * optional stellar.SourceAccount sourceAccount = 1;
 * @return {?proto.stellar.SourceAccount}
 */
proto.stellarops.ResolveSourceAccountIDStateResponse.prototype.getSourceaccount = function() {
  return /** @type{?proto.stellar.SourceAccount} */ (
    jspb.Message.getWrapperField(this, stellar_sourceAccount_pb.SourceAccount, 1));
};


/**
 * @param {?proto.stellar.SourceAccount|undefined} value
 * @return {!proto.stellarops.ResolveSourceAccountIDStateResponse} returns this
*/
proto.stellarops.ResolveSourceAccountIDStateResponse.prototype.setSourceaccount = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.stellarops.ResolveSourceAccountIDStateResponse} returns this
 */
proto.stellarops.ResolveSourceAccountIDStateResponse.prototype.clearSourceaccount = function() {
  return this.setSourceaccount(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.stellarops.ResolveSourceAccountIDStateResponse.prototype.hasSourceaccount = function() {
  return jspb.Message.getField(this, 1) != null;
};


goog.object.extend(exports, proto.stellarops);

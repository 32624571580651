/**
 * @fileoverview gRPC-Web generated client stub for marketing
 * @enhanceable
 * @public
 */

// Code generated by protoc-gen-grpc-web. DO NOT EDIT.
// versions:
// 	protoc-gen-grpc-web v1.5.0
// 	protoc              v5.28.0
// source: marketing/instrumentMarketingContentCreator.proto


/* eslint-disable */
// @ts-nocheck



const grpc = {};
grpc.web = require('grpc-web');


var marketing_instrumentMarketingContent_pb = require('../marketing/instrumentMarketingContent_pb.js')

var marketing_socialMediaLinks_pb = require('../marketing/socialMediaLinks_pb.js')

var marketing_independentReview_pb = require('../marketing/independentReview_pb.js')

var marketing_mediaEntry_pb = require('../marketing/mediaEntry_pb.js')

var marketing_newsArticle_pb = require('../marketing/newsArticle_pb.js')

var security_context_pb = require('../security/context_pb.js')

var audit_entry_pb = require('../audit/entry_pb.js')
const proto = {};
proto.marketing = require('./instrumentMarketingContentCreator_pb.js');

/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?grpc.web.ClientOptions} options
 * @constructor
 * @struct
 * @final
 */
proto.marketing.InstrumentMarketingContentCreatorClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options.format = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname.replace(/\/+$/, '');

};


/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?grpc.web.ClientOptions} options
 * @constructor
 * @struct
 * @final
 */
proto.marketing.InstrumentMarketingContentCreatorPromiseClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options.format = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname.replace(/\/+$/, '');

};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.marketing.NewInstrumentMarketingContentRequest,
 *   !proto.marketing.NewInstrumentMarketingContentResponse>}
 */
const methodDescriptor_InstrumentMarketingContentCreator_NewInstrumentMarketingContent = new grpc.web.MethodDescriptor(
  '/marketing.InstrumentMarketingContentCreator/NewInstrumentMarketingContent',
  grpc.web.MethodType.UNARY,
  proto.marketing.NewInstrumentMarketingContentRequest,
  proto.marketing.NewInstrumentMarketingContentResponse,
  /**
   * @param {!proto.marketing.NewInstrumentMarketingContentRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.marketing.NewInstrumentMarketingContentResponse.deserializeBinary
);


/**
 * @param {!proto.marketing.NewInstrumentMarketingContentRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.marketing.NewInstrumentMarketingContentResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.marketing.NewInstrumentMarketingContentResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.marketing.InstrumentMarketingContentCreatorClient.prototype.newInstrumentMarketingContent =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/marketing.InstrumentMarketingContentCreator/NewInstrumentMarketingContent',
      request,
      metadata || {},
      methodDescriptor_InstrumentMarketingContentCreator_NewInstrumentMarketingContent,
      callback);
};


/**
 * @param {!proto.marketing.NewInstrumentMarketingContentRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.marketing.NewInstrumentMarketingContentResponse>}
 *     Promise that resolves to the response
 */
proto.marketing.InstrumentMarketingContentCreatorPromiseClient.prototype.newInstrumentMarketingContent =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/marketing.InstrumentMarketingContentCreator/NewInstrumentMarketingContent',
      request,
      metadata || {},
      methodDescriptor_InstrumentMarketingContentCreator_NewInstrumentMarketingContent);
};


module.exports = proto.marketing;


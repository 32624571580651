/**
 * @fileoverview gRPC-Web generated client stub for financial
 * @enhanceable
 * @public
 */

// Code generated by protoc-gen-grpc-web. DO NOT EDIT.
// versions:
// 	protoc-gen-grpc-web v1.5.0
// 	protoc              v5.28.0
// source: financial/paymentRecipientReader_meshproto.proto


/* eslint-disable */
// @ts-nocheck



const grpc = {};
grpc.web = require('grpc-web');


var security_context_pb = require('../security/context_pb.js')

var search_criterion_pb = require('../search/criterion_pb.js')

var search_query_pb = require('../search/query_pb.js')

var search_sorting_pb = require('../search/sorting_pb.js')

var financial_paymentRecipient_pb = require('../financial/paymentRecipient_pb.js')

var google_protobuf_timestamp_pb = require('google-protobuf/google/protobuf/timestamp_pb.js')

var num_decimal_pb = require('../num/decimal_pb.js')

var ledger_futureAmount_pb = require('../ledger/futureAmount_pb.js')

var financial_paymentRecipientData_pb = require('../financial/paymentRecipientData_pb.js')
const proto = {};
proto.financial = require('./paymentRecipientReader_meshproto_pb.js');

/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?grpc.web.ClientOptions} options
 * @constructor
 * @struct
 * @final
 */
proto.financial.PaymentRecipientReaderClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options.format = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname.replace(/\/+$/, '');

};


/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?grpc.web.ClientOptions} options
 * @constructor
 * @struct
 * @final
 */
proto.financial.PaymentRecipientReaderPromiseClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options.format = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname.replace(/\/+$/, '');

};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.financial.ReadOnePaymentRecipientRequest,
 *   !proto.financial.ReadOnePaymentRecipientResponse>}
 */
const methodDescriptor_PaymentRecipientReader_ReadOnePaymentRecipient = new grpc.web.MethodDescriptor(
  '/financial.PaymentRecipientReader/ReadOnePaymentRecipient',
  grpc.web.MethodType.UNARY,
  proto.financial.ReadOnePaymentRecipientRequest,
  proto.financial.ReadOnePaymentRecipientResponse,
  /**
   * @param {!proto.financial.ReadOnePaymentRecipientRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.financial.ReadOnePaymentRecipientResponse.deserializeBinary
);


/**
 * @param {!proto.financial.ReadOnePaymentRecipientRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.financial.ReadOnePaymentRecipientResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.financial.ReadOnePaymentRecipientResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.financial.PaymentRecipientReaderClient.prototype.readOnePaymentRecipient =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/financial.PaymentRecipientReader/ReadOnePaymentRecipient',
      request,
      metadata || {},
      methodDescriptor_PaymentRecipientReader_ReadOnePaymentRecipient,
      callback);
};


/**
 * @param {!proto.financial.ReadOnePaymentRecipientRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.financial.ReadOnePaymentRecipientResponse>}
 *     Promise that resolves to the response
 */
proto.financial.PaymentRecipientReaderPromiseClient.prototype.readOnePaymentRecipient =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/financial.PaymentRecipientReader/ReadOnePaymentRecipient',
      request,
      metadata || {},
      methodDescriptor_PaymentRecipientReader_ReadOnePaymentRecipient);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.financial.ReadManyPaymentRecipientRequest,
 *   !proto.financial.ReadManyPaymentRecipientResponse>}
 */
const methodDescriptor_PaymentRecipientReader_ReadManyPaymentRecipient = new grpc.web.MethodDescriptor(
  '/financial.PaymentRecipientReader/ReadManyPaymentRecipient',
  grpc.web.MethodType.UNARY,
  proto.financial.ReadManyPaymentRecipientRequest,
  proto.financial.ReadManyPaymentRecipientResponse,
  /**
   * @param {!proto.financial.ReadManyPaymentRecipientRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.financial.ReadManyPaymentRecipientResponse.deserializeBinary
);


/**
 * @param {!proto.financial.ReadManyPaymentRecipientRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.financial.ReadManyPaymentRecipientResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.financial.ReadManyPaymentRecipientResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.financial.PaymentRecipientReaderClient.prototype.readManyPaymentRecipient =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/financial.PaymentRecipientReader/ReadManyPaymentRecipient',
      request,
      metadata || {},
      methodDescriptor_PaymentRecipientReader_ReadManyPaymentRecipient,
      callback);
};


/**
 * @param {!proto.financial.ReadManyPaymentRecipientRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.financial.ReadManyPaymentRecipientResponse>}
 *     Promise that resolves to the response
 */
proto.financial.PaymentRecipientReaderPromiseClient.prototype.readManyPaymentRecipient =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/financial.PaymentRecipientReader/ReadManyPaymentRecipient',
      request,
      metadata || {},
      methodDescriptor_PaymentRecipientReader_ReadManyPaymentRecipient);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.financial.ReadAllPaymentRecipientRequest,
 *   !proto.financial.ReadAllPaymentRecipientResponse>}
 */
const methodDescriptor_PaymentRecipientReader_ReadAllPaymentRecipient = new grpc.web.MethodDescriptor(
  '/financial.PaymentRecipientReader/ReadAllPaymentRecipient',
  grpc.web.MethodType.UNARY,
  proto.financial.ReadAllPaymentRecipientRequest,
  proto.financial.ReadAllPaymentRecipientResponse,
  /**
   * @param {!proto.financial.ReadAllPaymentRecipientRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.financial.ReadAllPaymentRecipientResponse.deserializeBinary
);


/**
 * @param {!proto.financial.ReadAllPaymentRecipientRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.financial.ReadAllPaymentRecipientResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.financial.ReadAllPaymentRecipientResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.financial.PaymentRecipientReaderClient.prototype.readAllPaymentRecipient =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/financial.PaymentRecipientReader/ReadAllPaymentRecipient',
      request,
      metadata || {},
      methodDescriptor_PaymentRecipientReader_ReadAllPaymentRecipient,
      callback);
};


/**
 * @param {!proto.financial.ReadAllPaymentRecipientRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.financial.ReadAllPaymentRecipientResponse>}
 *     Promise that resolves to the response
 */
proto.financial.PaymentRecipientReaderPromiseClient.prototype.readAllPaymentRecipient =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/financial.PaymentRecipientReader/ReadAllPaymentRecipient',
      request,
      metadata || {},
      methodDescriptor_PaymentRecipientReader_ReadAllPaymentRecipient);
};


module.exports = proto.financial;


/**
 * @fileoverview gRPC-Web generated client stub for stellar
 * @enhanceable
 * @public
 */

// Code generated by protoc-gen-grpc-web. DO NOT EDIT.
// versions:
// 	protoc-gen-grpc-web v1.5.0
// 	protoc              v5.28.0
// source: stellar/liquidityPoolInspector.proto


/* eslint-disable */
// @ts-nocheck



const grpc = {};
grpc.web = require('grpc-web');


var security_context_pb = require('../security/context_pb.js')

var stellar_liquidityPool_pb = require('../stellar/liquidityPool_pb.js')

var stellar_token_pb = require('../stellar/token_pb.js')
const proto = {};
proto.stellar = require('./liquidityPoolInspector_pb.js');

/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?grpc.web.ClientOptions} options
 * @constructor
 * @struct
 * @final
 */
proto.stellar.LiquidityPoolInspectorClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options.format = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname.replace(/\/+$/, '');

};


/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?grpc.web.ClientOptions} options
 * @constructor
 * @struct
 * @final
 */
proto.stellar.LiquidityPoolInspectorPromiseClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options.format = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname.replace(/\/+$/, '');

};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.stellar.InspectLiquidityPoolByIDRequest,
 *   !proto.stellar.InspectLiquidityPoolByIDResponse>}
 */
const methodDescriptor_LiquidityPoolInspector_InspectLiquidityPoolByID = new grpc.web.MethodDescriptor(
  '/stellar.LiquidityPoolInspector/InspectLiquidityPoolByID',
  grpc.web.MethodType.UNARY,
  proto.stellar.InspectLiquidityPoolByIDRequest,
  proto.stellar.InspectLiquidityPoolByIDResponse,
  /**
   * @param {!proto.stellar.InspectLiquidityPoolByIDRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.stellar.InspectLiquidityPoolByIDResponse.deserializeBinary
);


/**
 * @param {!proto.stellar.InspectLiquidityPoolByIDRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.stellar.InspectLiquidityPoolByIDResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.stellar.InspectLiquidityPoolByIDResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.stellar.LiquidityPoolInspectorClient.prototype.inspectLiquidityPoolByID =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/stellar.LiquidityPoolInspector/InspectLiquidityPoolByID',
      request,
      metadata || {},
      methodDescriptor_LiquidityPoolInspector_InspectLiquidityPoolByID,
      callback);
};


/**
 * @param {!proto.stellar.InspectLiquidityPoolByIDRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.stellar.InspectLiquidityPoolByIDResponse>}
 *     Promise that resolves to the response
 */
proto.stellar.LiquidityPoolInspectorPromiseClient.prototype.inspectLiquidityPoolByID =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/stellar.LiquidityPoolInspector/InspectLiquidityPoolByID',
      request,
      metadata || {},
      methodDescriptor_LiquidityPoolInspector_InspectLiquidityPoolByID);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.stellar.InspectLiquidityPoolByTokensRequest,
 *   !proto.stellar.InspectLiquidityPoolByTokensResponse>}
 */
const methodDescriptor_LiquidityPoolInspector_InspectLiquidityPoolByTokens = new grpc.web.MethodDescriptor(
  '/stellar.LiquidityPoolInspector/InspectLiquidityPoolByTokens',
  grpc.web.MethodType.UNARY,
  proto.stellar.InspectLiquidityPoolByTokensRequest,
  proto.stellar.InspectLiquidityPoolByTokensResponse,
  /**
   * @param {!proto.stellar.InspectLiquidityPoolByTokensRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.stellar.InspectLiquidityPoolByTokensResponse.deserializeBinary
);


/**
 * @param {!proto.stellar.InspectLiquidityPoolByTokensRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.stellar.InspectLiquidityPoolByTokensResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.stellar.InspectLiquidityPoolByTokensResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.stellar.LiquidityPoolInspectorClient.prototype.inspectLiquidityPoolByTokens =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/stellar.LiquidityPoolInspector/InspectLiquidityPoolByTokens',
      request,
      metadata || {},
      methodDescriptor_LiquidityPoolInspector_InspectLiquidityPoolByTokens,
      callback);
};


/**
 * @param {!proto.stellar.InspectLiquidityPoolByTokensRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.stellar.InspectLiquidityPoolByTokensResponse>}
 *     Promise that resolves to the response
 */
proto.stellar.LiquidityPoolInspectorPromiseClient.prototype.inspectLiquidityPoolByTokens =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/stellar.LiquidityPoolInspector/InspectLiquidityPoolByTokens',
      request,
      metadata || {},
      methodDescriptor_LiquidityPoolInspector_InspectLiquidityPoolByTokens);
};


module.exports = proto.stellar;


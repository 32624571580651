import { BigNumber } from "bignumber.js";
import { ValidationResult } from "common/validation";
import { Mechanism, MechanismType } from "james/market";

export function validateSpotMarketMechanism(
  marketMechanism: Mechanism,
): ValidationResult {
  // prepare validation result
  const validationResult: ValidationResult = {
    // assumed to true -
    // any error must set to false
    valid: true,
    // contains field validations
    fieldValidations: {},
  };

  const invalid = (field: string, message: string) => {
    validationResult.valid = false;
    validationResult.fieldValidations[`${MechanismType.Spot}-${field}`] =
      message;
  };

  const usedTokens: { [key: string]: boolean } = {};
  marketMechanism.quoteParameters.forEach((quoteParameter, qpIdx) => {
    if (usedTokens[quoteParameter.quoteToken.string()]) {
      invalid(`${qpIdx}-quoteToken`, "Duplicate");
    } else {
      usedTokens[quoteParameter.quoteToken.string()] = true;
    }

    if (quoteParameter.minimumDealSize.value.isLessThan(BigNumber("0.00002"))) {
      invalid(`${qpIdx}-minimumDealSize`, "Must be >= 0.00002");
    }

    if (
      !quoteParameter.maximumDealSize.value.isGreaterThan(
        quoteParameter.minimumDealSize.value,
      )
    ) {
      invalid(`${qpIdx}-maximumDealSize`, "Must be > Minimum");
    }
  });

  return validationResult;
}

import React, { useState } from "react";
import {
  DateTimeField,
  TextNumField,
  TextField,
} from "@mesh/common-js-react/dist/FormFields";
import { RecordRateSourceRequest } from "@mesh/common-js/dist/financial/rateSourceRecorder_pb";
import { dayjsToProtobufTimestamp } from "@mesh/common-js/dist/googleProtobufConverters";
import { timezoneToString } from "@mesh/common-js/dist/i8n";
import { bigNumberToDecimal } from "@mesh/common-js/dist/num";
import { Decimal } from "@mesh/common-js/dist/num/decimal_pb";
import { LoadingButton } from "@mui/lab";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Tooltip,
  IconButton,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { Box } from "@mui/system";
import { BigNumber } from "bignumber.js";
import { useAPIContext } from "context/API";
import { useApplicationContext } from "context/Application/Application";
import dayjs from "dayjs";
import { enqueueSnackbar } from "notistack";
import { Info as InfoIcon } from "@mui/icons-material";
import { RateSource } from "@mesh/common-js/dist/financial/rateSource_pb";

type RecordRateSourceDialogProps = {
  closeDialog: () => void;
  onRateSourceRecorded: () => void;
  rateSourceToRecord: RateSource;
};

export const RecordRateSourceDialog = (props: RecordRateSourceDialogProps) => {
  const { authContext } = useApplicationContext();
  const [formState, setFormState] = useState({
    recordRateSourceRequest: new RecordRateSourceRequest()
      .setContext(authContext.toFuture())
      .setDate(
        dayjsToProtobufTimestamp(dayjs().utc().startOf("day").set("h", -2)),
      )
      .setRate(bigNumberToDecimal(new BigNumber(10.12)))
      .setRatesourceid(props.rateSourceToRecord.getId()),
  });
  const {
    financial: { rateSourceRecorder },
  } = useAPIContext();
  const [loading, setLoading] = useState(false);

  const recordRate = async () => {
    setLoading(true);
    try {
      await rateSourceRecorder.recordRateSource(
        formState.recordRateSourceRequest,
      );
      enqueueSnackbar({
        message: `${props.rateSourceToRecord.getName()} Recorded`,
        variant: "success",
      });
      props.onRateSourceRecorded();
      props.closeDialog();
    } catch (e) {
      enqueueSnackbar({
        message: `error recording rate: ${e}`,
        variant: "error",
      });
    } finally {
      setLoading(false);
    }
  };

  return (
    <Dialog open onClose={props.closeDialog}>
      <DialogTitle>
        Record {props.rateSourceToRecord.getName()}
        <Box sx={{ alignSelf: "center" }}>
          <IconButton
            id={"recordRateSourceDialog-close-iconButton"}
            size={"small"}
            onClick={props.closeDialog}
          >
            <CloseIcon />
          </IconButton>
        </Box>
      </DialogTitle>
      <DialogContent>
        <Box sx={{ display: "flex", alignItems: "center", pt: 1 }}>
          <TextField
            label="Timezone"
            readOnly
            value={timezoneToString(props.rateSourceToRecord.getTimezone())}
            sx={{ width: "182px" }}
          />
          <Tooltip title="The timezone in which times will be shown on the form. All timezones stored in UTC.">
            <InfoIcon sx={{ ml: 1 }} />
          </Tooltip>
        </Box>
        <DateTimeField
          timezone={props.rateSourceToRecord.getTimezone()}
          disabled={loading}
          label="Date"
          value={formState.recordRateSourceRequest.getDate()}
          onChange={(newValue) =>
            setFormState({
              recordRateSourceRequest:
                formState.recordRateSourceRequest.setDate(newValue),
            })
          }
        />
        <TextNumField
          disabled={loading}
          fullWidth
          label="Rate"
          value={formState.recordRateSourceRequest.getRate()}
          onChange={(newValue: Decimal) =>
            setFormState({
              recordRateSourceRequest:
                formState.recordRateSourceRequest.setRate(newValue),
            })
          }
        />
      </DialogContent>
      <DialogActions>
        <LoadingButton
          color="primary"
          variant="contained"
          onClick={recordRate}
          loading={loading}
        >
          Record
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};

import React from "react";
import { Box, Theme, useMediaQuery } from "@mui/material";
import meshMiniLogo from "assets/images/logo/meshLogo.svg";
import { SetupIndividualClientProfileCard } from "./components/individualClient/SetupIndividualClientProfileCard";
import background from "assets/images/background/background.png";
import { SetupCompanyClientProfile } from "./components/companyClient/SetupCompanyClientProfile";
import { ClientKind } from "james/client/Client";
import { useApplicationContext } from "context/Application/Application";

export function SetupClientProfile() {
  const smUp = useMediaQuery((theme: Theme) => theme.breakpoints.up("sm"));
  const { loginClaims } = useApplicationContext();

  return (
    <>
      {smUp ? (
        <Box
          sx={{
            position: "relative",
            display: "flex",
            flexDirection: "column",
            backgroundImage: `url(${background})`,
            backgroundSize: "cover",
            height: "100vh",
            overflowY: {
              xs: "auto",
              md: "hidden",
            },
            width: "100%",
          }}
        >
          <Box
            sx={{
              width: 168,
              height: 77,
              position: "fixed",
            }}
          >
            <img alt="" src={meshMiniLogo} />
          </Box>
          <Box
            className="meshScroll"
            sx={{
              height: "100%",
              display: "grid",
              gridTemplateRows: `
                "a"
                "b"
                "c"
              `,
              justifyContent: "center",
              minWidth: "100vw",
              overflowY: "auto",
            }}
          >
            <Box
              sx={{
                gridArea: "b",
                pb: 2,
                pt: 4,
              }}
            >
              {loginClaims.clientKind === ClientKind.IndividualType ? (
                <SetupIndividualClientProfileCard />
              ) : (
                <SetupCompanyClientProfile />
              )}
            </Box>
          </Box>
        </Box>
      ) : loginClaims.clientKind === ClientKind.IndividualType ? (
        <SetupIndividualClientProfileCard />
      ) : (
        <SetupCompanyClientProfile />
      )}
    </>
  );
}

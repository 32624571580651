import React, { useEffect, useMemo, useState } from "react";
import {
  Box,
  Button,
  Divider,
  MenuItem,
  Dialog,
  DialogContent,
  DialogProps,
  DialogTitle,
  IconButton,
  Typography,
  FormControlLabel,
  Checkbox,
  Tooltip,
  CircularProgress,
} from "@mui/material";
import { Model as UnderwritingViewModel } from "james/views/marketSubscriptionUnderwritingView";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import { TextField } from "components/FormFields";
import { Amount } from "components/Ledger/Amount";
import { Close } from "@mui/icons-material";
import { SubscriptionUnderwritingStateController } from "james/market/SubscriptionUnderwritingStateController";
import { useApplicationContext } from "context/Application/Application";
import { useAccountContext } from "context/Account/Account";
import { IDIdentifier } from "james/search/identifier";
import {
  Model as StellarAccount,
  Balance,
} from "@mesh/common-js/dist/views/stellarAccountView/model_pb";
import { getAvailableBalance } from "@mesh/common-js/dist/views/stellarAccountView";
import { MechanismType } from "james/market";
import { Reader } from "james/views/marketListingView";
import { TextExactCriterion } from "james/search/criterion";
import { useSnackbar } from "notistack";
import { useErrorContext } from "context/Error";
import {
  LedgerAccountCategory,
  Amount as LedgerAmount,
  Token,
} from "james/ledger";
import { TransactionNotificationChannel } from "james/ledger/TransactionNotificationChannel";
import {
  TransactionFailedNotification,
  TransactionFailedNotificationTypeName,
  TransactionSubmissionResolutionFailedNotification,
  TransactionSubmissionResolutionFailedNotificationTypeName,
  TransactionSucceededNotification,
  TransactionSucceededNotificationTypeName,
} from "james/ledger/TransactionNotifications";
import { useNotificationContext } from "context/Notification";
import { Notification } from "james/notification/Notification";
import { Decimal } from "@mesh/common-js/dist/num/decimal_pb";
import { decimalToBigNumber } from "@mesh/common-js/dist/num";

interface CommitFundsDialogProps extends DialogProps {
  setCommitFundsDialog: (show: boolean) => void;
  underWritingViewModel: UnderwritingViewModel;
}

type AccountAndBalanceType = {
  account: StellarAccount;
  balance: Balance;
  signatoryRights: boolean;
};

export const CommitFundsDialog = ({
  setCommitFundsDialog,
  underWritingViewModel,
  ...props
}: CommitFundsDialogProps) => {
  const { authContext } = useApplicationContext();
  const [selectedAccountIndex, setSelectedAccountIndex] = useState(0);
  const { enqueueSnackbar } = useSnackbar();
  const { errorContextErrorTranslator } = useErrorContext();
  const { registerNotificationCallback } = useNotificationContext();

  const [confirmation, setConfirmation] = useState(false);

  const { stellarAccountContext } = useAccountContext();
  const [accounts, setAccounts] = useState<AccountAndBalanceType[]>([]);

  // loading
  const [fundingInProgress, setFundingInProgress] = useState(false);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const deb = setTimeout(async () => {
      setLoading(true);
      try {
        const ReadResponse = await Reader.ReadOne({
          context: authContext,
          criteria: {
            assetID: TextExactCriterion(underWritingViewModel.assetID),
          },
        });

        let _accounts: AccountAndBalanceType[] = [];

        for (let i = 0; i < stellarAccountContext.accounts.length; i++) {
          const balance = stellarAccountContext.accounts[i]
            .getBalancesList()
            .find((balance) => {
              if (
                Token.fromFutureToken(
                  balance.getTokenviewmodel()?.getToken(),
                ).isEqualTo(
                  ReadResponse.model.getMarketMechanismQuoteParameter(
                    MechanismType.Subscription,
                    underWritingViewModel.currentObligationAmount.token,
                  ).quoteToken,
                )
              )
                return balance;
            });

          if (
            stellarAccountContext.accounts[i].getOwnerid() ===
              underWritingViewModel.ownerID &&
            stellarAccountContext.accounts[i].getLabel() ===
              LedgerAccountCategory.Trading
          ) {
            _accounts = _accounts.concat({
              account: stellarAccountContext.accounts[i],
              balance: balance ?? new Balance(),
              signatoryRights:
                await stellarAccountContext.checkUserSignatoryOnAccount(
                  IDIdentifier(stellarAccountContext.accounts[i].getId()),
                ),
            });
          }
        }

        setAccounts(_accounts);
        setLoading(false);
        if (_accounts.length === 0) {
          enqueueSnackbar(`error fetching account information`, {
            variant: "error",
          });
          setCommitFundsDialog(false);
        }
      } catch (e) {
        setLoading(false);
        const err = errorContextErrorTranslator.translateError(e);
        console.error(
          `error fetching account information:
           ${err.message ? err.message : err.toString()}`,
        );
        enqueueSnackbar(
          `error fetching account information: ${
            err.message ? err.message : err.toString()
          }`,
          { variant: "error" },
        );
      }
    });
    return () => {
      clearTimeout(deb);
      setLoading(false);
    };
  }, []);

  const insufficientFunds =
    accounts.length > 0 &&
    decimalToBigNumber(
      getAvailableBalance(accounts[selectedAccountIndex].balance).getValue() ??
        new Decimal(),
    ).lt(underWritingViewModel.currentObligationAmount.value);

  const commitDisabled = useMemo(() => {
    if (loading) return "Loading accounts";
    if (accounts.length === 0) return "Could not load accounts";
    if (!accounts[selectedAccountIndex].signatoryRights)
      return "User is not a signatory on the selected account";
    if (insufficientFunds) return "Insufficient Funds";
    if (!confirmation) return "Confirmation must be selected";
    if (fundingInProgress) return "Funding in progress";
    return "";
  }, [loading, fundingInProgress, accounts, insufficientFunds, confirmation]);

  const Fund = async () => {
    setFundingInProgress(true);
    const accountToFund = accounts[selectedAccountIndex];
    if (!accountToFund) {
      console.error("account to fund with has not been selected");
      enqueueSnackbar("Error! Underwriting Funding Failed", {
        variant: "error",
      });
      setFundingInProgress(false);
      return;
    }

    try {
      const fundSubscriptionUnderwritingIDResponse =
        await SubscriptionUnderwritingStateController.FundSubscriptionUnderwritingID(
          {
            context: authContext,
            accountID: accountToFund.account.getId(),
            subscriptionUnderwritingID:
              underWritingViewModel.subscriptionUnderwritingID,
          },
        );
      enqueueSnackbar("Funding in Progress", {
        variant: "success",
      });

      try {
        // register callback to fire once the deposit has been submitted
        const deregister = await registerNotificationCallback(
          new TransactionNotificationChannel({
            transactionID: fundSubscriptionUnderwritingIDResponse.transactionID,
            private: true,
          }),
          [
            TransactionSucceededNotificationTypeName,
            TransactionFailedNotificationTypeName,
            TransactionSubmissionResolutionFailedNotificationTypeName,
          ],
          (n: Notification) => {
            if (n instanceof TransactionSucceededNotification) {
              enqueueSnackbar("Success! Underwriting Funding Complete", {
                variant: "success",
              });
            }

            if (n instanceof TransactionFailedNotification) {
              enqueueSnackbar("Error! Underwriting Funding Failed", {
                variant: "error",
              });
            }

            if (
              n instanceof TransactionSubmissionResolutionFailedNotification
            ) {
              enqueueSnackbar(
                "Warning! Something has gone wrong with the deposit and its status is being investigated",
                { variant: "warning" },
              );
            }
            deregister();
          },
        );
      } catch (e) {
        console.error(
          "error registering for funding transaction notifications",
          e,
        );
        enqueueSnackbar(
          "Warning! Unable to Register for Notifications on Funding Transaction - Please Refresh to Monitor.",
          { variant: "warning" },
        );
      }

      setFundingInProgress(false);
      setCommitFundsDialog(false);
    } catch (e) {
      setFundingInProgress(false);
      setCommitFundsDialog(false);
      const err = errorContextErrorTranslator.translateError(e);
      console.error(`error performing funding`, e);
      enqueueSnackbar(`Error Performing Funding: ${err}`, { variant: "error" });
    }
  };

  return (
    <Dialog
      {...props}
      PaperProps={{
        sx: {
          width: 578,
        },
      }}
    >
      <DialogTitle>
        <Typography>Commit Funds</Typography>
        {loading || fundingInProgress ? (
          <CircularProgress
            sx={{ width: "16px", height: "16px", ml: "auto", p: 1 }}
          />
        ) : (
          <IconButton
            sx={{
              ml: "auto",
            }}
            aria-label="close-dialog"
            onClick={() => setCommitFundsDialog(false)}
          >
            <Close />
          </IconButton>
        )}
      </DialogTitle>
      <DialogContent
        sx={{
          p: 3,
          pb: 5,
        }}
      >
        <Box
          sx={(theme) => ({
            color: theme.palette.text.primary,
            display: "flex",
            flexDirection: "column",
            gap: 1,
            mt: 4,
          })}
        >
          <Typography>Commitment Details</Typography>
          <Typography fontWeight={600}>
            {underWritingViewModel.assetName}
          </Typography>
        </Box>
        <Divider sx={{ mt: 1, mb: 2 }} />
        <Box
          sx={{
            display: "grid",
            gridTemplateColumns: "1fr 1fr",
            my: 3,
            gap: 5,
          }}
        >
          <TextField
            select
            label="Name"
            disabled
            value={selectedAccountIndex}
            onChange={(v) => setSelectedAccountIndex(+v.target.value)}
            helperText={
              <>
                {accounts.length > 0 && (
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      gap: 0.5,
                    }}
                  >
                    <Typography variant="body2">Available</Typography>
                    <Amount
                      codeTypographyProps={{
                        variant: "body2",
                      }}
                      valueTypographyProps={{
                        variant: "body2",
                      }}
                      amount={LedgerAmount.fromFutureAmount(
                        getAvailableBalance(
                          accounts[selectedAccountIndex].balance,
                        ),
                      )}
                    />
                  </Box>
                )}
              </>
            }
          >
            {accounts.length > 0 ? (
              accounts.map((a, idx) => (
                <MenuItem key={idx} value={idx}>
                  {a.account.getLabel()} Account {a.account.getNumber()}
                </MenuItem>
              ))
            ) : (
              <MenuItem key={0} value={0}>
                Loading accounts...
              </MenuItem>
            )}
          </TextField>
          <Box sx={{ mt: 0.5 }}>
            <Typography>Amount</Typography>
            <Amount amount={underWritingViewModel.currentObligationAmount} />
          </Box>
        </Box>
        <Box
          sx={(theme) => ({
            display: "flex",
            color: theme.palette.custom.darkYellow,
            alignItems: "center",
            gap: 2,
            mb: 1,
          })}
        >
          <ErrorOutlineIcon />
          <Typography variant="body2">
            Funds will be committed and not withdrawable/ refundable until issue
            date and settlement has occurred
          </Typography>
        </Box>

        <FormControlLabel
          control={<Checkbox />}
          value={confirmation}
          onChange={(e, checked) => setConfirmation(checked)}
          label={"I confirm that funds can be committed."}
        />

        <Box
          sx={{
            display: "flex",
            gap: 2,
            justifyContent: "flex-end",
            mt: 3,
          }}
        >
          <Button
            variant="outlined"
            disabled={loading || fundingInProgress}
            onClick={() => setCommitFundsDialog(false)}
          >
            Cancel
          </Button>
          <Tooltip title={commitDisabled}>
            <span>
              <Button
                color="primary"
                variant="contained"
                disabled={!!commitDisabled}
                onClick={Fund}
              >
                Commit Funds
              </Button>
            </span>
          </Tooltip>
        </Box>
      </DialogContent>
    </Dialog>
  );
};

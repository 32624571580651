import { BigNumber } from "bignumber.js";
import { Amount, Token } from "james/ledger";
import { AuditEntry } from "james/audit";
import dayjs from "dayjs";

export enum SpotType {
  Buy = "Buy",
  Sell = "Sell",
}

export const AllSpotTypes: SpotType[] = [SpotType.Buy, SpotType.Sell];

export enum SpotState {
  Pending = "Pending",
  Settled = "Settled",
  Failed = "Failed",
  UnderInvestigation = "Under Investigation",
}

export const AllSpotStates: SpotState[] = [
  SpotState.Pending,
  SpotState.Settled,
  SpotState.Failed,
  SpotState.UnderInvestigation,
];

export class Spot {
  public id = "";
  public ownerID = "";
  public sourceAccountLedgerID = "";
  public number = "";
  public submittedAt: string = dayjs().format();
  public type: SpotType | "" = "";
  public state: SpotState | "" = "";
  public slippage: BigNumber = new BigNumber("0");
  public estimatedPrice: Amount = new Amount();
  public baseAmount: Amount = new Amount();
  public quoteAmount: Amount = new Amount();
  public path: Token[] = [];
  public auditEntry: AuditEntry = new AuditEntry();

  constructor(s?: Spot) {
    if (!s) {
      return;
    }
    this.id = s.id;
    this.ownerID = s.ownerID;
    this.sourceAccountLedgerID = s.sourceAccountLedgerID;
    this.number = s.number;
    this.submittedAt = s.submittedAt;
    this.type = s.type;
    this.state = s.state;
    this.slippage = new BigNumber(s.slippage);
    this.estimatedPrice = new Amount(s.estimatedPrice);
    this.baseAmount = new Amount(s.baseAmount);
    this.quoteAmount = new Amount(s.quoteAmount);
    this.path = s.path.map((t) => new Token(t));
    this.auditEntry = new AuditEntry(s.auditEntry);
  }
}

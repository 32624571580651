import { Signatory } from "@mesh/common-js/dist/stellar/enrichedAccount_pb";
import { Client } from "./Client";
import { Horizon } from "stellar-sdk";
import * as Sentry from "@sentry/react";
import { pasrseSignatoryType as parseSignatoryType } from ".";

export type FetchAccountSignatoriesRequest = {
  ledgerID: string;
};

export type FetchAccountSignatoriesResponse = {
  signatories: Signatory[];
};

export type NewStellarAccountSignatoriesFetcherProps = {
  client: Client;
};

export type NewStellarAccountSignatoriesFetcher = {
  client: Client;
};

export class StellarAccountSignatoriesFetcher {
  private readonly stellarClient: Client;

  constructor(props: NewStellarAccountSignatoriesFetcherProps) {
    this.stellarClient = props.client;
  }

  async FetchAccountSignatories(
    request: FetchAccountSignatoriesRequest,
  ): Promise<FetchAccountSignatoriesResponse> {
    let stellarAccount: undefined | Horizon.ServerApi.AccountRecord = undefined;
    try {
      stellarAccount = await this.stellarClient.accountRecord(request.ledgerID);
    } catch (e) {
      const err = e as Error;
      Sentry.captureException(
        `could not retrieve account from stellar: ${
          err.message ? err.message : err.toString()
        }`,
      );
      throw new Error(
        `could not retrieve account from stellar: ${
          err.message ? err.message : err.toString()
        }`,
      );
    }

    // populate the account signatory details
    const signatories: Signatory[] = [];
    for (const s of stellarAccount.signers) {
      signatories.push(
        new Signatory()
          .setType(parseSignatoryType(s.type))
          .setKey(s.key)
          .setWeight(s.weight),
      );
    }

    return { signatories };
  }
}

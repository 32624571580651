import { BigNumber } from "bignumber.js";

export class CountryAllocation {
  public name = "";

  public percentage: BigNumber = new BigNumber("0");

  constructor(countryAllocation?: CountryAllocation) {
    if (!countryAllocation) {
      return;
    }
    this.name = countryAllocation.name;
    this.percentage = new BigNumber(countryAllocation.percentage);
  }
}

import {
  Autocomplete,
  Box,
  Button,
  Checkbox,
  CircularProgress,
  Collapse,
  Dialog,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  IconButton,
  Link,
  Theme,
  Tooltip,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import React, { useEffect, useRef, useState } from "react";
import meshMiniLogo from "assets/images/logo/meshLogoNoWords.svg";
import {
  Close as CloseIcon,
  KeyboardArrowDown,
  KeyboardArrowUp,
  Warning,
} from "@mui/icons-material";
import { Amount as DEPRECATEDAmount } from "james/ledger/Amount";
import { TextField } from "components/FormFields";
import {
  Model as StellarAccountViewModel,
  TokenViewModel,
} from "@mesh/common-js/dist/views/stellarAccountView/model_pb";
import { useSnackbar } from "notistack";
import cx from "classnames";
import { Amount } from "components/Ledger/Amount";
import { useValidatedForm } from "hooks/useForm";
import { useIsMounted } from "hooks";
import { TokenCategory } from "james/views/ledgerTokenView/Model";
import {
  getTokenBalance,
  getAvailableBalance,
} from "@mesh/common-js/dist/views/stellarAccountView";

import {
  formUpdaterSpecs,
  FormUpdaterSpecsType,
  State,
  UnfilteredValidationResult,
  validationFunc,
} from "views/Accounts/components/TransferDialog/Validation";
import { SkeletonLoader } from "components/SkeletonLoader";
import { useLedgerTokenViewContext } from "context/LedgerTokenView";
import { useAccountContext } from "context/Account/Account";
import { LedgerIDIdentifier } from "james/search/identifier";
import { JSONRPCCallAbortedError } from "utilities/network/jsonRPCRequest";
import { useApplicationContext } from "context/Application/Application";
import { useErrorContext } from "context/Error";
import { ValidationResult } from "common/validation";
import { ManagingCompanyClientName } from "const";
import {
  DataComponentInfo,
  DataLinkInfoType,
  InteractionAction,
  InteractionDriver,
  InteractionType,
} from "const/gtm";
import { TransferConfirmationDialog } from "views/Accounts/components/TransferDialog/components/TransferConfirmationDialog";
import { AmountIncrementField } from "components/FormFields/NumberField";
import { FutureAmount } from "@mesh/common-js/dist/ledger/futureAmount_pb";
import { useAPIContext } from "context/API";
import {
  CalculateAssetTransferFeeRequest,
  CalculateAssetTransferFeeResponse,
} from "@mesh/common-js/dist/market/assetTransferrerFeeCalculator_pb";
import { TextNumField } from "@mesh/common-js-react/dist/FormFields";
import { FutureToken } from "@mesh/common-js/dist/ledger/futureToken_pb";
import {
  amountIsUndefined,
  newAmountFromBigNumber,
  newAmountFromDecimal,
} from "@mesh/common-js/dist/ledger";
import { BigNumber } from "bignumber.js";
import { decimalToBigNumber } from "@mesh/common-js/dist/num";

const PREFIX = "TransferMainDialog";

const classes = {
  dialogTitle: `${PREFIX}-dialogTitle`,
  miniLogoWrapper: `${PREFIX}-miniLogoWrapper`,
  boldText: `${PREFIX}-boldText`,
  disabledTextColor: `${PREFIX}-disabledTextColor`,
  amountTextFieldHelperText: `${PREFIX}-amountTextFieldHelperText`,
  feeAccAndBalancesSection: `${PREFIX}-feeAccAndBalancesSection`,
  feeDetailsWrapper: `${PREFIX}-feeDetailsWrapper`,
  startAdornmentText: `${PREFIX}-startAdornmentText`,
  helperTextLayout: `${PREFIX}-helperTextLayout`,
  errorHelperTextLayout: `${PREFIX}-errorHelperTextLayout`,
  errorColor: `${PREFIX}-errorColor`,
  warningIcon: `${PREFIX}-warningIcon`,
  disabledText: `${PREFIX}-disabledText`,
  feeProgressCircular: `${PREFIX}-feeProgressCircular`,
};

const StyledBox = styled(Box)(({ theme }) => ({
  [`& .${classes.dialogTitle}`]: {
    display: "grid",
    gridTemplateColumns: "auto 1fr",
    alignItems: "center",
    gridColumnGap: theme.spacing(1),
  },

  [`& .${classes.miniLogoWrapper}`]: {
    height: 32,
    display: "flex",
    alignContent: "center",
    justifyContent: "center",
  },

  [`& .${classes.boldText}`]: {
    fontWeight: theme.typography.fontWeightBold,
  },

  [`& .${classes.disabledTextColor}`]: {
    color: theme.palette.text.disabled,
  },

  [`& .${classes.amountTextFieldHelperText}`]: {
    marginLeft: theme.spacing(1),
    marginBottom: theme.spacing(4),
    color: theme.palette.text.secondary,
  },

  [`& .${classes.feeAccAndBalancesSection}`]: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },

  [`& .${classes.feeDetailsWrapper}`]: {
    display: "flex",
  },

  [`& .${classes.startAdornmentText}`]: {
    color: theme.palette.text.disabled,
    marginRight: theme.spacing(0.5),
  },

  [`& .${classes.helperTextLayout}`]: {
    display: "grid",
    gridTemplateColumns: "65px auto",
  },

  [`& .${classes.errorHelperTextLayout}`]: {
    display: "flex",
    color: theme.palette.error.main,
  },

  [`& .${classes.errorColor}`]: {
    color: theme.palette.error.main,
  },

  [`& .${classes.warningIcon}`]: {
    width: 22,
    height: 19,
    marginLeft: theme.spacing(1),
  },

  [`& .${classes.disabledText}`]: {},

  [`& .${classes.feeProgressCircular}`]: {
    marginRight: theme.spacing(0.5),
  },
}));

// Todo: This dialog should stop depending on props and rather open based on url routes
interface TransferMainDialogProps {
  accountID: string; // The account that you are doing the transfer from
  token?: FutureToken; // The token that is being transferred
  open: boolean;
  onClose: () => void;
  tokenisationAllowed: boolean;
}

export enum TransferAccountType {
  StellarAccountType = "Stellar Account Public Key",
  MeshAccountType = "Mesh Account Number",
}

export const TransferMainDialog: React.FunctionComponent<
  TransferMainDialogProps
> = (props) => {
  const { stellar, market } = useAPIContext();
  const { errorContextErrorTranslator, errorContextDefaultErrorFeedback } =
    useErrorContext();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const [loading, setLoading] = useState(true);
  const [tokenViewModel, setTokenViewModel] = useState<
    undefined | TokenViewModel
  >(undefined);
  const [accountModel, setAccountModel] = useState<
    StellarAccountViewModel | undefined
  >(undefined);
  const { enqueueSnackbar } = useSnackbar();
  const [openConfirmationDialog, setOpenConfirmationDialog] = useState(false);
  const [includeReference, setIncludeReference] = useState(false);
  const { authContext } = useApplicationContext();
  const calculateTransferFeesTimeout = useRef<NodeJS.Timeout | undefined>(
    undefined,
  );
  const isMounted = useIsMounted();
  const [feeLoadingInProgress, setFeeLoadingInProgress] = useState(false);
  const [isUserSignatoryOnAccount, setIsUserSignatoryOnAccount] =
    useState(false);
  const { getLedgerTokenViewModel } = useLedgerTokenViewContext();
  const { stellarAccountContext } = useAccountContext();
  const [feePaymentBalance, setFeePaymentBalance] = useState<
    FutureAmount | undefined
  >(undefined);

  const [formState, validationResult, formUpdater, validationInProgress] =
    useValidatedForm<State, FormUpdaterSpecsType>(
      validationFunc,
      undefined,
      formUpdaterSpecs,
      {
        accountInspector: stellar.accountInspector,
        userID: "",
        accountID: "",
        accountType: TransferAccountType.MeshAccountType,
        transferTokenAvailableBalance: newAmountFromBigNumber(
          BigNumber("0"),
          props.token,
        ),
        mZARBalance: newAmountFromBigNumber(BigNumber("0"), new FutureToken()),
        reference: "",
        referenceCheckbox: false,
        transferAmount: newAmountFromBigNumber(BigNumber("0"), props.token),
        transferFees: new CalculateAssetTransferFeeResponse(),
        ledgerAccountModel: new StellarAccountViewModel(),
        tokenCategory: TokenCategory.Unknown,
      },
      new Set<string>(),
    );

  useEffect(() => {
    (async () => {
      if (!isMounted()) {
        return;
      }

      if (stellarAccountContext.loading) {
        return;
      }

      if (stellarAccountContext.error) {
        console.error(`initialization error: ${stellarAccountContext.error}`);
        enqueueSnackbar(
          `Initialization Error: ${stellarAccountContext.error}`,
          {
            variant: "error",
          },
        );

        // close the dialog
        props.onClose();
      }

      // if there was an error retrieving the accounts from the stellarContext
      // show a snackbar and then close the dialog
      if (stellarAccountContext.error) {
        console.error(`initialization error: ${stellarAccountContext.error}`);
        enqueueSnackbar(
          `Initialization Error: ${stellarAccountContext.error}`,
          { variant: "error" },
        );

        // close the dialog
        props.onClose();
      }

      formUpdater.userID(authContext.userID);

      // retrieve the on platform token
      try {
        const ledgerTokenViewModel = await getLedgerTokenViewModel(props.token);
        if (!isMounted()) {
          return;
        }
        setTokenViewModel(
          new TokenViewModel()
            .setId(ledgerTokenViewModel.id)
            .setOwnerid(ledgerTokenViewModel.ownerID)
            .setToken(ledgerTokenViewModel.token.toFutureToken())
            .setTokencategory(ledgerTokenViewModel.tokenCategory)
            .setName(ledgerTokenViewModel.name)
            .setIssuer(ledgerTokenViewModel.issuer)
            .setIconurl(ledgerTokenViewModel.publicIconURL),
        );
      } catch (e) {
        const err = errorContextErrorTranslator.translateError(e);
        console.error(
          `error retrieving on platform token view model: ${
            err.message ? err.message : err.toString()
          }`,
        );
        enqueueSnackbar(
          `Error retrieving on platform token view model: ${
            err.message ? err.message : err.toString()
          }`,
          { variant: "error" },
        );
        return;
      }

      // retrieve the stellar account view model
      const accountViewModel = stellarAccountContext.accounts.find(
        (a) => a.getId() === props.accountID,
      );

      // if the account is not found
      if (!accountViewModel) {
        enqueueSnackbar(`Unexpected error finding the account view model`, {
          variant: "error",
        });
        return;
      }

      setAccountModel(accountViewModel);
      formUpdater.ledgerAccountModel(accountViewModel);

      // retrieve the token balance from the account view model
      const tokenBalance = getTokenBalance(accountViewModel, props.token);
      if (tokenBalance) {
        formUpdater.transferTokenAvailableBalance(
          getAvailableBalance(tokenBalance),
        );
      }

      // retrieve the mZAR token from the account view model
      const mZARBalance = accountViewModel.getBalancesList().find((v) => {
        const tokenViewModel = v.getTokenviewmodel();
        const token = tokenViewModel?.getToken();
        const code = token?.getCode();
        const issuer = tokenViewModel?.getIssuer();

        if (
          code &&
          code.includes("mZAR") &&
          issuer &&
          issuer.includes(ManagingCompanyClientName)
        ) {
          return true;
        }
      });
      if (mZARBalance) {
        formUpdater.mZARBalance(getAvailableBalance(mZARBalance));
      }

      // check if the user is a signatory on the account
      try {
        if (isMounted()) {
          setIsUserSignatoryOnAccount(
            await stellarAccountContext.checkUserSignatoryOnAccount(
              LedgerIDIdentifier(accountViewModel.getLedgerid()),
            ),
          );
        }
      } catch (e) {
        const err = errorContextErrorTranslator.translateError(e);
        console.error(
          `error determining if user is signatory on account: ${
            err.message ? err.message : err.toString()
          }`,
        );
        enqueueSnackbar("Error Determining Signatory Status", {
          variant: "error",
        });
      }
    })().finally(() => setLoading(false));
  }, [
    stellarAccountContext.loading,
    stellarAccountContext.error,
    isMounted,
    formUpdater,
    enqueueSnackbar,
    props.accountID,
    props.token,
  ]);

  const handleCalculateTransferFee = (transferAmount: FutureAmount) => {
    setFeeLoadingInProgress(true);

    clearTimeout(calculateTransferFeesTimeout.current);
    calculateTransferFeesTimeout.current = setTimeout(async () => {
      try {
        const fee =
          await market.assetTransferrerFeeCalculator.calculateAssetTransferFee(
            new CalculateAssetTransferFeeRequest()
              .setContext(authContext.toFuture())
              .setTransferamount(transferAmount),
          );
        if (isMounted()) {
          formUpdater.transferFees(fee);
        }
        const transferFee = formState.transferFees.getFeeamount();
        if (
          transferFee?.getToken()?.getCode() ===
            formState.mZARBalance.getToken()?.getCode() ||
          tokenViewModel?.getTokencategory() === TokenCategory.DigitalInstrument
        ) {
          setFeePaymentBalance(formState.mZARBalance);
        } else {
          setFeePaymentBalance(formState.transferTokenAvailableBalance);
        }
        if (
          formState.tokenCategory === TokenCategory.Unknown &&
          tokenViewModel
        ) {
          formUpdater.tokenCategory(
            tokenViewModel.getTokencategory() as TokenCategory,
          );
        }
      } catch (e) {
        console.error("error calculating asset transfer fee", e);
        const err = errorContextErrorTranslator.translateError(e);
        if (err.code === JSONRPCCallAbortedError.ErrorCode) {
          return;
        }
        if (isMounted()) {
          errorContextDefaultErrorFeedback(err);
        }
      }
      if (isMounted()) {
        setFeeLoadingInProgress(false);
      }
    }, 400);
  };

  return (
    <Dialog
      PaperProps={{
        "data-component-info": JSON.stringify({
          component_id: "fund_account",
          component_business_name: "Fund account transfer information",
          component_title: "transfer information",
          component_driver: InteractionDriver.FundAccount,
        } as DataComponentInfo),
      }}
      fullScreen={isMobile}
      open={props.open}
      sx={{
        "& .MuiDialog-container": {
          "& .MuiPaper-root": {
            width: "100%",
            maxWidth: isMobile ? "100%" : "440px",
          },
        },
      }}
    >
      <DialogTitle
        sx={{
          backgroundColor: theme.palette.custom.spaceblue,
          display: "flex",
          alignItems: "center",
          py: 1,
        }}
      >
        <Box
          sx={{
            height: 32,
            display: "flex",
            alignContent: "center",
            justifyContent: "center",
            mr: 1,
          }}
        >
          <img alt="" width={40} src={meshMiniLogo} />
        </Box>
        <Typography
          variant="h5"
          children={`Transfer ${
            isMobile
              ? ""
              : `from account: ${accountModel ? accountModel.getNumber() : ""}`
          }`}
        />
        <IconButton
          size="small"
          onClick={props.onClose}
          disabled={loading}
          id="transferMainDialog-close-button"
          sx={{ ml: "auto" }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent
        sx={{
          display: "flex",
          flexDirection: "column",
          backgroundColor: theme.palette.custom.midnight,
          boxShadow: "0px -10px 12px -14px #000 inset",
        }}
        className="meshScroll"
      >
        <StyledBox
          sx={{
            p: 3,
          }}
        >
          <SkeletonLoader loading={loading}>
            <Typography
              sx={{
                fontWeight: "bold",
                padding: theme.spacing(0, 0, 4, 0),
              }}
              variant="h4"
              children="Transfer Information"
            />
          </SkeletonLoader>
          {/* Transfer Amount Text-Num-Field */}
          <SkeletonLoader
            loading={loading}
            width={isMobile ? "100%" : ""}
            sx={{
              margin: theme.spacing(3, 0),
            }}
          >
            {props.tokenisationAllowed ? (
              <TextNumField
                fullWidth={isMobile}
                id={"transferMainDialog-amount-formfield"}
                disallowNegative
                sx={[!isMobile && { width: "100%" }]}
                noDecimalPlaces={7}
                label={"Amount"}
                value={formState.transferAmount.getValue()}
                onChange={(e) => {
                  if (tokenViewModel) {
                    const transferAmount = newAmountFromDecimal(
                      e,
                      tokenViewModel.getToken(),
                    );
                    formUpdater.transferAmount(transferAmount);
                    handleCalculateTransferFee(transferAmount);
                  }
                }}
                InputProps={{
                  startAdornment: (() => {
                    if (
                      tokenViewModel
                        ?.getTokencategory()
                        .includes(TokenCategory.RightsToFiatCurrency) ||
                      tokenViewModel
                        ?.getTokencategory()
                        .includes(TokenCategory.CryptoCurrency)
                    ) {
                      return (
                        <Typography
                          className={classes.startAdornmentText}
                          variant="subtitle1"
                          children={tokenViewModel?.getToken()?.getCode()}
                        />
                      );
                    }
                    return undefined;
                  })(),
                  endAdornment: (() => {
                    if (
                      tokenViewModel
                        ?.getTokencategory()
                        .includes(TokenCategory.InstrumentStablecoin) ||
                      tokenViewModel
                        ?.getTokencategory()
                        .includes(TokenCategory.DigitalInstrument)
                    ) {
                      return (
                        <Typography
                          className={classes.startAdornmentText}
                          variant="subtitle1"
                          children={tokenViewModel?.getToken()?.getCode()}
                        />
                      );
                    }
                    return undefined;
                  })(),
                }}
                error={!!validationResult.fieldValidations.transferAmount}
              />
            ) : (
              <AmountIncrementField
                id={"transferMainDialog-amount-formfield"}
                disallowNegative
                noDecimals
                disabled={loading}
                color={
                  validationResult.fieldValidations.transferAmount
                    ? theme.palette.primary.main
                    : theme.palette.secondary.main
                }
                width={isMobile ? "138px" : "108px"}
                variant={isMobile ? "middle-input" : "left-input"}
                value={DEPRECATEDAmount.fromFutureAmount(
                  formState.transferAmount,
                )}
                onChange={(s, n) => {
                  let transferAmount = newAmountFromBigNumber(
                    BigNumber("0"),
                    props.token,
                  );
                  if (s === "") {
                    transferAmount = newAmountFromBigNumber(
                      BigNumber("0"),
                      props.token,
                    );
                  } else if (n.value.isNaN()) {
                    return;
                  } else {
                    transferAmount = newAmountFromBigNumber(
                      n.value,
                      props.token,
                    );
                  }

                  formUpdater.transferAmount(transferAmount);
                  handleCalculateTransferFee(transferAmount);
                }}
              />
            )}
            {/* Transfer Amount helper text */}
            <Box
              sx={[
                {
                  color: theme.palette.text.secondary,
                  margin: theme.spacing(0, 0, 3, 0),
                },
                !!validationResult.fieldValidations.transferAmount && {
                  color: theme.palette.error.main,
                },
              ]}
            >
              {(() => {
                // if there is an error for this formfield  display error helperText
                if (validationResult.fieldValidations.transferAmount) {
                  if (
                    validationResult.fieldValidations.transferAmount.includes(
                      "Insufficient",
                    )
                  ) {
                    return (
                      <div className={classes.errorHelperTextLayout}>
                        <Typography variant="caption">
                          Insufficient balance:&nbsp;
                        </Typography>
                        <Amount
                          reverse={
                            tokenViewModel
                              ?.getTokencategory()
                              .includes(TokenCategory.InstrumentStablecoin) ||
                            tokenViewModel
                              ?.getTokencategory()
                              .includes(TokenCategory.DigitalInstrument)
                          }
                          codeTypographyProps={{
                            variant: "caption",
                          }}
                          valueTypographyProps={{
                            variant: "caption",
                          }}
                          formatTextNumOpts={{ noDecimalPlaces: 7 }}
                          amount={formState.transferTokenAvailableBalance}
                        />
                      </div>
                    );
                  }
                  return (
                    <Typography
                      variant="caption"
                      children={
                        validationResult.fieldValidations.transferAmount
                      }
                    />
                  );
                }

                return (
                  <div className={classes.helperTextLayout}>
                    <Typography variant="caption">Available:</Typography>
                    <Amount
                      reverse={
                        tokenViewModel
                          ?.getTokencategory()
                          .includes(TokenCategory.InstrumentStablecoin) ||
                        tokenViewModel
                          ?.getTokencategory()
                          .includes(TokenCategory.DigitalInstrument)
                      }
                      valueTypographyProps={{
                        variant: "caption",
                      }}
                      codeTypographyProps={{
                        variant: "caption",
                      }}
                      formatTextNumOpts={{ noDecimalPlaces: 7 }}
                      amount={formState.transferTokenAvailableBalance}
                    />
                  </div>
                );
              })()}
            </Box>
          </SkeletonLoader>
          <Box>
            <SkeletonLoader loading={loading}>
              <Typography
                sx={{
                  paddingBottom: theme.spacing(1),
                }}
                variant="h6"
                children="Recipient"
              />
            </SkeletonLoader>
            <SkeletonLoader loading={loading} width={isMobile ? "100%" : ""}>
              <Autocomplete
                isOptionEqualToValue={(option, value) => option === value}
                fullWidth={isMobile}
                id="transferMainDialog-recipientAccountAddressType-autocomplete"
                disableClearable
                value={formState.accountType}
                options={[
                  TransferAccountType.StellarAccountType,
                  TransferAccountType.MeshAccountType,
                ]}
                sx={[
                  { marginBottom: theme.spacing(2) },
                  !isMobile && { width: "100%" },
                  formState.accountType ===
                    TransferAccountType.StellarAccountType && {
                    marginBottom: theme.spacing(1),
                  },
                ]}
                onChange={(e, v) => formUpdater.accountType(v || "")}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Recipient Address Type"
                    variant="outlined"
                    margin="dense"
                    InputLabelProps={{ shrink: true }}
                    helperText={
                      formState.accountType ===
                      TransferAccountType.StellarAccountType
                        ? "Needs to have a linked Mesh Account"
                        : undefined
                    }
                  />
                )}
              />
            </SkeletonLoader>
            <SkeletonLoader loading={loading} width={isMobile ? "100%" : ""}>
              <TextField
                fullWidth={isMobile}
                id="transferMainDialog-accountID-formfield"
                sx={[
                  { marginBottom: theme.spacing(2) },
                  !isMobile && { width: "100%" },
                  !!validationResult.fieldValidations.accountID && {
                    marginBottom: theme.spacing(1),
                  },
                ]}
                value={formState.accountID}
                label="Account ID"
                onChange={(e) => formUpdater.accountID(e.target.value)}
                error={!!validationResult.fieldValidations.accountID}
                helperText={validationResult.fieldValidations.accountID}
              />
            </SkeletonLoader>
          </Box>
          <SkeletonLoader loading={loading}>
            <FormControlLabel
              control={
                <Checkbox
                  data-link-info={JSON.stringify({
                    content_interaction_id: "transfer-information",
                    content_interaction_action: InteractionAction.Click,
                    content_interaction_type: InteractionType.Checkbox,
                    content_interaction_text: "include reference",
                    content_interaction_driver: InteractionDriver.TransferFunds,
                  } as DataLinkInfoType)}
                  name="checkedB"
                  color="secondary"
                  sx={(theme) => ({ marginLeft: theme.spacing(1) })}
                  id="transferDialog-includeReference-checkbox"
                  onChange={() => {
                    formUpdater.referenceCheckbox(!includeReference);
                    setIncludeReference(!includeReference);
                  }}
                />
              }
              label="Include Reference"
            />
          </SkeletonLoader>
          <Collapse in={includeReference}>
            <TextField
              fullWidth={isMobile}
              sx={[
                {
                  marginTop: theme.spacing(1),
                  marginBottom: theme.spacing(2),
                },
                !isMobile && { width: 304 },
              ]}
              label="Reference"
              onChange={(e) => formUpdater.reference(e.target.value)}
              helperText={
                validationResult.fieldValidations.reference
                  ? validationResult.fieldValidations.reference
                  : `${40 - formState.reference.length} Characters Remaining`
              }
              error={!!validationResult.fieldValidations.reference}
            />
          </Collapse>
          {
            // determine whether or not to show fee section based on fee amount
            !decimalToBigNumber(
              formState.transferFees.getFeeamount()?.getValue(),
            ).isZero() && (
              <TransferFees
                loading={loading}
                feeLoadingInProgress={feeLoadingInProgress}
                formState={formState}
                validationResult={validationResult}
                accountModel={accountModel}
                feePaymentBalance={feePaymentBalance}
              />
            )
          }
        </StyledBox>
        {/* Transfer Button Tablet and Desktop */}
        {!isMobile && (
          <Tooltip
            placement="top"
            title={
              UnfilteredValidationResult.fieldValidations.transferHoverText
                ? UnfilteredValidationResult.fieldValidations.transferHoverText
                : !isUserSignatoryOnAccount
                  ? "User is not a signatory on the account"
                  : ""
            }
          >
            <Box
              sx={{
                px: 3,
                mb: 2,
              }}
            >
              {/*box needed for tooltip*/}
              <SkeletonLoader loading={loading}>
                <Button
                  id="transferMainDialog-transfer-button"
                  color="primary"
                  variant="contained"
                  fullWidth
                  data-link-info={JSON.stringify({
                    content_interaction_id: "transfer-information",
                    content_interaction_action: InteractionAction.Click,
                    content_interaction_type: InteractionType.Button,
                    content_interaction_text: "transfer",
                    content_interaction_driver: InteractionDriver.TransferFunds,
                  } as DataLinkInfoType)}
                  disabled={
                    !validationResult.valid ||
                    validationInProgress ||
                    !isUserSignatoryOnAccount
                  }
                  sx={{
                    height: "36px",
                  }}
                  onClick={() => {
                    if (validationResult.valid) {
                      setOpenConfirmationDialog(true);
                    }
                  }}
                  children="Transfer"
                />
              </SkeletonLoader>
            </Box>
          </Tooltip>
        )}
        {/* Fee Collapse component controls section */}
      </DialogContent>
      {isMobile && (
        <DialogTitle
          sx={{
            padding: theme.spacing(3, 3, 5, 3),
          }}
        >
          <Tooltip
            placement="top"
            title={
              UnfilteredValidationResult.fieldValidations.transferHoverText
                ? UnfilteredValidationResult.fieldValidations.transferHoverText
                : !isUserSignatoryOnAccount
                  ? "User is not a signatory on the account"
                  : ""
            }
          >
            <Box>
              <SkeletonLoader loading={loading} width="100%">
                <Button
                  fullWidth
                  id="transferMainDialog-transfer-button"
                  data-link-info={JSON.stringify({
                    content_interaction_id: "transfer-information",
                    content_interaction_action: InteractionAction.Click,
                    content_interaction_type: InteractionType.Button,
                    content_interaction_text: "transfer",
                    content_interaction_driver:
                      InteractionDriver.TransferFundsComplete,
                  } as DataLinkInfoType)}
                  color="primary"
                  variant="contained"
                  sx={{
                    height: 48,
                    "&.Mui-disabled": {
                      pointerEvents: "auto",
                    },
                  }}
                  disabled={
                    !validationResult.valid ||
                    validationInProgress ||
                    !isUserSignatoryOnAccount
                  }
                  onClick={() => {
                    if (validationResult.valid) {
                      setOpenConfirmationDialog(true);
                    }
                  }}
                  children="Transfer"
                />
              </SkeletonLoader>
            </Box>
          </Tooltip>
        </DialogTitle>
      )}
      {openConfirmationDialog && (
        <TransferConfirmationDialog
          open={openConfirmationDialog}
          onClose={() => setOpenConfirmationDialog(false)}
          onTransferSubmissionComplete={() => props.onClose()}
          transferAmount={formState.transferAmount}
          recipientAccountID={formState.accountID}
          fromAccountID={props.accountID}
          recipientAccountType={formState.accountType}
          reference={includeReference ? formState.reference : ""}
          transferFeeSection={
            <TransferFees
              loading={loading}
              feeLoadingInProgress={feeLoadingInProgress}
              formState={formState}
              validationResult={validationResult}
              feePaymentBalance={feePaymentBalance}
            />
          }
          hideTransferFee={
            !decimalToBigNumber(
              formState.transferFees.getFeeamount()?.getValue(),
            ).isZero()
          }
        />
      )}
    </Dialog>
  );
};

interface TransferFeesProps {
  loading: boolean;
  feeLoadingInProgress: boolean;
  formState: State;
  validationResult: ValidationResult;
  tokenViewModel?: TokenViewModel;
  accountModel?: StellarAccountViewModel;
  feePaymentBalance: FutureAmount | undefined;
}

const TransferFees = ({
  loading,
  formState,
  feeLoadingInProgress,
  validationResult,
  tokenViewModel,
  accountModel,
  feePaymentBalance,
}: TransferFeesProps) => {
  const isMobile = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down("sm"),
  );
  const [expandFeeSection, setExpandFeeSection] = useState(false);

  return (
    <Box>
      {!loading && (
        <SkeletonLoader loading={loading}>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
            }}
          >
            <IconButton
              id="transferMainDialog-expand-button"
              data-link-info={JSON.stringify({
                content_interaction_id: "transfer-information",
                content_interaction_action: InteractionAction.Click,
                content_interaction_type: InteractionType.Icon,
                content_interaction_text: "transfer fee",
                content_interaction_driver: InteractionDriver.TransferFunds,
              } as DataLinkInfoType)}
              sx={{
                mr: 1,
              }}
              onClick={() => setExpandFeeSection(!expandFeeSection)}
              size="small"
            >
              {!expandFeeSection && (
                <KeyboardArrowDown
                  data-link-info={JSON.stringify({
                    content_interaction_id: "transfer-information",
                    content_interaction_action: InteractionAction.Click,
                    content_interaction_type: InteractionType.Icon,
                    content_interaction_text: "transfer fee",
                    content_interaction_driver: InteractionDriver.TransferFunds,
                  } as DataLinkInfoType)}
                  color="primary"
                />
              )}
              {expandFeeSection && (
                <KeyboardArrowUp
                  data-link-info={JSON.stringify({
                    content_interaction_id: "transfer-information",
                    content_interaction_action: InteractionAction.Click,
                    content_interaction_type: InteractionType.Icon,
                    content_interaction_text: "transfer fee",
                    content_interaction_driver: InteractionDriver.TransferFunds,
                  } as DataLinkInfoType)}
                  color="primary"
                />
              )}
            </IconButton>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                flexWrap: "wrap",
              }}
            >
              <Typography
                color="textSecondary"
                variant="body1"
                sx={(theme) => ({
                  minWidth: "92px",
                  mr: 1,
                  ...(feeLoadingInProgress && {
                    color: theme.palette.action.disabled,
                  }),
                })}
                children="Total Fee:"
              />
              <Box
                sx={{
                  display: "flex",
                }}
              >
                {formState.transferFees && (
                  <Amount
                    valueTypographyProps={{
                      className: cx(
                        {
                          [classes.errorColor]:
                            validationResult.fieldValidations.transferFees?.includes(
                              "Insufficient",
                            ),
                        },
                        { [classes.disabledText]: feeLoadingInProgress },
                      ),
                    }}
                    codeTypographyProps={{
                      className: cx(
                        {
                          [classes.errorColor]:
                            validationResult.fieldValidations.transferFees?.includes(
                              "Insufficient",
                            ),
                        },
                        { [classes.disabledText]: feeLoadingInProgress },
                      ),
                    }}
                    formatTextNumOpts={{
                      noDecimalPlaces:
                        isMobile &&
                        validationResult.fieldValidations.transferFees
                          ? 5
                          : 7,
                    }}
                    amount={(() => {
                      const transferFee = formState.transferFees.getFeeamount();
                      return amountIsUndefined(transferFee)
                        ? newAmountFromBigNumber(
                            BigNumber("0"),
                            formState.mZARBalance.getToken(),
                          )
                        : transferFee;
                    })()}
                  />
                )}
                {formState.transferFees &&
                  tokenViewModel &&
                  (tokenViewModel
                    .getTokencategory()
                    .includes(TokenCategory.DigitalInstrument) ||
                    tokenViewModel
                      .getTokencategory()
                      .includes(TokenCategory.InstrumentStablecoin)) && (
                    <Typography
                      color="primary"
                      component="span"
                      variant="caption"
                      children="*"
                    />
                  )}
                {validationResult.fieldValidations.transferFees && (
                  <Warning
                    className={cx(classes.errorColor, classes.warningIcon)}
                  />
                )}
                {feeLoadingInProgress && (
                  <CircularProgress
                    className={classes.feeProgressCircular}
                    size={15}
                  />
                )}
              </Box>
            </Box>
          </Box>
        </SkeletonLoader>
      )}
      <Collapse
        sx={{
          ml: 5,
        }}
        in={expandFeeSection}
      >
        <div className={classes.feeDetailsWrapper}>
          <Typography
            color="textSecondary"
            variant="caption"
            className={cx({ [classes.disabledText]: feeLoadingInProgress })}
          >
            Transfer Fee:&nbsp;
          </Typography>
          {formState.transferFees && (
            <Amount
              amount={(() => {
                const transferFee = formState.transferFees.getFeeamount();
                return amountIsUndefined(transferFee)
                  ? newAmountFromBigNumber(
                      BigNumber("0"),
                      formState.mZARBalance.getToken(),
                    )
                  : transferFee;
              })()}
              valueTypographyProps={{
                className: cx(
                  {
                    [classes.errorColor]:
                      validationResult.fieldValidations.transferFees?.includes(
                        "Insufficient",
                      ),
                  },
                  { [classes.disabledText]: feeLoadingInProgress },
                ),
                variant: "caption",
              }}
              codeTypographyProps={{
                className: cx(
                  {
                    [classes.errorColor]:
                      validationResult.fieldValidations.transferFees?.includes(
                        "Insufficient",
                      ),
                  },
                  { [classes.disabledText]: feeLoadingInProgress },
                ),
                variant: "caption",
              }}
            />
          )}
        </div>

        <div className={classes.feeDetailsWrapper}>
          <Typography
            color="textSecondary"
            variant="caption"
            className={cx({ [classes.disabledText]: feeLoadingInProgress })}
          >
            VAT on Fee:&nbsp;
          </Typography>
          {formState.transferFees && (
            <Amount
              amount={(() => {
                const vat = formState.transferFees.getVatamount();
                return amountIsUndefined(vat)
                  ? newAmountFromBigNumber(
                      BigNumber("0"),
                      formState.mZARBalance.getToken(),
                    )
                  : vat;
              })()}
              formatTextNumOpts={{ noDecimalPlaces: 7 }}
              valueTypographyProps={{
                className: cx(
                  {
                    [classes.errorColor]:
                      validationResult.fieldValidations.transferFees?.includes(
                        "Insufficient",
                      ),
                  },
                  { [classes.disabledText]: feeLoadingInProgress },
                ),
                variant: "caption",
              }}
              codeTypographyProps={{
                className: cx(
                  {
                    [classes.errorColor]:
                      validationResult.fieldValidations.transferFees?.includes(
                        "Insufficient",
                      ),
                  },
                  { [classes.disabledText]: feeLoadingInProgress },
                ),
                variant: "caption",
              }}
            />
          )}
        </div>
        {/* why these fees link */}
        <br />
        <Box
          component={isMobile ? Button : Link}
          variant={isMobile ? "text" : "body1"}
          underline={"hover"}
          sx={(theme) => ({
            cursor: "pointer",
            color: feeLoadingInProgress
              ? theme.palette.text.disabled
              : theme.palette.secondary.main,
            padding: {
              sm: 0,
              xs: theme.spacing(2, 2, 1, 0),
            },
          })}
          target="_blank"
          href="https://mesh.trade/fees"
          children="Why these fees?"
        />
        {/* Fee Acc  and available balance section */}
        <div className={classes.feeAccAndBalancesSection}>
          <div className={classes.feeDetailsWrapper}>
            <Typography
              variant="caption"
              color="textSecondary"
              className={cx({ [classes.disabledText]: feeLoadingInProgress })}
            >
              Fee Acc:&nbsp;
            </Typography>
            <Typography
              variant="caption"
              component="span"
              className={cx({ [classes.disabledText]: feeLoadingInProgress })}
            >
              {accountModel
                ? `${accountModel.getGroupname()} ${accountModel.getLabel()}`
                : ""}
            </Typography>
          </div>

          <div className={classes.feeDetailsWrapper}>
            <Typography
              color="textSecondary"
              variant="caption"
              className={cx({ [classes.disabledText]: feeLoadingInProgress })}
            >
              Available:&nbsp;
            </Typography>
            {feePaymentBalance && (
              <Amount
                amount={feePaymentBalance}
                valueTypographyProps={{
                  className: cx(
                    {
                      [classes.errorColor]:
                        validationResult.fieldValidations.transferFees?.includes(
                          "Insufficient",
                        ),
                    },
                    { [classes.disabledText]: feeLoadingInProgress },
                  ),
                  variant: "caption",
                }}
                codeTypographyProps={{
                  className: cx(
                    {
                      [classes.errorColor]:
                        validationResult.fieldValidations.transferFees?.includes(
                          "Insufficient",
                        ),
                    },
                    { [classes.disabledText]: feeLoadingInProgress },
                  ),
                  variant: "caption",
                }}
              />
            )}
            {validationResult.fieldValidations.transferFees?.includes(
              "Insufficient",
            ) &&
              !isMobile && (
                <Typography
                  className={cx(classes.errorColor, {
                    [classes.disabledText]: feeLoadingInProgress,
                  })}
                  color="textSecondary"
                  variant="caption"
                >
                  &nbsp;- Insufficient Funds
                </Typography>
              )}
          </div>
          {isMobile &&
            validationResult.fieldValidations.transferFees?.includes(
              "Insufficient",
            ) && (
              <Typography
                variant="caption"
                sx={(theme) => ({
                  padding: theme.spacing(0, 0, 1, 8),
                  color: feeLoadingInProgress
                    ? theme.palette.text.disabled
                    : theme.palette.error.main,
                })}
              >
                - Insufficient Funds
              </Typography>
            )}
        </div>
        {tokenViewModel &&
          (tokenViewModel
            .getTokencategory()
            .includes(TokenCategory.DigitalInstrument) ||
            tokenViewModel
              .getTokencategory()
              .includes(TokenCategory.InstrumentStablecoin)) && (
            <Typography component="p" variant="caption">
              <Typography
                color="primary"
                component="span"
                variant="caption"
                children="*"
              />
              Fee was calculated using a quoted price and might change at the
              time of transacting.
            </Typography>
          )}
      </Collapse>
    </Box>
  );
};

import React, { useEffect, useRef, useState } from "react";
import { Header } from "components/Cards/MarketDirectOrderViewCard/Components/Header";
import { OrderDirectionAndState } from "components/Cards/MarketDirectOrderViewCard/Components/OrderDirectionAndState";
import { AvatarAndDirection } from "components/Cards/MarketDirectOrderViewCard/Components/AvatarAndDirection";
import {
  Box,
  Button,
  Tooltip,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { DirectOrderState, DirectOrderType } from "james/market/DirectOrder";
import { Amount } from "components/Ledger/Amount";
import cx from "classnames";
import { Balance } from "@mesh/common-js/dist/views/stellarAccountView/model_pb";
import { useSnackbar } from "notistack";
import {
  AcceptDirectOrderIDRequest,
  DirectOrderStateController,
} from "james/market/DirectOrderStateController";
import { useIsMounted } from "hooks";
import { ValidationResult } from "common/validation";
import {
  MarketDirectOrderViewModelChangedNotification,
  MarketDirectOrderViewModelChangedNotificationTypeName,
  MarketDirectOrderViewNotificationChannelName,
  Model as MarketDirectOrderViewModel,
} from "james/views/marketDirectOrderView";
import { WarningDialog } from "components/Dialogs/WarningDialog";
import { WarningDialogOptions } from "views/InstrumentBuilder/Instruments/common";
import { GroupNotificationChannel } from "james/group";
import { Amount as LedgerAmount } from "james/ledger";
import { Notification } from "james/notification/Notification";
import { useNotificationContext } from "context/Notification";
import { InvestorAwaitingConfirmationCardProps } from "./InvestorAwaitingConfirmationCard";
import { useApplicationContext } from "context/Application/Application";
import { FundAccountDialog } from "views/Accounts/components/FundAccountDialog/FundAccountDialog";
import { StyledCard, classes } from "../cardStyle";
import { useErrorContext } from "context/Error";
import { decimalToBigNumber } from "@mesh/common-js/dist/num";

const dontHaveEnoughTokensHelperText = "You don't have enough tokens";
const dontHaveEnoughFundsHelperText = "You don't have enough funds";

function performValidation(
  marketDirectOrderViewModel: MarketDirectOrderViewModel,
  assetValuationTokenBalance: Balance,
  assetIssuanceTokenBalance: Balance,
): ValidationResult {
  // prepare a validation result
  const validationResult: ValidationResult = {
    // assumed to be true -
    // any error must set to false regardless of touched field state
    valid: true,
    // field validations
    fieldValidations: {},
  };

  if (marketDirectOrderViewModel.orderType === DirectOrderType.Buy) {
    // if investor is counterparty to a buy order
    // then confirm that they have sufficient balance to give the issuer the
    // tokens that they have paid for
    if (
      decimalToBigNumber(assetIssuanceTokenBalance.getAmount()?.getValue()).lt(
        marketDirectOrderViewModel.tokens.value,
      )
    ) {
      // balance insufficient so validation has failed
      validationResult.valid = false;

      // and an error message should be shown
      validationResult.fieldValidations.tokens = dontHaveEnoughTokensHelperText;
    }
  }

  if (marketDirectOrderViewModel.orderType === DirectOrderType.Sell) {
    // if the investor is counterparty to a sell order
    // then confirm that they have sufficient balance to give the investor the
    // amountIncl (minus fee) that they are giving for tokens
    if (
      decimalToBigNumber(assetValuationTokenBalance.getAmount()?.getValue()).lt(
        marketDirectOrderViewModel.amountIncl.value,
      )
    ) {
      // balance insufficient so validation has failed
      validationResult.valid = false;

      // and an error message should be shown
      validationResult.fieldValidations.amountIncl =
        dontHaveEnoughFundsHelperText;
    }
  }

  return validationResult;
}

export function CounterpartyCard(props: InvestorAwaitingConfirmationCardProps) {
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up("lg"));
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const isMounted = useIsMounted();
  const { errorContextErrorTranslator } = useErrorContext();
  const { enqueueSnackbar } = useSnackbar();
  const { authContext, viewConfiguration } = useApplicationContext();
  const ordersViewConfiguration = viewConfiguration.Marketplace
    ? viewConfiguration.Marketplace
      ? viewConfiguration.Marketplace.Orders
      : {}
    : {};
  const isBuy =
    props.marketDirectOrderViewModel.orderType === DirectOrderType.Buy;
  const [showFundAccDialog, setShowFundAccDialog] = useState(false);
  const { registerNotificationCallback } = useNotificationContext();
  const [
    investorAcceptDirectOrderRequest,
    setInvestorAcceptDirectOrderRequest,
  ] = useState<AcceptDirectOrderIDRequest>({
    context: authContext,
    directOrderID: props.marketDirectOrderViewModel.directOrderID,
    price: props.marketMechanismQuoteParameter.quoteToken.newAmountOf("0"),
  });

  useEffect(() => {
    setInvestorAcceptDirectOrderRequest({
      ...investorAcceptDirectOrderRequest,
      directOrderID: props.marketDirectOrderViewModel.directOrderID,
    });
  }, [props.marketDirectOrderViewModel.directOrderID]);

  // form validation each time request changes
  const [validationInProgress, setValidationInProgress] = useState(false);
  const [requestValidationResult, setRequestValidationResult] =
    useState<ValidationResult>({
      valid: false,
      fieldValidations: {},
    });
  const validationTimeoutRef = useRef<NodeJS.Timeout | undefined>(undefined);
  useEffect(() => {
    if (!isMounted()) {
      return;
    }

    // clear any pending validation
    clearTimeout(validationTimeoutRef.current);

    // defer validation to take place in 200ms
    setValidationInProgress(true);
    clearTimeout(validationTimeoutRef.current);
    validationTimeoutRef.current = setTimeout(() => {
      setRequestValidationResult(
        performValidation(
          props.marketDirectOrderViewModel,
          props.assetValuationTokenBalance,
          props.assetIssuanceTokenBalance,
        ),
      );
      setValidationInProgress(false);
    }, 200);
  }, [
    isMounted,
    props.marketDirectOrderViewModel,
    props.assetValuationTokenBalance,
    props.assetIssuanceTokenBalance,
  ]);

  const [declineInProgress, setDeclineInProgress] = useState(false);
  const [warningDialogOptions, setWarningDialogOptions] =
    useState<WarningDialogOptions | null>(null);
  const handleDecline = async () => {
    setDeclineInProgress(true);
    try {
      // Decline direct order
      await DirectOrderStateController.DeclineDirectOrderID({
        context: authContext,
        directOrderID: props.marketDirectOrderViewModel.directOrderID,
      });

      // close the card
      props.onActionComplete();

      // notify that decline is in progress
      enqueueSnackbar(
        `Order #${props.marketDirectOrderViewModel.number} is being declined`,
        { variant: "info" },
      );
    } catch (e) {
      console.error(
        `error declining direct order ${props.marketDirectOrderViewModel.directOrderID}`,
        e,
      );
      const err = errorContextErrorTranslator.translateError(e);
      enqueueSnackbar(
        `Error Declining Order #${props.marketDirectOrderViewModel.number}: ${err.message}`,
        { variant: "warning" },
      );
      setDeclineInProgress(false);
      return;
    }

    try {
      // register callback to fire once the order has reached declined
      const deregister = await registerNotificationCallback(
        new GroupNotificationChannel({
          groupID: props.marketDirectOrderViewModel.counterpartyGroupID,
          name: MarketDirectOrderViewNotificationChannelName,
          private: true,
        }),
        [MarketDirectOrderViewModelChangedNotificationTypeName],
        (n: Notification) => {
          if (
            n instanceof MarketDirectOrderViewModelChangedNotification &&
            n.model.directOrderID ===
              props.marketDirectOrderViewModel.directOrderID
          ) {
            // notify based on state
            switch (n.model.state) {
              case DirectOrderState.Declining:
              case DirectOrderState.ClearanceFailed:
              case DirectOrderState.Failing:
                // Do nothing during transient states
                // Return so that deregister is not called.
                return;

              case DirectOrderState.Declined:
                enqueueSnackbar(`Order #${n.model.number} declined`, {
                  variant: "success",
                });
                break;

              case DirectOrderState.Failed:
                enqueueSnackbar(`Order #${n.model.number} has failed`, {
                  variant: "error",
                });
                break;

              case DirectOrderState.UnderInvestigation:
                enqueueSnackbar(
                  `Something has gone wrong with Order #${n.model.number} - it's status is being investigated`,
                  { variant: "warning" },
                );
                break;
            }
            deregister();
          }
        },
      );
    } catch (e) {
      console.error("error registering for order notifications", e);
      enqueueSnackbar(
        "Warning! Unable to Register for Order Notifications - Please Refresh to Monitor.",
        { variant: "warning" },
      );
    }
  };

  const [acceptInProgress, setAcceptInProgress] = useState(false);
  const handleAccept = async () => {
    setAcceptInProgress(true);
    try {
      // accept direct order
      await DirectOrderStateController.AcceptDirectOrderID(
        investorAcceptDirectOrderRequest,
      );

      // close the card
      props.onActionComplete();

      // notify that accept is in progress
      enqueueSnackbar(
        `Order #${props.marketDirectOrderViewModel.number} is being accepted`,
        { variant: "info" },
      );
    } catch (e) {
      console.error(
        `error accepting direct order ${props.marketDirectOrderViewModel.directOrderID}`,
        e,
      );
      const err = errorContextErrorTranslator.translateError(e);
      enqueueSnackbar(
        `Error Accepting Order #${props.marketDirectOrderViewModel.number}: ${err.message}`,
        { variant: "warning" },
      );
      setAcceptInProgress(false);
      return;
    }

    try {
      // register callback to fire once the order has reached settled
      const deregister = await registerNotificationCallback(
        new GroupNotificationChannel({
          groupID: props.marketDirectOrderViewModel.counterpartyGroupID,
          name: MarketDirectOrderViewNotificationChannelName,
          private: true,
        }),
        [MarketDirectOrderViewModelChangedNotificationTypeName],
        (n: Notification) => {
          if (
            n instanceof MarketDirectOrderViewModelChangedNotification &&
            n.model.directOrderID ===
              props.marketDirectOrderViewModel.directOrderID
          ) {
            // notify based on state
            switch (n.model.state) {
              case DirectOrderState.Accepted:
              case DirectOrderState.ClearanceFailed:
              case DirectOrderState.Failing:
                // Do nothing during transient states
                // Return so that deregister is not called.
                return;

              case DirectOrderState.Settled:
                enqueueSnackbar(`Order #${n.model.number} settled`, {
                  variant: "success",
                });
                break;

              case DirectOrderState.Failed:
                enqueueSnackbar(`Order #${n.model.number} has failed`, {
                  variant: "error",
                });
                break;

              case DirectOrderState.UnderInvestigation:
                enqueueSnackbar(
                  `Something has gone wrong with Order #${n.model.number} - it's status is being investigated`,
                  { variant: "warning" },
                );
                break;
            }
            deregister();
          }
        },
      );
    } catch (e) {
      console.error("error registering for order notifications", e);
      enqueueSnackbar(
        "Warning! Unable to Register for Order Notifications - Please Refresh to Monitor.",
        { variant: "warning" },
      );
    }
  };

  const actionAPIInProgress = declineInProgress || acceptInProgress;

  return (
    <StyledCard
      sx={{
        backgroundColor: theme.palette.custom.midnight,
        [theme.breakpoints.down("sm")]: {
          borderRadius: 0,
          height: "100%",
        },
      }}
    >
      <Header {...props} showLoading={actionAPIInProgress} />
      <Box
        className={cx(
          classes.sectionWithPaperBackground,
          classes.sectionWithPaddingLR,
          classes.sectionWithRows2Gap,
        )}
      >
        <OrderDirectionAndState
          viewingAsInitiatingParty={false}
          marketDirectOrderViewModel={props.marketDirectOrderViewModel}
        />
        <AvatarAndDirection
          name={props.marketDirectOrderViewModel.counterpartyClientName}
          pictureDLURL={
            props.marketDirectOrderViewModel.counterpartyProfilePictureDLURL
          }
          receives={false} // Investor pays...
        />
        <Box className={classes.sectionWithRows1Gap}>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: theme.spacing(1),
            }}
          >
            <Box
              sx={{
                display: "grid",
                gridTemplateColumns: "repeat(2, 1fr)",
              }}
            >
              <Typography
                color="textSecondary"
                variant="caption"
                children={isBuy ? "Tokens" : "Amount"}
              />
            </Box>
            <Amount
              id="investorAwaitingConfirmationCard-counterpartyCard-pay-amount"
              reverse={isBuy}
              codeTypographyProps={{
                variant: "h6",
                className: classes.ledgerAmountCode,
              }}
              valueTypographyProps={{
                variant: "h6",
                className: classes.ledgerAmountValue,
              }}
              formatTextNumOpts={{
                noDecimalPlaces: isBuy ? 7 : 2,
                addDecimalPadding: !isBuy,
              }}
              amount={
                isBuy
                  ? // Buy:
                    // Show what investor will pay: tokens
                    props.marketDirectOrderViewModel.tokens
                  : // Sell:
                    // Show what investor will pay: amountIncl
                    props.marketDirectOrderViewModel.amountIncl.setValue(
                      props.marketDirectOrderViewModel.amountIncl.value
                        .plus(props.marketDirectOrderViewModel.feeAmount.value)
                        .plus(
                          props.marketDirectOrderViewModel.feeAmount.value.multipliedBy(
                            props.marketDirectOrderViewModel.vatRate,
                          ),
                        ),
                    )
              }
            />
          </Box>
          <Box className={classes.sectionWithRows1Gap}>
            <Box className={classes.sectionWithColumns2Gap}>
              <Typography
                className={classes.disabledText}
                variant="caption"
                children="Available:"
              />
              <Amount
                reverse={isBuy}
                codeTypographyProps={{
                  variant: "caption",
                  color: "textSecondary",
                }}
                valueTypographyProps={{
                  variant: "caption",
                  color: "textSecondary",
                }}
                formatTextNumOpts={{
                  noDecimalPlaces: isBuy ? 7 : 2,
                  addDecimalPadding: !isBuy,
                }}
                amount={
                  isBuy
                    ? LedgerAmount.fromFutureAmount(
                        props.assetIssuanceTokenBalance.getAmount(),
                      )
                    : LedgerAmount.fromFutureAmount(
                        props.assetValuationTokenBalance.getAmount(),
                      )
                }
              />
            </Box>
            {requestValidationResult.fieldValidations.tokens && (
              <Typography
                variant="body2"
                color="error"
                children={requestValidationResult.fieldValidations.tokens}
              />
            )}
            {!!requestValidationResult.fieldValidations.amountIncl &&
              !validationInProgress &&
              (requestValidationResult.fieldValidations.amountIncl ===
              dontHaveEnoughFundsHelperText ? (
                <Box className={classes.sectionWithColumns2Gap}>
                  <Typography
                    id="investorAwaitingConfirmationCard-counterpartyCard-fund-amountError"
                    variant="body2"
                    color="error"
                    children={
                      requestValidationResult.fieldValidations.amountIncl
                    }
                  />
                  <Typography
                    id="investorAwaitingConfirmationCard-counterpartyCard-fund-link"
                    className={classes.linkText}
                    variant="body2"
                    children="Fund"
                    onClick={() => setShowFundAccDialog(true)}
                  />
                </Box>
              ) : (
                <Typography
                  variant="body2"
                  color="error"
                  id="investorAwaitingConfirmationCard-counterpartyCard-amountError"
                  children={requestValidationResult.fieldValidations.amountIncl}
                />
              ))}
          </Box>
        </Box>
      </Box>
      <Box
        sx={{
          padding: theme.spacing(3, 4),
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
          flexGrow: 1,
        }}
      >
        <Box
          sx={{
            "& > *": {
              marginBottom: theme.spacing(1),
            },
          }}
        >
          <AvatarAndDirection
            name={props.marketDirectOrderViewModel.counterpartyClientName}
            pictureDLURL={
              props.marketDirectOrderViewModel.counterpartyProfilePictureDLURL
            }
            receives // Investor receives...
          />
          <Box className={classes.sectionWith2EqualColumns}>
            <Typography
              color="textSecondary"
              variant="caption"
              children={isBuy ? "Amount" : "Tokens"}
            />
            <Typography
              color="textSecondary"
              variant="caption"
              children="Price per Token"
            />
          </Box>
          <Box className={classes.sectionWith2EqualColumns}>
            <Amount
              id="investorAwaitingConfirmationCard-counterpartyCard-receive-amount"
              reverse={!isBuy}
              codeTypographyProps={{
                variant: "h6",
                className: classes.ledgerAmountCode,
              }}
              valueTypographyProps={{
                variant: "h6",
                className: classes.ledgerAmountValue,
              }}
              formatTextNumOpts={{
                noDecimalPlaces: isBuy ? 2 : 7,
                addDecimalPadding: isBuy,
              }}
              amount={
                isBuy
                  ? // Buy:
                    // Show what investor will receive: amountIncl
                    props.marketDirectOrderViewModel.amountIncl.setValue(
                      props.marketDirectOrderViewModel.amountIncl.value
                        .minus(props.marketDirectOrderViewModel.feeAmount.value)
                        .minus(
                          props.marketDirectOrderViewModel.feeAmount.value.multipliedBy(
                            props.marketDirectOrderViewModel.vatRate,
                          ),
                        ),
                    )
                  : // Sell:
                    // Show what investor will receive: tokens
                    props.marketDirectOrderViewModel.tokens
              }
            />
            <Amount
              id="investorAwaitingConfirmationCard-counterpartyCard-price-amount"
              codeTypographyProps={{
                variant: "h6",
                className: classes.ledgerAmountCode,
              }}
              valueTypographyProps={{
                variant: "h6",
                className: classes.ledgerAmountValue,
              }}
              formatTextNumOpts={{
                noDecimalPlaces: 2,
                addDecimalPadding: true,
              }}
              amount={props.marketDirectOrderViewModel.price}
            />
          </Box>
        </Box>
        {/* Decline and Accept buttons */}
        {ordersViewConfiguration.Accept && ordersViewConfiguration.Decline && (
          <Box
            sx={{
              mt: 3,
              position: "sticky",
              bottom: 0,
              display: "flex",
              [theme.breakpoints.down("sm")]: {
                width: "100%",
                flexDirection: "column",
              },
              [theme.breakpoints.up("lg")]: {
                display: "grid",
                gridTemplateColumns: "repeat(2, auto)",
                gridGap: theme.spacing(2),
              },
            }}
          >
            <Tooltip
              placement="top"
              title={(() => {
                switch (true) {
                  case !props.userIsSignatoryOnTradingAcc:
                    return "You are not a Signatory on the Trading Account";
                }
                return "";
              })()}
            >
              <span>
                <Button
                  id="investorAwaitingConfirmationCard-counterpartyCard-decline-button"
                  fullWidth={isDesktop || isMobile}
                  sx={{
                    boxShadow: 3,
                    marginRight: !(isDesktop || isMobile)
                      ? theme.spacing(2)
                      : 0,
                    [theme.breakpoints.down("sm")]: {
                      height: "36px",
                    },
                  }}
                  variant="contained"
                  color="secondary"
                  disabled={
                    actionAPIInProgress ||
                    !props.userIsSignatoryOnTradingAcc ||
                    validationInProgress
                  }
                  children="decline"
                  onClick={() =>
                    setWarningDialogOptions({
                      title: "Warning",
                      messageParagraphs: [
                        "Are you sure you want to DECLINE this order?",
                        "You will not be able to undo this action.",
                      ],
                      yesMethod: () => {
                        setWarningDialogOptions(null);
                        handleDecline().finally();
                      },
                      noMethod: () => setWarningDialogOptions(null),
                    })
                  }
                />
              </span>
            </Tooltip>
            {isMobile && <Box sx={(theme) => ({ height: theme.spacing(2) })} />}
            <Tooltip
              placement="top"
              title={(() => {
                switch (true) {
                  case !props.userIsSignatoryOnTradingAcc:
                    return "You are not a Signatory on the Trading Account";
                }
                return "";
              })()}
            >
              <span>
                <Button
                  id="investorAwaitingConfirmationCard-counterpartyCard-accept-button"
                  fullWidth={isDesktop || isMobile}
                  sx={{
                    boxShadow: 3,
                    [theme.breakpoints.down("sm")]: {
                      height: "36px",
                    },
                  }}
                  variant="contained"
                  color="primary"
                  disabled={
                    actionAPIInProgress ||
                    !props.userIsSignatoryOnTradingAcc ||
                    !requestValidationResult.valid ||
                    validationInProgress
                  }
                  children="accept"
                  onClick={handleAccept}
                />
              </span>
            </Tooltip>
          </Box>
        )}
      </Box>

      {showFundAccDialog && (
        <FundAccountDialog
          accountID={props.tradingAccViewModel.getId()}
          open
          onClose={() => setShowFundAccDialog(false)}
        />
      )}

      <WarningDialog
        showDialog={!!warningDialogOptions}
        onCloseClick={() => setWarningDialogOptions(null)}
        onYesClick={
          warningDialogOptions ? warningDialogOptions.yesMethod : () => null
        }
        onNoClick={
          warningDialogOptions ? warningDialogOptions.noMethod : () => null
        }
        title={warningDialogOptions?.title}
        messageParagraphs={
          warningDialogOptions ? warningDialogOptions.messageParagraphs : [""]
        }
        disableControls={actionAPIInProgress}
        showProgressIndicator={acceptInProgress}
      />
    </StyledCard>
  );
}

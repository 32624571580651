// source: financial/rateSourceReader_meshproto.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global =
    (typeof globalThis !== 'undefined' && globalThis) ||
    (typeof window !== 'undefined' && window) ||
    (typeof global !== 'undefined' && global) ||
    (typeof self !== 'undefined' && self) ||
    (function () { return this; }).call(null) ||
    Function('return this')();

var security_context_pb = require('../security/context_pb.js');
goog.object.extend(proto, security_context_pb);
var search_criterion_pb = require('../search/criterion_pb.js');
goog.object.extend(proto, search_criterion_pb);
var search_query_pb = require('../search/query_pb.js');
goog.object.extend(proto, search_query_pb);
var search_sorting_pb = require('../search/sorting_pb.js');
goog.object.extend(proto, search_sorting_pb);
var financial_rateSource_pb = require('../financial/rateSource_pb.js');
goog.object.extend(proto, financial_rateSource_pb);
var i8n_timezone_pb = require('../i8n/timezone_pb.js');
goog.object.extend(proto, i8n_timezone_pb);
goog.exportSymbol('proto.financial.ReadAllRateSourceRequest', null, global);
goog.exportSymbol('proto.financial.ReadAllRateSourceResponse', null, global);
goog.exportSymbol('proto.financial.ReadManyRateSourceRequest', null, global);
goog.exportSymbol('proto.financial.ReadManyRateSourceResponse', null, global);
goog.exportSymbol('proto.financial.ReadOneRateSourceRequest', null, global);
goog.exportSymbol('proto.financial.ReadOneRateSourceResponse', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.financial.ReadOneRateSourceRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.financial.ReadOneRateSourceRequest.repeatedFields_, null);
};
goog.inherits(proto.financial.ReadOneRateSourceRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.financial.ReadOneRateSourceRequest.displayName = 'proto.financial.ReadOneRateSourceRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.financial.ReadOneRateSourceResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.financial.ReadOneRateSourceResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.financial.ReadOneRateSourceResponse.displayName = 'proto.financial.ReadOneRateSourceResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.financial.ReadManyRateSourceRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.financial.ReadManyRateSourceRequest.repeatedFields_, null);
};
goog.inherits(proto.financial.ReadManyRateSourceRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.financial.ReadManyRateSourceRequest.displayName = 'proto.financial.ReadManyRateSourceRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.financial.ReadManyRateSourceResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.financial.ReadManyRateSourceResponse.repeatedFields_, null);
};
goog.inherits(proto.financial.ReadManyRateSourceResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.financial.ReadManyRateSourceResponse.displayName = 'proto.financial.ReadManyRateSourceResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.financial.ReadAllRateSourceRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.financial.ReadAllRateSourceRequest.repeatedFields_, null);
};
goog.inherits(proto.financial.ReadAllRateSourceRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.financial.ReadAllRateSourceRequest.displayName = 'proto.financial.ReadAllRateSourceRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.financial.ReadAllRateSourceResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.financial.ReadAllRateSourceResponse.repeatedFields_, null);
};
goog.inherits(proto.financial.ReadAllRateSourceResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.financial.ReadAllRateSourceResponse.displayName = 'proto.financial.ReadAllRateSourceResponse';
}

/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.financial.ReadOneRateSourceRequest.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.financial.ReadOneRateSourceRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.financial.ReadOneRateSourceRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.financial.ReadOneRateSourceRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.financial.ReadOneRateSourceRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
context: (f = msg.getContext()) && security_context_pb.ContextFuture.toObject(includeInstance, f),
criteriaList: jspb.Message.toObjectList(msg.getCriteriaList(),
    search_criterion_pb.Criterion.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.financial.ReadOneRateSourceRequest}
 */
proto.financial.ReadOneRateSourceRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.financial.ReadOneRateSourceRequest;
  return proto.financial.ReadOneRateSourceRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.financial.ReadOneRateSourceRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.financial.ReadOneRateSourceRequest}
 */
proto.financial.ReadOneRateSourceRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new security_context_pb.ContextFuture;
      reader.readMessage(value,security_context_pb.ContextFuture.deserializeBinaryFromReader);
      msg.setContext(value);
      break;
    case 2:
      var value = new search_criterion_pb.Criterion;
      reader.readMessage(value,search_criterion_pb.Criterion.deserializeBinaryFromReader);
      msg.addCriteria(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.financial.ReadOneRateSourceRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.financial.ReadOneRateSourceRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.financial.ReadOneRateSourceRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.financial.ReadOneRateSourceRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getContext();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      security_context_pb.ContextFuture.serializeBinaryToWriter
    );
  }
  f = message.getCriteriaList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      search_criterion_pb.Criterion.serializeBinaryToWriter
    );
  }
};


/**
 * optional security.ContextFuture context = 1;
 * @return {?proto.security.ContextFuture}
 */
proto.financial.ReadOneRateSourceRequest.prototype.getContext = function() {
  return /** @type{?proto.security.ContextFuture} */ (
    jspb.Message.getWrapperField(this, security_context_pb.ContextFuture, 1));
};


/**
 * @param {?proto.security.ContextFuture|undefined} value
 * @return {!proto.financial.ReadOneRateSourceRequest} returns this
*/
proto.financial.ReadOneRateSourceRequest.prototype.setContext = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.financial.ReadOneRateSourceRequest} returns this
 */
proto.financial.ReadOneRateSourceRequest.prototype.clearContext = function() {
  return this.setContext(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.financial.ReadOneRateSourceRequest.prototype.hasContext = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * repeated search.Criterion criteria = 2;
 * @return {!Array<!proto.search.Criterion>}
 */
proto.financial.ReadOneRateSourceRequest.prototype.getCriteriaList = function() {
  return /** @type{!Array<!proto.search.Criterion>} */ (
    jspb.Message.getRepeatedWrapperField(this, search_criterion_pb.Criterion, 2));
};


/**
 * @param {!Array<!proto.search.Criterion>} value
 * @return {!proto.financial.ReadOneRateSourceRequest} returns this
*/
proto.financial.ReadOneRateSourceRequest.prototype.setCriteriaList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.search.Criterion=} opt_value
 * @param {number=} opt_index
 * @return {!proto.search.Criterion}
 */
proto.financial.ReadOneRateSourceRequest.prototype.addCriteria = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.search.Criterion, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.financial.ReadOneRateSourceRequest} returns this
 */
proto.financial.ReadOneRateSourceRequest.prototype.clearCriteriaList = function() {
  return this.setCriteriaList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.financial.ReadOneRateSourceResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.financial.ReadOneRateSourceResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.financial.ReadOneRateSourceResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.financial.ReadOneRateSourceResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
ratesource: (f = msg.getRatesource()) && financial_rateSource_pb.RateSource.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.financial.ReadOneRateSourceResponse}
 */
proto.financial.ReadOneRateSourceResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.financial.ReadOneRateSourceResponse;
  return proto.financial.ReadOneRateSourceResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.financial.ReadOneRateSourceResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.financial.ReadOneRateSourceResponse}
 */
proto.financial.ReadOneRateSourceResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new financial_rateSource_pb.RateSource;
      reader.readMessage(value,financial_rateSource_pb.RateSource.deserializeBinaryFromReader);
      msg.setRatesource(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.financial.ReadOneRateSourceResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.financial.ReadOneRateSourceResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.financial.ReadOneRateSourceResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.financial.ReadOneRateSourceResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getRatesource();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      financial_rateSource_pb.RateSource.serializeBinaryToWriter
    );
  }
};


/**
 * optional RateSource rateSource = 1;
 * @return {?proto.financial.RateSource}
 */
proto.financial.ReadOneRateSourceResponse.prototype.getRatesource = function() {
  return /** @type{?proto.financial.RateSource} */ (
    jspb.Message.getWrapperField(this, financial_rateSource_pb.RateSource, 1));
};


/**
 * @param {?proto.financial.RateSource|undefined} value
 * @return {!proto.financial.ReadOneRateSourceResponse} returns this
*/
proto.financial.ReadOneRateSourceResponse.prototype.setRatesource = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.financial.ReadOneRateSourceResponse} returns this
 */
proto.financial.ReadOneRateSourceResponse.prototype.clearRatesource = function() {
  return this.setRatesource(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.financial.ReadOneRateSourceResponse.prototype.hasRatesource = function() {
  return jspb.Message.getField(this, 1) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.financial.ReadManyRateSourceRequest.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.financial.ReadManyRateSourceRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.financial.ReadManyRateSourceRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.financial.ReadManyRateSourceRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.financial.ReadManyRateSourceRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
context: (f = msg.getContext()) && security_context_pb.ContextFuture.toObject(includeInstance, f),
criteriaList: jspb.Message.toObjectList(msg.getCriteriaList(),
    search_criterion_pb.Criterion.toObject, includeInstance),
query: (f = msg.getQuery()) && search_query_pb.Query.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.financial.ReadManyRateSourceRequest}
 */
proto.financial.ReadManyRateSourceRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.financial.ReadManyRateSourceRequest;
  return proto.financial.ReadManyRateSourceRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.financial.ReadManyRateSourceRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.financial.ReadManyRateSourceRequest}
 */
proto.financial.ReadManyRateSourceRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new security_context_pb.ContextFuture;
      reader.readMessage(value,security_context_pb.ContextFuture.deserializeBinaryFromReader);
      msg.setContext(value);
      break;
    case 2:
      var value = new search_criterion_pb.Criterion;
      reader.readMessage(value,search_criterion_pb.Criterion.deserializeBinaryFromReader);
      msg.addCriteria(value);
      break;
    case 3:
      var value = new search_query_pb.Query;
      reader.readMessage(value,search_query_pb.Query.deserializeBinaryFromReader);
      msg.setQuery(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.financial.ReadManyRateSourceRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.financial.ReadManyRateSourceRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.financial.ReadManyRateSourceRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.financial.ReadManyRateSourceRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getContext();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      security_context_pb.ContextFuture.serializeBinaryToWriter
    );
  }
  f = message.getCriteriaList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      search_criterion_pb.Criterion.serializeBinaryToWriter
    );
  }
  f = message.getQuery();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      search_query_pb.Query.serializeBinaryToWriter
    );
  }
};


/**
 * optional security.ContextFuture context = 1;
 * @return {?proto.security.ContextFuture}
 */
proto.financial.ReadManyRateSourceRequest.prototype.getContext = function() {
  return /** @type{?proto.security.ContextFuture} */ (
    jspb.Message.getWrapperField(this, security_context_pb.ContextFuture, 1));
};


/**
 * @param {?proto.security.ContextFuture|undefined} value
 * @return {!proto.financial.ReadManyRateSourceRequest} returns this
*/
proto.financial.ReadManyRateSourceRequest.prototype.setContext = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.financial.ReadManyRateSourceRequest} returns this
 */
proto.financial.ReadManyRateSourceRequest.prototype.clearContext = function() {
  return this.setContext(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.financial.ReadManyRateSourceRequest.prototype.hasContext = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * repeated search.Criterion criteria = 2;
 * @return {!Array<!proto.search.Criterion>}
 */
proto.financial.ReadManyRateSourceRequest.prototype.getCriteriaList = function() {
  return /** @type{!Array<!proto.search.Criterion>} */ (
    jspb.Message.getRepeatedWrapperField(this, search_criterion_pb.Criterion, 2));
};


/**
 * @param {!Array<!proto.search.Criterion>} value
 * @return {!proto.financial.ReadManyRateSourceRequest} returns this
*/
proto.financial.ReadManyRateSourceRequest.prototype.setCriteriaList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.search.Criterion=} opt_value
 * @param {number=} opt_index
 * @return {!proto.search.Criterion}
 */
proto.financial.ReadManyRateSourceRequest.prototype.addCriteria = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.search.Criterion, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.financial.ReadManyRateSourceRequest} returns this
 */
proto.financial.ReadManyRateSourceRequest.prototype.clearCriteriaList = function() {
  return this.setCriteriaList([]);
};


/**
 * optional search.Query query = 3;
 * @return {?proto.search.Query}
 */
proto.financial.ReadManyRateSourceRequest.prototype.getQuery = function() {
  return /** @type{?proto.search.Query} */ (
    jspb.Message.getWrapperField(this, search_query_pb.Query, 3));
};


/**
 * @param {?proto.search.Query|undefined} value
 * @return {!proto.financial.ReadManyRateSourceRequest} returns this
*/
proto.financial.ReadManyRateSourceRequest.prototype.setQuery = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.financial.ReadManyRateSourceRequest} returns this
 */
proto.financial.ReadManyRateSourceRequest.prototype.clearQuery = function() {
  return this.setQuery(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.financial.ReadManyRateSourceRequest.prototype.hasQuery = function() {
  return jspb.Message.getField(this, 3) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.financial.ReadManyRateSourceResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.financial.ReadManyRateSourceResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.financial.ReadManyRateSourceResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.financial.ReadManyRateSourceResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.financial.ReadManyRateSourceResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
recordsList: jspb.Message.toObjectList(msg.getRecordsList(),
    financial_rateSource_pb.RateSource.toObject, includeInstance),
total: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.financial.ReadManyRateSourceResponse}
 */
proto.financial.ReadManyRateSourceResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.financial.ReadManyRateSourceResponse;
  return proto.financial.ReadManyRateSourceResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.financial.ReadManyRateSourceResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.financial.ReadManyRateSourceResponse}
 */
proto.financial.ReadManyRateSourceResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new financial_rateSource_pb.RateSource;
      reader.readMessage(value,financial_rateSource_pb.RateSource.deserializeBinaryFromReader);
      msg.addRecords(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setTotal(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.financial.ReadManyRateSourceResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.financial.ReadManyRateSourceResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.financial.ReadManyRateSourceResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.financial.ReadManyRateSourceResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getRecordsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      financial_rateSource_pb.RateSource.serializeBinaryToWriter
    );
  }
  f = message.getTotal();
  if (f !== 0) {
    writer.writeInt64(
      2,
      f
    );
  }
};


/**
 * repeated RateSource records = 1;
 * @return {!Array<!proto.financial.RateSource>}
 */
proto.financial.ReadManyRateSourceResponse.prototype.getRecordsList = function() {
  return /** @type{!Array<!proto.financial.RateSource>} */ (
    jspb.Message.getRepeatedWrapperField(this, financial_rateSource_pb.RateSource, 1));
};


/**
 * @param {!Array<!proto.financial.RateSource>} value
 * @return {!proto.financial.ReadManyRateSourceResponse} returns this
*/
proto.financial.ReadManyRateSourceResponse.prototype.setRecordsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.financial.RateSource=} opt_value
 * @param {number=} opt_index
 * @return {!proto.financial.RateSource}
 */
proto.financial.ReadManyRateSourceResponse.prototype.addRecords = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.financial.RateSource, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.financial.ReadManyRateSourceResponse} returns this
 */
proto.financial.ReadManyRateSourceResponse.prototype.clearRecordsList = function() {
  return this.setRecordsList([]);
};


/**
 * optional int64 total = 2;
 * @return {number}
 */
proto.financial.ReadManyRateSourceResponse.prototype.getTotal = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.financial.ReadManyRateSourceResponse} returns this
 */
proto.financial.ReadManyRateSourceResponse.prototype.setTotal = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.financial.ReadAllRateSourceRequest.repeatedFields_ = [2,3];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.financial.ReadAllRateSourceRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.financial.ReadAllRateSourceRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.financial.ReadAllRateSourceRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.financial.ReadAllRateSourceRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
context: (f = msg.getContext()) && security_context_pb.ContextFuture.toObject(includeInstance, f),
criteriaList: jspb.Message.toObjectList(msg.getCriteriaList(),
    search_criterion_pb.Criterion.toObject, includeInstance),
sortingList: jspb.Message.toObjectList(msg.getSortingList(),
    search_sorting_pb.Sorting.toObject, includeInstance),
batchsize: jspb.Message.getFieldWithDefault(msg, 4, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.financial.ReadAllRateSourceRequest}
 */
proto.financial.ReadAllRateSourceRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.financial.ReadAllRateSourceRequest;
  return proto.financial.ReadAllRateSourceRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.financial.ReadAllRateSourceRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.financial.ReadAllRateSourceRequest}
 */
proto.financial.ReadAllRateSourceRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new security_context_pb.ContextFuture;
      reader.readMessage(value,security_context_pb.ContextFuture.deserializeBinaryFromReader);
      msg.setContext(value);
      break;
    case 2:
      var value = new search_criterion_pb.Criterion;
      reader.readMessage(value,search_criterion_pb.Criterion.deserializeBinaryFromReader);
      msg.addCriteria(value);
      break;
    case 3:
      var value = new search_sorting_pb.Sorting;
      reader.readMessage(value,search_sorting_pb.Sorting.deserializeBinaryFromReader);
      msg.addSorting(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setBatchsize(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.financial.ReadAllRateSourceRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.financial.ReadAllRateSourceRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.financial.ReadAllRateSourceRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.financial.ReadAllRateSourceRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getContext();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      security_context_pb.ContextFuture.serializeBinaryToWriter
    );
  }
  f = message.getCriteriaList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      search_criterion_pb.Criterion.serializeBinaryToWriter
    );
  }
  f = message.getSortingList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      3,
      f,
      search_sorting_pb.Sorting.serializeBinaryToWriter
    );
  }
  f = message.getBatchsize();
  if (f !== 0) {
    writer.writeUint64(
      4,
      f
    );
  }
};


/**
 * optional security.ContextFuture context = 1;
 * @return {?proto.security.ContextFuture}
 */
proto.financial.ReadAllRateSourceRequest.prototype.getContext = function() {
  return /** @type{?proto.security.ContextFuture} */ (
    jspb.Message.getWrapperField(this, security_context_pb.ContextFuture, 1));
};


/**
 * @param {?proto.security.ContextFuture|undefined} value
 * @return {!proto.financial.ReadAllRateSourceRequest} returns this
*/
proto.financial.ReadAllRateSourceRequest.prototype.setContext = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.financial.ReadAllRateSourceRequest} returns this
 */
proto.financial.ReadAllRateSourceRequest.prototype.clearContext = function() {
  return this.setContext(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.financial.ReadAllRateSourceRequest.prototype.hasContext = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * repeated search.Criterion criteria = 2;
 * @return {!Array<!proto.search.Criterion>}
 */
proto.financial.ReadAllRateSourceRequest.prototype.getCriteriaList = function() {
  return /** @type{!Array<!proto.search.Criterion>} */ (
    jspb.Message.getRepeatedWrapperField(this, search_criterion_pb.Criterion, 2));
};


/**
 * @param {!Array<!proto.search.Criterion>} value
 * @return {!proto.financial.ReadAllRateSourceRequest} returns this
*/
proto.financial.ReadAllRateSourceRequest.prototype.setCriteriaList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.search.Criterion=} opt_value
 * @param {number=} opt_index
 * @return {!proto.search.Criterion}
 */
proto.financial.ReadAllRateSourceRequest.prototype.addCriteria = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.search.Criterion, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.financial.ReadAllRateSourceRequest} returns this
 */
proto.financial.ReadAllRateSourceRequest.prototype.clearCriteriaList = function() {
  return this.setCriteriaList([]);
};


/**
 * repeated search.Sorting sorting = 3;
 * @return {!Array<!proto.search.Sorting>}
 */
proto.financial.ReadAllRateSourceRequest.prototype.getSortingList = function() {
  return /** @type{!Array<!proto.search.Sorting>} */ (
    jspb.Message.getRepeatedWrapperField(this, search_sorting_pb.Sorting, 3));
};


/**
 * @param {!Array<!proto.search.Sorting>} value
 * @return {!proto.financial.ReadAllRateSourceRequest} returns this
*/
proto.financial.ReadAllRateSourceRequest.prototype.setSortingList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 3, value);
};


/**
 * @param {!proto.search.Sorting=} opt_value
 * @param {number=} opt_index
 * @return {!proto.search.Sorting}
 */
proto.financial.ReadAllRateSourceRequest.prototype.addSorting = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 3, opt_value, proto.search.Sorting, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.financial.ReadAllRateSourceRequest} returns this
 */
proto.financial.ReadAllRateSourceRequest.prototype.clearSortingList = function() {
  return this.setSortingList([]);
};


/**
 * optional uint64 batchSize = 4;
 * @return {number}
 */
proto.financial.ReadAllRateSourceRequest.prototype.getBatchsize = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.financial.ReadAllRateSourceRequest} returns this
 */
proto.financial.ReadAllRateSourceRequest.prototype.setBatchsize = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.financial.ReadAllRateSourceResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.financial.ReadAllRateSourceResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.financial.ReadAllRateSourceResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.financial.ReadAllRateSourceResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.financial.ReadAllRateSourceResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
recordsList: jspb.Message.toObjectList(msg.getRecordsList(),
    financial_rateSource_pb.RateSource.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.financial.ReadAllRateSourceResponse}
 */
proto.financial.ReadAllRateSourceResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.financial.ReadAllRateSourceResponse;
  return proto.financial.ReadAllRateSourceResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.financial.ReadAllRateSourceResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.financial.ReadAllRateSourceResponse}
 */
proto.financial.ReadAllRateSourceResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new financial_rateSource_pb.RateSource;
      reader.readMessage(value,financial_rateSource_pb.RateSource.deserializeBinaryFromReader);
      msg.addRecords(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.financial.ReadAllRateSourceResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.financial.ReadAllRateSourceResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.financial.ReadAllRateSourceResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.financial.ReadAllRateSourceResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getRecordsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      financial_rateSource_pb.RateSource.serializeBinaryToWriter
    );
  }
};


/**
 * repeated RateSource records = 1;
 * @return {!Array<!proto.financial.RateSource>}
 */
proto.financial.ReadAllRateSourceResponse.prototype.getRecordsList = function() {
  return /** @type{!Array<!proto.financial.RateSource>} */ (
    jspb.Message.getRepeatedWrapperField(this, financial_rateSource_pb.RateSource, 1));
};


/**
 * @param {!Array<!proto.financial.RateSource>} value
 * @return {!proto.financial.ReadAllRateSourceResponse} returns this
*/
proto.financial.ReadAllRateSourceResponse.prototype.setRecordsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.financial.RateSource=} opt_value
 * @param {number=} opt_index
 * @return {!proto.financial.RateSource}
 */
proto.financial.ReadAllRateSourceResponse.prototype.addRecords = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.financial.RateSource, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.financial.ReadAllRateSourceResponse} returns this
 */
proto.financial.ReadAllRateSourceResponse.prototype.clearRecordsList = function() {
  return this.setRecordsList([]);
};


goog.object.extend(exports, proto.financial);

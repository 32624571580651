/**
 * @fileoverview gRPC-Web generated client stub for marketing
 * @enhanceable
 * @public
 */

// Code generated by protoc-gen-grpc-web. DO NOT EDIT.
// versions:
// 	protoc-gen-grpc-web v1.5.0
// 	protoc              v5.28.0
// source: marketing/instrumentMarketingContentReaderUNSCOPED_meshproto.proto


/* eslint-disable */
// @ts-nocheck



const grpc = {};
grpc.web = require('grpc-web');


var security_context_pb = require('../security/context_pb.js')

var search_criterion_pb = require('../search/criterion_pb.js')

var search_query_pb = require('../search/query_pb.js')

var search_sorting_pb = require('../search/sorting_pb.js')

var marketing_instrumentMarketingContent_pb = require('../marketing/instrumentMarketingContent_pb.js')

var marketing_socialMediaLinks_pb = require('../marketing/socialMediaLinks_pb.js')

var marketing_independentReview_pb = require('../marketing/independentReview_pb.js')

var marketing_mediaEntry_pb = require('../marketing/mediaEntry_pb.js')

var marketing_newsArticle_pb = require('../marketing/newsArticle_pb.js')

var marketing_instrumentMarketingContentReader_meshproto_pb = require('../marketing/instrumentMarketingContentReader_meshproto_pb.js')
const proto = {};
proto.marketing = require('./instrumentMarketingContentReaderUNSCOPED_meshproto_pb.js');

/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?grpc.web.ClientOptions} options
 * @constructor
 * @struct
 * @final
 */
proto.marketing.InstrumentMarketingContentReaderUNSCOPEDClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options.format = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname.replace(/\/+$/, '');

};


/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?grpc.web.ClientOptions} options
 * @constructor
 * @struct
 * @final
 */
proto.marketing.InstrumentMarketingContentReaderUNSCOPEDPromiseClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options.format = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname.replace(/\/+$/, '');

};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.marketing.ReadOneInstrumentMarketingContentRequest,
 *   !proto.marketing.ReadOneInstrumentMarketingContentResponse>}
 */
const methodDescriptor_InstrumentMarketingContentReaderUNSCOPED_ReadOneInstrumentMarketingContentUNSCOPED = new grpc.web.MethodDescriptor(
  '/marketing.InstrumentMarketingContentReaderUNSCOPED/ReadOneInstrumentMarketingContentUNSCOPED',
  grpc.web.MethodType.UNARY,
  marketing_instrumentMarketingContentReader_meshproto_pb.ReadOneInstrumentMarketingContentRequest,
  marketing_instrumentMarketingContentReader_meshproto_pb.ReadOneInstrumentMarketingContentResponse,
  /**
   * @param {!proto.marketing.ReadOneInstrumentMarketingContentRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  marketing_instrumentMarketingContentReader_meshproto_pb.ReadOneInstrumentMarketingContentResponse.deserializeBinary
);


/**
 * @param {!proto.marketing.ReadOneInstrumentMarketingContentRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.marketing.ReadOneInstrumentMarketingContentResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.marketing.ReadOneInstrumentMarketingContentResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.marketing.InstrumentMarketingContentReaderUNSCOPEDClient.prototype.readOneInstrumentMarketingContentUNSCOPED =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/marketing.InstrumentMarketingContentReaderUNSCOPED/ReadOneInstrumentMarketingContentUNSCOPED',
      request,
      metadata || {},
      methodDescriptor_InstrumentMarketingContentReaderUNSCOPED_ReadOneInstrumentMarketingContentUNSCOPED,
      callback);
};


/**
 * @param {!proto.marketing.ReadOneInstrumentMarketingContentRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.marketing.ReadOneInstrumentMarketingContentResponse>}
 *     Promise that resolves to the response
 */
proto.marketing.InstrumentMarketingContentReaderUNSCOPEDPromiseClient.prototype.readOneInstrumentMarketingContentUNSCOPED =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/marketing.InstrumentMarketingContentReaderUNSCOPED/ReadOneInstrumentMarketingContentUNSCOPED',
      request,
      metadata || {},
      methodDescriptor_InstrumentMarketingContentReaderUNSCOPED_ReadOneInstrumentMarketingContentUNSCOPED);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.marketing.ReadManyInstrumentMarketingContentRequest,
 *   !proto.marketing.ReadManyInstrumentMarketingContentResponse>}
 */
const methodDescriptor_InstrumentMarketingContentReaderUNSCOPED_ReadManyInstrumentMarketingContentUNSCOPED = new grpc.web.MethodDescriptor(
  '/marketing.InstrumentMarketingContentReaderUNSCOPED/ReadManyInstrumentMarketingContentUNSCOPED',
  grpc.web.MethodType.UNARY,
  marketing_instrumentMarketingContentReader_meshproto_pb.ReadManyInstrumentMarketingContentRequest,
  marketing_instrumentMarketingContentReader_meshproto_pb.ReadManyInstrumentMarketingContentResponse,
  /**
   * @param {!proto.marketing.ReadManyInstrumentMarketingContentRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  marketing_instrumentMarketingContentReader_meshproto_pb.ReadManyInstrumentMarketingContentResponse.deserializeBinary
);


/**
 * @param {!proto.marketing.ReadManyInstrumentMarketingContentRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.marketing.ReadManyInstrumentMarketingContentResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.marketing.ReadManyInstrumentMarketingContentResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.marketing.InstrumentMarketingContentReaderUNSCOPEDClient.prototype.readManyInstrumentMarketingContentUNSCOPED =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/marketing.InstrumentMarketingContentReaderUNSCOPED/ReadManyInstrumentMarketingContentUNSCOPED',
      request,
      metadata || {},
      methodDescriptor_InstrumentMarketingContentReaderUNSCOPED_ReadManyInstrumentMarketingContentUNSCOPED,
      callback);
};


/**
 * @param {!proto.marketing.ReadManyInstrumentMarketingContentRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.marketing.ReadManyInstrumentMarketingContentResponse>}
 *     Promise that resolves to the response
 */
proto.marketing.InstrumentMarketingContentReaderUNSCOPEDPromiseClient.prototype.readManyInstrumentMarketingContentUNSCOPED =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/marketing.InstrumentMarketingContentReaderUNSCOPED/ReadManyInstrumentMarketingContentUNSCOPED',
      request,
      metadata || {},
      methodDescriptor_InstrumentMarketingContentReaderUNSCOPED_ReadManyInstrumentMarketingContentUNSCOPED);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.marketing.ReadAllInstrumentMarketingContentRequest,
 *   !proto.marketing.ReadAllInstrumentMarketingContentResponse>}
 */
const methodDescriptor_InstrumentMarketingContentReaderUNSCOPED_ReadAllInstrumentMarketingContentUNSCOPED = new grpc.web.MethodDescriptor(
  '/marketing.InstrumentMarketingContentReaderUNSCOPED/ReadAllInstrumentMarketingContentUNSCOPED',
  grpc.web.MethodType.UNARY,
  marketing_instrumentMarketingContentReader_meshproto_pb.ReadAllInstrumentMarketingContentRequest,
  marketing_instrumentMarketingContentReader_meshproto_pb.ReadAllInstrumentMarketingContentResponse,
  /**
   * @param {!proto.marketing.ReadAllInstrumentMarketingContentRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  marketing_instrumentMarketingContentReader_meshproto_pb.ReadAllInstrumentMarketingContentResponse.deserializeBinary
);


/**
 * @param {!proto.marketing.ReadAllInstrumentMarketingContentRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.marketing.ReadAllInstrumentMarketingContentResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.marketing.ReadAllInstrumentMarketingContentResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.marketing.InstrumentMarketingContentReaderUNSCOPEDClient.prototype.readAllInstrumentMarketingContentUNSCOPED =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/marketing.InstrumentMarketingContentReaderUNSCOPED/ReadAllInstrumentMarketingContentUNSCOPED',
      request,
      metadata || {},
      methodDescriptor_InstrumentMarketingContentReaderUNSCOPED_ReadAllInstrumentMarketingContentUNSCOPED,
      callback);
};


/**
 * @param {!proto.marketing.ReadAllInstrumentMarketingContentRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.marketing.ReadAllInstrumentMarketingContentResponse>}
 *     Promise that resolves to the response
 */
proto.marketing.InstrumentMarketingContentReaderUNSCOPEDPromiseClient.prototype.readAllInstrumentMarketingContentUNSCOPED =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/marketing.InstrumentMarketingContentReaderUNSCOPED/ReadAllInstrumentMarketingContentUNSCOPED',
      request,
      metadata || {},
      methodDescriptor_InstrumentMarketingContentReaderUNSCOPED_ReadAllInstrumentMarketingContentUNSCOPED);
};


module.exports = proto.marketing;


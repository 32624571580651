import {
  TextExactCriterion,
  TextListCriterion,
  TextSubstringCriterion,
} from "james/search/criterion";
import { ListingState } from "james/market/Listing";
import { AssetType } from "james/views/marketListingView/Model";

export enum FilterTabOpt {
  All = "All",
  Funds = "Funds",
  Bonds = "Bonds",
  Crypto = "Crypto",
}

export interface SearchOpts {
  tab: FilterTabOpt;
  textSearch: string;
}

export const allFilterTapOptions: FilterTabOpt[] = [
  FilterTabOpt.All,
  FilterTabOpt.Funds,
  FilterTabOpt.Bonds,
  FilterTabOpt.Crypto,
];

export const filterCriteriaIdx = {
  [FilterTabOpt.All]: {
    listingState: TextExactCriterion(ListingState.Active),
  },
  [FilterTabOpt.Crypto]: {
    listingState: TextExactCriterion(ListingState.Active),
    assetType: TextListCriterion([
      AssetType.RightsToAFiatCurrency,
      AssetType.RightsToACryptoCurrency,
      AssetType.CryptoCurrency,
      AssetType.YieldBearingStablecoin,
    ]),
  },
  [FilterTabOpt.Funds]: {
    listingState: TextExactCriterion(ListingState.Active),
    assetType: TextListCriterion([
      AssetType.ETF,
      AssetType.RightsToAnETF,
      AssetType.ETN,
      AssetType.RightsToAnETN,
      AssetType.AMC,
      AssetType.RightsToAnAMC,
      AssetType.Commodity,
      AssetType.RightsToACommodity,
    ]),
  },
  [FilterTabOpt.Bonds]: {
    listingState: TextExactCriterion(ListingState.Active),
    assetType: TextListCriterion([AssetType.Bond, AssetType.RightsToABond]),
  },
};

export const getFilterCriteria = (options: SearchOpts) => {
  let filterCriteria = {};

  if (options.textSearch) {
    filterCriteria = {
      ...filterCriteria,
      $or: [
        { assetName: TextSubstringCriterion(options.textSearch) },
        { assetShortName: TextSubstringCriterion(options.textSearch) },
        { "token.code": TextSubstringCriterion(options.textSearch) },
        {
          assetOwnerClientShortName: TextSubstringCriterion(options.textSearch),
        },
        {
          issuerName: TextSubstringCriterion(options.textSearch),
        },
        {
          returnDescription: TextSubstringCriterion(options.textSearch),
        },
        { exchangeCode: TextSubstringCriterion(options.textSearch) },
        { instrumentRiskProfile: TextSubstringCriterion(options.textSearch) },
      ],
    };
  }
  return {
    ...filterCriteriaIdx[options.tab],
    ...filterCriteria,
  };
};

export const pageSize = 6;

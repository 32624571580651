import { Area, AreaChart, ResponsiveContainer, Tooltip } from "recharts";
import React, { useEffect, useState } from "react";
import {
  alpha,
  Backdrop,
  Box,
  Card,
  Skeleton,
  Typography,
  useTheme,
} from "@mui/material";
import { Amount, Token } from "james/ledger";
import { formatTextNum } from "utilities/number";
import dayjs from "dayjs";
import { v4 as uuidV4 } from "uuid";
import { BigNumber } from "bignumber.js";
import { StellarNetwork } from "james/stellar";

export interface ValuationChartProps {
  valuations?: { date: string; valuation: Amount }[];
  noInfoDisplay?: boolean; // This is super optional
  height?: number | string;
}

export const ValuationChart = (props: ValuationChartProps) => {
  const theme = useTheme();
  const [gradientID, setGradientID] = useState("");

  useEffect(() => {
    // generate random ID for the
    if (!gradientID) {
      setGradientID(uuidV4());
    }
  }, []);

  return (
    <Box
      sx={{
        width: "100%",
        height: props.height ? props.height : "50px",
        position: "relative",
      }}
    >
      {props.valuations ? (
        <React.Fragment>
          <Backdrop
            sx={{
              position: "absolute",
              borderRadius: "4px",
              zIndex: 1,
              backgroundColor: alpha(theme.palette.background.default, 0),
            }}
            open={!!props.noInfoDisplay}
          >
            No data to display.
          </Backdrop>
          <ResponsiveContainer
            width="100%"
            height={props.height ? props.height : 50}
          >
            <AreaChart
              data={
                props.noInfoDisplay
                  ? valuationsDummyData.map((val) => ({
                      date: val.date.toString(),
                      value: val.valuation.value.toNumber(),
                      tokenCode: val.valuation.token.code,
                    }))
                  : props.valuations.map((val) => ({
                      date: val.date.toString(),
                      value: val.valuation.value.toNumber(),
                      tokenCode: val.valuation.token.code,
                    }))
              }
              margin={{
                top: 8,
                right: 0,
                left: 0,
                bottom: 0,
              }}
            >
              <defs>
                <linearGradient id={gradientID} x1="0" y1="0" x2="0" y2="1">
                  <stop
                    offset="5%"
                    stopColor={
                      props.noInfoDisplay
                        ? theme.palette.text.disabled
                        : alpha(theme.palette.common.white, 0.5)
                    }
                    stopOpacity={0.5}
                  />
                  <stop
                    offset="95%"
                    stopColor={
                      props.noInfoDisplay
                        ? theme.palette.text.disabled
                        : alpha(theme.palette.common.white, 0.5)
                    }
                    stopOpacity={0}
                  />
                </linearGradient>
              </defs>
              <Area
                dataKey="value"
                activeDot={{ fill: "white", stroke: "white" }}
                stroke={
                  props.noInfoDisplay
                    ? theme.palette.text.disabled
                    : theme.palette.common.white
                }
                fill={`url(#${gradientID})`}
                fillOpacity={1}
              />
              <Tooltip content={<CustomTooltip />} />
            </AreaChart>
          </ResponsiveContainer>
        </React.Fragment>
      ) : (
        <Skeleton
          width={"100%"}
          sx={{
            transform: "none",
          }}
          height={"110%"}
        />
      )}
    </Box>
  );
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function CustomTooltip({ active, payload }: any) {
  if (active && payload && payload.length) {
    return (
      <Card
        sx={() => ({
          backgroundColor: "#121027",
          height: "19px",
          padding: "12px",
          display: "flex",
          alignItems: "center",
        })}
      >
        <Typography
          sx={{
            textTransform: "none",
          }}
          variant="overline"
          component="div"
        >
          {`${payload[0].payload.tokenCode} ${formatTextNum(
            payload[0].payload.value,
            {
              noDecimalPlaces: 2,
              addDecimalPadding: true,
            },
          )} `}
        </Typography>
        <Typography
          variant={"overline"}
          component="div"
          sx={(theme) => ({
            textTransform: "none",
            color: theme.palette.text.secondary,
          })}
        >
          &nbsp;{`| ${dayjs(payload[0].payload.date).format("DD-MM-YY")}`}
        </Typography>
      </Card>
    );
  }
  return <></>;
}

const valuationsDummyData = [
  {
    date: dayjs().add(1, "day").format(),
    valuation: new Amount({
      value: new BigNumber("15.30"),
      token: new Token({
        code: "mZAR",
        issuer: "issuer",
        network: StellarNetwork.TestSDFNetwork,
      }),
    }),
  },
  {
    date: dayjs().add(2, "days").format(),
    valuation: new Amount({
      value: new BigNumber("11.10"),
      token: new Token({
        code: "mZAR",
        issuer: "issuer",
        network: StellarNetwork.TestSDFNetwork,
      }),
    }),
  },
  {
    date: dayjs().add(3, "days").format(),
    valuation: new Amount({
      value: new BigNumber("1.30"),
      token: new Token({
        code: "mZAR",
        issuer: "issuer",
        network: StellarNetwork.TestSDFNetwork,
      }),
    }),
  },
  {
    date: dayjs().add(4, "days").format(),
    valuation: new Amount({
      value: new BigNumber("13.30"),
      token: new Token({
        code: "mZAR",
        issuer: "issuer",
        network: StellarNetwork.TestSDFNetwork,
      }),
    }),
  },
  {
    date: dayjs().add(5, "days").format(),
    valuation: new Amount({
      value: new BigNumber("50.30"),
      token: new Token({
        code: "mZAR",
        issuer: "issuer",
        network: StellarNetwork.TestSDFNetwork,
      }),
    }),
  },
  {
    date: dayjs().add(6, "days").format(),
    valuation: new Amount({
      value: new BigNumber("100.30"),
      token: new Token({
        code: "mZAR",
        issuer: "issuer",
        network: StellarNetwork.TestSDFNetwork,
      }),
    }),
  },
  {
    date: dayjs().add(7, "days").format(),
    valuation: new Amount({
      value: new BigNumber("79.30"),
      token: new Token({
        code: "mZAR",
        issuer: "issuer",
        network: StellarNetwork.TestSDFNetwork,
      }),
    }),
  },
  {
    date: dayjs().add(8, "days").format(),
    valuation: new Amount({
      value: new BigNumber("81.30"),
      token: new Token({
        code: "mZAR",
        issuer: "issuer",
        network: StellarNetwork.TestSDFNetwork,
      }),
    }),
  },
  {
    date: dayjs().add(9, "days").format(),
    valuation: new Amount({
      value: new BigNumber("35.30"),
      token: new Token({
        code: "mZAR",
        issuer: "issuer",
        network: StellarNetwork.TestSDFNetwork,
      }),
    }),
  },
  {
    date: dayjs().add(10, "days").format(),
    valuation: new Amount({
      value: new BigNumber("75.30"),
      token: new Token({
        code: "mZAR",
        issuer: "issuer",
        network: StellarNetwork.TestSDFNetwork,
      }),
    }),
  },
];

import { Model } from "@mesh/common-js/dist/views/stellarAccountView/model_pb";
import React, { useContext } from "react";
import { Skeleton } from "@mui/material";
import { AccountSignatoryCard } from "./components/AccountSignatoryCard";
import { AccountSignatoriesContext } from "../../AccountDetails";

interface AccountSignatoriesListProps {
  accountModel: Model;
}

export function AccountSignatoriesList(props: AccountSignatoriesListProps) {
  const {
    retrievalInProgress,
    accountSignatories,
    accountSignatoriesBeingRemoved,
  } = useContext(AccountSignatoriesContext);

  if (
    // if account signatory user retrieval in progress
    retrievalInProgress && // AND
    // there are no signatories currently being removed
    !Object.keys(accountSignatoriesBeingRemoved).length
  ) {
    // then show a skeleton
    return (
      <>
        <Skeleton width="100%" height={65} />
        <Skeleton width="100%" height={65} />
      </>
    );
  }
  // otherwise show the existing list
  return (
    <>
      {accountSignatories.map((gu, index) => (
        <React.Fragment key={index}>
          <AccountSignatoryCard
            accountID={props.accountModel.getId()}
            signatoryGroupUser={gu}
            renderCheckBox={false}
          />
        </React.Fragment>
      ))}
    </>
  );
}

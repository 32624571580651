import { Context } from "james/security";
import config from "react-global-configuration";
import { Asset } from "james/financial/Asset";
import { Fee, NewFee } from "james/remuneration/Fee";
import { jsonRPCRequest } from "utilities/network/jsonRPCRequest";
import { JSONRPCRequestOpts } from "utilities/network/jsonRPCRequest/jsonRPCRequest";

export interface GenerateAssetListingFeesRequest {
  context: Context;
  asset: Asset;
}

export interface GenerateAssetListingFeesResponse {
  fees: Fee[];
}

export const AssetListingFeeGenerator = {
  serviceProvider: "market-AssetListingFeeGenerator",
  async GenerateAssetListingFees(
    request: GenerateAssetListingFeesRequest,
    jsonRPCRequestOpts?: JSONRPCRequestOpts,
  ): Promise<GenerateAssetListingFeesResponse> {
    const response = await jsonRPCRequest(
      {
        url: config.get("meshAuthenticatedURL"),
        method: `${AssetListingFeeGenerator.serviceProvider}.GenerateAssetListingFees`,
        request,
      },
      jsonRPCRequestOpts,
    );

    return { fees: response.fees.map((f: Fee) => NewFee(f)) };
  },
};

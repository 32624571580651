import React, { useEffect, useState } from "react";
import {
  Model as StellarAccountViewModel,
  Balance,
} from "@mesh/common-js/dist/views/stellarAccountView/model_pb";
import { Card, useTheme } from "@mui/material";
import { Model as MarketDirectOrderViewModel } from "james/views/marketDirectOrderView";
import { useLedgerTokenViewContext } from "context/LedgerTokenView";
import { useIsMounted } from "hooks";
import {
  Listing,
  ListingRepository,
  MechanismType,
  QuoteParameter,
} from "james/market";
import { Model as LedgerTokenViewModel } from "james/views/ledgerTokenView";
import { IDIdentifier } from "james/search/identifier/ID";
import { DirectOrderState } from "james/market/DirectOrder";
import { Header } from "./Components/Header";
import { LoadingSplash } from "./Components/LoadingSplash";
import { IssuerAwaitingConfirmationCard } from "./Components/IssuerAwaitingConfirmationCard";
import { InvestorAwaitingConfirmationCard } from "./Components/InvestorAwaitingConfirmationCard";
import { DefaultCard } from "./Components/DefaultCard";
import { useAccountContext } from "context/Account/Account";
import { useSnackbar } from "notistack";
import { LedgerIDIdentifier } from "james/search/identifier";
import { useApplicationContext } from "context/Application/Application";
import { Client } from "james/client";
import { useErrorContext } from "context/Error";
import {
  MarketListingViewModel,
  Reader as MarketListingViewReader,
} from "james/views/marketListingView";
import { getTokenBalance } from "@mesh/common-js/dist/views/stellarAccountView";
import { LedgerAccountCategory } from "james/ledger";

export interface MarketDirectOrderViewCardProps {
  onActionComplete: () => void;
  clientIDPerspective: string;
  marketDirectOrderViewModel: MarketDirectOrderViewModel;
  close?: () => void;
}

export function MarketDirectOrderViewCard({
  close,
  ...props
}: MarketDirectOrderViewCardProps) {
  const theme = useTheme();
  const { authContext, myClient, myClientRetrievalErr } =
    useApplicationContext();
  const { getLedgerTokenViewModel } = useLedgerTokenViewContext();
  const { enqueueSnackbar } = useSnackbar();
  const isMounted = useIsMounted();
  const { stellarAccountContext } = useAccountContext();

  // determine perspective
  const viewingAsInitiatingParty =
    props.clientIDPerspective ===
    props.marketDirectOrderViewModel.initiatingPartyClientID;
  const viewingAsAssetIssuer =
    props.clientIDPerspective ===
    props.marketDirectOrderViewModel.assetIssuingClientID;

  // load data required for issuer/investor for either buy/sell
  const [listing, setListing] = useState<Listing | undefined>(undefined);
  const [marketListingViewModel, setMarketListingViewModel] = useState<
    MarketListingViewModel | undefined
  >(undefined);
  const [tradingAccViewModel, setTradingAccViewModel] = useState<
    StellarAccountViewModel | undefined
  >(undefined);
  const [fetchingRequiredData, setFetchingRequiredData] = useState(true);
  const [tradingAccountID, setTradingAccountID] = useState<string | undefined>(
    undefined,
  );
  const [signatoryOnTradingAcc, setSignatoryOnTradingAcc] = useState(false);
  const [assetIssuanceTokenBalance, setAssetIssuanceTokenBalance] = useState<
    Balance | undefined
  >(undefined);
  const [assetIssuanceTokenViewModel, setAssetIssuanceTokenViewModel] =
    useState<LedgerTokenViewModel | undefined>(undefined);
  const [assetValuationTokenBalance, setAssetValuationTokenBalance] = useState<
    Balance | undefined
  >(undefined);
  const [assetValuationTokenViewModel, setAssetValuationTokenViewModel] =
    useState<LedgerTokenViewModel | undefined>(undefined);
  const [refreshCardToggle, setRefreshCardToggle] = useState(false);
  const [marketMechanismQuoteParameter, setMarketMechanismQuoteParameter] =
    useState<QuoteParameter | undefined>(undefined);
  const refreshCard = () => {
    setRefreshCardToggle(!refreshCardToggle);
  };
  const { errorContextDefaultErrorFeedback, errorContextErrorTranslator } =
    useErrorContext();

  useEffect(() => {
    if (!isMounted()) {
      return;
    }
    (async () => {
      if (stellarAccountContext.loading) {
        return;
      }

      if (!myClient && myClientRetrievalErr) {
        errorContextDefaultErrorFeedback(myClientRetrievalErr);
        return;
      }

      if (!myClient) {
        return;
      }

      if (stellarAccountContext.error) {
        console.error(`initialization error: ${stellarAccountContext.error}`);
        enqueueSnackbar(
          `Initialization Error: ${stellarAccountContext.error}`,
          {
            variant: "error",
          },
        );
      }

      try {
        // prepare required data variables
        let retrievedListing!: Listing;
        let retrievedMarketListingViewModel!: MarketListingViewModel;
        let retrievedMarketMechanismQuoteParameter!: QuoteParameter;
        let retrievedAssetIssuanceTokenViewModel!: LedgerTokenViewModel;
        let retrievedAssetValuationTokenViewModel!: LedgerTokenViewModel;

        // retrieve relevant trading account
        const retrievedAcc = viewingAsAssetIssuer
          ? stellarAccountContext.accounts.find(
              (val) =>
                val.getOwnerid() ===
                  props.marketDirectOrderViewModel.assetOwnerID &&
                val.getLabel() === LedgerAccountCategory.Trading,
            )
          : stellarAccountContext.accounts.find(
              (val) =>
                val.getOwnerid() === new Client(myClient).ownerID &&
                val.getLabel() === LedgerAccountCategory.Trading,
            );

        await Promise.all([
          // retrieve listing view model
          (async () => {
            retrievedMarketListingViewModel = (
              await MarketListingViewReader.ReadOne({
                context: authContext,
                criteria:
                  props.marketDirectOrderViewModel.tokens.token.toFilter(),
              })
            ).model;
          })(),
          // retrieve listing
          (async () => {
            retrievedListing = (
              await ListingRepository.RetrieveListing({
                context: authContext,
                identifier: IDIdentifier(
                  props.marketDirectOrderViewModel.listingID,
                ),
              })
            ).listing;
          })(),
          // retrieve asset issuance and valuation token view models
          (async () => {
            retrievedAssetIssuanceTokenViewModel =
              await getLedgerTokenViewModel(
                props.marketDirectOrderViewModel.tokens.token,
              );
          })(),
          (async () => {
            retrievedAssetValuationTokenViewModel =
              await getLedgerTokenViewModel(
                props.marketDirectOrderViewModel.amountIncl.token,
              );
          })(),
        ]);

        // set the marketmechanism quote parameter
        for (const m of retrievedListing.marketMechanisms) {
          if (
            m.type === MechanismType.DirectOrder &&
            m.quoteParameters.length === 1
          ) {
            retrievedMarketMechanismQuoteParameter = m.quoteParameters[0];
          }
        }

        if (
          !(
            retrievedAcc &&
            retrievedListing &&
            retrievedAssetIssuanceTokenViewModel &&
            retrievedAssetValuationTokenViewModel &&
            retrievedMarketMechanismQuoteParameter &&
            retrievedMarketListingViewModel
          )
        ) {
          console.error("something has gone wrong");
          return;
        }

        // determine if executing user is a signatory on this account
        try {
          if (isMounted()) {
            setSignatoryOnTradingAcc(
              await stellarAccountContext.checkUserSignatoryOnAccount(
                LedgerIDIdentifier(retrievedAcc.getLedgerid()),
              ),
            );
          }
        } catch (e) {
          const err = errorContextErrorTranslator.translateError(e);
          console.error(
            `error determining if user is signatory on account: ${
              err.message ? err.message : err.toString()
            }`,
          );
          enqueueSnackbar("Error Determining Signatory Status", {
            variant: "error",
          });
        }

        // find relevant token balances
        const assetIssuanceTokenBal = getTokenBalance(
          retrievedAcc,
          retrievedListing.token.toFutureToken(),
        );
        const assetValuationTokenBal = getTokenBalance(
          retrievedAcc,
          retrievedMarketMechanismQuoteParameter.quoteToken.toFutureToken(),
        );

        // set required values
        if (isMounted()) {
          setListing(retrievedListing);
          setMarketListingViewModel(retrievedMarketListingViewModel);
          setTradingAccViewModel(retrievedAcc);
          setTradingAccountID(retrievedAcc.getId());
          setMarketMechanismQuoteParameter(
            retrievedMarketMechanismQuoteParameter,
          );

          setAssetIssuanceTokenBalance(
            assetIssuanceTokenBal ||
              new Balance()
                .setAmount(
                  retrievedListing.token.newAmountOf("0").toFutureAmount(),
                )
                .setLimit(
                  retrievedListing.token.newAmountOf("0").toFutureAmount(),
                )
                .setSellingliabilities(
                  retrievedListing.token.newAmountOf("0").toFutureAmount(),
                )
                .setBuyingliabilities(
                  retrievedListing.token.newAmountOf("0").toFutureAmount(),
                ),
          );
          setAssetIssuanceTokenViewModel(retrievedAssetIssuanceTokenViewModel);
          setAssetValuationTokenBalance(
            assetValuationTokenBal ||
              new Balance()
                .setAmount(
                  retrievedMarketMechanismQuoteParameter.quoteToken
                    .newAmountOf("0")
                    .toFutureAmount(),
                )
                .setLimit(
                  retrievedMarketMechanismQuoteParameter.quoteToken
                    .newAmountOf("0")
                    .toFutureAmount(),
                )
                .setSellingliabilities(
                  retrievedListing.token.newAmountOf("0").toFutureAmount(),
                )
                .setBuyingliabilities(
                  retrievedListing.token.newAmountOf("0").toFutureAmount(),
                ),
          );
          setAssetValuationTokenViewModel(
            retrievedAssetValuationTokenViewModel,
          );
        }
      } catch (e) {
        const err = errorContextErrorTranslator.translateError(e);
        console.error(
          `error initialising: ${err.message ? err.message : err.toString()}`,
        );
      }
      if (isMounted()) {
        setFetchingRequiredData(false);
      }
    })();
  }, [
    refreshCardToggle,
    viewingAsAssetIssuer,
    isMounted,
    props.marketDirectOrderViewModel.listingID,
    props.marketDirectOrderViewModel.assetOwnerID,
    props.marketDirectOrderViewModel.tokens,
    props.marketDirectOrderViewModel.amountIncl,
    myClient,
    stellarAccountContext.loading,
    stellarAccountContext.error,
    authContext,
    getLedgerTokenViewModel,
  ]);

  if (
    props.marketDirectOrderViewModel.state !==
    DirectOrderState.AwaitingConfirmation
  ) {
    return (
      <DefaultCard
        viewingAsInitiatingParty={viewingAsInitiatingParty}
        marketDirectOrderViewModel={props.marketDirectOrderViewModel}
        close={close}
      />
    );
  }

  const retNode = fetchingRequiredData ? (
    <Card
      sx={{
        [theme.breakpoints.down("sm")]: {
          borderRadius: 0,
          height: "100%",
        },
      }}
    >
      <Header marketDirectOrderViewModel={props.marketDirectOrderViewModel} />
      <LoadingSplash />
    </Card>
  ) : viewingAsAssetIssuer ? (
    listing &&
    tradingAccViewModel &&
    assetIssuanceTokenBalance &&
    assetIssuanceTokenViewModel &&
    assetValuationTokenBalance &&
    assetValuationTokenViewModel &&
    tradingAccountID &&
    marketMechanismQuoteParameter &&
    marketListingViewModel && (
      <IssuerAwaitingConfirmationCard
        onActionComplete={props.onActionComplete}
        refreshCard={refreshCard}
        tradingAccViewModel={tradingAccViewModel}
        viewingAsInitiatingParty={viewingAsInitiatingParty}
        listing={listing}
        marketListingViewModel={marketListingViewModel}
        marketDirectOrderViewModel={props.marketDirectOrderViewModel}
        tradingAccountID={tradingAccountID}
        userIsSignatoryOnTradingAcc={signatoryOnTradingAcc}
        assetIssuanceTokenBalance={assetIssuanceTokenBalance}
        assetIssuanceTokenViewModel={assetIssuanceTokenViewModel}
        assetValuationTokenBalance={assetValuationTokenBalance}
        assetValuationTokenViewModel={assetValuationTokenViewModel}
        marketMechanismQuoteParameter={marketMechanismQuoteParameter}
        close={close}
      />
    )
  ) : (
    listing &&
    tradingAccViewModel &&
    assetIssuanceTokenBalance &&
    assetIssuanceTokenViewModel &&
    assetValuationTokenBalance &&
    assetValuationTokenViewModel &&
    tradingAccountID &&
    marketMechanismQuoteParameter && (
      <InvestorAwaitingConfirmationCard
        onActionComplete={props.onActionComplete}
        refreshCard={refreshCard}
        tradingAccViewModel={tradingAccViewModel}
        viewingAsInitiatingParty={viewingAsInitiatingParty}
        listing={listing}
        marketDirectOrderViewModel={props.marketDirectOrderViewModel}
        tradingAccountID={tradingAccountID}
        userIsSignatoryOnTradingAcc={signatoryOnTradingAcc}
        assetIssuanceTokenBalance={assetIssuanceTokenBalance}
        assetIssuanceTokenViewModel={assetIssuanceTokenViewModel}
        assetValuationTokenBalance={assetValuationTokenBalance}
        assetValuationTokenViewModel={assetValuationTokenViewModel}
        marketMechanismQuoteParameter={marketMechanismQuoteParameter}
        close={close}
      />
    )
  );

  return retNode || null;
}

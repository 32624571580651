// source: market/offPlatformTransferRecipientReader_meshproto.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global =
    (typeof globalThis !== 'undefined' && globalThis) ||
    (typeof window !== 'undefined' && window) ||
    (typeof global !== 'undefined' && global) ||
    (typeof self !== 'undefined' && self) ||
    (function () { return this; }).call(null) ||
    Function('return this')();

var security_context_pb = require('../security/context_pb.js');
goog.object.extend(proto, security_context_pb);
var search_criterion_pb = require('../search/criterion_pb.js');
goog.object.extend(proto, search_criterion_pb);
var search_query_pb = require('../search/query_pb.js');
goog.object.extend(proto, search_query_pb);
var search_sorting_pb = require('../search/sorting_pb.js');
goog.object.extend(proto, search_sorting_pb);
var market_offPlatformTransferRecipient_pb = require('../market/offPlatformTransferRecipient_pb.js');
goog.object.extend(proto, market_offPlatformTransferRecipient_pb);
var ledger_futureNetwork_pb = require('../ledger/futureNetwork_pb.js');
goog.object.extend(proto, ledger_futureNetwork_pb);
goog.exportSymbol('proto.market.ReadAllOffPlatformTransferRecipientRequest', null, global);
goog.exportSymbol('proto.market.ReadAllOffPlatformTransferRecipientResponse', null, global);
goog.exportSymbol('proto.market.ReadManyOffPlatformTransferRecipientRequest', null, global);
goog.exportSymbol('proto.market.ReadManyOffPlatformTransferRecipientResponse', null, global);
goog.exportSymbol('proto.market.ReadOneOffPlatformTransferRecipientRequest', null, global);
goog.exportSymbol('proto.market.ReadOneOffPlatformTransferRecipientResponse', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.market.ReadOneOffPlatformTransferRecipientRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.market.ReadOneOffPlatformTransferRecipientRequest.repeatedFields_, null);
};
goog.inherits(proto.market.ReadOneOffPlatformTransferRecipientRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.market.ReadOneOffPlatformTransferRecipientRequest.displayName = 'proto.market.ReadOneOffPlatformTransferRecipientRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.market.ReadOneOffPlatformTransferRecipientResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.market.ReadOneOffPlatformTransferRecipientResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.market.ReadOneOffPlatformTransferRecipientResponse.displayName = 'proto.market.ReadOneOffPlatformTransferRecipientResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.market.ReadManyOffPlatformTransferRecipientRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.market.ReadManyOffPlatformTransferRecipientRequest.repeatedFields_, null);
};
goog.inherits(proto.market.ReadManyOffPlatformTransferRecipientRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.market.ReadManyOffPlatformTransferRecipientRequest.displayName = 'proto.market.ReadManyOffPlatformTransferRecipientRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.market.ReadManyOffPlatformTransferRecipientResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.market.ReadManyOffPlatformTransferRecipientResponse.repeatedFields_, null);
};
goog.inherits(proto.market.ReadManyOffPlatformTransferRecipientResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.market.ReadManyOffPlatformTransferRecipientResponse.displayName = 'proto.market.ReadManyOffPlatformTransferRecipientResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.market.ReadAllOffPlatformTransferRecipientRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.market.ReadAllOffPlatformTransferRecipientRequest.repeatedFields_, null);
};
goog.inherits(proto.market.ReadAllOffPlatformTransferRecipientRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.market.ReadAllOffPlatformTransferRecipientRequest.displayName = 'proto.market.ReadAllOffPlatformTransferRecipientRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.market.ReadAllOffPlatformTransferRecipientResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.market.ReadAllOffPlatformTransferRecipientResponse.repeatedFields_, null);
};
goog.inherits(proto.market.ReadAllOffPlatformTransferRecipientResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.market.ReadAllOffPlatformTransferRecipientResponse.displayName = 'proto.market.ReadAllOffPlatformTransferRecipientResponse';
}

/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.market.ReadOneOffPlatformTransferRecipientRequest.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.market.ReadOneOffPlatformTransferRecipientRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.market.ReadOneOffPlatformTransferRecipientRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.market.ReadOneOffPlatformTransferRecipientRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.market.ReadOneOffPlatformTransferRecipientRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
context: (f = msg.getContext()) && security_context_pb.ContextFuture.toObject(includeInstance, f),
criteriaList: jspb.Message.toObjectList(msg.getCriteriaList(),
    search_criterion_pb.Criterion.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.market.ReadOneOffPlatformTransferRecipientRequest}
 */
proto.market.ReadOneOffPlatformTransferRecipientRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.market.ReadOneOffPlatformTransferRecipientRequest;
  return proto.market.ReadOneOffPlatformTransferRecipientRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.market.ReadOneOffPlatformTransferRecipientRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.market.ReadOneOffPlatformTransferRecipientRequest}
 */
proto.market.ReadOneOffPlatformTransferRecipientRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new security_context_pb.ContextFuture;
      reader.readMessage(value,security_context_pb.ContextFuture.deserializeBinaryFromReader);
      msg.setContext(value);
      break;
    case 2:
      var value = new search_criterion_pb.Criterion;
      reader.readMessage(value,search_criterion_pb.Criterion.deserializeBinaryFromReader);
      msg.addCriteria(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.market.ReadOneOffPlatformTransferRecipientRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.market.ReadOneOffPlatformTransferRecipientRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.market.ReadOneOffPlatformTransferRecipientRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.market.ReadOneOffPlatformTransferRecipientRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getContext();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      security_context_pb.ContextFuture.serializeBinaryToWriter
    );
  }
  f = message.getCriteriaList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      search_criterion_pb.Criterion.serializeBinaryToWriter
    );
  }
};


/**
 * optional security.ContextFuture context = 1;
 * @return {?proto.security.ContextFuture}
 */
proto.market.ReadOneOffPlatformTransferRecipientRequest.prototype.getContext = function() {
  return /** @type{?proto.security.ContextFuture} */ (
    jspb.Message.getWrapperField(this, security_context_pb.ContextFuture, 1));
};


/**
 * @param {?proto.security.ContextFuture|undefined} value
 * @return {!proto.market.ReadOneOffPlatformTransferRecipientRequest} returns this
*/
proto.market.ReadOneOffPlatformTransferRecipientRequest.prototype.setContext = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.market.ReadOneOffPlatformTransferRecipientRequest} returns this
 */
proto.market.ReadOneOffPlatformTransferRecipientRequest.prototype.clearContext = function() {
  return this.setContext(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.market.ReadOneOffPlatformTransferRecipientRequest.prototype.hasContext = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * repeated search.Criterion criteria = 2;
 * @return {!Array<!proto.search.Criterion>}
 */
proto.market.ReadOneOffPlatformTransferRecipientRequest.prototype.getCriteriaList = function() {
  return /** @type{!Array<!proto.search.Criterion>} */ (
    jspb.Message.getRepeatedWrapperField(this, search_criterion_pb.Criterion, 2));
};


/**
 * @param {!Array<!proto.search.Criterion>} value
 * @return {!proto.market.ReadOneOffPlatformTransferRecipientRequest} returns this
*/
proto.market.ReadOneOffPlatformTransferRecipientRequest.prototype.setCriteriaList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.search.Criterion=} opt_value
 * @param {number=} opt_index
 * @return {!proto.search.Criterion}
 */
proto.market.ReadOneOffPlatformTransferRecipientRequest.prototype.addCriteria = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.search.Criterion, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.market.ReadOneOffPlatformTransferRecipientRequest} returns this
 */
proto.market.ReadOneOffPlatformTransferRecipientRequest.prototype.clearCriteriaList = function() {
  return this.setCriteriaList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.market.ReadOneOffPlatformTransferRecipientResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.market.ReadOneOffPlatformTransferRecipientResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.market.ReadOneOffPlatformTransferRecipientResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.market.ReadOneOffPlatformTransferRecipientResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
offplatformtransferrecipient: (f = msg.getOffplatformtransferrecipient()) && market_offPlatformTransferRecipient_pb.OffPlatformTransferRecipient.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.market.ReadOneOffPlatformTransferRecipientResponse}
 */
proto.market.ReadOneOffPlatformTransferRecipientResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.market.ReadOneOffPlatformTransferRecipientResponse;
  return proto.market.ReadOneOffPlatformTransferRecipientResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.market.ReadOneOffPlatformTransferRecipientResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.market.ReadOneOffPlatformTransferRecipientResponse}
 */
proto.market.ReadOneOffPlatformTransferRecipientResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new market_offPlatformTransferRecipient_pb.OffPlatformTransferRecipient;
      reader.readMessage(value,market_offPlatformTransferRecipient_pb.OffPlatformTransferRecipient.deserializeBinaryFromReader);
      msg.setOffplatformtransferrecipient(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.market.ReadOneOffPlatformTransferRecipientResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.market.ReadOneOffPlatformTransferRecipientResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.market.ReadOneOffPlatformTransferRecipientResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.market.ReadOneOffPlatformTransferRecipientResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getOffplatformtransferrecipient();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      market_offPlatformTransferRecipient_pb.OffPlatformTransferRecipient.serializeBinaryToWriter
    );
  }
};


/**
 * optional OffPlatformTransferRecipient offPlatformTransferRecipient = 1;
 * @return {?proto.market.OffPlatformTransferRecipient}
 */
proto.market.ReadOneOffPlatformTransferRecipientResponse.prototype.getOffplatformtransferrecipient = function() {
  return /** @type{?proto.market.OffPlatformTransferRecipient} */ (
    jspb.Message.getWrapperField(this, market_offPlatformTransferRecipient_pb.OffPlatformTransferRecipient, 1));
};


/**
 * @param {?proto.market.OffPlatformTransferRecipient|undefined} value
 * @return {!proto.market.ReadOneOffPlatformTransferRecipientResponse} returns this
*/
proto.market.ReadOneOffPlatformTransferRecipientResponse.prototype.setOffplatformtransferrecipient = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.market.ReadOneOffPlatformTransferRecipientResponse} returns this
 */
proto.market.ReadOneOffPlatformTransferRecipientResponse.prototype.clearOffplatformtransferrecipient = function() {
  return this.setOffplatformtransferrecipient(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.market.ReadOneOffPlatformTransferRecipientResponse.prototype.hasOffplatformtransferrecipient = function() {
  return jspb.Message.getField(this, 1) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.market.ReadManyOffPlatformTransferRecipientRequest.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.market.ReadManyOffPlatformTransferRecipientRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.market.ReadManyOffPlatformTransferRecipientRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.market.ReadManyOffPlatformTransferRecipientRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.market.ReadManyOffPlatformTransferRecipientRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
context: (f = msg.getContext()) && security_context_pb.ContextFuture.toObject(includeInstance, f),
criteriaList: jspb.Message.toObjectList(msg.getCriteriaList(),
    search_criterion_pb.Criterion.toObject, includeInstance),
query: (f = msg.getQuery()) && search_query_pb.Query.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.market.ReadManyOffPlatformTransferRecipientRequest}
 */
proto.market.ReadManyOffPlatformTransferRecipientRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.market.ReadManyOffPlatformTransferRecipientRequest;
  return proto.market.ReadManyOffPlatformTransferRecipientRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.market.ReadManyOffPlatformTransferRecipientRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.market.ReadManyOffPlatformTransferRecipientRequest}
 */
proto.market.ReadManyOffPlatformTransferRecipientRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new security_context_pb.ContextFuture;
      reader.readMessage(value,security_context_pb.ContextFuture.deserializeBinaryFromReader);
      msg.setContext(value);
      break;
    case 2:
      var value = new search_criterion_pb.Criterion;
      reader.readMessage(value,search_criterion_pb.Criterion.deserializeBinaryFromReader);
      msg.addCriteria(value);
      break;
    case 3:
      var value = new search_query_pb.Query;
      reader.readMessage(value,search_query_pb.Query.deserializeBinaryFromReader);
      msg.setQuery(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.market.ReadManyOffPlatformTransferRecipientRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.market.ReadManyOffPlatformTransferRecipientRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.market.ReadManyOffPlatformTransferRecipientRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.market.ReadManyOffPlatformTransferRecipientRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getContext();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      security_context_pb.ContextFuture.serializeBinaryToWriter
    );
  }
  f = message.getCriteriaList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      search_criterion_pb.Criterion.serializeBinaryToWriter
    );
  }
  f = message.getQuery();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      search_query_pb.Query.serializeBinaryToWriter
    );
  }
};


/**
 * optional security.ContextFuture context = 1;
 * @return {?proto.security.ContextFuture}
 */
proto.market.ReadManyOffPlatformTransferRecipientRequest.prototype.getContext = function() {
  return /** @type{?proto.security.ContextFuture} */ (
    jspb.Message.getWrapperField(this, security_context_pb.ContextFuture, 1));
};


/**
 * @param {?proto.security.ContextFuture|undefined} value
 * @return {!proto.market.ReadManyOffPlatformTransferRecipientRequest} returns this
*/
proto.market.ReadManyOffPlatformTransferRecipientRequest.prototype.setContext = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.market.ReadManyOffPlatformTransferRecipientRequest} returns this
 */
proto.market.ReadManyOffPlatformTransferRecipientRequest.prototype.clearContext = function() {
  return this.setContext(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.market.ReadManyOffPlatformTransferRecipientRequest.prototype.hasContext = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * repeated search.Criterion criteria = 2;
 * @return {!Array<!proto.search.Criterion>}
 */
proto.market.ReadManyOffPlatformTransferRecipientRequest.prototype.getCriteriaList = function() {
  return /** @type{!Array<!proto.search.Criterion>} */ (
    jspb.Message.getRepeatedWrapperField(this, search_criterion_pb.Criterion, 2));
};


/**
 * @param {!Array<!proto.search.Criterion>} value
 * @return {!proto.market.ReadManyOffPlatformTransferRecipientRequest} returns this
*/
proto.market.ReadManyOffPlatformTransferRecipientRequest.prototype.setCriteriaList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.search.Criterion=} opt_value
 * @param {number=} opt_index
 * @return {!proto.search.Criterion}
 */
proto.market.ReadManyOffPlatformTransferRecipientRequest.prototype.addCriteria = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.search.Criterion, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.market.ReadManyOffPlatformTransferRecipientRequest} returns this
 */
proto.market.ReadManyOffPlatformTransferRecipientRequest.prototype.clearCriteriaList = function() {
  return this.setCriteriaList([]);
};


/**
 * optional search.Query query = 3;
 * @return {?proto.search.Query}
 */
proto.market.ReadManyOffPlatformTransferRecipientRequest.prototype.getQuery = function() {
  return /** @type{?proto.search.Query} */ (
    jspb.Message.getWrapperField(this, search_query_pb.Query, 3));
};


/**
 * @param {?proto.search.Query|undefined} value
 * @return {!proto.market.ReadManyOffPlatformTransferRecipientRequest} returns this
*/
proto.market.ReadManyOffPlatformTransferRecipientRequest.prototype.setQuery = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.market.ReadManyOffPlatformTransferRecipientRequest} returns this
 */
proto.market.ReadManyOffPlatformTransferRecipientRequest.prototype.clearQuery = function() {
  return this.setQuery(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.market.ReadManyOffPlatformTransferRecipientRequest.prototype.hasQuery = function() {
  return jspb.Message.getField(this, 3) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.market.ReadManyOffPlatformTransferRecipientResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.market.ReadManyOffPlatformTransferRecipientResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.market.ReadManyOffPlatformTransferRecipientResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.market.ReadManyOffPlatformTransferRecipientResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.market.ReadManyOffPlatformTransferRecipientResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
recordsList: jspb.Message.toObjectList(msg.getRecordsList(),
    market_offPlatformTransferRecipient_pb.OffPlatformTransferRecipient.toObject, includeInstance),
total: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.market.ReadManyOffPlatformTransferRecipientResponse}
 */
proto.market.ReadManyOffPlatformTransferRecipientResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.market.ReadManyOffPlatformTransferRecipientResponse;
  return proto.market.ReadManyOffPlatformTransferRecipientResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.market.ReadManyOffPlatformTransferRecipientResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.market.ReadManyOffPlatformTransferRecipientResponse}
 */
proto.market.ReadManyOffPlatformTransferRecipientResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new market_offPlatformTransferRecipient_pb.OffPlatformTransferRecipient;
      reader.readMessage(value,market_offPlatformTransferRecipient_pb.OffPlatformTransferRecipient.deserializeBinaryFromReader);
      msg.addRecords(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setTotal(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.market.ReadManyOffPlatformTransferRecipientResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.market.ReadManyOffPlatformTransferRecipientResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.market.ReadManyOffPlatformTransferRecipientResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.market.ReadManyOffPlatformTransferRecipientResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getRecordsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      market_offPlatformTransferRecipient_pb.OffPlatformTransferRecipient.serializeBinaryToWriter
    );
  }
  f = message.getTotal();
  if (f !== 0) {
    writer.writeInt64(
      2,
      f
    );
  }
};


/**
 * repeated OffPlatformTransferRecipient records = 1;
 * @return {!Array<!proto.market.OffPlatformTransferRecipient>}
 */
proto.market.ReadManyOffPlatformTransferRecipientResponse.prototype.getRecordsList = function() {
  return /** @type{!Array<!proto.market.OffPlatformTransferRecipient>} */ (
    jspb.Message.getRepeatedWrapperField(this, market_offPlatformTransferRecipient_pb.OffPlatformTransferRecipient, 1));
};


/**
 * @param {!Array<!proto.market.OffPlatformTransferRecipient>} value
 * @return {!proto.market.ReadManyOffPlatformTransferRecipientResponse} returns this
*/
proto.market.ReadManyOffPlatformTransferRecipientResponse.prototype.setRecordsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.market.OffPlatformTransferRecipient=} opt_value
 * @param {number=} opt_index
 * @return {!proto.market.OffPlatformTransferRecipient}
 */
proto.market.ReadManyOffPlatformTransferRecipientResponse.prototype.addRecords = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.market.OffPlatformTransferRecipient, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.market.ReadManyOffPlatformTransferRecipientResponse} returns this
 */
proto.market.ReadManyOffPlatformTransferRecipientResponse.prototype.clearRecordsList = function() {
  return this.setRecordsList([]);
};


/**
 * optional int64 total = 2;
 * @return {number}
 */
proto.market.ReadManyOffPlatformTransferRecipientResponse.prototype.getTotal = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.market.ReadManyOffPlatformTransferRecipientResponse} returns this
 */
proto.market.ReadManyOffPlatformTransferRecipientResponse.prototype.setTotal = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.market.ReadAllOffPlatformTransferRecipientRequest.repeatedFields_ = [2,3];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.market.ReadAllOffPlatformTransferRecipientRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.market.ReadAllOffPlatformTransferRecipientRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.market.ReadAllOffPlatformTransferRecipientRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.market.ReadAllOffPlatformTransferRecipientRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
context: (f = msg.getContext()) && security_context_pb.ContextFuture.toObject(includeInstance, f),
criteriaList: jspb.Message.toObjectList(msg.getCriteriaList(),
    search_criterion_pb.Criterion.toObject, includeInstance),
sortingList: jspb.Message.toObjectList(msg.getSortingList(),
    search_sorting_pb.Sorting.toObject, includeInstance),
batchsize: jspb.Message.getFieldWithDefault(msg, 4, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.market.ReadAllOffPlatformTransferRecipientRequest}
 */
proto.market.ReadAllOffPlatformTransferRecipientRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.market.ReadAllOffPlatformTransferRecipientRequest;
  return proto.market.ReadAllOffPlatformTransferRecipientRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.market.ReadAllOffPlatformTransferRecipientRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.market.ReadAllOffPlatformTransferRecipientRequest}
 */
proto.market.ReadAllOffPlatformTransferRecipientRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new security_context_pb.ContextFuture;
      reader.readMessage(value,security_context_pb.ContextFuture.deserializeBinaryFromReader);
      msg.setContext(value);
      break;
    case 2:
      var value = new search_criterion_pb.Criterion;
      reader.readMessage(value,search_criterion_pb.Criterion.deserializeBinaryFromReader);
      msg.addCriteria(value);
      break;
    case 3:
      var value = new search_sorting_pb.Sorting;
      reader.readMessage(value,search_sorting_pb.Sorting.deserializeBinaryFromReader);
      msg.addSorting(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setBatchsize(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.market.ReadAllOffPlatformTransferRecipientRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.market.ReadAllOffPlatformTransferRecipientRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.market.ReadAllOffPlatformTransferRecipientRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.market.ReadAllOffPlatformTransferRecipientRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getContext();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      security_context_pb.ContextFuture.serializeBinaryToWriter
    );
  }
  f = message.getCriteriaList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      search_criterion_pb.Criterion.serializeBinaryToWriter
    );
  }
  f = message.getSortingList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      3,
      f,
      search_sorting_pb.Sorting.serializeBinaryToWriter
    );
  }
  f = message.getBatchsize();
  if (f !== 0) {
    writer.writeUint64(
      4,
      f
    );
  }
};


/**
 * optional security.ContextFuture context = 1;
 * @return {?proto.security.ContextFuture}
 */
proto.market.ReadAllOffPlatformTransferRecipientRequest.prototype.getContext = function() {
  return /** @type{?proto.security.ContextFuture} */ (
    jspb.Message.getWrapperField(this, security_context_pb.ContextFuture, 1));
};


/**
 * @param {?proto.security.ContextFuture|undefined} value
 * @return {!proto.market.ReadAllOffPlatformTransferRecipientRequest} returns this
*/
proto.market.ReadAllOffPlatformTransferRecipientRequest.prototype.setContext = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.market.ReadAllOffPlatformTransferRecipientRequest} returns this
 */
proto.market.ReadAllOffPlatformTransferRecipientRequest.prototype.clearContext = function() {
  return this.setContext(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.market.ReadAllOffPlatformTransferRecipientRequest.prototype.hasContext = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * repeated search.Criterion criteria = 2;
 * @return {!Array<!proto.search.Criterion>}
 */
proto.market.ReadAllOffPlatformTransferRecipientRequest.prototype.getCriteriaList = function() {
  return /** @type{!Array<!proto.search.Criterion>} */ (
    jspb.Message.getRepeatedWrapperField(this, search_criterion_pb.Criterion, 2));
};


/**
 * @param {!Array<!proto.search.Criterion>} value
 * @return {!proto.market.ReadAllOffPlatformTransferRecipientRequest} returns this
*/
proto.market.ReadAllOffPlatformTransferRecipientRequest.prototype.setCriteriaList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.search.Criterion=} opt_value
 * @param {number=} opt_index
 * @return {!proto.search.Criterion}
 */
proto.market.ReadAllOffPlatformTransferRecipientRequest.prototype.addCriteria = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.search.Criterion, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.market.ReadAllOffPlatformTransferRecipientRequest} returns this
 */
proto.market.ReadAllOffPlatformTransferRecipientRequest.prototype.clearCriteriaList = function() {
  return this.setCriteriaList([]);
};


/**
 * repeated search.Sorting sorting = 3;
 * @return {!Array<!proto.search.Sorting>}
 */
proto.market.ReadAllOffPlatformTransferRecipientRequest.prototype.getSortingList = function() {
  return /** @type{!Array<!proto.search.Sorting>} */ (
    jspb.Message.getRepeatedWrapperField(this, search_sorting_pb.Sorting, 3));
};


/**
 * @param {!Array<!proto.search.Sorting>} value
 * @return {!proto.market.ReadAllOffPlatformTransferRecipientRequest} returns this
*/
proto.market.ReadAllOffPlatformTransferRecipientRequest.prototype.setSortingList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 3, value);
};


/**
 * @param {!proto.search.Sorting=} opt_value
 * @param {number=} opt_index
 * @return {!proto.search.Sorting}
 */
proto.market.ReadAllOffPlatformTransferRecipientRequest.prototype.addSorting = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 3, opt_value, proto.search.Sorting, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.market.ReadAllOffPlatformTransferRecipientRequest} returns this
 */
proto.market.ReadAllOffPlatformTransferRecipientRequest.prototype.clearSortingList = function() {
  return this.setSortingList([]);
};


/**
 * optional uint64 batchSize = 4;
 * @return {number}
 */
proto.market.ReadAllOffPlatformTransferRecipientRequest.prototype.getBatchsize = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.market.ReadAllOffPlatformTransferRecipientRequest} returns this
 */
proto.market.ReadAllOffPlatformTransferRecipientRequest.prototype.setBatchsize = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.market.ReadAllOffPlatformTransferRecipientResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.market.ReadAllOffPlatformTransferRecipientResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.market.ReadAllOffPlatformTransferRecipientResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.market.ReadAllOffPlatformTransferRecipientResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.market.ReadAllOffPlatformTransferRecipientResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
recordsList: jspb.Message.toObjectList(msg.getRecordsList(),
    market_offPlatformTransferRecipient_pb.OffPlatformTransferRecipient.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.market.ReadAllOffPlatformTransferRecipientResponse}
 */
proto.market.ReadAllOffPlatformTransferRecipientResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.market.ReadAllOffPlatformTransferRecipientResponse;
  return proto.market.ReadAllOffPlatformTransferRecipientResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.market.ReadAllOffPlatformTransferRecipientResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.market.ReadAllOffPlatformTransferRecipientResponse}
 */
proto.market.ReadAllOffPlatformTransferRecipientResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new market_offPlatformTransferRecipient_pb.OffPlatformTransferRecipient;
      reader.readMessage(value,market_offPlatformTransferRecipient_pb.OffPlatformTransferRecipient.deserializeBinaryFromReader);
      msg.addRecords(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.market.ReadAllOffPlatformTransferRecipientResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.market.ReadAllOffPlatformTransferRecipientResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.market.ReadAllOffPlatformTransferRecipientResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.market.ReadAllOffPlatformTransferRecipientResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getRecordsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      market_offPlatformTransferRecipient_pb.OffPlatformTransferRecipient.serializeBinaryToWriter
    );
  }
};


/**
 * repeated OffPlatformTransferRecipient records = 1;
 * @return {!Array<!proto.market.OffPlatformTransferRecipient>}
 */
proto.market.ReadAllOffPlatformTransferRecipientResponse.prototype.getRecordsList = function() {
  return /** @type{!Array<!proto.market.OffPlatformTransferRecipient>} */ (
    jspb.Message.getRepeatedWrapperField(this, market_offPlatformTransferRecipient_pb.OffPlatformTransferRecipient, 1));
};


/**
 * @param {!Array<!proto.market.OffPlatformTransferRecipient>} value
 * @return {!proto.market.ReadAllOffPlatformTransferRecipientResponse} returns this
*/
proto.market.ReadAllOffPlatformTransferRecipientResponse.prototype.setRecordsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.market.OffPlatformTransferRecipient=} opt_value
 * @param {number=} opt_index
 * @return {!proto.market.OffPlatformTransferRecipient}
 */
proto.market.ReadAllOffPlatformTransferRecipientResponse.prototype.addRecords = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.market.OffPlatformTransferRecipient, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.market.ReadAllOffPlatformTransferRecipientResponse} returns this
 */
proto.market.ReadAllOffPlatformTransferRecipientResponse.prototype.clearRecordsList = function() {
  return this.setRecordsList([]);
};


goog.object.extend(exports, proto.market);

import { NewsArticle, MediaEntry } from "james/marketing";
import { DataSheetSection } from "./DataSheetSection";

export const AssetMarketingMediaDataSheetSectionTypeName =
  "mesh::marketData/AssetMarketingMedia";

export const AssetMarketingMediaDataSheetSectionName = "Media & Resources";

export class AssetMarketingMediaDataSheetSection implements DataSheetSection {
  public ["@type"] = AssetMarketingMediaDataSheetSectionTypeName;

  public newsArticles: NewsArticle[] = [];
  public mediaEntries: MediaEntry[] = [];

  constructor(m?: AssetMarketingMediaDataSheetSection) {
    if (!m) return;

    if (m.newsArticles) {
      this.newsArticles = m.newsArticles.map((v) => new NewsArticle(v));
    }
    if (m.mediaEntries) {
      this.mediaEntries = m.mediaEntries.map((v) => new MediaEntry(v));
    }
  }

  public DataSheetSectionName(): string {
    return AssetMarketingMediaDataSheetSectionName;
  }
}

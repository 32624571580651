import React from "react";
import { Cell, Pie, PieChart, Tooltip } from "recharts";
import { FinancialSector, SectorAllocation } from "james/financial";
import { Box, Paper, Typography, styled, useTheme } from "@mui/material";
import { formatTextNum } from "utilities/number";
import { BigNumber } from "bignumber.js";
import { useAssetColorPicker } from "hooks";

const PREFIX = "SectorAllocationPieChart";

const classes = {
  chartLegendLineItem: `${PREFIX}-chartLegendLineItem`,
  legendColorIndicator: `${PREFIX}-legendColorIndicator`,
  chartToolTipPaper: `${PREFIX}-chartToolTipPaper`,
  chartToolTipText: `${PREFIX}-chartToolTipText`,
};

const Root = styled("div")(({ theme }) => ({
  [`& .${classes.chartLegendLineItem}`]: {
    display: "grid",
    gridTemplateColumns: "20px 50px auto",
    gridColumnGap: "4px",
    marginBottom: theme.spacing(0.5),
    alignItems: "center",
  },

  [`& .${classes.legendColorIndicator}`]: {
    borderRadius: "50%",
    backgroundColor: "black",
    width: 8,
    height: 8,
    alignSelf: "center",
  },

  [`& .${classes.chartToolTipPaper}`]: {
    borderRadius: 4,
    border: `solid 1px ${theme.palette.background.default}`,
    padding: theme.spacing(0.5, 1, 0.5, 1),
    display: "grid",
    gridTemplateColumns: "auto",
    backgroundColor: theme.palette.secondary.light,
  },

  [`& .${classes.chartToolTipText}`]: {
    color: theme.palette.background.default,
  },
}));

const otherColour = "#0093D6";

export interface SectorAllocationPieChartProps {
  height: number;
  sectorAllocations: SectorAllocation[];
  showLegend?: boolean;
}

export function SectorAllocationPieChart(props: SectorAllocationPieChartProps) {
  const theme = useTheme();
  let allocatedAmount = new BigNumber("0");
  const processedSectorAllocations: SectorAllocation[] = [];

  const getNewColor = useAssetColorPicker();

  const getColor = (key: string) => {
    if (key === "Other") return otherColour;
    return getNewColor(key);
  };

  // for every given sectorAllocation...
  for (const h of props.sectorAllocations) {
    // if adding in this sectorAllocation will result going over 100
    if (allocatedAmount.plus(h.percentage).isGreaterThan(new BigNumber(100))) {
      // then do no more processing
      break;
    }

    // otherwise add to amount allocated
    allocatedAmount = allocatedAmount.plus(h.percentage);

    // and add sectorAllocation to processed sectorAllocations
    processedSectorAllocations.push(h);
  }

  // determine unallocated amount
  const unallocatedAmount = new BigNumber(100).minus(allocatedAmount);
  if (unallocatedAmount) {
    processedSectorAllocations.push(
      new SectorAllocation({
        sector: "Other" as FinancialSector,
        percentage: unallocatedAmount,
      }),
    );
  }

  return (
    <Root
      sx={{
        width: "100%",
        display: "flex",
        flexDirection: {
          sm: "row",
          xs: "column",
        },
        alignItems: {
          sm: "unset",
          xs: "center",
        },
        gap: {
          sm: "unset",
          xs: theme.spacing(5),
        },
      }}
    >
      <PieChart height={props.height} width={props.height}>
        <Pie
          stroke="none"
          data={processedSectorAllocations}
          labelLine={false}
          // label={RenderCustomizedLabel as any} // intentionally left commented (see bottom of file)
          outerRadius={props.height / 2}
          dataKey={(dataObject) => {
            if (dataObject.payload instanceof SectorAllocation) {
              const sectorAllocation = dataObject.payload as SectorAllocation;
              return sectorAllocation.percentage.toNumber();
            }
            return 0;
          }}
        >
          {processedSectorAllocations.map((entry, idx) => (
            <Cell key={`cell-${idx}`} fill={getColor(entry.sector)} />
          ))}
        </Pie>
        <Tooltip
          content={({ active, payload }) => {
            if (active && payload && payload.length && payload[0].payload) {
              if (payload[0].payload.payload instanceof SectorAllocation) {
                const sectorAllocation = payload[0].payload
                  .payload as SectorAllocation;
                return (
                  <Paper className={classes.chartToolTipPaper}>
                    <Typography
                      variant="body2"
                      className={classes.chartToolTipText}
                      children={`${sectorAllocation.sector}: ${formatTextNum(
                        sectorAllocation.percentage,
                        { addDecimalPadding: true },
                      )}%`}
                    />
                  </Paper>
                );
              }
            }
            return null;
          }}
        />
      </PieChart>
      {props.showLegend && (
        <Box
          className={"meshScroll"}
          sx={{
            width: "100%",
            display: "flex",
            flexDirection: "column",
            height: {
              sm: "224px",
              xs: "160px",
            },
            overflowY: "auto",
            overflowX: "hidden",
            marginLeft: {
              sm: theme.spacing(4),
              xs: "unset",
            },
            alignSelf: {
              sm: "center",
              xs: "flex-start",
            },
            justifySelf: {
              sm: "center",
              xs: "flex-start",
            },
          }}
        >
          {processedSectorAllocations.map((v, i) => (
            <div key={i} className={classes.chartLegendLineItem}>
              <div
                style={{
                  backgroundColor: getColor(v.sector),
                }}
                className={classes.legendColorIndicator}
              />
              <Typography variant="body1">
                {v.percentage.toString()}%
              </Typography>
              <Typography color="textSecondary" variant="body1">
                {v.sector}
              </Typography>
            </div>
          ))}
        </Box>
      )}
    </Root>
  );
}

// The below is left intentionally commented out
// since it is likely this functionality will come back in some form.
// const RADIAN = Math.PI / 180;
//
// const truncate = (input: string) => input.length > 3 ? `${input.substring(0, 3)}...` : input;
//
// const RenderCustomizedLabel: (props: any) => ReactElement<SVGElement> = (
//   {
//     cx,
//     cy,
//     midAngle,
//     innerRadius,
//     outerRadius,
//     payload: dataObject
//   }: any) => {
//   const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
//   const x = cx + radius * Math.cos(-midAngle * RADIAN);
//   const y = cy + radius * Math.sin(-midAngle * RADIAN);
//
//   if (dataObject.payload instanceof SectorAllocation) {
//     const sectorAllocation = dataObject.payload as SectorAllocation;
//     return (
//       <text x={x} y={y} fill={'white'} textAnchor={x > cx ? 'start' : 'end'} dominantBaseline={'central'}>
//         {`${truncate(sectorAllocation.name)} ${formatTextNum(sectorAllocation.percentage)}%`}
//       </text>
//     );
//   }
//
//   return (
//     <text x={x} y={y} fill={'white'} textAnchor={x > cx ? 'start' : 'end'} dominantBaseline={'central'}>
//       {'?'}
//     </text>
//   );
// };

/**
 * @fileoverview gRPC-Web generated client stub for financial
 * @enhanceable
 * @public
 */

// Code generated by protoc-gen-grpc-web. DO NOT EDIT.
// versions:
// 	protoc-gen-grpc-web v1.5.0
// 	protoc              v5.28.0
// source: financial/rateSourceReaderUNSCOPED_meshproto.proto


/* eslint-disable */
// @ts-nocheck



const grpc = {};
grpc.web = require('grpc-web');


var security_context_pb = require('../security/context_pb.js')

var search_criterion_pb = require('../search/criterion_pb.js')

var search_query_pb = require('../search/query_pb.js')

var search_sorting_pb = require('../search/sorting_pb.js')

var financial_rateSource_pb = require('../financial/rateSource_pb.js')

var i8n_timezone_pb = require('../i8n/timezone_pb.js')

var financial_rateSourceReader_meshproto_pb = require('../financial/rateSourceReader_meshproto_pb.js')
const proto = {};
proto.financial = require('./rateSourceReaderUNSCOPED_meshproto_pb.js');

/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?grpc.web.ClientOptions} options
 * @constructor
 * @struct
 * @final
 */
proto.financial.RateSourceReaderUNSCOPEDClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options.format = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname.replace(/\/+$/, '');

};


/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?grpc.web.ClientOptions} options
 * @constructor
 * @struct
 * @final
 */
proto.financial.RateSourceReaderUNSCOPEDPromiseClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options.format = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname.replace(/\/+$/, '');

};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.financial.ReadOneRateSourceRequest,
 *   !proto.financial.ReadOneRateSourceResponse>}
 */
const methodDescriptor_RateSourceReaderUNSCOPED_ReadOneRateSourceUNSCOPED = new grpc.web.MethodDescriptor(
  '/financial.RateSourceReaderUNSCOPED/ReadOneRateSourceUNSCOPED',
  grpc.web.MethodType.UNARY,
  financial_rateSourceReader_meshproto_pb.ReadOneRateSourceRequest,
  financial_rateSourceReader_meshproto_pb.ReadOneRateSourceResponse,
  /**
   * @param {!proto.financial.ReadOneRateSourceRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  financial_rateSourceReader_meshproto_pb.ReadOneRateSourceResponse.deserializeBinary
);


/**
 * @param {!proto.financial.ReadOneRateSourceRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.financial.ReadOneRateSourceResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.financial.ReadOneRateSourceResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.financial.RateSourceReaderUNSCOPEDClient.prototype.readOneRateSourceUNSCOPED =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/financial.RateSourceReaderUNSCOPED/ReadOneRateSourceUNSCOPED',
      request,
      metadata || {},
      methodDescriptor_RateSourceReaderUNSCOPED_ReadOneRateSourceUNSCOPED,
      callback);
};


/**
 * @param {!proto.financial.ReadOneRateSourceRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.financial.ReadOneRateSourceResponse>}
 *     Promise that resolves to the response
 */
proto.financial.RateSourceReaderUNSCOPEDPromiseClient.prototype.readOneRateSourceUNSCOPED =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/financial.RateSourceReaderUNSCOPED/ReadOneRateSourceUNSCOPED',
      request,
      metadata || {},
      methodDescriptor_RateSourceReaderUNSCOPED_ReadOneRateSourceUNSCOPED);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.financial.ReadManyRateSourceRequest,
 *   !proto.financial.ReadManyRateSourceResponse>}
 */
const methodDescriptor_RateSourceReaderUNSCOPED_ReadManyRateSourceUNSCOPED = new grpc.web.MethodDescriptor(
  '/financial.RateSourceReaderUNSCOPED/ReadManyRateSourceUNSCOPED',
  grpc.web.MethodType.UNARY,
  financial_rateSourceReader_meshproto_pb.ReadManyRateSourceRequest,
  financial_rateSourceReader_meshproto_pb.ReadManyRateSourceResponse,
  /**
   * @param {!proto.financial.ReadManyRateSourceRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  financial_rateSourceReader_meshproto_pb.ReadManyRateSourceResponse.deserializeBinary
);


/**
 * @param {!proto.financial.ReadManyRateSourceRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.financial.ReadManyRateSourceResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.financial.ReadManyRateSourceResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.financial.RateSourceReaderUNSCOPEDClient.prototype.readManyRateSourceUNSCOPED =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/financial.RateSourceReaderUNSCOPED/ReadManyRateSourceUNSCOPED',
      request,
      metadata || {},
      methodDescriptor_RateSourceReaderUNSCOPED_ReadManyRateSourceUNSCOPED,
      callback);
};


/**
 * @param {!proto.financial.ReadManyRateSourceRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.financial.ReadManyRateSourceResponse>}
 *     Promise that resolves to the response
 */
proto.financial.RateSourceReaderUNSCOPEDPromiseClient.prototype.readManyRateSourceUNSCOPED =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/financial.RateSourceReaderUNSCOPED/ReadManyRateSourceUNSCOPED',
      request,
      metadata || {},
      methodDescriptor_RateSourceReaderUNSCOPED_ReadManyRateSourceUNSCOPED);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.financial.ReadAllRateSourceRequest,
 *   !proto.financial.ReadAllRateSourceResponse>}
 */
const methodDescriptor_RateSourceReaderUNSCOPED_ReadAllRateSourceUNSCOPED = new grpc.web.MethodDescriptor(
  '/financial.RateSourceReaderUNSCOPED/ReadAllRateSourceUNSCOPED',
  grpc.web.MethodType.UNARY,
  financial_rateSourceReader_meshproto_pb.ReadAllRateSourceRequest,
  financial_rateSourceReader_meshproto_pb.ReadAllRateSourceResponse,
  /**
   * @param {!proto.financial.ReadAllRateSourceRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  financial_rateSourceReader_meshproto_pb.ReadAllRateSourceResponse.deserializeBinary
);


/**
 * @param {!proto.financial.ReadAllRateSourceRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.financial.ReadAllRateSourceResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.financial.ReadAllRateSourceResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.financial.RateSourceReaderUNSCOPEDClient.prototype.readAllRateSourceUNSCOPED =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/financial.RateSourceReaderUNSCOPED/ReadAllRateSourceUNSCOPED',
      request,
      metadata || {},
      methodDescriptor_RateSourceReaderUNSCOPED_ReadAllRateSourceUNSCOPED,
      callback);
};


/**
 * @param {!proto.financial.ReadAllRateSourceRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.financial.ReadAllRateSourceResponse>}
 *     Promise that resolves to the response
 */
proto.financial.RateSourceReaderUNSCOPEDPromiseClient.prototype.readAllRateSourceUNSCOPED =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/financial.RateSourceReaderUNSCOPED/ReadAllRateSourceUNSCOPED',
      request,
      metadata || {},
      methodDescriptor_RateSourceReaderUNSCOPED_ReadAllRateSourceUNSCOPED);
};


module.exports = proto.financial;


"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.allFutureNetworks = void 0;
exports.futureNetworkToString = futureNetworkToString;
exports.stringToFutureNetwork = stringToFutureNetwork;
exports.getNetworkNoDecimalPlaces = getNetworkNoDecimalPlaces;
exports.futureNetworkToBEString = futureNetworkToBEString;
exports.futureNetworkToStellarNetwork = futureNetworkToStellarNetwork;
exports.futureNetworkFromStellarNetwork = futureNetworkFromStellarNetwork;
const network_pb_1 = require("../stellar/network_pb");
const futureNetwork_pb_1 = require("./futureNetwork_pb");
// Get all futureNetworks as enum values
exports.allFutureNetworks = Object.values(futureNetwork_pb_1.FutureNetwork).filter((value) => typeof value === "number");
// Define explicit mappings between FutureNetwork enums and custom string representations
const networkToStringMapping = {
    [futureNetwork_pb_1.FutureNetwork.UNDEFINED_NETWORK]: "-",
    [futureNetwork_pb_1.FutureNetwork.MESH_X_AGGREGATION_NETWORK]: "Mesh-X",
    [futureNetwork_pb_1.FutureNetwork.STELLAR_TEST_SDF_NETWORK]: "Stellar Test SDF",
    [futureNetwork_pb_1.FutureNetwork.STELLAR_PUBLIC_NETWORK]: "Stellar Public",
    [futureNetwork_pb_1.FutureNetwork.BITCOIN_TEST_NETWORK]: "Bitcoin Test",
    [futureNetwork_pb_1.FutureNetwork.BITCOIN_PUBLIC_NETWORK]: "Bitcoin Public",
    [futureNetwork_pb_1.FutureNetwork.LITECOIN_TEST_NETWORK]: "Litecoin Test",
    [futureNetwork_pb_1.FutureNetwork.LITECOIN_PUBLIC_NETWORK]: "Litecoin Public",
    [futureNetwork_pb_1.FutureNetwork.ETHEREUM_TEST_NETWORK]: "Ethereum Test",
    [futureNetwork_pb_1.FutureNetwork.ETHEREUM_PUBLIC_NETWORK]: "Ethereum PUblic",
    [futureNetwork_pb_1.FutureNetwork.RIPPLE_TEST_NETWORK]: "Ripple Test",
    [futureNetwork_pb_1.FutureNetwork.RIPPLE_PUBLIC_NETWORK]: "Ripple Public",
    [futureNetwork_pb_1.FutureNetwork.CTSE_TEST_NETWORK]: "CTSE Test",
    [futureNetwork_pb_1.FutureNetwork.CTSE_PRODUCTION_NETWORK]: "CTSE Production",
    [futureNetwork_pb_1.FutureNetwork.SA_STOCK_BROKERS_TEST_NETWORK]: "SA Stock Brokers Test",
    [futureNetwork_pb_1.FutureNetwork.SA_STOCK_BROKERS_PRODUCTION_NETWORK]: "SA Stock Brokers",
    [futureNetwork_pb_1.FutureNetwork.NULL_NETWORK]: "NULL",
};
// Reverse mapping from string to FutureNetwork enum
const stringToFutureNetworkMapping = {};
for (const [key, value] of Object.entries(networkToStringMapping)) {
    stringToFutureNetworkMapping[value] = Number(key);
}
class UnsupportedFutureNetworkError extends Error {
    constructor(futureNetwork) {
        const message = `Unsupported FutureNetwork: ${futureNetwork}`;
        super(message);
        this.futureNetwork = futureNetwork;
    }
}
/**
 * Converts a FutureNetwork enum instance to a custom string representation.
 * @param {FutureNetwork} futureNetwork - The futureNetwork to convert.
 * @returns {string} The custom string representation of the futureNetwork.
 */
function futureNetworkToString(futureNetwork) {
    if (futureNetwork in networkToStringMapping) {
        return networkToStringMapping[futureNetwork];
    }
    else {
        throw new UnsupportedFutureNetworkError(futureNetwork);
    }
}
class UnsupportedFutureNetworkStringError extends Error {
    constructor(futureNetworkStr) {
        const message = `Unsupported futureNetwork string: ${futureNetworkStr}`;
        super(message);
        this.futureNetworkStr = futureNetworkStr;
    }
}
/**
 * Converts a custom string representation to a FutureNetwork enum instance.
 * @param {string} futureNetworkStr - The custom string representation of the futureNetwork.
 * @returns {FutureNetwork} The corresponding FutureNetwork enum instance.
 */
function stringToFutureNetwork(futureNetworkStr) {
    if (futureNetworkStr in stringToFutureNetworkMapping) {
        return stringToFutureNetworkMapping[futureNetworkStr];
    }
    else {
        throw new UnsupportedFutureNetworkStringError(futureNetworkStr);
    }
}
// Define a mapping from Network to the number of decimal places
const networkDecimalPlaces = {
    [futureNetwork_pb_1.FutureNetwork.UNDEFINED_NETWORK]: 2,
    [futureNetwork_pb_1.FutureNetwork.MESH_X_AGGREGATION_NETWORK]: 7,
    [futureNetwork_pb_1.FutureNetwork.STELLAR_TEST_SDF_NETWORK]: 7,
    [futureNetwork_pb_1.FutureNetwork.STELLAR_PUBLIC_NETWORK]: 7,
    [futureNetwork_pb_1.FutureNetwork.BITCOIN_TEST_NETWORK]: 8,
    [futureNetwork_pb_1.FutureNetwork.BITCOIN_PUBLIC_NETWORK]: 8,
    [futureNetwork_pb_1.FutureNetwork.LITECOIN_TEST_NETWORK]: 7,
    [futureNetwork_pb_1.FutureNetwork.LITECOIN_PUBLIC_NETWORK]: 7,
    [futureNetwork_pb_1.FutureNetwork.ETHEREUM_TEST_NETWORK]: 7,
    [futureNetwork_pb_1.FutureNetwork.ETHEREUM_PUBLIC_NETWORK]: 7,
    [futureNetwork_pb_1.FutureNetwork.RIPPLE_TEST_NETWORK]: 7,
    [futureNetwork_pb_1.FutureNetwork.RIPPLE_PUBLIC_NETWORK]: 7,
    [futureNetwork_pb_1.FutureNetwork.CTSE_TEST_NETWORK]: 2,
    [futureNetwork_pb_1.FutureNetwork.CTSE_PRODUCTION_NETWORK]: 2,
    [futureNetwork_pb_1.FutureNetwork.SA_STOCK_BROKERS_TEST_NETWORK]: 2,
    [futureNetwork_pb_1.FutureNetwork.SA_STOCK_BROKERS_PRODUCTION_NETWORK]: 2,
    [futureNetwork_pb_1.FutureNetwork.NULL_NETWORK]: 2,
};
/**
 * Creates a new Amount object using a BigNumber and a Token.
 *
 * @param {FutureNetwork} network - The network for which to get decimal places.
 * @returns {number} Returns the number of decimal places supported on network.
 */
function getNetworkNoDecimalPlaces(network) {
    if (network in networkDecimalPlaces) {
        return networkDecimalPlaces[network];
    }
    else {
        throw new UnsupportedFutureNetworkError(network);
    }
}
// Define explicit mappings between FutureNetwork enums backend string representations
const networkToBEStringMapping = {
    [futureNetwork_pb_1.FutureNetwork.UNDEFINED_NETWORK]: "UNDEFINED_NETWORK",
    [futureNetwork_pb_1.FutureNetwork.MESH_X_AGGREGATION_NETWORK]: "MESH_X_AGGREGATION_NETWORK",
    [futureNetwork_pb_1.FutureNetwork.STELLAR_TEST_SDF_NETWORK]: "STELLAR_TEST_SDF_NETWORK",
    [futureNetwork_pb_1.FutureNetwork.STELLAR_PUBLIC_NETWORK]: "STELLAR_PUBLIC_NETWORK",
    [futureNetwork_pb_1.FutureNetwork.BITCOIN_TEST_NETWORK]: "BITCOIN_TEST_NETWORK",
    [futureNetwork_pb_1.FutureNetwork.BITCOIN_PUBLIC_NETWORK]: "BITCOIN_PUBLIC_NETWORK",
    [futureNetwork_pb_1.FutureNetwork.LITECOIN_TEST_NETWORK]: "LITECOIN_TEST_NETWORK",
    [futureNetwork_pb_1.FutureNetwork.LITECOIN_PUBLIC_NETWORK]: "LITECOIN_PUBLIC_NETWORK",
    [futureNetwork_pb_1.FutureNetwork.ETHEREUM_TEST_NETWORK]: "ETHEREUM_TEST_NETWORK",
    [futureNetwork_pb_1.FutureNetwork.ETHEREUM_PUBLIC_NETWORK]: "ETHEREUM_PUBLIC_NETWORK",
    [futureNetwork_pb_1.FutureNetwork.RIPPLE_TEST_NETWORK]: "RIPPLE_TEST_NETWORK",
    [futureNetwork_pb_1.FutureNetwork.RIPPLE_PUBLIC_NETWORK]: "RIPPLE_PUBLIC_NETWORK",
    [futureNetwork_pb_1.FutureNetwork.CTSE_TEST_NETWORK]: "CTSE_TEST_NETWORK",
    [futureNetwork_pb_1.FutureNetwork.CTSE_PRODUCTION_NETWORK]: "CTSE_PRODUCTION_NETWORK",
    [futureNetwork_pb_1.FutureNetwork.SA_STOCK_BROKERS_TEST_NETWORK]: "SA_STOCK_BROKERS_TEST_NETWORK",
    [futureNetwork_pb_1.FutureNetwork.SA_STOCK_BROKERS_PRODUCTION_NETWORK]: "SA_STOCK_BROKERS_PRODUCTION_NETWORK",
    [futureNetwork_pb_1.FutureNetwork.NULL_NETWORK]: "NULL_NETWORK",
};
/**
 * Converts a FutureNetwork enum instance to a BE string representation.
 * @param {FutureNetwork} futureNetwork - The futureNetwork to convert.
 * @returns {string} The custom string representation of the futureNetwork.
 */
function futureNetworkToBEString(futureNetwork) {
    if (futureNetwork in networkToBEStringMapping) {
        return networkToBEStringMapping[futureNetwork];
    }
    else {
        throw new UnsupportedFutureNetworkError(futureNetwork);
    }
}
/**
 * Converts a FutureNetwork enum instance stellar Network enum
 * @param {FutureNetwork} network - The futureNetwork to convert.
 * @returns {Network} The associated stellar Network.
 */
function futureNetworkToStellarNetwork(network) {
    switch (network) {
        case futureNetwork_pb_1.FutureNetwork.UNDEFINED_NETWORK:
            return network_pb_1.Network.UNDEFINED_NETWORK;
        case futureNetwork_pb_1.FutureNetwork.STELLAR_TEST_SDF_NETWORK:
            return network_pb_1.Network.STELLAR_TEST_SDF_NETWORK;
        case futureNetwork_pb_1.FutureNetwork.STELLAR_PUBLIC_NETWORK:
            return network_pb_1.Network.STELLAR_PUBLIC_NETWORK;
        default:
            throw new TypeError(`cannot convert future network '${network}' to stellar network`);
    }
}
/**
 * Converts a stellar Network enum to a FutureNetwork enum.
 * @param {Network} network - The stellar Network to convert.
 * @returns {FutureNetwork} The associated Future Network.
 */
function futureNetworkFromStellarNetwork(network) {
    switch (network) {
        case network_pb_1.Network.UNDEFINED_NETWORK:
            return futureNetwork_pb_1.FutureNetwork.UNDEFINED_NETWORK;
        case network_pb_1.Network.STELLAR_TEST_SDF_NETWORK:
            return futureNetwork_pb_1.FutureNetwork.STELLAR_TEST_SDF_NETWORK;
        case network_pb_1.Network.STELLAR_PUBLIC_NETWORK:
            return futureNetwork_pb_1.FutureNetwork.STELLAR_PUBLIC_NETWORK;
        default:
            throw new TypeError(`cannot convert future network '${network}' to stellar network`);
    }
}

import React from "react";
import { Box, Chip, useTheme } from "@mui/material";
import { AssetHolderLookupState } from "@mesh/common-js/dist/financial/assetHolderLookup_pb";
import { assetHolderLookupStateToString } from "@mesh/common-js/dist/financial/assetHolderLookup";

export type AssetHolderLookupStateChipProps = {
  state: AssetHolderLookupState;
};

export function AssetHolderLookupStateChip(
  props: AssetHolderLookupStateChipProps,
) {
  const theme = useTheme();
  const chipStyles = {
    chip: {
      fontWeight: 400,
    },
    warning: { backgroundColor: theme.palette.warning.main },
    error: { backgroundColor: theme.palette.error.main },
    success: { backgroundColor: theme.palette.success.main },
    secondary: { backgroundColor: theme.palette.info.main },
  };

  switch (props.state) {
    case AssetHolderLookupState.PENING_ASSET_HOLDER_LOOKUP_STATE:
      return (
        <Chip
          sx={{
            ...chipStyles.chip,
            ...chipStyles.secondary,
          }}
          size="small"
          label={assetHolderLookupStateToString(props.state)}
        />
      );

    case AssetHolderLookupState.IN_PROGRESS_ASSET_HOLDER_LOOKUP_STATE:
      return (
        <Chip
          sx={{
            ...chipStyles.chip,
            ...chipStyles.secondary,
          }}
          size="small"
          label={assetHolderLookupStateToString(props.state)}
        />
      );

    case AssetHolderLookupState.COMPLETE_ASSET_HOLDER_LOOKUP_STATE:
      return (
        <Chip
          sx={{
            ...chipStyles.chip,
            ...chipStyles.success,
          }}
          size="small"
          label={assetHolderLookupStateToString(props.state)}
        />
      );

    default:
      return <Box>{props.state}</Box>;
  }
}

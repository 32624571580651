import { ValidationResult } from "common/validation";
import { Amount } from "james/ledger";
import { FormData } from "./SubsciptionTicketDialog";
import { MarketListingViewModel } from "james/views/marketListingView";
import { Model as StellarAccountViewModel } from "@mesh/common-js/dist/views/stellarAccountView/model_pb";
import { formatTextNum } from "utilities/number";
import { QuoteParameter } from "james/market";
import {
  getAvailableBalance,
  getTokenBalance,
} from "@mesh/common-js/dist/views/stellarAccountView";

export type FormDataUpdaterSpecsType = {
  investmentAmount: (amount: Amount, prevRequest?: FormData) => FormData;
  subscriptionQuantity: (amount: Amount, prevRequest?: FormData) => FormData;
  accountBalance: (amount: Amount, prevRequest?: FormData) => FormData;
  model: (model: MarketListingViewModel, prevRequest?: FormData) => FormData;
  selectedSourceAccViewModel: (
    selectedSourceAccViewModel: StellarAccountViewModel,
    prevRequest?: FormData,
  ) => FormData;
  quoteParameter: (
    quoteParameter: QuoteParameter,
    prevRequest?: FormData,
  ) => FormData;
  unit: (unit: string, prevRequest?: FormData) => FormData;
};

export const formDataUpdaterSpecs: FormDataUpdaterSpecsType = {
  selectedSourceAccViewModel(
    selectedSourceAccountViewModel: StellarAccountViewModel,
    prevRequest?: FormData,
  ): FormData {
    prevRequest = prevRequest as FormData;

    let availablePayAssetBalance = prevRequest.availablePayAssetBalance;
    let signatoryOnSourceAccount = prevRequest.signatoryOnSourceAccount;
    if (selectedSourceAccountViewModel) {
      // get updated available pay asset balance
      const updatedAvailablePayAssetBalance = getTokenBalance(
        selectedSourceAccountViewModel,
        availablePayAssetBalance.token.toFutureToken(),
      );
      availablePayAssetBalance = updatedAvailablePayAssetBalance
        ? Amount.fromFutureAmount(
            getAvailableBalance(updatedAvailablePayAssetBalance),
          )
        : availablePayAssetBalance.setValue("0");

      // get updated signatory status
      signatoryOnSourceAccount = false;
      for (const k of prevRequest.userKeys) {
        if (
          selectedSourceAccountViewModel
            .getSignatoriesList()
            .find((s) => s.getKey() === k.publicKey)
        ) {
          signatoryOnSourceAccount = true;
          break;
        }
      }
    }

    return {
      ...prevRequest,
      selectedSourceAccountViewModel,
      availablePayAssetBalance,
      signatoryOnSourceAccount,
    };
  },
  investmentAmount(amount: Amount, prevRequest?: FormData): FormData {
    prevRequest = prevRequest as FormData;
    return {
      ...prevRequest,
      investmentAmount: amount,
    };
  },
  subscriptionQuantity(quantity: Amount, prevRequest?: FormData): FormData {
    prevRequest = prevRequest as FormData;
    return {
      ...prevRequest,
      subscriptionQuantity: quantity,
    };
  },
  accountBalance(amount: Amount, prevRequest?: FormData): FormData {
    prevRequest = prevRequest as FormData;
    return {
      ...prevRequest,
      accountBalance: amount,
    };
  },
  model: (model: MarketListingViewModel, prevRequest?: FormData): FormData => {
    prevRequest = prevRequest as FormData;
    return {
      ...prevRequest,
      model: model,
    };
  },
  quoteParameter: (
    quoteParameter: QuoteParameter,
    prevRequest?: FormData,
  ): FormData => {
    prevRequest = prevRequest as FormData;
    return {
      ...prevRequest,
      quoteParameter: quoteParameter,
    };
  },
  unit: (unit: string, prevRequest?: FormData): FormData => {
    prevRequest = prevRequest as FormData;
    return {
      ...prevRequest,
      unit: unit,
    };
  },
};

export const formDataValidationFunc = async (
  formData: FormData,
): Promise<ValidationResult> => {
  // prepare validation result
  const validationResult: ValidationResult = {
    // assumed to true -
    // any error must set to false regardless of field touched state
    valid: true,
    // contains field validations
    fieldValidations: {},
  };

  if (formData.quoteParameter) {
    // confirm that base amount is within deal size as set on quote parameter
    if (
      formData.subscriptionQuantity.value.isLessThan(
        formData.quoteParameter.minimumDealSize.value,
      )
    ) {
      validationResult.valid = false;
      validationResult.fieldValidations.subscriptionQuantity = `Minimum Order: ${formatTextNum(
        formData.quoteParameter.minimumDealSize.value,
        {
          noDecimalPlaces: 7,
          addDecimalPadding: false,
        },
      )} ${formData.unit}${formData.quoteParameter.minimumDealSize.value.gt(1) ? "s" : ""}`;
    } else if (
      formData.subscriptionQuantity.value.isGreaterThan(
        formData.quoteParameter.maximumDealSize.value,
      )
    ) {
      validationResult.valid = false;
      validationResult.fieldValidations.subscriptionQuantity = `Maximum Allowed: ${formatTextNum(
        formData.quoteParameter.maximumDealSize.value,
        {
          noDecimalPlaces: 7,
          addDecimalPadding: false,
        },
      )} ${formData.unit}s`;
    }
  }

  // Oversubscription
  const book = formData.model.marketSubscriptionOrderBookViewModel;
  if (book) {
    if (
      book.overSubscriptionAmount.value.lt(
        book.subscribedAmount.value.plus(formData.investmentAmount.value),
      )
    ) {
      const remaining = book.overSubscriptionAmount.value
        .minus(book.subscribedAmount.value)
        .dividedBy(book.unitPrice.value);
      validationResult.valid = false;
      validationResult.fieldValidations.subscriptionQuantity = `${remaining.toFormat()} units remaining`;
    }
  }

  // check account balance
  if (formData.quoteParameter) {
    const quoteTokenBalance = getTokenBalance(
      formData.selectedSourceAccountViewModel,
      formData.quoteParameter.quoteToken.toFutureToken(),
    );

    if (
      formData.investmentAmount.value.gt(
        Amount.fromFutureAmount(getAvailableBalance(quoteTokenBalance)).value ??
          formData.availablePayAssetBalance.value,
      )
    ) {
      validationResult.valid = false;
      validationResult.fieldValidations.subscriptionQuantity = `Insufficient Balance: ${AmountToString(
        formData.accountBalance,
      )}`;
    }
  }

  // signatory check
  if (!formData.signatoryOnSourceAccount) {
    validationResult.valid = false;
    validationResult.fieldValidations.selectedSourceAccountViewModel =
      "You are not a signatory on this account";
  }

  if (formData.subscriptionQuantity.value.eq(0)) {
    validationResult.valid = false;
    validationResult.fieldValidations.subscriptionQuantity = `Must be greater than 0`;
  }

  return validationResult;
};

export const AmountToString = (amount: Amount): string => {
  return `${amount.token.code} ${amount.value.toFormat()}`;
};

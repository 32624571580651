import React, { Dispatch, SetStateAction } from "react";
import {
  alpha,
  Box,
  Button,
  ButtonGroup,
  Icon,
  Typography,
} from "@mui/material";
import {
  NorthEast as DeFundArrow,
  SouthEast as FundArrow,
} from "@mui/icons-material";
import { LedgerAccountCategory } from "james/ledger";
import Tooltip from "@mui/material/Tooltip";
import { useApplicationContext } from "context/Application/Application";
import { Model } from "@mesh/common-js/dist/views/stellarAccountView/model_pb";
import {
  DataLinkInfoType,
  InteractionAction,
  InteractionDriver,
  InteractionType,
} from "const/gtm";

interface FundDeFunCardProps {
  accountModel?: Model;
  setShowDeFundDialog: Dispatch<SetStateAction<boolean>>;
  setShowFundAccDialog: Dispatch<SetStateAction<boolean>>;
  showDeFundDialog: boolean;
  showFundAccDialog: boolean;
}

export function FundDeFundCard(props: FundDeFunCardProps) {
  const { viewConfiguration } = useApplicationContext();
  return (
    <Box
      sx={(theme) => ({
        [theme.breakpoints.down("sm")]: {
          height: theme.spacing(6),
          width: "100%",
          padding: theme.spacing(0, 2),
        },
        [theme.breakpoints.up("sm")]: {
          height: theme.spacing(4.5),
          width: !props.accountModel
            ?.getLabel()
            .includes(LedgerAccountCategory.MarketInfrastructure)
            ? theme.spacing(31.5)
            : "126px",
        },
      })}
    >
      {props.accountModel && (
        <ButtonGroup variant="outlined" sx={{ width: "100%", height: "100%" }}>
          {(props.accountModel
            .getLabel()
            .includes(LedgerAccountCategory.Trading) ||
            props.accountModel.getLabel().includes(LedgerAccountCategory.Fee) ||
            props.accountModel
              .getLabel()
              .includes(LedgerAccountCategory.Operational) ||
            props.accountModel
              .getLabel()
              .includes(LedgerAccountCategory.MarketInfrastructure)) && (
            <Button
              sx={(theme) => ({
                "&:hover": {
                  borderColor: theme.palette.common.white,
                  backgroundColor: alpha(theme.palette.text.primary, 0.08),
                },
                borderBottomRightRadius: props.accountModel
                  ?.getLabel()
                  .includes(LedgerAccountCategory.MarketInfrastructure)
                  ? "4px"
                  : "revert",
                borderTopRightRadius: props.accountModel
                  ?.getLabel()
                  .includes(LedgerAccountCategory.MarketInfrastructure)
                  ? "4px"
                  : "revert",
                border: 1,
              })}
              fullWidth
              onClick={() =>
                props.setShowFundAccDialog(!props.showFundAccDialog)
              }
              data-link-info={JSON.stringify({
                content_interaction_id: "fund-account",
                content_interaction_action: InteractionAction.Click,
                content_interaction_text: "fund",
                content_interaction_type: InteractionType.Button,
                content_interaction_driver: InteractionDriver.FundAccount,
              } as DataLinkInfoType)}
            >
              <Icon
                sx={(theme) => ({
                  color: theme.palette.text.primary,
                })}
              >
                <FundArrow fontSize={"small"} />
              </Icon>
              <Typography children="Fund" />
            </Button>
          )}
          {props.accountModel &&
            (props.accountModel
              .getLabel()
              .includes(LedgerAccountCategory.Trading) ||
              props.accountModel
                .getLabel()
                .includes(LedgerAccountCategory.Fee) ||
              props.accountModel
                .getLabel()
                .includes(LedgerAccountCategory.Operational)) && (
              <Tooltip
                title={
                  viewConfiguration.DefundAccount
                    ? ""
                    : "You do not have the required permissions"
                }
                placement="top"
              >
                <Button
                  id={"accountDetails-defund-button"}
                  onClick={() =>
                    props.setShowDeFundDialog(!props.showDeFundDialog)
                  }
                  sx={(theme) => ({
                    "&:hover": {
                      borderColor: theme.palette.common.white,
                      backgroundColor: alpha(theme.palette.text.primary, 0.08),
                    },
                    height: "100%",
                    borderBottomLeftRadius: "revert",
                    borderTopLeftRadius: "revert",
                    border: 1,
                  })}
                  fullWidth
                  disabled={!viewConfiguration.DefundAccount}
                  data-link-info={JSON.stringify({
                    content_interaction_id: "defund-account",
                    content_interaction_action: InteractionAction.Click,
                    content_interaction_text: "defund",
                    content_interaction_type: InteractionType.Button,
                    content_interaction_driver: InteractionDriver.DefundAccount,
                  } as DataLinkInfoType)}
                >
                  <Typography children="Defund" />
                  <Icon
                    sx={(theme) => ({
                      color: theme.palette.text.primary,
                    })}
                  >
                    <DeFundArrow fontSize={"small"} />
                  </Icon>
                </Button>
              </Tooltip>
            )}
        </ButtonGroup>
      )}
    </Box>
  );
}

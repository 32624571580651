import React, { useEffect, useRef, useState } from "react";
import { Header } from "components/Cards/MarketDirectOrderViewCard/Components/Header";
import { OrderDirectionAndState } from "components/Cards/MarketDirectOrderViewCard/Components/OrderDirectionAndState";
import { AvatarAndDirection } from "components/Cards/MarketDirectOrderViewCard/Components/AvatarAndDirection";
import {
  Box,
  Button,
  Tooltip,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { Amount as LedgerAmount } from "james/ledger";
import { DirectOrderState, DirectOrderType } from "james/market/DirectOrder";
import { Amount } from "components/Ledger/Amount";
import { Balance } from "@mesh/common-js/dist/views/stellarAccountView/model_pb";
import { useSnackbar } from "notistack";
import { TextNumField } from "components/FormFields";
import {
  AcceptDirectOrderIDRequest,
  DirectOrderStateController,
} from "james/market/DirectOrderStateController";
import {
  CalculateDirectOrderFeeResponse,
  useCalculateDirectOrderFee,
} from "james/remuneration/DirectOrderFeeCalculator";
import { useIsMounted } from "hooks";
import { TouchedFields, ValidationResult } from "common/validation";
import { Model as LedgerTokenViewModel } from "james/views/ledgerTokenView";
import { NumFieldHlpTxt } from "validationHelperText";
import { formatTextNum } from "utilities/number";
import {
  MarketDirectOrderViewModelChangedNotification,
  MarketDirectOrderViewModelChangedNotificationTypeName,
  MarketDirectOrderViewNotificationChannelName,
  Model as MarketDirectOrderViewModel,
} from "james/views/marketDirectOrderView";
import { MintAssetDialog } from "components/Dialogs/Minting";
import { WarningDialog } from "components/Dialogs/WarningDialog";
import { WarningDialogOptions } from "views/InstrumentBuilder/Instruments/common";
import { useNotificationContext } from "context/Notification";
import { GroupNotificationChannel } from "james/group";
import { Notification } from "james/notification/Notification";
import { QuoteParameter } from "james/market/Mechanism";
import { IssuerAwaitingConfirmationCardProps } from "./IssuerAwaitingConfirmationCard";
import { useApplicationContext } from "context/Application/Application";
import { FundAccountDialog } from "views/Accounts/components/FundAccountDialog/FundAccountDialog";
import {
  TransactionDetails,
  TransactionStage,
  TransactionTypes,
} from "types/gtm";
import dayjs from "dayjs";
import { useGTMTriggersPusher } from "hooks/analytics/useGTMTriggersPusher";
import { StyledCard, classes } from "../cardStyle";
import { useErrorContext } from "context/Error";
import { TokenCategory } from "james/views/ledgerTokenView/Model";
import { decimalToBigNumber } from "@mesh/common-js/dist/num";

const dontHaveEnoughTokensHelperText = "You don't have enough tokens";
const dontHaveEnoughFundsHelperText = "You don't have enough funds";

function performValidation(
  marketDirectOrderViewModel: MarketDirectOrderViewModel,
  request: AcceptDirectOrderIDRequest,
  feeCalculationResult: CalculateDirectOrderFeeResponse,
  assetValuationTokenBalance: Balance,
  assetIssuanceTokenBalance: Balance,
  assetIssuanceTokenViewModel: LedgerTokenViewModel,
  touchedFields: TouchedFields,
  ignoreTouchedFields: boolean,
  marketMechanismQuoteParameter: QuoteParameter,
  assetFractionalisationAllowed: boolean,
): ValidationResult {
  // prepare a validation result
  const validationResult: ValidationResult = {
    // assumed to be true -
    // any error must set to false regardless of touched field state
    valid: true,
    // field validations
    fieldValidations: {},
  };

  // if price is not set
  if (request.price.value.isZero()) {
    // then validation has failed
    validationResult.valid = false;

    // and if the field has been touched
    if (ignoreTouchedFields || touchedFields.price) {
      // then an error message should be shown on it
      validationResult.fieldValidations.price =
        NumFieldHlpTxt.MustBeGreaterThan0;
    }
  }

  if (
    // otherwise if resultant token amount is out of
    // the deal size bounds as set on the listing
    feeCalculationResult.tokens.gt(
      marketMechanismQuoteParameter.maximumDealSize.value,
    ) ||
    feeCalculationResult.tokens.lt(
      marketMechanismQuoteParameter.minimumDealSize.value,
    )
  ) {
    // then validation has failed
    validationResult.valid = false;

    // and if price field has been touched
    if (ignoreTouchedFields || touchedFields.price) {
      // then an error message should be shown on the tokens
      validationResult.fieldValidations.tokens = `Order size out of bounds. Min ${formatTextNum(
        marketMechanismQuoteParameter.minimumDealSize.value,
        {
          noDecimalPlaces: 7,
          addDecimalPadding: true,
        },
      )} Max ${formatTextNum(
        marketMechanismQuoteParameter.maximumDealSize.value,
        {
          noDecimalPlaces: 7,
          addDecimalPadding: true,
        },
      )} ${assetIssuanceTokenViewModel.token.code}`;
    }
  }

  if (
    marketDirectOrderViewModel.orderType === DirectOrderType.Buy &&
    // only do balance validation if token error message not already set
    !validationResult.fieldValidations.tokens
  ) {
    // if issuer is counterparty to a buy order
    // then confirm that they have sufficient balance to give the investor the
    // tokens that they have paid for
    if (
      decimalToBigNumber(assetIssuanceTokenBalance.getAmount()?.getValue()).lt(
        feeCalculationResult.tokens,
      )
    ) {
      // balance insufficient so validation has failed
      validationResult.valid = false;

      // and an error message should be shown
      validationResult.fieldValidations.tokens = dontHaveEnoughTokensHelperText;
    }
  } else if (marketDirectOrderViewModel.orderType === DirectOrderType.Sell) {
    // if the issuer is counterparty to a sell order
    // then confirm that they have sufficient balance to give the investor the
    // amountIncl (minus fee) that they are giving for tokens
    if (
      decimalToBigNumber(assetValuationTokenBalance.getAmount()?.getValue()).lt(
        feeCalculationResult.amountIncl,
      )
    ) {
      // balance insufficient so validation has failed
      validationResult.valid = false;

      // and an error message should be shown
      validationResult.fieldValidations.amountIncl =
        dontHaveEnoughFundsHelperText;
    }
  }

  // apply asset fractionalisation allowed check
  if (
    !(assetFractionalisationAllowed || feeCalculationResult.tokens.isInteger())
  ) {
    // if the check fails then validation has failed
    validationResult.valid = false;

    // and an error message should be shown
    validationResult.fieldValidations.tokens =
      "Token Amount must be a whole number";
  }

  return validationResult;
}

export function CounterpartyCard(props: IssuerAwaitingConfirmationCardProps) {
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up("lg"));
  const isMounted = useIsMounted();
  const { enqueueSnackbar } = useSnackbar();
  const { pushTransactionComplete, pushTransactionAbandon } =
    useGTMTriggersPusher();
  const { viewConfiguration, authContext } = useApplicationContext();
  const { errorContextErrorTranslator } = useErrorContext();
  const { registerNotificationCallback } = useNotificationContext();
  const ordersViewConfiguration = viewConfiguration.Marketplace
    ? viewConfiguration.Marketplace
      ? viewConfiguration.Marketplace.Orders
      : {}
    : {};
  const isBuy =
    props.marketDirectOrderViewModel.orderType === DirectOrderType.Buy;
  const [showMintDialog, setShowMintDialog] = useState(false);
  const [showFundAccDialog, setShowFundAccDialog] = useState(false);

  const [issuerAcceptDirectOrderRequest, setIssuerAcceptDirectOrderRequest] =
    useState<AcceptDirectOrderIDRequest>({
      context: authContext,
      directOrderID: props.marketDirectOrderViewModel.directOrderID,
      price: props.marketDirectOrderViewModel.price.setValue("0"),
    });

  useEffect(() => {
    setIssuerAcceptDirectOrderRequest({
      ...issuerAcceptDirectOrderRequest,
      directOrderID: props.marketDirectOrderViewModel.directOrderID,
    });
  }, [props.marketDirectOrderViewModel.directOrderID]);

  const handleUpdateRequest =
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (field: string, fieldsAffected?: string[]) => (newValue: any) => {
      // prepare updated request
      const updatedRequest = {
        ...issuerAcceptDirectOrderRequest,
        [field]: newValue,
      };

      // prepare updated touched fields
      const updatedTouchedFields = {
        ...touchedFields,
        [field]: true,
      };
      if (fieldsAffected) {
        fieldsAffected.forEach((f) => {
          updatedTouchedFields[f] = true;
        });
      }

      setTouchedFields(updatedTouchedFields);
      setIssuerAcceptDirectOrderRequest(updatedRequest);
    };

  // re-calculate order parameters each time the request changes
  const {
    setCalculateDirectOrderFeeRequest,
    calculateDirectOrderFeeResponse,
    loading: calculationInProgress,
  } = useCalculateDirectOrderFee(undefined);
  useEffect(() => {
    if (!isMounted()) {
      return;
    }
    setCalculateDirectOrderFeeRequest(
      issuerAcceptDirectOrderRequest.price.isUndefined() ||
        issuerAcceptDirectOrderRequest.price.value.isZero()
        ? undefined
        : {
            context: authContext,
            directOrderType: props.marketDirectOrderViewModel
              .orderType as DirectOrderType,
            price: issuerAcceptDirectOrderRequest.price.value,
            amountIncl:
              props.marketDirectOrderViewModel.orderType === DirectOrderType.Buy
                ? props.marketDirectOrderViewModel.amountIncl.value
                : undefined,
            tokens:
              props.marketDirectOrderViewModel.orderType ===
              DirectOrderType.Sell
                ? props.marketDirectOrderViewModel.tokens.value
                : undefined,
          },
    );
  }, [
    isMounted,
    setCalculateDirectOrderFeeRequest,
    props.marketDirectOrderViewModel,
    authContext,
    issuerAcceptDirectOrderRequest,
  ]);

  // form validation each time request changes
  const [touchedFields, setTouchedFields] = useState<TouchedFields>({});
  const [validationInProgress, setValidationInProgress] = useState(false);
  const [requestValidationResult, setRequestValidationResult] =
    useState<ValidationResult>({
      valid: false,
      fieldValidations: {},
    });
  const validationTimeoutRef = useRef<NodeJS.Timeout | undefined>(undefined);
  useEffect(() => {
    if (!isMounted()) {
      return;
    }

    // clear any pending validation
    clearTimeout(validationTimeoutRef.current);

    // defer validation to take place in 200ms
    setValidationInProgress(true);
    clearTimeout(validationTimeoutRef.current);
    validationTimeoutRef.current = setTimeout(() => {
      setRequestValidationResult(
        performValidation(
          props.marketDirectOrderViewModel,
          issuerAcceptDirectOrderRequest,
          calculateDirectOrderFeeResponse,
          props.assetValuationTokenBalance,
          props.assetIssuanceTokenBalance,
          props.assetIssuanceTokenViewModel,
          touchedFields,
          false,
          props.marketMechanismQuoteParameter,
          props.marketListingViewModel.assetFractionalisationAllowed,
        ),
      );
      setValidationInProgress(false);
    }, 200);
  }, [
    isMounted,
    props.marketDirectOrderViewModel,
    issuerAcceptDirectOrderRequest,
    props.listing,
    calculateDirectOrderFeeResponse,
    props.assetValuationTokenBalance,
    props.assetIssuanceTokenBalance,
    props.assetValuationTokenViewModel,
    props.assetIssuanceTokenViewModel,
    touchedFields,
  ]);

  const [declineInProgress, setDeclineInProgress] = useState(false);
  const [warningDialogOptions, setWarningDialogOptions] =
    useState<WarningDialogOptions | null>(null);
  const handleDecline = async () => {
    setDeclineInProgress(true);

    try {
      // Decline direct order
      await DirectOrderStateController.DeclineDirectOrderID({
        context: authContext,
        directOrderID: props.marketDirectOrderViewModel.directOrderID,
      });

      // close the card
      props.onActionComplete();

      // notify that decline is in progress
      enqueueSnackbar(
        `Order #${props.marketDirectOrderViewModel.number} is being declined`,
        { variant: "info" },
      );
    } catch (e) {
      console.error(
        `error declining direct order ${props.marketDirectOrderViewModel.directOrderID}`,
        e,
      );
      const err = errorContextErrorTranslator.translateError(e);
      enqueueSnackbar(
        `Error Declining Order #${props.marketDirectOrderViewModel.number}: ${err.message}`,
        { variant: "warning" },
      );
      setDeclineInProgress(false);
      return;
    }

    try {
      // register callback to fire once the order has reached declined
      const deregister = await registerNotificationCallback(
        new GroupNotificationChannel({
          groupID: props.marketDirectOrderViewModel.counterpartyGroupID,
          name: MarketDirectOrderViewNotificationChannelName,
          private: true,
        }),
        [MarketDirectOrderViewModelChangedNotificationTypeName],
        (n: Notification) => {
          if (
            n instanceof MarketDirectOrderViewModelChangedNotification &&
            n.model.directOrderID ===
              props.marketDirectOrderViewModel.directOrderID
          ) {
            // notify based on state
            switch (n.model.state) {
              case DirectOrderState.Declining:
              case DirectOrderState.ClearanceFailed:
              case DirectOrderState.Failing:
                // Do nothing during transient states
                // Return so that deregister is not called.
                return;

              case DirectOrderState.Declined:
                pushTransactionAbandon({
                  // use the Direct Order number to uniquely identify the transaction
                  transaction_id: n.model.number,
                  transaction_type: TransactionTypes.directOrder,
                  // use the asset short name as transaction_asset_name
                  transaction_asset_name: n.model.assetName,
                  // use the combination asset code:issuer:network to create an asset id
                  transaction_asset_id: `${n.model.tokens.token.code}:${n.model.tokens.token.issuer}:${n.model.tokens.token.network}`,
                  // use the price on model to set asset sell price
                  transaction_asset_sell_price:
                    n.model.orderType === DirectOrderType.Sell
                      ? n.model.price.value.toString()
                      : "0",
                  // use the price on model to set asset buy price
                  transaction_asset_buy_price:
                    n.model.orderType === DirectOrderType.Buy
                      ? n.model.price.value.toString()
                      : "0",
                  // set the asset type
                  transaction_asset_type:
                    props.assetIssuanceTokenViewModel.tokenCategory,
                  // set transaction asset issuer
                  transaction_asset_issuer:
                    props.assetIssuanceTokenViewModel.issuer,
                  // use the instrument risk profile to set the asset risk rating
                  transaction_asset_risk_rating: "-",
                  transaction_stage: TransactionStage.abandon,
                  // this is the date in which the transaction is initiated
                  transaction_date: dayjs().format(),
                  // set the transaction slippage to zero
                  transaction_slippage: "0",
                  // set transaction trade fee
                  transaction_trade_fee: n.model.feeAmount.value.toString(),
                  // set the transaction currency
                  transaction_currency: n.model.feeAmount.token.code.toString(),
                  // use the quote amount on the spot as the investment amount
                  transaction_investment_amount:
                    n.model.amountIncl.value.toString(),
                  // use asset listing instrument risk profile as investor profile
                  transaction_asset_investor_profile: "-",
                } as TransactionDetails);

                enqueueSnackbar(`Order #${n.model.number} declined`, {
                  variant: "success",
                });
                break;

              case DirectOrderState.Failed:
                enqueueSnackbar(`Order #${n.model.number} has failed`, {
                  variant: "error",
                });
                break;

              case DirectOrderState.UnderInvestigation:
                enqueueSnackbar(
                  `Something has gone wrong with Order #${n.model.number} - it's status is being investigated`,
                  { variant: "warning" },
                );
                break;
            }
            deregister();
          }
        },
      );
    } catch (e) {
      console.error("error registering for order notifications", e);
      enqueueSnackbar(
        "Warning! Unable to Register for Order Notifications - Please Refresh to Monitor.",
        { variant: "warning" },
      );
    }
  };

  const [acceptInProgress, setAcceptInProgress] = useState(false);
  const handleAccept = async () => {
    setAcceptInProgress(true);
    try {
      // accept direct order
      await DirectOrderStateController.AcceptDirectOrderID(
        issuerAcceptDirectOrderRequest,
      );

      // close the card
      props.onActionComplete();

      // notify that accept is in progress
      enqueueSnackbar(
        `Order #${props.marketDirectOrderViewModel.number} is being accepted`,
        { variant: "info" },
      );
    } catch (e) {
      console.error(
        `error accepting direct order ${props.marketDirectOrderViewModel.directOrderID}`,
        e,
      );
      const err = errorContextErrorTranslator.translateError(e);
      enqueueSnackbar(
        `Error Accepting Order #${props.marketDirectOrderViewModel.number}: ${err.message}`,
        { variant: "warning" },
      );
      setAcceptInProgress(false);
      return;
    }

    try {
      // register callback to fire once the order has reached settled
      const deregister = await registerNotificationCallback(
        new GroupNotificationChannel({
          groupID: props.marketDirectOrderViewModel.counterpartyGroupID,
          name: MarketDirectOrderViewNotificationChannelName,
          private: true,
        }),
        [MarketDirectOrderViewModelChangedNotificationTypeName],
        (n: Notification) => {
          if (
            n instanceof MarketDirectOrderViewModelChangedNotification &&
            n.model.directOrderID ===
              props.marketDirectOrderViewModel.directOrderID
          ) {
            // notify based on state
            switch (n.model.state) {
              case DirectOrderState.Accepted:
              case DirectOrderState.ClearanceFailed:
              case DirectOrderState.Failing:
                // Do nothing during transient states
                // Return so that deregister is not called.
                return;

              case DirectOrderState.Settled:
                pushTransactionComplete({
                  // use the Direct Order no to uniquely identify the transaction
                  transaction_id: n.model.number,
                  transaction_type: TransactionTypes.directOrder,
                  // use the asset short name as transaction_asset_name
                  transaction_asset_name: n.model.assetName,
                  // use the combination asset code:issuer:network to create an asset id
                  transaction_asset_id: `${n.model.tokens.token.code}:${n.model.tokens.token.issuer}:${n.model.tokens.token.network}`,
                  // use the price on model to set asset sell price
                  transaction_asset_sell_price:
                    n.model.orderType === DirectOrderType.Sell
                      ? n.model.price.value.toString()
                      : "0",
                  // use the price on model to set asset buy price
                  transaction_asset_buy_price:
                    n.model.orderType === DirectOrderType.Buy
                      ? n.model.price.value.toString()
                      : "0",
                  // set the type of transaction in the
                  transaction_asset_type:
                    props.assetIssuanceTokenViewModel.tokenCategory,
                  // set transaction asset issuer
                  transaction_asset_issuer:
                    props.assetIssuanceTokenViewModel.issuer,
                  // use the instrument risk profile to set the asset risk rating
                  transaction_asset_risk_rating: "-",
                  transaction_stage: TransactionStage.complete,
                  // this is the date in which the transaction is initiated
                  transaction_date: dayjs().format(),
                  // set the transaction slippage to zero
                  transaction_slippage: "0",
                  // set transaction trade fee
                  transaction_trade_fee: n.model.feeAmount.value.toString(),
                  // set the transaction currency
                  transaction_currency: n.model.feeAmount.token.code.toString(),
                  // use the quote amount on the spot as the investment amount
                  transaction_investment_amount:
                    n.model.amountIncl.value.toString(),
                  // use asset listing instrument risk profile as investor profile
                  transaction_asset_investor_profile: "-",
                } as TransactionDetails);

                enqueueSnackbar(`Order #${n.model.number} settled`, {
                  variant: "success",
                });
                break;

              case DirectOrderState.Failed:
                enqueueSnackbar(`Order #${n.model.number} has failed`, {
                  variant: "error",
                });
                break;

              case DirectOrderState.UnderInvestigation:
                enqueueSnackbar(
                  `Something has gone wrong with Order #${n.model.number} - it's status is being investigated`,
                  { variant: "warning" },
                );
                break;
            }
            deregister();
          }
        },
      );
    } catch (e) {
      console.error("error registering for order notifications", e);
      enqueueSnackbar(
        "Warning! Unable to Register for Order Notifications - Please Refresh to Monitor.",
        { variant: "warning" },
      );
    }
  };

  const actionAPIInProgress = declineInProgress || acceptInProgress;

  return (
    <StyledCard
      sx={{
        backgroundColor: theme.palette.custom.midnight,
        [theme.breakpoints.down("sm")]: {
          borderRadius: 0,
        },
      }}
    >
      {showFundAccDialog && (
        <FundAccountDialog
          accountID={props.tradingAccViewModel.getId()}
          open
          onClose={() => setShowFundAccDialog(false)}
        />
      )}
      <Header {...props} showLoading={actionAPIInProgress} />
      <Box
        sx={{
          backgroundColor: theme.palette.background.paper,
          padding: theme.spacing(3, 4),
          display: "flex",
          flexDirection: "column",
          gap: theme.spacing(2),
        }}
      >
        <OrderDirectionAndState
          viewingAsInitiatingParty={false}
          marketDirectOrderViewModel={props.marketDirectOrderViewModel}
        />
        <AvatarAndDirection
          name={props.marketDirectOrderViewModel.counterpartyClientName}
          pictureDLURL={
            props.marketDirectOrderViewModel.counterpartyProfilePictureDLURL
          }
          receives // Issuer receives...
        />
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: 1,
          }}
        >
          <Box
            sx={{
              display: "grid",
              gridTemplateColumns: "repeat(2, 1fr)",
            }}
          >
            <Typography
              color="textSecondary"
              variant="caption"
              children={isBuy ? "Amount" : "Tokens"}
            />
            <Typography
              color="textSecondary"
              variant="caption"
              children="Price per Token"
            />
          </Box>
          <Box
            sx={{
              display: "grid",
              gridTemplateColumns: "repeat(2, 1fr)",
            }}
          >
            <Amount
              id="issuerAwaitingConfirmationCard-counterpartyCard-receive-amount"
              reverse={!isBuy}
              codeTypographyProps={{
                variant: "h6",
                className: classes.ledgerAmountCode,
              }}
              valueTypographyProps={{
                variant: "h6",
                className: classes.ledgerAmountValue,
              }}
              formatTextNumOpts={{
                noDecimalPlaces: isBuy ? 2 : 7,
                addDecimalPadding: isBuy,
              }}
              amount={
                isBuy
                  ? // Buy: amountIncl set (and in clearance acc.) :- fee also set
                    props.marketDirectOrderViewModel.amountIncl.setValue(
                      // show amountIncl - fee
                      props.marketDirectOrderViewModel.amountIncl.value
                        .minus(props.marketDirectOrderViewModel.feeAmount.value)
                        .minus(
                          props.marketDirectOrderViewModel.feeAmount.value.multipliedBy(
                            props.marketDirectOrderViewModel.vatRate,
                          ),
                        ),
                    )
                  : // Sell: token amount set (and in clearance account)
                    props.marketDirectOrderViewModel.tokens
              }
            />
            <Amount
              id="issuerAwaitingConfirmationCard-counterpartyCard-estPrice-amount"
              codeTypographyProps={{
                variant: "h6",
                className: classes.ledgerAmountCode,
              }}
              valueTypographyProps={{
                variant: "h6",
                className: classes.ledgerAmountValue,
              }}
              formatTextNumOpts={{
                noDecimalPlaces: 2,
                addDecimalPadding: true,
              }}
              amount={props.marketDirectOrderViewModel.price}
            />
          </Box>
        </Box>
        <Box className={classes.sectionWith2EqualColumns}>
          <TextNumField
            id="issuerAwaitingConfirmationCard-counterpartyCard-price-textNumField"
            disallowNegative
            label="Price"
            noDecimalPlaces={2}
            disabled={actionAPIInProgress || !ordersViewConfiguration.Accept}
            value={issuerAcceptDirectOrderRequest.price.value}
            onChange={(e) =>
              handleUpdateRequest("price")(
                issuerAcceptDirectOrderRequest.price.setValue(e.target.value),
              )
            }
            InputProps={{
              startAdornment: (
                <Tooltip
                  title={`Issued by ${props.assetValuationTokenViewModel.issuer}`}
                  placement="top"
                >
                  <Typography
                    variant="body1"
                    className={classes.textNumFieldCode}
                    children={props.assetValuationTokenViewModel.token.code}
                  />
                </Tooltip>
              ),
            }}
            error={!!requestValidationResult.fieldValidations.price}
            helperText={requestValidationResult.fieldValidations.price}
          />
        </Box>
      </Box>
      <Box
        sx={{
          padding: theme.spacing(3, 4),
          display: "flex",
          flexDirection: "column",
          gap: theme.spacing(2),
        }}
      >
        <AvatarAndDirection
          name={props.marketDirectOrderViewModel.counterpartyClientName}
          pictureDLURL={
            props.marketDirectOrderViewModel.counterpartyProfilePictureDLURL
          }
          receives={false} // Issuer Pays...
        />
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: theme.spacing(1),
          }}
        >
          <Typography
            color="textSecondary"
            variant="caption"
            children={isBuy ? "Tokens" : "Amount"}
          />
          <Amount
            id="issuerAwaitingConfirmationCard-counterpartyCard-pay-amount"
            reverse={isBuy}
            codeTypographyProps={{
              variant: "h6",
              className: classes.ledgerAmountCode,
            }}
            valueTypographyProps={{
              variant: "h6",
              className: classes.ledgerAmountValue,
            }}
            formatTextNumOpts={{
              noDecimalPlaces: isBuy ? 7 : 2,
              addDecimalPadding: !isBuy,
            }}
            amount={
              isBuy
                ? // Buy: fee value entered will affect final tokens paid :- show calculated result
                  props.listing.token.newAmountOf(
                    calculateDirectOrderFeeResponse.tokens,
                  )
                : // Sell: fee value entered will affect final amountExcl. paid (excl. since fee not taken yet)
                  props.marketMechanismQuoteParameter.quoteToken.newAmountOf(
                    calculateDirectOrderFeeResponse.amountExcl,
                  )
            }
          />
          <Box className={classes.sectionWithColumns2Gap}>
            <Typography
              className={classes.disabledText}
              variant="caption"
              children="Available:"
            />
            <Amount
              reverse={isBuy}
              codeTypographyProps={{
                variant: "caption",
                color: "textSecondary",
              }}
              valueTypographyProps={{
                variant: "caption",
                color: "textSecondary",
              }}
              formatTextNumOpts={{
                noDecimalPlaces: isBuy ? 7 : 2,
                addDecimalPadding: !isBuy,
              }}
              amount={
                isBuy
                  ? LedgerAmount.fromFutureAmount(
                      props.assetIssuanceTokenBalance.getAmount(),
                    )
                  : LedgerAmount.fromFutureAmount(
                      props.assetValuationTokenBalance.getAmount(),
                    )
              }
            />
          </Box>
          {!!requestValidationResult.fieldValidations.tokens &&
            !(validationInProgress || calculationInProgress) &&
            props.assetIssuanceTokenViewModel.tokenCategory !==
              TokenCategory.DigitalInstrument &&
            (requestValidationResult.fieldValidations.tokens ===
            dontHaveEnoughTokensHelperText ? (
              <Box className={classes.sectionWithColumns2Gap}>
                <Typography
                  variant="body2"
                  color="error"
                  children={requestValidationResult.fieldValidations.tokens}
                />
                <Typography
                  id="issuerAwaitingConfirmationCard-counterpartyCard-mint-link"
                  className={classes.linkText}
                  variant="body2"
                  onClick={() => setShowMintDialog(true)}
                  children="Mint"
                />
              </Box>
            ) : (
              !requestValidationResult.fieldValidations.tokens.includes(
                "Order size out",
              ) && (
                <Typography
                  variant="body2"
                  color="error"
                  children={requestValidationResult.fieldValidations.tokens}
                />
              )
            ))}
          {!!requestValidationResult.fieldValidations.amountIncl &&
            !(validationInProgress || calculationInProgress) &&
            (requestValidationResult.fieldValidations.amountIncl ===
              dontHaveEnoughTokensHelperText ||
            requestValidationResult.fieldValidations.amountIncl ===
              dontHaveEnoughFundsHelperText ? (
              <Box className={classes.sectionWithColumns2Gap}>
                <Typography
                  id="issuerAwaitingConfirmationCard-counterpartyCard-tokens-amountError"
                  variant="body2"
                  color="error"
                  children={requestValidationResult.fieldValidations.amountIncl}
                />
                <Typography
                  id="issuerAwaitingConfirmationCard-counterpartyCard-fund-link"
                  className={classes.linkText}
                  variant="body2"
                  children="Fund now"
                  onClick={() => setShowFundAccDialog(true)}
                />
              </Box>
            ) : (
              <Typography
                id="issuerAwaitingConfirmationCard-counterpartyCard-tokensAmountError"
                variant="body2"
                color="error"
                children={requestValidationResult.fieldValidations.amountIncl}
              />
            ))}
        </Box>
        {ordersViewConfiguration.Accept && ordersViewConfiguration.Decline && (
          <Box
            sx={{
              mt: 3,
              position: "sticky",
              bottom: 0,
              display: "flex",
              [theme.breakpoints.down("sm")]: {
                width: "100%",
                flexDirection: "column",
              },
              [theme.breakpoints.up("lg")]: {
                display: "grid",
                gridTemplateColumns: "repeat(2, auto)",
                gridGap: theme.spacing(2),
              },
            }}
          >
            <Tooltip
              placement="top"
              title={(() => {
                switch (true) {
                  case calculationInProgress:
                    return "Calculation in Progress";
                  case !props.userIsSignatoryOnTradingAcc:
                    return "You are not a Signatory on the Trading Account";
                }
                return "";
              })()}
            >
              <span>
                <Button
                  id="issuerAwaitingConfirmationCard-counterpartyCard-decline-button"
                  fullWidth
                  sx={{
                    [theme.breakpoints.down("sm")]: {
                      height: "36px",
                    },
                  }}
                  variant="contained"
                  color="secondary"
                  disabled={
                    actionAPIInProgress ||
                    !props.userIsSignatoryOnTradingAcc ||
                    validationInProgress ||
                    calculationInProgress
                  }
                  children="decline"
                  onClick={() =>
                    setWarningDialogOptions({
                      title: "Warning",
                      messageParagraphs: [
                        "Are you sure you want to DECLINE this order?",
                        "You will not be able to undo this action.",
                      ],
                      yesMethod: () => {
                        setWarningDialogOptions(null);
                        handleDecline().finally();
                      },
                      noMethod: () => setWarningDialogOptions(null),
                    })
                  }
                />
              </span>
            </Tooltip>
            {!isDesktop && (
              <Box
                sx={{
                  height: theme.spacing(2),
                  width: theme.spacing(2),
                }}
              />
            )}
            <Tooltip
              placement="top"
              title={(() => {
                switch (true) {
                  case calculationInProgress: {
                    return "Calculation in Progress";
                  }
                  case !requestValidationResult.valid: {
                    const msg = requestValidationResult.fieldValidations.tokens
                      ? requestValidationResult.fieldValidations.tokens.includes(
                          "Order size out",
                        )
                        ? requestValidationResult.fieldValidations.tokens
                        : ""
                      : "";
                    return (
                      msg ||
                      "Please ensure that all fields have been completed correctly"
                    );
                  }
                  case !props.userIsSignatoryOnTradingAcc: {
                    return "You are not a Signatory on the Trading Account";
                  }
                }
                return "";
              })()}
            >
              <span>
                <Button
                  id="issuerAwaitingConfirmationCard-counterpartyCard-accept-button"
                  fullWidth
                  sx={{
                    [theme.breakpoints.down("sm")]: {
                      height: "36px",
                    },
                  }}
                  variant="contained"
                  color="primary"
                  disabled={
                    actionAPIInProgress ||
                    !props.userIsSignatoryOnTradingAcc ||
                    !requestValidationResult.valid ||
                    validationInProgress ||
                    calculateDirectOrderFeeResponse.amountIncl.isZero() ||
                    calculationInProgress
                  }
                  children="accept"
                  onClick={handleAccept}
                />
              </span>
            </Tooltip>
          </Box>
        )}
      </Box>

      {showMintDialog && (
        <MintAssetDialog
          onMint={props.refreshCard}
          onCloseClick={() => setShowMintDialog(false)}
          assetToMintID={props.marketDirectOrderViewModel.assetID}
        />
      )}

      <WarningDialog
        showDialog={!!warningDialogOptions}
        onCloseClick={() => setWarningDialogOptions(null)}
        onYesClick={
          warningDialogOptions ? warningDialogOptions.yesMethod : () => null
        }
        onNoClick={
          warningDialogOptions ? warningDialogOptions.noMethod : () => null
        }
        title={warningDialogOptions?.title}
        messageParagraphs={
          warningDialogOptions ? warningDialogOptions.messageParagraphs : [""]
        }
        disableControls={actionAPIInProgress}
        showProgressIndicator={acceptInProgress}
      />
    </StyledCard>
  );
}

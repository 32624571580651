import { BigNumber } from "bignumber.js";
import isString from "lodash/isString";
import { roundToN } from "./round";

export interface FormatTextNumOpts {
  disallowNegative?: boolean;
  noDecimalPlaces?: number;
  roundUp?: boolean;
  addDecimalPadding?: boolean;
}

function getDefaultOpts(opts: FormatTextNumOpts) {
  if (opts.noDecimalPlaces === undefined) {
    return {
      disallowNegative: !!opts.disallowNegative,
      noDecimalPlaces: 2,
      addDecimalPadding: !!opts.addDecimalPadding,
    };
  }
  if (opts.noDecimalPlaces < 0) {
    throw new TypeError(
      `given number of decimals ${opts.noDecimalPlaces} is < 0 `,
    );
  } else {
    return {
      disallowNegative: !!opts.disallowNegative,
      noDecimalPlaces: opts.noDecimalPlaces,
      addDecimalPadding: !!opts.addDecimalPadding,
    };
  }
}

const specialCharacterMultipliers: { [key: string]: number } = {
  H: 2,
  h: 2,
  k: 3,
  K: 3,
  m: 6,
  M: 6,
  B: 9,
  b: 9,
};

const big10 = new BigNumber(10);

export function formatTextNum(
  valueToProcess: string | BigNumber,
  opts?: FormatTextNumOpts,
): string {
  const { disallowNegative, noDecimalPlaces, addDecimalPadding } =
    getDefaultOpts(opts ? opts : {});
  // get value to process as a string without any formatting
  valueToProcess = isString(valueToProcess)
    ? // if a string, then strip thousands separators
      stripThousandSeparators(valueToProcess)
    : // if it is Big then just convert to string
      valueToProcess.toString();

  // get value to process as big number for any operations
  const bigValueToProcess = new BigNumber(valueToProcess);

  // is value a number?
  if (!bigValueToProcess.isNaN()) {
    // value is a number

    // remove negative sign if negatives are disallowed
    if (disallowNegative && bigValueToProcess.isNegative()) {
      valueToProcess = valueToProcess.slice(1);
    }

    // format and return the result
    let result = removeLeadingZeros(
      roundToN(valueToProcess, noDecimalPlaces, opts?.roundUp),
    );
    if (addDecimalPadding) {
      result = addTrailingZeros(result, noDecimalPlaces);
    }

    return addThousandSeparators(result);
  }
  // value is not a number, deal with special cases or return zero

  // if value is only one character long
  if (valueToProcess.length === 1) {
    // it can only be a '-' and only if disallow negative is not set
    if (disallowNegative || valueToProcess !== "-") {
      return "";
    }
    return valueToProcess;
  }

  // look for special character multipliers between first digit and decimal point
  for (const specialChar in specialCharacterMultipliers) {
    if (new RegExp(`([0-9])*${specialChar}$`).test(valueToProcess)) {
      // process special character and round
      let roundedValue = roundToN(
        new BigNumber(valueToProcess.replace(specialChar, ""))
          .multipliedBy(big10.pow(specialCharacterMultipliers[specialChar]))
          .toString(),
        noDecimalPlaces,
        opts?.roundUp,
      );
      const bigRoundedValue = new BigNumber(roundedValue);

      // remove negative sign if negatives are disallowed
      if (disallowNegative && bigRoundedValue.isNegative()) {
        roundedValue = roundedValue.slice(1);
      }

      // format and return the result
      let result = removeLeadingZeros(roundedValue);

      if (addDecimalPadding) {
        result = addTrailingZeros(result, noDecimalPlaces);
      }

      return addThousandSeparators(result);
    }
  }

  return "";
}

export function stripThousandSeparators(
  valueToProcess: string,
  separator = ",",
): string {
  while (valueToProcess.includes(separator)) {
    valueToProcess = valueToProcess.replace(separator, "");
  }
  return valueToProcess;
}

export function addThousandSeparators(
  valueToProcess: string,
  separator = ",",
  decimalPoint = ".",
): string {
  const bigValueToProcess = new BigNumber(valueToProcess);
  if (bigValueToProcess.isNaN()) {
    console.error(
      `'${valueToProcess}' in 'addThousandSeparators' is not a number`,
    );
    return "";
  }
  const firstDigitIdx = bigValueToProcess.isNegative() ? 1 : 0;
  const decimalPointIdx = valueToProcess.includes(decimalPoint)
    ? valueToProcess.indexOf(decimalPoint)
    : valueToProcess.length;

  // if there are less than 4 digits before the decimal point
  // no separators will be added
  if (valueToProcess.slice(firstDigitIdx, decimalPointIdx).length < 4) {
    return valueToProcess;
  }

  const digitsToSeparate = valueToProcess.slice(
    firstDigitIdx + 1,
    decimalPointIdx,
  );
  let separatedDigits = "";
  for (let i = 0; i < digitsToSeparate.length; i++) {
    separatedDigits =
      digitsToSeparate[digitsToSeparate.length - (i + 1)] + separatedDigits;
    if (!((i + 1) % 3)) {
      separatedDigits = separator + separatedDigits;
    }
  }

  return `${valueToProcess.slice(
    0,
    firstDigitIdx + 1,
  )}${separatedDigits}${valueToProcess.slice(
    decimalPointIdx,
    valueToProcess.length,
  )}`;
}

const zerosOnlyRegex = new RegExp("^0+$");

export function removeLeadingZeros(valueToProcess: string): string {
  const bigValueToProcess = new BigNumber(valueToProcess);
  if (bigValueToProcess.isNaN()) {
    console.error(
      `'${valueToProcess}' in 'removeLeadingZeros' is not a number`,
    );
    return "";
  }

  // values with length <= 1 have no leading zeros to remove
  if (valueToProcess.length <= 1) {
    return valueToProcess;
  }

  // determine if value is negative and slice off '-' sign if so
  const negative = bigValueToProcess.isNegative();
  valueToProcess = negative ? valueToProcess.slice(1) : valueToProcess;

  // get index of decimal point in value to process
  let decimalPointIdx = valueToProcess.indexOf(".");

  // if there is no decimal point and the value contains only zeros
  // then return a single zero (add a '-' sign if required)
  if (decimalPointIdx < 0 && zerosOnlyRegex.test(valueToProcess)) {
    return (negative ? "-" : "") + "0";
  }

  // remove all but first leading zero
  decimalPointIdx =
    decimalPointIdx > -1 ? decimalPointIdx : valueToProcess.length;
  let sliceFromIdx = 0;
  for (let i = 0; i < decimalPointIdx; i++) {
    if (valueToProcess[i] === "0" && valueToProcess[i + 1] !== "0") {
      sliceFromIdx = valueToProcess[i + 1] === "." ? i : i + 1;
      break;
    } else if (valueToProcess[i] !== "0") {
      sliceFromIdx = i;
      break;
    }
  }

  // return trimmed value (add a '-' sign if required)
  return (
    (negative ? "-" : "") +
    valueToProcess.slice(sliceFromIdx, decimalPointIdx) +
    valueToProcess.slice(decimalPointIdx)
  );
}

export function addTrailingZeros(
  valueToProcess: string,
  decimalPlaces = 2,
  decimalPoint = ".",
): string {
  const bigValueToProcess = new BigNumber(valueToProcess);
  if (bigValueToProcess.isNaN()) {
    console.error(`'${valueToProcess}' in 'addTrailingZeros' is not a number`);
    return "";
  }

  // split the value to process into parts
  const valueParts = valueToProcess.split(decimalPoint);

  // if more than one part, most likely using the wrong decimalPoint
  if (valueParts.length > 2) {
    console.error(
      `'${valueToProcess}' in 'addTrailingZeros' has more than one decimal point '${decimalPoint}`,
    );
    return "";
  }

  // if no decimal point part, add one
  if (valueParts.length === 1) {
    valueParts.push("");
  }

  // fill with zeroes
  while (valueParts[1].length < decimalPlaces) {
    valueParts[1] += "0";
  }

  return valueParts.join(decimalPoint);
}

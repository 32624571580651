/**
 * @fileoverview gRPC-Web generated client stub for financial
 * @enhanceable
 * @public
 */

// Code generated by protoc-gen-grpc-web. DO NOT EDIT.
// versions:
// 	protoc-gen-grpc-web v1.5.0
// 	protoc              v5.28.0
// source: financial/rateSourceRecordingReader_meshproto.proto


/* eslint-disable */
// @ts-nocheck



const grpc = {};
grpc.web = require('grpc-web');


var security_context_pb = require('../security/context_pb.js')

var search_criterion_pb = require('../search/criterion_pb.js')

var search_query_pb = require('../search/query_pb.js')

var search_sorting_pb = require('../search/sorting_pb.js')

var financial_rateSourceRecording_pb = require('../financial/rateSourceRecording_pb.js')

var google_protobuf_timestamp_pb = require('google-protobuf/google/protobuf/timestamp_pb.js')

var num_decimal_pb = require('../num/decimal_pb.js')
const proto = {};
proto.financial = require('./rateSourceRecordingReader_meshproto_pb.js');

/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?grpc.web.ClientOptions} options
 * @constructor
 * @struct
 * @final
 */
proto.financial.RateSourceRecordingReaderClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options.format = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname.replace(/\/+$/, '');

};


/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?grpc.web.ClientOptions} options
 * @constructor
 * @struct
 * @final
 */
proto.financial.RateSourceRecordingReaderPromiseClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options.format = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname.replace(/\/+$/, '');

};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.financial.ReadOneRateSourceRecordingRequest,
 *   !proto.financial.ReadOneRateSourceRecordingResponse>}
 */
const methodDescriptor_RateSourceRecordingReader_ReadOneRateSourceRecording = new grpc.web.MethodDescriptor(
  '/financial.RateSourceRecordingReader/ReadOneRateSourceRecording',
  grpc.web.MethodType.UNARY,
  proto.financial.ReadOneRateSourceRecordingRequest,
  proto.financial.ReadOneRateSourceRecordingResponse,
  /**
   * @param {!proto.financial.ReadOneRateSourceRecordingRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.financial.ReadOneRateSourceRecordingResponse.deserializeBinary
);


/**
 * @param {!proto.financial.ReadOneRateSourceRecordingRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.financial.ReadOneRateSourceRecordingResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.financial.ReadOneRateSourceRecordingResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.financial.RateSourceRecordingReaderClient.prototype.readOneRateSourceRecording =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/financial.RateSourceRecordingReader/ReadOneRateSourceRecording',
      request,
      metadata || {},
      methodDescriptor_RateSourceRecordingReader_ReadOneRateSourceRecording,
      callback);
};


/**
 * @param {!proto.financial.ReadOneRateSourceRecordingRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.financial.ReadOneRateSourceRecordingResponse>}
 *     Promise that resolves to the response
 */
proto.financial.RateSourceRecordingReaderPromiseClient.prototype.readOneRateSourceRecording =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/financial.RateSourceRecordingReader/ReadOneRateSourceRecording',
      request,
      metadata || {},
      methodDescriptor_RateSourceRecordingReader_ReadOneRateSourceRecording);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.financial.ReadManyRateSourceRecordingRequest,
 *   !proto.financial.ReadManyRateSourceRecordingResponse>}
 */
const methodDescriptor_RateSourceRecordingReader_ReadManyRateSourceRecording = new grpc.web.MethodDescriptor(
  '/financial.RateSourceRecordingReader/ReadManyRateSourceRecording',
  grpc.web.MethodType.UNARY,
  proto.financial.ReadManyRateSourceRecordingRequest,
  proto.financial.ReadManyRateSourceRecordingResponse,
  /**
   * @param {!proto.financial.ReadManyRateSourceRecordingRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.financial.ReadManyRateSourceRecordingResponse.deserializeBinary
);


/**
 * @param {!proto.financial.ReadManyRateSourceRecordingRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.financial.ReadManyRateSourceRecordingResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.financial.ReadManyRateSourceRecordingResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.financial.RateSourceRecordingReaderClient.prototype.readManyRateSourceRecording =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/financial.RateSourceRecordingReader/ReadManyRateSourceRecording',
      request,
      metadata || {},
      methodDescriptor_RateSourceRecordingReader_ReadManyRateSourceRecording,
      callback);
};


/**
 * @param {!proto.financial.ReadManyRateSourceRecordingRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.financial.ReadManyRateSourceRecordingResponse>}
 *     Promise that resolves to the response
 */
proto.financial.RateSourceRecordingReaderPromiseClient.prototype.readManyRateSourceRecording =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/financial.RateSourceRecordingReader/ReadManyRateSourceRecording',
      request,
      metadata || {},
      methodDescriptor_RateSourceRecordingReader_ReadManyRateSourceRecording);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.financial.ReadAllRateSourceRecordingRequest,
 *   !proto.financial.ReadAllRateSourceRecordingResponse>}
 */
const methodDescriptor_RateSourceRecordingReader_ReadAllRateSourceRecording = new grpc.web.MethodDescriptor(
  '/financial.RateSourceRecordingReader/ReadAllRateSourceRecording',
  grpc.web.MethodType.UNARY,
  proto.financial.ReadAllRateSourceRecordingRequest,
  proto.financial.ReadAllRateSourceRecordingResponse,
  /**
   * @param {!proto.financial.ReadAllRateSourceRecordingRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.financial.ReadAllRateSourceRecordingResponse.deserializeBinary
);


/**
 * @param {!proto.financial.ReadAllRateSourceRecordingRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.financial.ReadAllRateSourceRecordingResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.financial.ReadAllRateSourceRecordingResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.financial.RateSourceRecordingReaderClient.prototype.readAllRateSourceRecording =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/financial.RateSourceRecordingReader/ReadAllRateSourceRecording',
      request,
      metadata || {},
      methodDescriptor_RateSourceRecordingReader_ReadAllRateSourceRecording,
      callback);
};


/**
 * @param {!proto.financial.ReadAllRateSourceRecordingRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.financial.ReadAllRateSourceRecordingResponse>}
 *     Promise that resolves to the response
 */
proto.financial.RateSourceRecordingReaderPromiseClient.prototype.readAllRateSourceRecording =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/financial.RateSourceRecordingReader/ReadAllRateSourceRecording',
      request,
      metadata || {},
      methodDescriptor_RateSourceRecordingReader_ReadAllRateSourceRecording);
};


module.exports = proto.financial;


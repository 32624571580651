import {
  jsonRPCRequest,
  JSONRPCRequestOpts,
} from "utilities/network/jsonRPCRequest/jsonRPCRequest";
import config from "react-global-configuration";
import { Context } from "james/security";
import { Token } from "james/ledger";
import { Listing, Mechanism } from "james/market";
import { LedgerNetwork } from "james/ledger/Network";
import { BigNumber } from "bignumber.js";

export type ListAssetRequestDEPRECATED = {
  context: Context;
  assetToken: Token;
  marketMechanism: Mechanism;
  estimatedAnnualReturn: BigNumber;
  investmentObjective: string;
  exchangeNetwork: LedgerNetwork;
};

export type ListAssetRequestDEPRECATEDField = keyof ListAssetRequestDEPRECATED;
export type ListAssetRequestDEPRECATEDValue<
  T extends ListAssetRequestDEPRECATEDField,
> = ListAssetRequestDEPRECATED[T];

export type ListAssetRequest = {
  context: Context;
  assetToken: Token;
  marketMechanisms: Mechanism[];
  estimatedAnnualReturn: BigNumber;
  investmentObjective: string;
  exchangeNetwork: LedgerNetwork;
};

export type ListAssetResponse = {
  listing: Listing;
  transactionID: string;
};

export type ListAssetRequestField = keyof ListAssetRequest;
export type ListAssetRequestValue<T extends ListAssetRequestField> =
  ListAssetRequest[T];

export type RetryListAssetRequest = {
  context: Context;
  assetToken: Token;
};

export type RetryListAssetResponse = {
  listing: Listing;
  transactionID: string;
};

export type ActivateListingRequest = {
  context: Context;
  listingID: string;
  lastActionAnnotation: string;
};

export type ActivateListingResponse = {
  listing: Listing;
};

export type DeactivateListingRequest = {
  context: Context;
  listingID: string;
  lastActionAnnotation: string;
};

export type DeactivateListingResponse = {
  listing: Listing;
};

export const AssetStateListerServiceProviderName =
  "market-ListingStateController";

export const ListingStateController = {
  async ListAsset(
    request: ListAssetRequest,
    opts?: JSONRPCRequestOpts,
  ): Promise<ListAssetResponse> {
    const response = await jsonRPCRequest(
      {
        url: config.get("meshConsistencyAuthorizedURL"),
        method: `${AssetStateListerServiceProviderName}.ListAsset`,
        request,
      },
      opts,
    );

    return {
      listing: new Listing(response.listing),
      transactionID: response.transactionID,
    };
  },
  async RetryListAsset(
    request: RetryListAssetRequest,
    opts?: JSONRPCRequestOpts,
  ): Promise<RetryListAssetResponse> {
    const response = await jsonRPCRequest(
      {
        url: config.get("meshConsistencyAuthorizedURL"),
        method: `${AssetStateListerServiceProviderName}.RetryListAsset`,
        request,
      },
      opts,
    );

    return {
      listing: new Listing(response.listing),
      transactionID: response.transactionID,
    };
  },
  async ActivateListing(
    request: ActivateListingRequest,
    opts?: JSONRPCRequestOpts,
  ): Promise<ActivateListingResponse> {
    const response = await jsonRPCRequest(
      {
        url: config.get("meshConsistencyAuthorizedURL"),
        method: `${AssetStateListerServiceProviderName}.ActivateListing`,
        request,
      },
      opts,
    );

    return { listing: new Listing(response.listing) };
  },
  async DeactivateListing(
    request: DeactivateListingRequest,
    opts?: JSONRPCRequestOpts,
  ): Promise<DeactivateListingResponse> {
    const response = await jsonRPCRequest(
      {
        url: config.get("meshConsistencyAuthorizedURL"),
        method: `${AssetStateListerServiceProviderName}.DeactivateListing`,
        request,
      },
      opts,
    );

    return { listing: new Listing(response.listing) };
  },
};

/**
 * @fileoverview gRPC-Web generated client stub for marketing
 * @enhanceable
 * @public
 */

// Code generated by protoc-gen-grpc-web. DO NOT EDIT.
// versions:
// 	protoc-gen-grpc-web v1.5.0
// 	protoc              v5.28.0
// source: marketing/instrumentMarketingContentUpdater.proto


/* eslint-disable */
// @ts-nocheck



const grpc = {};
grpc.web = require('grpc-web');


var marketing_instrumentMarketingContent_pb = require('../marketing/instrumentMarketingContent_pb.js')

var marketing_socialMediaLinks_pb = require('../marketing/socialMediaLinks_pb.js')

var marketing_independentReview_pb = require('../marketing/independentReview_pb.js')

var marketing_mediaEntry_pb = require('../marketing/mediaEntry_pb.js')

var marketing_newsArticle_pb = require('../marketing/newsArticle_pb.js')

var security_context_pb = require('../security/context_pb.js')
const proto = {};
proto.marketing = require('./instrumentMarketingContentUpdater_pb.js');

/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?grpc.web.ClientOptions} options
 * @constructor
 * @struct
 * @final
 */
proto.marketing.InstrumentMarketingContentUpdaterClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options.format = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname.replace(/\/+$/, '');

};


/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?grpc.web.ClientOptions} options
 * @constructor
 * @struct
 * @final
 */
proto.marketing.InstrumentMarketingContentUpdaterPromiseClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options.format = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname.replace(/\/+$/, '');

};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.marketing.UpdateInvestmentObjectiveRequest,
 *   !proto.marketing.UpdateInvestmentObjectiveResponse>}
 */
const methodDescriptor_InstrumentMarketingContentUpdater_UpdateInvestmentObjective = new grpc.web.MethodDescriptor(
  '/marketing.InstrumentMarketingContentUpdater/UpdateInvestmentObjective',
  grpc.web.MethodType.UNARY,
  proto.marketing.UpdateInvestmentObjectiveRequest,
  proto.marketing.UpdateInvestmentObjectiveResponse,
  /**
   * @param {!proto.marketing.UpdateInvestmentObjectiveRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.marketing.UpdateInvestmentObjectiveResponse.deserializeBinary
);


/**
 * @param {!proto.marketing.UpdateInvestmentObjectiveRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.marketing.UpdateInvestmentObjectiveResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.marketing.UpdateInvestmentObjectiveResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.marketing.InstrumentMarketingContentUpdaterClient.prototype.updateInvestmentObjective =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/marketing.InstrumentMarketingContentUpdater/UpdateInvestmentObjective',
      request,
      metadata || {},
      methodDescriptor_InstrumentMarketingContentUpdater_UpdateInvestmentObjective,
      callback);
};


/**
 * @param {!proto.marketing.UpdateInvestmentObjectiveRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.marketing.UpdateInvestmentObjectiveResponse>}
 *     Promise that resolves to the response
 */
proto.marketing.InstrumentMarketingContentUpdaterPromiseClient.prototype.updateInvestmentObjective =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/marketing.InstrumentMarketingContentUpdater/UpdateInvestmentObjective',
      request,
      metadata || {},
      methodDescriptor_InstrumentMarketingContentUpdater_UpdateInvestmentObjective);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.marketing.UpdateSocialMediaLinksRequest,
 *   !proto.marketing.UpdateSocialMediaLinksResponse>}
 */
const methodDescriptor_InstrumentMarketingContentUpdater_UpdateSocialMediaLinks = new grpc.web.MethodDescriptor(
  '/marketing.InstrumentMarketingContentUpdater/UpdateSocialMediaLinks',
  grpc.web.MethodType.UNARY,
  proto.marketing.UpdateSocialMediaLinksRequest,
  proto.marketing.UpdateSocialMediaLinksResponse,
  /**
   * @param {!proto.marketing.UpdateSocialMediaLinksRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.marketing.UpdateSocialMediaLinksResponse.deserializeBinary
);


/**
 * @param {!proto.marketing.UpdateSocialMediaLinksRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.marketing.UpdateSocialMediaLinksResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.marketing.UpdateSocialMediaLinksResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.marketing.InstrumentMarketingContentUpdaterClient.prototype.updateSocialMediaLinks =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/marketing.InstrumentMarketingContentUpdater/UpdateSocialMediaLinks',
      request,
      metadata || {},
      methodDescriptor_InstrumentMarketingContentUpdater_UpdateSocialMediaLinks,
      callback);
};


/**
 * @param {!proto.marketing.UpdateSocialMediaLinksRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.marketing.UpdateSocialMediaLinksResponse>}
 *     Promise that resolves to the response
 */
proto.marketing.InstrumentMarketingContentUpdaterPromiseClient.prototype.updateSocialMediaLinks =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/marketing.InstrumentMarketingContentUpdater/UpdateSocialMediaLinks',
      request,
      metadata || {},
      methodDescriptor_InstrumentMarketingContentUpdater_UpdateSocialMediaLinks);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.marketing.UpdateNewsArticlesRequest,
 *   !proto.marketing.UpdateNewsArticlesResponse>}
 */
const methodDescriptor_InstrumentMarketingContentUpdater_UpdateNewsArticles = new grpc.web.MethodDescriptor(
  '/marketing.InstrumentMarketingContentUpdater/UpdateNewsArticles',
  grpc.web.MethodType.UNARY,
  proto.marketing.UpdateNewsArticlesRequest,
  proto.marketing.UpdateNewsArticlesResponse,
  /**
   * @param {!proto.marketing.UpdateNewsArticlesRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.marketing.UpdateNewsArticlesResponse.deserializeBinary
);


/**
 * @param {!proto.marketing.UpdateNewsArticlesRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.marketing.UpdateNewsArticlesResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.marketing.UpdateNewsArticlesResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.marketing.InstrumentMarketingContentUpdaterClient.prototype.updateNewsArticles =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/marketing.InstrumentMarketingContentUpdater/UpdateNewsArticles',
      request,
      metadata || {},
      methodDescriptor_InstrumentMarketingContentUpdater_UpdateNewsArticles,
      callback);
};


/**
 * @param {!proto.marketing.UpdateNewsArticlesRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.marketing.UpdateNewsArticlesResponse>}
 *     Promise that resolves to the response
 */
proto.marketing.InstrumentMarketingContentUpdaterPromiseClient.prototype.updateNewsArticles =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/marketing.InstrumentMarketingContentUpdater/UpdateNewsArticles',
      request,
      metadata || {},
      methodDescriptor_InstrumentMarketingContentUpdater_UpdateNewsArticles);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.marketing.UpdateMediaEntriesRequest,
 *   !proto.marketing.UpdateMediaEntriesResponse>}
 */
const methodDescriptor_InstrumentMarketingContentUpdater_UpdateMediaEntries = new grpc.web.MethodDescriptor(
  '/marketing.InstrumentMarketingContentUpdater/UpdateMediaEntries',
  grpc.web.MethodType.UNARY,
  proto.marketing.UpdateMediaEntriesRequest,
  proto.marketing.UpdateMediaEntriesResponse,
  /**
   * @param {!proto.marketing.UpdateMediaEntriesRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.marketing.UpdateMediaEntriesResponse.deserializeBinary
);


/**
 * @param {!proto.marketing.UpdateMediaEntriesRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.marketing.UpdateMediaEntriesResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.marketing.UpdateMediaEntriesResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.marketing.InstrumentMarketingContentUpdaterClient.prototype.updateMediaEntries =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/marketing.InstrumentMarketingContentUpdater/UpdateMediaEntries',
      request,
      metadata || {},
      methodDescriptor_InstrumentMarketingContentUpdater_UpdateMediaEntries,
      callback);
};


/**
 * @param {!proto.marketing.UpdateMediaEntriesRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.marketing.UpdateMediaEntriesResponse>}
 *     Promise that resolves to the response
 */
proto.marketing.InstrumentMarketingContentUpdaterPromiseClient.prototype.updateMediaEntries =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/marketing.InstrumentMarketingContentUpdater/UpdateMediaEntries',
      request,
      metadata || {},
      methodDescriptor_InstrumentMarketingContentUpdater_UpdateMediaEntries);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.marketing.UpdateIndependentReviewsRequest,
 *   !proto.marketing.UpdateIndependentReviewsResponse>}
 */
const methodDescriptor_InstrumentMarketingContentUpdater_UpdateIndependentReviews = new grpc.web.MethodDescriptor(
  '/marketing.InstrumentMarketingContentUpdater/UpdateIndependentReviews',
  grpc.web.MethodType.UNARY,
  proto.marketing.UpdateIndependentReviewsRequest,
  proto.marketing.UpdateIndependentReviewsResponse,
  /**
   * @param {!proto.marketing.UpdateIndependentReviewsRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.marketing.UpdateIndependentReviewsResponse.deserializeBinary
);


/**
 * @param {!proto.marketing.UpdateIndependentReviewsRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.marketing.UpdateIndependentReviewsResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.marketing.UpdateIndependentReviewsResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.marketing.InstrumentMarketingContentUpdaterClient.prototype.updateIndependentReviews =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/marketing.InstrumentMarketingContentUpdater/UpdateIndependentReviews',
      request,
      metadata || {},
      methodDescriptor_InstrumentMarketingContentUpdater_UpdateIndependentReviews,
      callback);
};


/**
 * @param {!proto.marketing.UpdateIndependentReviewsRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.marketing.UpdateIndependentReviewsResponse>}
 *     Promise that resolves to the response
 */
proto.marketing.InstrumentMarketingContentUpdaterPromiseClient.prototype.updateIndependentReviews =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/marketing.InstrumentMarketingContentUpdater/UpdateIndependentReviews',
      request,
      metadata || {},
      methodDescriptor_InstrumentMarketingContentUpdater_UpdateIndependentReviews);
};


module.exports = proto.marketing;


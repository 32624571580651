import { AssetflowCategory } from "@mesh/common-js/dist/financial/assetflowCategory_pb";
import { BusinessDayConvention } from "@mesh/common-js/dist/financial/businessDayConvention_pb";
import { BulletSmartInstrumentLeg } from "@mesh/common-js/dist/financial/smartInstrumentLegBullet_pb";
import { SmartInstrument } from "@mesh/common-js/dist/financial/smartInstrument_pb";
import { protobufTimestampToDayjs } from "@mesh/common-js/dist/googleProtobufConverters";
import { decimalToBigNumber } from "@mesh/common-js/dist/num";
import { ValidationResult } from "common/validation";
import { Timestamp } from "google-protobuf/google/protobuf/timestamp_pb";
import { validateShiftingShiftingPeriod } from "../../../ShiftingPeriodForm";

export type BulletSmartInstrumentLegValidationData = {
  smartInstrument: SmartInstrument;
};

export function validateBulletSmartInstrumentLeg(
  leg: BulletSmartInstrumentLeg,
  data: BulletSmartInstrumentLegValidationData,
): ValidationResult {
  // prepare validation result
  const validationResult: ValidationResult = {
    // assumed to true -
    // any error must set to false
    valid: true,
    // contains field validations
    fieldValidations: {},
  };

  const getFieldValidationPrefix = () =>
    `bulletSmartInstrumentLeg-${leg.getId()}`;
  const notValid = (field: string, helperText: string) => {
    validationResult.valid = false;
    validationResult.fieldValidations[
      `${getFieldValidationPrefix()}-${field}`
    ] = helperText;
  };

  // perform necessary conversions
  const firstPossibleDate = protobufTimestampToDayjs(
    data.smartInstrument.getIssuedate() ?? new Timestamp(),
  );
  const amount = leg.getAmount()?.getValue();
  const date = protobufTimestampToDayjs(leg.getDate() ?? new Timestamp());

  //perform validations
  if (leg.getName() === "") {
    notValid("name", "Must be set");
  } else if (leg.getName().length < 3) {
    notValid("name", "Must be at least 3 characters");
  } else if (
    // placeholder for actual name validation
    leg.getName() === "Non-unique name"
  ) {
    notValid("name", "A leg with this name already exists");
  }

  if (
    leg.getAssetflowcategory() ===
    AssetflowCategory.UNDEFINED_ASSETFLOW_CATEGORY
  ) {
    notValid("assetFlowCategory", "Must be set");
  }

  if (
    !decimalToBigNumber(amount).isPositive() &&
    !decimalToBigNumber(amount).isNegative()
  ) {
    notValid("amount", "Invalid character");
  } else if (decimalToBigNumber(amount).isLessThanOrEqualTo(0)) {
    notValid("amount", "Must be greater than 0");
  }

  if (date.isBefore(firstPossibleDate)) {
    notValid("date", "Cannot be before instrument issue date");
  }

  if (leg.getCalendarsList().length === 0) {
    notValid("calendars", "Choose at least 1");
  }

  if (
    leg.getBusinessdayconvention() ===
    BusinessDayConvention.UNDEFINED_BUSINESS_DAY_CONVENTION
  ) {
    notValid("businessDayConvention", "Must be set");
  }

  const recordPeriod = leg.getRecordperiod();
  if (!recordPeriod) {
    throw new TypeError("record period is not set");
  }
  const scheduleValidationResult = validateShiftingShiftingPeriod(
    recordPeriod,
    getFieldValidationPrefix() + "-recordPeriod",
  );
  validationResult.valid =
    scheduleValidationResult.valid && validationResult.valid;
  for (const field in scheduleValidationResult.fieldValidations) {
    validationResult.fieldValidations[field] =
      scheduleValidationResult.fieldValidations[field];
  }

  return validationResult;
}

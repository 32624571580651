import { SignatoryType } from "@mesh/common-js/dist/stellar/enrichedAccount_pb";

export * from "./Client";
export * from "./ClientServer";
export * from "./SpotPricer";
export * from "./PriceHistorian";
export * from "./Network";

export function pasrseSignatoryType(t: string): SignatoryType {
  switch (t) {
    case "SHA256Hash":
      return SignatoryType.SHA256_HASH_SIGNATORY_TYPE;
    case "ed25519_public_key":
      return SignatoryType.ED25519_SIGNATORY_TYPE;
    case "PreAuthTx":
      return SignatoryType.PRE_AUTH_TX_SIGNATORY_TYPE;
    default:
      throw new TypeError(`unknown signatory type: '${t}'`);
  }
}

import React, { useCallback, useEffect, useMemo, useState } from "react";
import { styled } from "@mui/material/styles";
import {
  Card,
  CardContent,
  CardHeader,
  CircularProgress,
  Typography,
} from "@mui/material";
import { formatTextNum } from "utilities/number";
import { Reader as RemunerationFeeViewReader } from "james/views/remunerationFeeView";
import { TextListCriterion } from "james/search/criterion";
import { FeeState } from "james/remuneration/Fee";
import { Query } from "james/search/query/Query";
import { Balance } from "@mesh/common-js/dist/views/stellarAccountView/model_pb";
import { useSnackbar } from "notistack";
import { useIsMounted } from "hooks";
import { LedgerAccountCategory } from "james/ledger";
import { ReadResponse } from "james/views/remunerationFeeView/Reader";
import { BigNumber } from "bignumber.js";
import { useAccountContext } from "context/Account/Account";
import { useApplicationContext } from "context/Application/Application";
import { ManagingCompanyClientName } from "const";
import { useErrorContext } from "context/Error";

const PREFIX = "FeesAndAccountStatsCard";

const classes = {
  root: `${PREFIX}-root`,
  cardContentLoading: `${PREFIX}-cardContentLoading`,
  cardContent: `${PREFIX}-cardContent`,
};

const StyledCard = styled(Card)(({ theme }) => ({
  [`& .${classes.root}`]: {
    padding: theme.spacing(4),
    display: "flex",
    gap: theme.spacing(4),
    flexDirection: "column",
  },

  [`& .${classes.cardContentLoading}`]: {
    display: "grid",
    justifyContent: "center",
    alignItems: "center",
  },

  [`& .${classes.cardContent}`]: {
    display: "grid",
    gridTemplateColumns: "auto 1fr 1fr",
    gridTemplateRows: "repeat(3, auto)",
    rowGap: theme.spacing(1),
    columnGap: theme.spacing(2),
  },
}));

export function FeesAndAccountStatsCard() {
  const { errorContextErrorTranslator } = useErrorContext();
  const { authContext } = useApplicationContext();

  const { enqueueSnackbar } = useSnackbar();
  const isMounted = useIsMounted();

  const { stellarAccountContext } = useAccountContext();

  const [remunerationFeeViewReadLoading, setRemunerationFeeViewReadLoading] =
    useState(true);
  const [remunerationFeeViewReadResponse, setRemunerationFeeViewReadResponse] =
    useState<ReadResponse>({
      total: 0,
      models: [],
    });

  const remunerationFeeViewRead = useCallback(async () => {
    try {
      if (!isMounted()) {
        return;
      }
      setRemunerationFeeViewReadLoading(true);
      const remunerationFeeViewReadResp = await RemunerationFeeViewReader.Read({
        context: authContext,
        criteria: {
          state: TextListCriterion([
            FeeState.Pending,
            FeeState.UnderInvestigation,
          ]),
        },
        query: new Query(),
      });
      setRemunerationFeeViewReadResponse(remunerationFeeViewReadResp);
    } catch (e) {
      const err = errorContextErrorTranslator.translateError(e);
      console.error(
        `error retrieving remuneration fee view model: ${
          err.message ? err.message : err.toString()
        }`,
      );
      enqueueSnackbar(
        `Error retrieving remuneration fee view models: ${
          err.message ? err.message : err.toString()
        }`,
        { variant: "error" },
      );
    } finally {
      if (isMounted()) {
        setRemunerationFeeViewReadLoading(false);
      }
    }
  }, [authContext, enqueueSnackbar, isMounted]);

  const [feesAndAccStatsLoading, setFeesAndAccStatsLoading] = useState(true);
  const [opsAccBalanceInXLM, setOpsAccBalanceInXLM] = useState<
    Balance | undefined
  >(undefined);
  const [feeAccBalanceInMZAR, setFeeAccBalanceInMZAR] = useState<
    Balance | undefined
  >(undefined);

  const stellarAccountViewReaderRead = useCallback(async () => {
    try {
      if (!isMounted()) {
        return;
      }
      setFeesAndAccStatsLoading(true);
      const accountViewModels = stellarAccountContext.accounts.filter(
        (v) =>
          v.getLabel() === LedgerAccountCategory.Fee ||
          v.getLabel() === LedgerAccountCategory.Operational,
      );

      // finding the fee and operational account
      const feeAccs = accountViewModels.filter(
        (acc) => acc.getLabel() === LedgerAccountCategory.Fee,
      );
      const opsAccs = accountViewModels.filter(
        (acc) => acc.getLabel() === LedgerAccountCategory.Operational,
      );

      // using the XLM and mZAR balances of the first operational account and fee account respectivele
      const feeAccBalances =
        feeAccs.length > 0 ? feeAccs[0].getBalancesList() : [];
      const opsAccBalances =
        opsAccs.length > 0 ? opsAccs[0].getBalancesList() : [];

      const mZARFeeAccBalance = feeAccBalances.find(
        (balance) =>
          balance?.getTokenviewmodel()?.getToken()?.getCode() === "mZAR" &&
          balance
            ?.getTokenviewmodel()
            ?.getIssuer()
            ?.includes(ManagingCompanyClientName),
      );
      if (!isMounted()) {
        return;
      }
      if (mZARFeeAccBalance) {
        setFeeAccBalanceInMZAR(mZARFeeAccBalance);
      }
      const XLMOpsAccBalance = opsAccBalances.find(
        (balance) =>
          balance?.getTokenviewmodel()?.getToken()?.getCode() === "XLM" &&
          balance?.getTokenviewmodel()?.getIssuer()?.includes("Stellar"),
      );
      if (!isMounted()) {
        return;
      }
      if (XLMOpsAccBalance) {
        setOpsAccBalanceInXLM(XLMOpsAccBalance);
      }
    } catch (e) {
      const err = errorContextErrorTranslator.translateError(e);
      console.error(
        `error retrieving account view model: ${
          err.message ? err.message : err.toString()
        }`,
      );
      enqueueSnackbar(
        `Error retrieving account view models: ${
          err.message ? err.message : err.toString()
        }`,
        { variant: "error" },
      );
    } finally {
      if (isMounted()) {
        setFeesAndAccStatsLoading(false);
      }
    }
  }, [authContext, enqueueSnackbar, isMounted]);

  // calculate number of fees and sum of amount of fees in unsettled state
  const { feeTotal, numberOfFees }: { feeTotal: number; numberOfFees: number } =
    useMemo(
      () => ({
        feeTotal: remunerationFeeViewReadResponse.models
          .reduce(
            (total, fee) => fee.feeAmount.value.plus(total),
            new BigNumber("0"),
          )
          .toNumber(),
        numberOfFees: remunerationFeeViewReadResponse.total,
      }),
      [remunerationFeeViewReadResponse],
    );

  // effects
  useEffect(() => {
    if (!isMounted()) {
      return;
    }
    stellarAccountViewReaderRead().finally();
    remunerationFeeViewRead().finally();
  }, [stellarAccountViewReaderRead, remunerationFeeViewRead, isMounted]);

  return (
    <StyledCard>
      <CardHeader title="Fees and Account Stats" />
      {remunerationFeeViewReadLoading || feesAndAccStatsLoading ? (
        <CardContent className={classes.cardContentLoading}>
          <CircularProgress size={50} />
        </CardContent>
      ) : (
        <CardContent className={classes.cardContent}>
          <Typography variant="subtitle2" children="Operational Acc. Balance" />
          <Typography
            variant="subtitle1"
            color="secondary"
            children={
              opsAccBalanceInXLM
                ? formatTextNum(
                    opsAccBalanceInXLM.getAmount()?.getValue()?.toString() ||
                      "",
                  )
                : "-"
            }
          />
          <Typography
            variant="subtitle1"
            color="secondary"
            children={
              opsAccBalanceInXLM
                ? opsAccBalanceInXLM.getAmount()?.getToken()?.getCode()
                : "Unk."
            }
          />
          <Typography variant="subtitle2" children="Fee Acc. Balance" />
          <Typography
            variant="subtitle1"
            color="secondary"
            children={
              feeAccBalanceInMZAR
                ? formatTextNum(
                    feeAccBalanceInMZAR.getAmount()?.getValue()?.toString() ||
                      "",
                  )
                : "-"
            }
          />
          <Typography
            variant="subtitle1"
            color="secondary"
            children={
              feeAccBalanceInMZAR
                ? feeAccBalanceInMZAR.getAmount()?.getToken()?.getCode()
                : "Unk."
            }
          />
          <Typography
            variant="subtitle2"
            children={`Unsettled Fees (${numberOfFees} ${
              numberOfFees === 1 ? "Fee" : "Fees"
            })`}
          />
          <Typography
            variant="subtitle1"
            color="secondary"
            children={
              numberOfFees > 0
                ? `${formatTextNum(feeTotal.toString())}`
                : "No fees"
            }
          />
          <Typography variant="subtitle1" color="secondary" children="mZAR" />
        </CardContent>
      )}
    </StyledCard>
  );
}

import React from "react";
import { Listing, QuoteParameter } from "james/market";
import {
  Balance,
  Model as StellarAccountViewModel,
} from "@mesh/common-js/dist/views/stellarAccountView/model_pb";
import { Model as LedgerTokenViewModel } from "james/views/ledgerTokenView";
import { Model as MarketDirectOrderViewModel } from "james/views/marketDirectOrderView";
import { CounterpartyCard } from "./CounterpartyCard";
import { InitiatingPartyCard } from "./InitiatingPartyCard";
import { HeaderProps } from "../Header/Header";

export interface InvestorAwaitingConfirmationCardProps extends HeaderProps {
  onActionComplete: () => void;
  refreshCard: () => void;
  tradingAccViewModel: StellarAccountViewModel;
  viewingAsInitiatingParty: boolean;
  listing: Listing;
  marketDirectOrderViewModel: MarketDirectOrderViewModel;
  tradingAccountID: string;
  userIsSignatoryOnTradingAcc: boolean;
  assetIssuanceTokenBalance: Balance;
  assetIssuanceTokenViewModel: LedgerTokenViewModel;
  assetValuationTokenBalance: Balance;
  assetValuationTokenViewModel: LedgerTokenViewModel;
  marketMechanismQuoteParameter: QuoteParameter;
  close?: () => void;
}

export function InvestorAwaitingConfirmationCard(
  props: InvestorAwaitingConfirmationCardProps,
) {
  return props.viewingAsInitiatingParty ? (
    <InitiatingPartyCard {...props} />
  ) : (
    <CounterpartyCard {...props} />
  );
}

import isEqual from "lodash/isEqual";

export const youtubeUrlToEmbed = (
  url: string,
): { embedUrl: string; thumbnailUrl: string } => {
  // Function to extract video ID from a YouTube URL

  const extractVideoId = (url: string): string | null => {
    // Could add other sources in the future through the matching patterns
    const watchPattern =
      /(?:https?:\/\/)?(?:www\.)?youtube\.com\/watch\?v=([^&]+)/;
    const sharePattern = /(?:https?:\/\/)?youtu\.be\/([^?]+)/;

    const watchMatch = url.match(watchPattern);
    if (watchMatch && watchMatch[1]) {
      return watchMatch[1];
    }

    const shareMatch = url.match(sharePattern);
    if (shareMatch && shareMatch[1]) {
      return shareMatch[1];
    }

    return null;
  };

  // Extract the video ID
  const videoId = extractVideoId(url);

  if (!videoId) {
    return { embedUrl: url, thumbnailUrl: "" };
  }

  // Construct the embed URL
  const embedUrl = `https://www.youtube.com/embed/${videoId}`;
  // Construct the thumbnail URL (default size)
  const thumbnailUrl = `https://img.youtube.com/vi/${videoId}/default.jpg`;

  return { embedUrl, thumbnailUrl };
};

// Generic objectsAreEqual function
export function objectsAreEqual<T>(a: T[], b: T[]): boolean {
  if (a === undefined && b === undefined) {
    return true;
  }
  if (a?.length != b?.length) {
    return false;
  }

  for (const item in a) {
    if (!isEqual(a[item], b[item])) {
      return false;
    }
  }
  return true;
}

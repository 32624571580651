import React from "react";
import { Router } from "routes";
import {
  RateSourceRecordings,
  RateSources as RateSourceManagement,
} from "./components";
import { Box, Grid, Paper, Tab, Tabs } from "@mui/material";
import { RouteType } from "routes/Route";
import { useNavigate } from "react-router-dom";

const tabRoutes: RouteType[] = [
  {
    name: "Sources",
    id: "sources",
    path: "/market-management/rate-sources/rate-sources",
    component: <RateSourceManagement />,
  },
  {
    name: "Recordings",
    id: "recordings",
    path: "/market-management/rate-sources/rate-source-recordings",
    component: <RateSourceRecordings />,
  },
];

export function RateSources() {
  const navigate = useNavigate();

  // determine and set the active path each time the component renders
  // (initialise to first available route path if current route not valid)
  const currentRoute = tabRoutes.find(
    (r) => window.location.pathname === r.path,
  );
  let activePath = tabRoutes.length ? tabRoutes[0].path : "";
  if (currentRoute) {
    activePath = currentRoute.path;
  }

  return (
    <Box>
      <Paper square>
        <Grid container>
          <Grid item>
            <Tabs
              value={activePath}
              onChange={(_, value) => {
                if (activePath === value) {
                  return;
                }
                navigate(value);
              }}
              textColor={"inherit"}
            >
              {tabRoutes.map((t, i) => (
                <Tab key={i} value={t.path} label={t.name} />
              ))}
            </Tabs>
          </Grid>
        </Grid>
      </Paper>
      <Box sx={(theme) => ({ padding: theme.spacing(2) })}>
        <Router
          baseURL={"/market-management/rate-sources"}
          redirectPath={"/market-management/rate-sources/rate-sources"}
          routes={tabRoutes}
        />
      </Box>
    </Box>
  );
}

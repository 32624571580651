import React, { useContext, useEffect, useRef, useState } from "react";
import { Amount, Token } from "james/ledger";
import { AccountOverview, Viewer } from "james/views/stellarPortfolioView";
import {
  AccountChangedNotification,
  StellarAccountPortfolioNotificationChannelName,
} from "james/stellar";
import { GroupNotificationChannel, GroupRepository } from "james/group";
import { AccountChangedNotificationTypeName } from "james/stellar/AccountChangedNotification";
import { Notification } from "james/notification/Notification";
import { useNotificationContext } from "../Notification";
import { useCurrentAPICall } from "hooks";
import { JSONRPCCallAbortedError } from "utilities/network/jsonRPCRequest";
import { FinancialCurrencyStablecoinViewReader } from "james/views/financialCurrencyStablecoinView";
import { TextExactCriterion } from "james/search/criterion";
import { ManagingCompanyClientName } from "const";
import { useErrorContext } from "context/Error";
import { useApplicationContext } from "../Application/Application";
import { Determiner, ScopeFields } from "james/search/scope/Determiner";
import { Permission } from "james/security";

interface ContextType {
  portfolioContextLoading: boolean;
  error: undefined | string;
  valuationCurrency: Token | undefined;
  // TODO add a method to change valuation currency

  pnlAmount?: Amount;
  currentValue?: Amount;
  currentAssetAllocation?: { asset: Token; percentage: string }[];
  portfolioValuationHistory?: { date: string; valuation: Amount }[];
  accountOverviews?: AccountOverview[];

  // NotEnoughData is a way to tell the user if there's insufficient data to show the UI charts
  notEnoughPortfolioValuationHistoryData?: boolean;
  notEnoughPortfolioPnlData?: boolean;
}

const Context = React.createContext({} as ContextType);

export function PortfolioContext({ children }: { children?: React.ReactNode }) {
  const { errorContextErrorTranslator } = useErrorContext();
  const [refreshPortfolioData, setRefreshPortfolioData] = useState(false);
  const refreshPortfolioDataTimeoutRef = useRef<NodeJS.Timeout | undefined>(
    undefined,
  );
  const { registerNotificationCallback } = useNotificationContext();
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const groupsRegisteredForNotifications = useRef<any>({});
  const [
    isCurrentRetrievePortfolioDataAPICall,
    initRetrievePortfolioDataAPICall,
  ] = useCurrentAPICall();
  const { viewConfiguration, userAuthenticated, authContext } =
    useApplicationContext();

  const [state, setState] = useState<ContextType>({
    portfolioContextLoading: false,
    error: undefined,
    valuationCurrency: undefined,

    pnlAmount: undefined,
    currentValue: undefined,
    currentAssetAllocation: undefined,
    portfolioValuationHistory: undefined,
    accountOverviews: undefined,

    notEnoughPortfolioPnlData: undefined,
    notEnoughPortfolioValuationHistoryData: undefined,
  });

  // clean up useEffect
  useEffect(() => {
    if (!userAuthenticated) {
      groupsRegisteredForNotifications.current = {};
      setState({
        portfolioContextLoading: false,
        error: undefined,
        valuationCurrency: undefined,

        pnlAmount: undefined,
        currentValue: undefined,
        currentAssetAllocation: undefined,
        portfolioValuationHistory: undefined,
        accountOverviews: undefined,

        notEnoughPortfolioPnlData: undefined,
        notEnoughPortfolioValuationHistoryData: undefined,
      });
    }
  }, [userAuthenticated]);

  // this useEffect registers for notifications
  useEffect(() => {
    (async () => {
      // if not logged in return
      if (!userAuthenticated) {
        return;
      }

      // only register for notification if the user has permission to view portfolio data
      if (!viewConfiguration.StellarPortfolioViewViewer) {
        return;
      }

      // determine all groups that the user has permision to view the accounts
      const searchGroupResponse = await GroupRepository.SearchGroups({
        context: authContext,
        criteria: (
          await Determiner.DetermineScopeCriteriaByRoles({
            context: authContext,
            service: new Permission({
              serviceName: "View",
              serviceProvider: Viewer.serviceProvider,
              description: "-",
            }),
            criteria: {},
            scopeFields: [ScopeFields.IDField],
            buildScopeTree: false,
          })
        ).criteria,
      });

      // for each groupID check that if we registered for notification
      // only register for groups that are not registered yet
      for (const g of searchGroupResponse.records) {
        if (!groupsRegisteredForNotifications.current[g.id]) {
          try {
            // register group for notification
            groupsRegisteredForNotifications.current[g.id] =
              await registerNotificationCallback(
                new GroupNotificationChannel({
                  groupID: g.id,
                  name: StellarAccountPortfolioNotificationChannelName,
                  private: true,
                }),
                [AccountChangedNotificationTypeName],
                (n: Notification) => {
                  if (n instanceof AccountChangedNotification) {
                    setRefreshPortfolioData((value) => !value);
                  }
                },
              );
          } catch (e) {
            console.error(
              `error registering for notifications on group channel '${StellarAccountPortfolioNotificationChannelName}' for group ${g.id}`,
            );
          }
        }
      }
    })();
  }, [userAuthenticated, registerNotificationCallback, viewConfiguration]);

  // This useEffect is used to retrieve accounts portfolio data
  useEffect(() => {
    // do nothing if not logged in
    if (!userAuthenticated) {
      return;
    }

    const { apiCallID, abortController } = initRetrievePortfolioDataAPICall();

    // only register for notification if the user has permission to view portfolio data
    if (!viewConfiguration.StellarPortfolioViewViewer) {
      return;
    }

    // set state to a loading state
    (async () => {
      if (isCurrentRetrievePortfolioDataAPICall(apiCallID)) {
        setState((v) => ({ ...v, portfolioContextLoading: true }));
      }

      clearTimeout(refreshPortfolioDataTimeoutRef.current);
      refreshPortfolioDataTimeoutRef.current = setTimeout(async () => {
        try {
          // default valuation currency is mZAR
          let valuationCurrency = state.valuationCurrency;
          if (!valuationCurrency) {
            const readFinancialCurrencyStablecoinResponse =
              await FinancialCurrencyStablecoinViewReader.Read(
                {
                  context: authContext,
                  criteria: {
                    "token.code": TextExactCriterion("mZAR"),
                    issuer: TextExactCriterion(ManagingCompanyClientName),
                  },
                },
                { signal: abortController.signal },
              );

            valuationCurrency =
              readFinancialCurrencyStablecoinResponse.models[0].token;
          }

          const viewerResponse = await Viewer.View(
            {
              context: authContext,
              valuationToken: valuationCurrency,
            },
            { signal: abortController.signal },
          );

          if (isCurrentRetrievePortfolioDataAPICall(apiCallID)) {
            setState({
              portfolioContextLoading: false,
              error: undefined,
              valuationCurrency: valuationCurrency,
              pnlAmount: viewerResponse.pnlAmount,
              currentValue: viewerResponse.currentValue,
              currentAssetAllocation: viewerResponse.currentAssetAllocation.map(
                (v) => ({ asset: v.asset, percentage: v.allocationPercentage }),
              ),
              portfolioValuationHistory:
                viewerResponse.portfolioValuationHistory.map((v) => ({
                  date: v.date,
                  valuation: v.totalPortfolioValuation,
                })),
              accountOverviews: viewerResponse.accountOverviews,
              notEnoughPortfolioPnlData:
                viewerResponse.notEnoughPortfolioPnlData,
              notEnoughPortfolioValuationHistoryData:
                viewerResponse.notEnoughPortfolioValuationHistoryData,
            });
          }
        } catch (e) {
          const err = errorContextErrorTranslator.translateError(e);
          if (err.code === JSONRPCCallAbortedError.ErrorCode) {
            return;
          }
          console.error(
            `error retrieving portfolio data: ${err.message ? err.message : err.toString()
            }`,
          );
          setState((v) => ({ ...v, error: err.message }));
        }
      }, 400);
    })();
  }, [userAuthenticated, refreshPortfolioData, viewConfiguration]);

  return <Context.Provider value={state}>{children}</Context.Provider>;
}

const usePortfolioContext = () => useContext(Context);
export { usePortfolioContext };

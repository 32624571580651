import { Context } from "james/security";
import config from "react-global-configuration";
import { Amount } from "james/ledger/Amount";
import { Asset } from "james/financial/Asset";
import { Fee, NewFee } from "james/remuneration/Fee";
import { jsonRPCRequest } from "utilities/network/jsonRPCRequest";
import { JSONRPCRequestOpts } from "utilities/network/jsonRPCRequest/jsonRPCRequest";

export interface GenerateAssetMintingFeesRequest {
  context: Context;
  asset: Asset;
  noTokensToMint: Amount;
}

export interface GenerateAssetMintingFeesResponse {
  fees: Fee[];
}

export interface GenerateAssetBurningFeesRequest {
  context: Context;
  asset: Asset;
  noTokensToBurn: Amount;
}

export interface GenerateAssetBurningFeesResponse {
  fees: Fee[];
}

export const AssetTokeniserFeeGenerator = {
  serviceProvider: "financial-AssetTokeniserFeeGenerator",
  async GenerateAssetMintingFees(
    request: GenerateAssetMintingFeesRequest,
    jsonRPCRequestOpts?: JSONRPCRequestOpts,
  ): Promise<GenerateAssetMintingFeesResponse> {
    const response = await jsonRPCRequest(
      {
        url: config.get("meshAuthenticatedURL"),
        method: `${AssetTokeniserFeeGenerator.serviceProvider}.GenerateAssetMintingFees`,
        request,
      },
      jsonRPCRequestOpts,
    );

    return { fees: response.fees.map((f: Fee) => NewFee(f)) };
  },
  async GenerateAssetBurningFees(
    request: GenerateAssetBurningFeesRequest,
    jsonRPCRequestOpts?: JSONRPCRequestOpts,
  ): Promise<GenerateAssetBurningFeesResponse> {
    const response = await jsonRPCRequest(
      {
        url: config.get("meshAuthenticatedURL"),
        method: `${AssetTokeniserFeeGenerator.serviceProvider}.GenerateAssetBurningFees`,
        request,
      },
      jsonRPCRequestOpts,
    );

    return { fees: response.fees.map((f: Fee) => NewFee(f)) };
  },
};

import {
  ScheduleConfigurationWrapper,
  scheduleConfigurationTypeToString,
} from "@mesh/common-js/dist/financial";
import { AssetflowCategory } from "@mesh/common-js/dist/financial/assetflowCategory_pb";
import { DayCountConvention } from "@mesh/common-js/dist/financial/dayCountConvention_pb";
import { ScheduleConfigurationType } from "@mesh/common-js/dist/financial/scheduleConfigurationType_pb";
import { FloatingRateSmartInstrumentLeg } from "@mesh/common-js/dist/financial/smartInstrumentLegFloatingRate_pb";
import { SmartInstrument } from "@mesh/common-js/dist/financial/smartInstrument_pb";
import { decimalToBigNumber } from "@mesh/common-js/dist/num";
import { ValidationResult } from "common/validation";
import { validateScheduleConfiguration } from "../../../ScheduleConfiguration";
import { Deferrability } from "@mesh/common-js/dist/financial/deferrability_pb";
import { validateShiftingShiftingPeriod } from "../../../ShiftingPeriodForm";

export type FloatingRateSmartInstrumentLegValidationData = {
  smartInstrument: SmartInstrument;
};

export function validateFloatingRateSmartInstrumentLeg(
  leg: FloatingRateSmartInstrumentLeg,
  data: FloatingRateSmartInstrumentLegValidationData,
): ValidationResult {
  // prepare validation result
  const validationResult: ValidationResult = {
    // assumed to true -
    // any error must set to false
    valid: true,
    // contains field validations
    fieldValidations: {},
  };
  const getFieldValidationPrefix = () =>
    `floatingRateSmartInstrumentLeg-${leg.getId()}`;
  const notValid = (field: string, helperText: string) => {
    validationResult.valid = false;
    validationResult.fieldValidations[
      `${getFieldValidationPrefix()}-${field}`
    ] = helperText;
  };

  // perform necessary conversions
  const notionalAmount = leg.getNotional()?.getValue();
  const referenceRateFactor = leg.getReferenceratefactor();
  const rateSpread = leg.getSpread();
  const rateFloor = leg.getFloor();
  const wrappedScheduleConfiguration = new ScheduleConfigurationWrapper(
    leg.getScheduleconfiguration(),
  );

  //perform validations
  if (leg.getName() === "") {
    notValid("name", "Must be set");
  } else if (leg.getName().length < 3) {
    notValid("name", "Must be at least 3 characters");
  } else if (
    // placeholder for actual name validation
    leg.getName() === "Non-unique name"
  ) {
    notValid("name", "A leg with this name already exists");
  }

  if (
    !decimalToBigNumber(notionalAmount).isPositive() &&
    !decimalToBigNumber(notionalAmount).isNegative()
  ) {
    notValid("notionalAmount", "Invalid character");
  } else if (decimalToBigNumber(notionalAmount).isLessThanOrEqualTo(0)) {
    notValid("notionalAmount", "Must be greater than 0");
  }

  if (
    leg.getAssetflowcategory() ===
    AssetflowCategory.UNDEFINED_ASSETFLOW_CATEGORY
  ) {
    notValid("assetFlowCategory", "Must be set");
  }

  if (leg.getDeferrability() === Deferrability.UNDEFINED_DEFERRABILITY) {
    notValid("deferrability", "Must be set");
  }

  if (leg.getReferenceratesourceid() === "") {
    notValid("rateSource", "Must be set");
  }

  if (
    !decimalToBigNumber(referenceRateFactor).isPositive() &&
    !decimalToBigNumber(referenceRateFactor).isNegative()
  ) {
    notValid("referenceRateFactor", "Invalid character");
  } else if (!decimalToBigNumber(referenceRateFactor).isPositive()) {
    notValid("referenceRateFactor", "Must be greater than 0");
  }

  if (
    !decimalToBigNumber(rateSpread).isPositive() &&
    !decimalToBigNumber(rateSpread).isNegative()
  ) {
    notValid("rateSpread", "Invalid character");
  }

  if (
    !decimalToBigNumber(rateFloor).isPositive() &&
    !decimalToBigNumber(rateFloor).isNegative()
  ) {
    notValid("rateFloor", "Invalid character");
  } else if (!decimalToBigNumber(rateFloor).isPositive()) {
    notValid("rateFloor", "Must be greater than 0");
  }

  if (
    leg.getDaycountconvention() ===
    DayCountConvention.UNDEFINED_DAY_COUNT_CONVENTION
  ) {
    notValid("dayCountConvention", "Must be set");
  }

  if (
    wrappedScheduleConfiguration.scheduleConfigurationType ===
    ScheduleConfigurationType.UNDEFINED_SCHEDULE_CONFIGURATION_TYPE
  ) {
    notValid("scheduleConfiguationType", "Must be set");
  } else if (
    wrappedScheduleConfiguration.scheduleConfigurationType !==
    ScheduleConfigurationType.NON_PERPETUAL_SCHEDULE_CONFIGURATION_TYPE
  ) {
    notValid(
      "scheduleConfiguationType",
      `Only ${scheduleConfigurationTypeToString(ScheduleConfigurationType.NON_PERPETUAL_SCHEDULE_CONFIGURATION_TYPE)} supported`,
    );
  } else {
    const scheduleConfiguration = leg.getScheduleconfiguration();
    if (!scheduleConfiguration) {
      throw new TypeError("schedule configuration is not set");
    }
    const scheduleValidationResult = validateScheduleConfiguration(
      scheduleConfiguration,
      data,
      getFieldValidationPrefix(),
    );
    validationResult.valid =
      scheduleValidationResult.valid && validationResult.valid;
    for (const field in scheduleValidationResult.fieldValidations) {
      validationResult.fieldValidations[field] =
        scheduleValidationResult.fieldValidations[field];
    }
  }

  const resetPeriod = leg.getRateresetperiod();
  if (!resetPeriod) {
    throw new TypeError("reset period is not set");
  } else {
    const periodValidationResult = validateShiftingShiftingPeriod(
      resetPeriod,
      getFieldValidationPrefix() + "-rateResetPeriod",
    );
    validationResult.valid =
      periodValidationResult.valid && validationResult.valid;
    for (const field in periodValidationResult.fieldValidations) {
      validationResult.fieldValidations[field] =
        periodValidationResult.fieldValidations[field];
    }
  }

  const recordPeriod = leg.getRecordperiod();
  if (!recordPeriod) {
    throw new TypeError("record period is not set");
  } else {
    const periodValidationResult = validateShiftingShiftingPeriod(
      recordPeriod,
      getFieldValidationPrefix() + "-recordPeriod",
    );
    validationResult.valid =
      periodValidationResult.valid && validationResult.valid;
    for (const field in periodValidationResult.fieldValidations) {
      validationResult.fieldValidations[field] =
        periodValidationResult.fieldValidations[field];
    }
  }

  return validationResult;
}

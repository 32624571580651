/**
 * @fileoverview gRPC-Web generated client stub for stellarSourceAccountView
 * @enhanceable
 * @public
 */

// Code generated by protoc-gen-grpc-web. DO NOT EDIT.
// versions:
// 	protoc-gen-grpc-web v1.5.0
// 	protoc              v5.28.0
// source: views/stellarSourceAccountView/modelReader.proto


/* eslint-disable */
// @ts-nocheck



const grpc = {};
grpc.web = require('grpc-web');


var security_context_pb = require('../../security/context_pb.js')

var search_criterion_pb = require('../../search/criterion_pb.js')

var search_query_pb = require('../../search/query_pb.js')

var views_stellarSourceAccountView_model_pb = require('../../views/stellarSourceAccountView/model_pb.js')
const proto = {};
proto.stellarSourceAccountView = require('./modelReader_pb.js');

/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?grpc.web.ClientOptions} options
 * @constructor
 * @struct
 * @final
 */
proto.stellarSourceAccountView.ModelReaderClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options.format = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname.replace(/\/+$/, '');

};


/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?grpc.web.ClientOptions} options
 * @constructor
 * @struct
 * @final
 */
proto.stellarSourceAccountView.ModelReaderPromiseClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options.format = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname.replace(/\/+$/, '');

};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.stellarSourceAccountView.ReadOneModelRequest,
 *   !proto.stellarSourceAccountView.ReadOneModelResponse>}
 */
const methodDescriptor_ModelReader_ReadOneModel = new grpc.web.MethodDescriptor(
  '/stellarSourceAccountView.ModelReader/ReadOneModel',
  grpc.web.MethodType.UNARY,
  proto.stellarSourceAccountView.ReadOneModelRequest,
  proto.stellarSourceAccountView.ReadOneModelResponse,
  /**
   * @param {!proto.stellarSourceAccountView.ReadOneModelRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.stellarSourceAccountView.ReadOneModelResponse.deserializeBinary
);


/**
 * @param {!proto.stellarSourceAccountView.ReadOneModelRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.stellarSourceAccountView.ReadOneModelResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.stellarSourceAccountView.ReadOneModelResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.stellarSourceAccountView.ModelReaderClient.prototype.readOneModel =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/stellarSourceAccountView.ModelReader/ReadOneModel',
      request,
      metadata || {},
      methodDescriptor_ModelReader_ReadOneModel,
      callback);
};


/**
 * @param {!proto.stellarSourceAccountView.ReadOneModelRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.stellarSourceAccountView.ReadOneModelResponse>}
 *     Promise that resolves to the response
 */
proto.stellarSourceAccountView.ModelReaderPromiseClient.prototype.readOneModel =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/stellarSourceAccountView.ModelReader/ReadOneModel',
      request,
      metadata || {},
      methodDescriptor_ModelReader_ReadOneModel);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.stellarSourceAccountView.ReadManyModelRequest,
 *   !proto.stellarSourceAccountView.ReadManyModelResponse>}
 */
const methodDescriptor_ModelReader_ReadManyModel = new grpc.web.MethodDescriptor(
  '/stellarSourceAccountView.ModelReader/ReadManyModel',
  grpc.web.MethodType.UNARY,
  proto.stellarSourceAccountView.ReadManyModelRequest,
  proto.stellarSourceAccountView.ReadManyModelResponse,
  /**
   * @param {!proto.stellarSourceAccountView.ReadManyModelRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.stellarSourceAccountView.ReadManyModelResponse.deserializeBinary
);


/**
 * @param {!proto.stellarSourceAccountView.ReadManyModelRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.stellarSourceAccountView.ReadManyModelResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.stellarSourceAccountView.ReadManyModelResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.stellarSourceAccountView.ModelReaderClient.prototype.readManyModel =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/stellarSourceAccountView.ModelReader/ReadManyModel',
      request,
      metadata || {},
      methodDescriptor_ModelReader_ReadManyModel,
      callback);
};


/**
 * @param {!proto.stellarSourceAccountView.ReadManyModelRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.stellarSourceAccountView.ReadManyModelResponse>}
 *     Promise that resolves to the response
 */
proto.stellarSourceAccountView.ModelReaderPromiseClient.prototype.readManyModel =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/stellarSourceAccountView.ModelReader/ReadManyModel',
      request,
      metadata || {},
      methodDescriptor_ModelReader_ReadManyModel);
};


module.exports = proto.stellarSourceAccountView;


import { Model as LedgerTokenViewModel } from "james/views/ledgerTokenView";
import { DigitalInstrumentFirstTimeMintingFee } from "james/remuneration/DigitalInstrumentFirstTimeMintingFee";
import { DigitalInstrumentTokenMintingFee } from "james/remuneration/DigitalInstrumentTokenMintingFee";
import { Amount } from "james/ledger";
import { BigNumber } from "bignumber.js";

export const AllFeeNames: string[] = [
  new DigitalInstrumentFirstTimeMintingFee().feeName(),
  new DigitalInstrumentTokenMintingFee().feeName(),
];

export const ModelTypeName = "mesh::remunerationFeeView/Model";

export class Model {
  ["@type"]: string = ModelTypeName;

  public id = "";

  public feeID = "";

  public responsibleGroupName = "";

  public responsibleGroupsClientName = "";

  public feeName = "";

  public description = "";

  public feeAmount: Amount = new Amount();

  public vatRate: BigNumber = BigNumber("0");

  public feePaidInTokenViewModel: LedgerTokenViewModel =
    new LedgerTokenViewModel();

  public state = "";

  constructor(model?: Model) {
    if (!model) {
      return;
    }
    this.id = model.id;
    this.feeID = model.feeID;
    this.responsibleGroupName = model.responsibleGroupName;
    this.responsibleGroupsClientName = model.responsibleGroupsClientName;
    this.feeName = model.feeName;
    this.description = model.description;
    this.feeAmount = new Amount(model.feeAmount);
    this.vatRate = BigNumber(model.vatRate);
    this.feePaidInTokenViewModel = new LedgerTokenViewModel(
      model.feePaidInTokenViewModel,
    );
    this.state = model.state;
  }
}

import React, { useCallback, useEffect, useState } from "react";
import { styled } from "@mui/material/styles";
import {
  alpha,
  Autocomplete,
  Button,
  CircularProgress,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  InputAdornment,
  Skeleton,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import {
  Check as AllGoodIcon,
  Close as CloseIcon,
  Face as FaceIcon,
  InfoOutlined as InfoIcon,
  Search as SearchIcon,
  Waves as LiquidityPoolsIcon,
} from "@mui/icons-material";
import {
  Model as TokenViewModel,
  useRead as userLedgerTokenViewRead,
} from "james/views/ledgerTokenView";
import {
  TextListCriterion,
  TextNEExactCriterion,
  TextSubstringCriterion,
} from "james/search/criterion";
import { TokenIconViewUpload } from "components/Ledger/Token";
import { Query } from "james/search/query";
import { useSnackbar } from "notistack";
import { AllStellarNetworks } from "james/stellar";
import { TokenCategory } from "james/views/ledgerTokenView/Model";
import { useApplicationContext } from "context/Application/Application";
import { Client } from "james/client";
import { useErrorContext } from "context/Error";
import { useAPIContext } from "context/API";
import { RegisterLiquidityPoolForTokensRequest } from "@mesh/common-js/dist/stellar/liquidityPoolRegistrar_pb";
import { CheckForLiquidityPoolRegistrationByTokensRequest } from "@mesh/common-js/dist/stellar/liquidityPoolRegistrationInspector_pb";
import { futureTokenToStellarToken } from "@mesh/common-js/dist/ledger/token";

const PREFIX = "RegisterDialog";

const classes = {
  dialogTitleRootOverride: `${PREFIX}-dialogTitleRootOverride`,
  searchIcon: `${PREFIX}-searchIcon`,
  selectFormField: `${PREFIX}-selectFormField`,
  phaseHeaderCardRoot: `${PREFIX}-phaseHeaderCardRoot`,
  splashCardContent: `${PREFIX}-splashCardContent`,
  splashBody: `${PREFIX}-splashBody`,
  splashIcon: `${PREFIX}-splashIcon`,
  noDataSlashErrorIcon: `${PREFIX}-noDataSlashErrorIcon`,
  iconSuccess: `${PREFIX}-iconSuccess`,
  row: `${PREFIX}-row`,
};

const StyledDialog = styled(Dialog)(({ theme }) => ({
  [`& .${classes.dialogTitleRootOverride}`]: {
    display: "grid",
    gridTemplateColumns: "1fr auto",
    padding: `${theme.spacing(1)} ${theme.spacing(1)} ${theme.spacing(
      1,
    )} ${theme.spacing(2)}`,
    alignItems: "center",
  },

  [`& .${classes.searchIcon}`]: {
    color: theme.palette.text.tertiary,
  },

  [`& .${classes.selectFormField}`]: {
    width: 400,
  },

  [`& .${classes.phaseHeaderCardRoot}`]: {
    marginTop: -3,
    display: "grid",
    gridTemplateColumns: "1fr auto",
    alignItems: "center",
    columnGap: theme.spacing(1),
  },

  // no data splash
  [`& .${classes.splashCardContent}`]: {
    height: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },

  [`& .${classes.splashBody}`]: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    gap: theme.spacing(2),
  },

  [`& .${classes.splashIcon}`]: {
    fontSize: 110,
    color: alpha(theme.palette.background.default, 0.5),
  },

  [`& .${classes.noDataSlashErrorIcon}`]: {
    color: theme.palette.error.light,
  },

  [`& .${classes.iconSuccess}`]: {
    color: theme.palette.success.light,
  },

  [`& .${classes.row}`]: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    gap: theme.spacing(2),
  },
}));

export type RegisterDialogProps = {
  closeDialog: () => void;
  onRegister: () => void;
};

enum DialogState {
  SelectingTokens,
  RegistrationExists,
  RegistrationDoesntExist,
}

const defaultCriteria = {
  "token.network": TextListCriterion(AllStellarNetworks),
  tokenCategory: TextNEExactCriterion(TokenCategory.LiquidityPoolShares),
};

export function RegisterDialog(props: RegisterDialogProps) {
  const {
    stellar: { liquidityPoolRegistrationInspector, liquidityPoolRegistrar },
  } = useAPIContext();
  const { authContext, myClient, myClientRetrievalErr } =
    useApplicationContext();
  const { enqueueSnackbar } = useSnackbar();
  const { errorContextErrorTranslator, errorContextDefaultErrorFeedback } =
    useErrorContext();
  const {
    readResponse: token1LedgerTokenViewReadResponse,
    readRequest: token1LedgerTokenViewReadRequest,
    setReadRequest: token1SetLedgerTokenViewReadRequest,
    loading: token1LedgerTokenViewReadLoading,
  } = userLedgerTokenViewRead({
    query: new Query({
      ...new Query(),
      limit: 10,
    }),
    criteria: defaultCriteria,
  });

  const {
    readResponse: token2LedgerTokenViewReadResponse,
    readRequest: token2LedgerTokenViewReadRequest,
    setReadRequest: token2SetLedgerTokenViewReadRequest,
    loading: token2LedgerTokenViewReadLoading,
  } = userLedgerTokenViewRead({
    query: new Query({
      ...new Query(),
      limit: 10,
    }),
    criteria: defaultCriteria,
  });

  const [token1, setToken1] = useState<TokenViewModel | null>(null);
  const [token2, setToken2] = useState<TokenViewModel | null>(null);
  const [checkingRegistration, setCheckingRegistration] = useState(false);
  const [dialogState, setDialogState] = useState<DialogState>(
    DialogState.SelectingTokens,
  );
  useEffect(() => {
    (async () => {
      // do nothing if tokens not set
      if (!(token1 && token2)) {
        setDialogState(DialogState.SelectingTokens);
        return;
      }

      // do nothing if selected tokens are the same
      if (token1.token.isEqualTo(token2.token)) {
        setDialogState(DialogState.SelectingTokens);
        return;
      }

      // check liquidity pool registration
      setCheckingRegistration(true);

      if (myClientRetrievalErr && !myClient) {
        errorContextDefaultErrorFeedback(myClientRetrievalErr);
        setDialogState(DialogState.SelectingTokens);
        return;
      }

      if (!myClient) {
        return;
      }

      try {
        const registrationExists = (
          await liquidityPoolRegistrationInspector.checkForLiquidityPoolRegistrationByTokens(
            new CheckForLiquidityPoolRegistrationByTokensRequest()
              .setContext(authContext.toFuture())
              .setToken1(
                futureTokenToStellarToken(token1.token.toFutureToken()),
              )
              .setToken2(
                futureTokenToStellarToken(token2.token.toFutureToken()),
              ),
          )
        ).getExists();
        setDialogState(
          registrationExists
            ? DialogState.RegistrationExists
            : DialogState.RegistrationDoesntExist,
        );
      } catch (e) {
        const err = errorContextErrorTranslator.translateError(e);
        console.error(
          `error checking liquidity pool registration: ${
            err.message ? err.message : err.toString()
          }`,
        );
        enqueueSnackbar(
          `error checking liquidity pool registration: ${
            err.message ? err.message : err.toString()
          }`,
          { variant: "error" },
        );
        setDialogState(DialogState.SelectingTokens);
      }
      setCheckingRegistration(false);
    })();
  }, [token1, token2, authContext, myClient, enqueueSnackbar]);

  const [registrationInProgress, setRegistrationInProgress] = useState(false);
  const handleRegister = useCallback(async () => {
    if (!(token1 && token2)) {
      return;
    }
    setRegistrationInProgress(true);
    try {
      await liquidityPoolRegistrar.registerLiquidityPoolForTokens(
        new RegisterLiquidityPoolForTokensRequest()
          .setContext(authContext.toFuture())
          .setOwnerid(new Client(myClient).ownerID)
          .setToken1(futureTokenToStellarToken(token1.token.toFutureToken()))
          .setToken2(futureTokenToStellarToken(token2.token.toFutureToken())),
      );
      enqueueSnackbar("Liquidity Pool Registered", { variant: "success" });
      props.onRegister();
      props.closeDialog();
      return;
    } catch (e) {
      const err = errorContextErrorTranslator.translateError(e);
      console.error(`error registering liquidity pool`, e);
      enqueueSnackbar(`Error Registering Liquidity Pool: ${err.message}`, {
        variant: "error",
      });
    }
    setRegistrationInProgress(false);
  }, [myClient, token1, token2, authContext, enqueueSnackbar, props]);

  return (
    <StyledDialog open maxWidth="lg">
      <DialogTitle classes={{ root: classes.dialogTitleRootOverride }}>
        <Grid container direction="row" spacing={1} alignItems="center">
          <Grid item>
            <LiquidityPoolsIcon />
          </Grid>
          <Grid item>
            <Typography variant="h5" children="Register Liquidity Pool" />
          </Grid>
          {registrationInProgress && (
            <Grid item>
              <CircularProgress size={20} />
            </Grid>
          )}
        </Grid>
        <Grid container direction="row" spacing={1} alignItems="center">
          <Grid item>
            <Tooltip title="Close" placement="top">
              <IconButton
                id="stellarRegisterLiquidityPoolDialog-close-iconButton"
                size="small"
                disabled={
                  token1LedgerTokenViewReadLoading ||
                  token2LedgerTokenViewReadLoading ||
                  checkingRegistration ||
                  registrationInProgress
                }
                onClick={props.closeDialog}
              >
                <CloseIcon />
              </IconButton>
            </Tooltip>
          </Grid>
        </Grid>
      </DialogTitle>
      <DialogContent>
        <Grid container direction="column" alignItems="center" spacing={2}>
          <Grid item>
            <Typography
              variant="subtitle1"
              children="Select a token pair to identify a liquidity pool to register"
            />
          </Grid>
          <Grid item>
            <Typography
              color="textSecondary"
              variant="subtitle2"
              children="Order does not matter - tokens will be sorted according to protocol roles"
            />
          </Grid>
          <Grid item>
            <Autocomplete
              isOptionEqualToValue={(option, value) => option === value}
              id="stellarRegisterLiquidityPoolDialog-token1-autoComplete"
              disabled={registrationInProgress}
              getOptionLabel={(option: TokenViewModel) =>
                `${option.token.code} - ${option.issuer}`
              }
              options={token1LedgerTokenViewReadResponse.models}
              loading={token1LedgerTokenViewReadLoading}
              onChange={(a, selected: TokenViewModel | null) => {
                setToken1(selected);
              }}
              value={token1}
              onInputChange={(e, newInputValue) => {
                if (newInputValue === "") {
                  token1SetLedgerTokenViewReadRequest({
                    ...token1LedgerTokenViewReadRequest,
                    criteria: defaultCriteria,
                  });
                } else {
                  token1SetLedgerTokenViewReadRequest({
                    ...token1LedgerTokenViewReadRequest,
                    criteria: {
                      ...defaultCriteria,
                      "token.code": TextSubstringCriterion(newInputValue),
                    },
                  });
                }
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  id="stellarRegisterLiquidityPoolDialog-token1-autoCompleteTextField"
                  fullWidth
                  InputProps={{
                    ...params.InputProps,
                    placeholder: "Select...",
                    className: classes.selectFormField,
                    startAdornment: token1 ? (
                      <InputAdornment position="start">
                        <TokenIconViewUpload size={23} token={token1.token} />
                      </InputAdornment>
                    ) : undefined,
                    endAdornment: (
                      <>
                        {token1LedgerTokenViewReadLoading ? (
                          <CircularProgress color="inherit" size={20} />
                        ) : null}
                        <SearchIcon className={classes.searchIcon} />
                      </>
                    ),
                  }}
                />
              )}
            />
          </Grid>
          <Grid item>
            <Autocomplete
              isOptionEqualToValue={(option, value) => option === value}
              id="stellarRegisterLiquidityPoolDialog-token2-autoComplete"
              disabled={registrationInProgress}
              getOptionLabel={(option: TokenViewModel) =>
                `${option.token.code} - ${option.issuer}`
              }
              options={token2LedgerTokenViewReadResponse.models}
              loading={token2LedgerTokenViewReadLoading}
              onChange={(a, selected: TokenViewModel | null) => {
                setToken2(selected);
              }}
              onInputChange={(e, newInputValue) => {
                if (newInputValue === "") {
                  token2SetLedgerTokenViewReadRequest({
                    ...token2LedgerTokenViewReadRequest,
                    criteria: defaultCriteria,
                  });
                } else {
                  token2SetLedgerTokenViewReadRequest({
                    ...token2LedgerTokenViewReadRequest,
                    criteria: {
                      ...defaultCriteria,
                      "token.code": TextSubstringCriterion(newInputValue),
                    },
                  });
                }
              }}
              value={token2}
              renderInput={(params) => (
                <TextField
                  {...params}
                  id="stellarRegisterLiquidityPoolDialog-token2-autoCompleteTextField"
                  fullWidth
                  InputProps={{
                    ...params.InputProps,
                    placeholder: "Select...",
                    className: classes.selectFormField,
                    startAdornment: token2 ? (
                      <InputAdornment position="start">
                        <TokenIconViewUpload size={23} token={token2.token} />
                      </InputAdornment>
                    ) : undefined,
                    endAdornment: (
                      <>
                        {token2LedgerTokenViewReadLoading ? (
                          <CircularProgress color="inherit" size={20} />
                        ) : null}
                        <SearchIcon className={classes.searchIcon} />
                      </>
                    ),
                  }}
                />
              )}
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogContent>
        {(() => {
          // if a token pair is not selected render a splash to that effect
          if (!(token1 && token2)) {
            return (
              <div className={classes.splashCardContent}>
                <div className={classes.splashBody}>
                  <FaceIcon className={classes.splashIcon} />
                  <Typography
                    color="textSecondary"
                    variant="h4"
                    children="Nothing to show"
                  />
                  <Typography
                    color="textSecondary"
                    variant="body2"
                    children="Liquidity pool detail will show here once a token pair is set"
                  />
                </div>
              </div>
            );
          }

          // if both tokens selected are the same render a splash to that effect
          if (token1.token.isEqualTo(token2.token)) {
            return (
              <div className={classes.splashCardContent}>
                <div className={classes.splashBody}>
                  <InfoIcon className={classes.splashIcon} />
                  <Typography
                    color="textSecondary"
                    variant="h4"
                    children="Nothing to show"
                  />
                  <Typography
                    color="textSecondary"
                    variant="body2"
                    id="stellarRegisterLiquidityPoolDialog-select2DifferentTokens-typography"
                    children="Select 2 different tokens"
                  />
                </div>
              </div>
            );
          }

          // render skeleton while loading
          if (checkingRegistration) {
            return <Skeleton width="100%" height={200} />;
          }

          switch (dialogState) {
            case DialogState.SelectingTokens:
              return null;

            case DialogState.RegistrationExists:
              return (
                <div className={classes.splashCardContent}>
                  <div className={classes.splashBody}>
                    <Typography
                      color="textSecondary"
                      variant="h4"
                      children="Nothing to do"
                    />
                    <div className={classes.row}>
                      <AllGoodIcon className={classes.iconSuccess} />
                      <Typography
                        id="stellarRegisterLiquidityPoolDialog-poolAlreadyRegistered-typography"
                        color="textSecondary"
                        variant="body2"
                        children="Pool already registered"
                      />
                    </div>
                  </div>
                </div>
              );

            case DialogState.RegistrationDoesntExist:
              return (
                <div className={classes.splashCardContent}>
                  <div className={classes.splashBody}>
                    <FaceIcon className={classes.splashIcon} />
                    <Typography
                      color="textSecondary"
                      variant="h4"
                      children="Pool not registered"
                    />
                    <Button
                      id="stellarRegisterLiquidityPoolDialog-register-button"
                      variant="contained"
                      disabled={registrationInProgress}
                      color="primary"
                      children="register"
                      onClick={handleRegister}
                    />
                  </div>
                </div>
              );

            default:
              return null;
          }
        })()}
      </DialogContent>
    </StyledDialog>
  );
}

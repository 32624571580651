"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.TokenWrapper = void 0;
const bignumber_js_1 = require("bignumber.js");
const futureToken_pb_1 = require("./futureToken_pb");
const amount_1 = require("./amount");
const futureNetwork_pb_1 = require("./futureNetwork_pb");
/**
 * Class representing a wrapper around a FutureAmount.
 *
 * The AmountWrapper class provides convenience methods to manipulate and retrieve the
 * FutureAmount instance it wraps, along with its associated FutureToken and value.
 */
class TokenWrapper {
    /**
     * Constructs an instance of the TokenWrapper class.
     *
     * This constructor initializes the TokenWrapper with a given FutureToken.
     * The token argument is set to option (i.e. could be undefined) as objects
     * returned by protobuf types are typically optionally undefined.
     *
     * @param {FutureToken} [token] - The token to be wrapped. Must be defined.
     */
    constructor(token) {
        var _a, _b, _c;
        this._token = new futureToken_pb_1.FutureToken()
            .setCode((_a = token === null || token === void 0 ? void 0 : token.getCode()) !== null && _a !== void 0 ? _a : "")
            .setIssuer((_b = token === null || token === void 0 ? void 0 : token.getIssuer()) !== null && _b !== void 0 ? _b : "")
            .setNetwork((_c = token === null || token === void 0 ? void 0 : token.getNetwork()) !== null && _c !== void 0 ? _c : futureNetwork_pb_1.FutureNetwork.UNDEFINED_NETWORK);
    }
    get code() {
        return this._token.getCode();
    }
    getCode() {
        return this.code;
    }
    get issuer() {
        return this._token.getIssuer();
    }
    getIssuer() {
        return this.issuer;
    }
    get network() {
        return this._token.getNetwork();
    }
    getNetwork() {
        return this.network;
    }
    /**
     * Retrieves the wrapped FutureToken.
     *
     * This getter provides access to the FutureToken instance that is wrapped by this class.
     *
     * @returns {FutureToken} The wrapped FutureToken.
     */
    get token() {
        return this._token;
    }
    /**
     * Creates a new FutureAmount instance based on the given value.
     *
     * This method accepts a value of type BigNumber or Decimal and returns a new FutureAmount
     * instance created from this value, associated with the wrapped FutureToken.
     *
     * @param {BigNumber | Decimal} value - The value to convert into a FutureAmount. It can be either a BigNumber or a Decimal.
     *
     * @returns {FutureAmount} A new FutureAmount instance based on the provided value and the wrapped FutureToken.
     */
    newAmountOf(value) {
        if (bignumber_js_1.BigNumber.isBigNumber(value)) {
            return (0, amount_1.newAmountFromBigNumber)(value, this._token);
        }
        return (0, amount_1.newAmountFromDecimal)(value, this._token);
    }
    isEqualTo(t2) {
        return (this.code === t2.getCode() &&
            this.issuer === t2.getIssuer() &&
            this.network === t2.getNetwork());
    }
}
exports.TokenWrapper = TokenWrapper;

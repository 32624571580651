import { BigNumber } from "bignumber.js";

export class Holding {
  public name = "";

  public percentage: BigNumber = new BigNumber("0");

  constructor(holding?: Holding) {
    if (!holding) {
      return;
    }
    this.name = holding.name;
    this.percentage = new BigNumber(holding.percentage);
  }
}

import { allTimezones, timezoneToString } from "@mesh/common-js/dist/i8n";
import { Timezone } from "@mesh/common-js/dist/i8n/timezone_pb";
import { LoadingButton } from "@mui/lab";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  TextField,
  MenuItem,
  DialogActions,
  Tooltip,
  TextareaAutosize,
  Typography,
  useTheme,
  IconButton,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { Box } from "@mui/system";
import { useAPIContext } from "context/API";
import { useApplicationContext } from "context/Application/Application";
import { useSnackbar } from "notistack";
import React, { useState } from "react";
import { formDataValidationFunc, formUpdaterSpecs } from "./useValidatedForm";
import { useValidatedForm } from "hooks/useForm";
import { RateSource } from "@mesh/common-js/dist/financial/rateSource_pb";
import { NewRateSourceRequest } from "@mesh/common-js/dist/financial/rateSourceCreator_pb";

type RateSourceCRUDDialogProps = {
  closeDialog: () => void;
  onRateSourceSaved: () => void;
};

export const RateSourceCRUDDialog = (props: RateSourceCRUDDialogProps) => {
  const theme = useTheme();
  const { authContext } = useApplicationContext();
  const {
    financial: { rateSourceCreator },
  } = useAPIContext();
  const { enqueueSnackbar } = useSnackbar();
  const [formData, formDataValidationResult, formUpdater] = useValidatedForm(
    formDataValidationFunc,
    undefined,
    formUpdaterSpecs,
    { rateSource: new RateSource().setTimezone(Timezone.SAST_TIMEZONE) },
    new Set<string>([]),
  );

  const [loading, setLoading] = useState(false);
  const handleSave = async () => {
    setLoading(true);
    try {
      await rateSourceCreator.newRateSource(
        new NewRateSourceRequest()
          .setContext(authContext.toFuture())
          .setName(formData.rateSource.getName())
          .setDescription(formData.rateSource.getDescription())
          .setTimezone(formData.rateSource.getTimezone()),
      );
      enqueueSnackbar("Rate Source Created!", { variant: "success" });
      props.onRateSourceSaved();
      props.closeDialog();
    } catch (e) {
      console.error(`error creating new rate source: ${e}`);
      enqueueSnackbar(`error creating new rate source: ${e}`, {
        variant: "error",
      });
    }
    setLoading(false);
  };

  return (
    <Dialog open onClose={props.closeDialog}>
      <DialogTitle>
        Create Rate Source
        <Box sx={{ alignSelf: "center" }}>
          <IconButton
            id={"rateSourceCRUDDialog-close-iconButton"}
            size={"small"}
            onClick={props.closeDialog}
          >
            <CloseIcon />
          </IconButton>
        </Box>
      </DialogTitle>
      <DialogContent
        sx={(theme) => ({
          display: "flex",
          flexDirection: "column",
          gap: theme.spacing(2),
        })}
      >
        <TextField
          id={"rateSourceCRUDDialog-name-textField"}
          fullWidth
          sx={{ maxWidth: 320, mt: 3 }}
          label="Name"
          disabled={loading}
          placeholder="Enter a name"
          value={formData.rateSource.getName()}
          onChange={(e) => formUpdater.name(e.target.value)}
          error={!!formDataValidationResult.fieldValidations.name}
          helperText={formDataValidationResult.fieldValidations.name}
        />
        <Box>
          <Typography variant="body2" color="textSecondary">
            Description
          </Typography>
          <TextareaAutosize
            minRows={5}
            maxRows={5}
            disabled={loading}
            value={formData.rateSource.getDescription()}
            onChange={(e) => {
              let newValue: string = e.target.value;
              if (newValue.length >= 250) {
                newValue = newValue.slice(0, 250);
              }
              formUpdater.description(newValue);
            }}
            placeholder="Enter a description."
            style={{
              color: theme.palette.text.primary,
              backgroundColor: theme.palette.background.paper,
              width: 400,
              maxWidth: 400,
            }}
          />
          {formDataValidationResult.fieldValidations.description ? (
            <Typography variant="body2" color="textSecondary">
              {formDataValidationResult.fieldValidations.description}
            </Typography>
          ) : (
            <Typography variant="body2" color="textSecondary">
              {250 - formData.rateSource.getDescription().length} Characters
              Left
            </Typography>
          )}
        </Box>
        <TextField
          id="rateSourceCRUDDialog-timezone-select"
          label="Timezone"
          disabled={loading}
          fullWidth
          sx={{ maxWidth: 290 }}
          select
          value={formData.rateSource.getTimezone()}
          onChange={(e) => formUpdater.timezone(Number(e.target.value))}
          error={!!formDataValidationResult.fieldValidations.timezone}
          helperText={formDataValidationResult.fieldValidations.timezone}
        >
          {allTimezones
            .filter((tz) => tz != Timezone.UNDEFINED_TIMEZONE)
            .map((tz, idx) => (
              <MenuItem key={idx} value={tz}>
                {timezoneToString(tz)}
              </MenuItem>
            ))}
        </TextField>
      </DialogContent>
      <DialogActions>
        <Tooltip title={formDataValidationResult.valid ? "" : "Complete form"}>
          <span>
            <LoadingButton
              color="primary"
              variant="contained"
              onClick={handleSave}
              loading={loading}
              disabled={!formDataValidationResult.valid}
            >
              Save
            </LoadingButton>
          </span>
        </Tooltip>
      </DialogActions>
    </Dialog>
  );
};

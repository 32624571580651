import {
  smartInstrumentAttributeTypeToString,
  allFiatCurrencyCodes,
  fiatCurrencyCodeToString,
} from "@mesh/common-js/dist/financial";
import {
  FiatCurrencyAllocationsSmartInstrumentAttribute,
  SmartInstrumentFiatCurrencyAllocationEntry,
} from "@mesh/common-js/dist/financial/smartInstrumentAttributeFiatCurrencyAllocations_pb";
import {
  bigNumberToDecimal,
  decimalToBigNumber,
} from "@mesh/common-js/dist/num";
import {
  IconButton,
  InputAdornment,
  MenuItem,
  Tooltip,
  Typography,
} from "@mui/material";
import { Box } from "@mui/system";
import { BigNumber } from "bignumber.js";
import React from "react";
import { Close as DeleteIcon } from "@mui/icons-material";
import { TextField, TextNumField } from "@mesh/common-js-react/dist/FormFields";
import { ValidationResult } from "common/validation";
import { SmartInstrumentAttributeType } from "@mesh/common-js/dist/financial/smartInstrumentAttributeType_pb";
import { FiatCurrencyCode } from "@mesh/common-js/dist/financial/fiatCurrencyCode_pb";

export type FiatCurrencyAllocationsAttributeFormProps = {
  fiatCurrencyAllocationsAttribute: FiatCurrencyAllocationsSmartInstrumentAttribute;
  onChange: (
    updatedFiatCurrencyAllocationsAttribute: FiatCurrencyAllocationsSmartInstrumentAttribute,
  ) => void;
  disabled: boolean;
  readOnly: boolean;
  formDataValidationResult: ValidationResult;
};

export const FiatCurrencyAllocationsAttributeForm = (
  props: FiatCurrencyAllocationsAttributeFormProps,
) => {
  const entries = props.fiatCurrencyAllocationsAttribute.getEntriesList();

  // sum total allocation
  const totalAllocation = entries.reduce(
    (sum, entry) => sum.plus(decimalToBigNumber(entry.getAmount())),
    BigNumber("0"),
  );

  const fieldValidationResult = (field: string) => {
    return props.formDataValidationResult.fieldValidations[
      `${smartInstrumentAttributeTypeToString(SmartInstrumentAttributeType.FIAT_CURRENCY_ALLOCATIONS_SMART_INSTRUMENT_ATTRIBUTE_TYPE)}-${field}`
    ];
  };

  return (
    <Box sx={{ width: 400 }}>
      <Box
        sx={(theme) => ({
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          gap: theme.spacing(1),
        })}
      >
        <Typography
          sx={(theme) => ({ fontWeight: theme.typography.fontWeightBold })}
          variant="caption"
        >
          Total Allocated:
        </Typography>
        <Typography variant="caption">{totalAllocation.toString()}%</Typography>
      </Box>
      {!!fieldValidationResult("total") && (
        <Typography color={"error"} variant="body2">
          {fieldValidationResult("total")}
        </Typography>
      )}
      {props.readOnly ? (
        <ul>
          {entries.map((entry, idx) => (
            <Typography
              variant="body2"
              component={"li"}
              key={`${idx}-${entry.getFiatcurrency()}`}
            >
              {`${fiatCurrencyCodeToString(entry.getFiatcurrency())} - ${decimalToBigNumber(
                entry.getAmount(),
              ).toString()}%`}
            </Typography>
          ))}
        </ul>
      ) : (
        <Box>
          {entries.map((thisEntry, idx) => (
            <Box
              key={`fiatcurrency-${thisEntry.getFiatcurrency()}-${idx}`}
              sx={(theme) => ({
                display: "grid",
                gridTemplateColumns: "1fr 80px auto",
                alignItems: "center",
                gap: theme.spacing(0.5),
              })}
            >
              <TextField
                size="small"
                fullWidth
                id={
                  "fiatCurrencyAllocationsAttributeForm-fiatcurrency-selectField"
                }
                disabled={props.disabled}
                select
                placeholder="Select..."
                error={
                  !!fieldValidationResult(`${thisEntry.getFiatcurrency()}`)
                }
                value={thisEntry.getFiatcurrency()}
                onChange={(e) => {
                  entries[idx] = thisEntry.setFiatcurrency(
                    Number(e.target.value) as FiatCurrencyCode,
                  );
                  props.onChange(
                    props.fiatCurrencyAllocationsAttribute.setEntriesList(
                      entries,
                    ),
                  );
                }}
              >
                {[
                  // only show non-used, non-undefined values and the current fiatcurrency
                  thisEntry.getFiatcurrency(),
                  ...allFiatCurrencyCodes.filter(
                    (fiatcurrency) =>
                      !entries.find(
                        (existingEntry) =>
                          existingEntry.getFiatcurrency() === fiatcurrency,
                      ) &&
                      fiatcurrency !==
                        FiatCurrencyCode.UNDEFINED_FIAT_CURRENCY_CODE,
                  ),
                ].map((v) => {
                  return (
                    <MenuItem key={v} value={v}>
                      {fiatCurrencyCodeToString(v)}
                    </MenuItem>
                  );
                })}
              </TextField>
              <TextNumField
                fullWidth
                sx={{ width: 80 }}
                id={`${thisEntry.getFiatcurrency()}-${idx}-fiatCurrencyAllocationsAttributeForm-fiatcurrency-percentage-textNumField`}
                disabled={props.disabled}
                readOnly={props.readOnly}
                disallowNegative
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <Typography>%</Typography>
                    </InputAdornment>
                  ),
                }}
                error={
                  !!fieldValidationResult(`${thisEntry.getFiatcurrency()}`)
                }
                value={thisEntry.getAmount()}
                onChange={(newValue) => {
                  entries[idx] = thisEntry.setAmount(newValue);
                  props.onChange(
                    props.fiatCurrencyAllocationsAttribute.setEntriesList(
                      entries,
                    ),
                  );
                }}
              />
              <Tooltip placement="top" title={"Remove"}>
                <IconButton
                  size={"small"}
                  id={`${thisEntry.getFiatcurrency()}-${idx}-fiatCurrencyAllocationsAttributeForm-fiatcurrency-remove-iconButton`}
                  onClick={() =>
                    props.onChange(
                      props.fiatCurrencyAllocationsAttribute.setEntriesList(
                        entries.filter(
                          (entry) =>
                            entry.getFiatcurrency() !==
                            thisEntry.getFiatcurrency(),
                        ),
                      ),
                    )
                  }
                >
                  <DeleteIcon />
                </IconButton>
              </Tooltip>
              {!!fieldValidationResult(`${thisEntry.getFiatcurrency()}`) && (
                <Typography
                  sx={{ marginTop: -1 }}
                  color={"error"}
                  variant="body2"
                >
                  {fieldValidationResult(`${thisEntry.getFiatcurrency()}`)}
                </Typography>
              )}
            </Box>
          ))}

          {/* Render the "add new" control row at the end if not in read only mode */}
          {!props.readOnly && (
            <Box sx={{ marginTop: 1 }}>
              <TextField
                size="small"
                fullWidth
                id={
                  "fiatCurrencyAllocationsAttributeForm-addAFiatCurrency-selectField"
                }
                disabled={props.disabled}
                select
                label="Add a FiatCurrency"
                value={FiatCurrencyCode.UNDEFINED_FIAT_CURRENCY_CODE}
                onChange={(e) => {
                  props.onChange(
                    props.fiatCurrencyAllocationsAttribute.setEntriesList([
                      ...entries,
                      new SmartInstrumentFiatCurrencyAllocationEntry()
                        .setFiatcurrency(
                          Number(e.target.value) as FiatCurrencyCode,
                        )
                        .setAmount(bigNumberToDecimal(BigNumber("0"))),
                    ]),
                  );
                }}
              >
                {allFiatCurrencyCodes
                  .filter(
                    // only show non-used values
                    (fiatcurrency) =>
                      !entries.find(
                        (existingEntry) =>
                          existingEntry.getFiatcurrency() === fiatcurrency,
                      ),
                  )
                  .map((v) => {
                    return (
                      <MenuItem key={v} value={v}>
                        {fiatCurrencyCodeToString(v)}
                      </MenuItem>
                    );
                  })}
              </TextField>
            </Box>
          )}
        </Box>
      )}
    </Box>
  );
};

import React, { useEffect, useMemo, useRef, useState } from "react";
import { BPTable } from "components/Table";
import {
  LedgerTokenViewUpdater,
  Model as LedgerTokenViewModel,
  Reader,
  useRead,
} from "james/views/ledgerTokenView";
import {
  Box,
  Button,
  Card,
  CardContent,
  IconButton,
  InputAdornment,
  TextField,
  Tooltip,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { useSnackbar } from "notistack";
import { NewSorting, Query } from "james/search/query";
import { TokenIconViewUpload } from "components/Ledger/Token";
import config from "react-global-configuration";
import { Token as LedgerToken } from "james/ledger";
import {
  Clear as ClearIcon,
  Opacity as MintIcon,
  Refresh as ReloadIcon,
} from "@mui/icons-material";
import { MintDialog } from "./MintDialog";
import {
  TextExactCriterion,
  TextSubstringCriterion,
} from "james/search/criterion";
import { InfiniteScrollList } from "components/Table/InfCardTable/InfiniteScrollList";
import { useApplicationContext } from "context/Application/Application";
import { Environment } from "../../const";
import { useAppNoticeContext } from "context/AppNotice/AppNotice";
import { useErrorContext } from "context/Error";
import { AssetHoldersList } from "./AssetHoldersList";
import { useAPIContext } from "context/API";
import { ListTokensRequest } from "@mesh/common-js/dist/stellar/tokenTap_pb";
import { futureTokenFromStellarToken } from "@mesh/common-js/dist/ledger";

const initialQuery = new Query({
  limit: 15,
  offset: 0,
  sorting: [NewSorting("id", "desc")],
});

export function Token() {
  const {
    stellar: { tokenTap },
  } = useAPIContext();
  const { authContext, viewConfiguration } = useApplicationContext();

  const { readRequest, readResponse, setReadRequest, loading } = useRead({
    criteria: {},
    query: new Query(initialQuery),
  });
  const { enqueueSnackbar } = useSnackbar();
  const [apiLoading, setAPILoading] = useState(false);
  const { current: productionEnvironment } = useRef(
    config.get("environment") === Environment.Production,
  );
  const { NotificationBannerHeight: noticeBannerHeight } =
    useAppNoticeContext();
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [textSearchCriterion, setTextSearchCriterion] = useState<any>(null);
  const [textSearchCriterionTextField, setTextSearchCriterionTextField] =
    useState("");
  const { errorContextErrorTranslator } = useErrorContext();
  useEffect(() => {
    if (textSearchCriterionTextField === "") {
      setTextSearchCriterion(null);
    } else {
      setTextSearchCriterion({
        $or: [
          { name: TextSubstringCriterion(textSearchCriterionTextField) },
          { issuer: TextSubstringCriterion(textSearchCriterionTextField) },
          {
            "token.code": TextSubstringCriterion(textSearchCriterionTextField),
          },
          {
            "token.category": TextSubstringCriterion(
              textSearchCriterionTextField,
            ),
          },
        ],
      });
    }
  }, [textSearchCriterionTextField]);
  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    let criteria: any = {};

    if (textSearchCriterion) {
      criteria = {
        ...criteria,
        ...textSearchCriterion,
      };
    }

    setReadRequest({
      query: new Query(initialQuery),
      criteria,
    });
  }, [textSearchCriterion, setReadRequest, authContext]);

  const [pourableTokens, setPourableTokens] = useState<LedgerToken[]>([]);
  const [loadingPourableTokens, setLoadingPourableTokens] = useState(false);
  const [tokenToMint, setTokenToMint] = useState<LedgerToken | undefined>(
    undefined,
  );
  useEffect(() => {
    if (productionEnvironment) {
      return;
    }
    (async () => {
      setLoadingPourableTokens(true);
      try {
        setPourableTokens(
          (
            await tokenTap.listTokens(
              new ListTokensRequest().setContext(authContext.toFuture()),
            )
          )
            .getTokensList()
            .map((t) =>
              LedgerToken.fromFutureToken(futureTokenFromStellarToken(t)),
            ),
        );
      } catch (e) {
        const err = errorContextErrorTranslator.translateError(e);
        console.error(
          `error getting list of pourable tokens: ${
            err.message ? err.message : err.toString()
          }`,
        );
        enqueueSnackbar(
          `Error getting list of pourable tokens: ${
            err.message ? err.message : err.toString()
          }`,
          { variant: "error" },
        );
      }
      setLoadingPourableTokens(false);
    })();
  }, [productionEnvironment, enqueueSnackbar]);

  const tokenViewConfig = viewConfiguration.Tokens;
  if (!tokenViewConfig) {
    console.error("no Tokens view config");
    return null;
  }
  const viewModelConfig = tokenViewConfig.ViewModel
    ? tokenViewConfig.ViewModel
    : {};

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const mZAR = useMemo(() => {
    return pourableTokens.findLast((token) => token.code === "mZAR");
  }, [pourableTokens]);

  // if on mobile render infinite scrollcard
  if (isMobile) {
    return <TokenWithInfiniteScrollCard />;
  }

  return (
    <Box sx={{ p: 2 }}>
      <BPTable
        singleSelect
        height={window.innerHeight - 80 - noticeBannerHeight}
        title="Tokens"
        loading={loading || apiLoading || loadingPourableTokens}
        data={readResponse.models}
        totalNoRecords={readResponse.total}
        query={readRequest.query}
        onQueryChange={(query) =>
          setReadRequest({
            ...readRequest,
            query,
          })
        }
        toolBarControls={(() => {
          const controls: React.ReactNode[] = [];

          if (viewModelConfig.Update) {
            controls.push(
              <Button
                variant="contained"
                color="primary"
                children="Full Update"
                onClick={async () => {
                  setAPILoading(true);
                  try {
                    await LedgerTokenViewUpdater.FullUpdate({
                      context: authContext,
                      assetCriteria: {},
                    });
                    setReadRequest({
                      ...readRequest,
                      query: new Query(initialQuery),
                    });
                    enqueueSnackbar("Full Update Complete", {
                      variant: "success",
                    });
                  } catch (e) {
                    const err = errorContextErrorTranslator.translateError(e);
                    console.error(
                      `error performing full update: ${
                        err.message ? err.message : err.toString()
                      }`,
                    );
                    enqueueSnackbar(
                      `Error Performing Full Update: ${
                        err.message ? err.message : err.toString()
                      }`,
                      { variant: "error" },
                    );
                  }
                  setAPILoading(false);
                }}
              />,
            );
          }

          controls.push(
            <Tooltip title="Reload">
              <span>
                <IconButton
                  id="tokensTable-refresh-iconButton"
                  size="small"
                  onClick={() =>
                    setReadRequest({
                      ...readRequest,
                      query: new Query(initialQuery),
                    })
                  }
                >
                  <ReloadIcon />
                </IconButton>
              </span>
            </Tooltip>,
          );

          return controls;
        })()}
        filters={[
          <TextField
            id={"tokenTable-textFilter-textField"}
            variant={"outlined"}
            margin={"dense"}
            sx={{ width: 400 }}
            label={"Search Text Fields"}
            placeholder={"Start Typing..."}
            InputLabelProps={{ shrink: true }}
            InputProps={{
              endAdornment: textSearchCriterionTextField ? (
                <InputAdornment
                  position={"end"}
                  children={
                    <IconButton
                      id={"tokenTable-textFilterClear-iconButton"}
                      size={"small"}
                      onClick={() => setTextSearchCriterionTextField("")}
                    >
                      <ClearIcon />
                    </IconButton>
                  }
                />
              ) : undefined,
            }}
            value={textSearchCriterionTextField}
            onChange={(e) => setTextSearchCriterionTextField(e.target.value)}
          />,
        ]}
        expandRowComponent={{
          maxHeight: 420,
          component: (selectedRowData) => (
            <AssetHoldersList
              mZAR={mZAR}
              token={selectedRowData.token}
              excludedTokenCodes={["mZAR", "XLM"]}
            />
          ),
        }}
        columns={(() => {
          const columns = [
            {
              label: "Icon",
              field: "icon",
              sortable: false,
              // eslint-disable-next-line @typescript-eslint/no-explicit-any
              accessor: (data: { [key: string]: any }) => (
                <Box sx={{ mr: 2 }}>
                  <TokenIconViewUpload
                    token={(data as LedgerTokenViewModel).token}
                    tokenIconDownloadURL={
                      (data as LedgerTokenViewModel).publicIconURL
                    }
                  />
                </Box>
              ),
            },
            {
              label: "Code",
              field: "token.code",
              // eslint-disable-next-line @typescript-eslint/no-explicit-any
              accessor: (data: { [key: string]: any }) =>
                (data as LedgerTokenViewModel).token.code,
            },
            {
              field: "issuer",
              label: "Issuer",
            },
            {
              field: "tokenCategory",
              label: "Category",
            },
            {
              field: "name",
              label: "Name",
            },
          ];

          if (!productionEnvironment) {
            columns.push({
              label: "",
              field: "mint",
              sortable: false,
              // eslint-disable-next-line @typescript-eslint/no-explicit-any
              accessor: (data: { [key: string]: any }) => {
                const { token } = data as LedgerTokenViewModel;
                if (pourableTokens.find((t) => t.isEqualTo(token))) {
                  return (
                    <Tooltip title={`Mint ${token.code}`} placement="top">
                      <span>
                        <IconButton
                          id={`tokensTable-mint-${token.code}-iconButton`}
                          size="small"
                          onClick={(e) => {
                            e.stopPropagation();
                            setTokenToMint(token);
                          }}
                        >
                          <MintIcon />
                        </IconButton>
                      </span>
                    </Tooltip>
                  );
                }
                return <div />;
              },
            });
          }

          return columns;
        })()}
      />

      {!!tokenToMint && (
        <MintDialog
          closeDialog={() => setTokenToMint(undefined)}
          tokenToMint={tokenToMint}
        />
      )}
    </Box>
  );
}

export const TokenWithInfiniteScrollCard = () => {
  const [models, setModels] = useState<LedgerTokenViewModel[]>([]);
  const [name, setName] = useState("");
  const [total, setTotal] = useState(0);
  const [loading, setLoading] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const { errorContextErrorTranslator } = useErrorContext();

  const loadMoreItems =
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (criteria: any, query: Query, updateOffset: () => void) => async () => {
      setLoading(true);

      // load more items
      try {
        const response = await Reader.Read({
          query,
          criteria,
        });

        updateOffset();

        setModels([...models, ...response.models]);

        setTotal(response.total);
      } catch (e) {
        const err = errorContextErrorTranslator.translateError(e);
        console.error(
          `error reading token view models: ${
            err.message ? err.message : err.toString()
          }`,
        );
        enqueueSnackbar(
          `Error reading token view models: ${
            err.message ? err.message : err.toString()
          }`,
          { variant: "error" },
        );
      }

      setLoading(false);
    };

  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        flexDirection: "column",
        gap: "16px",
        marginTop: (theme) => ({ marginTop: theme.spacing(5) }),
      }}
    >
      <InfiniteScrollList
        total={total}
        data={models}
        isLoading={loading}
        clearData={() => setModels([])}
        loadMoreData={loadMoreItems}
        rowWidth={300}
        renderData={(data) => {
          return (
            <Box
              sx={{
                display: "grid",
                gridTemplateRows: "auto 0px",
              }}
            >
              <Card>
                <CardContent
                  sx={{
                    display: "Grid",
                    gridTemplateColumns: "repeat(2, 1fr)",
                    width: `calc(100%)`,
                    height: 100,
                  }}
                >
                  <Typography>Name</Typography>
                  <Typography>{data.name}</Typography>
                  <Typography>Code</Typography>
                  <Typography>{data.token.code}</Typography>
                  <Typography>Category</Typography>
                  <Typography>{data.tokenCategory}</Typography>
                </CardContent>
              </Card>
              <div />
            </Box>
          );
        }}
        noDataSplashComponent={
          <Typography variant={"body1"} children={"No data to display"} />
        }
      >
        {({ setCriteria, setSorting }) => (
          <Box
            sx={{
              display: "flex",
              gap: "16px",
              alignItems: "center",
              flexDirection: "column",
              marginBottom: "32px",
            }}
          >
            <TextField
              value={name}
              label={"Filter By Name"}
              sx={{
                width: "300px",
              }}
              onChange={(e) => {
                setName(e.target.value);
                if (e.target.value) {
                  setCriteria(
                    // eslint-disable-next-line @typescript-eslint/no-explicit-any
                    (prevCriteria: any) =>
                      ({
                        ...prevCriteria,
                        name: TextExactCriterion(e.target.value),
                        // eslint-disable-next-line @typescript-eslint/no-explicit-any
                      }) as any,
                  );
                } else {
                  // eslint-disable-next-line @typescript-eslint/no-explicit-any
                  setCriteria((prevCriteria: any) => {
                    const updatedCriteria = prevCriteria;
                    delete updatedCriteria.name;
                    return { ...updatedCriteria };
                  });
                }
              }}
            />
            <Button
              variant={"outlined"}
              sx={{ height: "39px" }}
              onClick={() => {
                setSorting([NewSorting("name", "asc")]);
              }}
            >
              Sort by Code
            </Button>
          </Box>
        )}
      </InfiniteScrollList>
    </Box>
  );
};

export enum LedgerAccountCategory {
  Issuance = "Issuance",
  Trading = "Trading",
  Operational = "Operational",
  MarketInfrastructure = "Market Infrastructure",
  Fee = "Fee",
  Clearance = "Clearance",
}

export const AllLedgerAccountCategories: LedgerAccountCategory[] = [
  LedgerAccountCategory.Issuance,
  LedgerAccountCategory.Clearance,
  LedgerAccountCategory.Trading,
  LedgerAccountCategory.Operational,
  LedgerAccountCategory.MarketInfrastructure,
  LedgerAccountCategory.Fee,
];

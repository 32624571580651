import React, { Dispatch, SetStateAction } from "react";
import { Box, Card, CardHeader, Typography, useTheme } from "@mui/material";
import { formatTextNum } from "utilities/number";
import {
  Balance,
  Model,
} from "@mesh/common-js/dist/views/stellarAccountView/model_pb";
import { FundDeFundCard } from "../../FundDeFund/FundDeFundCard";
import useMediaQuery from "@mui/material/useMediaQuery";
import { getAvailableBalance } from "@mesh/common-js/dist/views/stellarAccountView";
import { decimalToBigNumber } from "@mesh/common-js/dist/num";
import { Decimal } from "@mesh/common-js/dist/num/decimal_pb";

interface CashBalancesCardProps {
  fundDeFundOnBalanceCard: boolean | undefined;
  balance: Balance;
  accountModel?: Model;
  setShowDeFundDialog: Dispatch<SetStateAction<boolean>>;
  setShowFundAccDialog: Dispatch<SetStateAction<boolean>>;
  showDeFundDialog: boolean;
  showFundAccDialog: boolean;
}

export function CashBalancesCard(props: CashBalancesCardProps) {
  const isUSDC = props.balance.getAmount()?.getToken()?.getCode() === "USDC";
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  return (
    <Card
      sx={(theme) => ({
        width: "100%",
        backgroundColor: theme.palette.background.paper,
        borderRadius: "10px",
        minWidth: "217px",
        height: props.fundDeFundOnBalanceCard && !isMobile ? "96px" : "130px",
      })}
    >
      <CardHeader
        sx={(theme) => ({
          height: theme.spacing(3),
          padding: theme.spacing(0, 2),
          background: `linear-gradient(90deg, ${
            isUSDC ? theme.palette.info.main : theme.palette.primary.main
          } 0%, ${theme.palette.custom.grapeDark}) 100%`,
        })}
        title={
          <Typography
            sx={{
              fontSize: "12px",
              fontWeight: "bold",
            }}
            children={props.balance.getAmount()?.getToken()?.getCode()}
          />
        }
      />
      {props.fundDeFundOnBalanceCard && !isMobile ? (
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            p: 2,
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
            }}
          >
            <Typography
              sx={(theme) => ({
                fontSize: "12px",
                color: theme.palette.text.disabled,
              })}
            >
              Available Balance
            </Typography>
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
              }}
            >
              <Typography
                variant={"caption"}
                sx={(theme) => ({
                  fontSize: "16px",
                  color: theme.palette.text.disabled,
                })}
              >
                {props.balance.getAmount()?.getToken()?.getCode()}
              </Typography>
              <Typography
                sx={{
                  fontSize: "16px",
                  padding: "1px 0px 0px 4px",
                }}
              >
                {formatTextNum(
                  decimalToBigNumber(
                    getAvailableBalance(props.balance).getValue() ??
                      new Decimal(),
                  ),
                  {
                    addDecimalPadding: true,
                    noDecimalPlaces: 2,
                  },
                )}
              </Typography>
            </Box>
          </Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
            }}
          >
            <Typography
              sx={(theme) => ({
                fontSize: "12px",
                color: theme.palette.text.disabled,
              })}
            >
              Total Balance
            </Typography>
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
              }}
            >
              <Typography
                variant={"caption"}
                sx={(theme) => ({
                  fontSize: "16px",
                  color: theme.palette.text.disabled,
                })}
              >
                {props.balance.getAmount()?.getToken()?.getCode()}
              </Typography>
              <Typography
                sx={{
                  fontSize: "16px",
                  padding: "1px 0px 0px 4px",
                }}
              >
                {formatTextNum(
                  decimalToBigNumber(props.balance.getAmount()?.getValue()),
                  {
                    addDecimalPadding: true,
                    noDecimalPlaces: 2,
                  },
                )}
              </Typography>
            </Box>
          </Box>
          {!isMobile && (
            <FundDeFundCard
              accountModel={props.accountModel}
              setShowDeFundDialog={props.setShowDeFundDialog}
              setShowFundAccDialog={props.setShowFundAccDialog}
              showDeFundDialog={props.showDeFundDialog}
              showFundAccDialog={props.showFundAccDialog}
            />
          )}
        </Box>
      ) : (
        <Box sx={{ p: 2 }}>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
            }}
          >
            <Typography
              sx={(theme) => ({
                fontSize: "12px",
                color: theme.palette.text.disabled,
              })}
            >
              Available
            </Typography>
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
              }}
            >
              <Typography
                variant={"caption"}
                sx={(theme) => ({
                  fontSize: "16px",
                  color: theme.palette.text.disabled,
                })}
              >
                {props.balance.getAmount()?.getToken()?.getCode()}
              </Typography>
              <Typography
                sx={{
                  fontSize: "16px",
                  padding: "1px 0px 0px 4px",
                }}
              >
                {formatTextNum(
                  decimalToBigNumber(
                    getAvailableBalance(props.balance).getValue() ??
                      new Decimal(),
                  ),
                  {
                    addDecimalPadding: true,
                    noDecimalPlaces: 2,
                  },
                )}
              </Typography>
            </Box>
          </Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              paddingTop: "8px",
            }}
          >
            <Typography
              sx={(theme) => ({
                fontSize: "10px",
                color: theme.palette.text.disabled,
                lineHeight: "10px",
              })}
            >
              Total
            </Typography>
            <Typography
              variant={"caption"}
              sx={(theme) => ({
                fontSize: "12px",
                color: theme.palette.text.disabled,
              })}
              children={`${props.balance.getAmount()?.getToken()?.getCode()} ${formatTextNum(
                decimalToBigNumber(props.balance.getAmount()?.getValue()),
                {
                  addDecimalPadding: true,
                  noDecimalPlaces: 2,
                },
              )}`}
            />
          </Box>
        </Box>
      )}
    </Card>
  );
}

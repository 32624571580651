import { MarketingContentContextProvider } from "./Context";
import { Layout } from "./Layout";

export type MarketingContentProps = {
  system: boolean;
};

export const MarketingContent = (props: MarketingContentProps) => {
  return (
    <MarketingContentContextProvider system={props.system}>
      <Layout />
    </MarketingContentContextProvider>
  );
};

import { Amount } from "james/ledger";
import { SubscriptionUnderwritingState } from "james/market/SubscriptionUnderwriting";
import { BigNumber } from "bignumber.js";

export class Model {
  public id = "";
  public subscriptionUnderwritingID = "";
  public ownerID = "";
  public ownerClientName = "";
  public subscriptionOrderBookOwnerID = "";
  public subscriptionOrderBookID = "";
  public subscriptionOrderBookNumber = "";
  public number = "";
  public assetName = "";
  public state: SubscriptionUnderwritingState | "" = "";
  public percentage: BigNumber = new BigNumber("0");
  public maxTakeUpAmount: Amount = new Amount();
  public maxTakeUpTokens: Amount = new Amount();
  public fundedAmount: Amount = new Amount();
  public fundedTokens: Amount = new Amount();
  public takenUpAmount: Amount = new Amount();
  public takenUpTokens: Amount = new Amount();
  public currentObligationAmount: Amount = new Amount();
  public currentObligationTokenAmount: Amount = new Amount();
  public resolutionCount = 0;
  public issueDate = "";
  public issuerName = "";
  public assetID = "";
  public maturityDate = "";
  public failureReasons: string[] = [];

  constructor(l?: Model) {
    if (!l) {
      return;
    }
    this.id = l.id;
    this.subscriptionUnderwritingID = l.subscriptionUnderwritingID;
    this.ownerID = l.ownerID;
    this.ownerClientName = l.ownerClientName;
    this.subscriptionOrderBookOwnerID = l.subscriptionOrderBookOwnerID;
    this.subscriptionOrderBookID = l.subscriptionOrderBookID;
    this.subscriptionOrderBookNumber = l.subscriptionOrderBookNumber;
    this.number = l.number;
    this.assetName = l.assetName;
    this.state = l.state;
    this.percentage = new BigNumber(l.percentage);
    this.maxTakeUpAmount = new Amount(l.maxTakeUpAmount);
    this.maxTakeUpTokens = new Amount(l.maxTakeUpTokens);
    this.fundedAmount = new Amount(l.fundedAmount);
    this.fundedTokens = new Amount(l.fundedTokens);
    this.takenUpAmount = new Amount(l.takenUpAmount);
    this.takenUpTokens = new Amount(l.takenUpTokens);
    this.currentObligationAmount = new Amount(l.currentObligationAmount);
    this.currentObligationTokenAmount = new Amount(
      l.currentObligationTokenAmount,
    );
    this.resolutionCount = l.resolutionCount;
    this.issueDate = l.issueDate;
    this.issuerName = l.issuerName;
    this.assetID = l.assetID;
    this.maturityDate = l.maturityDate;
    this.failureReasons = l.failureReasons;
  }
}

import React, { useEffect, useMemo, useRef, useState } from "react";
import { RouteType } from "routes/Route";
import { Router } from "routes";
import { Orders } from "./components/Orders";
import { Trades } from "./components/Trades";
import { Markets } from "./components/Markets";
import { Ribbon } from "./components/Ribbon";
import { ViewConfiguration } from "james/configuration";
import isObject from "lodash/isObject";
import { atLeastOneTrueFoundInViewConfigObject } from "routes/private/administrationRoutes";
import { LedgerAccountCategory, Token } from "james/ledger";
import { ClientKYCStatus } from "james/client";
import { AssetOverview } from "./components/AssetOverview";
import useMediaQuery from "@mui/material/useMediaQuery";
import { Box, useTheme } from "@mui/material";
import { useAccountContext } from "context/Account/Account";
import { useApplicationContext } from "context/Application/Application";
import { MarketplaceTour } from "context/AppTour/MarketplaceTour";
import { useAppTourContext } from "context/AppTour/AppTour";
import { UserGuide } from "james/user/User";
import { Helmet } from "react-helmet-async";
import { PlatformGuidedJourneyRenderDelay } from "components/AppTour/PlatformGuidedJourneyRenderDelay";
import { Subscriptions } from "./components/Subscriptions/Subscriptions";

import { useLocalStorage } from "hooks/persistentStateStore/useLocalStorage";
import { RedirectPopUp } from "components/Dialogs/RedirectPopUp/RedirectPopUp";
import { Exchange } from "./components/Exchange/Exchange";
import { getAvailableBalance } from "@mesh/common-js/dist/views/stellarAccountView";
import { decimalToBigNumber } from "@mesh/common-js/dist/num";

export const marketplaceRoutes: RouteType[] = [
  {
    name: "Markets",
    id: "marketplace-markets",
    path: "/market/markets",
    component: <Markets />,
  },
  {
    name: "Orders",
    id: "marketplace-orders",
    path: "/market/orders",
    component: <Orders />,
  },
  {
    name: "Trades",
    id: "marketplace-trades",
    path: "/market/trades",
    component: <Trades />,
  },
  {
    name: "Subscriptions",
    id: "marketplace-subscriptions",
    path: "/market/subscriptions",
    component: <Subscriptions />,
  },
  {
    name: "Exchange",
    id: "marketplace-exchange",
    path: "/market/exchange",
    allowSubPaths: true,
    component: <Exchange />,
  },
  {
    name: "Asset Overview",
    id: "marketplace-asset-overview",
    path: "/market/asset-overview",
    component: <AssetOverview />,
  },
];

export const allAccessibleTabRouteNames = ["Markets"];

export const allAccessibleNonTabRouteNames = ["Asset Overview"];

export function determineAvailableTabRoutes(
  viewConfig: ViewConfiguration,
): RouteType[] {
  // get marketplace view configuration
  const marketplaceViewConfig = viewConfig.Marketplace;
  if (!marketplaceViewConfig) {
    return [];
  }

  // prepare routes to return
  const routesToReturn: RouteType[] = [];

  // for every marketplace route...
  marketplaceRoutes.forEach((r) => {
    if (allAccessibleTabRouteNames.includes(r.name)) {
      // if the route is one that everyone should have
      // then add it
      routesToReturn.push(r);
    } else if (isObject(marketplaceViewConfig[r.name])) {
      // otherwise if the associated view config is an object
      if (
        atLeastOneTrueFoundInViewConfigObject(marketplaceViewConfig[r.name])
      ) {
        // and there is at least 1 true within the object
        // then add it
        routesToReturn.push(r);
      }
    } else {
      // otherwise the view config object is assumed to be a boolean
      if (marketplaceViewConfig[r.name]) {
        // if it is true
        // then add the route
        routesToReturn.push(r);
      }
    }
  });

  return routesToReturn;
}

function determineAllAccessibleNonTabRoutes(): RouteType[] {
  // prepare routes to return
  const routesToReturn: RouteType[] = [];

  // for every marketplace route...
  marketplaceRoutes.forEach((r) => {
    if (allAccessibleNonTabRouteNames.includes(r.name)) {
      // if the route is one that everyone should have
      // then add it
      routesToReturn.push(r);
    }
  });

  return routesToReturn;
}

interface MarketplaceProps {
  baseURL: string;
}

export const Marketplace = (props: MarketplaceProps) => {
  const { viewConfiguration, myClientKYCStatus } = useApplicationContext();
  const [modelID, setModelID] = useState<string>("");
  const [mZARBalance, setMZARBalance] = useState<string>("");
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const { stellarAccountContext } = useAccountContext();
  const { registerElement, startTour } = useAppTourContext();
  const scrollableDivRef = useRef<HTMLDivElement>(null);
  const { getStore } = useLocalStorage();

  useEffect(() => {
    startTour(MarketplaceTour, UserGuide.PlatformGuidedJourney);
  }, []);

  // useEffect that sets the trading Account
  useEffect(() => {
    if (!viewConfiguration.Wallet) {
      return;
    }

    // if a client kyc status is not verified return
    if (myClientKYCStatus !== ClientKYCStatus.VerifiedStatus) {
      return;
    }

    // if the stellarAccountContext accounts are still being loaded return
    if (stellarAccountContext.loading) {
      return;
    }

    // retrieve the first trading account
    for (const account of stellarAccountContext.accounts) {
      if (account.getLabel() === LedgerAccountCategory.Trading) {
        setModelID(account.getId());
        for (const bal of account.getBalancesList()) {
          if (
            bal.getTokenviewmodel()?.getIssuer().includes("Mesh B.V.") &&
            bal.getTokenviewmodel()?.getToken()?.getCode().includes("mZAR")
          ) {
            setMZARBalance(
              decimalToBigNumber(
                getAvailableBalance(bal).getValue(),
              ).toString() || "0",
            );
          }
        }
        break;
      }
    }
  }, [
    myClientKYCStatus,
    stellarAccountContext.loading,
    stellarAccountContext.accounts,
    viewConfiguration.Wallet,
  ]);

  useEffect(() => {
    const handleScroll = () => {
      if (
        scrollableDivRef.current &&
        scrollableDivRef.current.scrollHeight -
          scrollableDivRef.current.scrollTop ===
          scrollableDivRef.current.clientHeight
      ) {
        window.dispatchEvent(new Event("marketplaceFullyScrolled"));
      }
    };

    if (scrollableDivRef.current) {
      scrollableDivRef.current.addEventListener("scroll", handleScroll);
    }

    return () => {
      if (scrollableDivRef.current) {
        scrollableDivRef.current.removeEventListener("scroll", handleScroll);
      }
    };
  });

  const availableTabRoutes = useMemo(
    () => determineAvailableTabRoutes(viewConfiguration),
    [viewConfiguration],
  );
  const availableNonTabRoutes = determineAllAccessibleNonTabRoutes();

  const [redirectPopUp, setRedirectPopUp] = useState(true);

  useEffect(() => {
    window.addEventListener("MarketPlaceScrollTop", () => {
      if (scrollableDivRef.current) {
        scrollableDivRef.current.scrollTop = 0;
      }
    });
    return () => {
      window.removeEventListener("MarketPlaceScrollTop", () => {
        return null;
      });
    };
  }, []);
  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Mesh | Marketplace</title>
        <meta
          name="description"
          content="The Mesh smart marketplace, for buying and selling a range of cross-asset financial instruments"
        />
      </Helmet>
      <>
        <PlatformGuidedJourneyRenderDelay>
          <Box
            sx={{
              position: "relative",
              boxShadow: "0 0 12px -2px #000000",
              zIndex: 50,
            }}
            ref={registerElement("marketPlaceRibbon")}
          >
            {!isMobile && (
              <Ribbon
                modelID={modelID}
                balance={mZARBalance}
                routes={availableTabRoutes}
              />
            )}
          </Box>
          <Box
            id="marketPlaceContentScrollableDiv"
            ref={scrollableDivRef}
            className={"meshScroll"}
            sx={(theme) => ({
              position: "relative",
              height: "100%",
              overflowY: "auto",
              overflowX: "hidden",
              overscrollBehavior: "contain",
              "&::after": {
                content: "''",
                position: "fixed",
                bottom: 0,
                left: 0,
                right: 0,
                height: 48,
                boxShadow: "0px 10px 10px 10px rgba(0, 0, 0, 0.5)",
                pointerEvents: "none",
              },
              [theme.breakpoints.up("sm")]: {
                // windowWidth wasn't updating accurately with window resize events, had
                // to set the screen width using root document node client width
                width: document.documentElement.clientWidth,
                display: "flex",
                alignItems: "center",
                flexDirection: "column",
                height: `calc(100% - 48px)`,
              },
            })}
          >
            <Box
              id="marketPlaceContent"
              ref={registerElement("marketPlaceContent")}
              sx={{
                position: "relative",
                minHeight: "100%",
                [theme.breakpoints.up("sm")]: {
                  // windowWidth wasn't updating accurately with window resize events, had
                  // to set the screen width using root document node client width
                  width: document.documentElement.clientWidth,
                  display: "flex",
                  alignItems: "center",
                  flexDirection: "column",
                },
              }}
            >
              <Router
                baseURL={props.baseURL}
                redirectPath={"/market/markets"}
                routes={[...availableTabRoutes, ...availableNonTabRoutes]}
              />
            </Box>
          </Box>
        </PlatformGuidedJourneyRenderDelay>
      </>
      {getStore().redirectURL !== "" &&
        localStorage.getItem("Platform Guided Journey") === "complete" && (
          <RedirectPopUp
            dialogProps={{ open: redirectPopUp }}
            closeDialog={() => setRedirectPopUp(false)}
            redirectURL={getStore().redirectURL}
            redirectName={getStore().redirectAssetName ?? ""}
            redirectShortName={getStore().redirectAssetShortName ?? ""}
            redirectToken={new Token()}
          />
        )}
    </>
  );
};

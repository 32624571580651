import config from "react-global-configuration";
import { jsonRPCRequestDEPRECATED } from "utilities/network/jsonRPCRequest/jsonRPCRequestDEPRECATED";
import { Context } from "james/security";
import { Query } from "james/search/query";
import { GroupUser } from "james/user";
import { useEffect, useRef, useState } from "react";
import { useErrorContext } from "context/Error";

export interface SearchAccountSignatoryGroupUsersRequest {
  context: Context;
  accountNumber: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  criteria: any;
  query?: Query;
}

export interface SearchAccountSignatoryGroupUsersResponse {
  records: GroupUser[];
  total: number;
}

export const AccountSignatoryInspector = {
  serviceProvider: "stellar-AccountSignatoryInspector",
  async SearchAccountSignatoryGroupUsers(
    request: SearchAccountSignatoryGroupUsersRequest,
  ): Promise<SearchAccountSignatoryGroupUsersResponse> {
    const response = await jsonRPCRequestDEPRECATED({
      url: config.get("meshAuthorizedURL"),
      method: `${AccountSignatoryInspector.serviceProvider}.SearchAccountSignatoryGroupUsers`,
      request,
    });
    return {
      total: 0,
      records: response.records.map((gu: GroupUser) => new GroupUser(gu)),
    };
  },
};

export function useSearchAccountSignatoryGroupUsers(
  initialSearchAccountSignatoryGroupUsersRequest: SearchAccountSignatoryGroupUsersRequest,
  shouldNotExecute?: boolean,
) {
  const [
    searchAccountSignatoryGroupUsersRequest,
    setSearchAccountSignatoryGroupUsersRequest,
  ] = useState<SearchAccountSignatoryGroupUsersRequest>(
    initialSearchAccountSignatoryGroupUsersRequest,
  );
  const [
    searchAccountSignatoryGroupUsersResponse,
    setSearchAccountSignatoryGroupUsersResponse,
  ] = useState<SearchAccountSignatoryGroupUsersResponse>({
    total: 0,
    records: [],
  });
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<undefined | string>(undefined);
  const timeoutRef = useRef<NodeJS.Timeout | undefined>(undefined);
  const { errorContextErrorTranslator } = useErrorContext();

  useEffect(() => {
    if (shouldNotExecute) {
      return;
    }
    setLoading(true);
    clearTimeout(timeoutRef.current);
    timeoutRef.current = setTimeout(async () => {
      setError(undefined);
      try {
        setSearchAccountSignatoryGroupUsersResponse(
          await AccountSignatoryInspector.SearchAccountSignatoryGroupUsers(
            searchAccountSignatoryGroupUsersRequest,
          ),
        );
      } catch (e) {
        const err = errorContextErrorTranslator.translateError(e);
        console.error(
          `error searching for signatory group users: ${
            err.message ? err.message : err.toString()
          }`,
        );
        setError(
          `error searching for signatory group users: ${
            err.message ? err.message : err.toString()
          }`,
        );
      }
      setLoading(false);
    }, 400);
  }, [searchAccountSignatoryGroupUsersRequest, shouldNotExecute]);

  return {
    searchAccountSignatoryGroupUsersRequest,
    setSearchAccountSignatoryGroupUsersRequest,
    searchAccountSignatoryGroupUsersResponse,
    loading,
    error,
  };
}

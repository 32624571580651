// source: views/financialPaymentFundingView/modelReaderUNSCOPED_meshproto.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global =
    (typeof globalThis !== 'undefined' && globalThis) ||
    (typeof window !== 'undefined' && window) ||
    (typeof global !== 'undefined' && global) ||
    (typeof self !== 'undefined' && self) ||
    (function () { return this; }).call(null) ||
    Function('return this')();

var security_context_pb = require('../../security/context_pb.js');
goog.object.extend(proto, security_context_pb);
var search_criterion_pb = require('../../search/criterion_pb.js');
goog.object.extend(proto, search_criterion_pb);
var search_query_pb = require('../../search/query_pb.js');
goog.object.extend(proto, search_query_pb);
var search_sorting_pb = require('../../search/sorting_pb.js');
goog.object.extend(proto, search_sorting_pb);
var views_financialPaymentFundingView_model_pb = require('../../views/financialPaymentFundingView/model_pb.js');
goog.object.extend(proto, views_financialPaymentFundingView_model_pb);
var google_protobuf_timestamp_pb = require('google-protobuf/google/protobuf/timestamp_pb.js');
goog.object.extend(proto, google_protobuf_timestamp_pb);
var ledger_futureAmount_pb = require('../../ledger/futureAmount_pb.js');
goog.object.extend(proto, ledger_futureAmount_pb);
var ledger_transaction_pb = require('../../ledger/transaction_pb.js');
goog.object.extend(proto, ledger_transaction_pb);
var views_financialPaymentFundingView_modelReader_meshproto_pb = require('../../views/financialPaymentFundingView/modelReader_meshproto_pb.js');
goog.object.extend(proto, views_financialPaymentFundingView_modelReader_meshproto_pb);

import { Chip } from "@mui/material";

export type ObjectiveChipProps = {
  value: boolean;
};

export function ObjectiveChip(props: ObjectiveChipProps) {
  const label = props.value ? "Complete" : "Empty";
  const color = props.value ? "success" : "warning";

  return (
    <Chip sx={{ fontWeight: 400 }} color={color} size="small" label={label} />
  );
}

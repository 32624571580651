import React, { useRef } from "react";
import { styled } from "@mui/material/styles";
import { Cell, Pie, PieChart, Tooltip } from "recharts";
import { getRandomColor } from "utilities/color";
import { Paper, Typography } from "@mui/material";
import { CountryAllocation } from "james/financial/CountryAllocation";
import { countries } from "james/country";
import { BigNumber } from "bignumber.js";

const PREFIX = "CountryAllocationPieChart";

const classes = {
  chartToolTipPaper: `${PREFIX}-chartToolTipPaper`,
  chartToolTipText: `${PREFIX}-chartToolTipText`,
};

const StyledPieChart = styled(PieChart)(({ theme }) => ({
  [`& .${classes.chartToolTipPaper}`]: {
    borderRadius: 4,
    border: `solid 1px ${theme.palette.background.default}`,
    padding: theme.spacing(0.5, 1, 0.5, 1),
    display: "grid",
    gridTemplateColumns: "auto",
    backgroundColor: theme.palette.secondary.light,
  },

  [`& .${classes.chartToolTipText}`]: {
    color: theme.palette.background.default,
  },
}));

const otherColour = "#0093D6";

const idealColors = ["#FF8042", "#5F2076", "#FFBB28", "#00C49F", "#003585"];

export interface CountryAllocationPieChartProps {
  height: number;
  countryAllocations: CountryAllocation[];
}

export function CountryAllocationPieChart(
  props: CountryAllocationPieChartProps,
) {
  let allocatedAmount = new BigNumber("0");
  const processedCountryAllocations: CountryAllocation[] = [];
  const usedColors = useRef<{ [key: string]: string }>({ Other: otherColour });

  const getRandomColorForKey = (key: string) => {
    // if a color is already stored for this key, use it
    if (usedColors.current[key]) {
      return usedColors.current[key];
    }

    // otherwise check if any of the ideal colors could be used
    for (const c of idealColors) {
      if (!Object.values(usedColors.current).includes(c)) {
        usedColors.current[key] = c;
        return usedColors.current[key];
      }
    }

    // otherwise get a new random color
    usedColors.current[key] = getRandomColor([
      ...idealColors,
      ...Object.values(usedColors.current),
    ]);
    return usedColors.current[key];
  };

  // for every given countryAllocation...
  for (const h of props.countryAllocations) {
    // if adding in this countryAllocation will result going over 100
    if (allocatedAmount.plus(h.percentage).isGreaterThan(new BigNumber(100))) {
      // then do no more processing
      break;
    }

    // otherwise add to amount allocated
    allocatedAmount = allocatedAmount.plus(h.percentage);

    // and add countryAllocation to processed countryAllocations
    processedCountryAllocations.push(h);
  }

  // determine unallocated amount
  const unallocatedAmount = new BigNumber(100).minus(allocatedAmount);
  if (unallocatedAmount) {
    processedCountryAllocations.push(
      new CountryAllocation({
        name: "Other",
        percentage: unallocatedAmount,
      }),
    );
  }

  return (
    <StyledPieChart height={props.height} width={props.height}>
      <Pie
        stroke="none"
        data={processedCountryAllocations}
        labelLine={false}
        outerRadius={props.height / 2}
        dataKey={(dataObject) => {
          if (dataObject.payload instanceof CountryAllocation) {
            const countryAllocation = dataObject.payload as CountryAllocation;
            return countryAllocation.percentage.toNumber();
          }
          return 0;
        }}
      >
        {processedCountryAllocations.map((entry, idx) => (
          <Cell key={`cell-${idx}`} fill={getRandomColorForKey(entry.name)} />
        ))}
      </Pie>
      <Tooltip
        content={({ active, payload }) => {
          if (active && payload && payload.length && payload[0].payload) {
            if (payload[0].payload.payload instanceof CountryAllocation) {
              const ca = payload[0].payload.payload as CountryAllocation;
              const country = countries.find((c) => c.value === ca.name);

              return (
                <Paper className={classes.chartToolTipPaper}>
                  <Typography
                    variant="body2"
                    className={classes.chartToolTipText}
                    children={
                      ca.name === "Other"
                        ? `Other: ${ca.percentage}%`
                        : `${ca.name} - ${country ? country.label : "?"}: ${
                            ca.percentage
                          }%`
                    }
                  />
                </Paper>
              );
            }
          }

          return null;
        }}
      />
    </StyledPieChart>
  );
}

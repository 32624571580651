import { Box, Card, Typography, useTheme } from "@mui/material";
import { useEffect, useState } from "react";
import { TextField } from "components/FormFields";
import { ValidationResult } from "common/validation";
import { SocialMediaLinks } from "@mesh/common-js/dist/marketing/socialMediaLinks_pb";

export type SocialMediaLinksFormProps = {
  socialMediaLinks: SocialMediaLinks;
  disabled: boolean;
  readOnly: boolean;
  formDataValidationResult: ValidationResult;
  onChange: (updatedSocialMediaLinks: SocialMediaLinks) => void;
};

export const SocialMediaLinksForm = (props: SocialMediaLinksFormProps) => {
  const theme = useTheme();

  // TODO: Add validations
  // const getFieldValidation = (field: string) => {
  //   return props.formDataValidationResult.fieldValidations[
  //     `socialMediaLinks-${field}`
  //   ];
  // };

  // Local state to track the current working state
  const [currentSocialMediaLinks, setCurrentSocialMediaLinks] = useState(
    new SocialMediaLinks()
      .setWeb(props.socialMediaLinks.getWeb())
      .setFacebook(props.socialMediaLinks.getFacebook())
      .setLinkedin(props.socialMediaLinks.getLinkedin())
      .setYoutube(props.socialMediaLinks.getYoutube())
      .setInstagram(props.socialMediaLinks.getInstagram())
      .setTwitter(props.socialMediaLinks.getTwitter()),
  );

  // State for the last saved social media links
  const [savedSocialMediaLinks, setSavedSocialMediaLinks] = useState(
    props.socialMediaLinks,
  );

  // Helper function to compare social media links for unsaved changes
  const areSocialMediaLinksDifferent = (
    linksA: SocialMediaLinks,
    linksB: SocialMediaLinks,
  ) => {
    return (
      linksA.getWeb() !== linksB.getWeb() ||
      linksA.getFacebook() !== linksB.getFacebook() ||
      linksA.getLinkedin() !== linksB.getLinkedin() ||
      linksA.getYoutube() !== linksB.getYoutube() ||
      linksA.getInstagram() !== linksB.getInstagram() ||
      linksA.getTwitter() !== linksB.getTwitter()
    );
  };

  // Determine unsaved changes
  const hasUnsavedChanges =
    !props.readOnly &&
    areSocialMediaLinksDifferent(
      currentSocialMediaLinks,
      savedSocialMediaLinks,
    );

  // Handle changes in readOnly prop
  useEffect(() => {
    if (props.readOnly) {
      // Save the current state when switching to readOnly
      setSavedSocialMediaLinks(currentSocialMediaLinks);
    }
  }, [props.readOnly]);

  return (
    <Card
      sx={{
        width: "100%",
        display: "flex",
        flexDirection: "column",
        border: `1px solid ${theme.palette.custom.grape}`,
        backgroundColor: theme.palette.custom.midnight,
        borderRadius: theme.shape.borderRadius,
        p: theme.spacing(2),
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          mb: theme.spacing(2),
        }}
      >
        <Typography variant="h6">Social Media Links</Typography>
        {hasUnsavedChanges && (
          <Box
            sx={{
              width: 8,
              height: 8,
              borderRadius: "50%",
              backgroundColor: theme.palette.warning.light,
            }}
          />
        )}
      </Box>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
        }}
      >
        <TextField
          fullWidth
          id={"instrumentMarketingContent-socialMediaLinks-web-textField"}
          disabled={props.disabled}
          readOnly={props.readOnly}
          label={"Website"}
          value={props.socialMediaLinks.getWeb()}
          placeholder={"Enter Website link..."}
          onChange={(e) => {
            const updatedLinks = new SocialMediaLinks()
              .setWeb(e.target.value)
              .setFacebook(currentSocialMediaLinks.getFacebook())
              .setLinkedin(currentSocialMediaLinks.getLinkedin())
              .setYoutube(currentSocialMediaLinks.getYoutube())
              .setInstagram(currentSocialMediaLinks.getInstagram())
              .setTwitter(currentSocialMediaLinks.getTwitter());
            setCurrentSocialMediaLinks(updatedLinks);
            props.onChange(updatedLinks);
          }}
        />
        <TextField
          fullWidth
          id={"instrumentMarketingContent-socialMediaLinks-facebook-textField"}
          disabled={props.disabled}
          readOnly={props.readOnly}
          label={"Facebook"}
          value={props.socialMediaLinks.getFacebook()}
          placeholder={"Enter Facebook link..."}
          onChange={(e) => {
            const updatedLinks = new SocialMediaLinks()
              .setWeb(currentSocialMediaLinks.getWeb())
              .setFacebook(e.target.value)
              .setLinkedin(currentSocialMediaLinks.getLinkedin())
              .setYoutube(currentSocialMediaLinks.getYoutube())
              .setInstagram(currentSocialMediaLinks.getInstagram())
              .setTwitter(currentSocialMediaLinks.getTwitter());
            setCurrentSocialMediaLinks(updatedLinks);
            props.onChange(updatedLinks);
          }}
        />
        <TextField
          fullWidth
          id={"instrumentMarketingContent-socialMediaLinks-linkedIn-textField"}
          disabled={props.disabled}
          readOnly={props.readOnly}
          label={"LinkedIn"}
          value={props.socialMediaLinks.getLinkedin()}
          placeholder={"Enter LinkedIn link..."}
          onChange={(e) => {
            const updatedLinks = new SocialMediaLinks()
              .setWeb(currentSocialMediaLinks.getWeb())
              .setFacebook(currentSocialMediaLinks.getFacebook())
              .setLinkedin(e.target.value)
              .setYoutube(currentSocialMediaLinks.getYoutube())
              .setInstagram(currentSocialMediaLinks.getInstagram())
              .setTwitter(currentSocialMediaLinks.getTwitter());
            setCurrentSocialMediaLinks(updatedLinks);
            props.onChange(updatedLinks);
          }}
        />
        <TextField
          fullWidth
          id={"instrumentMarketingContent-socialMediaLinks-youtube-textField"}
          disabled={props.disabled}
          readOnly={props.readOnly}
          label={"Youtube"}
          value={props.socialMediaLinks.getYoutube()}
          placeholder={"Enter Youtube link..."}
          onChange={(e) => {
            const updatedLinks = new SocialMediaLinks()
              .setWeb(currentSocialMediaLinks.getWeb())
              .setFacebook(currentSocialMediaLinks.getFacebook())
              .setLinkedin(currentSocialMediaLinks.getLinkedin())
              .setYoutube(e.target.value)
              .setInstagram(currentSocialMediaLinks.getInstagram())
              .setTwitter(currentSocialMediaLinks.getTwitter());
            setCurrentSocialMediaLinks(updatedLinks);
            props.onChange(updatedLinks);
          }}
        />
      </Box>
    </Card>
  );
};

import { AllStellarNetworks, StellarNetwork } from "pkgTemp/stellar/Network";
import { AllBitcoinNetworks, BitcoinNetwork } from "pkgTemp/bitcoin/Network";
import { AllLitecoinNetworks, LitecoinNetwork } from "pkgTemp/litecoin/Network";
import { AllEthereumNetworks, EthereumNetwork } from "pkgTemp/ethereum/Network";
import { AllRippleNetworks, RippleNetwork } from "pkgTemp/ripple/Network";
import { AllCTSENetworks, CTSENetwork } from "pkgTemp/ctse/Network";
import {
  AllInteractiveBrokersNetworks,
  SAStockBrokersNetwork,
} from "pkgTemp/interactiveBrokers/Network";
import { FutureNetwork } from "@mesh/common-js/dist/ledger/futureNetwork_pb";

export type LedgerNetwork =
  | "-"
  | StellarNetwork
  | BitcoinNetwork
  | CTSENetwork
  | SAStockBrokersNetwork
  | LitecoinNetwork
  | EthereumNetwork
  | RippleNetwork;

export const AllLedgerNetworks: LedgerNetwork[] = [
  ...AllStellarNetworks,
  ...AllBitcoinNetworks,
  ...AllCTSENetworks,
  ...AllInteractiveBrokersNetworks,
  ...AllLitecoinNetworks,
  ...AllEthereumNetworks,
  ...AllRippleNetworks,
];

export function networkToFutureNetwork(network: LedgerNetwork): FutureNetwork {
  switch (network) {
    case "-":
      return FutureNetwork.UNDEFINED_NETWORK;

    case StellarNetwork.TestSDFNetwork:
      return FutureNetwork.STELLAR_TEST_SDF_NETWORK;

    case StellarNetwork.PublicNetwork:
      return FutureNetwork.STELLAR_PUBLIC_NETWORK;

    case BitcoinNetwork.TestNetwork:
      return FutureNetwork.BITCOIN_TEST_NETWORK;

    case BitcoinNetwork.PublicNetwork:
      return FutureNetwork.BITCOIN_PUBLIC_NETWORK;

    case SAStockBrokersNetwork.TestNetwork:
      return FutureNetwork.SA_STOCK_BROKERS_TEST_NETWORK;

    case SAStockBrokersNetwork.ProductionNetwork:
      return FutureNetwork.SA_STOCK_BROKERS_PRODUCTION_NETWORK;

    default:
      throw TypeError(`unsupported network: ${network}`);
  }
}

import { Box, Card, Typography, useTheme } from "@mui/material";
import { TextField } from "components/FormFields";
import { ValidationResult } from "common/validation";
import { useEffect, useState } from "react";

export type InvestmentObjectiveFormProps = {
  investmentObjective: string;
  disabled: boolean;
  readOnly: boolean;
  formDataValidationResult: ValidationResult;
  onChange: (updatedInvestmentObjective: string) => void;
};

export const InvestmentObjectiveForm = (
  props: InvestmentObjectiveFormProps,
) => {
  const theme = useTheme();

  // TODO: Add validations
  // const getFieldValidation = () => {
  //   return props.formDataValidationResult.fieldValidations[
  //     `investmentObjective`
  //   ];
  // };

  // State for tracking updates on form
  const [currentInvestmentObjective, setCurrentInvestmentObjective] = useState(
    props.investmentObjective,
  );

  // State for the last saved social media links
  const [savedInvestmentObjective, setSavedInvestmentObjective] = useState(
    props.investmentObjective,
  );

  // Determine unsaved changes
  const hasUnsavedChanges =
    !props.readOnly && currentInvestmentObjective !== savedInvestmentObjective;

  // Handle changes in readOnly prop
  useEffect(() => {
    if (props.readOnly) {
      // Save the current state when switching to readOnly
      setSavedInvestmentObjective(currentInvestmentObjective);
    }
  }, [props.readOnly]);

  return (
    <Card
      sx={{
        width: "100%",
        display: "flex",
        flexDirection: "column",
        border: `1px solid ${theme.palette.custom.grape}`,
        backgroundColor: theme.palette.custom.midnight,
        borderRadius: theme.shape.borderRadius,
        p: theme.spacing(2),
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          mb: theme.spacing(2),
        }}
      >
        <Typography variant="h6">Investment Objective</Typography>
        {hasUnsavedChanges && (
          <Box
            sx={{
              width: 8,
              height: 8,
              borderRadius: "50%",
              backgroundColor: theme.palette.warning.light,
            }}
          />
        )}
      </Box>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
        }}
      >
        <TextField
          fullWidth
          multiline
          rows={8.4}
          id={"instrumentMarketingContent-investmentObjective-textField"}
          disabled={props.disabled}
          readOnly={props.readOnly}
          value={props.investmentObjective}
          placeholder={"Enter investment objective..."}
          onChange={(e) => {
            props.onChange(e.target.value);
            setCurrentInvestmentObjective(e.target.value);
          }}
        />
      </Box>
    </Card>
  );
};

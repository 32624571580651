export class MediaEntry {
  public type: MediaEntryType = MediaEntryType.Undefined;
  public url = "";
  public thumbnailUrl = "";

  constructor(m?: MediaEntry) {
    if (!m) return;

    this.type = m.type;
    this.url = m.url;
    this.thumbnailUrl = m.thumbnailUrl;
  }
}

export enum MediaEntryType {
  Undefined = "undefined",
  Image = "image",
  Video = "video",
  EmbeddedVideo = "embedded video",
}

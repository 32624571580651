import React, { useRef } from "react";
import { styled } from "@mui/material/styles";
import { Cell, Pie, PieChart, Tooltip } from "recharts";
import { Holding } from "james/financial/Holding";
import { getRandomColor } from "utilities/color";
import { formatTextNum } from "utilities/number";
import { Paper, Typography } from "@mui/material";
import { BigNumber } from "bignumber.js";

const PREFIX = "HoldingsPieChart";

const classes = {
  chartToolTipPaper: `${PREFIX}-chartToolTipPaper`,
  chartToolTipText: `${PREFIX}-chartToolTipText`,
};

const StyledPieChart = styled(PieChart)(({ theme }) => ({
  [`& .${classes.chartToolTipPaper}`]: {
    borderRadius: 4,
    border: `solid 1px ${theme.palette.background.default}`,
    padding: theme.spacing(0.5, 1, 0.5, 1),
    display: "grid",
    gridTemplateColumns: "auto",
    backgroundColor: theme.palette.secondary.light,
  },

  [`& .${classes.chartToolTipText}`]: {
    color: theme.palette.background.default,
  },
}));

const otherColour = "#0093D6";

const idealColors = ["#FF8042", "#5F2076", "#FFBB28", "#00C49F", "#003585"];

export interface HoldingsAllocationPieChartProps {
  height: number;
  holdings: Holding[];
}

export function HoldingsPieChart(props: HoldingsAllocationPieChartProps) {
  let allocatedAmount = new BigNumber("0");
  const processedHoldings: Holding[] = [];
  const usedColors = useRef<{ [key: string]: string }>({ Other: otherColour });

  const getRandomColorForKey = (key: string) => {
    // if a color is already stored for this key, use it
    if (usedColors.current[key]) {
      return usedColors.current[key];
    }

    // otherwise check if any of the ideal colors could be used
    for (const c of idealColors) {
      if (!Object.values(usedColors.current).includes(c)) {
        usedColors.current[key] = c;
        return usedColors.current[key];
      }
    }

    // otherwise get a new random color
    usedColors.current[key] = getRandomColor([
      ...idealColors,
      ...Object.values(usedColors.current),
    ]);
    return usedColors.current[key];
  };

  // for every given holding...
  for (const h of props.holdings) {
    // if adding in this holding will result going over 100
    if (allocatedAmount.plus(h.percentage).isGreaterThan(new BigNumber(100))) {
      // then do no more processing
      break;
    }

    // otherwise add to amount allocated
    allocatedAmount = allocatedAmount.plus(h.percentage);

    // and add holding to processed holdings
    processedHoldings.push(h);
  }

  // determine unallocated amount
  const unallocatedAmount = new BigNumber(100).minus(allocatedAmount);
  if (unallocatedAmount) {
    processedHoldings.push(
      new Holding({
        name: "Other",
        percentage: unallocatedAmount,
      }),
    );
  }

  return (
    <StyledPieChart height={props.height} width={props.height}>
      <Pie
        stroke="none"
        data={processedHoldings}
        labelLine={false}
        outerRadius={props.height / 2}
        dataKey={(dataObject) => {
          if (dataObject.payload instanceof Holding) {
            const holding = dataObject.payload as Holding;
            return holding.percentage.toNumber();
          }
          return 0;
        }}
      >
        {processedHoldings.map((entry, idx) => (
          <Cell key={`cell-${idx}`} fill={getRandomColorForKey(entry.name)} />
        ))}
      </Pie>
      <Tooltip
        content={({ active, payload }) => {
          if (active && payload && payload.length && payload[0].payload) {
            if (payload[0].payload.payload instanceof Holding) {
              const holding = payload[0].payload.payload as Holding;
              return (
                <Paper className={classes.chartToolTipPaper}>
                  <Typography
                    variant="body2"
                    className={classes.chartToolTipText}
                    children={`${holding.name}: ${formatTextNum(
                      holding.percentage,
                      { addDecimalPadding: true },
                    )}%`}
                  />
                </Paper>
              );
            }
          }

          return null;
        }}
      />
    </StyledPieChart>
  );
}

import { Router } from "routes";
import { Table, MarketingContent } from "./components";
import { Box } from "@mui/material";
import { useApplicationContext } from "context/Application/Application";

export function Marketing() {
  const { viewConfiguration } = useApplicationContext();
  const system = !!viewConfiguration["Marketing"]?.ReadUNSCOPED;

  return (
    <Router
      baseURL={"/builder/marketing"}
      redirectPath={"/builder/marketing/table"}
      routes={[
        {
          name: "Table",
          id: "table",
          path: "/builder/marketing/table",
          component: (
            <Box sx={(theme) => ({ padding: theme.spacing(2) })}>
              <Table system={system} />
            </Box>
          ),
        },
        {
          name: "Marketing Content",
          id: "marketingContent",
          path: "/builder/marketing/content",
          component: (
            <Box sx={(theme) => ({ padding: theme.spacing(2) })}>
              <MarketingContent system={system} />
            </Box>
          ),
        },
      ]}
    />
  );
}

import React from "react";
import { Box, Grid, Paper, Tab, Tabs } from "@mui/material";
import { RouteType } from "routes/Route";
import { ViewConfiguration } from "james/configuration";
import { useNavigate } from "react-router-dom";
import { Router } from "routes";
import { TransactionBatch } from "./TransactionBatch";
import { useApplicationContext } from "context/Application/Application";
import { Ownership } from "./Ownership";
import { Stellar } from "./Stellar";

const tabRoutes: RouteType[] = [
  {
    name: "Stellar",
    id: "stellar",
    path: "/market-management/ledger/stellar",
    component: <Stellar />,
    allowSubPaths: true,
  },
  {
    name: "Ownership",
    id: "ownership",
    path: "/market-management/ledger/ownership",
    component: <Ownership />,
    allowSubPaths: true,
  },
  {
    name: "Transaction Batch",
    id: "ledger-transaction-batch",
    path: "/market-management/ledger/transaction-batch",
    component: <TransactionBatch />,
    allowSubPaths: true,
  },
];

function determineAvailableTabRoutes(
  viewConfig: ViewConfiguration,
): RouteType[] {
  const ledgerViewConfigurationRules = viewConfig.Ledger;
  if (!ledgerViewConfigurationRules) {
    console.error("no ledger view configuration");
    return [];
  }
  return tabRoutes.filter((route) => ledgerViewConfigurationRules[route.name]);
}

export function Ledger() {
  const navigate = useNavigate();
  const { viewConfiguration } = useApplicationContext();
  const availableTabRoutes = determineAvailableTabRoutes(viewConfiguration);

  // determine and set the active path each time the component renders
  // (initialise to first available route path if current route not valid)
  const currentRoute = availableTabRoutes.find((r) =>
    window.location.pathname.includes(r.path),
  );
  let activePath = availableTabRoutes.length ? availableTabRoutes[0].path : "";
  if (currentRoute) {
    activePath = currentRoute.path;
  }

  return (
    <Box>
      <Paper square>
        <Grid container>
          <Grid item>
            <Tabs
              value={activePath}
              onChange={(_, value) => {
                if (activePath === value) {
                  return;
                }
                navigate(value);
              }}
              textColor={"inherit"}
            >
              {availableTabRoutes.map((t, i) => (
                <Tab key={i} value={t.path} label={t.name} />
              ))}
            </Tabs>
          </Grid>
        </Grid>
      </Paper>
      <Box
        className={"meshScroll"}
        sx={(theme) => ({
          padding: theme.spacing(2),
        })}
      >
        <Router
          baseURL={"/market-management/ledger"}
          redirectPath={"/market-management/ledger/stellar"}
          routes={availableTabRoutes}
        />
      </Box>
    </Box>
  );
}

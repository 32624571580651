import React, { useRef, useState } from "react";
import {
  Avatar,
  Box,
  IconButton,
  Skeleton,
  SxProps,
  TextField,
  TextFieldProps,
  Theme,
  Typography,
  useTheme,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { SkeletonLoader } from "components/SkeletonLoader";
import { AddAPhotoRounded as AddAPhotoIcon } from "@mui/icons-material";
import { useApplicationContext } from "context/Application/Application";

interface EditableInfoProps {
  id?: string;
  label: string;
  value?: string;
  editing?: boolean;
  sx?: SxProps<Theme>;
  noWrap?: boolean;
  loading?: boolean;
  onClear?: () => void;
}

export const EditableInfo = ({
  id,
  label,
  value,
  editing,
  sx,
  noWrap,
  loading,
  onClear,
  ...props
}: EditableInfoProps & TextFieldProps): JSX.Element => {
  const theme = useTheme();

  return (
    <Box
      sx={{
        my: theme.spacing(0.5),
        ...{ sx },
      }}
    >
      {!editing && value && (
        <SkeletonLoader loading={loading ?? false}>
          <Typography
            id={`${id}-label`}
            sx={{
              fontSize: "12px",
              fontWeight: "italic",
              color: theme.palette.text.secondary,
            }}
          >
            {label}
          </Typography>
        </SkeletonLoader>
      )}
      {!editing && value && (
        <SkeletonLoader loading={loading ?? false}>
          <Typography
            id={`${id}-value`}
            sx={{
              fontSize: "16px",
              color: theme.palette.text.primary,
            }}
            noWrap={noWrap ?? true}
          >{`${value}`}</Typography>
        </SkeletonLoader>
      )}
      {!editing && loading && (
        <Skeleton height="18px" width={`${100 + label.length * 2}px`} />
      )}
      {editing && (
        <TextField
          {...props}
          id={`${id}-textField`}
          margin="dense"
          variant="outlined"
          label={label}
          value={value}
          InputProps={{
            endAdornment: (
              <IconButton size={"small"} onClick={onClear}>
                <CloseIcon />
              </IconButton>
            ),
            startAdornment: <></>,
          }}
        />
      )}
    </Box>
  );
};

interface ProfilePictureProps {
  width: number | string;
  height: number | string;
}

export const ProfilePicture = ({
  width,
  height,
}: Partial<ProfilePictureProps>): JSX.Element => {
  const { myProfilePictureURL } = useApplicationContext();
  const profilePictureRef = useRef<HTMLImageElement>(null);
  const [profilePictureVertical, setProfilePictureVertical] = useState(false);

  return myProfilePictureURL ? (
    <Avatar
      sx={{
        width: width ?? "40px",
        height: height ?? "40px",
      }}
    >
      <img
        onLoad={() => {
          if (profilePictureRef.current) {
            setProfilePictureVertical(
              profilePictureRef.current.naturalWidth <
                profilePictureRef.current.naturalHeight,
            );
          }
        }}
        ref={profilePictureRef}
        alt=""
        src={myProfilePictureURL}
        style={{
          width: profilePictureVertical ? (width ?? "40px") : "auto",
          height: !profilePictureVertical ? (height ?? "40px") : "auto",
        }}
      />
    </Avatar>
  ) : (
    <Avatar
      sx={{
        width: width ?? "40px",
        height: height ?? "40px",
        backgroundColor: (theme) => theme.palette.custom.lavender,
      }}
      alt="profile-picture"
    >
      <AddAPhotoIcon />
    </Avatar>
  );
};

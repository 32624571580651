import React, { useEffect, useState } from "react";
import { useErrorContext } from "context/Error";
import { useApplicationContext } from "context/Application/Application";
import {
  Model,
  Model as StellarAccountViewModel,
  Balance,
} from "@mesh/common-js/dist/views/stellarAccountView/model_pb";
import { Amount as LedgerAmount } from "james/ledger";
import { useSnackbar } from "notistack";
import { useStellarContext } from "context/Stellar";
import {
  Box,
  Button,
  Collapse,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  TextFieldProps,
  Tooltip,
  Typography,
  useTheme,
} from "@mui/material";
import {
  CurrencyStablecoin,
  FinancialCurrencyStablecoinRepository,
} from "james/financial";
import { useAccountContext } from "context/Account/Account";
import { TextExactCriterion } from "james/search/criterion";
import { LedgerIDIdentifier } from "james/search/identifier";
import { Clear as ClearIcon, Close as CloseIcon } from "@mui/icons-material";
import { DateField, TextField, TextNumField } from "components/FormFields";
import { Confirmation as ConfirmCancellation } from "views/Banking/components/Funding/components/Confirmation/Cancellation/ConfirmCancelFundingDialog";
import { styled } from "@mui/material/styles";
import { FundingOrder } from "@mesh/common-js/dist/banking/fundingOrder_pb";
import { FundingOrderState } from "@mesh/common-js/dist/banking/fundingOrderState_pb";
import { useFundingContext } from "views/Banking/components/Funding/Context";
import {
  dayjsToProtobufTimestamp,
  protobufTimestampToDayjs,
} from "@mesh/common-js/dist/googleProtobufConverters";
import { Timestamp } from "google-protobuf/google/protobuf/timestamp_pb";
import { dateIsValid } from "utilities/date";
import { useAPIContext } from "context/API";
import { ReadOneModelRequest } from "@mesh/common-js/dist/views/stellarAccountView/modelReader_pb";
import { newTextExactCriterion } from "@mesh/common-js/dist/search";
import { getTokenBalance } from "@mesh/common-js/dist/views/stellarAccountView";

const PREFIX = "EditFunding";

const classes = {
  assetToMintSection: `${PREFIX}-assetToMintSection`,
  detail: `${PREFIX}-detail`,
  dialogTitleContent: `${PREFIX}-dialogTitleContent`,
  dialogTitle: `${PREFIX}-dialogTitle`,
  collapse: `${PREFIX}-collapse`,
  textSearchField: `${PREFIX}-textSearchField`,
  dateFilterField: `${PREFIX}-dateFilterField`,
  iconButton: `${PREFIX}-iconButton`,
};

const StyledDialog = styled(Dialog)(({ theme }) => ({
  [`& .${classes.assetToMintSection}`]: {
    display: "flex",
    marginBottom: theme.spacing(2),
  },

  [`& .${classes.detail}`]: {
    display: "grid",
    gridTemplateColumns: "repeat(3, auto)",
  },

  [`& .${classes.dialogTitleContent}`]: {
    alignItems: "center",
  },

  [`& .${classes.dialogTitle}`]: {
    display: "grid",
    gridTemplateColumns: "auto 1fr",
    alignItems: "center",
    gridColumnGap: theme.spacing(1),
  },

  [`& .${classes.collapse}`]: {
    padding: theme.spacing(0, 2),
    backgroundColor: theme.palette.custom.midnight,
  },

  [`& .${classes.textSearchField}`]: {
    width: 400,
  },

  [`& .${classes.dateFilterField}`]: {
    width: 180,
  },

  [`& .${classes.iconButton}`]: {
    fontSize: 20,
    color: theme.palette.action.disabled,
    "&:hover": {
      color: theme.palette.action.active,
    },
    cursor: "pointer",
  },
}));

interface EditFundingDialogProps {
  open: boolean;
  onClose: () => void;
  fundingOrder: FundingOrder;
  handleApproveFunding: (approve: boolean) => void;
  confirmApproval: boolean;
}

export function EditFundingDialog(props: EditFundingDialogProps) {
  const {
    updateFundingOrderAccountNumber,
    apiCallInProgress,
    setFundingOrder,
  } = useFundingContext();
  const { errorContextErrorTranslator } = useErrorContext();
  const { authContext } = useApplicationContext();
  const [reference, setReference] = useState(
    props.fundingOrder.getAccountnumber(),
  );
  const [collapse, setCollapse] = useState(false);
  const [targetStellarAccountViewModel, setTargetStellarAccountViewModel] =
    useState(new StellarAccountViewModel());
  const [mZARStablecoinBalance, setMZARStablecoinBalance] = useState<
    Balance | undefined
  >(undefined);
  const [confirmCancellation, setConfirmCancellation] = useState(false);
  const [unitsToMint, setUnitsToMint] = useState<LedgerAmount>(
    LedgerAmount.fromFutureAmount(props.fundingOrder.getAmount()),
  );
  const [apiLoading, setApiLoading] = useState(false);
  const [paymentReference, setPaymentReference] = useState(
    props.fundingOrder.getBankreference(),
  );

  const {
    views: {
      stellarAccountViewModelReaderUNSCOPED: UnscopedStellarAccountViewReader,
    },
  } = useAPIContext();

  const [valueDate, setValueDate] = useState(props.fundingOrder.getValuedate());
  const { enqueueSnackbar } = useSnackbar();
  const { stellarAccountContextPopulateModelWithLedgerDetails } =
    useStellarContext();
  const [validationState, setValidationState] = useState<{
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    [key: string]: any;
  }>({});
  const theme = useTheme();
  const [openEditDialog, setOpenEditDialog] = useState(props.open);

  const [editFundingOrder] = useState<FundingOrder>(props.fundingOrder);

  // on dialog open:
  // - Retrieve mZAR stablecoin model
  // - Determine if user is signatory on stablecoin issuance account
  const [initialising, setInitialising] = useState(true);
  const [userSignatoryOnAccount, setUserSignatoryOnAccount] = useState(false);
  const [mZARCurrencyStablecoin, setMZARCurrencyStablecoin] = useState(
    new CurrencyStablecoin(),
  );
  const { stellarAccountContext } = useAccountContext();

  useEffect(() => {
    (async () => {
      if (stellarAccountContext.loading) {
        return;
      }

      if (stellarAccountContext.error) {
        console.error(`initialisation error::${stellarAccountContext.error}`);
        enqueueSnackbar(
          `Initialisation Error: ${stellarAccountContext.error}`,
          {
            variant: "error",
          },
        );

        // close the dialog
        props.onClose();
      }

      // retrieve mZAR stablecoin view model and issuance token
      let retrievedMZARCurrencyStablecoin: CurrencyStablecoin;
      try {
        const currencyStablecoins = (
          await FinancialCurrencyStablecoinRepository.SearchCurrencyStablecoin({
            context: authContext,
            criteria: {
              "token.code": TextExactCriterion("mZAR"),
            },
          })
        ).records;
        if (currencyStablecoins.length !== 1) {
          console.error(
            "unexpected number of currency stablecoins retrieved for mZAR",
          );
          enqueueSnackbar(
            "unexpected number of currency stablecoins retrieved for mZAR",
            { variant: "error" },
          );
          setInitialising(false);
          return;
        }
        retrievedMZARCurrencyStablecoin = currencyStablecoins[0];
        if (!unitsToMint) {
          setUnitsToMint(
            retrievedMZARCurrencyStablecoin.token.newAmountOf("0"),
          );
        }
        setMZARCurrencyStablecoin(currencyStablecoins[0]);
      } catch (e) {
        const err = errorContextErrorTranslator.translateError(e);
        console.error(
          `error retrieving mZAR currency stablecoin: ${
            err.message ? err.message : err.toString()
          }`,
        );
        enqueueSnackbar("Error retrieving mZAR currency stablecoin", {
          variant: "error",
        });
        setInitialising(false);
        return;
      }

      try {
        setUserSignatoryOnAccount(
          await stellarAccountContext.checkUserSignatoryOnAccount(
            LedgerIDIdentifier(retrievedMZARCurrencyStablecoin.token.issuer),
          ),
        );
      } catch (e) {
        const err = errorContextErrorTranslator.translateError(e);
        console.error(
          `error determining if user is signatory on destination account: ${
            err.message ? err.message : err.toString()
          }`,
        );
        enqueueSnackbar("Error Determining Signatory Status", {
          variant: "error",
        });
      }

      if (reference && initialising && retrievedMZARCurrencyStablecoin) {
        await getAccountDetails(retrievedMZARCurrencyStablecoin);
      }

      setInitialising(false);
    })();
  }, [
    targetStellarAccountViewModel,
    authContext,
    enqueueSnackbar,
    stellarAccountContext.loading,
    stellarAccountContext.error,
  ]);

  const performValidations = () => {
    const newValidationState: { [key: string]: string | undefined } = {};

    if (!mZARStablecoinBalance) {
      newValidationState.limit = "Limit not set on destination account.";
    }

    if (unitsToMint.value.isZero()) {
      newValidationState.unitsToMint = "Cannot be zero";
    }

    if (!paymentReference) {
      newValidationState.paymentReference = "Cannot be blank.";
    }

    setValidationState(newValidationState);
    return !Object.keys(newValidationState).length;
  };

  const handleClearValidation = (field: string) => {
    setValidationState({
      ...validationState,
      [field]: undefined,
    });
  };

  const handleClear = () => {
    setReference("");
    setCollapse(false);
  };

  const getAccountDetails = async (currencyStablecoin?: CurrencyStablecoin) => {
    setApiLoading(true);
    setValidationState({});
    try {
      setCollapse(true);
      // retrieve view model of account that is to be funded
      const ledgerAccViewModel =
        await stellarAccountContextPopulateModelWithLedgerDetails(
          (
            await UnscopedStellarAccountViewReader.readOneModelUNSCOPED(
              new ReadOneModelRequest()
                .setContext(authContext.toFuture())
                .setCriteriaList([newTextExactCriterion("number", reference)]),
            )
          ).getModel() ?? new Model(),
        );
      setTargetStellarAccountViewModel(ledgerAccViewModel);

      // look for mZAR balance in balances of the account
      const balance = getTokenBalance(
        ledgerAccViewModel,
        currencyStablecoin
          ? currencyStablecoin.token.toFutureToken()
          : mZARCurrencyStablecoin.token.toFutureToken(),
      );

      setMZARStablecoinBalance(balance);
    } catch (e) {
      setCollapse(false);
      const err = errorContextErrorTranslator.translateError(e);
      console.error(
        `error retrieving target stellar account view model : ${
          err.message ? err.message : err.toString()
        }`,
      );
      enqueueSnackbar(
        `Error retrieving account details: ${
          err.message ? err.message : err.toString()
        }`,
        { variant: "error" },
      );
    }
    editFundingOrder.setAccountnumber(reference);
    setFundingOrder(editFundingOrder);
    setApiLoading(false);
  };

  if (confirmCancellation) {
    return (
      <ConfirmCancellation
        loading={apiLoading}
        onClose={props.onClose}
        fundingOrder={editFundingOrder}
        confirmInfo={confirmCancellation}
      />
    );
  }

  return (
    <StyledDialog open={openEditDialog}>
      <DialogTitle>
        <Grid
          className={classes.dialogTitleContent}
          container
          direction="row"
          justifyContent="space-between"
          alignItems="flex-start"
        >
          <Grid item className={classes.dialogTitle}>
            <Typography variant="h5">Review Funding</Typography>
          </Grid>
          <Grid item>
            <IconButton
              id="fundingOrder-close-button"
              disabled={apiLoading || apiCallInProgress}
              onClick={props.onClose}
              size="small"
            >
              <CloseIcon />
            </IconButton>
          </Grid>
        </Grid>
      </DialogTitle>
      <DialogContent sx={{ width: "570px", paddingBottom: 0 }}>
        <Typography sx={{ padding: "16px 0px", fontSize: "20px" }} variant="h3">
          Client Reference
        </Typography>
        <div className={classes.assetToMintSection}>
          <TextField
            disabled={initialising || apiLoading || apiCallInProgress}
            id="fundingOrder-assetCode-textField"
            label="Asset Code"
            value={mZARCurrencyStablecoin.token.code}
            readOnly
          />
          <TextNumField
            readOnly
            id="fundingOrder-mintAmount-textNumField"
            label="Units to Mint"
            value={unitsToMint.value}
            error={!!validationState.unitsToMint}
            helperText={validationState.unitsToMint}
            disallowNegative
            onChange={(e) => {
              handleClearValidation("unitsToMint");
              setUnitsToMint(
                mZARCurrencyStablecoin.token.newAmountOf(e.target.value),
              );
            }}
          />
        </div>
        <div className={classes.assetToMintSection}>
          <TextField
            disabled={
              initialising || apiLoading || collapse || apiCallInProgress
            }
            fullWidth
            id="fundingOrder-reference-textField"
            onChange={(e) => setReference(e.target.value)}
            label="Client Reference (Account Nr.)"
            value={reference}
          />
          <div
            style={{
              color: "secondary",
              display: "flex",
              paddingLeft: theme.spacing(2),
              alignItems: "center",
            }}
          >
            {collapse ? (
              <Button
                id="fundingOrder-fund-button"
                variant="outlined"
                disabled={apiLoading || initialising || apiCallInProgress}
                onClick={handleClear}
              >
                Clear
              </Button>
            ) : (
              <Button
                id="fundingOrder-fund-button"
                variant="contained"
                color="secondary"
                disabled={apiLoading || initialising || apiCallInProgress}
                onClick={() => getAccountDetails()}
              >
                Fetch
              </Button>
            )}
          </div>
        </div>
      </DialogContent>
      <Collapse className={classes.collapse} in={collapse}>
        {!(apiLoading || apiCallInProgress) && (
          <Typography
            sx={{ padding: "24px 16px 0px 0px", fontSize: "20px" }}
            variant="h3"
          >
            Client Info
          </Typography>
        )}
        {!(apiLoading || apiCallInProgress) && (
          <div className={classes.detail}>
            <TextField
              id="fundingOrder-client-name"
              label="Client"
              readOnly
              value={targetStellarAccountViewModel.getClientname()}
            />
            <TextField
              id="fundingOrder-group-name"
              label="Group"
              readOnly
              value={targetStellarAccountViewModel.getClientname()}
            />
            <TextField
              id="fundingOrder-account-number"
              label="Account Number"
              readOnly
              value={targetStellarAccountViewModel.getNumber()}
            />
            <TextNumField
              id="fundingOrder-stablecoinBalance-textfield"
              label="mZAR Balance"
              readOnly
              error={!mZARStablecoinBalance}
              value={
                LedgerAmount.fromFutureAmount(
                  mZARStablecoinBalance?.getAmount(),
                ).value
              }
            />
            <TextNumField
              id="fundingOrder-stablecoinlimit-textfield"
              label="mZAR limit"
              fullWidth
              readOnly
              value={
                LedgerAmount.fromFutureAmount(mZARStablecoinBalance?.getLimit())
                  .value
              }
              error={!!validationState.limit || !mZARStablecoinBalance}
              helperText={validationState.limit}
            />
            <DateField
              label="Value Date"
              id="fundingOrder-valueDate-dateField"
              readOnly
              className={classes.dateFilterField}
              value={
                valueDate
                  ? protobufTimestampToDayjs(
                      valueDate ?? new Timestamp(),
                    ).format("MM/DD/YYYY")
                  : ""
              }
              onChange={(newValue) => {
                if (!(newValue && dateIsValid(newValue))) {
                  setValueDate(undefined);
                } else {
                  setValueDate(dayjsToProtobufTimestamp(newValue));
                }
              }}
              renderInput={(textFieldProps: TextFieldProps) => (
                <TextField
                  {...textFieldProps}
                  variant="outlined"
                  margin="dense"
                  InputLabelProps={{ shrink: true }}
                  InputProps={{
                    endAdornment: (() => (
                      <>
                        {valueDate && (
                          <Tooltip title="Clear" placement="top">
                            <IconButton
                              className={classes.iconButton}
                              size="small"
                              onClick={() => setValueDate(undefined)}
                            >
                              <ClearIcon />
                            </IconButton>
                          </Tooltip>
                        )}
                        {textFieldProps.InputProps &&
                        textFieldProps.InputProps.endAdornment
                          ? textFieldProps.InputProps.endAdornment
                          : null}
                      </>
                    ))(),
                  }}
                />
              )}
            />
          </div>
        )}
        {!(apiLoading || apiCallInProgress) && (
          <TextField
            id="fundingOrder-stablecoinledgerID-textfield"
            label="Ledger ID"
            fullWidth
            readOnly
            value={targetStellarAccountViewModel.getLedgerid()}
          />
        )}
        {!(apiLoading || apiCallInProgress) && (
          <TextField
            fullWidth
            style={{ marginBottom: theme.spacing(2) }}
            readOnly
            id="fundingOrder-paymentReference"
            label="Bank Transaction Ref."
            value={paymentReference}
            error={!!validationState.paymentReference}
            helperText={validationState.paymentReference}
            onChange={(e) => {
              handleClearValidation("paymentReference");
              setPaymentReference(e.target.value);
            }}
          />
        )}
        {!(apiLoading || apiCallInProgress) && (
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "end",
              gap: "16px",
              marginBottom: 3,
            }}
          >
            <Tooltip
              placement="top"
              title={
                userSignatoryOnAccount
                  ? ""
                  : "user is not a signatory on issuance account"
              }
            >
              <span>
                <Button
                  id="fundingOrder-fundAccount-balance"
                  variant="outlined"
                  fullWidth
                  size="small"
                  onClick={() => setConfirmCancellation(true)}
                  disabled={!userSignatoryOnAccount}
                  children="Cancel funding"
                />
              </span>
            </Tooltip>
            {editFundingOrder.getState() ===
              FundingOrderState.AWAITING_APPROVAL_FUNDING_ORDER_STATE && (
              <Tooltip
                placement="top"
                title={
                  userSignatoryOnAccount
                    ? ""
                    : "user is not a signatory on issuance account"
                }
              >
                <span>
                  <Button
                    id="fundingOrder-fundAccount-balance"
                    variant="contained"
                    color="primary"
                    fullWidth
                    size="small"
                    disabled={!userSignatoryOnAccount}
                    onClick={async () => {
                      performValidations();
                      props.handleApproveFunding(true);
                      setOpenEditDialog(false);
                      await updateFundingOrderAccountNumber(editFundingOrder);
                    }}
                    children="Approve"
                  />
                </span>
              </Tooltip>
            )}
          </Box>
        )}
      </Collapse>
    </StyledDialog>
  );
}

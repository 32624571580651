import { Amount } from "james/ledger";
import { SubscriptionUnderwriting } from "james/market/SubscriptionUnderwriting";
import dayjs from "dayjs";
import { BigNumber } from "bignumber.js";
import { Fee, NewFee } from "james/remuneration/Fee";

export class Model {
  public calculatedAt: string = dayjs().format();
  public settlementAmount: Amount = new Amount();
  public settlementTokens: Amount = new Amount();
  public leftOverAmount: BigNumber = new BigNumber("0");
  public leftOverTokens: BigNumber = new BigNumber("0");
  public subscribedAmount: Amount = new Amount();
  public subscribedTokens: Amount = new Amount();
  public outstandingAmount: Amount = new Amount();
  public outstandingTokens: Amount = new Amount();
  public underwriterTakeUpAmount: Amount = new Amount();
  public underwriterTakeUpTokens: Amount = new Amount();
  public underwriterSettlementAllocations: SubscriptionUnderwriting[] = [];
  public fees: Fee[] = [];

  constructor(l?: Model) {
    if (!l) {
      return;
    }
    this.calculatedAt = l.calculatedAt;
    this.settlementAmount = new Amount(l.settlementAmount);
    this.settlementTokens = new Amount(l.settlementTokens);
    this.leftOverTokens = new BigNumber(l.leftOverTokens);
    this.leftOverAmount = new BigNumber(l.leftOverAmount);
    this.subscribedAmount = new Amount(l.subscribedAmount);
    this.subscribedTokens = new Amount(l.subscribedTokens);
    this.outstandingAmount = new Amount(l.outstandingAmount);
    this.outstandingTokens = new Amount(l.outstandingTokens);
    this.underwriterTakeUpAmount = new Amount(l.underwriterTakeUpAmount);
    this.underwriterTakeUpTokens = new Amount(l.underwriterTakeUpTokens);
    this.underwriterSettlementAllocations =
      l.underwriterSettlementAllocations.map(
        (a) => new SubscriptionUnderwriting(a),
      );
    this.fees = l.fees.map((f) => NewFee(f));
  }
}

/**
 * @fileoverview gRPC-Web generated client stub for stellarops
 * @enhanceable
 * @public
 */

// Code generated by protoc-gen-grpc-web. DO NOT EDIT.
// versions:
// 	protoc-gen-grpc-web v1.5.0
// 	protoc              v5.28.0
// source: ledger/stellarops/accountOperator.proto


/* eslint-disable */
// @ts-nocheck



const grpc = {};
grpc.web = require('grpc-web');


var security_context_pb = require('../../security/context_pb.js')

var search_criterion_pb = require('../../search/criterion_pb.js')

var ledger_futureAmount_pb = require('../../ledger/futureAmount_pb.js')
const proto = {};
proto.stellarops = require('./accountOperator_pb.js');

/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?grpc.web.ClientOptions} options
 * @constructor
 * @struct
 * @final
 */
proto.stellarops.AccountOperatorClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options.format = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname.replace(/\/+$/, '');

};


/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?grpc.web.ClientOptions} options
 * @constructor
 * @struct
 * @final
 */
proto.stellarops.AccountOperatorPromiseClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options.format = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname.replace(/\/+$/, '');

};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.stellarops.SetLimitRequest,
 *   !proto.stellarops.SetLimitResponse>}
 */
const methodDescriptor_AccountOperator_SetLimit = new grpc.web.MethodDescriptor(
  '/stellarops.AccountOperator/SetLimit',
  grpc.web.MethodType.UNARY,
  proto.stellarops.SetLimitRequest,
  proto.stellarops.SetLimitResponse,
  /**
   * @param {!proto.stellarops.SetLimitRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.stellarops.SetLimitResponse.deserializeBinary
);


/**
 * @param {!proto.stellarops.SetLimitRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.stellarops.SetLimitResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.stellarops.SetLimitResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.stellarops.AccountOperatorClient.prototype.setLimit =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/stellarops.AccountOperator/SetLimit',
      request,
      metadata || {},
      methodDescriptor_AccountOperator_SetLimit,
      callback);
};


/**
 * @param {!proto.stellarops.SetLimitRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.stellarops.SetLimitResponse>}
 *     Promise that resolves to the response
 */
proto.stellarops.AccountOperatorPromiseClient.prototype.setLimit =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/stellarops.AccountOperator/SetLimit',
      request,
      metadata || {},
      methodDescriptor_AccountOperator_SetLimit);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.stellarops.AddSignatoriesRequest,
 *   !proto.stellarops.AddSignatoriesResponse>}
 */
const methodDescriptor_AccountOperator_AddSignatories = new grpc.web.MethodDescriptor(
  '/stellarops.AccountOperator/AddSignatories',
  grpc.web.MethodType.UNARY,
  proto.stellarops.AddSignatoriesRequest,
  proto.stellarops.AddSignatoriesResponse,
  /**
   * @param {!proto.stellarops.AddSignatoriesRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.stellarops.AddSignatoriesResponse.deserializeBinary
);


/**
 * @param {!proto.stellarops.AddSignatoriesRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.stellarops.AddSignatoriesResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.stellarops.AddSignatoriesResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.stellarops.AccountOperatorClient.prototype.addSignatories =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/stellarops.AccountOperator/AddSignatories',
      request,
      metadata || {},
      methodDescriptor_AccountOperator_AddSignatories,
      callback);
};


/**
 * @param {!proto.stellarops.AddSignatoriesRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.stellarops.AddSignatoriesResponse>}
 *     Promise that resolves to the response
 */
proto.stellarops.AccountOperatorPromiseClient.prototype.addSignatories =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/stellarops.AccountOperator/AddSignatories',
      request,
      metadata || {},
      methodDescriptor_AccountOperator_AddSignatories);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.stellarops.RemoveSignatoriesRequest,
 *   !proto.stellarops.RemoveSignatoriesResponse>}
 */
const methodDescriptor_AccountOperator_RemoveSignatories = new grpc.web.MethodDescriptor(
  '/stellarops.AccountOperator/RemoveSignatories',
  grpc.web.MethodType.UNARY,
  proto.stellarops.RemoveSignatoriesRequest,
  proto.stellarops.RemoveSignatoriesResponse,
  /**
   * @param {!proto.stellarops.RemoveSignatoriesRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.stellarops.RemoveSignatoriesResponse.deserializeBinary
);


/**
 * @param {!proto.stellarops.RemoveSignatoriesRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.stellarops.RemoveSignatoriesResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.stellarops.RemoveSignatoriesResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.stellarops.AccountOperatorClient.prototype.removeSignatories =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/stellarops.AccountOperator/RemoveSignatories',
      request,
      metadata || {},
      methodDescriptor_AccountOperator_RemoveSignatories,
      callback);
};


/**
 * @param {!proto.stellarops.RemoveSignatoriesRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.stellarops.RemoveSignatoriesResponse>}
 *     Promise that resolves to the response
 */
proto.stellarops.AccountOperatorPromiseClient.prototype.removeSignatories =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/stellarops.AccountOperator/RemoveSignatories',
      request,
      metadata || {},
      methodDescriptor_AccountOperator_RemoveSignatories);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.stellarops.ClaimClaimableBalanceRequest,
 *   !proto.stellarops.ClaimClaimableBalanceResponse>}
 */
const methodDescriptor_AccountOperator_ClaimClaimableBalance = new grpc.web.MethodDescriptor(
  '/stellarops.AccountOperator/ClaimClaimableBalance',
  grpc.web.MethodType.UNARY,
  proto.stellarops.ClaimClaimableBalanceRequest,
  proto.stellarops.ClaimClaimableBalanceResponse,
  /**
   * @param {!proto.stellarops.ClaimClaimableBalanceRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.stellarops.ClaimClaimableBalanceResponse.deserializeBinary
);


/**
 * @param {!proto.stellarops.ClaimClaimableBalanceRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.stellarops.ClaimClaimableBalanceResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.stellarops.ClaimClaimableBalanceResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.stellarops.AccountOperatorClient.prototype.claimClaimableBalance =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/stellarops.AccountOperator/ClaimClaimableBalance',
      request,
      metadata || {},
      methodDescriptor_AccountOperator_ClaimClaimableBalance,
      callback);
};


/**
 * @param {!proto.stellarops.ClaimClaimableBalanceRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.stellarops.ClaimClaimableBalanceResponse>}
 *     Promise that resolves to the response
 */
proto.stellarops.AccountOperatorPromiseClient.prototype.claimClaimableBalance =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/stellarops.AccountOperator/ClaimClaimableBalance',
      request,
      metadata || {},
      methodDescriptor_AccountOperator_ClaimClaimableBalance);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.stellarops.SetHomeDomainRequest,
 *   !proto.stellarops.SetHomeDomainResponse>}
 */
const methodDescriptor_AccountOperator_SetHomeDomain = new grpc.web.MethodDescriptor(
  '/stellarops.AccountOperator/SetHomeDomain',
  grpc.web.MethodType.UNARY,
  proto.stellarops.SetHomeDomainRequest,
  proto.stellarops.SetHomeDomainResponse,
  /**
   * @param {!proto.stellarops.SetHomeDomainRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.stellarops.SetHomeDomainResponse.deserializeBinary
);


/**
 * @param {!proto.stellarops.SetHomeDomainRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.stellarops.SetHomeDomainResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.stellarops.SetHomeDomainResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.stellarops.AccountOperatorClient.prototype.setHomeDomain =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/stellarops.AccountOperator/SetHomeDomain',
      request,
      metadata || {},
      methodDescriptor_AccountOperator_SetHomeDomain,
      callback);
};


/**
 * @param {!proto.stellarops.SetHomeDomainRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.stellarops.SetHomeDomainResponse>}
 *     Promise that resolves to the response
 */
proto.stellarops.AccountOperatorPromiseClient.prototype.setHomeDomain =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/stellarops.AccountOperator/SetHomeDomain',
      request,
      metadata || {},
      methodDescriptor_AccountOperator_SetHomeDomain);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.stellarops.SetAuthRequiredFlagRequest,
 *   !proto.stellarops.SetAuthRequiredFlagResponse>}
 */
const methodDescriptor_AccountOperator_SetAuthRequiredFlag = new grpc.web.MethodDescriptor(
  '/stellarops.AccountOperator/SetAuthRequiredFlag',
  grpc.web.MethodType.UNARY,
  proto.stellarops.SetAuthRequiredFlagRequest,
  proto.stellarops.SetAuthRequiredFlagResponse,
  /**
   * @param {!proto.stellarops.SetAuthRequiredFlagRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.stellarops.SetAuthRequiredFlagResponse.deserializeBinary
);


/**
 * @param {!proto.stellarops.SetAuthRequiredFlagRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.stellarops.SetAuthRequiredFlagResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.stellarops.SetAuthRequiredFlagResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.stellarops.AccountOperatorClient.prototype.setAuthRequiredFlag =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/stellarops.AccountOperator/SetAuthRequiredFlag',
      request,
      metadata || {},
      methodDescriptor_AccountOperator_SetAuthRequiredFlag,
      callback);
};


/**
 * @param {!proto.stellarops.SetAuthRequiredFlagRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.stellarops.SetAuthRequiredFlagResponse>}
 *     Promise that resolves to the response
 */
proto.stellarops.AccountOperatorPromiseClient.prototype.setAuthRequiredFlag =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/stellarops.AccountOperator/SetAuthRequiredFlag',
      request,
      metadata || {},
      methodDescriptor_AccountOperator_SetAuthRequiredFlag);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.stellarops.SetAuthRevocableFlagRequest,
 *   !proto.stellarops.SetAuthRevocableFlagResponse>}
 */
const methodDescriptor_AccountOperator_SetAuthRevocableFlag = new grpc.web.MethodDescriptor(
  '/stellarops.AccountOperator/SetAuthRevocableFlag',
  grpc.web.MethodType.UNARY,
  proto.stellarops.SetAuthRevocableFlagRequest,
  proto.stellarops.SetAuthRevocableFlagResponse,
  /**
   * @param {!proto.stellarops.SetAuthRevocableFlagRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.stellarops.SetAuthRevocableFlagResponse.deserializeBinary
);


/**
 * @param {!proto.stellarops.SetAuthRevocableFlagRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.stellarops.SetAuthRevocableFlagResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.stellarops.SetAuthRevocableFlagResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.stellarops.AccountOperatorClient.prototype.setAuthRevocableFlag =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/stellarops.AccountOperator/SetAuthRevocableFlag',
      request,
      metadata || {},
      methodDescriptor_AccountOperator_SetAuthRevocableFlag,
      callback);
};


/**
 * @param {!proto.stellarops.SetAuthRevocableFlagRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.stellarops.SetAuthRevocableFlagResponse>}
 *     Promise that resolves to the response
 */
proto.stellarops.AccountOperatorPromiseClient.prototype.setAuthRevocableFlag =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/stellarops.AccountOperator/SetAuthRevocableFlag',
      request,
      metadata || {},
      methodDescriptor_AccountOperator_SetAuthRevocableFlag);
};


module.exports = proto.stellarops;


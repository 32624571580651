// source: views/stellarAccountView/model.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global =
    (typeof globalThis !== 'undefined' && globalThis) ||
    (typeof window !== 'undefined' && window) ||
    (typeof global !== 'undefined' && global) ||
    (typeof self !== 'undefined' && self) ||
    (function () { return this; }).call(null) ||
    Function('return this')();

var stellar_enrichedAccount_pb = require('../../stellar/enrichedAccount_pb.js');
goog.object.extend(proto, stellar_enrichedAccount_pb);
var audit_entry_pb = require('../../audit/entry_pb.js');
goog.object.extend(proto, audit_entry_pb);
var stellar_network_pb = require('../../stellar/network_pb.js');
goog.object.extend(proto, stellar_network_pb);
var stellar_amount_pb = require('../../stellar/amount_pb.js');
goog.object.extend(proto, stellar_amount_pb);
var ledger_futureAmount_pb = require('../../ledger/futureAmount_pb.js');
goog.object.extend(proto, ledger_futureAmount_pb);
var ledger_futureToken_pb = require('../../ledger/futureToken_pb.js');
goog.object.extend(proto, ledger_futureToken_pb);
var google_protobuf_timestamp_pb = require('google-protobuf/google/protobuf/timestamp_pb.js');
goog.object.extend(proto, google_protobuf_timestamp_pb);
goog.exportSymbol('proto.stellarAccountView.Balance', null, global);
goog.exportSymbol('proto.stellarAccountView.Model', null, global);
goog.exportSymbol('proto.stellarAccountView.TokenViewModel', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.stellarAccountView.Model = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.stellarAccountView.Model.repeatedFields_, null);
};
goog.inherits(proto.stellarAccountView.Model, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.stellarAccountView.Model.displayName = 'proto.stellarAccountView.Model';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.stellarAccountView.Balance = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.stellarAccountView.Balance, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.stellarAccountView.Balance.displayName = 'proto.stellarAccountView.Balance';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.stellarAccountView.TokenViewModel = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.stellarAccountView.TokenViewModel, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.stellarAccountView.TokenViewModel.displayName = 'proto.stellarAccountView.TokenViewModel';
}

/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.stellarAccountView.Model.repeatedFields_ = [9,10];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.stellarAccountView.Model.prototype.toObject = function(opt_includeInstance) {
  return proto.stellarAccountView.Model.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.stellarAccountView.Model} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.stellarAccountView.Model.toObject = function(includeInstance, msg) {
  var f, obj = {
id: jspb.Message.getFieldWithDefault(msg, 1, ""),
ownerid: jspb.Message.getFieldWithDefault(msg, 2, ""),
number: jspb.Message.getFieldWithDefault(msg, 3, ""),
label: jspb.Message.getFieldWithDefault(msg, 4, ""),
state: jspb.Message.getFieldWithDefault(msg, 5, 0),
network: jspb.Message.getFieldWithDefault(msg, 6, 0),
ledgerid: jspb.Message.getFieldWithDefault(msg, 7, ""),
auditentry: (f = msg.getAuditentry()) && audit_entry_pb.Entry.toObject(includeInstance, f),
balancesList: jspb.Message.toObjectList(msg.getBalancesList(),
    proto.stellarAccountView.Balance.toObject, includeInstance),
signatoriesList: jspb.Message.toObjectList(msg.getSignatoriesList(),
    stellar_enrichedAccount_pb.Signatory.toObject, includeInstance),
authflags: (f = msg.getAuthflags()) && stellar_enrichedAccount_pb.AuthFlags.toObject(includeInstance, f),
groupname: jspb.Message.getFieldWithDefault(msg, 12, ""),
clientname: jspb.Message.getFieldWithDefault(msg, 13, ""),
createdtime: (f = msg.getCreatedtime()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.stellarAccountView.Model}
 */
proto.stellarAccountView.Model.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.stellarAccountView.Model;
  return proto.stellarAccountView.Model.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.stellarAccountView.Model} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.stellarAccountView.Model}
 */
proto.stellarAccountView.Model.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setOwnerid(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setNumber(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setLabel(value);
      break;
    case 5:
      var value = /** @type {!proto.stellar.AccountState} */ (reader.readEnum());
      msg.setState(value);
      break;
    case 6:
      var value = /** @type {!proto.stellar.Network} */ (reader.readEnum());
      msg.setNetwork(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setLedgerid(value);
      break;
    case 8:
      var value = new audit_entry_pb.Entry;
      reader.readMessage(value,audit_entry_pb.Entry.deserializeBinaryFromReader);
      msg.setAuditentry(value);
      break;
    case 9:
      var value = new proto.stellarAccountView.Balance;
      reader.readMessage(value,proto.stellarAccountView.Balance.deserializeBinaryFromReader);
      msg.addBalances(value);
      break;
    case 10:
      var value = new stellar_enrichedAccount_pb.Signatory;
      reader.readMessage(value,stellar_enrichedAccount_pb.Signatory.deserializeBinaryFromReader);
      msg.addSignatories(value);
      break;
    case 11:
      var value = new stellar_enrichedAccount_pb.AuthFlags;
      reader.readMessage(value,stellar_enrichedAccount_pb.AuthFlags.deserializeBinaryFromReader);
      msg.setAuthflags(value);
      break;
    case 12:
      var value = /** @type {string} */ (reader.readString());
      msg.setGroupname(value);
      break;
    case 13:
      var value = /** @type {string} */ (reader.readString());
      msg.setClientname(value);
      break;
    case 14:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setCreatedtime(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.stellarAccountView.Model.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.stellarAccountView.Model.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.stellarAccountView.Model} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.stellarAccountView.Model.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getOwnerid();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getNumber();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getLabel();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getState();
  if (f !== 0.0) {
    writer.writeEnum(
      5,
      f
    );
  }
  f = message.getNetwork();
  if (f !== 0.0) {
    writer.writeEnum(
      6,
      f
    );
  }
  f = message.getLedgerid();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getAuditentry();
  if (f != null) {
    writer.writeMessage(
      8,
      f,
      audit_entry_pb.Entry.serializeBinaryToWriter
    );
  }
  f = message.getBalancesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      9,
      f,
      proto.stellarAccountView.Balance.serializeBinaryToWriter
    );
  }
  f = message.getSignatoriesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      10,
      f,
      stellar_enrichedAccount_pb.Signatory.serializeBinaryToWriter
    );
  }
  f = message.getAuthflags();
  if (f != null) {
    writer.writeMessage(
      11,
      f,
      stellar_enrichedAccount_pb.AuthFlags.serializeBinaryToWriter
    );
  }
  f = message.getGroupname();
  if (f.length > 0) {
    writer.writeString(
      12,
      f
    );
  }
  f = message.getClientname();
  if (f.length > 0) {
    writer.writeString(
      13,
      f
    );
  }
  f = message.getCreatedtime();
  if (f != null) {
    writer.writeMessage(
      14,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.stellarAccountView.Model.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.stellarAccountView.Model} returns this
 */
proto.stellarAccountView.Model.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string ownerID = 2;
 * @return {string}
 */
proto.stellarAccountView.Model.prototype.getOwnerid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.stellarAccountView.Model} returns this
 */
proto.stellarAccountView.Model.prototype.setOwnerid = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string number = 3;
 * @return {string}
 */
proto.stellarAccountView.Model.prototype.getNumber = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.stellarAccountView.Model} returns this
 */
proto.stellarAccountView.Model.prototype.setNumber = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string label = 4;
 * @return {string}
 */
proto.stellarAccountView.Model.prototype.getLabel = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.stellarAccountView.Model} returns this
 */
proto.stellarAccountView.Model.prototype.setLabel = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional stellar.AccountState state = 5;
 * @return {!proto.stellar.AccountState}
 */
proto.stellarAccountView.Model.prototype.getState = function() {
  return /** @type {!proto.stellar.AccountState} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {!proto.stellar.AccountState} value
 * @return {!proto.stellarAccountView.Model} returns this
 */
proto.stellarAccountView.Model.prototype.setState = function(value) {
  return jspb.Message.setProto3EnumField(this, 5, value);
};


/**
 * optional stellar.Network network = 6;
 * @return {!proto.stellar.Network}
 */
proto.stellarAccountView.Model.prototype.getNetwork = function() {
  return /** @type {!proto.stellar.Network} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {!proto.stellar.Network} value
 * @return {!proto.stellarAccountView.Model} returns this
 */
proto.stellarAccountView.Model.prototype.setNetwork = function(value) {
  return jspb.Message.setProto3EnumField(this, 6, value);
};


/**
 * optional string ledgerID = 7;
 * @return {string}
 */
proto.stellarAccountView.Model.prototype.getLedgerid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.stellarAccountView.Model} returns this
 */
proto.stellarAccountView.Model.prototype.setLedgerid = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional audit.Entry auditEntry = 8;
 * @return {?proto.audit.Entry}
 */
proto.stellarAccountView.Model.prototype.getAuditentry = function() {
  return /** @type{?proto.audit.Entry} */ (
    jspb.Message.getWrapperField(this, audit_entry_pb.Entry, 8));
};


/**
 * @param {?proto.audit.Entry|undefined} value
 * @return {!proto.stellarAccountView.Model} returns this
*/
proto.stellarAccountView.Model.prototype.setAuditentry = function(value) {
  return jspb.Message.setWrapperField(this, 8, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.stellarAccountView.Model} returns this
 */
proto.stellarAccountView.Model.prototype.clearAuditentry = function() {
  return this.setAuditentry(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.stellarAccountView.Model.prototype.hasAuditentry = function() {
  return jspb.Message.getField(this, 8) != null;
};


/**
 * repeated Balance balances = 9;
 * @return {!Array<!proto.stellarAccountView.Balance>}
 */
proto.stellarAccountView.Model.prototype.getBalancesList = function() {
  return /** @type{!Array<!proto.stellarAccountView.Balance>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.stellarAccountView.Balance, 9));
};


/**
 * @param {!Array<!proto.stellarAccountView.Balance>} value
 * @return {!proto.stellarAccountView.Model} returns this
*/
proto.stellarAccountView.Model.prototype.setBalancesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 9, value);
};


/**
 * @param {!proto.stellarAccountView.Balance=} opt_value
 * @param {number=} opt_index
 * @return {!proto.stellarAccountView.Balance}
 */
proto.stellarAccountView.Model.prototype.addBalances = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 9, opt_value, proto.stellarAccountView.Balance, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.stellarAccountView.Model} returns this
 */
proto.stellarAccountView.Model.prototype.clearBalancesList = function() {
  return this.setBalancesList([]);
};


/**
 * repeated stellar.Signatory signatories = 10;
 * @return {!Array<!proto.stellar.Signatory>}
 */
proto.stellarAccountView.Model.prototype.getSignatoriesList = function() {
  return /** @type{!Array<!proto.stellar.Signatory>} */ (
    jspb.Message.getRepeatedWrapperField(this, stellar_enrichedAccount_pb.Signatory, 10));
};


/**
 * @param {!Array<!proto.stellar.Signatory>} value
 * @return {!proto.stellarAccountView.Model} returns this
*/
proto.stellarAccountView.Model.prototype.setSignatoriesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 10, value);
};


/**
 * @param {!proto.stellar.Signatory=} opt_value
 * @param {number=} opt_index
 * @return {!proto.stellar.Signatory}
 */
proto.stellarAccountView.Model.prototype.addSignatories = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 10, opt_value, proto.stellar.Signatory, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.stellarAccountView.Model} returns this
 */
proto.stellarAccountView.Model.prototype.clearSignatoriesList = function() {
  return this.setSignatoriesList([]);
};


/**
 * optional stellar.AuthFlags authFlags = 11;
 * @return {?proto.stellar.AuthFlags}
 */
proto.stellarAccountView.Model.prototype.getAuthflags = function() {
  return /** @type{?proto.stellar.AuthFlags} */ (
    jspb.Message.getWrapperField(this, stellar_enrichedAccount_pb.AuthFlags, 11));
};


/**
 * @param {?proto.stellar.AuthFlags|undefined} value
 * @return {!proto.stellarAccountView.Model} returns this
*/
proto.stellarAccountView.Model.prototype.setAuthflags = function(value) {
  return jspb.Message.setWrapperField(this, 11, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.stellarAccountView.Model} returns this
 */
proto.stellarAccountView.Model.prototype.clearAuthflags = function() {
  return this.setAuthflags(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.stellarAccountView.Model.prototype.hasAuthflags = function() {
  return jspb.Message.getField(this, 11) != null;
};


/**
 * optional string groupName = 12;
 * @return {string}
 */
proto.stellarAccountView.Model.prototype.getGroupname = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 12, ""));
};


/**
 * @param {string} value
 * @return {!proto.stellarAccountView.Model} returns this
 */
proto.stellarAccountView.Model.prototype.setGroupname = function(value) {
  return jspb.Message.setProto3StringField(this, 12, value);
};


/**
 * optional string clientName = 13;
 * @return {string}
 */
proto.stellarAccountView.Model.prototype.getClientname = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 13, ""));
};


/**
 * @param {string} value
 * @return {!proto.stellarAccountView.Model} returns this
 */
proto.stellarAccountView.Model.prototype.setClientname = function(value) {
  return jspb.Message.setProto3StringField(this, 13, value);
};


/**
 * optional google.protobuf.Timestamp createdTime = 14;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.stellarAccountView.Model.prototype.getCreatedtime = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 14));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.stellarAccountView.Model} returns this
*/
proto.stellarAccountView.Model.prototype.setCreatedtime = function(value) {
  return jspb.Message.setWrapperField(this, 14, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.stellarAccountView.Model} returns this
 */
proto.stellarAccountView.Model.prototype.clearCreatedtime = function() {
  return this.setCreatedtime(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.stellarAccountView.Model.prototype.hasCreatedtime = function() {
  return jspb.Message.getField(this, 14) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.stellarAccountView.Balance.prototype.toObject = function(opt_includeInstance) {
  return proto.stellarAccountView.Balance.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.stellarAccountView.Balance} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.stellarAccountView.Balance.toObject = function(includeInstance, msg) {
  var f, obj = {
amount: (f = msg.getAmount()) && ledger_futureAmount_pb.FutureAmount.toObject(includeInstance, f),
limit: (f = msg.getLimit()) && ledger_futureAmount_pb.FutureAmount.toObject(includeInstance, f),
sellingliabilities: (f = msg.getSellingliabilities()) && ledger_futureAmount_pb.FutureAmount.toObject(includeInstance, f),
buyingliabilities: (f = msg.getBuyingliabilities()) && ledger_futureAmount_pb.FutureAmount.toObject(includeInstance, f),
sponsor: jspb.Message.getFieldWithDefault(msg, 5, ""),
tokenviewmodel: (f = msg.getTokenviewmodel()) && proto.stellarAccountView.TokenViewModel.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.stellarAccountView.Balance}
 */
proto.stellarAccountView.Balance.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.stellarAccountView.Balance;
  return proto.stellarAccountView.Balance.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.stellarAccountView.Balance} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.stellarAccountView.Balance}
 */
proto.stellarAccountView.Balance.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new ledger_futureAmount_pb.FutureAmount;
      reader.readMessage(value,ledger_futureAmount_pb.FutureAmount.deserializeBinaryFromReader);
      msg.setAmount(value);
      break;
    case 2:
      var value = new ledger_futureAmount_pb.FutureAmount;
      reader.readMessage(value,ledger_futureAmount_pb.FutureAmount.deserializeBinaryFromReader);
      msg.setLimit(value);
      break;
    case 3:
      var value = new ledger_futureAmount_pb.FutureAmount;
      reader.readMessage(value,ledger_futureAmount_pb.FutureAmount.deserializeBinaryFromReader);
      msg.setSellingliabilities(value);
      break;
    case 4:
      var value = new ledger_futureAmount_pb.FutureAmount;
      reader.readMessage(value,ledger_futureAmount_pb.FutureAmount.deserializeBinaryFromReader);
      msg.setBuyingliabilities(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setSponsor(value);
      break;
    case 6:
      var value = new proto.stellarAccountView.TokenViewModel;
      reader.readMessage(value,proto.stellarAccountView.TokenViewModel.deserializeBinaryFromReader);
      msg.setTokenviewmodel(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.stellarAccountView.Balance.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.stellarAccountView.Balance.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.stellarAccountView.Balance} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.stellarAccountView.Balance.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAmount();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      ledger_futureAmount_pb.FutureAmount.serializeBinaryToWriter
    );
  }
  f = message.getLimit();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      ledger_futureAmount_pb.FutureAmount.serializeBinaryToWriter
    );
  }
  f = message.getSellingliabilities();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      ledger_futureAmount_pb.FutureAmount.serializeBinaryToWriter
    );
  }
  f = message.getBuyingliabilities();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      ledger_futureAmount_pb.FutureAmount.serializeBinaryToWriter
    );
  }
  f = message.getSponsor();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getTokenviewmodel();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      proto.stellarAccountView.TokenViewModel.serializeBinaryToWriter
    );
  }
};


/**
 * optional ledger.FutureAmount amount = 1;
 * @return {?proto.ledger.FutureAmount}
 */
proto.stellarAccountView.Balance.prototype.getAmount = function() {
  return /** @type{?proto.ledger.FutureAmount} */ (
    jspb.Message.getWrapperField(this, ledger_futureAmount_pb.FutureAmount, 1));
};


/**
 * @param {?proto.ledger.FutureAmount|undefined} value
 * @return {!proto.stellarAccountView.Balance} returns this
*/
proto.stellarAccountView.Balance.prototype.setAmount = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.stellarAccountView.Balance} returns this
 */
proto.stellarAccountView.Balance.prototype.clearAmount = function() {
  return this.setAmount(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.stellarAccountView.Balance.prototype.hasAmount = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional ledger.FutureAmount limit = 2;
 * @return {?proto.ledger.FutureAmount}
 */
proto.stellarAccountView.Balance.prototype.getLimit = function() {
  return /** @type{?proto.ledger.FutureAmount} */ (
    jspb.Message.getWrapperField(this, ledger_futureAmount_pb.FutureAmount, 2));
};


/**
 * @param {?proto.ledger.FutureAmount|undefined} value
 * @return {!proto.stellarAccountView.Balance} returns this
*/
proto.stellarAccountView.Balance.prototype.setLimit = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.stellarAccountView.Balance} returns this
 */
proto.stellarAccountView.Balance.prototype.clearLimit = function() {
  return this.setLimit(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.stellarAccountView.Balance.prototype.hasLimit = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional ledger.FutureAmount sellingLiabilities = 3;
 * @return {?proto.ledger.FutureAmount}
 */
proto.stellarAccountView.Balance.prototype.getSellingliabilities = function() {
  return /** @type{?proto.ledger.FutureAmount} */ (
    jspb.Message.getWrapperField(this, ledger_futureAmount_pb.FutureAmount, 3));
};


/**
 * @param {?proto.ledger.FutureAmount|undefined} value
 * @return {!proto.stellarAccountView.Balance} returns this
*/
proto.stellarAccountView.Balance.prototype.setSellingliabilities = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.stellarAccountView.Balance} returns this
 */
proto.stellarAccountView.Balance.prototype.clearSellingliabilities = function() {
  return this.setSellingliabilities(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.stellarAccountView.Balance.prototype.hasSellingliabilities = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional ledger.FutureAmount buyingLiabilities = 4;
 * @return {?proto.ledger.FutureAmount}
 */
proto.stellarAccountView.Balance.prototype.getBuyingliabilities = function() {
  return /** @type{?proto.ledger.FutureAmount} */ (
    jspb.Message.getWrapperField(this, ledger_futureAmount_pb.FutureAmount, 4));
};


/**
 * @param {?proto.ledger.FutureAmount|undefined} value
 * @return {!proto.stellarAccountView.Balance} returns this
*/
proto.stellarAccountView.Balance.prototype.setBuyingliabilities = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.stellarAccountView.Balance} returns this
 */
proto.stellarAccountView.Balance.prototype.clearBuyingliabilities = function() {
  return this.setBuyingliabilities(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.stellarAccountView.Balance.prototype.hasBuyingliabilities = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional string sponsor = 5;
 * @return {string}
 */
proto.stellarAccountView.Balance.prototype.getSponsor = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.stellarAccountView.Balance} returns this
 */
proto.stellarAccountView.Balance.prototype.setSponsor = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional TokenViewModel tokenViewModel = 6;
 * @return {?proto.stellarAccountView.TokenViewModel}
 */
proto.stellarAccountView.Balance.prototype.getTokenviewmodel = function() {
  return /** @type{?proto.stellarAccountView.TokenViewModel} */ (
    jspb.Message.getWrapperField(this, proto.stellarAccountView.TokenViewModel, 6));
};


/**
 * @param {?proto.stellarAccountView.TokenViewModel|undefined} value
 * @return {!proto.stellarAccountView.Balance} returns this
*/
proto.stellarAccountView.Balance.prototype.setTokenviewmodel = function(value) {
  return jspb.Message.setWrapperField(this, 6, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.stellarAccountView.Balance} returns this
 */
proto.stellarAccountView.Balance.prototype.clearTokenviewmodel = function() {
  return this.setTokenviewmodel(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.stellarAccountView.Balance.prototype.hasTokenviewmodel = function() {
  return jspb.Message.getField(this, 6) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.stellarAccountView.TokenViewModel.prototype.toObject = function(opt_includeInstance) {
  return proto.stellarAccountView.TokenViewModel.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.stellarAccountView.TokenViewModel} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.stellarAccountView.TokenViewModel.toObject = function(includeInstance, msg) {
  var f, obj = {
id: jspb.Message.getFieldWithDefault(msg, 1, ""),
ownerid: jspb.Message.getFieldWithDefault(msg, 2, ""),
token: (f = msg.getToken()) && ledger_futureToken_pb.FutureToken.toObject(includeInstance, f),
tokencategory: jspb.Message.getFieldWithDefault(msg, 4, ""),
name: jspb.Message.getFieldWithDefault(msg, 5, ""),
issuer: jspb.Message.getFieldWithDefault(msg, 6, ""),
iconurl: jspb.Message.getFieldWithDefault(msg, 7, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.stellarAccountView.TokenViewModel}
 */
proto.stellarAccountView.TokenViewModel.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.stellarAccountView.TokenViewModel;
  return proto.stellarAccountView.TokenViewModel.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.stellarAccountView.TokenViewModel} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.stellarAccountView.TokenViewModel}
 */
proto.stellarAccountView.TokenViewModel.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setOwnerid(value);
      break;
    case 3:
      var value = new ledger_futureToken_pb.FutureToken;
      reader.readMessage(value,ledger_futureToken_pb.FutureToken.deserializeBinaryFromReader);
      msg.setToken(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setTokencategory(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setIssuer(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setIconurl(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.stellarAccountView.TokenViewModel.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.stellarAccountView.TokenViewModel.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.stellarAccountView.TokenViewModel} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.stellarAccountView.TokenViewModel.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getOwnerid();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getToken();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      ledger_futureToken_pb.FutureToken.serializeBinaryToWriter
    );
  }
  f = message.getTokencategory();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getIssuer();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getIconurl();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.stellarAccountView.TokenViewModel.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.stellarAccountView.TokenViewModel} returns this
 */
proto.stellarAccountView.TokenViewModel.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string ownerID = 2;
 * @return {string}
 */
proto.stellarAccountView.TokenViewModel.prototype.getOwnerid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.stellarAccountView.TokenViewModel} returns this
 */
proto.stellarAccountView.TokenViewModel.prototype.setOwnerid = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional ledger.FutureToken token = 3;
 * @return {?proto.ledger.FutureToken}
 */
proto.stellarAccountView.TokenViewModel.prototype.getToken = function() {
  return /** @type{?proto.ledger.FutureToken} */ (
    jspb.Message.getWrapperField(this, ledger_futureToken_pb.FutureToken, 3));
};


/**
 * @param {?proto.ledger.FutureToken|undefined} value
 * @return {!proto.stellarAccountView.TokenViewModel} returns this
*/
proto.stellarAccountView.TokenViewModel.prototype.setToken = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.stellarAccountView.TokenViewModel} returns this
 */
proto.stellarAccountView.TokenViewModel.prototype.clearToken = function() {
  return this.setToken(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.stellarAccountView.TokenViewModel.prototype.hasToken = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional string tokenCategory = 4;
 * @return {string}
 */
proto.stellarAccountView.TokenViewModel.prototype.getTokencategory = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.stellarAccountView.TokenViewModel} returns this
 */
proto.stellarAccountView.TokenViewModel.prototype.setTokencategory = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string name = 5;
 * @return {string}
 */
proto.stellarAccountView.TokenViewModel.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.stellarAccountView.TokenViewModel} returns this
 */
proto.stellarAccountView.TokenViewModel.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string issuer = 6;
 * @return {string}
 */
proto.stellarAccountView.TokenViewModel.prototype.getIssuer = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.stellarAccountView.TokenViewModel} returns this
 */
proto.stellarAccountView.TokenViewModel.prototype.setIssuer = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional string iconURL = 7;
 * @return {string}
 */
proto.stellarAccountView.TokenViewModel.prototype.getIconurl = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.stellarAccountView.TokenViewModel} returns this
 */
proto.stellarAccountView.TokenViewModel.prototype.setIconurl = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


goog.object.extend(exports, proto.stellarAccountView);

import React, { useCallback, useContext, useEffect, useState } from "react";
import { useIsMounted } from "hooks";
import { useApplicationContext } from "context/Application/Application";
import utc from "dayjs/plugin/utc";
import dayjs from "dayjs";
import { useNavigate, useSearchParams } from "react-router-dom";
import { Model as FinancialPaymentViewModel } from "@mesh/common-js/dist/views/financialPaymentView/model_pb";
import { ReadOneModelRequest } from "@mesh/common-js/dist/views/financialPaymentView/modelReader_meshproto_pb";
import { newTextExactCriterion } from "@mesh/common-js/dist/search";
import { AssetHolderLookup } from "@mesh/common-js/dist/financial/assetHolderLookup_pb";
import { ReadOneAssetHolderLookupRequest } from "@mesh/common-js/dist/financial/assetHolderLookupReader_meshproto_pb";
import { PaymentTrigger } from "@mesh/common-js/dist/financial/paymentTrigger_pb";
import { useAPIContext } from "context/API";
import { FetchOnePaymentTriggerRequest } from "@mesh/common-js/dist/financial/paymentTriggerFetcher_pb";

dayjs.extend(utc);

export type PaymentContextType = {
  apiCallInProgress: boolean;

  initialised: boolean;
  initialisationError: string | null;
  clearInitialisationError: () => void;
  reload: () => void;

  payment: FinancialPaymentViewModel;
  paymentTrigger: PaymentTrigger;
  assetHolderLookup: AssetHolderLookup;
};

export const defaultContext: PaymentContextType = {
  apiCallInProgress: false,

  initialised: false,
  initialisationError: null,
  clearInitialisationError: () => null,
  reload: () => null,

  payment: new FinancialPaymentViewModel(),
  paymentTrigger: new PaymentTrigger(),
  assetHolderLookup: new AssetHolderLookup(),
};

const PaymentContext = React.createContext<PaymentContextType>(defaultContext);

export const usePaymentContext = () => useContext(PaymentContext);

export const PaymentContextProvider: React.FC<{
  system: boolean;
  children: React.ReactNode;
}> = ({ system, children }) => {
  const {
    financial: { paymentTriggerFetcher, paymentTriggerFetcherUNSCOPED },
    views: {
      financialPaymentViewModelReader,
      financialPaymentViewModelUNSCOPEDReader,
    },
    ledger: {
      ledgerAssetHolderLookupReader,
      ledgerAssetHolderLookupReaderUNSCOPED,
    },
  } = useAPIContext();
  const navigate = useNavigate();
  const { authContext } = useApplicationContext();
  const isMounted = useIsMounted();
  const [searchParams] = useSearchParams();

  // ----------- payment -----------
  const [paymentLoaded, setPaymentLoaded] = useState(false);
  const [paymentLoadError, setPaymentLoadError] = useState<string | null>(null);
  const [payment, setPayment] = useState<FinancialPaymentViewModel>(
    new FinancialPaymentViewModel(),
  );
  useEffect(() => {
    (async () => {
      // do nothing if:
      // - payment already loaded
      // - if there was an payment loading error
      // - component no longer mounted
      if (paymentLoaded || paymentLoadError || !isMounted()) {
        return;
      }

      // get the id of the payment that is being viewed from the url
      const idFromURL = searchParams.get("id");
      if (!idFromURL) {
        console.error("no payment id found in url");
        navigate({
          pathname: "builder/payments/table",
        });
        return;
      }

      // Fetch an associated payment view model.
      let paymentForState: FinancialPaymentViewModel | undefined;
      try {
        // fetch payment
        paymentForState = (
          await (system
            ? financialPaymentViewModelUNSCOPEDReader.readOneModelUNSCOPED(
                new ReadOneModelRequest()
                  .setContext(authContext.toFuture())
                  .setCriteriaList([
                    newTextExactCriterion("paymentid", idFromURL),
                  ]),
              )
            : financialPaymentViewModelReader.readOneModel(
                new ReadOneModelRequest()
                  .setContext(authContext.toFuture())
                  .setCriteriaList([
                    newTextExactCriterion("paymentid", idFromURL),
                  ]),
              ))
        ).getModel();
      } catch (e) {
        console.error("error loading payment", e);
        setPaymentLoadError("Error Loading Payment - Please try Again.");
        return;
      }
      if (!paymentForState) {
        setPaymentLoadError("Payment not correctly initialised - Try Again.");
        return;
      }
      setPayment(paymentForState);
      setPaymentLoaded(true);
    })();
  }, [paymentLoaded, paymentLoadError, isMounted]);

  // ----------- assetHolderLookup -----------
  const [assetHolderLookupLoaded, setAssetHolderLookupLoaded] = useState(false);
  const [assetHolderLookupLoadError, setAssetHolderLookupLoadError] = useState<
    string | null
  >(null);
  const [assetHolderLookup, setAssetHolderLookup] = useState<AssetHolderLookup>(
    new AssetHolderLookup(),
  );
  useEffect(() => {
    (async () => {
      // do nothing if:
      // - there was a payment load error
      // - payment not yet loaded
      // - assetHolderLookup already loaded
      // - if there was an assetHolderLookup loading error
      // - component no longer mounted
      if (
        paymentLoadError ||
        !paymentLoaded ||
        assetHolderLookupLoaded ||
        assetHolderLookupLoadError ||
        !isMounted()
      ) {
        return;
      }

      // Fetch an associated assetHolderLookup view model.
      let assetHolderLookupForState: AssetHolderLookup | undefined;
      try {
        // fetch assetHolderLookup
        assetHolderLookupForState = (
          await (system
            ? ledgerAssetHolderLookupReaderUNSCOPED.readOneAssetHolderLookupUNSCOPED(
                new ReadOneAssetHolderLookupRequest()
                  .setContext(authContext.toFuture())
                  .setCriteriaList([
                    newTextExactCriterion(
                      "metadata.paymentTriggerID",
                      payment.getTriggerid(),
                    ),
                  ]),
              )
            : ledgerAssetHolderLookupReader.readOneAssetHolderLookup(
                new ReadOneAssetHolderLookupRequest()
                  .setContext(authContext.toFuture())
                  .setCriteriaList([
                    newTextExactCriterion(
                      "metadata.paymentTriggerID",
                      payment.getTriggerid(),
                    ),
                  ]),
              ))
        ).getAssetholderlookup();
      } catch (e) {
        console.error("error loading assetHolderLookup", e);
        setAssetHolderLookupLoadError(
          "Error Loading AssetHolderLookup - Please try Again.",
        );
        return;
      }
      if (!assetHolderLookupForState) {
        setAssetHolderLookupLoadError(
          "AssetHolderLookup not correctly initialised - Try Again.",
        );
        return;
      }
      setAssetHolderLookup(assetHolderLookupForState);
      setAssetHolderLookupLoaded(true);
    })();
  }, [
    paymentLoaded,
    paymentLoadError,
    isMounted,
    assetHolderLookupLoaded,
    assetHolderLookupLoadError,
  ]);

  // ----------- paymentTrigger -----------
  const [paymentTriggerLoaded, setPaymentTriggerLoaded] = useState(false);
  const [paymentTriggerLoadError, setPaymentTriggerLoadError] = useState<
    string | null
  >(null);
  const [paymentTrigger, setPaymentTrigger] = useState<PaymentTrigger>(
    new PaymentTrigger(),
  );
  useEffect(() => {
    (async () => {
      // do nothing if:
      // - there was a payment load error
      // - payment not yet loaded
      // - paymentTrigger already loaded
      // - if there was an paymentTrigger loading error
      // - component no longer mounted
      if (
        paymentLoadError ||
        !paymentLoaded ||
        paymentTriggerLoaded ||
        paymentTriggerLoadError ||
        !isMounted()
      ) {
        return;
      }

      // Fetch an associated paymentTrigger view model.
      let paymentTriggerForState: PaymentTrigger | undefined;
      try {
        // fetch paymentTrigger
        paymentTriggerForState = (
          await (system
            ? paymentTriggerFetcherUNSCOPED.fetchOnePaymentTriggerUNSCOPED(
                new FetchOnePaymentTriggerRequest()
                  .setContext(authContext.toFuture())
                  .setCriteriaList([
                    newTextExactCriterion("id", payment.getTriggerid()),
                  ]),
              )
            : paymentTriggerFetcher.fetchOnePaymentTrigger(
                new FetchOnePaymentTriggerRequest()
                  .setContext(authContext.toFuture())
                  .setCriteriaList([
                    newTextExactCriterion("id", payment.getTriggerid()),
                  ]),
              ))
        ).getPaymenttrigger();
      } catch (e) {
        console.error("error loading paymentTrigger", e);
        setPaymentTriggerLoadError(
          "Error Loading PaymentTrigger - Please try Again.",
        );
        return;
      }
      if (!paymentTriggerForState) {
        setPaymentTriggerLoadError(
          "PaymentTrigger not correctly initialised - Try Again.",
        );
        return;
      }
      setPaymentTrigger(paymentTriggerForState);
      setPaymentTriggerLoaded(true);
    })();
  }, [
    paymentLoaded,
    paymentLoadError,
    isMounted,
    paymentTriggerLoaded,
    paymentTriggerLoadError,
  ]);

  const clearInitialisationError = useCallback(() => {
    setAssetHolderLookupLoadError(null);
    setPaymentLoadError(null);
    setPaymentTriggerLoadError(null);
  }, [paymentLoadError]);

  const reload = useCallback(() => {
    setPaymentLoaded(false);
    setAssetHolderLookupLoaded(false);
    setPaymentTriggerLoaded(false);
  }, [paymentLoaded, assetHolderLookupLoaded, paymentTriggerLoaded]);

  return (
    <PaymentContext.Provider
      value={{
        apiCallInProgress: false,

        initialised:
          paymentLoaded && assetHolderLookupLoaded && paymentTriggerLoaded,
        initialisationError:
          paymentLoadError ||
          assetHolderLookupLoadError ||
          paymentTriggerLoadError,
        clearInitialisationError,
        reload,

        payment,
        paymentTrigger,
        assetHolderLookup,
      }}
    >
      {children}
    </PaymentContext.Provider>
  );
};

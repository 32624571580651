import React from "react";
import { Chip, ChipProps, Theme } from "@mui/material";
import { TransactionState } from "@mesh/common-js/dist/stellar/transaction_pb";
import { transactionStateToString } from "@mesh/common-js/dist/stellar";

export interface TransactionStateChipProps {
  state: TransactionState | "";
  chipProps?: ChipProps;
}

export function TransactionStateChip(props: TransactionStateChipProps) {
  switch (props.state) {
    case TransactionState.DRAFT_TRANSACTION_STATE:
      return (
        <Chip
          {...props.chipProps}
          sx={(theme: Theme) => ({
            backgroundColor: theme.palette.info.main,
          })}
          id={`transactionStateChip-${props.state}-chip`}
          size={"small"}
          label={transactionStateToString(props.state)}
        />
      );

    case TransactionState.SIGNING_IN_PROGRESS_TRANSACTION_STATE:
      return (
        <Chip
          {...props.chipProps}
          sx={(theme: Theme) => ({
            backgroundColor: theme.palette.info.main,
          })}
          id={`transactionStateChip-${props.state}-chip`}
          size={"small"}
          label={transactionStateToString(props.state)}
        />
      );

    case TransactionState.PENDING_TRANSACTION_STATE:
      return (
        <Chip
          {...props.chipProps}
          sx={(theme: Theme) => ({
            backgroundColor: theme.palette.info.main,
          })}
          id={`transactionStateChip-${props.state}-chip`}
          size={"small"}
          label={transactionStateToString(props.state)}
        />
      );

    case TransactionState.SUBMISSION_IN_PROGRESS_TRANSACTION_STATE:
      return (
        <Chip
          {...props.chipProps}
          sx={(theme: Theme) => ({
            backgroundColor: theme.palette.info.main,
          })}
          id={`transactionStateChip-${props.state}-chip`}
          size={"small"}
          label={transactionStateToString(props.state)}
        />
      );

    case TransactionState.FAILED_TRANSACTION_STATE:
      return (
        <Chip
          {...props.chipProps}
          sx={(theme: Theme) => ({
            backgroundColor: theme.palette.warning.main,
          })}
          id={`transactionStateChip-${props.state}-chip`}
          size={"small"}
          label={transactionStateToString(props.state)}
        />
      );

    case TransactionState.INDETERMINATE_TRANSACTION_STATE:
      return (
        <Chip
          {...props.chipProps}
          sx={(theme: Theme) => ({
            backgroundColor: theme.palette.error.main,
          })}
          id={`transactionStateChip-${props.state}-chip`}
          size={"small"}
          label={transactionStateToString(props.state)}
        />
      );

    case TransactionState.SUCCESSFUL_TRANSACTION_STATE:
      return (
        <Chip
          {...props.chipProps}
          sx={(theme: Theme) => ({
            backgroundColor: theme.palette.success.main,
          })}
          id={`transactionStateChip-${props.state}-chip`}
          size={"small"}
          label={transactionStateToString(props.state)}
        />
      );

    default:
      return <div>{props.state}</div>;
  }
}

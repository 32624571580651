import React, { useCallback, useEffect, useRef, useState } from "react";
import {
  Autocomplete,
  Box,
  Button,
  CircularProgress,
  Collapse,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  Skeleton,
  Tooltip,
  Typography,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import {
  Close as CloseIcon,
  ExpandLess as ExpandLessIcon,
  ExpandMore as ExpandMoreIcon,
} from "@mui/icons-material";
import { useSnackbar } from "notistack";
import { IDIdentifier, LedgerIDIdentifier } from "james/search/identifier";
import { Model as StellarAccountViewModel } from "@mesh/common-js/dist/views/stellarAccountView/model_pb";
import { Model as LedgerTokenViewModel } from "james/views/ledgerTokenView";
import {
  AssetTokeniserFeeGenerator,
  GenerateAssetMintingFeesResponse,
} from "james/financial/AssetTokeniserFeeGenerator";
import { Amount, AssetFetcher, LedgerAccountCategory } from "james/ledger";
import { TouchedFields, ValidationResult } from "common/validation";
import { TextField, TextNumField } from "components/FormFields";
import { formatTextNum } from "utilities/number";
import { NumFieldHlpTxt, TextFieldHlpTxt } from "validationHelperText";
import cx from "classnames";
import { ClientKYCStatus } from "james/client";
import { Asset } from "james/financial/Asset";
import { useAccountContext } from "context/Account/Account";
import { useLedgerTokenViewContext } from "context/LedgerTokenView";
import { useApplicationContext } from "context/Application/Application";
import { TransactionNotificationChannel } from "james/ledger/TransactionNotificationChannel";
import {
  TransactionFailedNotification,
  TransactionFailedNotificationTypeName,
  TransactionSubmissionResolutionFailedNotification,
  TransactionSubmissionResolutionFailedNotificationTypeName,
  TransactionSucceededNotification,
  TransactionSucceededNotificationTypeName,
} from "james/ledger/TransactionNotifications";
import { Notification } from "james/notification/Notification";
import { useNotificationContext } from "context/Notification";
import { useErrorContext } from "context/Error";
import { useAPIContext } from "context/API";
import { ReadOneSmartInstrumentRequest } from "@mesh/common-js/dist/financial/smartInstrumentReader_meshproto_pb";
import { newTextExactCriterion } from "@mesh/common-js/dist/search";
import { SmartInstrument } from "@mesh/common-js/dist/financial/smartInstrument_pb";
import {
  AssetTokeniser,
  MintAssetRequest,
  MintAssetResponse,
} from "james/financial/AssetTokeniser";
import { AssetWrapper } from "james/financial/AssetWrapper";
import { getTokenBalance } from "@mesh/common-js/dist/views/stellarAccountView";

const PREFIX = "MintAssetDialog";

const classes = {
  mintDialogTitle: `${PREFIX}-mintDialogTitle`,
  titleSkeletonLayout: `${PREFIX}-titleSkeletonLayout`,
  dialogContentRootOverride: `${PREFIX}-dialogContentRootOverride`,
  fieldLayout: `${PREFIX}-fieldLayout`,
  feeUnitsAvailableHelperText: `${PREFIX}-feeUnitsAvailableHelperText`,
  feeLayout: `${PREFIX}-feeLayout`,
  loadingSkeletonLayout: `${PREFIX}-loadingSkeletonLayout`,
  feeControlRowLayout: `${PREFIX}-feeControlRowLayout`,
  feeDetailLineItemsWrapper: `${PREFIX}-feeDetailLineItemsWrapper`,
  feeDetailBodyLineItemLayout: `${PREFIX}-feeDetailBodyLineItemLayout`,
  feeDetailBodyWhyTheseFeesLink: `${PREFIX}-feeDetailBodyWhyTheseFeesLink`,
  feeDetailBodyWhyTheseFeesLinkDisabled: `${PREFIX}-feeDetailBodyWhyTheseFeesLinkDisabled`,
  feeDetailAccAndFeeRow: `${PREFIX}-feeDetailAccAndFeeRow`,
  tokenAmount: `${PREFIX}-tokenAmount`,
  tokenAmountIssuer: `${PREFIX}-tokenAmountIssuer`,
  disabledText: `${PREFIX}-disabledText`,
  readOnlyTextField: `${PREFIX}-readOnlyTextField`,
};

const StyledDialog = styled(Dialog)(({ theme }) => ({
  [`& .${classes.mintDialogTitle}`]: {
    display: "grid",
    height: 50,
    gridTemplateColumns: "1fr auto",
    padding: `${theme.spacing(1)} ${theme.spacing(1)} ${theme.spacing(
      1,
    )} ${theme.spacing(3)}`,
    alignItems: "center",
    borderBottom: `1px solid ${theme.palette.divider}`,
  },

  [`& .${classes.titleSkeletonLayout}`]: {
    marginTop: -4,
  },

  [`& .${classes.dialogContentRootOverride}`]: {
    width: 499,
    padding: 0,
  },

  //
  // field section
  //
  [`& .${classes.fieldLayout}`]: {
    height: 152,
    padding: theme.spacing(3),
    display: "grid",
    gridTemplateColumns: "repeat(2, 1fr)",
    columnGap: theme.spacing(5),
  },

  [`& .${classes.feeUnitsAvailableHelperText}`]: {
    margin: 0,
    padding: theme.spacing(0, 0, 0, 1.5),
  },

  //
  // fee section
  //
  [`& .${classes.feeLayout}`]: {
    borderTop: `1px solid ${theme.palette.divider}`,
  },

  [`& .${classes.loadingSkeletonLayout}`]: {
    height: 68,
    display: "grid",
    alignItems: "center",
    marginTop: -4,
    paddingLeft: theme.spacing(3),
  },

  [`& .${classes.feeControlRowLayout}`]: {
    padding: theme.spacing(1, 3, 1, 1),
    display: "grid",
    alignItems: "center",
    gridTemplateColumns: "auto auto auto 1fr",
    columnGap: theme.spacing(1),
  },

  [`& .${classes.feeDetailLineItemsWrapper}`]: {
    height: 150,
    display: "grid",
    alignItems: "center",
    paddingLeft: theme.spacing(8),
  },

  [`& .${classes.feeDetailBodyLineItemLayout}`]: {
    display: "grid",
    columnGap: theme.spacing(1),
    gridTemplateColumns: "180px auto",
  },

  [`& .${classes.feeDetailBodyWhyTheseFeesLink}`]: {
    marginTop: theme.spacing(1),
    cursor: "pointer",
    "&:hover": {
      textDecoration: "underline",
    },
  },

  [`& .${classes.feeDetailBodyWhyTheseFeesLinkDisabled}`]: {
    marginTop: theme.spacing(1),
    color: theme.palette.action.disabled,
  },

  [`& .${classes.feeDetailAccAndFeeRow}`]: {
    display: "grid",
    alignItems: "center",
    gridTemplateColumns: "auto 1fr auto auto",
    columnGap: theme.spacing(1),
    padding: theme.spacing(3),
  },

  //
  // other
  //
  [`& .${classes.tokenAmount}`]: {
    display: "grid",
    gridTemplateColumns: "auto 1fr",
    columnGap: theme.spacing(0.5),
  },

  [`& .${classes.tokenAmountIssuer}`]: {
    cursor: "pointer",
    "&:hover": {
      color: theme.palette.primary.light,
    },
  },

  [`& .${classes.disabledText}`]: {
    color: theme.palette.action.disabled,
  },

  [`& .${classes.readOnlyTextField}`]: {
    marginLeft: theme.spacing(2),
  },
}));

export interface MintDialogProps {
  onCloseClick: () => void;
  onAwayClick?: () => void;
  onMint?: () => void;
  assetToMintID: string;
}

function validateMintAssetRequest(
  asset: Asset,
  request: MintAssetRequest,
  touchedFields: TouchedFields,
  ignoreTouchedFields: boolean,
): ValidationResult {
  // prepare a validation result
  const validationResult: ValidationResult = {
    // assumed to be true -
    // any error must set to false regardless of touched field state
    valid: true,
    // field validations
    fieldValidations: {},
  };

  //
  // amount
  //
  // if the amount is 0
  if (request.amount.value.isZero()) {
    // then the request is not valid
    validationResult.valid = false;

    // and if the field has been touched
    if (ignoreTouchedFields || touchedFields.amount) {
      // then an error message should be shown on it
      validationResult.fieldValidations.amount =
        NumFieldHlpTxt.MustBeGreaterThan0;
    }
  }

  //
  // destinationAccountID
  //
  // if destination account is not set
  if (!request.destinationAccountID) {
    // then the request is not valid
    validationResult.valid = false;

    // and if the field has been touched
    if (ignoreTouchedFields || touchedFields.destinationAccountID) {
      // then an error message should be shown on it
      validationResult.fieldValidations.destinationAccountID =
        TextFieldHlpTxt.CannotBeBlank;
    }
  }

  return validationResult;
}

export function MintAssetDialog(props: MintDialogProps) {
  const { getLedgerTokenViewModel } = useLedgerTokenViewContext();
  const { authContext, myClientKYCStatus } = useApplicationContext();
  const [initialLoading, setInitialLoading] = useState(true);
  const [mintingInProgress, setMintingInProgress] = useState(false);
  const [feeGenerationInProgress, setFeeGenerationInProgress] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const [assetToMint, setAssetToMint] = useState<AssetWrapper | undefined>(
    undefined,
  );
  const { stellarAccountContext } = useAccountContext();
  const { registerNotificationCallback } = useNotificationContext();
  const {
    financial: { smartInstrumentReader },
  } = useAPIContext();

  const [
    potentialTradingAccountViewModels,
    setPotentialTradingAccountViewModels,
  ] = useState<StellarAccountViewModel[]>([]);
  const [
    userIsSignatoryOnDestinationAccount,
    setUserIsSignatoryOnDestinationAccount,
  ] = useState(false);
  const [
    destinationAccSignatoryCheckInProgress,
    setDestinationAccSignatoryCheckInProgress,
  ] = useState(false);

  const feeGenerationTimeoutRef = useRef<NodeJS.Timeout | undefined>(undefined);
  const [feeAccountViewModel, setFeeAccountViewModel] = useState(
    new StellarAccountViewModel(),
  );
  const [
    userIsSignatoryOnIssuanceAccount,
    setUserIsSignatoryOnIssuanceAccount,
  ] = useState(false);
  const [feeAccountFeeAssetBalance, setFeeAccountFeeAssetBalance] =
    useState<Amount>(new Amount());

  const [validationInProgress, setValidationInProgress] = useState(false);
  const [showFeeDetail, setShowFeeDetail] = useState(false);
  const [mintAssetRequest, setMintAssetRequest] = useState<MintAssetRequest>({
    context: authContext,
    assetID: "",
    amount: new Amount(),
    destinationAccountID: "",
  });
  const [touchedFields, setTouchedFields] = useState<TouchedFields>({});
  const [generateAssetMintingFeeResponse, setGenerateAssetMintingFeeResponse] =
    useState<GenerateAssetMintingFeesResponse>({
      fees: [],
    });
  const [feeTokenViewModel, setFeeTokenViewModel] =
    useState<LedgerTokenViewModel>(new LedgerTokenViewModel());
  const [feeTotal, setFeeTotal] = useState<Amount>(new Amount());
  const [mintRequestValidationResult, setMintRequestValidationResult] =
    useState<ValidationResult>({
      valid: false,
      fieldValidations: {},
    });
  const validationTimeoutRef = useRef<NodeJS.Timeout | undefined>(undefined);
  const { errorContextErrorTranslator } = useErrorContext();
  const kycStatusVerified =
    myClientKYCStatus === ClientKYCStatus.VerifiedStatus;

  useEffect(() => {
    (async () => {
      // if asset has already been retrieved
      if (assetToMint) {
        // then do nothing
        return;
      }

      // prepare initial mint request
      const initialMintRequest: MintAssetRequest = {
        context: authContext,
        assetID: props.assetToMintID,
        destinationAccountID: "",
        amount: new Amount(),
      };

      // retrieve the asset that is to be minted
      let retrievedAsset: AssetWrapper;
      try {
        // first try and retrieve asset as a smart instrument
        const retrievedSmartInstrument = (
          await smartInstrumentReader.readOneSmartInstrument(
            new ReadOneSmartInstrumentRequest()
              .setContext(authContext.toFuture())
              .setCriteriaList([
                newTextExactCriterion("id", props.assetToMintID),
              ]),
          )
        ).getSmartinstrument();
        if (!retrievedSmartInstrument) {
          throw new Error("smart instrument not set after retrieval");
        }
        retrievedAsset = new AssetWrapper(retrievedSmartInstrument);
      } catch (e) {
        if (`${e}`.includes("not found")) {
          try {
            const retrievedOldAsset = (
              await AssetFetcher.FetchAsset({
                context: authContext,
                identifier: IDIdentifier(props.assetToMintID),
              })
            ).asset;
            retrievedAsset = new AssetWrapper(retrievedOldAsset);
          } catch (e) {
            const err = errorContextErrorTranslator.translateError(e);
            console.error(
              `error retrieving asset: ${
                err.message ? err.message : err.toString()
              }`,
            );
            enqueueSnackbar(
              `Error Retrieving Asset: ${
                err.message ? err.message : err.toString()
              }`,
              { variant: "error" },
            );
            setInitialLoading(false);
            return;
          }
        } else {
          const err = errorContextErrorTranslator.translateError(e);
          console.error(
            `error retrieving asset: ${
              err.message ? err.message : err.toString()
            }`,
          );
          enqueueSnackbar(
            `Error Retrieving Asset: ${
              err.message ? err.message : err.toString()
            }`,
            { variant: "error" },
          );
          setInitialLoading(false);
          return;
        }
      }
      // If execution reaches here then the asset's issuance token has been retrieved
      // initialise amount in the mint request.
      initialMintRequest.amount = retrievedAsset.assetToken().newAmountOf("0");

      // fetching all of the trading accounts owned by the same group that owns the asset being minted
      const updatedPotentialTradingAccountModels =
        stellarAccountContext.accounts.filter(
          (v) =>
            v.getOwnerid() === retrievedAsset.assetOwnerID() &&
            v.getLabel() === LedgerAccountCategory.Trading,
        );

      if (!updatedPotentialTradingAccountModels.length) {
        enqueueSnackbar("Error Retrieving Trading Accounts", {
          variant: "error",
        });
        return;
      }

      // initialise destination to first retrieved account
      initialMintRequest.destinationAccountID =
        updatedPotentialTradingAccountModels[0].getId();

      // set all potential destination accounts
      setPotentialTradingAccountViewModels(
        updatedPotentialTradingAccountModels,
      );

      // look for the trading account of the group that owns the asset
      // since it should be among those retrieved
      const retrievedFeeAccountViewModel =
        updatedPotentialTradingAccountModels.find(
          (fa) => fa.getOwnerid() === retrievedAsset.assetOwnerID(),
        );
      if (!retrievedFeeAccountViewModel) {
        enqueueSnackbar("Error Retrieving Fee Account", {
          variant: "error",
        });
        return;
      }
      setFeeAccountViewModel(retrievedFeeAccountViewModel);

      // determine if the executing user is a signatory on the
      // asset issuance account
      try {
        setUserIsSignatoryOnIssuanceAccount(
          await stellarAccountContext.checkUserSignatoryOnAccount(
            LedgerIDIdentifier(retrievedAsset.assetToken().issuer),
          ),
        );
      } catch (e) {
        const err = errorContextErrorTranslator.translateError(e);
        console.error(
          `error determining if user is a signatory on the issuance account: ${
            err.message ? err.message : err.toString()
          }`,
        );
        enqueueSnackbar("Error Determining Issuance Account Signing Rights", {
          variant: "error",
        });
      }

      setAssetToMint(retrievedAsset);
      setMintAssetRequest(initialMintRequest);
      setInitialLoading(false);
    })();
  }, [
    stellarAccountContext.loading,
    stellarAccountContext.error,
    authContext,
    props.assetToMintID,
    assetToMint,
    enqueueSnackbar,
  ]);

  // --------------------------------------------------------------------------
  //  Minting Request Update
  // --------------------------------------------------------------------------
  type MintAssetRequestField = keyof MintAssetRequest;
  type MintAssetRequestValue<T extends MintAssetRequestField> =
    MintAssetRequest[T];
  const handleUpdateMintAssetRequest =
    (field: string, fieldsAffected?: string[]) =>
    <T extends MintAssetRequestField>(newValue: MintAssetRequestValue<T>) => {
      if (!assetToMint) {
        return;
      }

      // prepare updated request
      const updatedMintAssetRequest = {
        ...mintAssetRequest,
        [field]: newValue,
      };

      // prepare updated touched fields
      const updatedTouchedFields = {
        ...touchedFields,
        [field]: true,
      };
      if (fieldsAffected) {
        fieldsAffected.forEach((f) => {
          updatedTouchedFields[f] = true;
        });
      }

      // set updated touched fields
      setTouchedFields(updatedTouchedFields);

      // clear any pending validation
      clearTimeout(validationTimeoutRef.current);

      // defer validation to take place in 200ms
      setValidationInProgress(true);
      clearTimeout(validationTimeoutRef.current);
      validationTimeoutRef.current = setTimeout(() => {
        setMintRequestValidationResult(
          validateMintAssetRequest(
            assetToMint,
            updatedMintAssetRequest,
            updatedTouchedFields,
            false,
          ),
        );
        setValidationInProgress(false);
      }, 800);

      return setMintAssetRequest(updatedMintAssetRequest);
    };

  // --------------------------------------------------------------------------
  //  Fee Generation and Balance check
  // --------------------------------------------------------------------------
  useEffect(() => {
    // defer fee generation to take place in 500ms
    setFeeGenerationInProgress(true);
    clearTimeout(feeGenerationTimeoutRef.current);
    feeGenerationTimeoutRef.current = setTimeout(async () => {
      if (!assetToMint || !feeAccountViewModel.getId()) {
        setFeeGenerationInProgress(false);
        return;
      }

      try {
        // generate updated fees
        let response: GenerateAssetMintingFeesResponse;
        const wrappedAsset = assetToMint.wrappedAsset;
        if (wrappedAsset instanceof SmartInstrument) {
          response = { fees: [] }; // Free Minting for smart instruments for now
        } else if (wrappedAsset) {
          response = await AssetTokeniserFeeGenerator.GenerateAssetMintingFees({
            context: authContext,
            asset: wrappedAsset,
            noTokensToMint: mintAssetRequest.amount,
          });
        } else {
          throw new Error("asset is not set in asset");
        }

        // if any fees are returned
        if (response.fees.length) {
          // then retrieve a model of the fee token in which fees are to be paid
          // (this assumes all fees are paid in the same token)
          setFeeTokenViewModel(
            await getLedgerTokenViewModel(response.fees[0].feeTotal().token),
          );

          // get fee token, look for balance in fee acc, and set fee balance
          const feeBalance = getTokenBalance(
            feeAccountViewModel,
            response.fees[0].feeAmount().token.toFutureToken(),
          );
          if (feeBalance) {
            setFeeAccountFeeAssetBalance(
              Amount.fromFutureAmount(feeBalance.getAmount()),
            );
          } else {
            setFeeAccountFeeAssetBalance(
              response.fees[0].feeAmount().token.newAmountOf("0"),
            );
          }

          // set fee total
          setFeeTotal(
            response.fees.reduce(
              (total, fee) =>
                fee
                  .feeAmount()
                  .setValue(fee.feeTotal().value.plus(total.value)),
              response.fees[0].feeAmount().token.newAmountOf("0"),
            ),
          );
        }

        // set updated fees
        setGenerateAssetMintingFeeResponse(response);
      } catch (e) {
        console.error(`error generating asset minting fee: ${e}`);
        enqueueSnackbar("Error Calculating Minting Fee", { variant: "error" });
      }
      setFeeGenerationInProgress(false);
    }, 500);
  }, [
    feeAccountViewModel,
    enqueueSnackbar,
    mintAssetRequest.amount,
    assetToMint,
    authContext,
  ]);

  const monitorMintTransaction = useCallback(
    async (transactionID: string) => {
      // register callback to fire once the mint transaction has settled
      const deregister = await registerNotificationCallback(
        new TransactionNotificationChannel({
          transactionID: transactionID,
          private: true,
        }),
        [
          TransactionSucceededNotificationTypeName,
          TransactionFailedNotificationTypeName,
          TransactionSubmissionResolutionFailedNotificationTypeName,
        ],
        (n: Notification) => {
          if (
            n instanceof TransactionSucceededNotification &&
            n.transactionID === transactionID
          ) {
            enqueueSnackbar(
              `Success! ${formatTextNum(mintAssetRequest.amount.value)} ${
                mintAssetRequest.amount.token.code
              } minted`,
              {
                variant: "success",
              },
            );
            if (props.onMint) {
              props.onMint();
            }
          }

          if (
            n instanceof TransactionFailedNotification &&
            n.transactionID === transactionID
          ) {
            enqueueSnackbar(
              `Error! ${mintAssetRequest.amount.token.code} mint failed`,
              {
                variant: "error",
              },
            );
          }

          if (
            n instanceof TransactionSubmissionResolutionFailedNotification &&
            n.transactionID === transactionID
          ) {
            enqueueSnackbar(
              "Warning! Something has gone wrong with the mint and its being investigated",
              { variant: "warning" },
            );
          }
          deregister();
          setMintingInProgress(false);
          props.onCloseClick();
        },
      );
    },
    [
      registerNotificationCallback,
      enqueueSnackbar,
      props.onCloseClick,
      props.onMint,
      mintAssetRequest,
    ],
  );

  // --------------------------------------------------------------------------
  //  Destination Account Signatory Check and Fee Amount Update
  //  This will run each time the destination account is changed
  // --------------------------------------------------------------------------
  useEffect(() => {
    (async () => {
      if (!mintAssetRequest.destinationAccountID) {
        setUserIsSignatoryOnDestinationAccount(false);
        return;
      }

      // defer checks to take place in 500ms
      setDestinationAccSignatoryCheckInProgress(true);
      // check if user is a signatory on the account
      try {
        setUserIsSignatoryOnDestinationAccount(
          await stellarAccountContext.checkUserSignatoryOnAccount(
            IDIdentifier(mintAssetRequest.destinationAccountID),
          ),
        );
      } catch (e) {
        const err = errorContextErrorTranslator.translateError(e);
        console.error(
          `error determining if user is signatory on destination account: ${
            err.message ? err.message : err.toString()
          }`,
        );
        enqueueSnackbar("Error Determining Signatory Status", {
          variant: "error",
        });
      }
      setDestinationAccSignatoryCheckInProgress(false);
    })();
  }, [enqueueSnackbar, mintAssetRequest.destinationAccountID, authContext]);

  return (
    <StyledDialog
      open
      onClose={props.onAwayClick ? props.onAwayClick : undefined}
      maxWidth="lg"
    >
      <DialogTitle classes={{ root: classes.mintDialogTitle }}>
        <Grid container direction="row" spacing={1} alignItems="center">
          {initialLoading ? (
            <Grid item className={classes.titleSkeletonLayout}>
              <Skeleton height={40} width={100} />
            </Grid>
          ) : (
            <Grid item>
              <Typography variant="h5" children="Mint Tokens" />
            </Grid>
          )}
          {mintingInProgress && (
            <Grid item>
              <CircularProgress size={20} />
            </Grid>
          )}
        </Grid>
        <Grid container direction="row" spacing={1} alignItems="center">
          {!initialLoading && (
            <Grid item>
              <Tooltip
                placement="top"
                title={(() => {
                  switch (true) {
                    case destinationAccSignatoryCheckInProgress:
                      return "Checking if you are a signatory on destination account...";

                    case feeGenerationInProgress:
                      return "Fee calculation in progress...";

                    case !mintRequestValidationResult.valid:
                      return "All fields must be completed";

                    case !userIsSignatoryOnIssuanceAccount:
                      return "You are not a signatory on the issuance account";

                    case !userIsSignatoryOnDestinationAccount:
                      return "You are not a signatory on the destination trading account";

                    case feeAccountFeeAssetBalance.value.lt(feeTotal.value):
                      return "Insufficient balance in Fee Acc";
                  }

                  return "";
                })()}
              >
                <span>
                  <Button
                    id="mintAssetDialog-mint-button"
                    variant="contained"
                    children="mint"
                    color="primary"
                    disabled={
                      initialLoading ||
                      validationInProgress ||
                      mintingInProgress ||
                      destinationAccSignatoryCheckInProgress ||
                      feeGenerationInProgress ||
                      !mintRequestValidationResult.valid ||
                      !userIsSignatoryOnIssuanceAccount ||
                      !userIsSignatoryOnDestinationAccount ||
                      feeAccountFeeAssetBalance.value.lt(feeTotal.value)
                    }
                    onClick={async () => {
                      if (!kycStatusVerified) {
                        enqueueSnackbar(
                          "A Verified KYC Status is Required to Perform Minting",
                          { variant: "warning" },
                        );
                        return;
                      }

                      if (!assetToMint) {
                        return;
                      }

                      setMintingInProgress(true);

                      // perform minting
                      let mintAssetResponse: MintAssetResponse;
                      try {
                        mintAssetResponse =
                          await AssetTokeniser.MintAsset(mintAssetRequest);
                      } catch (e) {
                        console.error(`error minting asset`, e);
                        enqueueSnackbar("Error Minting", { variant: "error" });
                        setMintingInProgress(false);
                        return;
                      }

                      try {
                        await monitorMintTransaction(
                          mintAssetResponse.transactionID,
                        );
                      } catch (e) {
                        console.error(
                          "error registering for minting notifications",
                          e,
                        );
                        enqueueSnackbar(
                          "Warning! Unable to Register for Minting Transaction Notifications - Please Refresh to Monitor.",
                          { variant: "warning" },
                        );
                      }
                    }}
                  />
                </span>
              </Tooltip>
            </Grid>
          )}
          <Grid item>
            <Tooltip title="Close" placement="top">
              <span>
                <IconButton
                  size="small"
                  disabled={initialLoading || mintingInProgress}
                  id="mintDialog-close-button"
                  onClick={props.onCloseClick}
                >
                  <CloseIcon />
                </IconButton>
              </span>
            </Tooltip>
          </Grid>
        </Grid>
      </DialogTitle>
      <DialogContent className={classes.dialogContentRootOverride}>
        <div className={classes.fieldLayout}>
          {(() => {
            if (initialLoading || !assetToMint) {
              return (
                <>
                  <Box alignItems="top">
                    <Skeleton height={50} />
                    <Skeleton height={50} />
                  </Box>
                  <Box>
                    <Skeleton height={50} />
                    <Skeleton height={18} width="60%" />
                  </Box>
                </>
              );
            }
            return (
              <>
                <div>
                  <TextField
                    className={classes.readOnlyTextField}
                    id="mintAssetDialog-assetCode-textField"
                    label="Asset Code"
                    value={assetToMint.assetToken().code}
                    readOnly
                  />
                  <Autocomplete
                    isOptionEqualToValue={(option, value) => option === value}
                    id="mintAssetDialog-destinationAccount-autoComplete"
                    disabled
                    getOptionLabel={(option: StellarAccountViewModel) =>
                      `${option.getGroupname()} Trading Acc.`
                    }
                    options={potentialTradingAccountViewModels}
                    loading={mintingInProgress}
                    onChange={(_, selected: StellarAccountViewModel | null) =>
                      handleUpdateMintAssetRequest("destinationAccountID")(
                        selected ? selected.getId() : "",
                      )
                    }
                    value={(() => {
                      const model = potentialTradingAccountViewModels.find(
                        (m) =>
                          m.getId() === mintAssetRequest.destinationAccountID,
                      );
                      return model || null;
                    })()}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        disabled
                        id="mintAssetDialog-destinationAccount-autoCompleteTextField"
                        label="Trading Account"
                        InputProps={{
                          ...params.InputProps,
                          placeholder: mintAssetRequest.destinationAccountID
                            ? undefined
                            : "Select...",
                        }}
                        InputLabelProps={{ shrink: true }}
                        error={
                          !!mintRequestValidationResult.fieldValidations
                            .destinationAccountID
                        }
                        helperText={
                          mintRequestValidationResult.fieldValidations
                            .destinationAccountID
                        }
                      />
                    )}
                  />
                </div>
                <TextNumField
                  id="mintAssetDialog-mintAmount-textNumField"
                  label="Units to Mint"
                  disabled={mintingInProgress}
                  noDecimalPlaces={7}
                  disallowNegative
                  value={mintAssetRequest.amount.value}
                  onChange={(e) =>
                    handleUpdateMintAssetRequest("amount")(
                      mintAssetRequest.amount.setValue(e.target.value),
                    )
                  }
                  error={!!mintRequestValidationResult.fieldValidations.amount}
                  helperText={
                    mintRequestValidationResult.fieldValidations.amount
                  }
                />
              </>
            );
          })()}
        </div>
        <div className={classes.feeLayout}>
          {initialLoading ? (
            <div className={classes.loadingSkeletonLayout}>
              <Skeleton height={40} width={150} />
            </div>
          ) : (
            <div className={classes.feeControlRowLayout}>
              <Tooltip
                title={showFeeDetail ? "Show Less" : "Show More"}
                placement="top"
              >
                <span>
                  <IconButton
                    id="mintAssetDialog-toggleFeeDetail-iconButton"
                    onClick={() => setShowFeeDetail(!showFeeDetail)}
                    size="large"
                  >
                    {showFeeDetail ? (
                      <ExpandLessIcon color="primary" />
                    ) : (
                      <ExpandMoreIcon color="primary" />
                    )}
                  </IconButton>
                </span>
              </Tooltip>
              <Typography
                variant="body1"
                color="textSecondary"
                className={cx({
                  [classes.disabledText]: feeGenerationInProgress,
                })}
                children="Total Fee:"
              />
              <div className={classes.tokenAmount}>
                <Tooltip
                  title={`Issued by ${feeTokenViewModel.issuer}`}
                  placement="top"
                >
                  <Typography
                    variant="body1"
                    className={cx(classes.tokenAmountIssuer, {
                      [classes.disabledText]: feeGenerationInProgress,
                    })}
                    children={feeTokenViewModel.token.code}
                  />
                </Tooltip>
                <Typography
                  variant="body1"
                  className={cx({
                    [classes.disabledText]: feeGenerationInProgress,
                  })}
                  children={formatTextNum(feeTotal.value, {
                    addDecimalPadding: true,
                  })}
                />
              </div>
              {feeGenerationInProgress && <CircularProgress size={15} />}
            </div>
          )}
          <Collapse in={showFeeDetail}>
            <div className={classes.feeDetailLineItemsWrapper}>
              <div>
                {generateAssetMintingFeeResponse.fees.map((f, idx) => (
                  <div
                    key={idx}
                    className={classes.feeDetailBodyLineItemLayout}
                  >
                    <Typography
                      variant="body1"
                      color="textSecondary"
                      className={cx({
                        [classes.disabledText]: feeGenerationInProgress,
                      })}
                      children={f.feeName()}
                    />

                    <div className={classes.tokenAmount}>
                      <Tooltip
                        title={`Issued by ${feeTokenViewModel.issuer}`}
                        placement="top"
                      >
                        <Typography
                          color="textSecondary"
                          className={cx(classes.tokenAmountIssuer, {
                            [classes.disabledText]: feeGenerationInProgress,
                          })}
                          variant="body1"
                          children={feeTokenViewModel.token.code}
                        />
                      </Tooltip>
                      <Typography
                        variant="body1"
                        color="textSecondary"
                        className={cx({
                          [classes.disabledText]: feeGenerationInProgress,
                        })}
                        children={formatTextNum(f.feeAmount().value, {
                          addDecimalPadding: true,
                        })}
                      />
                    </div>

                    <Typography
                      variant="body1"
                      color="textSecondary"
                      className={cx({
                        [classes.disabledText]: feeGenerationInProgress,
                      })}
                      children={"VAT on " + f.feeName()}
                    />

                    <div className={classes.tokenAmount}>
                      <Tooltip
                        title={`Issued by ${feeTokenViewModel.issuer}`}
                        placement="top"
                      >
                        <Typography
                          color="textSecondary"
                          className={cx(classes.tokenAmountIssuer, {
                            [classes.disabledText]: feeGenerationInProgress,
                          })}
                          variant="body1"
                          children={feeTokenViewModel.token.code}
                        />
                      </Tooltip>
                      <Typography
                        variant="body1"
                        color="textSecondary"
                        className={cx({
                          [classes.disabledText]: feeGenerationInProgress,
                        })}
                        children={formatTextNum(
                          f.feeVATRate().multipliedBy(f.feeAmount().value),
                          {
                            addDecimalPadding: true,
                          },
                        )}
                      />
                    </div>
                  </div>
                ))}

                <Typography
                  className={cx({
                    [classes.feeDetailBodyWhyTheseFeesLink]:
                      !feeGenerationInProgress,
                    [classes.feeDetailBodyWhyTheseFeesLinkDisabled]:
                      feeGenerationInProgress,
                  })}
                  variant="body1"
                  color="secondary"
                  onClick={() =>
                    window.open("https://mesh.trade/fees", "_blank")
                  }
                  children="Why these fees?"
                />
              </div>
            </div>
            <div className={classes.feeDetailAccAndFeeRow}>
              <Typography
                variant="body2"
                color="textSecondary"
                children="Fee Acc:"
              />
              <Typography
                variant="body2"
                children={`${feeAccountViewModel.getGroupname()} Trading Acc.`}
              />
              <Typography
                variant="body2"
                color="textSecondary"
                children="Available:"
              />
              <div className={classes.tokenAmount}>
                <Tooltip
                  title={`Issued by ${feeTokenViewModel.issuer}`}
                  placement="top"
                >
                  <Typography
                    className={classes.tokenAmountIssuer}
                    variant="body2"
                    color="textSecondary"
                    children={feeTokenViewModel.token.code}
                  />
                </Tooltip>
                <Typography
                  variant="body2"
                  children={formatTextNum(feeAccountFeeAssetBalance.value, {
                    addDecimalPadding: true,
                  })}
                />
              </div>
            </div>
          </Collapse>
        </div>
      </DialogContent>
    </StyledDialog>
  );
}

// Code generated by protoc-gen-meshproto DO NOT EDIT.
// source: message 'RateSourceRecording' in 'financial/rateSourceRecording.proto'

// @generated by protoc-gen-meshjsreact v1
// @generated from file financial/rateSourceRecordingReader_meshproto.proto (package financial, syntax proto3)
/* eslint-disable */

import { ReadManyRateSourceRecordingRequest, ReadManyRateSourceRecordingResponse } from "@mesh/common-js/dist/financial/rateSourceRecordingReader_meshproto_pb";
import "@mesh/common-js/dist/security/context_pb";
import { useEffect, useRef, useState } from "react";
import 'grpc-web';
/**
* Custom React hook that performs a RateSourceRecording read operation.
* Manages loading, error handling, and response state with debounce.
*
* @function useReadManyRateSourceRecording
* @param {Reader | ReaderUNSCOPED} readManyRateSourceRecordingFunc - The `ReadManyRateSourceRecording` method for reading RateSourceRecording data from the service provider.
* @param {Object} options - Configuration options for the hook.
* @param {ContextFuture} [options.authContext] - Authorization context to attach to the request.
* @param {boolean} [options.shouldNotExecute=false] - If true, prevents the hook from automatically executing the read request.
* @returns {Object} - Contains request, response, loading status, error state, and a setter for updating the read request.
*
* @property {ReadManyRateSourceRecordingRequest} readManyRateSourceRecordingRequest - The current RateSourceRecording request object.
* @property {ReadManyRateSourceRecordingResponse} readManyRateSourceRecordingResponse - The latest response received from the RateSourceRecording read operation.
* @property {string | undefined} readManyRateSourceRecordingError - Error message in case the request fails.
* @property {function} setReadRateSourceRecordingRequest - Function to update the read request with new criteria or context.
*
* @throws {TypeError} Throws error if neither `initialRequest` nor `authContext` are set in the request setup.
*
* @example
* const {
*   readManyRateSourceRecordingRequest,
*   readManyRateSourceRecordingResponse,
*   readManyRateSourceRecordingError,
*   setReadRateSourceRecordingRequest,
*   readManyRateSourceRecordingLoading
* } = useReadManyRateSourceRecording(rateSourceRecordingReader.ReadMany, { authContext });
*/
export function useReadManyRateSourceRecording(reader, { initialRequest, authContext, shouldNotExecute, }) {
    const [readManyRequest, setReadManyRequest] = useState((() => {
        if (initialRequest) {
            return initialRequest;
        }
        if (authContext) {
            return new ReadManyRateSourceRecordingRequest().setContext(authContext);
        }
        throw new TypeError("neither initalRequest nor authContext are set");
    })());
    const [readManyResponse, setReadManyResponse] = useState(new ReadManyRateSourceRecordingResponse());
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(undefined);
    const timeoutRef = useRef(undefined);
    useEffect(() => {
        // Do not execute until required
        if (shouldNotExecute) {
            return;
        }
        // start loading and clear previous timeout
        setLoading(true);
        clearTimeout(timeoutRef.current);
        // start new request
        timeoutRef.current = setTimeout(async () => {
            setError(undefined);
            try {
                setReadManyResponse(await ("readManyRateSourceRecording" in reader ? reader.readManyRateSourceRecording(readManyRequest) : reader.readManyRateSourceRecordingUNSCOPED(readManyRequest)));
            }
            catch (e) {
                console.error(`error reading many rateSourceRecording ${e}`);
                setError(`${e}`);
            }
            setLoading(false);
        }, 400);
    }, [readManyRequest, shouldNotExecute]);
    return {
        readManyRateSourceRecordingRequest: readManyRequest,
        readManyRateSourceRecordingResponse: readManyResponse,
        readManyRateSourceRecordingError: error,
        setReadRateSourceRecordingRequest: (readManyRateSourceRecordingRequest) => {
            setReadManyRequest(new ReadManyRateSourceRecordingRequest().
                setContext(readManyRateSourceRecordingRequest.getContext()).
                setCriteriaList(readManyRateSourceRecordingRequest.getCriteriaList()).
                setQuery(readManyRateSourceRecordingRequest.getQuery()));
        },
        readManyRateSourceRecordingLoading: loading,
    };
}

import {
  jsonRPCRequest,
  JSONRPCRequestOpts,
} from "utilities/network/jsonRPCRequest/jsonRPCRequest";
import config from "react-global-configuration";
import { Context } from "james/security";
import { Token } from "james/ledger";

export interface RequestIconUploadURLForTokenRequest {
  context: Context;
  token: Token;
}

export interface RequestIconUploadURLForTokenResponse {
  uploadURL: string;
}

export interface RequestIconDownloadURLForTokenRequest {
  context: Context;
  token: Token;
}

export interface RequestIconDownloadURLForTokenResponse {
  downloadURL: string;
}

export const TokenIconControllerServiceProviderName =
  "financial-TokenIconController";

export const TokenIconController = {
  async RequestIconUploadURLForToken(
    request: RequestIconUploadURLForTokenRequest,
    opts?: JSONRPCRequestOpts,
  ): Promise<RequestIconUploadURLForTokenResponse> {
    return jsonRPCRequest(
      {
        url: config.get("meshAuthorizedURL"),
        method: `${TokenIconControllerServiceProviderName}.RequestIconUploadURLForToken`,
        request,
      },
      opts,
    );
  },
  async RequestIconDownloadURLForToken(
    request: RequestIconDownloadURLForTokenRequest,
    additionalHeaders?: Headers,
    opts?: JSONRPCRequestOpts,
  ): Promise<RequestIconDownloadURLForTokenResponse> {
    return jsonRPCRequest(
      {
        url: config.get("meshAuthenticatedURL"),
        method: `${TokenIconControllerServiceProviderName}.RequestIconDownloadURLForToken`,
        request,
      },
      opts,
    );
  },
};

import {
  Box,
  Button,
  Card,
  Divider,
  IconButton,
  Tooltip,
  Typography,
  useTheme,
} from "@mui/material";
import { useEffect, useState } from "react";
import { ReactSortable } from "react-sortablejs";
import DeleteIcon from "@mui/icons-material/Delete";
import { ValidationResult } from "common/validation";
import { DateField, TextField } from "components/FormFields";
import { NewsArticle } from "@mesh/common-js/dist/marketing/newsArticle_pb";
import {
  protobufTimestampToDayjs,
  dayjsToProtobufTimestamp,
} from "@mesh/common-js/dist/googleProtobufConverters";
import { NoDataSplashCard } from "../NoDataSplashCard";
import dayjs from "dayjs";

export type NewsArticlesFormProps = {
  newsArticles: NewsArticle[];
  disabled: boolean;
  readOnly: boolean;
  formDataValidationResult: ValidationResult;
  onChange: (updatedNewsArticles: NewsArticle[]) => void;
};

export const NewsArticlesForm = (props: NewsArticlesFormProps) => {
  const theme = useTheme();

  // TODO: Add validations
  // const getFieldValidation = (field: string, index: number) => {
  //   return props.formDataValidationResult.fieldValidations[
  //     `newsArticles-${index}-${field}`
  //   ];
  // };

  // State for the list of news articles
  const [newsArticles, setNewsArticles] = useState(
    props.newsArticles.map((article, idx) => ({
      id: idx,
      article,
    })),
  );

  // State for the new article being added
  const [currentArticle, setCurrentArticle] = useState<NewsArticle>(
    new NewsArticle().setDate(dayjsToProtobufTimestamp(dayjs())),
  );

  // State for the last saved news articles
  const [savedNewsArticles, setSavedNewsArticles] = useState(
    props.newsArticles,
  );

  // Helper function to compare articles for unsaved changes
  const areArticlesDifferent = (
    articlesA: NewsArticle[],
    articlesB: NewsArticle[],
  ) => {
    if (articlesA.length !== articlesB.length) return true;
    return articlesA.some((a, idx) => {
      const b = articlesB[idx];
      return (
        a.getDate() !== b.getDate() ||
        a.getUrl() !== b.getUrl() ||
        a.getExcerpt() !== b.getExcerpt()
      );
    });
  };

  // Determine unsaved changes
  const hasUnsavedChanges =
    !props.readOnly &&
    areArticlesDifferent(
      newsArticles.map((item) => item.article),
      savedNewsArticles,
    );

  // Handle changes in readOnly prop
  useEffect(() => {
    if (props.readOnly) {
      // Save the current state when switching to readOnly
      setSavedNewsArticles(newsArticles.map((item) => item.article));
      // Reset current article
      setCurrentArticle(
        new NewsArticle().setDate(dayjsToProtobufTimestamp(dayjs())),
      );
    }
  }, [props.readOnly]);

  return (
    <Card
      sx={{
        width: "100%",
        display: "flex",
        flexDirection: "column",
        border: `1px solid ${theme.palette.custom.grape}`,
        backgroundColor: theme.palette.custom.midnight,
        borderRadius: theme.shape.borderRadius,
        p: theme.spacing(2),
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          mb: theme.spacing(2),
        }}
      >
        <Typography variant="h6">News Articles</Typography>
        {hasUnsavedChanges && (
          <Box
            sx={{
              width: 8,
              height: 8,
              borderRadius: "50%",
              backgroundColor: theme.palette.warning.light,
            }}
          />
        )}
      </Box>

      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
        }}
      >
        {/* Section with added media entries */}
        {!newsArticles.length && props.readOnly ? (
          <NoDataSplashCard
            dataDescriptionTitle={"News Articles"}
            dataDescriptionBody={"Articles"}
          />
        ) : (
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
            }}
          >
            <ReactSortable
              animation={200}
              easing="ease-out"
              disabled={props.readOnly}
              list={newsArticles}
              style={{
                display: "flex",
                flexDirection: "column",
                gap: theme.spacing(2),
                cursor: props.readOnly ? "default" : "grab",
              }}
              setList={(newList) => {
                setNewsArticles(newList);
                props.onChange(newList.map((item) => item.article));
              }}
            >
              {newsArticles.map(({ id, article }, idx) => (
                <Card
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    p: theme.spacing(2),
                  }}
                  key={id}
                >
                  <Box
                    sx={{
                      display: "flex",
                      width: "100%",
                      gap: theme.spacing(1.5),
                      alignItems: "center",
                    }}
                  >
                    <Box sx={{ width: "20%" }}>
                      <DateField
                        id={`instrumentMarketingContent-newsArticle-${idx}-dateField`}
                        label={"Date"}
                        readOnly={true}
                        value={
                          article.getDate()
                            ? protobufTimestampToDayjs(article.getDate()!)
                            : null
                        }
                      />
                    </Box>
                    <Divider orientation="vertical" flexItem />
                    <TextField
                      id={`instrumentMarketingContent-newsArticle-${idx}-link-textField`}
                      sx={{ width: "40%" }}
                      label={"Link"}
                      readOnly={true}
                      value={article.getUrl()}
                    />
                    <Divider orientation="vertical" flexItem />
                    <TextField
                      id={`instrumentMarketingContent-newsArticle-${idx}-excerpt-textField`}
                      sx={{ width: "40%" }}
                      label={"Excerpt"}
                      readOnly={true}
                      value={article.getExcerpt()}
                    />
                    {!props.readOnly && (
                      <Divider orientation="vertical" flexItem />
                    )}
                  </Box>

                  {/* Delete Icon */}
                  {!props.readOnly && (
                    <Tooltip title="Delete Article" placement="top">
                      <IconButton
                        id={`instrumentMarketingContent-newsArticle-${idx}-delete-iconButton`}
                        disabled={props.disabled}
                        aria-label="delete"
                        size="medium"
                        sx={{ ml: theme.spacing(2) }}
                        onClick={() => {
                          const updatedArticles = newsArticles.filter(
                            (_, index) => index !== idx,
                          );
                          setNewsArticles(updatedArticles);
                          props.onChange(
                            updatedArticles.map((item) => item.article),
                          );
                        }}
                      >
                        <DeleteIcon />
                      </IconButton>
                    </Tooltip>
                  )}
                </Card>
              ))}
            </ReactSortable>
          </Box>
        )}

        {/* Section for adding a new article */}
        {!props.readOnly && (
          <>
            <Box
              sx={{
                display: "flex",
                width: "100%",
                gap: theme.spacing(2),
                mt: theme.spacing(2),
                mb: theme.spacing(1),
              }}
            >
              <DateField
                id={"instrumentMarketingContent-newNewsArticle-dateField"}
                disabled={props.disabled}
                label={"Date"}
                sx={{ width: "20%" }}
                value={
                  currentArticle.getDate()
                    ? protobufTimestampToDayjs(currentArticle.getDate()!)
                    : null
                }
                onChange={(e) => {
                  if (e) {
                    setCurrentArticle((prev) =>
                      new NewsArticle()
                        .setDate(dayjsToProtobufTimestamp(e))
                        .setUrl(prev.getUrl())
                        .setExcerpt(prev.getExcerpt()),
                    );
                  }
                }}
              />
              <TextField
                fullWidth
                id={"instrumentMarketingContent-currentArticle-url-textField"}
                disabled={props.disabled}
                label={"Link"}
                value={currentArticle.getUrl()}
                placeholder={"Enter article link..."}
                sx={{ width: "83%" }}
                onChange={(e) => {
                  setCurrentArticle((prev) =>
                    new NewsArticle()
                      .setDate(prev.getDate())
                      .setUrl(e.target.value)
                      .setExcerpt(prev.getExcerpt()),
                  );
                }}
              />
            </Box>
            <TextField
              multiline
              rows={2}
              id={"instrumentMarketingContent-currentArticle-excerpt-textField"}
              disabled={props.disabled}
              label={"Excerpt"}
              value={currentArticle.getExcerpt()}
              placeholder={"Enter article excerpt..."}
              onChange={(e) => {
                setCurrentArticle((prev) =>
                  new NewsArticle()
                    .setDate(prev.getDate())
                    .setUrl(prev.getUrl())
                    .setExcerpt(e.target.value),
                );
              }}
            />
            <Button
              id={"instrumentMarketingContent-newNewsArticle-add-button"}
              sx={{ mt: 2 }}
              variant="contained"
              color="primary"
              disabled={
                !currentArticle.getUrl() ||
                !currentArticle.getExcerpt() ||
                !currentArticle.getDate()
              }
              onClick={() => {
                const updatedArticles = [
                  ...newsArticles,
                  { id: newsArticles.length, article: currentArticle },
                ];
                setNewsArticles(updatedArticles);
                props.onChange(updatedArticles.map((item) => item.article));

                // Reset current article
                setCurrentArticle(
                  new NewsArticle().setDate(dayjsToProtobufTimestamp(dayjs())),
                );
              }}
            >
              Add Article
            </Button>
          </>
        )}
      </Box>
    </Card>
  );
};
